import { useState, useEffect, useRef } from "react";
import * as React from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import { useAlert, AlertManager } from "react-alert";
import gql from "graphql-tag";
import { Loader } from "@@ui-kit";
import uuid from "uuid/v4";
import _ from "lodash";
import {
  GetFormByIdQueryVariables,
  GetFormByIdQuery,
} from "@@generated/graphql";
import { Authorization } from "../PCAuthorizations/PatientTile/interfaces";
import { useConfig, Config } from "../../hooks";
import { Container, PDFImage } from "../../components/PDFEditor/Editor";
import {
  TaggerForManuallyUploadedForm,
  OptionGroup,
  dragType,
  ManuallyConfiguredBoxes,
} from "./TaggerForManuallyUploadedForm";
import { calculateDimensionRatio } from "../../components/PDFEditor/Editor/utils";
import { Form } from "../../util/inputConfigurationManager";
import { useDrop, DropTargetMonitor } from "react-dnd-new";
import FormSubmitButtons from "../../components/AuthorizationSharedComponents/FormSubmitButtons";
import { formatPhoneNumber } from "../../components/PDFEditor/InputTypes/PhoneInput";
import { CUSTOM_FIELDS } from "../../../../server/src/shared_code";

const OverlayShadow = styled.div`
  position: absolute;
  background-color: black;
  opacity: 0.1;
  z-index: ${(props) => props.theme.zMiddle};
  width: ${({ theme }) => theme.pdfWidth};
  height: ${({ theme }) => theme.pdfHeight};
  pointer-events: none;
`;

const HeaderContainer = styled.h4`
  color: ${(props) => props.theme.primary};
`;

const Overlay = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.zLow};
  width: ${({ theme }) => theme.pdfWidth};
  height: ${({ theme }) => theme.pdfHeight};
`;

const ScrollContainer = styled.div<{ height?: string }>`
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  width: ${({ theme }) => theme.pdfWidth};
  height: ${({ theme }) => theme.pdfHeight};
  overflow: scroll;
`;

const formQuery = gql`
  query getFormById($id: Int!) {
    formById(id: $id) {
      pdfURL
      imgURLs
      specification
      taggedHeight
      taggedWidth
      manuallyConfiguredBoxes {
        height
        page
        width
        x
        y
        id
        value
        key
      }
    }
  }
`;

const setManuallyConfiguredBoxesOnFormMutation = gql`
  mutation setManuallyConfiguredBoxesOnForm(
    $input: SetManuallyConfiguredBoxesOnFormInput!
  ) {
    setManuallyConfiguredBoxesOnForm(input: $input) {
      success
    }
  }
`;

export const PRIMARY_DRUG_NAME_LABEL = "Service/Drug Name";
export const PRIMARY_NDC_LABEL = "Code";

const validateMandatoryValue = ({
  authorizationConfig,
  keys,
  alert,
}: {
  authorizationConfig: Config;
  keys: string[];
  alert: AlertManager;
}) => {
  const defaultFields = authorizationConfig.DEFAULT_FIELDS;
  const mandatoryFields = [
    defaultFields.PATIENT_FIRST_NAME.title,
    defaultFields.PATIENT_LAST_NAME.title,
    defaultFields.PATIENT_DOB.title,
    defaultFields.PRESCRIBER_FIRST_NAME.title,
    defaultFields.PRESCRIBER_LAST_NAME.title,
    defaultFields.PRESCRIBER_NPI.title,
    CUSTOM_FIELDS.SAMA_FAX.title,
    PRIMARY_DRUG_NAME_LABEL,
    PRIMARY_NDC_LABEL,
  ];

  const missingItems: string[] = [];
  mandatoryFields.forEach((item) => {
    if (!keys.includes(item)) {
      missingItems.push(item);
    }
  });
  if (missingItems.length > 0) {
    alert.error(
      `Missing field ${JSON.stringify(missingItems)} on current form`
    );
    return {
      success: false,
    };
  }
  return {
    success: true,
  };
};

const formatUserInputData = ({
  authorizationConfig,
  authorization,
  samaFaxNumber,
}: {
  authorizationConfig: Config;
  authorization: Authorization;
  samaFaxNumber: string;
}) => {
  const defaultFields = authorizationConfig.DEFAULT_FIELDS;
  const sectionCollector = [
    {
      name: "Patient",
      fields: [
        defaultFields.PATIENT_FIRST_NAME.key,
        defaultFields.PATIENT_LAST_NAME.key,
        defaultFields.PATIENT_DOB.key,
        defaultFields.PATIENT_ADDRESS_LINE.key,
        defaultFields.PATIENT_CITY.key,
        defaultFields.PATIENT_STATE.key,
        defaultFields.PATIENT_ZIP.key,
        defaultFields.NOVARTIS_PATIENT_ID.key,
      ],
    },
    {
      name: "Product",
      fields: [
        defaultFields.PRIMARY_DRUG_NAME.key,
        defaultFields.PRIMARY_NDC.key,
      ],
    },
    {
      name: "HCP Details",
      fields: [
        CUSTOM_FIELDS.SAMA_FAX.key,
        defaultFields.MULTI_PARTY_OFFICE_CONTACT.key,
      ],
    },
    {
      name: "Patient Insurance Information",
      fields: [
        defaultFields.PATIENT_MEMBER_ID.key,
        defaultFields.INSURANCE_STATE.key,
        defaultFields.INSURANCE_COMPANY.key,
      ],
    },
    {
      name: "Provider & Location Details",
      fields: [
        defaultFields.PRESCRIBER_FIRST_NAME.key,
        defaultFields.PRESCRIBER_LAST_NAME.key,
        defaultFields.PRESCRIBER_NPI.key,
        defaultFields.PRESCRIBER_ADDRESS.key,
        defaultFields.PRESCRIBER_CITY.key,
        defaultFields.PRESCRIBER_STATE.key,
        defaultFields.PRESCRIBER_ZIP.key,
      ],
    },
  ];

  const { config } = authorization;
  const formTypes = authorizationConfig.CONSTANTS.FORM_TYPES;

  const result = sectionCollector.map((section) => {
    const formattedFields: { [key: string]: any } = {};
    section.fields.forEach((key) => {
      let title;
      if (key === defaultFields.PRIMARY_DRUG_NAME.key) {
        title = PRIMARY_DRUG_NAME_LABEL;
        formattedFields[title] = config[key];
      } else if (key === defaultFields.PRIMARY_NDC.key) {
        title = PRIMARY_NDC_LABEL;
        formattedFields[title] = config[key];
      } else if (key === CUSTOM_FIELDS.SAMA_FAX.key) {
        title = CUSTOM_FIELDS.SAMA_FAX.title;
        formattedFields[title] = samaFaxNumber;
      } else {
        title = defaultFields[key as keyof typeof defaultFields].title;
        if (config[key] != null && title != null) {
          if (
            defaultFields[key as keyof typeof defaultFields].type ===
            formTypes.PHONE
          ) {
            formattedFields[title] = formatPhoneNumber(config[key] as string);
          } else {
            formattedFields[title] = config[key];
          }
        }
      }
    });

    return {
      name: section.name,
      fields: formattedFields,
    };
  });

  const filteredResult = result.filter((section) => !_.isEmpty(section.fields));

  return filteredResult;
};

export const PDFEditorForManuallyUploadedForm: React.VoidFunctionComponent<{
  onBack: () => void;
  nextStep: ({
    onStart,
    onEnd,
    shouldStart,
  }: {
    onStart: () => Promise<void>;
    onEnd: () => void;
    shouldStart: () => boolean;
  }) => void;
  authorization: Authorization;
  samaFaxNumber: string;
}> = ({ onBack, nextStep, authorization, samaFaxNumber }) => {
  const alert = useAlert();
  const authorizationConfig = useConfig();

  const { data, loading } = useQuery<
    GetFormByIdQuery,
    GetFormByIdQueryVariables
  >(formQuery, {
    variables: { id: authorization.formId! },
  });

  const [setManuallyConfiguredBoxesOnForm] = useMutation(
    setManuallyConfiguredBoxesOnFormMutation
  );

  const [dimensionRatios, setDimensionRatios] = useState({});
  const [items, setItems] = useState<ManuallyConfiguredBoxes[] | []>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [removeId, setRemoveId] = useState<string | null>(null);
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [optionGroup, setOptionGroup] = useState<OptionGroup[] | []>([]);

  const overlay =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const moveItem = (item: { id: string }, monitor: DropTargetMonitor) => {
    const itemOffset = monitor.getClientOffset();
    const mousePointerOffset = monitor.getSourceClientOffset();
    const selectedItem = items.find((ele) => ele.id === item.id);
    if (
      _.isNil(overlay.current) ||
      _.isNil(itemOffset) ||
      _.isNil(mousePointerOffset)
    ) {
      return;
    }
    const { left, top } = overlay.current.getBoundingClientRect();
    /**
     * When user click the item and trying to drag it,
     * the position of the mouse pointer (x, y), is
     * different from the position of the item itself,
     * so we need to subtract the difference when
     * calculate the updated position
     */
    const x =
      itemOffset.x - left - Math.abs(itemOffset.x - mousePointerOffset.x);
    const y =
      itemOffset.y - top - Math.abs(itemOffset.y - mousePointerOffset.y);
    if (selectedItem !== undefined) {
      setItems(
        items.map((entry) =>
          entry.id === selectedItem.id
            ? {
                ...entry,
                x,
                y,
              }
            : { ...entry }
        )
      );
    }
  };

  const submit = () => {
    nextStep({
      onStart: async () => {
        setIsSubmitting(true);

        const filteredBoxes = items.map((box) => {
          return {
            x: box.x,
            y: box.y,
            width: box.width,
            height: box.height,
            value: box.value,
            id: box.id,
            page: box.page,
            key: box.key,
          };
        });

        try {
          await setManuallyConfiguredBoxesOnForm({
            variables: {
              input: {
                formId: authorization.formId!,
                manuallyConfiguredBoxes: filteredBoxes,
              },
            },
          });
        } catch (err) {
          alert.error(err instanceof Error ? err.message : "Unknown error");
        }
      },
      onEnd: () => {
        setIsSubmitting(false);
      },
      shouldStart: () => {
        const keys = items
          .map((item) => item.key)
          .filter((key) => key != null) as string[];
        const validateResult = validateMandatoryValue({
          authorizationConfig,
          keys,
          alert,
        });
        return validateResult.success;
      },
    });
  };

  const [, drop] = useDrop(
    () => ({
      accept: dragType,
      drop: (item: { id: string }, monitor) => moveItem(item, monitor),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [items]
  );

  useEffect(() => {
    const fetchData = async () => {
      const result = formatUserInputData({
        authorizationConfig,
        authorization,
        samaFaxNumber,
      });

      if (!_.isNil(result) && !_.isNil(result)) {
        setOptionGroup(result);
      }
    };
    void fetchData();

    if (!_.isNil(data)) {
      setDimensionRatios(
        (data?.formById?.imgURLs ?? []).reduce((acc, _key, i) => {
          return { ...acc, [i]: null };
        }, {})
      );
      if (!_.isNil(data.formById?.manuallyConfiguredBoxes)) {
        setItems(
          data.formById?.manuallyConfiguredBoxes as ManuallyConfiguredBoxes[]
        );
      }
    }
  }, [data]);

  if (loading) {
    return <div />;
  }

  const areAllPagesLoaded = Object.values(dimensionRatios).every(
    (val) => !_.isNil(val)
  );

  const create = (e: React.MouseEvent) => {
    e.stopPropagation();
    const selected = _.find(items, { id: selectedId });
    const toBeRemoved = _.find(items, { id: removeId });
    if (selected === undefined && toBeRemoved === undefined) {
      const bounds = overlay.current.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const y = e.clientY - bounds.top;

      const id = uuid();

      const newBox = {
        /**
         * The page property for now is just a placeholder,
         * we will calculate the page and the value of "y"
         * after the user clicks save and finishes editing
         *
         * Same with width, we will calculate the width of
         * box based on user selection
         */
        x,
        y,
        width: 185,
        height: 25,
        page: 0,
      };
      setItems([...items, { id, ...newBox, value: null, key: null }]);
    }
  };

  const removeItem = (id: string) => {
    setItems(items.filter((item) => item.id !== id));
    setSelectedId(null);
    setRemoveId(null);
  };

  return (
    <>
      <Container>
        <Loader active={!areAllPagesLoaded} />
        <HeaderContainer>
          Click anywhere on the form to fill information. Required fields are
          marked with an asterisk (*).
        </HeaderContainer>
        <OverlayShadow />
        <ScrollContainer ref={drop}>
          <Overlay
            onMouseDown={create}
            onMouseUp={(e) => {
              e.stopPropagation();
              setSelectedId(null);
            }}
            ref={overlay}
          >
            {_.map(items, ({ x, y, id }) => {
              return (
                <TaggerForManuallyUploadedForm
                  setHoveredId={setHoveredId}
                  key={id}
                  x={x}
                  y={y}
                  id={id}
                  setItems={setItems}
                  setSelectedId={setSelectedId}
                  setRemoveId={setRemoveId}
                  removeItem={removeItem}
                  items={items}
                  hoveredId={hoveredId}
                  optionGroups={optionGroup}
                />
              );
            })}
            {!!data &&
            !_.isNil(data.formById) &&
            !_.isNil(data.formById.imgURLs) ? (
              data.formById.imgURLs.map((imgURL, i) => (
                <PDFImage
                  key={imgURL}
                  onLoad={(event) => {
                    setDimensionRatios({
                      ...dimensionRatios,
                      [i]: calculateDimensionRatio(
                        data.formById! as Form,
                        event.currentTarget
                      ),
                    });
                  }}
                  src={imgURL}
                />
              ))
            ) : (
              <div />
            )}
          </Overlay>
        </ScrollContainer>
      </Container>
      <FormSubmitButtons back={onBack} submit={submit} loading={isSubmitting} />
    </>
  );
};
