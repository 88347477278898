import * as React from "react";
import styled from "styled-components";

const PurpleBox = styled.div`
  border: 2px solid ${(props) => props.theme.purple};
  border-radius: 8px;
  padding: 12px 20px 16px;
  margin-bottom: 36px;
`;

const PurpleBoxLabel = styled.div`
  display: flex;
  font-size: 18px;
  width: 100%;
  line-height: 1.5em;
  color: ${(props) => props.theme.purple};
`;

const Bullet = () => (
  <div style={{ marginLeft: "10px", marginRight: "6px" }}>&bull;</div>
);

export const EnrollmentBenefits: React.VFC = () => (
  <PurpleBox>
    <PurpleBoxLabel style={{ marginBottom: "8px" }}>
      SamaAssist Service:
    </PurpleBoxLabel>
    <PurpleBoxLabel>
      <Bullet />
      <div>SamaCare dedicated support at every stage</div>
    </PurpleBoxLabel>
    <PurpleBoxLabel>
      <Bullet />
      <div>Faster payer response time</div>
    </PurpleBoxLabel>
    <PurpleBoxLabel>
      <Bullet />
      <div>Higher chance of approval</div>
    </PurpleBoxLabel>
  </PurpleBox>
);
