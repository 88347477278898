import React from "react";
import { render } from "react-dom";

import Root from "./layouts/Root";

render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("app")
);
