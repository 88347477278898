import MuiBox, { BoxProps } from "@mui/material/Box";
import { OverridableComponent } from "@mui/types";
import { BoxTypeMap } from "@mui/system";

import { Theme } from "../theme";

const Box: OverridableComponent<BoxTypeMap<object, "div", Theme>> = MuiBox;

export type { BoxProps };
export default Box;
