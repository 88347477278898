import axios from "axios";
import UserSessionRecorder from "../services/UserSessionRecorder";

export const login = (email, password, onComplete) => {
  axios
    .post("/login", { email: email.trim(), password })
    .then((res) => {
      const { user } = res.data;

      UserSessionRecorder.identify(
        user.id,
        `${user.firstName} ${user.lastName}`,
        user.email
      );

      if (CONFIG.ENABLE_SATISMETER) {
        satismeter({
          writeKey: CONFIG.SATISMETER_KEY,
          userId: user.id,
          traits: {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            createdAt: user.createdAt,
          },
        });
      }
      if (CONFIG.ENABLE_FRESHDESK) {
        // Prefill these fields in the freshworks/freshchat widgets
        if (fcWidget) {
          window.fcWidget.user.setProperties({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          });
        }

        if (FreshworksWidget) {
          FreshworksWidget("identify", "ticketForm", {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          });

          // Hide these fields in the freshworks widget
          FreshworksWidget("hide", "ticketForm", ["name", "email"]);
        }
      }
      onComplete(null, user);
    })
    .catch((err) => onComplete(err));
};

export const logout = () => {
  axios.post("/logout").then(() => {
    window.location.reload();
  });
};

export const isAuthenticated = (onComplete) => {
  axios
    .get("/isAuthenticated")
    .then((res) => {
      onComplete(null, res.data);
    })
    .catch((err) => onComplete(err));
};
