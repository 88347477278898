import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  GridCloseIcon,
  IconButton,
  Typography,
  Divider,
} from "@samacare/design/core";
import { useTheme } from "styled-components";
import { SxProps } from "@samacare/design/core/styles";

export const DialogModal: React.VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  actions: React.ReactNode;
  headerText?: string;
  isBackgroundYellow?: boolean;
  showCloseIcon?: boolean;
  sx?: SxProps;
  divider?: boolean;
}> = ({
  open,
  onClose,
  title,
  content,
  actions,
  headerText,
  isBackgroundYellow,
  showCloseIcon = true,
  sx,
  divider,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="lg"
      sx={{
        ...sx,
        "& .MuiDialog-paper": {
          borderTop: `4px solid ${
            isBackgroundYellow ? theme.lightYellow : theme.purple
          }`,
          borderRadius: "8px",
          width: "600px",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "left",
          py: 1.25,
          px: 3,
          fontSize: "20px !important",
          fontWeight: "bold",
          position: "relative",
        }}
      >
        {title}
        {divider && (
          <Divider
            sx={{
              mt: 2,
              backgroundColor: `${theme.black}21`,
              position: "absolute",
              left: 0,
              right: 0,
              width: "100%",
            }}
          />
        )}
      </DialogTitle>
      {headerText && (
        <Typography
          sx={{
            textAlign: "left",
            py: 0,
            px: 3,
            fontSize: "17px",
            mt: -1,
          }}
        >
          {headerText}
        </Typography>
      )}
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 6,
            color: theme.gray,
          }}
        >
          <GridCloseIcon />
        </IconButton>
      )}
      <DialogContent
        sx={{
          p: 2.5,
          m: 2.5,
          mt: 0,
          overflow: "hidden",
        }}
      >
        {content}
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: isBackgroundYellow
            ? theme.lightYellow
            : theme.lightPurple,
          py: 1.25,
          px: 3,
          justifyContent: "flex-end",
        }}
      >
        {actions}
      </DialogActions>
    </Dialog>
  );
};
