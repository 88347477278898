import * as React from "react";
import styled, { css } from "styled-components";
import Favorite from "@@components/Favorite";
import { Typography } from "@samacare/design/core";
import { formatPhoneForDisplay } from "@samacare/utils";

const Item = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px;
  align-self: stretch;
  border: 1px solid transparent;

  &:hover {
    background: rgba(99, 99, 242, 0.08);
  }

  ${(props) =>
    props.isSelected &&
    css`
      border-radius: 4px;
      border: 1px solid rgba(99, 99, 242, 0.5);
      background: rgba(99, 99, 242, 0.08);
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
`;

const StyledDetails = styled(Typography)<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? "#6363f2" : "rgba(0, 0, 0, 0.6)")};
`;

const StyledFavoriteContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

interface Props {
  number: string;
  description: string;
  isFavorite: boolean;
  id: string;
  onClick: (params: any) => void;
  isSelected: boolean;
  dataCy?: string;
}

export const PhoneNumberItem: React.VoidFunctionComponent<Props> = ({
  number,
  description,
  isFavorite,
  id,
  onClick,
  isSelected,
  dataCy,
}) => (
  <Item key={id} isSelected={isSelected} data-cy={dataCy}>
    <Container onClick={() => onClick(id)}>
      <StyledDetails isSelected={isSelected} variant="body2">
        {formatPhoneForDisplay(number)}
      </StyledDetails>
      <StyledDetails isSelected={isSelected} variant="body2">
        {description}
      </StyledDetails>
    </Container>
    <StyledFavoriteContainer>
      <Favorite isFavorite={isFavorite} numberId={id} isSimplifiedUx={true} />
    </StyledFavoriteContainer>
  </Item>
);
