import React from "react";
import _ from "lodash";
import VMasker from "vanilla-masker";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { InputLabelProps, TextField, TextFieldProps } from "@samacare/design";

const unmaskValue = (val: string) => val.replace(/\D/g, "");

export type MaskedTextFieldProps = UseControllerProps &
  TextFieldProps & {
    mask: string;
  };

export const MaskedTextField: React.FC<MaskedTextFieldProps> = (props) => {
  const {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control: controlFromProps,
    disabled,
    ...otherProps
  } = props;
  const { control } = useFormContext();

  const maskValue = (val: string) =>
    val ? VMasker.toPattern(val, props.mask) : val;

  return (
    <Controller
      name={name}
      control={controlFromProps ?? control}
      rules={{
        required: props.required && `${props.label} required`,
        ...rules,
      }}
      defaultValue={defaultValue}
      disabled={false} // ensures that the field is picked up by react-hook-form when submitting, disabled set below in render
      shouldUnregister={shouldUnregister ?? false}
      render={({
        field: { onChange, value, ...otherFieldProps },
        fieldState: { error },
      }) => (
        <TextField
          {...otherProps}
          error={Boolean(error)}
          helperText={error && error.message ? error.message : null}
          {...otherFieldProps}
          value={maskValue(value)}
          disabled={disabled}
          onChange={(e) => {
            // mask first to eliminate unwanted characters
            onChange(unmaskValue(maskValue(e.target.value)));
          }}
          InputLabelProps={{
            shrink: true,
            ...(props.InputLabelProps as InputLabelProps),
          }}
          inputProps={{ "data-cy": _.camelCase(`field.${props.name}`) }}
        />
      )}
    />
  );
};
