import * as React from "react";
import { useTheme } from "../app/hooks";
import { BaseButton, BaseButtonProps } from "./BaseButton";

export type SecondaryButtonProps = React.ComponentProps<typeof SecondaryButton>;

/**
 * @deprecated Use a MUI component instead
 */
export const SecondaryButton: React.FunctionComponent<
  Omit<BaseButtonProps, "backgroundColor">
> = (props) => {
  const theme = useTheme();
  return <BaseButton {...props} backgroundColor={theme.colors.secondary} />;
};
