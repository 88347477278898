import { PureComponent } from "react";
import { ReactTooltip } from "@@ui-kit/ReactTooltip";
import strings from "Resources/strings";
import PropTypes from "prop-types";

import { genericInput } from "../InputPropTypes";
import { BaseInput, getPropsFromInput } from "./BaseInput";

class TextInput extends PureComponent {
  static propTypes = {
    input: genericInput.isRequired,
    number: PropTypes.bool,
  };

  static defaultProps = {
    number: false,
  };

  render() {
    const { input, number } = this.props;
    return (
      <div>
        <BaseInput
          data-cy={input.id}
          type={number ? "number" : "text"}
          {...getPropsFromInput(input)}
          onChange={({ target }) => {
            if (input.characterBoxes) {
              // validate character boxes if necessary
              target.setCustomValidity(
                target.value.length > input.characterBoxes
                  ? strings.PDF_EDITOR.CHAR_BOX_ERROR(input.characterBoxes)
                  : ""
              );
            }
            input.onChange({ [input.id]: target.value });
          }}
          value={input.value || ""}
          data-tooltip-id={`input_${input.id}_tooltip`}
        />
        {input.characterBoxes != null &&
          input.value &&
          input.value.length > input.characterBoxes && (
            <ReactTooltip id={`input_${input.id}_tooltip`}>
              {strings.PDF_EDITOR.CHAR_BOX_ERROR(input.characterBoxes)}
            </ReactTooltip>
          )}
      </div>
    );
  }
}

export default TextInput;
