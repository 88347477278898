import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import Alert from "@samacare/design/core/Alert/Alert";
import AlertTitle from "@samacare/design/core/AlertTitle/AlertTitle";
import { ReactNode } from "react";
import Stack from "@samacare/design/core/Stack";
import { Box, Button } from "@samacare/design/core";
import { datadogRum } from "@datadog/browser-rum";

interface ErrorBoundaryProps {
  children: ReactNode;
  message?: string;
  name: string;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, name }) => {
  return (
    <ReactErrorBoundary
      key={name}
      onError={(error: Error, info: { componentStack: string }) => {
        datadogRum.addError(error, {
          boundary: name,
          componentStack: info.componentStack,
        });
        return true;
      }}
      fallbackRender={({ error }) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <Alert
            severity="error"
            sx={{
              minWidth: "300px",
              maxWidth: "500px",
              minHeight: "150px",
              maxHeight: "300px",
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{ height: "100%" }}
            >
              <Box>
                <AlertTitle>Oops</AlertTitle>
                An error occurred. Please refresh the page and try again.
                {error.message && <pre>{error.message}</pre>}
              </Box>
              <Stack
                direction="row"
                sx={{ justifyContent: "end", marginTop: "4px" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => (window.location.href = "/")}
                >
                  Reload
                </Button>
              </Stack>
            </Stack>
          </Alert>
        </Stack>
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
