import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";

import authorizationFormInfo from "./fragments/authorizationFormInfo";
import detailedAuthorizationFormInfo from "./fragments/detailedAuthorizationFormInfo";
import { withDefaultProps } from "./utils";
import { GET_AUTHORIZATION_QUERY_NAME } from "./Authorization";

export const WITH_FORMS_QUERY = "getPossibleForms";
export const withForms = graphql(
  gql`
    query getPossibleForms(
      $jCode: String
      $state: String
      $insuranceType: String
      $insuranceCompanyId: Int!
      $NDC: String
      $benefitType: String
    ) {
      getPossibleForms(
        jCode: $jCode
        state: $state
        insuranceType: $insuranceType
        insuranceCompanyId: $insuranceCompanyId
        NDC: $NDC
        benefitType: $benefitType
      ) {
        suggestedForms {
          ...authorizationFormInfo
        }
        stateForms {
          ...authorizationFormInfo
        }
        payerForms {
          ...authorizationFormInfo
        }
        favoriteForms {
          ...authorizationFormInfo
        }
        otherStateForms {
          ...authorizationFormInfo
        }
        otherPayerForms {
          ...authorizationFormInfo
        }
        suggestedParentForms {
          ...authorizationFormInfo
        }
        hasForms
      }
    }
    ${authorizationFormInfo}
  `,
  {
    options: (props: {
      state: string;
      insuranceCompanyId: string;
      insuranceType: string;
      jCode: string;
      NDC: string;
      benefitType: string;
    }) => ({
      variables: {
        state: props.state,
        insuranceCompanyId: props.insuranceCompanyId,
        insuranceType: props.insuranceType,
        jCode: props.jCode,
        NDC: props.NDC,
        benefitType: props.benefitType,
      },
      fetchPolicy: "network-only",
    }),
    skip: (props) => !props.insuranceCompanyId,
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      forms: _.get(data, "getPossibleForms"),
    })),
  }
);

export const WITH_AVAILABLE_FORMS_QUERY = "retrieveAvailableForms";
export const withAvailableForms = graphql(
  gql`
    query retrieveAvailableForms(
      $jCode: String
      $state: String
      $insuranceType: String
      $insuranceCompanyId: Int!
      $NDC: String
      $benefitType: String
    ) {
      retrieveAvailableForms(
        jCode: $jCode
        state: $state
        insuranceType: $insuranceType
        insuranceCompanyId: $insuranceCompanyId
        NDC: $NDC
        benefitType: $benefitType
      ) {
        favoriteForms {
          ...authorizationFormInfo
        }
        payerForms {
          ...authorizationFormInfo
        }
        hasForms
      }
    }
    ${authorizationFormInfo}
  `,
  {
    options: (props: {
      state: string;
      insuranceCompanyId: string;
      insuranceType: string;
      jCode: string;
      NDC: string;
      benefitType: string;
    }) => ({
      variables: {
        state: props.state,
        insuranceCompanyId: props.insuranceCompanyId,
        insuranceType: props.insuranceType,
        jCode: props.jCode,
        NDC: props.NDC,
        benefitType: props.benefitType,
      },
      fetchPolicy: "cache-first",
    }),
    skip: (props) => !props.insuranceCompanyId,
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      forms: _.get(data, "retrieveAvailableForms"),
    })),
  }
);

export const withFormById = graphql(
  gql`
    query formById($id: Int!) {
      formById(id: $id) {
        ...authorizationFormInfo
      }
    }
    ${authorizationFormInfo}
  `,
  {
    options: (props) => ({ variables: { id: props.authorization.formId } }),
    skip: (props: { authorization: { formId: string } }) =>
      !_.get(props.authorization, "formId"),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    props: withDefaultProps(({ data }) => ({ form: _.get(data, "formById") })),
  }
);

export const reportMissingInformationMutation = gql`
  mutation reportMissingInformation(
    $type: String!
    $authorizationId: Int!
    $insuranceCompanyId: Int
    $state: String
    $details: String
    $fileKey: String
  ) {
    reportMissingInformation(
      type: $type
      authorizationId: $authorizationId
      insuranceCompanyId: $insuranceCompanyId
      state: $state
      details: $details
      fileKey: $fileKey
    )
  }
`;

export const withReportMissingInformation = graphql(
  reportMissingInformationMutation,
  { name: "reportMissingInformation" }
);

export const formsFindActiveQuery = gql`
  query formsFindActiveQuery {
    authorizationForms(filters: { isArchived: false }) {
      edges {
        node {
          ...authorizationFormInfo
        }
      }
    }
  }
  ${authorizationFormInfo}
`;

export const DETAILED_FORMS_QUERY = "withDetailedForms";
export const detailedFormsQuery = gql`
  query withDetailedForms($filters: AuthorizationFormsFilters) {
    authorizationForms(filters: $filters) {
      edges {
        node {
          ...detailedAuthorizationFormInfo
        }
      }
    }
  }
  ${detailedAuthorizationFormInfo}
`;

export const withDetailedForms = graphql(detailedFormsQuery, {
  options: { fetchPolicy: "network-only" },
  props: withDefaultProps(({ data }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const edges = _.get(data, "authorizationForms.edges", []);
    return {
      formsLoading: data?.loading,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      forms: edges.map((edge: any) => edge.node),
    };
  }),
});

export const withUpsertForm = graphql(
  gql`
    mutation upsertForm(
      $formId: Int
      $metadata: JSON
      $specification: JSON
      $title: String
      $description: String
      $imgKeys: [String]
      $pdfKey: String
    ) {
      upsertForm(
        formId: $formId
        metadata: $metadata
        specification: $specification
        title: $title
        description: $description
        imgKeys: $imgKeys
        pdfKey: $pdfKey
      ) {
        ...authorizationFormInfo
      }
    }
    ${authorizationFormInfo}
  `,
  {
    name: "upsertForm",
  }
);

const withUpdateAuthorizationFormMutation = gql`
  mutation updateAuthorizationForm(
    $id: Int!
    $patch: AuthorizationFormUpdatePatch
  ) {
    updateAuthorizationFormById(id: $id, patch: $patch) {
      ...detailedAuthorizationFormInfo
    }
  }
  ${detailedAuthorizationFormInfo}
`;
export const withUpdateAuthorizationForm = graphql(
  withUpdateAuthorizationFormMutation,
  {
    name: "updateAuthorizationForm",
  }
);

export const withAssociateForm = graphql(
  gql`
    mutation associateAuthorizationForm(
      $id: Int!
      $formNumbers: [Int]
      $insuranceCompanies: [Int]
    ) {
      associateAuthorizationForm(
        id: $id
        formNumbers: $formNumbers
        insuranceCompanies: $insuranceCompanies
      ) {
        ...detailedAuthorizationFormInfo
      }
    }
    ${detailedAuthorizationFormInfo}
  `,
  {
    name: "associateForm",
  }
);

export const withDeleteAuthorizationForm = graphql(
  gql`
    mutation deleteAuthorizationForm($id: Int!) {
      deleteAuthorizationForm(id: $id)
    }
  `,
  {
    name: "deleteAuthorizationForm",
    options: () => ({ refetchQueries: () => [DETAILED_FORMS_QUERY] }),
  }
);

export const createAuthorizationSpecificFormMutation = gql`
  mutation createAuthorizationSpecificForm(
    $authorizationId: Int!
    $fileKey: String!
  ) {
    createAuthorizationSpecificForm(
      authorizationId: $authorizationId
      fileKey: $fileKey
    ) {
      success
    }
  }
`;

export const withCreateAndAssociateAuthForm = graphql(
  createAuthorizationSpecificFormMutation,
  {
    name: "createAuthorizationSpecificForm",
    options: () => ({
      refetchQueries: () => [GET_AUTHORIZATION_QUERY_NAME],
    }),
  }
);
