import { useState } from "react";
import { useAlert } from "react-alert";
import { createAuthorizationCorrespondenceMutation } from "../../../graphql/AuthorizationCorrespondence";
import {
  ALL_AUTHORIZATIONS_QUERY_NAME,
  AUTHORIZATION_PAGINATION_QUERY_NAME,
  GET_AUTHORIZATION_QUERY_NAME,
} from "../../../graphql/Authorization";
import { Button } from "@samacare/design";
import {
  Authorization,
  Mutation,
  MutationCreateAuthorizationCorrespondenceArgs,
} from "@samacare/graphql";
import { useConfig } from "@@hooks/config";
import { useMutation } from "@apollo/client";
import { UploadModal } from "@@components/UploadModal";
import { AxiosResponse } from "axios";
import { GET_AUTHORIZATION_LOGS_QUERY_NAME } from "@@hooks/useAuthorizationLogs";

export const AttachFileBtn: React.VFC<{
  authorization: Authorization;
}> = ({ authorization }) => {
  const [showAttachModal, setShowAttachModal] = useState(false);
  const { CONSTANTS } = useConfig();
  const alert = useAlert();

  const { AUTHORIZATION_CORRESPONDENCE_TYPES } = CONSTANTS;

  const [createAuthorizationCorrespondence] = useMutation<
    Mutation,
    MutationCreateAuthorizationCorrespondenceArgs
  >(createAuthorizationCorrespondenceMutation, {
    refetchQueries: [
      ALL_AUTHORIZATIONS_QUERY_NAME,
      AUTHORIZATION_PAGINATION_QUERY_NAME,
      GET_AUTHORIZATION_QUERY_NAME,
      GET_AUTHORIZATION_LOGS_QUERY_NAME,
    ],
  });

  const onDropSuccess = async (_p: null, res: AxiosResponse<any>) => {
    try {
      await createAuthorizationCorrespondence({
        variables: {
          authorizationId: parseInt(authorization.id),
          fileId: res.data.id,
          type: AUTHORIZATION_CORRESPONDENCE_TYPES.RESPONSE,
          userUploaded: true,
        },
      });

      alert.info("Successfully uploaded new correspondence");
      setShowAttachModal(false);
    } catch (e) {
      alert.error("There was an error uploading the document.");
    }
  };

  const onDropError = () => {
    alert.error("There was an error uploading the document.");
  };

  return (
    <>
      <Button
        sx={{ height: ({ spacing }) => spacing(4) }}
        onClick={() => setShowAttachModal(true)}
        variant="contained"
      >
        Attach File
      </Button>

      <UploadModal
        uploadWarning
        onClose={() => setShowAttachModal(false)}
        open={showAttachModal}
        onDropError={onDropError}
        onDropSuccess={onDropSuccess}
      />
    </>
  );
};
