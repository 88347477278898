import { Link, Typography } from "@samacare/design/core";
import { useTheme } from "styled-components";

export const LearnMoreText: React.FC = () => {
  const theme = useTheme();

  const copilotFaqLink =
    "https://samacare-help.freshdesk.com/support/solutions/articles/72000630963-samacare-copilot";
  return (
    <Typography
      sx={{
        fontSize: "12px",
        color: theme.gray,
        textAlign: "left",
        marginTop: 2.5,
        marginLeft: 1.25,
      }}
    >
      Learn more{" "}
      <Link
        href={copilotFaqLink}
        style={{ color: theme.darkGray, fontWeight: "bold" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        CoPilot FAQ
      </Link>
    </Typography>
  );
};
