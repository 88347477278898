import styled from "styled-components";

import LoadingSpinner from "./LoadingSpinner";
import BaseButton from "./BaseButton";

const Button = styled(BaseButton)`
  border-color: ${(props) =>
    props.disabled ? props.theme.lightGray : props.theme.purple};
  background: ${(props) =>
    props.disabled ? props.theme.lightGray : props.theme.purple};
  color: white;
  display: flex;
  flex-direction: row;

  &:hover {
    cursor: ${(props) => (props.disabled ? "" : "pointer")};
    color: ${(props) =>
      props.disabled ? "white" : props.color || props.theme.purple};
    background: ${(props) =>
      props.disabled ? props.theme.lightGray : "white"};

    ${LoadingSpinner} {
      border-top-color: ${(props) =>
        props.disabled ? "white" : props.color || props.theme.purple};
    }
  }
`;

const LoadingButton = ({
  children,
  loading,
  disabled = false,
  onClick,
  style = {},
  cypressTag,
  type,
}) => (
  <Button
    data-cy={cypressTag}
    disabled={disabled || loading}
    onClick={() => {
      if (!(disabled || loading)) {
        if (onClick) {
          onClick();
        }
      }
    }}
    style={style}
    type={type ?? "button"}
  >
    {loading && <LoadingSpinner />}
    {children}
  </Button>
);

/**
 * @deprecated Use a MUI component instead
 */
export default LoadingButton;
