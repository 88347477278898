import * as React from "react";
import styled from "styled-components";

import { ModalBody } from "./ModalStyledComponents";

const Pic = styled.img`
  height: 120px;
  width: 150px;
  margin-bottom: 20px;
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div<{
  textColor?: string;
}>`
  color: ${(props) => props.textColor ?? props.theme.primary};
  justify-content: center;
  font-weight: 700;
  margin-top: 10px;
`;

export const ConfirmationModalContent: React.FunctionComponent<{
  messages: string[];
  textColor?: string | undefined;
}> = ({ messages, textColor }) => (
  <ModalBody style={{ width: "375px" }}>
    <MessageContainer>
      <Pic src="../../../assets/requestNewPayer.png" />
      {messages.map((message) => (
        <TextContainer key={message} textColor={textColor}>
          {message}
        </TextContainer>
      ))}
    </MessageContainer>
  </ModalBody>
);

/**
 * @deprecated Use a MUI component instead
 */
export default ConfirmationModalContent;
