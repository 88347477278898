import _ from "lodash";

export default (config) =>
  _.reduce(
    config,
    (trimmed, val, key) => ({
      ...trimmed,
      [key]: _.isString(val) ? val.trim() : val,
    }),
    {}
  );
