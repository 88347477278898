import React from "react";
import { Typography, Button } from "@samacare/design/core";
import { SxProps, useTheme } from "@samacare/design/core/styles";

interface PortalMessageProps {
  prefixText: string;
  fontSize?: string;
  color?: string;
  buttonText?: string;
  suffixText?: string;
  onButtonClick?: () => void;
  sx?: SxProps;
}

const PortalMessage: React.FC<PortalMessageProps> = ({
  prefixText,
  fontSize = "14px",
  color = "black",
  buttonText = "new tab",
  suffixText,
  onButtonClick,
  sx = {},
}) => {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        fontSize,
        color,
        lineHeight: "1.2",
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      {prefixText}{" "}
      <Button
        onClick={onButtonClick}
        sx={{
          px: 0.5,
          m: 0,
          fontSize,
          fontWeight: "normal",
          color: theme.palette.primary.light,
          textDecoration: "underline",
          minWidth: "auto",
          lineHeight: "1.2",
          verticalAlign: "middle",
          "&:hover": {
            backgroundColor: "transparent",
          },
          cursor: "pointer",
        }}
      >
        {buttonText}
      </Button>{" "}
      {suffixText}
    </Typography>
  );
};

export default PortalMessage;
