import { PureComponent } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import { BaseText } from "Segment/StyledComponents";
import RequiredTag from "../../../components/RequiredTag";
import { withReportMissingInformation } from "../../../graphql/AuthorizationForm";
import BaseButton from "../../../components/BaseButton";
import { ModalBodyLarge } from "../../../components/ModalStyledComponents";
import strings from "Resources/strings";

const Title = styled.div`
  font-weight: 700;
  margin: 9px 4px 7px 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const InfoText = styled.div`
  text-align: center;
  width: 100%;
  margin: 10px;
`;

const FooterButton = styled(BaseButton)`
  margin-left: 10px;
`;

const WideButton = styled(BaseButton)`
  width: 100%;
`;

const { MISSING_INFORMATION_TYPES } = CONFIG.CONSTANTS;
class MissingInformationModalContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hcpcsCode: props.defaultValue || "",
      name: "",
      submitted: false,
    };
  }

  getError = () => {
    const { hcpcsCode, name } = this.state;

    if (!hcpcsCode || hcpcsCode.length !== 5) {
      return "HCPCS Code must be exactly 5 characters in length";
    }

    if (!name) {
      return "Drug name must be provided";
    }

    return null;
  };

  setAndClose = () => {
    const { alert, close, set } = this.props;
    const { hcpcsCode, name } = this.state;

    const error = this.getError();
    if (error) {
      alert.error(error);
      return;
    }

    set(null, name, hcpcsCode);
    close();
  };

  setAndSubmit = async () => {
    const { reportMissingInformation, set, alert, authorizationId } =
      this.props;
    const { hcpcsCode, name } = this.state;

    const error = this.getError();
    if (error) {
      alert.error(error);
      return;
    }

    try {
      await reportMissingInformation({
        variables: {
          type: MISSING_INFORMATION_TYPES.DRUG_OPTION,
          details: `Please add drug option with HCPCSCode[${hcpcsCode}] and name[${name}]`,
          authorizationId,
        },
      });
      set(null, name, hcpcsCode);
      this.setState({ submitted: true });
    } catch (e) {
      alert.error(strings.MISSING_INFORMATION.ERROR);
    }
  };

  render() {
    const { close, authorizationId } = this.props;
    const { hcpcsCode, name, submitted } = this.state;

    if (submitted) {
      return (
        <div>
          <ModalBodyLarge>
            <InfoText>
              We have added this service to your authorization and a request has
              been sent to SamaCare.
            </InfoText>
            <InfoText>
              SamaCare will verify your service details. If approved, we will
              typically add it to the system within 72 business hours.
            </InfoText>
          </ModalBodyLarge>
          <ButtonContainer>
            <WideButton onClick={close}>Confirm</WideButton>
          </ButtonContainer>
        </div>
      );
    }

    return (
      <div>
        <ModalBodyLarge>
          <Title>
            HCPCS Code
            <RequiredTag />
          </Title>
          <BaseText
            value={hcpcsCode}
            onChange={(e) => {
              this.setState({ hcpcsCode: e.target.value });
            }}
          />
          <Title>
            Service Name
            <RequiredTag />
          </Title>
          <BaseText
            data-cy="fieldAddDrugModalServiceName"
            value={name}
            onChange={(e) => {
              this.setState({ name: e.target.value });
            }}
          />
        </ModalBodyLarge>
        <ButtonContainer>
          {!!authorizationId && (
            <FooterButton onClick={this.setAndSubmit}>
              Use this service and add to SamaCare
            </FooterButton>
          )}
          <FooterButton
            data-cy="actionAddDrugModalSubmit"
            onClick={this.setAndClose}
          >
            Use this service
          </FooterButton>
        </ButtonContainer>
      </div>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withReportMissingInformation)(
  withAlert()(MissingInformationModalContent)
);
