export interface S3UploadInfo {
  Policy: string;
  "X-Amz-Algorithm": string;
  "X-Amz-Credential": string;
  "X-Amz-Date": string;
  "X-Amz-Signature": string;
  "content-disposition": string;
  "content-type": string;
  bucket: string;
  key: string;
}

export const prepareS3UploadData = (
  file: File,
  s3UploadInfo: S3UploadInfo
): FormData => {
  const fileUploadData = new FormData();
  Object.entries(s3UploadInfo).forEach(([key, value]) => {
    fileUploadData.append(key, String(value));
  });
  fileUploadData.append("file", file); // file field must be last
  return fileUploadData;
};
