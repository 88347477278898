import { createContext, useContext, useMemo } from "react";
import { useHistory, useRouteMatch, generatePath } from "react-router-dom";

import { EnrollmentRoutes } from "./Enrollment.types";
import { useEnrollmentContext } from "./EnrollmentProvider";

import * as React from "react";

type IStepContext = {
  active: EnrollmentRoutes;
  next?: EnrollmentRoutes | false;
  previous?: EnrollmentRoutes | false;
  onNextStep: (stringParam?: string) => void;
  onPreviousStep: () => void;
};

const StepContext = createContext<IStepContext>({
  active: EnrollmentRoutes.program,
  onNextStep: () => {},
  onPreviousStep: () => {},
});

const getCurrentView = (
  isSubmitted: boolean,
  view: EnrollmentRoutes
): EnrollmentRoutes => {
  if (isSubmitted) {
    return EnrollmentRoutes.summary;
  }

  return view;
};

function getNextStep(view: EnrollmentRoutes) {
  switch (view) {
    case EnrollmentRoutes.program:
      return EnrollmentRoutes.details;
    case EnrollmentRoutes.details:
      return EnrollmentRoutes.submission;
    case EnrollmentRoutes.submission:
      return EnrollmentRoutes.summary;
    default:
      return false;
  }
}

function getPreviousStep(view: EnrollmentRoutes) {
  switch (view) {
    case EnrollmentRoutes.summary:
      return EnrollmentRoutes.submission;
    case EnrollmentRoutes.submission:
      return EnrollmentRoutes.details;
    case EnrollmentRoutes.details:
      return EnrollmentRoutes.program;
    default:
      return false;
  }
}

function getEnrollmentPath(
  path: string,
  search: URLSearchParams,
  view: EnrollmentRoutes,
  enrollmentId?: string
) {
  const searchParams = search.toString();
  const pathname = generatePath(path, { view, enrollmentId });
  return searchParams.length > 0 ? `${pathname}?${searchParams}` : pathname;
}

export const StepProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const {
    path,
    params: { view, enrollmentId },
  } = useRouteMatch<{ view: EnrollmentRoutes; enrollmentId?: string }>();

  const { enrollment, isSubmitted } = useEnrollmentContext();

  const value: IStepContext = useMemo(() => {
    const search = new URLSearchParams(history.location.search);

    const next = getNextStep(view);
    const previous = getPreviousStep(view);
    const active = getCurrentView(isSubmitted ?? false, view);

    return {
      view,
      next,
      active,
      previous,
      onNextStep(searchParams?: string) {
        if (next) {
          const pathname = getEnrollmentPath(path, search, next, enrollmentId);
          history.push(searchParams ? pathname + searchParams : pathname);
        }
      },
      onPreviousStep() {
        if (previous) {
          history.push(getEnrollmentPath(path, search, previous, enrollmentId));
        } else {
          history.push("/enrollments");
        }
      },
    };
  }, [enrollment, path, view, enrollmentId, history]);

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};

export const useStepContext = (): IStepContext =>
  useContext<IStepContext>(StepContext);
