import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";

import formNumberInfo from "./fragments/formNumberInfo";
import faxFilterInfo from "./fragments/faxFilterInfo";
import authorizationFormInfo from "./fragments/authorizationFormInfo";
import insuranceCompanyInfo from "./fragments/insuranceCompanyInfo";
import { withDefaultProps } from "./utils";

export const POSSIBLE_NUMBERS_QUERY = "getPossibleNumbers";
export const withPossibleFormNumbers = graphql(
  gql`
    query getPossibleNumbers(
      $authorizationFormId: Int!
      $insuranceCompanyId: Int!
      $state: String
    ) {
      getPossibleNumbers(
        authorizationFormId: $authorizationFormId
        insuranceCompanyId: $insuranceCompanyId
        state: $state
      ) {
        suggestedNumbers {
          ...formNumberInfo
        }
        remainingNumbers {
          ...formNumberInfo
        }
        favoriteNumbers {
          ...formNumberInfo
        }
        hasNumbers
      }
    }
    ${formNumberInfo}
  `,
  {
    options: (props: {
      authorizationFormId: string;
      insuranceCompanyId: string;
      state: string;
    }) => ({
      variables: {
        authorizationFormId: props.authorizationFormId,
        insuranceCompanyId: props.insuranceCompanyId,
        state: props.state,
      },
    }),
    skip: (props) => !props.insuranceCompanyId || !props.authorizationFormId,
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      numbers: _.get(data, "getPossibleNumbers"),
    })),
  }
);

export const EXISTING_FORM_NUMBERS_QUERY = "getExistingFormNumbers";
export const withExistingFormNumbers = graphql(
  gql`
    query getExistingFormNumbers($authorizationFormId: Int!) {
      getExistingFormNumbers(authorizationFormId: $authorizationFormId) {
        verified {
          ...formNumberInfo
        }
        favorite {
          ...formNumberInfo
        }
        custom {
          ...formNumberInfo
        }
      }
    }
    ${formNumberInfo}
  `,
  {
    options: (props: { authorizationFormId: string }) => ({
      variables: {
        authorizationFormId: props.authorizationFormId,
      },
    }),
    skip: (props) => !props.authorizationFormId,
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      numbers: _.get(data, "getExistingFormNumbers"),
    })),
  }
);

export const FORM_NUMBERS_QUERY = "formNumbersFindAll";
export const formNumbersFindAllQuery = gql`
  query formNumbersFindAll($number: String, $description: String) {
    formNumbersFindAll(number: $number, description: $description) {
      ...formNumberInfo
      InstitutionId
      associatedForms {
        ...authorizationFormInfo
      }
      associatedCompanies {
        ...insuranceCompanyInfo
      }
      faxFilter {
        ...faxFilterInfo
      }
    }
  }
  ${formNumberInfo}
  ${authorizationFormInfo}
  ${insuranceCompanyInfo}
  ${faxFilterInfo}
`;

export const withCreateFormNumber = graphql(
  gql`
    mutation createFormNumber(
      $number: String!
      $description: String!
      $insuranceCompanyId: Int
      $createWithoutInstitution: Boolean
      $formId: Int
    ) {
      createFormNumber(
        number: $number
        description: $description
        insuranceCompanyId: $insuranceCompanyId
        createWithoutInstitution: $createWithoutInstitution
        formId: $formId
      ) {
        ...formNumberInfo
      }
    }
    ${formNumberInfo}
  `,
  {
    name: "createFormNumber",
    options: () => ({
      refetchQueries: () => [
        POSSIBLE_NUMBERS_QUERY,
        FORM_NUMBERS_QUERY,
        EXISTING_FORM_NUMBERS_QUERY,
      ],
    }),
  }
);

export const getForbiddenFormNumberQuery = gql`
  query getForbiddenFormNumber($number: String!) {
    getForbiddenFormNumber(number: $number) {
      ...formNumberInfo
    }
  }
  ${formNumberInfo}
`;

export const withUpdateFormNumber = graphql(
  gql`
    mutation updateFormNumber($id: Int!, $patch: JSON!) {
      updateFormNumber(id: $id, patch: $patch) {
        ...formNumberInfo
      }
    }
    ${formNumberInfo}
  `,
  {
    name: "updateFormNumber",
    options: () => ({ refetchQueries: () => [FORM_NUMBERS_QUERY] }),
  }
);

export const withDeleteFormNumber = graphql(
  gql`
    mutation deleteFormNumber($id: Int!) {
      deleteFormNumber(id: $id)
    }
  `,
  {
    name: "deleteFormNumber",
    options: () => ({
      refetchQueries: () => [FORM_NUMBERS_QUERY, POSSIBLE_NUMBERS_QUERY],
    }),
  }
);

export const searchFormNumbersQuery = gql`
  query searchFormNumbers($partialNumber: String!) {
    searchFormNumbers(partialNumber: $partialNumber) {
      ...formNumberInfo
    }
  }
  ${formNumberInfo}
`;
