import _ from "lodash";
import colors from "Resources/colors";
import PropTypes from "prop-types";

const shared = {
  background: PropTypes.oneOf(
    _.map(CONFIG.CONSTANTS.INPUT_IMPORTANCE, "color").concat(
      colors.disabledGray
    )
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  importance: PropTypes.oneOf(_.map(CONFIG.CONSTANTS.INPUT_IMPORTANCE, "key"))
    .isRequired,
  isDisplayHidden: PropTypes.bool.isRequired,
  left: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  samaTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabIndex: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  siblingId: PropTypes.number,
};

export const genericInput = PropTypes.exact({
  ...shared,
  value: PropTypes.string,
});

export const checkInput = PropTypes.exact({
  ...shared,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
});
