import {
  Authorization,
  CapturesPatientConsent,
  CaremetxEnrollmentDisplayStatus,
} from "@samacare/graphql";

export const isDisplayAuthEnrollment = (
  authorization: Authorization
): boolean => {
  if (authorization == null) {
    return false;
  }

  return (
    authorization.supportsEnrollment ||
    (authorization.type ===
      window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.FORM.key &&
      authorization.caremetxEnrollmentDisplayStatus ===
        CaremetxEnrollmentDisplayStatus.DisplayAndEnable &&
      authorization.institution?.capturesPatientConsent !==
        CapturesPatientConsent.No)
  );
};
export const requireAuthEnrollmentRequiredFields = (
  authorization: Authorization
): boolean =>
  authorization.type === window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.FORM.key &&
  authorization.caremetxEnrollmentDisplayStatus !==
    CaremetxEnrollmentDisplayStatus.Hide &&
  authorization.institution?.capturesPatientConsent !==
    CapturesPatientConsent.No;
