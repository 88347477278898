import { IngestJob } from "@samacare/graphql";
import { ColumnErrorCode, columnErrorTextByCode } from "./ColumnErrorCode";

const errorTextByErrorCode: Record<string, string> = {
  required: "Required",
  invalid_date: "Invalid",
  invalid_state: "Invalid",
  invalid_phone: "Invalid",
  invalid_zip: "Invalid",
  invalid_sex: "Invalid",
  invalid_name: "Invalid",
  duplicate_mrn: "Duplicate",
};

const columnErrorCodeByErrorCode: Record<string, ColumnErrorCode> = {
  required: ColumnErrorCode.Required,
  invalid_date: ColumnErrorCode.InvalidDate,
  invalid_state: ColumnErrorCode.InvalidState,
  invalid_phone: ColumnErrorCode.InvalidPhone,
  invalid_zip: ColumnErrorCode.InvalidZip,
  invalid_sex: ColumnErrorCode.InvalidSex,
  invalid_name: ColumnErrorCode.InvalidName,
  duplicate_mrn: ColumnErrorCode.DuplicateMrn,
};

export const extractErrors = (row: Record<string, string> | null) => {
  const errorByColumn: Record<string, string> = {};
  const tooltipTextByColumn: Record<string, string> = {};
  let restOfRow: Record<string, string> = {};
  let isGenericError = false;

  if (row) {
    const { error_codes: rawErrorCodes, ...rest } = row;
    restOfRow = rest;
    const ecPairs = rawErrorCodes?.split("..");

    if (ecPairs) {
      for (const ecPair of ecPairs) {
        const [column, errorCode] = ecPair.split(".");
        errorByColumn[column] = errorTextByErrorCode[errorCode];
        tooltipTextByColumn[column] =
          columnErrorTextByCode[columnErrorCodeByErrorCode[errorCode]];
      }
    } else {
      isGenericError = true;
    }
  }

  return { errorByColumn, tooltipTextByColumn, restOfRow, isGenericError };
};

export const getFailureCount = (ingestJob: IngestJob) =>
  (ingestJob.failedValidationCount ?? 0) +
  (ingestJob.failedLoadCount ?? 0) +
  (ingestJob.duplicateCount ?? 0);
