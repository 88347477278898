import _ from "lodash";
import { Query } from "@apollo/client/react/components";
import { compose } from "recompose";
import { createRef, PureComponent } from "react";
import { TiArrowSortedDown as DownCaret } from "@react-icons/all-files/ti/TiArrowSortedDown";
import { TiArrowSortedUp as UpCaret } from "@react-icons/all-files/ti/TiArrowSortedUp";
import { MdSwapHoriz as SwapIcon } from "@react-icons/all-files/md/MdSwapHoriz";
import { withAlert } from "react-alert";

import styled, { css } from "styled-components";
import { BaseText } from "Segment/StyledComponents";

import Note from "./Note";
import { withDeleteAuthorization } from "../../graphql/Authorization";
import {
  withAuthorizationNotesQuery,
  withCreateAuthorizationNote,
} from "../../graphql/Note";
import BaseButton from "../BaseButton";

const Container = styled.div`
  width: 100%;
  min-width: 250px;
`;

const activeStyles = css`
  background: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const NoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 600px;
  padding: 5px;
  border-bottom: 1px solid
    ${(props) => (props.expanded ? props.theme.purple : props.theme.darkGray)};
  cursor: pointer;

  ${(props) => (props.expanded ? `${activeStyles}` : "")}

  &:hover {
    background: white;
    border-bottom: 1px solid ${(props) => props.theme.purple};
  }
`;

const EmptyNotes = styled.div`
  text-align: center;
  padding: 20px 20px 10px 20px;
  color: ${(props) => props.theme.darkGray};
`;

const NoteSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
  padding: 5px;
`;

const AddOrViewNotes = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  color: ${(props) => props.theme.purple};
  margin: auto 0 auto auto;
`;

const AddNoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${BaseText} {
    flex: 1;
  }
  ${BaseButton} {
    width: 50px;
    margin-left: 5px;
  }
`;

const AdditionalNotes = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  max-height: 200px;
  top: auto;
  border: 1px solid ${(props) => props.theme.purple};
  border-top: 0;
  background: white;
  padding: 10px 10px 0 10px;
  overflow: scroll;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  z-index: ${(props) => props.theme.zLow};

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const HideNotesButton = styled(BaseButton)`
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 5px;
`;

class AuthorizationNotes extends PureComponent {
  wrapperRef = createRef();

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (
      this.wrapperRef?.current &&
      !this.wrapperRef.current.contains(event.target)
    )
      this.setState({
        expanded: false,
        typing: false,
      });
  };

  state = {
    expanded: false,
    width: null,
    newNote: "",
    newLatestNote: "",
    typing: false,
  };

  ref = null;

  noteRef = null;

  toggleOpen = (e) => {
    if (!(this.noteRef && this.noteRef.contains(e.target))) {
      e.stopPropagation();
      const bounding = this.ref.getBoundingClientRect();

      this.setState({
        expanded: true,
        width: bounding.width,
        newNote: "",
      });
    }
  };

  toggleClose = (e) => {
    e.stopPropagation();
    this.setState({ expanded: false, newNote: "" });
  };

  componentDidUpdate(prevProps, prevState) {
    // add listener if expanded changes to true
    if (!prevState.expanded && this.state.expanded)
      document.addEventListener("mousedown", this.handleClickOutside);

    // remove listener if expanded changes to false
    if (prevState.expanded && !this.state.expanded)
      document.removeEventListener("mousedown", this.handleClickOutside);
  }

  submitNewNote = async () => {
    const { createAuthorizationNote, authorizationId, alert } = this.props;
    const { newNote } = this.state;

    try {
      const { data } = await createAuthorizationNote({
        variables: { note: newNote, authorizationId },
      });
      this.setState({
        newNote: "",
        newLatestNote: data.createAuthorizationNote,
      });
    } catch (e) {
      alert.error("Error adding new note");
    }
  };

  submitOnEnter = (e) => {
    if (e.key === "Enter") {
      this.submitNewNote();
    }
  };

  updateNewNote = (e) => {
    this.setState({ newNote: e.target.value });
  };

  render() {
    const { latestNote, authorizationId, hideNotes, deleteAuthorization } =
      this.props;
    const { expanded, width, newNote, newLatestNote } = this.state;

    return (
      <Container
        ref={this.wrapperRef}
        onMouseEnter={this.toggleOpen}
        onMouseLeave={(e) => {
          if (!this.state.typing) this.toggleClose(e);
        }}
        onClick={(e) => {
          this.toggleOpen(e);
        }}
      >
        <NoteContainer ref={(ref) => (this.ref = ref)} expanded={expanded}>
          {(latestNote || newLatestNote) && (
            <Note note={newLatestNote || latestNote} />
          )}
          {!latestNote && !newLatestNote && (
            <NoteSubContainer>
              No activity with this authorization
            </NoteSubContainer>
          )}
          <AddOrViewNotes>
            {hideNotes && (
              <HideNotesButton onClick={hideNotes}>
                <SwapIcon />
              </HideNotesButton>
            )}
            {expanded ? (
              <div onClick={this.toggleClose}>
                <UpCaret />
              </div>
            ) : (
              <DownCaret />
            )}
          </AddOrViewNotes>
        </NoteContainer>
        {expanded && (
          <AdditionalNotes
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={(ref) => (this.noteRef = ref)}
            width={width}
          >
            <AddNoteContainer>
              <BaseText
                placeholder="Type and press enter to add note"
                onKeyPress={this.submitOnEnter}
                onChange={this.updateNewNote}
                onClick={(e) => {
                  e.stopPropagation();
                  const { typing } = this.state;
                  this.setState({ typing: !typing });
                }}
                value={newNote}
              />
              <BaseButton
                disabled={!newNote}
                onClick={() => {
                  this.submitNewNote();
                }}
                style={{
                  width: "100px",
                }}
              >
                Add
              </BaseButton>
            </AddNoteContainer>
            <Query
              query={withAuthorizationNotesQuery}
              variables={{ authorizationId }}
            >
              {({ loading, data, error }) => {
                if (loading || !data) {
                  return <div>Loading</div>;
                }
                if (error) {
                  return <div>Failed to load notes.</div>;
                }
                const additionalNotes = _.filter(
                  data.getAuthorizationNotes,
                  (note) => note?.id !== (newLatestNote || latestNote)?.id
                );

                if (_.isEmpty(additionalNotes)) {
                  return <EmptyNotes>No additional notes</EmptyNotes>;
                }

                return _.map(additionalNotes, (note) => (
                  <Note key={`note_sub_container_${note.id}`} note={note} />
                ));
              }}
            </Query>
            {CONFIG.NODE_ENV === "demo" && (
              <BaseButton
                onClick={() => {
                  deleteAuthorization({ variables: { id: authorizationId } });
                }}
              >
                Delete This Authorization
              </BaseButton>
            )}
          </AdditionalNotes>
        )}
      </Container>
    );
  }
}
/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withCreateAuthorizationNote,
  withDeleteAuthorization
)(withAlert()(AuthorizationNotes));
