import _ from "lodash";
import { useMemo } from "react";
import { useAlert } from "react-alert";

import {
  FindAllAccountsQuery,
  FindAllAccountsQueryVariables,
} from "@@generated/graphql";
import { gql, useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@samacare/design/core";
import { Account } from "@samacare/graphql";

const accountsQuery = gql`
  query findAllAccounts {
    accountsFindAll {
      id
      firstName
      lastName
      isSystemUser
      isSamaUser
    }
  }
`;

interface IAssigneeSelectProps {
  defaultValue: number | null;
  onChange: (value: number) => Promise<unknown>;
}

export const AssigneeSelect: React.FC<IAssigneeSelectProps> = (props) => {
  const { defaultValue, onChange } = props;
  const alert = useAlert();

  const { loading, data } = useQuery<
    FindAllAccountsQuery,
    FindAllAccountsQueryVariables
  >(accountsQuery);

  const accountOptions = useMemo(() => {
    const accountData = (data?.accountsFindAll ?? []) as Account[];
    const labelledData = accountData.map((acc) => ({
      id: parseInt(acc.id),
      label: acc?.isSystemUser
        ? "Unassigned"
        : `${acc?.firstName} ${acc?.lastName}`,
    }));
    return _.sortBy(labelledData, "label");
  }, [data]);

  const defaultSelected = useMemo(() => {
    return accountOptions.find((acc) => acc.id === defaultValue);
  }, [defaultValue, accountOptions]);

  if (loading) {
    return <div />;
  }

  return (
    <Autocomplete
      data-cy="controlSelectAssignee"
      disableClearable
      selectOnFocus
      handleHomeEndKeys
      autoHighlight
      onChange={async (event, value) => {
        value &&
          onChange(value?.id)
            .then(() => alert.info("Successfully reassigned!"))
            .catch((err) =>
              alert.error(err instanceof Error ? err.message : "Unknown error")
            );
      }}
      defaultValue={defaultSelected}
      options={accountOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label="Assignee"
        />
      )}
      sx={{
        width: 200,
      }}
    />
  );
};
