import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";

export const useFeatureFlag = <T,>(
  flag: string,
  defaultValue?: T
): T | undefined | null => {
  const ldClient = useLDClient();
  const flags = useFlags();

  if (!ldClient) return defaultValue ?? null; // Client hasn't been initialized yet

  if (flags[flag] === undefined) {
    return defaultValue;
  }

  return flags[flag] as T;
};
