import { PatientTreatmentsGrid } from "./PatientTreatmentsGrid";

import { PatientTreatmentsProvider } from "./PatientTreatmentsProvider";

export const PatientTreatments: React.FC = () => {
  return (
    <PatientTreatmentsProvider>
      <PatientTreatmentsGrid />
    </PatientTreatmentsProvider>
  );
};
