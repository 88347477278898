import { PureComponent } from "react";

import DownloadFormsModal from "./DownloadFormsModal";

class DownloadFormsButton extends PureComponent {
  state = { open: false };

  toggleModal = (e) => {
    e.stopPropagation();
    const { open } = this.state;

    this.setState({ open: !open });
  };

  render() {
    const { children, authorization } = this.props;
    const { open } = this.state;
    return (
      <div>
        <DownloadFormsModal
          header="Download Forms"
          onClick={this.toggleModal}
          open={open}
          authorizationId={authorization.id}
        />
        <div onClick={this.toggleModal}>{children}</div>
      </div>
    );
  }
}

/**
 * @deprecated Use a MUI component instead
 */
export default DownloadFormsButton;
