import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _ from "lodash";
import { Stack } from "@samacare/design";
import { AutocompleteField, TextField } from "@samacare/form2";
import { ServiceQuantityTypeCode } from "@samacare/graphql";
import { useFormContext } from "react-hook-form";
const toLabel = (value) => _.startCase(_.lowerCase(value));
const QuantityTypeOrder = [
    ServiceQuantityTypeCode.Units,
    ServiceQuantityTypeCode.Visits,
    ServiceQuantityTypeCode.Hours,
    ServiceQuantityTypeCode.Days,
    ServiceQuantityTypeCode.Months,
];
const ensurePositiveValue = (value) => {
    if (value === "")
        return "";
    const parsedValue = Number(value);
    if (!Number.isInteger(parsedValue) || parsedValue === 0) {
        return "1";
    }
    return parsedValue < 0
        ? Math.abs(parsedValue).toString()
        : parsedValue.toString();
};
export const QuantityAndType = ({ disabled, required, name, }) => {
    const { watch, setValue } = useFormContext();
    const quantityTypeTag = `${name}.quantityType`;
    const quantityType = watch(quantityTypeTag);
    const quantityTag = `${name}.quantity`;
    return (_jsxs(Stack, { direction: "row", spacing: 1, children: [_jsx(AutocompleteField, { disableClearable: true, getOptionLabel: (val) => toLabel(val), options: QuantityTypeOrder, label: "Quantity type", name: quantityTypeTag, required: required, disabled: disabled, value: quantityType !== null && quantityType !== void 0 ? quantityType : null, isOptionEqualToValue: (option, value) => option === value || value === "", "data-cy": "fieldHcpcsCodeQuantityType" }), _jsx(TextField, { name: `${name}.quantity`, label: "Quantity", placeholder: quantityType ? `Number of ${quantityType}` : "", required: required, disabled: disabled, fullWidth: true, type: "number", InputProps: { inputProps: { min: 1 } }, onChange: (e) => setValue(quantityTag, ensurePositiveValue(e.target.value)), "data-cy": "fieldHcpcsCodeQuantity" })] }));
};
