import moment from "moment";
import React from "react";

import {
  Box,
  Grid,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Typography,
} from "@samacare/design/core";

import { getInsuranceType } from "../../../util/getInsuranceType";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import { UnifiedBenefitsCheckCoverageStatus } from "./UnifiedBenefitsCheckCoverageStatus";
import { UnifiedBenefitsCheckStatus } from "./UnifiedBenefitsCheckStatus";

const formatPlanDate = (d?: string | null) => {
  if (d == null) {
    return "-";
  }
  return moment(d).format("MM/DD/YYYY");
};

export const BenefitsCheckLiteSummary: React.FC = () => {
  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();

  if (!unifiedBenefitsCheck) {
    return <Box />;
  }

  return (
    <TabContext value="summary">
      <TabList>
        <Tab label="Summary" value="summary" />
      </TabList>
      <TabPanel value="summary" sx={{ paddingX: 0 }}>
        <Grid container padding={0}>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle2">BV Lite Status</Typography>
              <UnifiedBenefitsCheckStatus />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle2">Coverage Status</Typography>
              <UnifiedBenefitsCheckCoverageStatus />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Start Date</Typography>
              <Typography variant="body1" color="primary">
                {formatPlanDate(
                  unifiedBenefitsCheck.liteData?.activePlan?.planStartDate ||
                    unifiedBenefitsCheck.liteData?.inactivePlan?.planStartDate
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Termination Date</Typography>
              <Typography variant="body1" color="primary">
                {formatPlanDate(
                  unifiedBenefitsCheck.liteData?.activePlan?.planEndDate ||
                    unifiedBenefitsCheck.liteData?.inactivePlan?.planEndDate
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Payer</Typography>
              <Typography variant="body1">
                {unifiedBenefitsCheck.insuranceCompanyData?.name || "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Plan Type</Typography>
              <Typography variant="body1">
                {getInsuranceType(
                  unifiedBenefitsCheck.insurancePolicyData?.planType
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Member ID</Typography>
              <Typography variant="body1">
                {unifiedBenefitsCheck.insurancePolicyData?.memberId || "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Insurance State</Typography>
              <Typography variant="body1">
                {unifiedBenefitsCheck.insurancePolicyData?.insuranceState ||
                  "-"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </TabContext>
  );
};
