import * as React from "react";

import RequiredTag from "../../components/RequiredTag";

const TextLabel: React.VFC<{
  keyPrefix: string;
  labelKey: string;
  labelText: string;
  requiredFields: string[];
}> = ({ keyPrefix, labelKey, labelText, requiredFields }) => {
  return (
    <div
      key={`${keyPrefix}-${labelKey}-edit-cell-label`}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "2px",
      }}
    >
      {labelText}
      {requiredFields.includes(labelKey) ? <RequiredTag /> : null}
    </div>
  );
};

export default TextLabel;
