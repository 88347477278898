import axios from "axios";
import { useCallback, useState } from "react";

import client from "../services/client";
import { S3UploadInfo, prepareS3UploadData } from "../util/s3Utils";

interface UploadInfo {
  id: number;
  url: string;
  fields: S3UploadInfo;
}

export const useFileUpload = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");

  const upload = useCallback(
    async (file: File) => {
      setFileName(file.name);
      setIsLoading(true);

      try {
        // get s3 upload info
        const uploadInfoResponse = await client.post<UploadInfo>("/upload", {
          name: file.name,
          contentType: file.type,
          contentDisposition: "inline",
        });

        // prep form data for s3 file upload
        const {
          id: fileId,
          url: s3Url,
          fields: s3UploadInfo,
        } = uploadInfoResponse.data;
        const fileUploadData = prepareS3UploadData(file, s3UploadInfo);

        // upload file to s3
        await axios.post(s3Url, fileUploadData);
        setIsSuccess(true);
        return {
          fileTitle: file.name,
          fileId,
          s3Bucket: s3UploadInfo.bucket,
          s3Key: s3UploadInfo.key,
          fileName: file.name,
        };
      } catch (err) {
        setIsError(true);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading]
  );

  const reset = useCallback(() => {
    setIsLoading(false);
    setIsSuccess(false);
    setIsError(false);
    setFileName("");
  }, []);

  return {
    isLoading,
    isSuccess,
    isError,
    fileName,
    upload,
    reset,
  };
};
