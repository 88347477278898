import React, { useState, useEffect } from "react";
import { useTheme } from "@samacare/design/core/styles";
import { GridToolbarQuickFilter, Stack } from "@samacare/design";

interface GridQuickFilterProps {
  onFilterChange?: (filterValue: string) => void;
  searchWord?: string;
  hideSearchIcon?: boolean;
  inputHeightOverride?: number;
  inputWidthOverride?: number;
  dataCy?: string;
}

const GridQuickFilter: React.VFC<GridQuickFilterProps> = ({
  onFilterChange,
  searchWord,
  hideSearchIcon,
  inputHeightOverride,
  inputWidthOverride,
  dataCy,
}) => {
  const theme = useTheme();
  const [filterValue, setFilterValue] = useState(searchWord || "");

  useEffect(() => {
    setFilterValue(searchWord || "");
  }, [searchWord]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilterValue(value);
    if (onFilterChange) {
      onFilterChange(value);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      data-cy={dataCy ?? "gridQuickFilter"}
    >
      <GridToolbarQuickFilter
        debounceMs={500}
        onInput={handleFilterChange}
        value={filterValue}
        width={inputWidthOverride}
        sx={{
          paddingBottom: 0,
          flexGrow: 1,
          border: 1,
          borderRadius: 1,
          borderColor: theme.palette.colors.N400,
          "& .MuiSvgIcon-root": {
            margin: "5px",
            display: hideSearchIcon ? "none" : "block",
          },
          "& .MuiInput-root:before": {
            display: "none",
          },
          "& input": {
            padding: "0 8px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 0,
            boxSizing: "border-box",
            height: inputHeightOverride ?? 30,
            width: inputWidthOverride ?? "100%",
          },
        }}
      />
    </Stack>
  );
};

export default GridQuickFilter;
