import _ from "lodash";
import { PureComponent } from "react";

import { genericInput } from "../InputPropTypes";
import { BaseInput, getPropsFromInput } from "./BaseInput";

export const formatPhoneNumber = (unformatted = "") => {
  const phoneDigits = (unformatted || "").replace(/\D/g, "");
  const phonePartsWithValues = _.compact([
    phoneDigits.substring(0, 3),
    phoneDigits.substring(3, 6),
    phoneDigits.substring(6, 10),
    phoneDigits.substring(10, 14),
  ]);

  return _.reduce(
    ["-", "-", "x"],
    (phoneNumber, delimiter, i) => {
      if (phonePartsWithValues[i + 1]) {
        return `${phoneNumber}${delimiter}${phonePartsWithValues[i + 1]}`;
      }

      return phoneNumber;
    },
    phonePartsWithValues[0] || ""
  );
};

class PhoneInput extends PureComponent {
  static propTypes = {
    input: genericInput.isRequired,
  };

  componentDidMount() {
    // Important to format the phone number on the first load
    const { input } = this.props;
    input.onChange({ [input.id]: formatPhoneNumber(input.value) });
  }

  render() {
    const { input } = this.props;

    return (
      <BaseInput
        {...getPropsFromInput(input)}
        onChange={(e) => {
          const phoneValue = e.target.value;
          input.onChange({ [input.id]: formatPhoneNumber(phoneValue) });
        }}
        value={input.value}
        placeholder="555-515-5175x1234"
      />
    );
  }
}

export default PhoneInput;
