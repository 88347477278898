import _ from "lodash";
import * as React from "react";
import { useAlert } from "react-alert";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@samacare/design/core";
import { MutationUpdateInsuranceCompanyByIdArgs } from "@samacare/graphql";
import { withForms } from "../../../graphql/AuthorizationForm";
interface Form {
  hasForms: boolean;
}

export type ArchivePayerProps = React.ComponentProps<typeof ArchivePayer>;

export const ArchivePayer: React.VoidFunctionComponent<{
  insuranceCompanyId: number;
  name: string;
  back: () => void;
  clearForm: () => Promise<void>;
  updateInsuranceCompany: (options: {
    variables: MutationUpdateInsuranceCompanyByIdArgs;
  }) => Promise<void>;
  forms: Form[];
  isArchived: boolean;
}> = (props) => {
  const alert = useAlert();
  const { forms } = props;
  const formStatus: boolean = _.get(forms, "hasForms") === false;
  const updatePayer = async () => {
    const {
      insuranceCompanyId,
      updateInsuranceCompany,
      clearForm,
      isArchived,
    } = props;

    try {
      await updateInsuranceCompany({
        variables: {
          id: insuranceCompanyId,
          patch: {
            isArchived: !isArchived,
          },
        },
      });

      await clearForm();
      alert.info("Successfully updated payer");
    } catch (e) {
      alert.error(`Failed to update ${e as string}`);
    }
  };

  const generateModal = (allowUpdate: boolean) => {
    const { name, back, isArchived } = props;
    return (
      <Dialog
        open
        onClose={() => {
          back();
        }}
      >
        <DialogTitle>{isArchived ? "Unarchive" : "Archive"} Payer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {allowUpdate
              ? `Are you sure you want to ${
                  isArchived ? "unarchive" : "archive"
                } this payer: ${name}?`
              : "This payer is associated to one or more forms and cannot be archived"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              back();
            }}
          >
            Cancel
          </Button>
          {allowUpdate && (
            <Button variant="contained" onClick={updatePayer}>
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
  return generateModal(formStatus);
};

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default withForms(ArchivePayer);
