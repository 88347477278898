import { Authorization } from "@samacare/graphql";
import { useConfig } from "@@hooks/config";
import { Box, Stack, Typography } from "@samacare/design/core";

const CODES = "Codes";
const SERVICE_DATE = "Service Date";

const DetailsContainer: React.FC<{ authorization: Authorization }> = ({
  authorization,
}) => {
  const { DEFAULT_FIELDS } = useConfig();
  const { config, patient } = authorization;

  const authorizationDetails = [
    {
      title: "Patient Details",
      items: [["Name", `${patient?.lastName}, ${patient?.firstName}`]],
    },
    {
      title: "Request Details",
      items: [
        [
          "Drug",
          (config as Record<string, string>)[
            DEFAULT_FIELDS.PRIMARY_DRUG_NAME.key
          ],
        ],
        [
          SERVICE_DATE,
          (config as Record<string, string>)[
            DEFAULT_FIELDS.DATE_OF_SERVICE.key
          ],
        ],
        [
          CODES,
          (config as Record<string, string>)[DEFAULT_FIELDS.ALL_HCPCS.key],
        ],
      ],
    },
  ];

  return (
    <>
      {authorizationDetails.map((detailSection) => (
        <div key={`association_detailSection_${detailSection.title}`}>
          <Typography variant="subtitle2">{detailSection.title}</Typography>
          <Box display="flex" flexWrap="wrap">
            {detailSection.items.map((prop) => (
              <Stack
                width={
                  prop[0] === CODES || prop[0] === SERVICE_DATE ? "50%" : "100%"
                }
                direction="column"
                key={`SupportView_${prop[0]}`}
                mt={1}
              >
                <b>{prop[0]}</b>
                <div>{prop[1]}</div>
              </Stack>
            ))}
          </Box>
        </div>
      ))}
    </>
  );
};

export default DetailsContainer;
