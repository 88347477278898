import colors from "Resources/colors";
import dimensions from "Resources/dimensions";
import { ThemeProps } from "styled-components";

type Colors = typeof colors;
export type Variants = keyof Omit<Colors, "text">;
type Dimensions = typeof dimensions;

export interface StyledTheme extends Colors, Dimensions {
  colors: Colors;
  dimensions: Dimensions;
}

export const theme: StyledTheme = {
  colors,
  dimensions,
  ...dimensions,
  ...colors,
};

export const colorForVariant = (
  props: ThemeProps<StyledTheme>,
  variant: Variants
): string => props.theme.colors[variant];
