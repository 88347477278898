import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import CreateIcon from "@samacare/design/core/icons/Create";
import { EmailField } from "@samacare/form/EmailField/EmailField";
import { AuthLoginQuery, AuthLoginQueryVariables } from "@@generated/graphql";

import {
  Alert,
  Stack,
  Button,
  LoadingButton,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@samacare/design/core";

import {
  useForm,
  PasswordField,
  SubmitHandler,
  FormProvider,
} from "@samacare/form";

import { AuthTitle } from "./AuthTitle";
import AuthLoginGql from "./AuthLogin.gql";
import { AuthIdentity } from "./AuthIdentity";
import { useAuth } from "../../providers/AuthProvider";
import { AUTH_USERNAME_STORAGE } from "./Auth.constants";

type AuthLoginForm = {
  username: string;
  password?: string;
};

export const AuthLogin: React.FC = () => {
  const history = useHistory();
  const { onSignIn, error, loading, onReset, onError } = useAuth();
  const methods = useForm<AuthLoginForm>();

  const [isSSO, setIsSSO] = useState(false);
  const [username, setUsername] = useState<string | undefined>(
    () => localStorage.getItem(AUTH_USERNAME_STORAGE) ?? void 0
  );
  const [identityProvider, setIdentityProvider] = useState<string | undefined>(
    void 0
  );

  const [getIdentityProvider, { loading: requestLoading, called }] =
    useLazyQuery<AuthLoginQuery, AuthLoginQueryVariables>(AuthLoginGql, {
      onCompleted: (requestData) => {
        setIdentityProvider(requestData.getIdentityProvider?.name ?? void 0);
      },
      onError: (requestError) => {
        onError(requestError.message);
      },
    });

  useEffect(() => {
    if (!called && !requestLoading) {
      void (async () => {
        await getIdentityProvider({ variables: { email: username ?? "" } });
      })();
    }
  }, [called, username, requestLoading, getIdentityProvider]);

  const onSubmit: SubmitHandler<AuthLoginForm> = async (data) => {
    if (!username) {
      localStorage.setItem(AUTH_USERNAME_STORAGE, data.username);
      setUsername(data.username);
      await getIdentityProvider({
        variables: {
          email: data.username,
        },
      });
      return;
    }

    if (!identityProvider) {
      await onSignIn(data);
    } else {
      setIsSSO(true);
    }
  };

  const isLoading = loading || requestLoading;
  const callToAction = identityProvider ? "Login with SSO" : "Login";

  if (isSSO && identityProvider) {
    return <AuthIdentity name={identityProvider} />;
  }

  if (requestLoading) {
    return (
      <Stack spacing={2} alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <FormProvider {...methods}>
      <form name="login" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <AuthTitle>Login to SamaCare</AuthTitle>
        <Stack spacing={2}>
          <EmailField
            name="username"
            label="Email address"
            disabled={!!username}
            value={username}
            InputProps={{
              endAdornment: username && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Change email address"
                    onClick={() => {
                      localStorage.removeItem(AUTH_USERNAME_STORAGE);
                      methods.setValue("username", "");
                      setUsername(void 0);
                      setIdentityProvider(void 0);
                      onError(void 0);
                    }}
                    edge="end"
                  >
                    <CreateIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {username && !identityProvider && !isSSO && (
            <PasswordField
              name="password"
              label="Password"
              InputProps={{
                autoComplete: "current-password",
              }}
            />
          )}
          {error && <Alert color="error">{error}</Alert>}

          <LoadingButton
            loading={isLoading}
            type="submit"
            size="large"
            variant="contained"
            data-cy="actionLogin"
          >
            {username ? callToAction : "Continue"}
          </LoadingButton>
          {!identityProvider && (
            <Button
              onClick={() => {
                onReset();
                history.push("/auth/reset-password");
              }}
              size="large"
            >
              Forgot Password?
            </Button>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
