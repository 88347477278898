import * as React from "react";
import styled from "styled-components";
import { Label } from "./Label";
import { Flex, Box } from "../Flexbox";
import { useTheme } from "../../app/hooks";

const Caption = styled.div<{
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
}>`
  color: ${({
    disabled = false,
    error = false,
    success = false,
    warning = false,
    theme: { colors },
  }) => {
    if (disabled) {
      return colors.disabledGray;
    }
    if (error) {
      return colors.danger;
    }
    if (warning) {
      return colors.warning;
    }
    if (success) {
      return colors.success;
    }
    return colors.text.secondary;
  }};
  font-size: 12px;
  margin-bottom: 6px;
  margin-top: 6px;
`;

export type FormControlProps = React.ComponentProps<typeof FormControl>;

/**
 * FormControl is a component that accepts a form input as a child and adds a
 * label and caption on the top and bottom of the input (respectively). If the
 * child element has an `id` prop, FormControl will set `htmlFor` on the label
 * component to match it.
 *
 * When an error or success prop is passed, it'll be rendered in place of the
 * caption. An error prop will take precedence over a success prop.
 *
 * Note: FormControl expects a single child component and will throw an error if
 * more than one child is passed.
 *
 * @example
 * ```ts
 * <FormControl label="Username" error={isUsernameInvalid ? "Your username is
 * invalid." : }>
 *   <Input id="username" />
 * </FormControl>
 * ```
 */
/**
 * @deprecated Use component from design/forms instead
 */
export const FormControl: React.VoidFunctionComponent<{
  /**
   * A React node to render in the caption position. Both error and success take
   * priority over this value.
   */
  caption?: React.ReactNode;
  /**
   * The form element to be controlled (e.g. an input, textarea, etc.)
   *
   * In order for automatic labeling functionality to work, the child element
   * must have an `id` attribute on it.
   *
   * You must pass exactly one element here; if you pass an array of children,
   * this element will throw an error.
   */
  children: React.ReactNode;
  /**
   * Whether or not the switch can be interacted with.
   */
  disabled?: boolean;
  /**
   * A React node to render in the caption position. Takes priority over
   * caption, success, and warning.
   */
  error?: React.ReactNode;
  /**
   * A React node to render within the Label component.
   */
  label?: React.ReactNode;
  /**
   * Whether or not to mark the field as required. (Marking the field as
   * required will display a red asterisk next to the label.)
   */
  required?: boolean;
  /**
   * A React node to render in the caption position. Takes priority over
   * caption.
   */
  success?: React.ReactNode;
  /**
   * A React node to render in the caption position. Takes priority over
   * caption and success.
   */
  warning?: React.ReactNode;
  /**
   * Custom styling that will be applied to the component to control layout
   * and appearance.
   */
  style?: React.CSSProperties;
  cypressTag?: string;
}> = ({
  caption,
  children,
  disabled,
  error,
  label,
  required = false,
  success,
  warning,
  style,
  cypressTag,
}) => {
  const theme = useTheme();

  // Get ready for some ✨magic ✨!
  //
  // We're about to intercept rendering our children component(s) so we can
  // inspect its props and inject some overrides from the FormControl props.
  // This will let us:
  //
  // - Disable the child component if the FormControl is disabled
  // - Pass the FormControl's error state to the child
  //
  // In each of these scenarios, we'll respect the child's props when defined.
  //
  // TypeScript is going to have some trouble keeping up here, and with good
  // reason; what we're about to do is not exactly typesafe.
  const onlyChild = React.Children.only(children);
  const childProps = (
    React.isValidElement(onlyChild) ? onlyChild.props : {}
  ) as Record<string, unknown>;

  return (
    <div data-cy={cypressTag}>
      <Flex flexDirection="column" style={style}>
        {label != null && label !== "" && (
          <Box marginBottom="6px" marginTop="6px">
            <Label
              aria-label={`Label for Input ${
                typeof childProps.name === "string" ? childProps.name : ""
              }`.trim()}
              htmlFor={
                typeof childProps.id === "string" ? childProps.id : undefined
              }
            >
              {label}
            </Label>

            {required && (
              <span style={{ color: theme.colors.danger }}>&nbsp;*</span>
            )}
          </Box>
        )}
        <Box>
          {React.Children.map(React.Children.toArray(children), (child) => {
            if (!React.isValidElement(child)) {
              return child;
            }

            return React.cloneElement(child, {
              disabled:
                "disabled" in childProps ? childProps.disabled : disabled,
              error: "error" in childProps ? childProps.error : Boolean(error),
              success:
                "success" in childProps ? childProps.success : Boolean(success),
              warning:
                "warning" in childProps ? childProps.warning : Boolean(warning),
            } as React.ComponentProps<typeof FormControl>);
          })}
        </Box>
        <Box>
          <Caption
            aria-label={`Caption for Input ${
              typeof childProps.name === "string" ? childProps.name : ""
            }`.trim()}
            error={error != null && error !== ""}
            success={success != null && success !== ""}
            warning={warning != null && warning !== ""}
          >
            {(() => {
              switch (true) {
                case error != null && error !== "":
                  return error;
                case warning != null && warning !== "":
                  return warning;
                case success != null && success !== "":
                  return success;
                case caption != null && caption !== "":
                  return caption;
                default:
                  return null;
              }
            })()}
          </Caption>
        </Box>
      </Flex>
    </div>
  );
};
