import { useMutation } from "@apollo/client";
import * as React from "react";
import { useAlert } from "react-alert";
import { useHistory, useParams } from "react-router-dom";

import {
  SubmitBenefitsVerificationMutation,
  UpdateBenefitsVerificationMutation,
} from "@@generated/graphql";
import { Box } from "@samacare/design/core";
import Typography from "@samacare/design/core/Typography";
import { ALL_BENEFITS_VERIFICATIONS_QUERY_NAME } from "../../../graphql/BenefitsVerificationOutcome";
import { useBenefitsVerification } from "../../../hooks/useBenefitsVerification";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { BenefitsVerificationTypeEnum } from "@samacare/graphql";
import {
  BenefitsVerificationRequestFormFullGeneric,
  BenefitsVerificationRequestFormPavblu,
} from "../components";
import { RightSideMenu } from "../components/RightSideMenu";
import { useFeatureFlag, useFeatureFlagNames, useConfig } from "../../../hooks";
import {
  transformBvToFormValues,
  transformRequestFormToPatch,
} from "../formUtils";
import SubmitBenefitsVerificationMutationGql from "../graphql/SubmitBenefitsVerification.gql";
import UpdateBenefitsVerificationMutationGql from "../graphql/UpdateBenefitsVerification.gql";
import { RequestBenefitVerificationFormDataType } from "../types";

export const EditBenefitsVerificationRequest: React.VoidFunctionComponent =
  () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const alert = useAlert();

    // load bc data
    const { benefitsVerification, loading } = useBenefitsVerification(id);

    const config = useConfig();
    const featureFlags = useFeatureFlagNames();
    const enabledDrugs =
      useFeatureFlag<{ drugName: string; drugOptionId: number }[]>(
        featureFlags.AvailableBenefitsVerificationsDrugsV2
      ) ?? [];

    const currentDrugOptionId =
      benefitsVerification?.request.drugDetails.drugOptionId;
    const isPavblu =
      !!currentDrugOptionId &&
      enabledDrugs.find(
        (drug) =>
          Object.values(config.CONSTANTS.CAREMETX_BV_DRUGS).includes(
            drug.drugName
          ) && drug.drugOptionId === parseInt(currentDrugOptionId, 10)
      ) != null;

    // init bv graphql mutations
    const [updateBenefitsVerification] =
      useMutation<UpdateBenefitsVerificationMutation>(
        UpdateBenefitsVerificationMutationGql,
        { refetchQueries: [ALL_BENEFITS_VERIFICATIONS_QUERY_NAME] }
      );
    const [submitBenefitsVerification] =
      useMutation<SubmitBenefitsVerificationMutation>(
        SubmitBenefitsVerificationMutationGql,
        { refetchQueries: [ALL_BENEFITS_VERIFICATIONS_QUERY_NAME] }
      );

    // interaction handlers
    const onBack = () => {
      history.push(ROUTE_PATHS.BENEFITS_VERIFICATIONS.path);
    };

    const onSubmit = React.useCallback(
      async (data: RequestBenefitVerificationFormDataType) => {
        try {
          const updatedBv = await updateBenefitsVerification({
            variables: { id, patch: transformRequestFormToPatch(data) },
          });

          if (
            updatedBv.data?.updateBenefitsVerification?.type ===
            BenefitsVerificationTypeEnum.Caremetx
          ) {
            return history.push(
              `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/caremetx-pavblu-request/${updatedBv.data?.updateBenefitsVerification?.id}`
            );
          }

          await submitBenefitsVerification({ variables: { id } });
          alert.success("Benefits Verification Submitted ");
          history.push(`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${id}`);
        } catch (err) {
          alert.error(`Error: ${(err as Error).message}`);
        }
      },
      [
        id,
        history,
        alert,
        updateBenefitsVerification,
        submitBenefitsVerification,
      ]
    );

    if (loading) {
      return <div>Loading...</div>;
    }

    if (benefitsVerification == null) {
      return <div>{`Failed to find Benefits Verification with Id ${id}`}</div>;
    }

    const defaultValues = transformBvToFormValues(benefitsVerification);
    return (
      <Box minWidth={600} data-cy="componentBenefitsVerificationEditPage">
        <Box borderBottom="1px solid" borderColor="rgba(28, 28, 28, 0.1)">
          <Typography variant="h6" color="text/primary" padding={2}>
            Edit Benefits Verification
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box padding={2}>
            {isPavblu && (
              <BenefitsVerificationRequestFormPavblu
                editable={benefitsVerification.outcome == null}
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                onBack={onBack}
              />
            )}
            {!isPavblu && (
              <BenefitsVerificationRequestFormFullGeneric
                editable={benefitsVerification.outcome == null}
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                onBack={onBack}
              />
            )}
          </Box>
          <Box width="230px" margin={7}>
            <RightSideMenu activeStep={1} />
          </Box>
        </Box>
      </Box>
    );
  };
