import IconButton from "@samacare/design/core/IconButton";
import { Patient } from "@samacare/graphql";
import DeleteIcon from "@samacare/design/core/icons/Delete";
import { useConfirmDialog } from "../../../hooks/confirmDialogHook";
import { useDeletePatient } from "../../../graphql/Patient";
import { Stack } from "@samacare/design";

interface DeletePatientIconButtonProps {
  patient: Patient;
}
export const DeletePatientIconButton: React.VFC<
  DeletePatientIconButtonProps
> = (props) => {
  const deletePatient = useDeletePatient();
  const [ConfirmDialog, confirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog({
      message: (
        <Stack maxWidth={600}>
          <Stack>
            Delete the patient named {props.patient.firstName}{" "}
            {props.patient.lastName}?
          </Stack>
          <Stack mt={2}>
            Authorizations, enrollments, and benefit verifications for this
            patient will remain in the system.
          </Stack>
        </Stack>
      ),
      confirm: async () => deletePatient(+props.patient.id),
      zIndexOffset: 1,
    });

  return (
    <>
      <IconButton onClick={() => setConfirmDialogOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <ConfirmDialog {...confirmDialogProps} />
    </>
  );
};
