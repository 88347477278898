import { PureComponent } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";

import BaseButton from "./BaseButton";
import LoadingButton from "./LoadingButton";
import UploadFileOutlined from "@samacare/design/core/icons/UploadFileOutlined";

const dropzoneClass = "dropzone";
const MAX_FILE_SIZE_B = 50000000;

export const DROPZONE_WIDTH = 400;
const DropzoneContainer = styled.div`
  .${dropzoneClass} {
    padding: 30px 20px;
    cursor: ${(props) => (props.disabled ? "" : "pointer")};
    width: ${({ fullWidth }) => (fullWidth ? "100%" : `${DROPZONE_WIDTH}px`)};
    border: 1px dashed ${(props) => props.theme.purple};
    border-radius: 3px;
    text-align: center;
    display: flex;
    flex-direction: column;

    ${BaseButton} {
      margin: 0 auto 10px auto;
      width: 200px;
    }
  }
  margin-bottom: 10px;
`;

const HelperText = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.darkGray};
`;

const Icon = styled(UploadFileOutlined)`
  color: #6363f2;
  width: 24px;
  height: 24px;
  margin: 8px;
`;

const SecondRawContainer = styled.div`
  display: flex;
  padding: 8px 0;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  letter-spacing: 0.15px;
`;

const ClickToUpload = styled.div`
  color: #6363f2;
  font-feature-settings: "liga" off, "clig" off;
  cursor: pointer;
  text-decoration: underline;
`;

export class SamaDropzone extends PureComponent {
  render() {
    const {
      multiple = false,
      minSize,
      maxSize,
      accept = [CONFIG.CONSTANTS.CONTENT_TYPES.PDF],
      disabled,
      loading,
      onDrop,
      onDropRejected,
      className,
      helperText = "(files must be in PDF format)",
      buttonText = "Browse PDFs to upload",
      fullWidth = false,
      isSimplifiedUx,
    } = this.props;

    return (
      <DropzoneContainer disabled={disabled || loading} fullWidth={fullWidth}>
        <Dropzone
          accept={accept}
          className={className}
          disabled={disabled || loading}
          // 1 KB is an unrealistically small PDF, but this is intended to safeguard against 0-byte files
          minSize={minSize || 1024}
          maxSize={maxSize || MAX_FILE_SIZE_B}
          multiple={multiple}
          onDrop={onDrop}
          onDropRejected={onDropRejected}
        >
          {isSimplifiedUx ? (
            <>
              <Icon />
              <SecondRawContainer>
                <ClickToUpload>{buttonText}</ClickToUpload>
                <span>or drag and drop</span>
              </SecondRawContainer>
              <HelperText>{helperText}</HelperText>
            </>
          ) : (
            <>
              <LoadingButton
                disabled={disabled || loading}
                loading={loading}
                cypressTag="actionDropAttachment"
                type="button"
              >
                {buttonText}
              </LoadingButton>
              <span>or drag and drop them here</span>
              <HelperText>{helperText}</HelperText>
            </>
          )}
        </Dropzone>
      </DropzoneContainer>
    );
  }
}

/**
 * @deprecated Use a MUI component instead
 */
export default SamaDropzone;
