export enum EnrollmentRoutes {
  details = "details",
  submission = "submission",
  program = "program",
  summary = "summary",
}

export const EnrollmentDetails = {
  PatientDetails: "Patient Details",
  DiagnosisAndDrug: "Diagnosis & Drug",
  InsuranceDetails: "Insurance Details",
  ProviderDetails: "Provider Details",
  LocationDetails: "Location Details",
  Questionnaire: "Questionnaire",
};

export type EnrollmentRoutesMap = {
  [key in EnrollmentRoutes]?: string;
};

export const EnrollmentRouteNames: EnrollmentRoutesMap = {
  [EnrollmentRoutes.program]: "Initiate Enrollment",
  [EnrollmentRoutes.details]: "Enrollment Details",
  [EnrollmentRoutes.submission]: "Submission Details",
  [EnrollmentRoutes.summary]: "Summary",
};
