import * as React from "react";
import { Button, Box } from "@samacare/design/core";
import { useTheme } from "styled-components";

import { DialogModal } from "@@components/DialogModal";
import { DialogCopilotButton } from "@@components/DialogCopilotButton";
import { INSTRUCTION_MODAL_SHOWED_TIMES } from "../../../util/constants";
import { PurpleContainerForModal } from "./PurpleContainerForModal";
import { LearnMoreText } from "./LearnMoreText";

export const ExtensionInstructionDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onShowNewPortalModal: () => void;
}> = ({ open, onClose, onShowNewPortalModal }) => {
  const theme = useTheme();

  const handleChoosePortalClick = () => {
    const instructionModalShowedTimes = localStorage.getItem(
      INSTRUCTION_MODAL_SHOWED_TIMES
    );

    instructionModalShowedTimes
      ? localStorage.setItem(
          INSTRUCTION_MODAL_SHOWED_TIMES,
          String(Number(instructionModalShowedTimes) + 1)
        )
      : localStorage.setItem(INSTRUCTION_MODAL_SHOWED_TIMES, "1");

    onShowNewPortalModal();
  };

  const content = (
    <Box>
      <PurpleContainerForModal />
      <LearnMoreText />
    </Box>
  );

  const actions = (
    <>
      <Button
        onClick={onClose}
        sx={{
          color: theme.purple,
          textDecoration: "underline",
          "&:hover": {
            background: "transparent",
          },
        }}
      >
        Close
      </Button>
      <DialogCopilotButton
        text="Choose Portal"
        onClick={handleChoosePortalClick}
        sx={{
          background: theme.purple,
          "&:hover": {
            background: theme.darkPurple,
          },
        }}
      />
    </>
  );

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      title="Capture and Store PAs in Any Portal with CoPilot"
      headerText="After submission click on CoPilot to save PAs in SamaCare."
      content={content}
      actions={actions}
    />
  );
};
