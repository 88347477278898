import gql from "graphql-tag";

export default gql`
  fragment enrollmentInfo on Enrollment {
    id
    status
    type
    externalId
    createdAt
    updatedAt
  }
`;
