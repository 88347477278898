import { PureComponent } from "react";
import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import styled from "styled-components";
import _ from "lodash";
import { MdCheck as CheckIcon } from "@react-icons/all-files/md/MdCheck";
import NoteIcon from "@samacare/design/core/icons/StickyNote2Outlined";
import { push } from "connected-react-router";
import {
  displayLetterOfMedicaNecessityForAuth,
  PatientAccessProgram,
  ISIPanel,
} from "../../AuthorizationSharedSteps/LomnSection/LomnShared";
import { AuthorizationStepKey } from "@samacare/graphql";
import * as stepUtils from "../../../util/stepUtils";
import { Alert } from "@samacare/design/core";
import Divider from "@samacare/design/core/Divider";

import { withAuthorizationQuery } from "../../../graphql/Authorization";
import ROUTE_PATHS from "../../../routes/ROUTE_PATHS";

const Container = styled.div`
  flex: 1;
  padding: 36px 0;
  max-width: 288px;
`;

const Tracker = styled.div`
  width: 288px;
  margin: 0 auto;
  margin-bottom: 8px;
  display: block;
  border: 1px solid ${(props) => props.theme.lightGray};
  border-radius: 3px;
  padding: 10px 20px;
`;

const Section = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: 18px;
  margin-left: 10px;
  font-weight: 700;
`;

const SectionItems = styled.div`
  font-size: 14px;
`;

const Item = styled.div`
  color: ${(props) =>
    props.current
      ? "white"
      : props.active
      ? props.theme.darkGray
      : props.theme.lightGray};
  padding: 6px 0 6px 28px;
  font-weight: ${(props) => (props.current ? 500 : 200)};
  background: ${(props) =>
    props.current ? props.theme.purple : "transparent"};

  &:hover {
    cursor: ${(props) => !props.current && props.active && "pointer"};
    background: ${(props) =>
      props.current
        ? props.theme.purple
        : props.active
        ? props.theme.purple
        : "transparent"};
    color: ${(props) =>
      props.current ? "white" : props.active ? "white" : props.theme.lightGray};
    font-weight: ${(props) => props.active && 500};
  }
`;

const TrackerTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 10px;
  font-size: 20px;
  color: ${(props) => props.theme.purple};
  font-weight: 700;
`;

const checkmarkSize = 20;
const Checkmark = styled(CheckIcon)`
  height: ${checkmarkSize}px;
  width: ${checkmarkSize}px;
  color: ${(props) => props.theme.purple};
`;

const CheckmarkPlaceholder = styled.div`
  height: ${checkmarkSize}px;
  width: ${checkmarkSize}px;
`;

const { AUTHORIZATION_STATUSES } = CONFIG.CONSTANTS;
const SUMMARY = "Summary";

const getSectionIcon = (section, authorization) => {
  if (section.title === SUMMARY) {
    return (
      <NoteIcon sx={{ height: "20px", width: "20px", color: "primary.main" }} />
    );
  } else if (section.sectionActive(authorization.status)) {
    return <Checkmark />;
  }
  return <CheckmarkPlaceholder />;
};

const getSections = (steps) => [
  {
    title: "Pre Submission",
    steps: _.filter(steps, { section: "PRE_SUBMIT" }),
    sectionActive() {
      return true;
    },
  },
  {
    title: "Authorization Submitted",

    sectionActive(status) {
      return !_.includes([AUTHORIZATION_STATUSES.PRESUBMISSION], status);
    },
  },
  {
    title: SUMMARY,
    sectionActive() {
      return true;
    },
    steps: _.filter(steps, { section: "POST_SUBMIT" }),
  },
];
class ProgressTracker extends PureComponent {
  render() {
    const { authorization, setStep, currentStep } = this.props;

    const orderedSteps = _.orderBy(authorization?.steps, ["number"], ["asc"]);

    const sections = getSections(orderedSteps);
    const parentStep = orderedSteps.find(
      (s) => s.number === currentStep
    )?.belongsTo;

    if (authorization) {
      return (
        <Container>
          <Tracker>
            <TrackerTitle>Progress</TrackerTitle>
            {_.map(sections, (section, ind) => (
              <div key={`Section-${section.title}`}>
                {ind === sections.length - 1 && <Divider />}
                <Section>
                  <TitleContainer key={`ProgressTracker${section.title}`}>
                    {getSectionIcon(section, authorization)}
                    <Title>{section.title}</Title>
                  </TitleContainer>
                  <SectionItems>
                    {_.map(
                      section.steps?.filter((step) => !step.belongsTo),
                      (step) => (
                        <Item
                          data-cy={`actionJumpToStep${_.startCase(
                            //This generates a camelCase string with a capital first letter (PascalCase)
                            _.camelCase(step.title)
                          ).replace(/ /g, "")}`}
                          key={`ProgressTrackerItem${step.title}`}
                          active={step.active}
                          current={(parentStep || currentStep) === step.number}
                          onClick={() => {
                            if (step.active) {
                              setStep(step.number);
                            }
                          }}
                        >
                          {step.title}
                        </Item>
                      )
                    )}
                  </SectionItems>
                </Section>
              </div>
            ))}
          </Tracker>
          {stepUtils.isStep(
            authorization.steps,
            currentStep,
            AuthorizationStepKey.ReviewSignSubmit
          ) &&
            _.includes(
              [
                AUTHORIZATION_STATUSES.PRESUBMISSION,
                AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT,
              ],
              authorization.status
            ) && (
              <Alert severity="info">
                To make changes, return to previous steps by clicking on the
                step above
              </Alert>
            )}
          {displayLetterOfMedicaNecessityForAuth(authorization) && (
            <>
              <PatientAccessProgram authorization={authorization} />
              <ISIPanel authorization={authorization} />
            </>
          )}
        </Container>
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state) => ({
  currentStep: state.form.currentStep,
});

const mapDispatchToProps = (dispatch) => ({
  toSamaStory(id) {
    dispatch(push(`${ROUTE_PATHS.STORY_PLAYER.path}?authorizationId=${id}`));
  },
});

const withAuthorization = graphql(withAuthorizationQuery, {
  options: (props) => ({
    variables: { id: parseInt(props.authorizationId, 10) },
  }),
  props({ data }) {
    return { authorization: data.authorizationById };
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withAuthorization)(ProgressTracker));
