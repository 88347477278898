import { useState } from "react";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import {
  CreateAuthUploadFromAwsKeyMutation,
  CreateAuthUploadFromAwsKeyMutationVariables,
  UploadRedoxDocumentToS3MutationMutation,
  UploadRedoxDocumentToS3MutationMutationVariables,
} from "@@generated/graphql";

import { Authorization } from "@samacare/graphql";
import { useDispatch } from "react-redux";
import { setAttachment, File as FormFile } from "../reducers/formReducer";
import { RedoxFileType } from "AuthorizationSharedComponents/RedoxAttachFilesModal";
import { createAuthUploadFromAwsKeyMutation } from "../graphql/File";
import { uploadRedoxDocumentToS3Mutation } from "../graphql/UploadRedoxDocumentReferenceToS3.gql";

export const useAttachRedoxDocument = (
  authorization: Authorization,
  maxFileSizeMB?: number
) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const set = (file: FormFile) => dispatch(setAttachment(file));

  const [selectedFile, setSelectedFile] = useState<null | RedoxFileType>(null);
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);

  const [createAuthUploadFromAwsKey] = useMutation<
    CreateAuthUploadFromAwsKeyMutation,
    CreateAuthUploadFromAwsKeyMutationVariables
  >(createAuthUploadFromAwsKeyMutation);

  const [uploadRedoxDocumentReferenceToS3] = useMutation<
    UploadRedoxDocumentToS3MutationMutation,
    UploadRedoxDocumentToS3MutationMutationVariables
  >(uploadRedoxDocumentToS3Mutation);

  const selectFile = (redoxFile: RedoxFileType, index: number) => {
    setSelectedFile(redoxFile);
    setSelectedIndex(index);
  };

  const attachSelectedFile = async () => {
    try {
      const uploadDocumentResponse = await uploadRedoxDocumentReferenceToS3({
        variables: {
          authorizationId: +authorization.id,
          documentId: selectedFile!.id,
        },
      });

      if (!uploadDocumentResponse?.data) {
        throw Error("No data returned from uploading document reference to S3");
      }

      const document = uploadDocumentResponse.data.uploadRedoxDocumentToS3;
      const fileCreationResult = await createAuthUploadFromAwsKey({
        variables: {
          authorizationId: +authorization.id,
          title: document.description,
          awsKey: document.id,
          maxFileSizeMB,
        },
      });

      const fileData = fileCreationResult?.data?.createAuthUploadFromAwsKey;

      if (fileData) {
        set({
          title: fileData.title,
          awsKey: fileData.awsKey as string,
          id: fileData.id,
        });
        setSelectedFile(null);
        setSelectedIndex(null);
      }
    } catch (e) {
      if (e instanceof Error && _.includes(e.message, "too large")) {
        alert.error(
          `The selected file is larger than the allowed limit of ${maxFileSizeMB}MB`
        );
      } else {
        alert.error(
          "There was an error uploading your file, please try again or reach out to us if this problem continues"
        );
      }
    }
  };

  return {
    selectedFile,
    selectedIndex,
    selectFile,
    attachSelectedFile,
  };
};
