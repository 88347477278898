import { Box, LinearProgress, Typography } from "@samacare/design";
import { VFC } from "react";
import { IngestJob, PipelineStage } from "@samacare/graphql";

const stepCountByStage = {
  [PipelineStage.WaitingForUpload]: 1,
  [PipelineStage.WaitingForPipeline]: 2,
  [PipelineStage.ValidateFile]: 3,
  [PipelineStage.TransformFile]: 4,
  [PipelineStage.DeduplicateRows]: 5,
  [PipelineStage.ChunkData]: 6,
  [PipelineStage.TransformAndValidateRows]: 7,
  [PipelineStage.LoadData]: 8,
  [PipelineStage.Finished]: 9,
  [PipelineStage.Failed]: 9,
};

const messageByStage: Record<string, string> = {
  [PipelineStage.WaitingForUpload]: "Starting Job",
  [PipelineStage.WaitingForPipeline]: "Waiting for Pipeline",
  [PipelineStage.ValidateFile]: "Validating",
  [PipelineStage.TransformFile]: "Transforming",
  [PipelineStage.DeduplicateRows]: "Deduplicating",
  [PipelineStage.ChunkData]: "Splitting",
  [PipelineStage.TransformAndValidateRows]: "Transforming",
  [PipelineStage.LoadData]: "Loading Patients",
  [PipelineStage.Finished]: "Finished",
  [PipelineStage.Failed]: "Failed",
};

const MIN = 0;
const MAX = 9;
const normalise = (value: number) => ((value - MIN) * 100) / (MAX - MIN);

interface IngestProgressProps {
  ingestJob: IngestJob | null;
}
export const IngestProgress: VFC<IngestProgressProps> = ({ ingestJob }) => {
  const index = ingestJob ? stepCountByStage[ingestJob.currentStage] : 0;

  return (
    <Box sx={{ width: "600px" }}>
      <Typography variant="h5" color="primary">
        Status:{" "}
        {ingestJob?.currentStage
          ? messageByStage[ingestJob.currentStage]
          : "Uploading"}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={normalise(index)}
        sx={{ width: "100%", marginTop: ({ spacing }) => spacing(2) }}
      />
    </Box>
  );
};
