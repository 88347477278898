import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { BenefitsVerificationProvider } from "./BeneftsVerificationProvider";
import { CareMetxPavbluRequestPage } from "./pages/CareMetxPavbluRequestPage";
import { CreateBenefitsVerificationRequest } from "./pages/CreateBenefitsVerificationRequest";
import { EditBenefitsVerificationRequest } from "./pages/EditBenefitsVerificationRequest";
import { SelectBenefitsVerificationType } from "./pages/SelectBenefitsVerificationType";
import { UnifiedBenefitsCheckGrid } from "./pages/UnifiedBenefitsCheckGrid";
import { ViewUnifiedBenefitsCheck } from "./pages/ViewUnifiedBenefitsCheck";
import { UnifiedBenefitsCheckProvider } from "./UnifiedBenefitsCheckProvider";

export const BenefitsVerifications: React.VoidFunctionComponent = () => {
  const { path } = useRouteMatch<{ id: string }>();

  return (
    <Switch>
      <Route path={`${path}/view/:id`}>
        <UnifiedBenefitsCheckProvider>
          <ViewUnifiedBenefitsCheck />
        </UnifiedBenefitsCheckProvider>
      </Route>
      <Route path={`${path}/create`}>
        <CreateBenefitsVerificationRequest />
      </Route>
      <Route path={`${path}/type`}>
        <SelectBenefitsVerificationType />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <BenefitsVerificationProvider>
          <EditBenefitsVerificationRequest />
        </BenefitsVerificationProvider>
      </Route>
      <Route path={`${path}/caremetx-pavblu-request/:id`}>
        <UnifiedBenefitsCheckProvider>
          <CareMetxPavbluRequestPage />
        </UnifiedBenefitsCheckProvider>
      </Route>
      <Route>
        <UnifiedBenefitsCheckGrid />
      </Route>
    </Switch>
  );
};
