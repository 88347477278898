import { useQuery } from "@apollo/client";
import {
  InstitutionsQuery,
  InstitutionsQueryVariables,
} from "@@generated/graphql";
import { allInstitutionsQuery } from "../graphql/Institution";

export const useAllInstitutions = () => {
  const { data } = useQuery<InstitutionsQuery, InstitutionsQueryVariables>(
    allInstitutionsQuery
  );
  return data?.institutionsFindAll ?? null;
};
