import { PureComponent } from "react";
import styled from "styled-components";
import ReactCrop from "react-image-crop";
import { withAlert } from "react-alert";
import PDFJS from "pdfjs-dist/es5/build/pdf";

import "react-image-crop/dist/ReactCrop.css";

import { ModalBodyLarge, ModalFooter } from "../../ModalStyledComponents";
import Modal from "../../Modal";
import BaseButton from "../../BaseButton";
import SamaDropzone from "../../SamaDropzone";

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;
const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const ModalContent = styled.div`
  padding: 10px 0;
`;

const CropContainer = styled.div`
  height: 500px;
  overflow-y: scroll;
  margin-bottom: 10px;
`;

function getCroppedImg(image, crop) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = Math.ceil(crop.width * scaleX);
  canvas.height = Math.ceil(crop.height * scaleY);
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  // As Base64 string
  return canvas.toDataURL("image/png");
}

const defaultState = {
  imageSrc: null,
  crop: {
    unit: "px",
    x: 130,
    y: 50,
    width: 200,
    height: 200,
  },
};

class SignatureInput extends PureComponent {
  state = defaultState;

  resetState = () => {
    this.setState(defaultState);
  };

  saveCroppedSignature = async (institutionWide) => {
    const { saveAndSetSignature } = this.props;
    const { crop } = this.state;

    const dataUrl = getCroppedImg(this.imageRef, crop);
    await saveAndSetSignature(dataUrl, institutionWide);
    this.resetState();
  };

  onDropSuccess = async (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      if (file.type === CONFIG.CONSTANTS.CONTENT_TYPES.PDF) {
        reader.onload = async () => {
          const pdf = await PDFJS.getDocument(reader.result).promise;
          const page = await pdf.getPage(1);
          const scale = 1.5;
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement("canvas");

          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          await page.render({ canvasContext: context, viewport }).promise;
          this.setState({ imageSrc: canvas.toDataURL("image/png") });
        };
        reader.readAsArrayBuffer(file);
      } else {
        reader.addEventListener("load", () =>
          this.setState({ imageSrc: reader.result })
        );
        reader.readAsDataURL(file);
      }
    }
  };

  onDropError = () => {
    const { alert } = this.props;
    alert.error(
      "There was an error uploading the document. Please ensure you file is under 10 MB"
    );
  };

  render() {
    const { crop, imageSrc } = this.state;

    return (
      <Container>
        <SamaDropzone
          // megabytes
          maxSize={10 * 1000000}
          onDrop={this.onDropSuccess}
          onDropRejected={this.onDropError}
          accept={[
            CONFIG.CONSTANTS.CONTENT_TYPES.PDF,
            CONFIG.CONSTANTS.CONTENT_TYPES.PNG,
            CONFIG.CONSTANTS.CONTENT_TYPES.JPEG,
          ]}
          className="dropzone"
          buttonText="Browse Signatures"
          helperText="Files must be in .pdf, .png, .jpeg format"
        />
        {imageSrc && (
          <Modal
            header="Crop your image"
            onClick={this.resetState}
            open={!!imageSrc}
          >
            <ModalBodyLarge>
              <ModalContent>
                <CropContainer>
                  <ReactCrop
                    src={imageSrc}
                    crop={crop}
                    onChange={(newCrop) => {
                      this.setState({ crop: newCrop });
                    }}
                    onImageLoaded={(image) => {
                      this.imageRef = image;
                    }}
                  />
                </CropContainer>
                <ModalFooter>
                  <BaseButton
                    style={{ width: "100%" }}
                    type="button"
                    onClick={() => {
                      this.saveCroppedSignature(false);
                    }}
                  >
                    Save to my Account
                  </BaseButton>
                  <BaseButton
                    type="button"
                    style={{ width: "100%", marginLeft: "10px" }}
                    onClick={() => {
                      this.saveCroppedSignature(true);
                    }}
                  >
                    Save to my Practice (All Users)
                  </BaseButton>
                </ModalFooter>
              </ModalContent>
            </ModalBodyLarge>
          </Modal>
        )}
      </Container>
    );
  }
}

/**
 * @deprecated Use a MUI component instead
 */
export default withAlert()(SignatureInput);
