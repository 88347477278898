import gql from "graphql-tag";
import * as FullStory from "@fullstory/browser";
import createClient from "./apollo";

const apolloClient = createClient();

export const persistSessionURL = gql`
  mutation saveSessionRecordingReference(
    $AuthorizationId: String!
    $sessionURL: String!
  ) {
    saveSessionRecordingReference(
      sessionURL: $sessionURL
      AuthorizationId: $AuthorizationId
    ) {
      success
    }
  }
`;

export class UserSessionRecorder {
  private initialized = false;
  private FULL_STORY_ID = "";

  public constructor({ FULL_STORY_ID }: { FULL_STORY_ID: string }) {
    this.FULL_STORY_ID = FULL_STORY_ID;

    if (FULL_STORY_ID !== "") {
      FullStory.init({ orgId: FULL_STORY_ID });
      this.initialized = true;
    }
  }

  public identify(accountId: string, name: string, email: string): void {
    if (this.initialized) {
      FullStory.identify(accountId, {
        displayName: name,
        email,
      });
    }
  }

  public async tagAuthorizationSession(AuthorizationId: string): Promise<void> {
    if (this.initialized) {
      let sessionURL = FullStory.getCurrentSessionURL(true);

      if (sessionURL == null) {
        // The user can trigger tagAuthorizationSession prior to FullStory beginning recording, for example, when refreshing the page from authorization flow. Attempt to wait for recording to start
        await new Promise((resolve) => setTimeout(resolve, 1500));
        sessionURL = FullStory.getCurrentSessionURL(true);
      }

      if (AuthorizationId && sessionURL !== null) {
        await apolloClient.mutate({
          mutation: persistSessionURL,
          variables: { AuthorizationId, sessionURL },
        });
      }
    }
  }
}

export default new UserSessionRecorder({
  FULL_STORY_ID: window.CONFIG.FULL_STORY_ID,
});
