import { useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { BsQuestion as QuestionIcon } from "@react-icons/all-files/bs/BsQuestion";
import { MdMail as EmailIcon } from "@react-icons/all-files/md/MdMail";
import { BsChatFill as ChatIcon } from "@react-icons/all-files/bs/BsChatFill";
import { BaseButton } from "./BaseButton";

const ParentContainer = styled.div`
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 9999998;
`;

const Modal = styled.div`
  background-color: ${(props) => props.theme.lightestPurple};
  width: 300px;
  height: 340px;
  border-radius: 25px;
  border: 2px solid ${(props) => props.theme.purple};
  padding: 18px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0 5px 4px 0 ${(props) => props.theme.lightGray};
`;

const WidgetContainer = styled.div`
  background-color: ${(props) => props.theme.lightestPurple};
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 5px 4px 0 ${(props) => props.theme.lightGray};
`;

const IntroText = styled.span`
  font-size: 20px;
`;

const CaptionText = styled.span`
  margin-top: 5px;
  font-size: 14px;
  color: ${(props) => props.theme.darkGray};
`;

const PurpleQuestion = styled(QuestionIcon)`
  width: 35px;
  height: 35px;
  color: ${(props) => props.theme.purple};
`;

const WhiteChat = styled(ChatIcon)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const WhiteEmail = styled(EmailIcon)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const HorizontalLogo = styled.img`
  height: 100%;
  width: 100%;
`;

const LogoContainer = styled.div`
  height: 28px;
  width: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PurpleButton = styled(BaseButton)`
  background-color: ${({ theme, disabled }) =>
    disabled! ? theme.disabledGray : theme.purple};
  border-color: ${({ theme, disabled }) =>
    disabled! ? theme.disabledGray : theme.purple};
  color: white;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${({ theme, disabled }) => (disabled! ? "white" : theme.purple)};
    background: ${({ theme, disabled }) =>
      disabled! ? theme.disabledGray : "white"};
  }
`;

const FreshdeskNavigator: React.VFC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = async () => {
    setIsModalOpen(!isModalOpen);
  };

  const openChat = async () => {
    // close modal
    await toggleModal();
    // open chat
    window.fcWidget.show();
    window.fcWidget.open();
  };

  const openEmail = async () => {
    // close modal
    await toggleModal();

    // open chat
    window.FreshworksWidget("open");
  };

  if (window.FreshworksWidget === undefined)
    // eslint-disable-next-line no-console
    console.error("Cannot load email widget");
  // eslint-disable-next-line no-console
  if (window.fcWidget === undefined) console.error("Cannot load email widget");

  return (
    <ParentContainer>
      {!isModalOpen && (
        <WidgetContainer>
          <PurpleQuestion onClick={toggleModal} />
        </WidgetContainer>
      )}
      {isModalOpen && (
        <Modal onClick={toggleModal}>
          <LogoContainer>
            <HorizontalLogo src="../../assets/purple-logo-horizontal.png" />
          </LogoContainer>
          <IntroText>
            Got questions or feedback? <br />
            We are here to help!
          </IntroText>
          <div>
            <PurpleButton
              disabled={window.FreshworksWidget === undefined}
              onClick={openEmail}
            >
              <WhiteEmail />
              <div>Email Us</div>
            </PurpleButton>
            <CaptionText>
              We typically get back within 1 business day
            </CaptionText>
          </div>
          <div>
            <PurpleButton
              onClick={openChat}
              disabled={window.fcWidget === undefined}
            >
              <WhiteChat />
              <div>Chat with Us</div>
            </PurpleButton>
            <CaptionText>Live chat available 7am - 4pm PT</CaptionText>
          </div>
        </Modal>
      )}
    </ParentContainer>
  );
};

export default FreshdeskNavigator;
