import _ from "lodash";

import {
  IEnrollmentServiceResponseBodyType,
  IEntry,
} from "./EnrollmentServiceResponseBodyType";

export class Entry {
  parser: EnrollmentServiceResponseParser;
  entry: IEntry;
  constructor(parser: EnrollmentServiceResponseParser, entry: IEntry) {
    this.parser = parser;
    this.entry = entry;
  }

  getId(): string {
    return this.entry.resource.id;
  }

  getType(): string {
    return this.entry.resource.resourceType;
  }

  getRawText(): string | undefined {
    return this.entry.resource.text?.div;
  }

  allEntries(): Entry[] {
    return _.map(this.entry.resource.entry ?? [], (entry) => {
      return this.parser.entryFor(entry);
    });
  }

  // This function flattens a somewhat obnoxious data structure into a simple list of KV pairs
  getAttributes(): { key: string; value: string | undefined }[] {
    switch (this.getType()) {
      case "Patient":
        return [
          ..._.map(
            this.entry.resource.name as {
              use: string;
              family: string;
              given: string[];
            }[],
            ({ use, family, given }) => ({
              key: `Name (${use})`,
              value: `${family}, ${given.join(" ")}`,
            })
          ),
          {
            key: `${this.entry.resource.identifier?.[0].assigner.display} ID`,
            value: this.entry.resource.identifier?.[0].value,
          },
          {
            key: "DOB",
            value: this.entry.resource.birthDate,
          },
          {
            key: "Gender",
            value: this.entry.resource.gender,
          },
          // communication ??
          ..._.map(
            this.entry.resource.address,
            ({ line, city, state, postalCode, country }, idx) => ({
              key: `Address${idx ? ` ${idx}` : ""})`,
              value: `${line.join(
                " "
              )}, ${city}, ${state} ${postalCode}, ${country}`,
            })
          ),
          ..._.map(
            _.reverse(_.sortBy(this.entry.resource.telecom, "rank")),
            ({ use, value, system }) => ({
              key: `${_.capitalize(system)} (${use})`,
              value,
            })
          ),
        ];
      case "CoverageEligibilityResponse":
        return [
          // To make the response relevant, we include all of the attributes from the "insurer" (Organization) resource
          ...(this.parser
            .entryForId(this.entry.resource.insurer!.reference)
            ?.getAttributes() ?? []),
          { key: "Outcome", value: this.entry.resource.outcome },
          { key: "Disposition", value: this.entry.resource.disposition! },
          ..._.map(this.entry.resource.identifier, ({ assigner, value }) => ({
            key: `${assigner.display} ID`,
            value,
          })),
        ];
      case "Organization":
        return [
          { key: "Name", value: this.entry.resource.name as string },
          ..._.map(this.entry.resource.identifier, ({ assigner, value }) => ({
            key: `${assigner.display} ID`,
            value,
          })),
          ..._.map(
            _.reverse(_.sortBy(this.entry.resource.telecom, "rank")),
            ({ use, value, system }) => ({
              key: `${_.capitalize(system)} (${use})`,
              value,
            })
          ),
        ];

      default:
        return [];
    }
  }
}

export class EnrollmentServiceResponseParser {
  body: IEnrollmentServiceResponseBodyType;
  entriesById: { [id: string]: Entry };

  constructor(body: IEnrollmentServiceResponseBodyType) {
    this.body = body;
    this.entriesById = _.keyBy(this.allEntries(), (e) => e.getId());
  }

  getStatusCode(): string | undefined {
    const entries = this.body.entry ?? [];
    const header = _.first(
      _.filter(
        entries,
        (e) => _.get(e, "resource.resourceType") === "MessageHeader"
      )
    );
    return header?.resource?.response?.code;
  }

  entryForId(id: string): Entry | undefined {
    const cleanId = id.startsWith("#") ? id.slice(1) : id;
    return this.entriesById[cleanId];
  }

  entryFor(e: IEntry): Entry {
    return new Entry(this, e);
  }

  getResponseTextNodes(): string[] {
    return _.compact(_.map(this.allEntries(), (e) => e.getRawText()));
  }

  allEntries(): Entry[] {
    return _.flatMap(this.body.entry ?? [], (e) => [
      this.entryFor(e),
      ...this.entryFor(e).allEntries(),
    ]);
  }

  findEntryByType(type: string): Entry | undefined {
    return _.find(this.allEntries(), (e) => e.getType() === type);
  }

  getSummarySections(): Entry[] {
    return _.flatten([
      this.findEntryByType("CoverageEligibilityResponse"),
      this.findEntryByType("Patient"),
    ]) as Entry[];
  }
}
