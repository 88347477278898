import { useCallback } from "react";
import { useDispatch } from "../configureStore";
import { setDrugOptionId } from "../reducers/formReducer";

export const useSetDrugOptionId = () => {
  const dispatch = useDispatch();

  return useCallback(
    (drugOptionId: number | null) => {
      dispatch(setDrugOptionId(drugOptionId));
    },
    [dispatch]
  );
};
