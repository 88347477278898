import * as React from "react";
import { Section as BaseSection } from "react-headings";

export type SectionProps = React.ComponentProps<typeof Section>;

/**
 * @deprecated Use a MUI component instead
 */
export const Section: React.VoidFunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  return <BaseSection component={null} {...props} />;
};
