import * as React from "react";
import styled from "styled-components";

export type ActionRequiredRowContainer = React.ComponentProps<
  typeof ActionRequiredRowContainer
>;

export const ActionRequiredRowContainer: React.VoidFunctionComponent<{
  children: React.ReactNode;
}> = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  background-color: ${({ theme }) => theme.lightPurple};
`;
