import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@samacare/design";
import { useTheme } from "@samacare/design/core/styles";
export const Rule = ({ sx }) => {
    const theme = useTheme();
    return (_jsx(Box, { pt: 1, pb: 1, pr: 2, pl: 2, width: "100%", sx: sx, children: _jsx(Box, { sx: {
                width: "100%",
                height: "1px",
                background: theme.palette.divider,
            } }) }));
};
