import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export type LoadingSpinnerProps = React.ComponentProps<typeof LoadingSpinner>;

/**
 * @deprecated Use a MUI component instead
 */
export const LoadingSpinner = styled.div.attrs({
  "aria-busy": true,
  "aria-label": "Loading Indicator",
})<{
  color?: string;
  colorTop?: string;
  height?: number;
  open?: boolean;
  width?: number;
}>`
  animation: ${spin} 1s infinite linear;
  border: 4px solid ${({ color = "rgba(255,255,255,0.2)" }) => color};
  border-radius: 50%;
  border-top-color: ${({ colorTop = "rgba(255,255,255)" }) => colorTop};
  height: ${({ height = 20 }) => height}px;
  margin-right: 20px;
  width: ${({ width = 20 }) => width}px;
`;

/**
 * @deprecated Use a MUI component instead
 */
export default LoadingSpinner;
