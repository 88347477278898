import gql from "graphql-tag";

export default gql`
  fragment authorizationCorrespondenceInfo on AuthorizationCorrespondence {
    id
    createdAt
    startDate
    endDate
    fileURL
    mimeType
    isLatest
    authorizedProcedures
    code
    type
    label
    isHidden
    InstitutionId
    AuthorizationId
    patientMemberId
    patientLastName
    suggestedStatus
    fileAWSKey
    preAssociationAuthorizationId
    originatingFaxNumber
    reviewNotRequired
    rejectedAuthorizationIds
  }
`;
