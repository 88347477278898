export const getInsuranceType = (type?: string | null): string => {
  if (type == null) {
    return "-";
  }

  let mapping;
  switch (type) {
    case window.CONFIG.DEFAULT_FIELDS.IS_COMMERCIAL.key:
      mapping = "Commercial";
      break;
    case window.CONFIG.DEFAULT_FIELDS.IS_MEDICARE_ADVANTAGE.key:
      mapping = "Medicare Advantage";
      break;
    case window.CONFIG.DEFAULT_FIELDS.IS_MEDICAID.key:
      mapping = "Medicaid";
      break;
    default:
      mapping = "Other";
  }
  return mapping;
};
