import _ from "lodash";
import moment from "moment";
import React from "react";

import { Box, Stack, Typography } from "@samacare/design/core";
import PersonIcon from "@samacare/design/core/icons/Person";
import PhoneIcon from "@samacare/design/core/icons/Phone";
import PlaceIcon from "@samacare/design/core/icons/Place";

import { formatPhoneNumber } from "../../../components/PDFEditor/InputTypes/PhoneInput";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";

export const PatientDetails: React.FC = () => {
  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();

  if (!unifiedBenefitsCheck) {
    return <Box />;
  }

  const { patient } = unifiedBenefitsCheck;
  const patientLine = _.compact([
    patient.firstName,
    patient.lastName,
    moment(patient.dob).format("(MM/DD/YYYY)"),
  ]).join(" ");
  const patientAddress = _.compact([
    patient.address,
    patient.state,
    patient.zip,
  ]).join(", ");
  return (
    <Stack
      direction="column"
      padding={2}
      borderRadius={2}
      border="1px solid rgba(0, 0, 0, 0.42)"
    >
      <Box>
        <PersonIcon
          sx={{
            width: 24,
            height: 24,
            color: "rgba(0, 0, 0, 0.56)",
            marginLeft: "-3px",
          }}
        />
        <Typography
          variant="h6"
          display="inline-block"
          marginLeft={1}
          sx={{ verticalAlign: "middle" }}
        >
          {patientLine}
        </Typography>
      </Box>
      <Box>
        <Box display="inline-block">
          <PhoneIcon
            sx={{ width: 18, height: 18, color: "rgba(0, 0, 0, 0.54)" }}
          />
          <Typography variant="body1" display="inline-block" marginLeft={1}>
            {formatPhoneNumber(patient.phone ?? "")}
          </Typography>
        </Box>
        <Box display="inline-block" marginLeft={7}>
          <PlaceIcon
            sx={{ width: 18, height: 18, color: "rgba(0, 0, 0, 0.54)" }}
          />
          <Typography variant="body1" display="inline-block" marginLeft={1}>
            {patientAddress}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
