import * as React from "react";
import styled from "styled-components";
import { Warning } from "@@ui-kit/Icons";
import colors from "Resources/colors";

const StatusWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 9px;
`;

const StatusLabel = styled.div`
  margin-right: 9px;
`;

const StatusValue = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.purple};
`;

const SecondaryValue = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.darkGray};
  margin-left: 9px;
`;

const IconWrapper = styled.div`
  position: relative;
  min-width: 24px;
  height: 24px;
`;

const InnerIconWrapper = styled.div`
  position: absolute;
  top: -2px;
`;

interface IWarningIconProps {
  style?: React.CSSProperties;
}
const WarningIcon = (props: IWarningIconProps) => (
  <IconWrapper style={props.style}>
    <InnerIconWrapper>
      <Warning color={colors.warningOrange} size="24px" />
    </InnerIconWrapper>
  </IconWrapper>
);

interface IStatusFieldProps {
  label: string;
  value: string;
  secondaryValue?: string;
  isError?: boolean;
  style?: React.CSSProperties;
}
export const StatusField: React.VFC<IStatusFieldProps> = (props) => (
  <StatusWrapper style={props.style}>
    <StatusLabel>{`${props.label} Status:`}</StatusLabel>
    {props.isError === true && (
      <WarningIcon style={{ marginRight: "3px", alignSelf: "center" }} />
    )}
    <StatusValue>{props.value}</StatusValue>
    {props.secondaryValue != null && (
      <SecondaryValue>{`(${props.secondaryValue})`}</SecondaryValue>
    )}
  </StatusWrapper>
);
