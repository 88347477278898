import { PureComponent } from "react";

import { genericInput } from "../InputPropTypes";
import { BaseTextArea, getPropsFromInput } from "./BaseInput";

class TextMultilineInput extends PureComponent {
  static propTypes = {
    input: genericInput.isRequired,
  };

  render() {
    const { input } = this.props;

    return (
      <BaseTextArea
        type="text"
        {...getPropsFromInput(input)}
        onChange={(e) => {
          input.onChange({ [input.id]: e.target.value });
        }}
        value={input.value || ""}
      />
    );
  }
}

export default TextMultilineInput;
