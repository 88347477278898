import Autocomplete from "@samacare/design/core/Autocomplete";
import TextField from "@samacare/design/core/TextField";
import { useConfig } from "../../../hooks";

interface AvailityServiceReviewSearchMappingsKeySelectorProps {
  availityServiceReviewSearchMappingsKey: string;
  setAvailityServiceReviewSearchMappingsKey: (key: string | null) => void;
}

export const AvailityServiceReviewSearchMappingsKeySelector: React.FC<
  AvailityServiceReviewSearchMappingsKeySelectorProps
> = ({
  availityServiceReviewSearchMappingsKey,
  setAvailityServiceReviewSearchMappingsKey,
}) => {
  const config = useConfig();

  const handleKeyChange = (_: unknown, value: string | null) => {
    setAvailityServiceReviewSearchMappingsKey(value);
  };

  return (
    <Autocomplete
      selectOnFocus
      handleHomeEndKeys
      autoHighlight
      onChange={handleKeyChange}
      value={availityServiceReviewSearchMappingsKey}
      options={
        config.CONSTANTS
          .AVAILITY_SERVICE_REVIEW_SEARCH_MAPPINGS_KEYS as string[]
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label="Availity Service Review Search Mappings Key"
        />
      )}
    />
  );
};
