import {
  Account,
  Authorization,
  AuthorizationStatusEnum,
  AuthorizationType,
  HcpcsCode,
  IntegratedEmrFilesDownloaded,
  IntegrationInstallation,
  Maybe,
  Mutation,
  MutationCreateAuthorizationArgs,
  MutationCreateAuthorizationFromBvArgs,
  MutationCreateAuthorizationFromEnrollmentArgs,
  PaOriginType,
  Prescriber,
  TrackingStatus,
} from "@samacare/graphql";
import _ from "lodash";
import moment from "moment";
import { Forms } from "../routes/NewAuthorization/SelectFormModal/types";
import { isValidDate } from "./dateUtils";
import { Config } from "@@hooks/config";
import { useAlert } from "react-alert";
import { useHistory } from "../hooks/routing";
import { MutationFunction } from "@apollo/client";
import ROUTE_PATHS from "../routes/ROUTE_PATHS";
import { PortalAuth } from "app/types/PortalAuth";

export const getFriendlyString = (authStatus: string): string => {
  return _.upperFirst(authStatus.replace(/_/g, " "));
};

export const formatPrescriber = (p: Prescriber): string => {
  const defaultValue = `${_.get(p, "firstName") ?? ""} ${
    _.get(p, "lastName") ?? ""
  }`;
  return _.get(p, "label") || defaultValue;
};

export const isTrackedPortalAuth = (auth: Authorization): boolean => {
  return (
    auth.type === AuthorizationType.Portal ||
    auth.type === AuthorizationType.PortalExternal
  );
};

export const needCurrentUserAction = (
  auth: Authorization,
  acc: Account
): boolean => {
  return (
    isTrackedPortalAuth(auth) &&
    auth.statusCheckLoginRequiresAttn === true &&
    (auth.createdById === acc.id || acc.isSamaUser) &&
    (auth.portalKey != null || auth.PortalId != null) &&
    auth.trackingStatus != null &&
    [TrackingStatus.BlockedOnMfa, TrackingStatus.WrongCreds].includes(
      auth.trackingStatus
    )
  );
};

export const NO_ASSOCIATED_INSURANCE_LABEL = "No associated insurance";
export const getInsuranceLabel = (authorization: Authorization) => {
  const isExternalAuth =
    authorization.type ===
    window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.value;

  if (authorization.portalKey || authorization.PortalId) {
    let label = "[Portal]";

    if (authorization.portal && authorization.portal.title) {
      label += ` ${authorization.portal.title}`;

      if (authorization.insuranceCompany?.name) {
        label += ` - ${authorization.insuranceCompany?.name}`;
      }
    } else if (authorization.insuranceCompany?.name) {
      label += ` ${authorization.insuranceCompany?.name}`;
    } else {
      label += " No associated insurance";
    }
    return label;
  }

  if (isExternalAuth === true && authorization.insuranceCompany?.name != null) {
    return `[External] ${authorization.insuranceCompany?.name}`;
  }

  if (isExternalAuth === true && authorization.insuranceCompany?.name == null) {
    return "[External] No associated insurance";
  }

  if (
    authorization.formType != null &&
    authorization.formType ===
      window.CONFIG.CONSTANTS.AUTHORIZATION_FORM_TYPE.MANUALLY_TAGGED_FORM &&
    authorization.insuranceCompany?.name != null
  ) {
    return `[Manual] ${authorization.insuranceCompany?.name}`;
  }

  if (
    authorization.formType != null &&
    authorization.formType ===
      window.CONFIG.CONSTANTS.AUTHORIZATION_FORM_TYPE.MANUALLY_TAGGED_FORM &&
    authorization.insuranceCompany?.name == null
  ) {
    return "[Manual] No associated insurance";
  }

  return authorization.insuranceCompany?.name ?? NO_ASSOCIATED_INSURANCE_LABEL;
};

export const getAuthDescription = (authorization: Authorization): string => {
  const description = authorization.config?.[
    window.CONFIG.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key
  ] as string | undefined;
  const dateOfService = authorization.config?.[
    window.CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE.key
  ] as string | undefined;

  if (dateOfService && isValidDate(dateOfService)) {
    return `[${dateOfService}] ${description ?? ""}`;
  }
  // Older auths won't have a date of service
  if (!_.isNil(description) && description !== "") {
    return `${description}`;
  }
  // Auths that aren't yet initiated will not have a description or date of service
  return "";
};

export const getFilteredFiles = (
  selectedCategoryParam: string,
  files: IntegratedEmrFilesDownloaded[],
  attachments: { awsKey: string }[]
): IntegratedEmrFilesDownloaded[] => {
  return _.sortBy(
    _.filter(
      files,
      (file) =>
        file?.category === selectedCategoryParam &&
        !_.find(attachments, { awsKey: file?.key })
    ),
    ({ date }: { date: string }) => moment(date)
  ).reverse() as IntegratedEmrFilesDownloaded[];
};

export const shouldDisplayEMRLoading = (input: {
  integrations: IntegrationInstallation[];
  isNextGenOrModMed: boolean;
  authorization: Authorization;
}): boolean => {
  const { integrations, isNextGenOrModMed, authorization } = input;
  const {
    integratedEMRFilesDownloaded,
    integratedEMRDocumentsDownloaded,
    createdAt,
  } = authorization;
  const { CONFIG } = window;

  const isOncoEmr =
    integrations.filter(
      (installation) =>
        [CONFIG.CONSTANTS.INTEGRATION_TITLE.oncoEMR].includes(
          installation.integrationId
        ) && installation.isEnabled
    ).length > 0;

  return (
    (isOncoEmr &&
      integratedEMRFilesDownloaded != null &&
      _.isEmpty(integratedEMRFilesDownloaded) &&
      moment().diff(createdAt, "minutes") <
        CONFIG.CONSTANTS.ONCOEMR_FORM_DELAY_MINUTES &&
      !integratedEMRDocumentsDownloaded) ||
    (isNextGenOrModMed &&
      !integratedEMRDocumentsDownloaded &&
      integratedEMRFilesDownloaded != null)
  );
};

export type AwsDocument = {
  name: string;
  id?: string;
  date?: string;
  awsKey: string;
  title: string;
  fileURL?: string;
};

export const containsUnnamedDocuments = (documents: AwsDocument[]): boolean =>
  !_.every(documents, (doc) => doc.name);

export const getDateValidationMessages = (params: {
  correspondenceStartDate?: string;
  correspondenceEndDate?: string;
  correspondenceCode?: string;
  authorizedProcedures?: { title: string; value: string }[];
}): {
  errorMessages: string[];
  warningMessages: string[];
} => {
  const {
    correspondenceStartDate,
    correspondenceEndDate,
    correspondenceCode,
    authorizedProcedures,
  } = params;

  let errorMessages: any[] = [];
  let warningMessages: any[] = [];
  const { DATE_FORMAT } = window.CONFIG.CONSTANTS;

  const isAnythingEntered =
    correspondenceStartDate ||
    correspondenceEndDate ||
    correspondenceCode ||
    !_.isEmpty(
      _.filter(
        authorizedProcedures,
        ({ title, value }) =>
          !_.isEmpty(_.trim(title)) && !_.isEmpty(_.trim(value))
      )
    );

  if (!isAnythingEntered) {
    return { errorMessages: ["No details entered"], warningMessages };
  }

  if (correspondenceStartDate) {
    if (!moment(correspondenceStartDate, DATE_FORMAT, true).isValid()) {
      errorMessages = [...errorMessages, "Invalid start date"];
    }

    if (
      moment(correspondenceStartDate, DATE_FORMAT, true) <
      moment().subtract(1, "month")
    ) {
      warningMessages = [...warningMessages, "Start date is in the past"];
    }
  }

  if (correspondenceEndDate) {
    if (!moment(correspondenceEndDate, DATE_FORMAT, true).isValid()) {
      errorMessages = [...errorMessages, "Invalid end date"];
    }

    if (
      moment(correspondenceEndDate, DATE_FORMAT, true) <
      moment().subtract(1, "month")
    ) {
      warningMessages = [...warningMessages, "End date is in the past"];
    }
  }

  if (
    !_.isEmpty(correspondenceEndDate) &&
    !_.isEmpty(correspondenceStartDate)
  ) {
    if (
      moment(correspondenceEndDate, DATE_FORMAT) <
      moment(correspondenceStartDate, DATE_FORMAT)
    ) {
      errorMessages = [...errorMessages, "End date before start date"];
    }
  }

  return { errorMessages, warningMessages };
};

export const sortForms = (forms: Forms) => {
  const favoriteForms = forms.favoriteForms || [];
  const favoriteFormIds = new Set(favoriteForms.map((f) => f.id));
  const allForms = _.uniqBy(
    [
      ...(forms.stateForms || []),
      ...(forms.payerForms || []),
      ...(forms.suggestedForms || []),
      ...favoriteForms,
    ],
    "id"
  );

  return _.orderBy(
    allForms,
    [
      (form) => favoriteFormIds.has(form.id),
      (form) => (form.approvalCount === null ? -Infinity : form.approvalCount),
    ],
    ["desc", "desc"]
  );
};

export const mapHcpcsCodesToAuthConfig = (
  config: Config,
  hcpcsCodes?: HcpcsCode[]
): Record<string, string> => {
  if (!hcpcsCodes || hcpcsCodes.length === 0) {
    return {};
  }

  const maxEntries: number = Number(
    config.DEFAULT_FIELDS.POSSIBLE_ICD_AND_HCPCS_ENTRIES ?? 5
  );

  const slicedHcpcsCodes = hcpcsCodes.slice(0, maxEntries);

  const authConfig: Record<string, string> = {};

  slicedHcpcsCodes.forEach((hcpcsCodeData, index) => {
    const codeKey: string | undefined = _.get(
      config,
      `DEFAULT_FIELDS.HCPCS_${index}.key`
    );
    const quantityKey: string | undefined = _.get(
      config,
      `DEFAULT_FIELDS.HCPCS_QUANTITY_${index}.key`
    );

    if (codeKey && quantityKey && hcpcsCodeData.code) {
      authConfig[codeKey] = hcpcsCodeData.code;
      authConfig[quantityKey] = "";
    }
  });

  return authConfig;
};

const generateConfigFieldsForAccount = (
  config: Config,
  account: Record<string, unknown>
): Partial<Authorization["config"]> => {
  if (!account) return {};
  return {
    [config.DEFAULT_FIELDS.REQUESTED_BY_FIRST_NAME.key]: account.firstName,
    [config.DEFAULT_FIELDS.REQUESTED_BY_LAST_NAME.key]: account.lastName,
    [config.DEFAULT_FIELDS.REQUESTED_BY
      .key]: `${account.firstName} ${account.lastName}`,
    [config.DEFAULT_FIELDS.REQUESTED_BY_PHONE.key]: account.phone,
    [config.DEFAULT_FIELDS.REQUESTED_BY_EXTENSION.key]: account.extension,
  };
};

const getDefaultFields = (
  config: Config,
  type: string,
  account: Record<string, unknown>,
  HCPCSCodes: HcpcsCode[],
  portal?: { id?: string; key?: string; isLegacy: boolean },
  portalAuth?: PortalAuth,
  isBlendedPortalWorkflow?: boolean | null
) => {
  if (!account) {
    throw new Error("Account not yet available");
  }

  if (!type) {
    throw new Error("Authorization type must be specified");
  }

  const defaultFields: Record<string, unknown> = {
    config: {
      ...generateConfigFieldsForAccount(config, account),
      [config.DEFAULT_FIELDS.EMAIL_NOTIFICATION_TYPE.key]: "On",
      [config.DEFAULT_FIELDS.DEFAULT_CHECKMARK_TRUE.key]: "On",
    },
    status: config.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION,
    type,
    ...(isBlendedPortalWorkflow
      ? { PortalId: portal?.id }
      : { portalKey: portal?.key }),
  };

  if (type === config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key) {
    const hcpcsFormatted = mapHcpcsCodesToAuthConfig(config, HCPCSCodes);

    return {
      ...defaultFields,
      config: {
        ...(defaultFields.config as object),
        ...hcpcsFormatted,
      },
      ...(portal?.key
        ? {
            isWebExtension: (
              config.CONSTANTS.WEB_EXTENSION_PORTAL_KEYS as string[]
            ).includes(portal.key),
          }
        : {}),
    };
  } else if (type === config.CONSTANTS.AUTHORIZATION_TYPES.REFERRAL.key) {
    return {
      ...defaultFields,
      isReferral: true,
      config: {
        ...(defaultFields.config as object),
        [config.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key]: "Referral Request",
      },
    };
  }
  return defaultFields;
};

export const doCreate = async ({
  type,
  portal,
  paOrigin,
  onClickConfigOverride = {},
  otherDefaultFields,
  account,
  config,
  patientId,
  createAuthorizationFromBv,
  createAuthorizationFromExistingEnrollment,
  createAuthorization,
  history,
  alert,
  configOverrides = {},
  isBlendedPortalWorkflow,
  portalAuth,
}: {
  type: string;
  paOrigin?: PaOriginType;
  portal?: { id?: string; key?: string; isLegacy: boolean };
  onClickConfigOverride?: Record<string, unknown>;
  otherDefaultFields?: {
    status: AuthorizationStatusEnum;
    HCPCSCodes: HcpcsCode[];
  };
  account: Maybe<Account>;
  config: Config;
  patientId?: string;
  createAuthorizationFromBv?: MutationFunction<
    Mutation,
    MutationCreateAuthorizationFromBvArgs
  >;
  createAuthorizationFromExistingEnrollment?: MutationFunction<
    Mutation,
    MutationCreateAuthorizationFromEnrollmentArgs
  >;
  createAuthorization: MutationFunction<
    Mutation,
    MutationCreateAuthorizationArgs
  >;
  portalAuth?: PortalAuth;
  history?: ReturnType<typeof useHistory>;
  alert: ReturnType<typeof useAlert>;
  configOverrides?: Record<string, unknown>;
  isBlendedPortalWorkflow?: boolean | null;
}): Promise<Authorization | undefined | null> => {
  if (!account) throw new Error("Account not yet available");
  if (!type) throw new Error("Authorization type must be specified");

  const typeToPathPrefixMap = {
    [config.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key]:
      ROUTE_PATHS.FORM_AUTHORIZATION.path,
    [config.CONSTANTS.AUTHORIZATION_TYPES.FORM.key]:
      ROUTE_PATHS.FORM_AUTHORIZATION.path,
    [config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key]:
      ROUTE_PATHS.PORTAL_AUTHORIZATION.path,
    [config.CONSTANTS.AUTHORIZATION_TYPES.REFERRAL.key]:
      ROUTE_PATHS.REFERRAL.path,
    [config.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key]:
      ROUTE_PATHS.MUTLI_PARTY_AUTH.path,
  };

  const defaultFields: Record<string, unknown> = {
    PatientId: patientId,
    ...getDefaultFields(
      config,
      type,
      account,
      otherDefaultFields?.HCPCSCodes ?? [],
      portal,
      portalAuth,
      isBlendedPortalWorkflow
    ),
  };

  defaultFields.config = {
    ...(defaultFields as unknown as { config: object }).config,
    ...configOverrides,
    ...onClickConfigOverride,
  };

  try {
    if (configOverrides?.benefitsVerificationId && createAuthorizationFromBv) {
      const res = await createAuthorizationFromBv({
        variables: {
          benefitsVerificationId:
            configOverrides?.benefitsVerificationId as string,
          patch: { type, config: defaultFields.config },
        },
      });
      if (history)
        history.push(
          `${typeToPathPrefixMap[type]}?step=1&id=${
            res.data?.createAuthorizationFromBv?.id || ""
          }`
        );
      return res.data?.createAuthorizationFromBv;
    } else if (
      configOverrides?.enrollmentId &&
      createAuthorizationFromExistingEnrollment
    ) {
      const res = await createAuthorizationFromExistingEnrollment({
        variables: {
          enrollmentId: configOverrides?.enrollmentId as string,
          patch: { type, config: defaultFields.config },
        },
      });
      if (history)
        history.push(
          `${typeToPathPrefixMap[type]}?step=1&id=${
            res.data?.createAuthorizationFromEnrollment?.id || ""
          }`
        );
      return res.data?.createAuthorizationFromEnrollment;
    } else if (otherDefaultFields && portalAuth && createAuthorization) {
      const res = await createAuthorization({
        variables: {
          defaultFields: {
            ...otherDefaultFields,
            ...defaultFields,
            PatientId: patientId,
            isDraft: true,
            ...(paOrigin
              ? {
                  paOrigin,
                }
              : {}),
          },
        },
      });

      return res?.data?.createAuthorization;
    }

    const res = await createAuthorization({ variables: { defaultFields } });
    if (history)
      history.push(
        `${typeToPathPrefixMap[type]}?step=1&id=${
          res.data?.createAuthorization?.id || ""
        }`
      );

    return res?.data?.createAuthorization;
  } catch (e) {
    alert.error("There was an error creating a new Authorization");
  }
};
