import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import colors from "Resources/colors";

import FavoriteIcon from "@samacare/design/core/icons/Favorite";
import EmptyFavoriteIcon from "@samacare/design/core/icons/FavoriteBorder";

import {
  SET_FAVORITE_FORM,
  DELETE_FAVORITE_FORM,
} from "../graphql/FavoriteForm";

const iconStyle = {
  margin: 0,
  height: "24px",
  width: "24px",
  cursor: "pointer",
  color: colors.purple,
};

interface FavoriteFormProps {
  isFavorite: boolean;
  formId: number;
  onChange?: (id: string) => void;
}

const FavoriteForm: React.FC<FavoriteFormProps> = ({
  isFavorite,
  formId,
  onChange,
}) => {
  const [setFavoriteForm] = useMutation(SET_FAVORITE_FORM);
  const [deleteFavoriteForm] = useMutation(DELETE_FAVORITE_FORM);
  const [hover, setHover] = useState(false);

  const handleClick = async () => {
    const variables = {
      authorizationFormId: formId,
    };

    if (onChange) onChange(formId.toString());

    if (isFavorite) {
      await deleteFavoriteForm({ variables });
    } else {
      await setFavoriteForm({ variables });
    }
  };

  return (
    <span
      data-cy={
        isFavorite ? "actionDeleteFavoriteForm" : "actionSetFavoriteForm"
      }
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {isFavorite !== hover ? (
        <FavoriteIcon sx={iconStyle} />
      ) : (
        <EmptyFavoriteIcon sx={iconStyle} />
      )}
    </span>
  );
};

export default FavoriteForm;
