import { Button, ButtonProps } from "@samacare/design";

interface RoundedButtonProps extends ButtonProps {}
export const RoundedButton: React.FC<RoundedButtonProps> = (props) => {
  const { sx, variant, children, ...restProps } = props;
  return (
    <Button
      variant={variant ?? "contained"}
      sx={{
        borderRadius: "50vmax",
        whiteSpace: "nowrap",
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Button>
  );
};
