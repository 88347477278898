import { useState, useEffect } from "react";
import { createPortal } from "react-dom";

import { Box, Typography } from "@samacare/design";

export const AUTH_TITLE_ID = "Portal-AuthTitle";

export const AuthTitle: React.FC = ({ children }) => {
  const [element, setElement] = useState<HTMLElement | null>(
    document.getElementById(AUTH_TITLE_ID)
  );

  useEffect(() => {
    if (element === null) {
      setElement(document.getElementById(AUTH_TITLE_ID));
    }
  }, [element]);

  return element !== null
    ? createPortal(
        <Box pt={3} px={5}>
          <Typography
            display="block"
            variant="h6"
            textAlign="center"
            color="primary"
          >
            {children}
          </Typography>
        </Box>,
        element
      )
    : null;
};
