import { Stack, FormLabel, Grid } from "@samacare/design/core";
import { QuestionnaireField } from "../../components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { DatePickerField } from "@samacare/form";

export type DiagnosisInfoBlockProps = {
  isSubmitted?: boolean;
  isRequired?: boolean;
};

export const HorizonUpliznaDiagnosisInfoBlock: React.FC<
  DiagnosisInfoBlockProps
> = ({ isSubmitted = false, isRequired = false }) => {
  return (
    <Stack direction="column" spacing={2} maxWidth={600}>
      <DatePickerField
        label="Date of Diagnosis"
        name="questionnaire.sama-prescriptionInfo-diagnosisDate"
        disabled={isSubmitted}
        textFieldProps={{ required: isRequired }}
      />
      <QuestionnaireField
        id="sama-isAqp4Positive"
        text="Has the patient ever tested positive for AQP4 antibodies?"
        type={QuestionTypeEnumType.Choice}
        disabled={isSubmitted}
        required
        answerOption={[
          {
            valueString: "Yes",
          },
          {
            valueString: "No",
          },
        ]}
      />
      <Stack direction="column" spacing={1}>
        <FormLabel required>Check all previous NMOSD therapies:</FormLabel>
        {[
          [
            {
              id: "sama-disagosisNew",
              type: QuestionTypeEnumType.Boolean,
              text: "None/new diagnosis",
            },
            {
              id: "sama-disagosisSatralizumab-mwge",
              type: QuestionTypeEnumType.Boolean,
              text: "Satralizumab-mwge",
            },
            {
              id: "sama-Riabni",
              type: QuestionTypeEnumType.Boolean,
              text: "Riabni",
            },
          ],
          [
            {
              id: "sama-Tocilizumab",
              type: QuestionTypeEnumType.Boolean,
              text: "Tocilizumab",
            },
            {
              id: "sama-Eculizumab",
              type: QuestionTypeEnumType.Boolean,
              text: "Eculizumab",
            },
            {
              id: "sama-Ruxience",
              type: QuestionTypeEnumType.Boolean,
              text: "Ruxience",
            },
          ],
          [
            {
              id: "sama-Steroid",
              type: QuestionTypeEnumType.Boolean,
              text: "Steroid",
            },
            {
              id: "sama-Rituxan",
              type: QuestionTypeEnumType.Boolean,
              text: "Rituxan",
            },
            {
              id: "sama-Truxima",
              type: QuestionTypeEnumType.Boolean,
              text: "Truxima",
            },
          ],
          [
            {
              id: "sama-Ultomiris",
              type: QuestionTypeEnumType.Boolean,
              text: "Ultomiris",
            },
            {
              id: "sama-diagnosisOther",
              type: QuestionTypeEnumType.String,
              text: "Other",
            },
          ],
        ].map((grid, index) => {
          return (
            <Grid container spacing={1} key={index}>
              {grid.map(({ id, type, text }) => (
                <Grid item xs={4} key={id}>
                  <QuestionnaireField
                    id={id}
                    type={type}
                    text={text}
                    disabled={isSubmitted}
                  />
                </Grid>
              ))}
            </Grid>
          );
        })}
      </Stack>
    </Stack>
  );
};
