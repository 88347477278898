import styled from "styled-components";
import SimplifiedModal from "@@components/SimplifiedModal";
import { Typography } from "@samacare/design/core";
import * as React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Pic = styled.img`
  height: 120px;
  width: 150px;
  margin-bottom: 20px;
`;

const ConfirmationModal = ({ onClose, open }) => (
  <SimplifiedModal
    header="Thank you"
    onClick={onClose}
    submit={onClose}
    open={open}
    submitText="Close and return to main list"
    minWidth="375px"
  >
    <Container>
      <Pic src="../../../assets/requestNewPayer.png" />
      <Typography variant="body1">
        Thank you for making our system smarter!
      </Typography>
      <Typography variant="body1">
        We will update this shortly from our end.
      </Typography>
    </Container>
  </SimplifiedModal>
);

export default ConfirmationModal;
