import * as React from "react";

import {
  Box,
  Link,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@samacare/design/core";
import { useBenefitsVerificationContext } from "../BeneftsVerificationProvider";

interface RightSideMenuProps {
  activeStep: number;
}

export const RightSideMenu: React.FC<RightSideMenuProps> = ({
  activeStep,
}: RightSideMenuProps) => {
  const { benefitsVerification } = useBenefitsVerificationContext();
  const isSummaryAvailable =
    benefitsVerification != null && benefitsVerification.outcome != null;
  return (
    <Paper>
      <Box padding={({ spacing }) => spacing(2)}>
        <Stepper
          orientation="vertical"
          activeStep={activeStep}
          connector={null}
        >
          <Step>
            <StepLabel>Initiate BV</StepLabel>
          </Step>
          <Step>
            <StepLabel>
              {benefitsVerification == null ? (
                <>Submission Details</>
              ) : (
                <Link
                  href={`#/benefits-verification/edit/${benefitsVerification.id}`}
                  variant="body2"
                  underline="none"
                  color="black"
                >
                  Submission Details
                </Link>
              )}
            </StepLabel>
          </Step>
          <Step active={isSummaryAvailable}>
            <StepLabel>
              {!isSummaryAvailable ? (
                <>Summary</>
              ) : (
                <Link
                  href={`#/benefits-verification/view/${benefitsVerification.id}`}
                  variant="body2"
                  underline="none"
                  color="black"
                >
                  Summary
                </Link>
              )}
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
    </Paper>
  );
};
