import { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";

import { BaseMultiline } from "./StyledComponents";

const StyledMultiline = styled(BaseMultiline)`
  resize: vertical;
`;

class SegmentMultiline extends PureComponent {
  render() {
    const {
      item,
      handleChange,
      result,
      disabled,
      highlightIfMissing,
      defaultValue,
    } = this.props;

    return (
      <StyledMultiline
        data-cy={_.camelCase(`field_${item.key}`)}
        disabled={disabled}
        onChange={(event) => {
          handleChange({ [item.key]: event.target.value });
        }}
        value={result || defaultValue}
        highlight={highlightIfMissing && !result}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { key } = props.item;
  return key ? { result: state.form.results[key] } : { result: null };
}

export default connect(mapStateToProps)(SegmentMultiline);
