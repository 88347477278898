import * as React from "react";

import {
  ListItemButton,
  Box,
  Link,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@samacare/design/core";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import ListIcon from "@samacare/design/core/icons/ListOutlined";
import { useTheme } from "@samacare/design/core/styles";

interface RightSideMenuProps {
  activeStep: number;
}

export const RightSideMenuForBvLite: React.FC<RightSideMenuProps> = ({
  activeStep,
}: RightSideMenuProps) => {
  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();
  const theme = useTheme();

  if (!unifiedBenefitsCheck) {
    return <Box />;
  }

  return (
    <Paper>
      <Box paddingY={1}>
        <Stepper
          orientation="vertical"
          activeStep={activeStep}
          connector={null}
        >
          <Step active>
            <ListItemButton selected>
              <StepLabel
                StepIconComponent={ListIcon}
                StepIconProps={{
                  sx: {
                    color: "white",
                    background: theme.palette.primary.light,
                    borderRadius: "50%",
                    marginRight: 1,
                  },
                }}
              >
                <Link
                  href={`#/benefits-verification/view/${unifiedBenefitsCheck.id}`}
                  variant="body2"
                  underline="none"
                  color="black"
                >
                  Summary
                </Link>
              </StepLabel>
            </ListItemButton>
          </Step>
        </Stepper>
      </Box>
    </Paper>
  );
};
