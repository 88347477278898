import MuiButton, { ButtonTypeMap } from "@mui/material/Button";
import { OverrideProps } from "@mui/types";
import * as React from "react";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export type ButtonProps<
  RootComponent extends React.ElementType = ButtonTypeMap["defaultComponent"],
  AdditionalProps = object,
> = OverrideProps<
  ButtonTypeMap<AdditionalProps, RootComponent>,
  RootComponent
> & { download?: string };

const Button: React.FC<ButtonProps> = MuiButton;

export default Button;
