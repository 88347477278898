import { useEffect, useRef } from "react";
import * as React from "react";

interface IOutsideAlerterProps {
  onOutside: (() => void) | undefined | null | false;
  children?: React.ReactNode;
}

const OutsideAlerter: React.FC<IOutsideAlerterProps> = ({
  onOutside,
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isActive = onOutside != null && onOutside !== false;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isActive &&
        wrapperRef != null &&
        wrapperRef.current != null &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onOutside();
      }
    };

    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [onOutside, isActive]);

  return <div ref={wrapperRef}>{children}</div>;
};

/**
 * @deprecated Use a MUI component instead
 */
export { OutsideAlerter };
