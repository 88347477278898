import _ from "lodash";
import { Account, Portal, PortalCredential } from "@samacare/graphql";
import { TableHeader } from "app/routes/Admin/AdminStyles";
import { MfaSetupModal } from "app/components/MfaSetupModal";
import PortalCredentialModal from "app/routes/UserSettings/PortalCredentialModal";
import { PortalCredentialRow } from "app/routes/UserSettings/PortalCredentialRow";
import { useState } from "react";
import * as React from "react";
import { usePortals } from "../../hooks/usePortals";

interface IPortalLoginsPaneProps {
  currentAccount: Account;
}
export const PortalLoginsPane: React.FC<IPortalLoginsPaneProps> = ({
  currentAccount,
}) => {
  const [isPortalCredentialModalOpen, setIsPortalCredentialModalOpen] =
    useState(false);
  const [credentialToEdit, setCredentialToEdit] =
    useState<PortalCredential | null>(null);
  const [selectedPortal, setSelectedPortal] = useState<Portal | null>(null);
  const [isMfaDialogOpen, setIsMfaDialogOpen] = useState(false);

  const { portals } = usePortals();

  const portalCreds: PortalCredential[] =
    (currentAccount?.portalCredentials as PortalCredential[]) ?? [];

  const credsByPortalId: Record<number, PortalCredential> = {};
  portalCreds.forEach((pc) => {
    if (pc.PortalId != null) credsByPortalId[pc.PortalId] = pc;
  });

  const handleOpenCredentialModal = (
    portal: Portal,
    credential: PortalCredential | null
  ) => {
    setCredentialToEdit(credential);
    setSelectedPortal(portal);
    setIsPortalCredentialModalOpen(true);
  };

  const handleClosePortalCredentialModal = (shouldOpenMfaDialog: boolean) => {
    setIsPortalCredentialModalOpen(false);
    setCredentialToEdit(null);
    if (shouldOpenMfaDialog) {
      setIsMfaDialogOpen(true);
    }
  };

  const handleCloseMfaDialog = () => {
    setIsMfaDialogOpen(false);
  };

  const sortedPortals = _.sortBy(portals, (p) => p.title);

  return (
    <>
      <table
        id="pendo_portalLoginsListView"
        style={{ tableLayout: "auto", marginTop: "8px" }}
      >
        <thead>
          <tr>
            <TableHeader style={{ width: "auto" }} />
            <TableHeader style={{ width: "auto" }} />
          </tr>
        </thead>
        <tbody>
          {sortedPortals.map((p) => (
            <PortalCredentialRow
              key={p.id}
              portal={p}
              portalCredential={credsByPortalId[+p.id]}
              onEdit={handleOpenCredentialModal}
            />
          ))}
        </tbody>
      </table>

      <PortalCredentialModal
        isOpen={isPortalCredentialModalOpen}
        credentialToEdit={credentialToEdit}
        portal={selectedPortal}
        currentAccount={currentAccount}
        currentInstitutionId={currentAccount.institution?.id}
        onModalClose={handleClosePortalCredentialModal}
      />
      {selectedPortal && (
        <MfaSetupModal
          isOpen={isMfaDialogOpen}
          closeDialog={handleCloseMfaDialog}
          portal={selectedPortal}
          currentAccount={currentAccount}
        />
      )}
    </>
  );
};
