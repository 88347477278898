import { PureComponent } from "react";
import styled from "styled-components";
import Button from "@samacare/design/core/Button";
import LoadingButton from "@samacare/design/core/LoadingButton";
import Stack from "@samacare/design/core/Stack";
import BaseButton from "../BaseButton";
import LegacyLoadingButton from "../LoadingButton";

const BackButton = styled(BaseButton)`
  color: white;
  border-color: ${(props) => props.theme.lightGray};
  background-color: ${(props) => props.theme.lightGray};
  width: 150px;
  margin-right: ${(props) => (props.hasSubmit ? "auto" : 0)};
  &:hover {
    color: white;
    background-color: ${(props) =>
      props.disabled ? props.theme.lightGray : props.theme.gray};
    border-color: ${(props) =>
      props.disabled ? props.theme.lightGray : props.theme.gray};
  }
`;

export class FormSubmitButtons extends PureComponent {
  render() {
    const {
      back,
      backText,
      submit,
      submitText,
      loading = false,
      disabled,
      submitDisabled,
      style = {},
      children,
      useLegacyButtons = false,
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "20px",
          ...style,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          {back && !useLegacyButtons && (
            <Button
              variant="contained"
              color="neutral"
              data-cy="actionBack"
              disabled={disabled}
              hasSubmit={!!submit}
              onClick={back}
            >
              {backText ?? "Back"}
            </Button>
          )}
          {back && useLegacyButtons && (
            <BackButton disabled={disabled} hasSubmit={!!submit} onClick={back}>
              {backText ?? "Back"}
            </BackButton>
          )}
          {!back && <div />}
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {children}
            {submit && !useLegacyButtons && (
              <LoadingButton
                data-cy="actionSubmit"
                variant="contained"
                color="primary"
                disabled={disabled || submitDisabled}
                loading={loading}
                onClick={submit}
              >
                {submitText || "Next"}
              </LoadingButton>
            )}
            {submit && useLegacyButtons && (
              <LegacyLoadingButton
                cypressTag="actionSubmit"
                disabled={disabled || submitDisabled}
                loading={loading}
                onClick={submit}
                style={{
                  width: 150,
                  marginLeft: "auto",
                }}
              >
                {submitText || "Next"}
              </LegacyLoadingButton>
            )}
          </Stack>
        </Stack>
      </div>
    );
  }
}

export default FormSubmitButtons;
