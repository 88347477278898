import { gql, useMutation } from "@apollo/client";
import {
  ModelType,
  Mutation,
  MutationGeneratePdfDownloadArgs,
  TemplateType,
} from "@samacare/graphql";
import { ButtonProps, Tooltip, LoadingButton } from "@samacare/design/core";
import DownloadIcon from "@samacare/design/core/icons/Download";
import DownloadDoneIcon from "@samacare/design/core/icons/DownloadDone";
import React from "react";
import { useAlert } from "react-alert";

const generatePdfDownloadMutation = gql`
  mutation generatePdfDownload(
    $template: TemplateType!
    $modelType: ModelType!
    $modelId: String!
  ) {
    generatePdfDownload(
      template: $template
      modelType: $modelType
      modelId: $modelId
    )
  }
`;

type DownloadAsPdfProps = {
  template: TemplateType;
  modelType: ModelType;
  modelId: string | number;
} & ButtonProps;

export const DownloadAsPdf: React.FC<DownloadAsPdfProps> = ({
  template,
  modelType,
  modelId,
  ...buttonProps
}) => {
  const alert = useAlert();
  const [downloaded, setDownloaded] = React.useState(false);
  const [generatePdfDownload, { loading }] = useMutation<
    Mutation,
    MutationGeneratePdfDownloadArgs
  >(generatePdfDownloadMutation);

  async function unwrapAsync<T>(
    promise: Promise<T>
  ): Promise<[Error | null, T | null]> {
    try {
      const res: T = await promise;
      return [null, res];
    } catch (err) {
      return [err as Error, null];
    }
  }

  return (
    <Tooltip title={downloaded ? "Downloaded!" : "Download as PDF"}>
      <LoadingButton
        loading={loading}
        size="small"
        sx={{ minWidth: "auto" }}
        color={downloaded ? "success" : "primary"}
        onClick={async () => {
          const [err, res] = await unwrapAsync(
            generatePdfDownload({
              variables: {
                template,
                modelType,
                modelId: `${modelId}`,
              },
            })
          );
          const fileUrl = res?.data?.generatePdfDownload;
          if (err || !fileUrl) {
            alert.error("Failed to generate PDF!");
            return;
          }
          window.open(fileUrl, "_blank");
          setDownloaded(true);
          setTimeout(() => setDownloaded(false), 5000);
        }}
        {...buttonProps}
      >
        {downloaded ? <DownloadDoneIcon /> : <DownloadIcon />}
      </LoadingButton>
    </Tooltip>
  );
};
