import MuiLink, {
  type LinkTypeMap,
  type LinkProps as MuiLinkProps,
} from "@mui/material/Link";

export type LinkProps<
  T extends React.ElementType = LinkTypeMap["defaultComponent"],
  AdditionalProps = { disabled?: boolean },
> = MuiLinkProps<T, AdditionalProps>;

const Link: React.FC<LinkProps> = MuiLink;

export default Link;
