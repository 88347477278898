import { ChangeEvent, FC } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import MuiPhoneNumber, { MuiPhoneNumberProps } from "material-ui-phone-number";
import { useTheme } from "@samacare/design/core/styles";
import { isE164 } from "@samacare/utils";

// Define the props for the wrapper component
export type IntlPhoneFieldProps = Omit<MuiPhoneNumberProps, "onChange"> & {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  defaultCountry?: string;
};

const IntlPhoneField: FC<IntlPhoneFieldProps> = (props) => {
  const theme = useTheme();
  const { control } = useFormContext();
  const { name, label, rules, defaultCountry = "us", ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) => !value || isE164(value) || `Invalid ${label}`,
        ...rules,
      }}
      defaultValue=""
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <MuiPhoneNumber
          {...rest}
          variant="outlined"
          defaultCountry={defaultCountry || "us"}
          label={label}
          value={value}
          onChange={(phone: string | ChangeEvent) => {
            if (typeof phone === "string") {
              if (phone === "+") {
                return onChange("");
              }
              // Strip formatting before storing
              const rawValue = phone?.replace(/[^\d+]/g, "");
              return onChange(rawValue);
            }
            return onChange(phone);
          }}
          onBlur={onBlur}
          error={!!error}
          helperText={error ? error.message : ""}
          InputProps={{ sx: { height: "40px" } }}
          sx={{
            "& .MuiOutlinedInput-root": {
              // Border color when focused
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main, // Your desired focused border color
              },
            },
            "& .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused": {
              color: theme.palette.primary.main, // Custom color when shrunken
            },
          }}
        />
      )}
    />
  );
};

export default IntlPhoneField;
