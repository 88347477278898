const hasActionableFollowUp = (
  authorization: {
    followUp?: { type: string } | null;
    latestCorrespondence?: { fileURL?: string | null } | null;
    portalKey?: string | null;
    PortalId?: string | number | null;
  } = {}
): boolean =>
  authorization?.followUp?.type != null &&
  authorization.followUp.type !==
    window.CONFIG.CONSTANTS.FOLLOW_UP_TYPES.CONTACT_PAYER &&
  authorization?.latestCorrespondence?.fileURL != null &&
  authorization?.portalKey == null &&
  authorization?.PortalId == null;

export default hasActionableFollowUp;
