import * as React from "react";
import styled from "styled-components";

export type TextareaProps = React.ComponentProps<typeof Textarea>;

/**
 * Textarea is a component that allows a user to type in long-form text
 * information.
 *
 * ## When to use:
 *
 * When you want to collect lots of free-form information from a user; e.g. an
 * error report textbox.
 *
 * @example
 * ```tsx
 * import { Textarea, Label } from "@@ui-kit/forms";
 *
 * // ...
 *
 * <Label htmlFor="the-problem">
 *   <Textarea
 *     id="favorite-band"
 *     error={content.length < 25}
 *     placeholder="Describe your problem..."
 *     value={content}
 *   />
 *   Report a problem:
 * </Label>
 * ```
 */
/**
 * @deprecated Use component from design/forms instead
 */
export const Textarea = styled.textarea<{
  /**
   * Whether or not to render the component in an error state.
   */
  error?: boolean;
  /**
   * Whether or not to render the component in a success state.
   */
  success?: boolean;
  /**
   * Whether or not to render the component in a warning state.
   */
  warning?: boolean;
}>`
  border: 1px solid
    ${({
      error = false,
      success = false,
      theme: { colors },
      warning = false,
    }) => {
      if (error) {
        return colors.danger;
      }
      if (warning) {
        return colors.warning;
      }
      if (success) {
        return colors.success;
      }
      return colors.lightGray;
    }};
  border-radius: 3px;
  box-shadow: none;
  padding: 10px;
  width: 100%;

  &:focus {
    border-color: ${({
      error = false,
      success = false,
      theme: { colors },
      warning = false,
    }) => {
      if (error) {
        return colors.danger;
      }
      if (warning) {
        return colors.warning;
      }
      if (success) {
        return colors.success;
      }
      return colors.primary;
    }};
    outline: 0;
  }

  &:disabled {
    background: ${({ theme: { colors } }) => colors.disabledGray};
  }
`;
