import { PureComponent } from "react";

import BaseButton from "../BaseButton";

class SegmentButton extends PureComponent {
  render() {
    const { onClick, text, disabled, style } = this.props;

    return (
      <BaseButton disabled={disabled} onClick={onClick} style={style}>
        {text}
      </BaseButton>
    );
  }
}

export default SegmentButton;
