import { Property } from "csstype";
import * as React from "react";
import { ControlMenuContainer, ControlMenuContainerProps } from "./Container";
import { ControlMenuControls, ControlMenuControlsProps } from "./Controls";
import {
  ControlMenuItem,
  ControlMenuItemProps,
  ControlMenuNestedMenu,
  ControlMenuNestedMenuProps,
} from "./Item";

interface ControlMenu {
  (props: {
    children?: (props: { open: boolean }) => React.ReactNode;
    control?: (props: { open: boolean }) => React.ReactNode;
    header?: (props: { open: boolean }) => React.ReactNode;
    open?: boolean;
    style?: ControlMenuContainerProps["style"];
    width?: Property.Width;
  }): React.ReactElement;

  Controls: typeof ControlMenuControls;
  Item: typeof ControlMenuItem;
  Nested: typeof ControlMenuNestedMenu;
}

export type ControlMenuProps = React.ComponentProps<typeof ControlMenu>;

/**
 * @deprecated Use component from design/forms instead
 */
export const ControlMenu: ControlMenu = ({
  children,
  control = null,
  header = null,
  open = true,
  width = "240px",
  ...rest
}) => {
  return (
    <ControlMenuContainer width={width} {...rest}>
      {control != null && control({ open })}
      {header != null && header({ open })}
      {children != null && children({ open })}
    </ControlMenuContainer>
  );
};

export type {
  ControlMenuControlsProps,
  ControlMenuItemProps,
  ControlMenuNestedMenuProps,
};

ControlMenu.Controls = ControlMenuControls;
ControlMenu.Item = ControlMenuItem;
ControlMenu.Nested = ControlMenuNestedMenu;
