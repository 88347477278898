import { Component } from "react";
import styled from "styled-components";
import _ from "lodash";

const ImportanceContainer = styled.div`
  border: 1px solid ${(props) => props.color || props.theme.lightGray};
  background: ${(props) =>
    props.fill === "true"
      ? props.color || props.theme.lightGray
      : "transparent"};
  width: 18px;
  height: 18px;
  border-radius: 3px;
  margin-right: 5px;

  &:hover {
    width: 20px;
    height: 20px;
  }
`;

const Menu = styled.div`
  position: absolute;
  z-index: 3;
  background-color: white;
  display: flex;
  flex-direction: column;
  color: black;
  padding: 5px 10px;
  border: 1px solid ${(props) => props.theme.darkGray};
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  :hover {
    background: ${(props) => props.theme.lightGray};
  }
`;

class Card extends Component {
  state = { showMenu: false };

  showMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  render() {
    const { toggleImportance, importance, hasBoxes } = this.props;
    const { showMenu } = this.state;

    return (
      <div>
        <ImportanceContainer
          onClick={this.showMenu}
          color={_.get(
            _.find(CONFIG.CONSTANTS.INPUT_IMPORTANCE, { key: importance }),
            "color"
          )}
          fill={`${hasBoxes}`}
        />
        {showMenu && (
          <Menu>
            {_.map(CONFIG.CONSTANTS.INPUT_IMPORTANCE, (_importance) => (
              <OptionContainer
                onClick={() => {
                  toggleImportance(_importance.key);
                }}
                key={_importance.key}
              >
                <ImportanceContainer fill="true" color={_importance.color} />
                {_importance.title}
              </OptionContainer>
            ))}
          </Menu>
        )}
      </div>
    );
  }
}

export default Card;
