import { Components } from "@mui/material";

export default (): Components["MuiFormLabel"] => ({
  styleOverrides: {
    root: {
      "+ &": {
        backgroundColor: "transparent",
      },
    },
    asterisk: {
      color: "#db3131",
      fontSize: "1.5em",
      "&$error": {
        color: "#db3131",
      },
    },
  },
});
