import { Account, Institution } from "../../../monorepo/packages/graphql";

export const shouldUseMocks = ({
  env,
  account,
  institution,
}: {
  env?: string;
  account?: Account;
  institution?: Institution | null;
}) => {
  return env !== "production" && account?.isSamaUser && institution?.isTest;
};
