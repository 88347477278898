import { FC } from "react";
import _ from "lodash";
import {
  Grid,
  GridCloseIcon,
  IconButton,
  Stack,
  Typography,
} from "@samacare/design";
import {
  LeftRightCenterAll,
  LeftRightCenterV,
  LeftRightSpreadCenterV,
  TopBottom,
  RoundedButton,
} from "@samacare/component";
import { useTheme } from "@samacare/design/core/styles";
import Edit from "@samacare/design/core/icons/Edit";

const BORDER_RADIUS = "4px";

const ellipsisSx = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

interface BottomMessageProps {
  isError: boolean;
  buttonText?: string;
  message: string;
  onButtonClick?: () => void;
}
const BottomMessage: FC<BottomMessageProps> = (props) => {
  const theme = useTheme();
  const { isError, buttonText, onButtonClick, message } = props;
  const { grey, error, warning } = theme.palette;
  const background = isError ? error.ultraLight : warning.veryLight;
  return (
    <LeftRightSpreadCenterV
      sx={{
        background,
        px: 2,
        py: 2,
        borderBottomLeftRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
      }}
    >
      <Typography variant="body2" color={grey[700]}>
        {message}
      </Typography>
      {buttonText && onButtonClick && (
        <RoundedButton
          onClick={onButtonClick}
          color={isError ? "error" : "warning"}
        >
          {buttonText}
        </RoundedButton>
      )}
    </LeftRightSpreadCenterV>
  );
};

interface GridItemProps {
  label: string;
  value: string | null;
}
const GridItem: FC<GridItemProps> = (props) => {
  const theme = useTheme();
  const { grey } = theme.palette;
  return (
    <Grid item xs={6}>
      <Typography variant="body2" color={grey[600]}>
        {props.label}
      </Typography>
      <Typography variant="body2">{props.value || "-"}</Typography>
    </Grid>
  );
};

const SideBySideGridItem: FC<GridItemProps> = (props) => {
  const theme = useTheme();
  const { grey } = theme.palette;
  return (
    <Grid item xs={6} p={0}>
      <Stack direction="row" gap={1}>
        <Typography variant="body2" color={grey[600]}>
          {props.label}
        </Typography>
        <Typography variant="body2" p={0}>
          {props.value || "-"}
        </Typography>
      </Stack>
    </Grid>
  );
};

interface EntityCardProps {
  title: string;
  subtitle?: string;
  subtitle2?: string;
  disabled?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
  address?: {
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  middleBlockDataItems?: { label: string; value: string | null }[];
  dataItems?: { label: string; value: string | null }[];
  warningMessage?: string;
  warningButtonText?: string;
  onWarningButtonClick?: () => void;
  errorMessage?: string;
  errorButtonText?: string;
  onErrorButtonClick?: () => void;
}
export const EntityCard: React.FC<EntityCardProps> = (props) => {
  const { address } = props;
  const theme = useTheme();
  const { grey } = theme.palette;

  const line2 = props.subtitle || props.subtitle2;
  const line3 = props.subtitle && props.subtitle2;

  const fullAddress =
    address &&
    _.compact([address.address, address.city, address.state, address.zip]).join(
      ", ",
    );

  return (
    <Stack
      sx={{
        border: `1px solid ${theme.palette.primary.superLight}`,
        borderRadius: BORDER_RADIUS,
      }}
    >
      <Stack
        sx={{
          backgroundColor: theme.palette.primary.ultraLight,
          borderTopLeftRadius: BORDER_RADIUS,
          borderTopRightRadius: BORDER_RADIUS,
          pr: 1,
          py: 1,
          maxWidth: "100%",
        }}
      >
        <Stack direction="row" alignItems="center">
          <Stack
            direction="row"
            alignItems="center"
            sx={{ maxWidth: "100%", flexGrow: 1, overflow: "hidden" }}
          >
            <LeftRightCenterAll
              sx={{
                height: "36px",
                width: "36px",
                minHeight: "36px",
                minWidth: "36px",
                backgroundColor: theme.palette.primary.veryLight,
                borderRadius: "18px",
                ml: 2,
                color: theme.palette.common.white,
                fontSize: "1.5rem",
              }}
            >
              {props.title && props.title.length > 0 ? props.title[0] : ""}
            </LeftRightCenterAll>
            <TopBottom sx={{ ml: 2, flexGrow: 1, overflow: "hidden" }}>
              <Typography sx={ellipsisSx}>{props.title}</Typography>
              {line2 && (
                <Typography variant="caption" color={grey[700]} sx={ellipsisSx}>
                  {line2}
                </Typography>
              )}
            </TopBottom>
          </Stack>
          {!props.disabled && (
            <LeftRightCenterV sx={{ mt: "1px" }}>
              {props.onEdit && (
                <IconButton onClick={props.onEdit}>
                  <Edit />
                </IconButton>
              )}
              {props.onRemove && (
                <IconButton onClick={props.onRemove}>
                  <GridCloseIcon />
                </IconButton>
              )}
            </LeftRightCenterV>
          )}
        </Stack>
        {line3 && (
          <Typography
            variant="caption"
            color={grey[700]}
            sx={ellipsisSx}
            ml="68px"
          >
            {line3}
          </Typography>
        )}
      </Stack>

      {(props.middleBlockDataItems || fullAddress) && (
        <Stack sx={{ background: "#F8F9FD" }} py={2} gap={0.5}>
          {fullAddress && (
            <Typography variant="body2" sx={{ px: 2 }}>
              {fullAddress}
            </Typography>
          )}
          {props.middleBlockDataItems && (
            <Grid container px={2} py={0} spacing={1}>
              {props.middleBlockDataItems?.map((item) => (
                <SideBySideGridItem
                  key={item.label}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </Grid>
          )}
        </Stack>
      )}
      <Grid container p={2} spacing={1}>
        {props.dataItems?.map((item) => (
          <GridItem key={item.label} label={item.label} value={item.value} />
        ))}
      </Grid>

      {props.warningMessage && (
        <BottomMessage
          isError={false}
          buttonText={props.warningButtonText || "Update"}
          message={props.warningMessage}
          onButtonClick={props.onWarningButtonClick}
        />
      )}
      {props.errorMessage && (
        <BottomMessage
          isError
          buttonText={props.errorButtonText || "Update"}
          message={props.errorMessage}
          onButtonClick={props.onErrorButtonClick}
        />
      )}
    </Stack>
  );
};
