import * as React from "react";
import styled from "styled-components";
import { LoadingSpinner } from "./LoadingSpinner";

export type BaseButtonProps = React.ComponentProps<typeof BaseButton>;

/**
 * @deprecated This button is deprecated; you should instead use a semantic
 * button from `@@ui-kit` (like `PrimaryButton` or `DangerButton`).
 */
export const BaseButton = styled.button
  .withConfig({
    shouldForwardProp(prop, defaultValidatorFn) {
      if (["loading"].includes(prop)) {
        return false;
      }
      return defaultValidatorFn(prop);
    },
  })
  .attrs<{
    disabled?: boolean;
    children: React.ReactNode;
    loading?: boolean;
  }>(({ children, disabled = false, onClick, loading = false }) => ({
    onClick:
      onClick == null
        ? onClick
        : (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (!disabled) {
              onClick(event);
            }
          },
    children: [
      loading ? <LoadingSpinner key="loading-spinner" /> : null,
      children,
    ],
    disabled,
    loading,
  }))<{
  disabled?: boolean;
  children: React.ReactNode;
  loading?: boolean;
}>`
  display: flex;
  padding: 10px;
  flex-direction: row;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ color, disabled, theme }) =>
    disabled! ? theme.disabledGray : color ?? theme.purple};
  color: ${({ color, disabled, theme }) =>
    disabled! ? theme.disabledGray : color ?? theme.purple};
  background-color: white;
  border-radius: 3px;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: ${({ disabled }) => (disabled! ? "" : "pointer")};
    color: ${({ disabled, theme }) =>
      disabled! ? theme.disabledGray : "white"};
    background: ${({ color, disabled, theme }) =>
      disabled! ? "white" : color ?? theme.purple};

    ${LoadingSpinner} {
      border-top-color: ${({ color, disabled, theme }) =>
        disabled! ? "white" : color ?? theme.purple};
    }
  }

  &:focus {
    outline: 0;
  }
`;

/**
 * @deprecated Use a MUI component instead
 */
export default BaseButton;
