import { Box, Popper } from "@samacare/design";
import { PopperPlacementType } from "@samacare/design/core/Popper";
import { ClickAwayListener } from "@samacare/design/core/base";
import { useState, useRef, FC } from "react";

interface PopperWithTargetProps {
  children: React.ReactNode;
  target: React.ReactNode;
  placement?: PopperPlacementType;
}

export const PopperWithTarget: FC<PopperWithTargetProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  // Handle target click
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    event.stopPropagation();
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={closePopper}>
      <Box>
        <Box onClick={handleClick}>{props.target}</Box>
        <Popper
          sx={{ zIndex: 1200 }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          ref={popperRef}
          placement={props.placement || "bottom"}
        >
          <Box onClick={closePopper}>{props.children}</Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
