import * as React from "react";
import styled from "styled-components";

import Modal from "./Modal";
import { ModalContent } from "./ModalStyledComponents";

const ModalBody = styled.div`
  width: 450px;
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;
`;

const AlertModal: React.FC<{
  open: boolean;
  closeModal: (event: React.MouseEvent<Element, MouseEvent>) => void;
  buttons?: React.ReactNode;
  header: React.ReactNode;
  content: React.ReactNode;
  styleOverride?: React.CSSProperties;
}> = ({ open, closeModal, buttons, header, content, styleOverride }) => (
  <Modal
    header={header}
    onClick={closeModal}
    open={open}
    styleOverride={styleOverride}
  >
    <ModalBody>
      <CustomModalContent>
        <div>{content}</div>
      </CustomModalContent>
      {buttons}
    </ModalBody>
  </Modal>
);

/**
 * @deprecated Use a MUI component instead
 */
export default AlertModal;
