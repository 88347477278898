import { useState } from "react";
import _ from "lodash";
import { Stack, SxProps, Typography } from "@samacare/design";
import { useApolloClient } from "@apollo/client";
import { Location } from "@samacare/graphql";
import {
  formatPhoneForDisplay,
  formatTinForDisplay,
  formatZipForDisplay,
} from "@samacare/utils";
import {
  EntityCard,
  FieldError,
  HiddenField,
  LocationAutocomplete,
  UpsertLocationDialog,
  useFormContext,
  useFormState,
} from "@samacare/form2";

export type PaLocationBlockProps = {
  sx?: SxProps;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  label?: string;
  onAlert?: (message: string) => void;
  tinRequired?: boolean;
};

export const PaLocationBlock: React.FC<PaLocationBlockProps> = (props) => {
  const { watch, setValue, trigger, control } = useFormContext();
  const { errors } = useFormState({ control });
  const [locationToEdit, setLocationToEdit] = useState<Location | null>(null);

  const apolloClient = useApolloClient();

  const [isNewLocationDialogOpen, setIsNewLocationDialogOpen] =
    useState<boolean>(false);

  const location = watch("location");
  const LocationId = watch("LocationId");

  const tinError = _.get(errors, "location.taxId") as FieldError;

  const clearLocation = async () => {
    setValue("location", null, { shouldDirty: true });
    setValue("LocationId", null, {
      shouldDirty: true,
    });
    await trigger();
  };

  const handleEdit = () => {
    setLocationToEdit({
      ...location,
      id: location.id ?? LocationId,
    });
    setIsNewLocationDialogOpen(true);
  };

  const isLocationSelected =
    location && (location.facilityName || location.NPI);

  return (
    <Stack gap={2} maxWidth={600} width={600} mb={1} sx={props.sx}>
      {isLocationSelected ? (
        <Stack gap={1}>
          <EntityCard
            title={location.facilityName}
            subtitle={location.nickname}
            onEdit={location.id ? handleEdit : undefined}
            onRemove={clearLocation}
            disabled={props.disabled}
            middleBlockDataItems={[
              { label: "Fax", value: formatPhoneForDisplay(location.fax) },
            ]}
            address={{
              address: location.address,
              city: location.city,
              state: location.state,
              zip: formatZipForDisplay(location.zip),
            }}
            dataItems={[
              { label: "NPI", value: location.NPI },
              { label: "TIN", value: formatTinForDisplay(location.taxId) },
            ]}
            warningMessage={
              !props.tinRequired && !location.taxId && !props.disabled
                ? "Enter TIN for seamless tracking of Prior Authorizations"
                : undefined
            }
            onWarningButtonClick={handleEdit}
            errorMessage={
              props.tinRequired && !location.taxId && !props.disabled
                ? "TIN required to track Prior Authorizations"
                : undefined
            }
            errorButtonText="Add TIN"
            onErrorButtonClick={handleEdit}
          />
          {tinError && (
            <Typography color="error" variant="caption" ml={1}>
              {tinError.message || "TIN required"}
            </Typography>
          )}
          {props.tinRequired && (
            <HiddenField
              name="location.taxId"
              required
              rules={{ required: "TIN must be added above before proceeding" }}
            />
          )}
        </Stack>
      ) : (
        <LocationAutocomplete
          apolloClient={apolloClient}
          name={props.name || "location"}
          placeholder="Choose..."
          label={props.label}
          disabled={props.disabled}
          required={props.required}
          filterSelectedOptions
          onNew={() => {
            setLocationToEdit({} as Location);
            setIsNewLocationDialogOpen(true);
            clearLocation();
          }}
          onChange={async (_event, change) => {
            setValue("location", change, { shouldDirty: true });
            setValue("LocationId", change?.id ?? null, {
              shouldDirty: true,
            });
            await trigger();
          }}
        />
      )}
      <UpsertLocationDialog
        apolloClient={apolloClient}
        open={isNewLocationDialogOpen}
        LocationId={locationToEdit?.id}
        onClose={async (loc: Location | null | undefined) => {
          setIsNewLocationDialogOpen(false);
          if (loc) {
            setValue("location", loc, { shouldDirty: true });
            setValue("LocationId", loc?.id, { shouldDirty: true });
            await trigger();
          }
        }}
        onAlert={props.onAlert}
        tinRequired={props.tinRequired}
      />
    </Stack>
  );
};
