import { Property } from "csstype";
import { MdError } from "@react-icons/all-files/md/MdError";
import { setLightness } from "polished";
import * as React from "react";
import styled from "styled-components";
import { useTheme } from "../app/hooks";
import { Flex, Box } from "./Flexbox";

export type MessageProps = React.ComponentProps<typeof Message>;

const MessageContainer = styled(Flex)<{ primaryColor: string }>`
  align-content: center;
  background-color: ${({ primaryColor }) => setLightness(0.97, primaryColor)};
  border-color: ${({ primaryColor }) => setLightness(0.75, primaryColor)};
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  padding-bottom: 9px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 9px;
`;

/**
 * A Message displays information that explains nearby content.
 */
/**
 * @deprecated Use a MUI component instead
 */
export const Message: React.VoidFunctionComponent<{
  children: React.ReactNode;
  color?: Property.Color;
  icon?: React.ComponentType<{ color?: Property.Color }>;
  type: "danger" | "warning";
}> = ({ children, color, icon: Icon = MdError, type }) => {
  const theme = useTheme();
  const primaryColor = color ?? theme.colors[type];

  return (
    <MessageContainer
      aria-label="Informational Message"
      primaryColor={primaryColor}
    >
      <Box marginRight="9px">
        <Icon data-testid="message-icon" color={primaryColor} />
      </Box>
      <Box aria-label="Informational Message Content">{children}</Box>
    </MessageContainer>
  );
};
