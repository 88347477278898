import * as React from "react";
import { Button } from "@samacare/design/core";
import { useTheme } from "styled-components";
import { SxProps } from "@samacare/design/core/styles";

interface DialogCopilotButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
  sx?: SxProps;
}

export const DialogCopilotButton: React.FC<DialogCopilotButtonProps> = ({
  onClick,
  text,
  sx,
}) => {
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onClick(event);
  };

  return (
    <Button
      variant="contained"
      sx={{
        background: theme.orange,
        "&:hover": {
          background: theme.warningOrange,
        },
        ...sx,
      }}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};
