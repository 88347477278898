import {
  useForm,
  EmailField,
  SubmitHandler,
  FormProvider,
} from "@samacare/form";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Stack, Button, LoadingButton } from "@samacare/design/core";

import { useAuth } from "../../providers/AuthProvider";
import { AUTH_USERNAME_STORAGE } from "./Auth.constants";
import { AuthTitle } from "./AuthTitle";

type AuthResetPasswordForm = {
  username: string;
};

export const AuthResetPassword: React.FC = () => {
  const history = useHistory();

  const [username] = useState<string | null>(() =>
    localStorage.getItem(AUTH_USERNAME_STORAGE)
  );

  const methods = useForm<AuthResetPasswordForm>();

  const { onReset, onResetPassword, error, loading } = useAuth();

  const onSubmit: SubmitHandler<AuthResetPasswordForm> = async (data) => {
    localStorage.setItem(AUTH_USERNAME_STORAGE, data.username);
    await onResetPassword(data);
  };

  return (
    <FormProvider {...methods}>
      <form name="login" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <AuthTitle>Forgot Password?</AuthTitle>
        <Stack spacing={2}>
          <EmailField name="username" label="Email address" value={username} />
          {error && <Alert color="error">{error}</Alert>}
          <LoadingButton
            loading={loading}
            type="submit"
            size="large"
            variant="contained"
          >
            Send Reset Password Email
          </LoadingButton>
          <Button
            onClick={() => {
              onReset();
              history.push("/auth/login");
            }}
            size="large"
          >
            Back to Login
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
