import { ReactElement, ReactNode } from "react";
import * as React from "react";
import _ from "lodash";
import { Authorization, LomnField } from "@samacare/graphql";
import { ContentBlock } from "draft-js";
import styled from "styled-components";
import reactStringReplace from "react-string-replace";
import { WhiteButton } from "@@ui-kit/WhiteButton";
import { Panel } from "@@ui-kit";
import moment from "moment";
import EditAndResubmitButton from "@@components/EditAndResubmitButton";
import ErrorIcon from "@samacare/design/core/icons/Error";
import { Box, Stack, SvgIcon, SvgIconProps } from "@samacare/design/core";

// Parse out the attributes in an HTML tag. We use this below to process the HTMl that we're getting from the server into React Components
export const parseTagAttributes = (tag: string): { [key: string]: string } => {
  const attributes = tag.replace(/'/g, '"').match(/([-\w]+)="([^"]*)"/g);
  const result: { [key: string]: string } = {};
  if (attributes) {
    attributes.forEach((attribute) => {
      const [key, value] = attribute.split("=");
      result[key] = value.replace(/"/g, "");
    });
  }
  return result;
};

// This is the helper method that we use to render the HTML that we get from the server into React Components
// We could use dangerouslySetInnerHTML, but that's not recommended. This is a safer way to do it and allows us
// to write simpler HTML.
// This function supports the following tags:
// <Button href="https://www.google.com">Google</Button>
// <a href="https://www.google.com">Google</a>
// <img src="" />
// <ul><li>Item 1</li><li>Item 2</li></ul>
// <b>bold</b>
// See tests for this function in index.spec.tsx
export const replaceWithComponents = (
  html: string,
  omitButton = false
): React.ReactNodeArray => {
  let replaced: string | React.ReactNodeArray = html;
  replaced = reactStringReplace(
    replaced,
    /(<Button\s+[^>]+>.*<\/Button>)/,
    (match, idx) => {
      const m = match.match(/<Button\s+([^>]+)>(.*)<\/Button>/);
      if (!m) return match;
      const attributes = parseTagAttributes(m[1]);
      if (omitButton) return null;
      return (
        <WhiteButton
          key={`button-${String(idx)}`}
          data-cy={attributes["data-cy"] ?? undefined}
          onClick={() =>
            attributes.href && window.open(attributes.href, "blank")
          }
        >
          {m[2]}
        </WhiteButton>
      );
    }
  );
  replaced = reactStringReplace(
    replaced,
    /(<a\s+[^>]+>.*?<\/a>)/,
    (match, idx) => {
      const m = match.match(/<a\s+([^>]+)>(.*)<\/a>/);
      if (!m) return match;
      const attributes = parseTagAttributes(m[1]);
      return (
        <a
          key={`a-${String(idx)}`}
          data-cy={attributes["data-cy"] ?? undefined}
          href={attributes.href}
          target="_blank"
          rel="noreferrer"
        >
          {m[2]}
        </a>
      );
    }
  );
  replaced = reactStringReplace(replaced, /(<img\s+[^>]+\/>)/, (match, idx) => {
    const m = match.match(/<img\s+([^\\]+)\/>/);
    if (!m) return match;
    const attributes = parseTagAttributes(m[1]);
    return (
      <div key={`img-${String(idx)}`} style={{ textAlign: "center" }}>
        <img src={attributes.src} />
      </div>
    );
  });
  replaced = reactStringReplace(
    replaced,
    /(<Icon\s+[^>]+\/>)/,
    (match, idx) => {
      const m = match.match(/<Icon\s+([^\\]+)\/>/);
      if (!m) return match;
      const attributes = parseTagAttributes(m[1]);
      const iconMap: Record<string, typeof SvgIcon> = {
        error: ErrorIcon,
      };

      const Icon = iconMap[attributes.key];
      return (
        <Icon
          key={`icon-${String(idx)}`}
          color={attributes.color as SvgIconProps["color"]}
        />
      );
    }
  );
  replaced = reactStringReplace(replaced, /<ul>(.*)<\/ul>/, (ulMatch, idx) => {
    const re = /<li>(.*?)<\/li>/g;
    const matches = [];
    let liMatch = re.exec(ulMatch);
    while (liMatch) {
      matches.push(liMatch[1]);
      liMatch = re.exec(ulMatch);
    }
    return (
      <ul key={`ul-${String(idx)}`} style={{ marginTop: 0, marginBottom: 0 }}>
        {_.map(matches, (match, idx2) => (
          <li key={idx2}>{match}</li>
        ))}
      </ul>
    );
  });
  replaced = reactStringReplace(replaced, /<ul>(.*)<\/ul>/, (ulMatch, idx) => {
    const re = /<li>(.*?)<\/li>/g;
    const matches = [];
    let liMatch = re.exec(ulMatch);
    while (liMatch) {
      matches.push(liMatch[1]);
      liMatch = re.exec(ulMatch);
    }
    return (
      <ul key={`ul-${String(idx)}`} style={{ marginTop: 0, marginBottom: 0 }}>
        {_.map(matches, (match, idx2) => (
          <li key={idx2}>{match}</li>
        ))}
      </ul>
    );
  });
  replaced = reactStringReplace(replaced, /<div>(.*)<\/div>/, (match, idx) => {
    return <div key={`div-${String(idx)}`}>{match}</div>;
  });
  replaced = reactStringReplace(
    replaced,
    /<small>(.*)<\/small>/,
    (match, idx) => {
      return (
        <div style={{ fontSize: ".7em" }} key={`small-${String(idx)}`}>
          {match}
        </div>
      );
    }
  );
  replaced = reactStringReplace(replaced, /<b>(.*)<\/b>/, (match, idx) => {
    return <b key={`b-${String(idx)}`}>{match}</b>;
  });
  replaced = reactStringReplace(replaced, /<p>(.*)<\/p>/, (match, idx) => {
    return <p key={`p-${String(idx)}`}>{match}</p>;
  });
  return replaced;
};

export const renderHtml = (
  html: string,
  omitButton = false
): React.ReactNodeArray | JSX.Element => {
  if (html.includes("||")) {
    const components = html
      .split("||")
      .map((item) => replaceWithComponents(item, omitButton));
    const el = (
      <Stack direction="row" spacing={2} alignItems="center">
        {components.map((item, idx) => (
          <Box key={idx}>{item}</Box>
        ))}
      </Stack>
    );
    return el;
  }
  return replaceWithComponents(html, omitButton);
};

export const renderTemplate = (
  template: string,
  context: object
): [Error | null, string] => {
  try {
    return [null, _.template(template)(context)];
  } catch (e) {
    return [e as Error, ""];
  }
};

export const displayLetterOfMedicaNecessityForAuth = (
  authorization: Authorization
): boolean =>
  authorization.type === window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.FORM.key &&
  authorization.drugConfiguration?.useLetterOfMedicalNecessity === true;

function findWithRegex(
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) {
  const text = contentBlock.getText();
  let match;
  do {
    match = regex.exec(text);
    if (match !== null) {
      callback(match.index, match.index + match[0].length);
    }
  } while (match !== null);
}

// This decorator matches strings enclosed in square braces. eg: [My placeholder name]
export const PlaceholderSpan = styled.span`
  color: magenta;
`;
export const PLACEHOLDER_REGEX = /\[\[[^[]*\]\]/gm;
export const placeholderDecorator = {
  strategy: (
    block: ContentBlock,
    callback: (start: number, end: number) => void
  ): void => findWithRegex(PLACEHOLDER_REGEX, block, callback),
  component: PlaceholderSpan,
};

export const SuperscriptSpan = styled.span`
  vertical-align: super;
`;
export const SUPERSCRIPT_REGEX = /<sup>(.*?)<\/sup>/g;
export const superscriptDecorator = {
  strategy: (
    block: ContentBlock,
    callback: (start: number, end: number) => void
  ): void => findWithRegex(SUPERSCRIPT_REGEX, block, callback),
  component: (props: {
    children?: ReactNode;
  }): ReactElement<any, any> | null => {
    const rawText = _.get(props, "children[0].props.text") as string;
    const match = rawText.match(new RegExp(SUPERSCRIPT_REGEX, ""));
    if (!match) return null;
    return <SuperscriptSpan>{match[1]}</SuperscriptSpan>;
  },
};

export const SignaturePlaceholder = (): JSX.Element => {
  return <img src="/signature_placeholder.png" alt="Signature Placeholder" />;
};

export const signatureDecorator = {
  strategy: (
    block: ContentBlock,
    callback: (start: number, end: number) => void
  ): void => findWithRegex(/SIGNATURE_PLACEHOLDER/gm, block, callback),
  component: SignaturePlaceholder,
};

// This does some manipulation to create the context in which to execute the lodash templates to populate the LON
export function createTemplateContext(
  drugConfiguration: Authorization["drugConfiguration"] | null,
  config: Authorization["config"],
  faxResponseNumber: string | null,
  templateMode?: boolean
): Record<string, unknown> {
  // First, we start with nulls for all of the fields that we expect in the LOMN custom fields
  const lomnFields = [
    ...((drugConfiguration && drugConfiguration.lomnFields) || []),
    ...((drugConfiguration && drugConfiguration.appealFields) || []),
  ];
  const configBase = {
    ..._.zipObject(
      _.map(lomnFields, "key") as string[],
      _.map(lomnFields, () => null)
    ),
    ...((config ?? {}) as object),
  };

  //Now we remove all of the purely numerical keys.. this is primarily for clarity and tidiness when outputting these
  const configWithoutNumericalKeys: Authorization["config"] = _.omitBy(
    configBase,
    (v, k) => k.match("^[0-9]+$")
  );

  //The mandatory keys are the LOMNFields that do not have optional=true. This is important because we fill these with a [placeholder] based on their field name
  const mandatoryKeys = _.map(
    _.filter(
      lomnFields,
      (o: LomnField) => o.optional === undefined || o.optional === false
    ),
    "key"
  );

  const configWithPlaceholdersInserted: Authorization["config"] = _.mapValues(
    configWithoutNumericalKeys as object,
    (value: string, key: string) => {
      if (
        templateMode === true ||
        ((value === undefined || value === null || _.isEmpty(value)) &&
          mandatoryKeys.includes(key))
      ) {
        return `[[${
          (
            _.find(lomnFields, { key }) ?? {
              title: _.startCase(_.toLower(key)),
            }
          ).title ?? ""
        }]]`;
      }

      return value;
    }
  );

  const finalConfig = {
    ..._.zipObject(
      _.map(lomnFields, "key") as string[],
      _.times(lomnFields.length, _.constant(""))
    ),
    ...((configWithPlaceholdersInserted ?? {}) as object),
  };

  return {
    date: new Date(),
    faxResponseNumber,
    diagnosis: [
      finalConfig.ICD_DESCRIPTION_0,
      finalConfig.ICD_DESCRIPTION_1,
      finalConfig.ICD_DESCRIPTION_2,
      finalConfig.ICD_DESCRIPTION_3,
      finalConfig.ICD_DESCRIPTION_4,
    ]
      .join("\n")
      .trim(),
    moment,
    //This is populating a blank string into all keys for fields that we have in the lomnFields.. otherwise we get some strange contamination from the window object (todo.. should unfilled strings show up with placeholder text)
    CONFIG: finalConfig,
  };
}

export const ISIPanel: React.VFC<{
  authorization: Authorization;
}> = ({ authorization }) => {
  const { drugConfiguration } = authorization;
  if (
    !drugConfiguration ||
    drugConfiguration.isiHtml === null ||
    drugConfiguration.isiHtml === undefined
  )
    return null;
  return <Panel>{renderHtml(drugConfiguration.isiHtml)}</Panel>;
};

export const PatientAccessProgram: React.VFC<{
  authorization: Authorization;
}> = ({ authorization }) => {
  const { drugConfiguration } = authorization;
  if (
    !drugConfiguration ||
    drugConfiguration.patientAccessInfoHtml === null ||
    drugConfiguration.patientAccessInfoHtml === undefined
  )
    return null;
  return (
    <Panel text="white" bg="primary" style={{ marginBottom: "4px" }}>
      {renderHtml(drugConfiguration.patientAccessInfoHtml)}
    </Panel>
  );
};

export const ReviewAndSubmitNote: React.VFC<{
  authorization: Authorization;
}> = ({ authorization }) => {
  const { drugConfiguration } = authorization;
  if (
    !drugConfiguration ||
    drugConfiguration.reviewAndSubmitNote === null ||
    drugConfiguration.reviewAndSubmitNote === undefined
  )
    return null;
  return (
    <Panel bg="primary" text="white" style={{ marginBottom: "16px" }}>
      {renderHtml(drugConfiguration.reviewAndSubmitNote)}
    </Panel>
  );
};

export const AppealLetterNote: React.VFC<{
  authorization: Authorization;
}> = ({ authorization }) => {
  const { drugConfiguration } = authorization;

  if (
    !drugConfiguration ||
    drugConfiguration.appealLetterHtml === null ||
    drugConfiguration.appealLetterHtml === undefined
  )
    return null;
  return (
    <Panel bg="primary" text="white">
      <Stack direction="row">
        {renderHtml(drugConfiguration.appealLetterHtml, true)}
        <EditAndResubmitButton authorizationId={authorization.id} triggerAppeal>
          <WhiteButton data-cy="actionResubmitWithAppeal">
            Edit & Resubmit with Appeal
          </WhiteButton>
        </EditAndResubmitButton>
      </Stack>
    </Panel>
  );
};
