import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

import {
  AUTHORIZATION_PAGINATION_QUERY_NAME,
  GET_AUTHORIZATION_QUERY_NAME,
} from "./Authorization";
import authorizationCorrespondenceInfo from "./fragments/authorizationCorrespondenceInfo";

export const createAuthorizationCorrespondenceMutation = gql`
  mutation createAuthorizationCorrespondence(
    $authorizationId: Int!
    $fileId: Int!
    $type: String!
    $startDate: DateTime
    $endDate: DateTime
    $code: String
    $authorizedProcedures: [JSON]
    $userUploaded: Boolean
  ) {
    createAuthorizationCorrespondence(
      authorizationId: $authorizationId
      fileId: $fileId
      type: $type
      startDate: $startDate
      endDate: $endDate
      code: $code
      authorizedProcedures: $authorizedProcedures
      userUploaded: $userUploaded
    ) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;
export const withCreateAuthorizationCorrespondence = graphql(
  createAuthorizationCorrespondenceMutation,
  {
    name: "createAuthorizationCorrespondence",
    options: () => ({
      refetchQueries: () => [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
        GET_AUTHORIZATION_QUERY_NAME,
      ],
    }),
  }
);

export const updateLatestAuthorizationCorrespondenceMutation = gql`
  mutation updateLatestAuthorizationCorrespondence(
    $authorizationId: Int
    $type: String
    $startDate: DateTime
    $endDate: DateTime
    $code: String
    $authorizedProcedures: [JSON]
  ) {
    updateLatestAuthorizationCorrespondence(
      authorizationId: $authorizationId
      type: $type
      startDate: $startDate
      endDate: $endDate
      code: $code
      authorizedProcedures: $authorizedProcedures
    ) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;
export const withLatestUpdateAuthorizationCorrespondence = graphql(
  updateLatestAuthorizationCorrespondenceMutation,
  {
    name: "updateLatestAuthorizationCorrespondence",
    options: () => ({
      refetchQueries: () => [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
        GET_AUTHORIZATION_QUERY_NAME,
      ],
    }),
  }
);

export const INSURANCE_RESPONSE_QUERY_NAME = "getInsuranceResponses";
export const withInsuranceResponsesQuery = gql`
  query getInsuranceResponses(
    $authorizationExtensionFilter: AuthorizationExtensionFilterValue!
  ) {
    getInsuranceResponses(
      authorizationExtensionFilter: $authorizationExtensionFilter
    ) {
      items {
        ...authorizationCorrespondenceInfo
      }
      totalAmount
    }
  }
  ${authorizationCorrespondenceInfo}
`;

const withUpdateAuthorizationCorrespondenceMutation = gql`
  mutation updateAuthorizationCorrespondence($id: Int!, $patch: JSON!) {
    updateAuthorizationCorrespondence(patch: $patch, id: $id) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;
export const withUpdateAuthorizationCorrespondence = graphql(
  withUpdateAuthorizationCorrespondenceMutation,
  {
    name: "updateAuthorizationCorrespondence",
    options: () => ({
      refetchQueries: () => [INSURANCE_RESPONSE_QUERY_NAME],
    }),
  }
);

export const deleteCorrespondenceMutation = gql`
  mutation deleteCorrespondence($id: Int!) {
    deleteCorrespondence(id: $id)
  }
`;

export const withDeleteCorrespondence = graphql(deleteCorrespondenceMutation, {
  name: "deleteCorrespondence",
  options: () => ({ refetchQueries: () => [GET_AUTHORIZATION_QUERY_NAME] }),
});

const withSetCorrespondenceOnAuthorizationMutation = gql`
  mutation setCorrespondenceOnAuthorization(
    $authorizationId: Int!
    $correspondenceId: Int!
  ) {
    setCorrespondenceOnAuthorization(
      authorizationId: $authorizationId
      correspondenceId: $correspondenceId
    ) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;

export const unsetCorrespondenceFromAuthorizationMutation = gql`
  mutation unsetCorrespondenceFromAuthorization(
    $authorizationId: Int!
    $correspondenceId: Int!
  ) {
    unsetCorrespondenceFromAuthorization(
      authorizationId: $authorizationId
      correspondenceId: $correspondenceId
    ) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;

export const withSetCorrespondenceOnAuthorization = graphql(
  withSetCorrespondenceOnAuthorizationMutation,
  {
    name: "setCorrespondenceOnAuthorization",
    options: () => ({
      refetchQueries: () => [INSURANCE_RESPONSE_QUERY_NAME],
    }),
  }
);

export const withHideAuthorizationCorrespondence = graphql(
  gql`
    mutation hideAuthorizationCorrespondence($id: Int!) {
      hideAuthorizationCorrespondence(id: $id) {
        success
      }
    }
  `,
  {
    name: "hideCorrespondence",
    options: () => ({
      refetchQueries: () => [INSURANCE_RESPONSE_QUERY_NAME],
    }),
  }
);

export const listCorrespondences = gql`
  query ListCorrespondences($authorizationId: Int!) {
    listCorrespondences(where: { authorizationId: $authorizationId }) {
      ...authorizationCorrespondenceInfo
    }
  }
  ${authorizationCorrespondenceInfo}
`;
