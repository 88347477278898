import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@samacare/design/core";

import { useState } from "react";
import { Box } from "@samacare/design";
import Tooltip from "@samacare/design/core/Tooltip";
import ContentCopyIcon from "@samacare/design/core/icons/ContentCopy";
import CheckCircleIcon from "@samacare/design/core/icons/CheckCircle";

export const NextGenDebugModal: React.VoidFunctionComponent<{
  onClose: () => void;
  open: boolean;
  errorContext: string;
}> = ({ onClose, errorContext, open }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(errorContext);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <Dialog onClose={onClose} scroll="paper" open={open} fullWidth={true}>
      <DialogTitle color="primary.main">NextGen External Error</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          sx={{ paddingTop: ({ spacing }) => spacing(2) }}
        >
          <Typography>
            Sorry, it looks like NextGen is not responding and we are unable to
            upload this document.
          </Typography>
          <Typography>
            If this continues, please contact NextGen’s support team for a fix,
            including the following error log will be helpful.
          </Typography>
          <Stack direction="column" spacing={1} mt={2} mb={2}>
            <Stack direction="row" alignItems="center">
              <Typography fontWeight="bold" mr={2}>
                1. Copy error log
              </Typography>
              <Tooltip title={copied ? "Copied!" : `Copy ${name}`}>
                <Button
                  variant="outlined"
                  endIcon={<ContentCopyIcon />}
                  sx={{ cursor: "pointer" }}
                  onClick={handleCopy}
                  data-cy="actionCopyNextGenErrorLog"
                >
                  Click to Copy
                </Button>
              </Tooltip>

              {copied && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <CheckCircleIcon />
                  <Typography color="green" variant="body2" ml={1}>
                    Copied
                  </Typography>
                </Box>
              )}
            </Stack>

            <Typography fontWeight="bold">
              2. Email or submit a support ticket with NextGen, including the
              error log
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
