import { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import _ from "lodash";

import Modal from "../Modal";
import { BaseText } from "./StyledComponents";
import { withICD10Search } from "../../graphql/ICD10";

const ModelContent = styled.div`
  width: 500px;
  height: 150px;
  overflow: scroll;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid ${(props) => props.theme.lightGray};
`;

const SearchResult = styled.div`
  display: flex;
  border-radius: 3px;
  padding: 5px;
  align-items: center;

  b {
    margin-right: 10px;
  }
  &:hover {
    background-color: ${(props) => props.theme.green};
    cursor: pointer;
    color: white;
  }
`;

const EmptyState = styled.div`
  font-weight: 700;
  color: ${(props) => props.theme.lightGray};
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
  text-align: center;
  font-size: 24px;
`;

const ModalSearch = styled(BaseText)`
  margin-top: 20px;
`;

class SegmentIcd extends PureComponent {
  state = { showModal: false };

  requeryResults = (searchTerm) => {
    const { refetch } = this.props;
    return refetch({ searchTerm });
  };

  refetchAndOpenModal = () => {
    const { refetch, result } = this.props;
    refetch({ searchTerm: result || "" }).then(() => {
      this.setState({ showModal: true });
    });
  };

  render() {
    const { item, result, handleChange, disabled, icd10, highlightIfMissing } =
      this.props;
    const { showModal } = this.state;

    return (
      <div>
        <BaseText
          disabled={disabled}
          onFocus={this.refetchAndOpenModal}
          value={result || ""}
          highlight={highlightIfMissing && !result}
        />
        {showModal && (
          <Modal
            header="Search ICD-10 Codes"
            onClick={() => {
              this.setState({ showModal: false });
            }}
            open={showModal}
          >
            <ModalSearch
              //eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              onChange={(e) => {
                const { value } = e.target;
                handleChange({ [item.key]: value });
                this.requeryResults(value);
              }}
              placeholder="Begin typing to search"
              value={result || ""}
            />
            <ModelContent>
              {icd10.length === 0 && (
                <EmptyState>No matching results</EmptyState>
              )}
              {icd10.length > 0 &&
                _.map(icd10, (code) => (
                  <SearchResult
                    key={code[0]}
                    onClick={() => {
                      handleChange({ [item.key]: code[0] });
                      this.setState({ showModal: false });
                    }}
                  >
                    <b>{code[0]}</b> {code[1]}
                  </SearchResult>
                ))}
            </ModelContent>
          </Modal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { key } = props.item;
  return key ? { result: state.form.results[key] } : { result: null };
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withICD10Search)(connect(mapStateToProps)(SegmentIcd));
