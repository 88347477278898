import * as React from "react";
import Select, { CSSObjectWithLabel } from "react-select";
import colors from "../../../../resources/colors";

interface ServiceTypeDropdownProps {
  isRequired?: boolean;
  isDisabled?: boolean;
  defaultValue?: string;
  onChange: (value: string | null) => void;
}
export const ServiceTypeDropdown: React.VoidFunctionComponent<
  ServiceTypeDropdownProps
> = (props) => {
  const options = window.CONFIG.CONSTANTS.AVAILITY_SERVICE_TYPES;
  const defaultOption = options.find(
    (option) => option.value === props.defaultValue
  );

  return (
    <Select
      isDisabled={props.isDisabled}
      styles={{
        control: (baseStyles: CSSObjectWithLabel, state) =>
          ({
            ...baseStyles,
            height: "46px",
            maxWidth: "600px",
            border:
              props.isRequired && !state.hasValue
                ? `2px solid ${colors.red}`
                : "1px solid #b0b0b0",
          } as CSSObjectWithLabel),
      }}
      options={options}
      onChange={(selected) => {
        props.onChange(selected != null ? selected.value : null);
      }}
      defaultValue={defaultOption}
      isSearchable
      isClearable
    />
  );
};
