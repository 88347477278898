import gql from "graphql-tag";

import { useLazyQuery } from "@apollo/client";
import { Location } from "@samacare/graphql";
import { useCallback, useState } from "react";
import { locationInfo } from "../graphql/fragments/locationInfo";

export const findLocationByNpiQuery = gql`
  query FindLocationByNpi($npi: String!) {
    findLocationByNpi(npi: $npi) {
      success
      message
      location {
        ...locationInfo
      }
      locationFromNpiRegistry {
        facilityName
        address
        city
        state
        zip
        fax
        NPI
        source
      }
    }
  }
  ${locationInfo}
`;

export const useFindLocationByNpi = () => {
  const [location, setLocation] = useState<Location | null>(null);
  const [find, { data, loading }] = useLazyQuery(findLocationByNpiQuery, {
    onCompleted: (d) => {
      if (d.findLocationByNpi?.location) {
        setLocation(d.findLocationByNpi.location);
      } else if (d.findLocationByNpi?.locationFromNpiRegistry) {
        setLocation(d.findLocationByNpi.locationFromNpiRegistry);
      }
    },
    fetchPolicy: "network-only",
  });

  const findLocationByNpi = useCallback(
    (npi: string) => {
      find({ variables: { npi } });
    },
    [find],
  );

  return {
    findLocationByNpi,
    location,
    isExisting: !!location?.id,
    findLocationSuccess: data?.findLocationByNpi?.success,
    errorMessage: data?.findLocationByNpi?.message,
    loading,
  };
};
