import { push } from "connected-react-router";
import _ from "lodash";
import { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import LoadingButton from "@@components/LoadingButton";
import LoadingSpinner from "@@components/LoadingSpinner";
import MainList from "@@components/MainList";
import ActionRow from "@@components/MainList/ActionRow";
import getAuthorizationRoute from "../../../util/getAuthorizationRoute";
import getTableCells from "./getTableCells";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptyState = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.darkGray};
  font-weight: bold;
  margin: 50px 20px 0 20px;
  text-align: center;
`;

const PageButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
`;

const SortTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SortLabel = styled.div`
  font-weight: bold;
  margin-right: 10px;
  padding: 7px;
`;

const SortButton = styled.div`
  padding: 7px;
  border-bottom: 2px solid
    ${(props) => (props.selected ? props.theme.purple : "transparent")};
  cursor: pointer;
  display: flex;
  flex-direction: row;

  &:hover {
    border-color: ${(props) => props.theme.purple};
    opacity: 0.8;
  }

  ${LoadingSpinner} {
    color: ${(props) => props.theme.purple};
  }
`;

export class AuthorizationListManager extends PureComponent {
  handleRowClick = (authorization, shouldOpenInBlank) => {
    const { toAuthorization, alwaysOpenInBlank } = this.props;
    const route = getAuthorizationRoute(authorization);

    toAuthorization(
      authorization.id,
      authorization.formDetails.currentStep,
      route,
      alwaysOpenInBlank || shouldOpenInBlank
    );
  };

  renderSortTabs = () => {
    const { setSortBy, sortedBy } = this.props;

    let sortByContent = <div />;
    if (setSortBy) {
      sortByContent = (
        <SortTabs>
          <SortLabel>Sort By:</SortLabel>
          <SortButton
            onClick={() => {
              setSortBy("SERVICE_DATE");
            }}
            selected={sortedBy === "SERVICE_DATE"}
          >
            Service Date
          </SortButton>
          <SortButton
            onClick={() => {
              setSortBy("LAST_UPDATED");
            }}
            selected={sortedBy === "LAST_UPDATED"}
          >
            Last Updated
          </SortButton>
        </SortTabs>
      );
    }

    return <SortTabs>{sortByContent}</SortTabs>;
  };

  render() {
    const {
      authorizations,
      emptyStateText,
      getNextPage,
      hasNextPage,
      minWidth,
      tableConfig,
      loadingBase,
      loadingAdditional,
      includeActionRow,
      showActionBanner,
      isSupport,
    } = this.props;

    const tabularData = _.map(authorizations, (authorization) => ({
      model: authorization,
      cells: getTableCells(tableConfig.columns, authorization),
      requiresAssociationReview: authorization.requiresAssociationReview,
    }));

    return (
      <Container>
        {this.renderSortTabs()}
        <div>
          {tabularData.length && !loadingBase ? (
            <MainList
              dataRows={tabularData}
              headers={tableConfig.headers}
              keyOff="authList"
              onClick={this.handleRowClick}
              minWidth={minWidth}
              ActionRow={includeActionRow ? ActionRow : false}
              showActionBanner={showActionBanner}
              isSupport={isSupport}
            />
          ) : (
            <EmptyState>
              {loadingBase ? "Loading..." : emptyStateText}
            </EmptyState>
          )}
        </div>
        {hasNextPage && !loadingBase && (
          <PageButtonsContainer>
            <LoadingButton
              loading={loadingAdditional}
              style={{ width: 500 }}
              onClick={getNextPage}
            >
              Load More Authorizations
            </LoadingButton>
          </PageButtonsContainer>
        )}
      </Container>
    );
  }
}

const mapDispatchProps = (dispatch) => ({
  toAuthorization(id, step, routePrefix, shouldOpenInBlank) {
    const route = `${routePrefix}?step=${step || 1}&id=${id}`;
    if (shouldOpenInBlank) {
      window.open(`#${route}`, "_blank");
    } else {
      dispatch(push(route));
    }
  },
});

export default connect(() => ({}), mapDispatchProps)(AuthorizationListManager);
