import _ from "lodash";
import { PureComponent } from "react";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import styled from "styled-components";
import Select from "react-select";
import { BaseText } from "Segment/StyledComponents";

import PayerEdit from "./PayerEdit";
import { withInsuranceCompanies } from "../../../graphql/InsuranceCompany";
import {
  SupportTwoPane,
  SupportListContainer,
  SupportTable,
  SupportTableHeader,
  SupportTableRow,
  SupportCell,
  SupportScroll,
} from "../SharedStyles";

const StyledSelect = styled(Select)`
  margin-left: 10px;
  width: 325px;
`;

const InputLabel = styled.div`
  width: 100%;
  font-weight: 700;
  margin: 10px 0;
`;

const sortByOptions = ["name", "parentCompanyName", "tagCount"];

const columns = ["name", "searchTags", "parentCompany"];

class SupportPayers extends PureComponent {
  state = { searchText: "", sortBy: sortByOptions[0], selectedCompany: {} };

  clearSelectedCompany = () => {
    this.setState({ selectedCompany: {} });
  };

  resetSelectedCompany = () => {
    const { insuranceCompanies } = this.props;
    this.setState((prevState) => ({
      selectedCompany: _.find(insuranceCompanies, {
        id: prevState.selectedCompany.id,
      }),
    }));
  };

  render() {
    const { searchText, sortBy, selectedCompany } = this.state;
    const { insuranceCompanies } = this.props;
    const modifiedInsuranceCompanies = _.map(insuranceCompanies, (company) => ({
      ...company,
      ...(company.planParentCompanyId
        ? {
            parentCompanyName: _.find(insuranceCompanies, {
              id: company.planParentCompanyId,
            }).name,
          }
        : {}),
      tagCount: 0 - company.searchTags.length,
    }));
    const filteredOptions = _.filter(
      modifiedInsuranceCompanies,
      ({ name, searchTags }) =>
        _.includes(name.toUpperCase(), searchText.toUpperCase()) ||
        _.includes(searchTags.join("").toUpperCase(), searchText.toUpperCase())
    );

    return (
      <SupportTwoPane>
        <SupportListContainer>
          <div style={{ display: "flex" }}>
            <InputLabel style={{ width: "100%" }}>
              Select an Insurance Company to Edit
            </InputLabel>
            <InputLabel style={{ marginLeft: "10px", width: "325px" }}>
              Sort by:
            </InputLabel>
          </div>
          <div style={{ display: "flex", height: "38px" }}>
            <BaseText
              data-cy="fieldSearchPayerByName"
              value={searchText}
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
              placeholder="Search by Insurance Company Name or Search Tags"
            />
            <StyledSelect
              value={{ value: sortBy, label: sortBy }}
              onChange={({ value }) => {
                this.setState({ sortBy: value });
              }}
              options={_.map(sortByOptions, (option) => ({
                value: option,
                label: option,
              }))}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <SupportScroll>
            <SupportTable>
              <thead>
                <tr>
                  {_.map(columns, (name) => (
                    <SupportTableHeader key={`column-${name}`}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>{`${_.upperFirst(name)} `}</div>
                        <div style={{ marginLeft: "3px", fontSize: "30px" }}>
                          {sortBy === name ? "▾" : ""}
                        </div>
                      </div>
                    </SupportTableHeader>
                  ))}
                </tr>
              </thead>
              <tbody>
                {_.map(_.sortBy(filteredOptions, sortBy), (company) => (
                  <SupportTableRow
                    data-cy="componentPayerRow"
                    key={`insurance-compay-id-${company.id}`}
                    onClick={() => {
                      this.setState({ selectedCompany: company });
                    }}
                    selected={company.id === selectedCompany.id}
                  >
                    <SupportCell>
                      {company.name}
                      {"    "}
                      {company.isArchived && (
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          Archived
                        </span>
                      )}
                    </SupportCell>
                    <SupportCell>{company.searchTags.join(", ")}</SupportCell>
                    <SupportCell>{company.parentCompanyName}</SupportCell>
                  </SupportTableRow>
                ))}
              </tbody>
            </SupportTable>
          </SupportScroll>
        </SupportListContainer>
        <PayerEdit
          selectedCompany={selectedCompany}
          insuranceCompanies={_.sortBy(insuranceCompanies, "name")}
          clearSelectedCompany={this.clearSelectedCompany}
          resetSelectedCompany={this.resetSelectedCompany}
        />
      </SupportTwoPane>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withInsuranceCompanies)(withAlert()(SupportPayers));
