import { fetchAuthSession } from "aws-amplify/auth";
import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios";

async function createClient(options?: AxiosRequestConfig) {
  return axios.create({
    headers: window.CONFIG.COGNITO_ENABLED
      ? {
          Authorization: `Bearer ${
            (await fetchAuthSession())?.tokens?.idToken
          }`,
        }
      : {},
    ...options,
  });
}

async function clientPost<T = any, R = AxiosResponse<T>>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  const client = await createClient();
  return client.post<T, R>(url, data, config);
}

async function clientGet<T = any, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig
) {
  const client = await createClient();
  return client.get<T, R>(url, config);
}

export default { post: clientPost, get: clientGet };
