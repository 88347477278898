import _ from "lodash";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import Modal from "./Modal";
import LoadingButton from "./LoadingButton";
import { ModalBodyLarge } from "./ModalStyledComponents";

const HelperText = styled.div`
  text-align: center;
  margin: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: flex-end;
`;

const practiceDataExportMutation = gql`
  mutation practiceDataExport($isXLSX: Boolean!) {
    practiceDataExport(isXLSX: $isXLSX)
  }
`;

function PracticeReportDownloader({ close }) {
  const [practiceDataExport, { error, loading, data }] = useMutation(
    practiceDataExportMutation
  );

  const fileURL = _.get(data, "practiceDataExport");

  return (
    <Modal header="Authorizations Report" onClick={close} open>
      <ModalBodyLarge>
        <HelperText>
          {!error
            ? "Download your authorizations from SamaCare"
            : "There was an unknown error downloading your attachment. Refresh to try again, and please contact us if you continue to experience issues"}
        </HelperText>
        {fileURL ? (
          <HelperText>
            <a href={fileURL}>Download File Here</a>
          </HelperText>
        ) : (
          <ButtonContainer>
            <LoadingButton
              loading={loading}
              onClick={() =>
                practiceDataExport({ variables: { isXLSX: true } })
              }
            >
              Download as XLSX
            </LoadingButton>
            <LoadingButton
              style={{ marginLeft: "5px" }}
              loading={loading}
              onClick={() =>
                practiceDataExport({ variables: { isXLSX: false } })
              }
            >
              Download as CSV
            </LoadingButton>
          </ButtonContainer>
        )}
      </ModalBodyLarge>
    </Modal>
  );
}

/**
 * @deprecated Reorganize me?
 */
export default PracticeReportDownloader;
