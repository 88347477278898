export enum ColumnErrorCode {
  Required,
  InvalidDate,
  InvalidState,
  InvalidPhone,
  InvalidZip,
  InvalidName,
  InvalidSex,
  DuplicateMrn,
  MissingData,
}

export const columnErrorTextByCode: Record<ColumnErrorCode, string> = {
  [ColumnErrorCode.Required]: "Required",
  [ColumnErrorCode.InvalidDate]: "use MM/DD/YYYY format",
  [ColumnErrorCode.InvalidState]: "use 2 letter state abbreviation",
  [ColumnErrorCode.InvalidPhone]: "use xxx-xxx-xxxx format",
  [ColumnErrorCode.InvalidZip]:
    "use 5 or 9 digit zip code in xxxxx-xxxx format",
  [ColumnErrorCode.MissingData]: "this column has missing data",
  [ColumnErrorCode.InvalidName]: "names should not contain numbers",
  [ColumnErrorCode.InvalidSex]: "use Male, Female, or Other",
  [ColumnErrorCode.DuplicateMrn]: "Duplicate MRN",
};
