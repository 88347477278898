import React from "react";
import { Button, ButtonProps } from "@samacare/design/core";
import CheckCircleIcon from "@samacare/design/core/icons/CheckCircle";
import LinkIcon from "@samacare/design/core/icons/Link";
import Tooltip from "@samacare/design/core/Tooltip";

export type PermalinkButtonProps = {
  text?: string;
  url: string;
} & ButtonProps;

export const PermalinkButton: React.FC<PermalinkButtonProps> = ({
  text = "Copy",
  url,
  ...props
}) => {
  const [copied, setCopied] = React.useState(false);
  const ref = React.createRef<HTMLButtonElement>();
  return (
    <Tooltip title={copied ? "Copied!" : text}>
      <Button
        ref={ref}
        size="small"
        sx={{ minWidth: "auto" }}
        color={copied ? "success" : "primary"}
        onClick={async () => {
          const copyUrl =
            url.startsWith("/") || url.startsWith("#")
              ? window.location.origin + url
              : url;
          await navigator.clipboard.writeText(copyUrl);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }}
        {...props}
      >
        {copied ? <CheckCircleIcon /> : <LinkIcon />}
      </Button>
    </Tooltip>
  );
};
