import _ from "lodash";

import CheckIcon from "@samacare/design/core/icons/Check";
import ErrorOutlineOutlinedIcon from "@samacare/design/core/icons/ErrorOutlineOutlined";
import HourglassEmptyOutlinedIcon from "@samacare/design/core/icons/HourglassEmptyOutlined";
import HelpIcon from "@samacare/design/core/icons/Help";
import InsertDriveFileIcon from "@samacare/design/core/icons/InsertDriveFile";
import Chip, { ChipProps } from "@samacare/design/core/Chip";

export enum StatusOption {
  Success = "success",
  Completed = "completed",
  Submitted = "submitted",
  Pending = "pending",
  Error = "error",
  Draft = "draft",
  Inactive = "inactive",
  Active = "active",
  Unknown = "unknown",
}

const statusProps: Record<StatusOption, ChipProps> = {
  [StatusOption.Success]: {
    icon: <CheckIcon />,
    color: "success",
  },
  [StatusOption.Completed]: {
    icon: <CheckIcon />,
    color: "success",
  },
  [StatusOption.Submitted]: {
    icon: <HourglassEmptyOutlinedIcon />,
    color: "primary",
  },
  [StatusOption.Pending]: {
    icon: <HourglassEmptyOutlinedIcon />,
    color: "primary",
  },
  [StatusOption.Error]: {
    icon: <ErrorOutlineOutlinedIcon />,
    color: "error",
  },
  [StatusOption.Draft]: {
    icon: <InsertDriveFileIcon />,
    color: "default",
  },
  [StatusOption.Inactive]: {
    icon: <ErrorOutlineOutlinedIcon />,
    color: "error",
  },
  [StatusOption.Active]: {
    icon: <CheckIcon />,
    color: "success",
  },
  [StatusOption.Unknown]: {
    icon: <HelpIcon />,
    color: "default",
  },
};

export const StatusChip: React.FC<{
  status: StatusOption;
}> = ({ status }) => {
  const props = statusProps[status] ?? statusProps.unknown;
  return (
    <Chip
      size="small"
      variant="outlined"
      label={_.capitalize(status)}
      {...props}
    />
  );
};
