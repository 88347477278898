import IconButton from "@samacare/design/core/IconButton";
import { Prescriber } from "@samacare/graphql";
import DeleteIcon from "@samacare/design/core/icons/Delete";
import { useConfirmDialog } from "../../../hooks/confirmDialogHook";
import { useUpsertPrescriber } from "@samacare/hooks-data";

interface DeactivateIconButtonProps {
  prescriber: Prescriber;
}
export const DeactivateIconButton: React.VFC<DeactivateIconButtonProps> = (
  props
) => {
  const upsertPrescriber = useUpsertPrescriber();
  const deactivatePrescriber = async (prescriber: Prescriber) => {
    const newPrescriber = { ...prescriber, isDeactivated: true };
    await upsertPrescriber(newPrescriber);
  };

  const [ConfirmDialog, confirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog({
      message: `Delete the provider named ${props.prescriber.firstName} ${props.prescriber.lastName}?`,
      confirm: async () => deactivatePrescriber(props.prescriber),
      zIndexOffset: 1,
    });

  return (
    <>
      <IconButton onClick={() => setConfirmDialogOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <ConfirmDialog {...confirmDialogProps} />
    </>
  );
};
