import { VFC } from "react";
import { ColumnErrorCode, columnErrorTextByCode } from "./ColumnErrorCode";
import { Tooltip } from "@samacare/design";
import Error from "@samacare/design/core/icons/Error";

interface ColumnErrorIconProps {
  errorCode: ColumnErrorCode;
}
export const ColumnErrorIcon: VFC<ColumnErrorIconProps> = ({ errorCode }) => (
  <Tooltip
    title={columnErrorTextByCode[errorCode]}
    sx={{
      position: "absolute",
      left: ({ spacing }) => `calc(100% + ${spacing(1)})`,
      top: ({ spacing }) => `calc(50% + ${spacing(1)})`,
      transform: "translate(0, -50%)",
    }}
  >
    <Error color="error" />
  </Tooltip>
);
