import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { locationInfo } from "../graphql/fragments/locationInfo";

const locationByIdQuery = gql`
  query LocationById($id: Int!) {
    locationById(id: $id) {
      ...locationInfo
    }
  }
  ${locationInfo}
`;

export const useLocation = (id: number | string | undefined | null) => {
  const { data, loading } = useQuery(locationByIdQuery, {
    variables: { id: id ?? -1 },
  });

  return {
    location: data?.locationById ?? null,
    loading,
  };
};
