import styled from "styled-components";

/**
 * @deprecated Use a MUI component instead
 */
export const LeftRight = styled.div`
  display: flex;
`;

/**
 * @deprecated Use a MUI component instead
 */
export const LeftRightCenterV = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * @deprecated Use a MUI component instead
 */
export const LeftRightSpreadCenterV = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

/**
 * @deprecated Use a MUI component instead
 */
export const LeftRightCenterAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
