import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import colors from "Resources/colors";
import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";
import PrescriberDetails from "./PrescriberDetails/PrescriberDetails";
import BaseButton from "../../BaseButton";
import LocationDetails, {
  LocationType,
} from "./LocationDetails/LocationDetails";
import ROUTE_PATHS from "../../../routes/ROUTE_PATHS";
import { setFormFields } from "../../../reducers/formReducer";
import { LeftRightCenterAll } from "../../../components/LeftRight";
import { isDisplayWebExtensionDetails } from "../../../util/portalUtils";
import { RequesterSection } from "@@components/AuthorizationSharedComponents/RequesterSection";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const { DEFAULT_FIELDS } = CONFIG;

const MissingAuthWorkflowButton = styled(BaseButton)`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-top: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.purple};
`;

const BorderedInfoBox = styled.div`
  margin-top: 32px;
  border: 1px solid ${({ theme }) => theme.colors.purple};
  border-radius: 8px;
  padding: 8px 16px;
`;

const { PRESCRIBER, REFERRING_PROVIDER } = CONFIG.CONSTANTS.PRESCRIBER_TYPES;

export class Prescriber extends PureComponent {
  state = {
    isSubmitting: false,
  };

  mapConfigToModelKeys = (isPrescriber, providerType = PRESCRIBER) => {
    const { results } = this.props;
    if (isPrescriber) {
      switch (providerType) {
        case REFERRING_PROVIDER:
          return {
            firstName:
              results[DEFAULT_FIELDS.REFERRING_PROVIDER_FIRST_NAME.key],
            lastName: results[DEFAULT_FIELDS.REFERRING_PROVIDER_LAST_NAME.key],
            specialtyDescription:
              results[DEFAULT_FIELDS.REFERRING_PROVIDER_SPECIALTY.key],
            NPI: results[DEFAULT_FIELDS.REFERRING_PROVIDER_NPI.key],
            TIN: results[DEFAULT_FIELDS.REFERRING_PROVIDER_TIN.key],
            label: results[DEFAULT_FIELDS.REFERRING_PROVIDER_LABEL.key],
          };
        default:
          return {
            firstName: results[DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME.key],
            lastName: results[DEFAULT_FIELDS.PRESCRIBER_LAST_NAME.key],
            specialtyDescription:
              results[DEFAULT_FIELDS.PRESCRIBER_SPECIALTY.key],
            NPI: results[DEFAULT_FIELDS.PRESCRIBER_NPI.key],
            TIN: results[DEFAULT_FIELDS.PRESCRIBER_TIN.key],
            specialtyCode:
              results[DEFAULT_FIELDS.PRESCRIBER_SPECIALTY_CODE.key],
            licenseNumber:
              results[DEFAULT_FIELDS.PRESCRIBER_LICENSE_NUMBER.key],
            DEA: results[DEFAULT_FIELDS.PRESCRIBER_DEA_NUMBER.key],
            label: results[DEFAULT_FIELDS.PRESCRIBER_LABEL.key],
          };
      }
    }

    // Location
    switch (providerType) {
      case REFERRING_PROVIDER:
        return {
          facilityName:
            results[DEFAULT_FIELDS.REFERRING_PROVIDER_FACILITY_NAME.key],
          nickname:
            results[DEFAULT_FIELDS.REFERRING_PROVIDER_LOCATION_NICKNAME.key],
          address: results[DEFAULT_FIELDS.REFERRING_PROVIDER_ADDRESS.key],
          city: results[DEFAULT_FIELDS.REFERRING_PROVIDER_CITY.key],
          state: results[DEFAULT_FIELDS.REFERRING_PROVIDER_STATE.key],
          zip: results[DEFAULT_FIELDS.REFERRING_PROVIDER_ZIP.key],
          taxId: results[DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_TIN.key],
          NPI: results[DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_NPI.key],
          fax: results[DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_FAX.key],
        };
      default:
        return {
          facilityName: results[DEFAULT_FIELDS.FACILITY_NAME.key],
          nickname: results[DEFAULT_FIELDS.LOCATION_NAME.key],
          address: results[DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key],
          city: results[DEFAULT_FIELDS.PRESCRIBER_CITY.key],
          state: results[DEFAULT_FIELDS.PRESCRIBER_STATE.key],
          zip: results[DEFAULT_FIELDS.PRESCRIBER_ZIP.key],
          taxId: results[DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key],
          NPI: results[DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key],
          fax: results[DEFAULT_FIELDS.PRESCRIBER_OFFICE_FAX.key],
        };
    }
  };

  /*
   * This PDF submit functionality originally exists in FORM_DETAILS step (STEP 4)
   * for PA submission.
   * In Novartis PA submission workflow, we removed the FORM_DETAILS step,
   * so we added the novartisSubmitOverride in
   * PRESCRIBER_AND_LOCATION_DETAILS step (STEP 3)
   * for PDF submission
   */
  novartisSubmitOverride = () => {
    const { onSubmit, authorization } = this.props;
    onSubmit(
      () => {
        this.setState({ isSubmitting: true });
      },
      () => {
        this.setState({ isSubmitting: false });
      },
      () => {
        if (
          !_.includes(
            [
              CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key,
              CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key,
            ],
            authorization.type
          )
        ) {
          return false;
        }
        return true;
      },
      this.onInvalidFormState
    );
  };

  saveAndStep = async () => {
    const { nextStep } = this.props;

    try {
      nextStep();
    } catch {
      alert.error(
        "There was an error updating prescriber or location information"
      );
    }
  };

  saveProgressAndBackToMain = async () => {
    const { toAuths, saveChanges } = this.props;

    try {
      saveChanges();
      toAuths();
    } catch {
      alert.error(
        "There was an error updating prescriber or location information"
      );
    }
  };

  procureSubmitFunction = () => {
    const { isNovartis, nextStep, authorization } = this.props;

    if (
      isDisplayWebExtensionDetails(authorization) &&
      authorization.status !==
        CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION
    ) {
      return undefined; // don't show submit button
    } else if (isNovartis) {
      return () => {
        this.novartisSubmitOverride();
        nextStep();
      };
    }
    return async () => {
      this.setState({ isSubmitting: true });
      await nextStep();
      this.setState({ isSubmitting: false });
    };
  };

  render() {
    const {
      back,
      results,
      authorization,
      disabled,
      children,
      account,
      saveChanges,
      useLegacyButtons,
      validate,
    } = this.props;

    const { isSubmitting } = this.state;

    const isDispWebExtensionDetails =
      isDisplayWebExtensionDetails(authorization);

    let submitText;
    if (isDispWebExtensionDetails) {
      submitText = "Proceed";
    } else if (
      authorization.type ===
        CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key &&
      authorization.submissionPhoneNumber == null
    ) {
      submitText = "Complete";
    }

    return (
      <FormContainer>
        <RequesterSection
          auth={authorization}
          account={account}
          disabled={disabled}
          saveChanges={saveChanges}
        />

        <PrescriberDetails
          authorization={authorization}
          disabled={disabled}
          results={results}
          providerType={PRESCRIBER}
        />

        <LocationDetails
          authorization={authorization}
          locationType={LocationType.Servicing}
          disabled={disabled}
          validate={validate}
          results={results}
        />

        {account?.institution?.featureFlags?.ReferringProvider === true && (
          <>
            <PrescriberDetails
              authorization={authorization}
              disabled={disabled}
              results={results}
              providerType={REFERRING_PROVIDER}
            />
            <LocationDetails
              authorization={authorization}
              locationType={LocationType.Referring}
              disabled={disabled}
            />
          </>
        )}

        {authorization.status ===
          CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PREMIUM_RX_SUPPORT && (
          <>
            <MissingAuthWorkflowButton
              color={colors.purple}
              onClick={this.saveProgressAndBackToMain}
            >
              Back to main list
            </MissingAuthWorkflowButton>
            <p style={{ "font-size": "12px", "text-align": "right" }}>
              (PremiumRx Support will take it from here)
            </p>
          </>
        )}
        {!children &&
          authorization.status !==
            CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PREMIUM_RX_SUPPORT && (
            <>
              {isDispWebExtensionDetails &&
                authorization.status ===
                  CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION && (
                  <LeftRightCenterAll>
                    <BorderedInfoBox>
                      Once you launch the portal, SamaCare will assist with
                      pre-filling the authorization based on the information you
                      have provided
                    </BorderedInfoBox>
                  </LeftRightCenterAll>
                )}
              <FormSubmitButtons
                back={back}
                submitText={submitText}
                submit={this.procureSubmitFunction()}
                disabled={isSubmitting}
                loading={isSubmitting}
                useLegacyButtons={useLegacyButtons}
              />
            </>
          )}
      </FormContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    location: state.router.location,
    results: state.form.results,
    disabled: state.form.disabled || ownProps.disabled,
  };
}

const mapDispatchToProps = (dispatch) => ({
  set(fields) {
    dispatch(setFormFields(fields));
  },
  toAuths: () => {
    dispatch(push(ROUTE_PATHS.AUTHORIZATION_LIST_FILTERED.path));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose()(withAlert()(Prescriber)));
