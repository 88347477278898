import _ from "lodash";
import { PureComponent } from "react";

import {
  BaseInputCleave,
  handleInputFocus,
  getPropsFromInput,
} from "./BaseInput";

class DateInput3 extends PureComponent {
  constructor(props) {
    super(props);
    const splitDate = _.split(props.value, "/", 3);

    this.state = {
      partInputs: {
        0: {
          options: { date: true, datePattern: ["m"], delimiter: "/" },
          placeholder: "MM",
          value: splitDate[0],
        },
        1: {
          options: { date: true, datePattern: ["d"], delimiter: "/" },
          placeholder: "DD",
          value: splitDate.length > 1 ? splitDate[1] : "",
        },
        2: {
          options: { date: true, datePattern: ["Y"], delimiter: "/" },
          placeholder: "YYYY",
          value: splitDate.length > 2 ? splitDate[2] : "",
        },
      },
    };
  }

  updateValue = (target, part, partIndex) => {
    const { partInputs } = this.state;
    // update state
    this.setState(
      {
        partInputs: {
          ...partInputs,
          [partIndex]: { ...partInputs[partIndex], value: target.value },
        },
      },
      () => {
        const { partInputs: updatedPartInputs } = this.state;
        // store values for reference in state callback
        handleInputFocus(target, updatedPartInputs, partIndex);
        // trigger results update
        part.onChange({
          [part.id]: _.join(
            _.filter(
              _.map(updatedPartInputs, (input) => input.value),
              (s) => s
            ),
            "/"
          ),
        });
      }
    );
  };

  render() {
    const { parts } = this.props;
    const { partInputs } = this.state;

    if (parts.length !== 3 && CONFIG.NODE_ENV !== "production") {
      throw new Error("Invalid Date3");
    }

    return (
      <div>
        {_.map(parts, (part, i) => (
          <BaseInputCleave
            key={`date3_input_${part.id}_${i}`}
            ref={(input) => {
              partInputs[i].ref = input;
            }}
            {...getPropsFromInput(part)}
            onChange={(e) => {
              this.updateValue(e.target, part, i);
            }}
            value={partInputs[i].value}
            options={partInputs[i].options}
            placeholder={partInputs[i].placeholder}
          />
        ))}
      </div>
    );
  }
}

export default DateInput3;
