import Modal from "@@components/Modal";
import * as React from "react";
import { Account, Portal } from "@samacare/graphql";
import { WhiteButton } from "@@ui-kit/WhiteButton";

import { MfaSetupModalBody } from "./MfaSetupModalBody";

interface IMfaSetupModalProps {
  isOpen: boolean;
  closeDialog: () => void;
  portal?: Portal;
  currentAccount: Account;
}

export const MfaSetupModal: React.VFC<IMfaSetupModalProps> = (props) => {
  const { isOpen, portal, closeDialog, currentAccount } = props;

  if (portal == null) return null;

  return (
    <Modal
      open={isOpen}
      onClick={closeDialog}
      header={`MFA Setup - ${portal?.title ?? ""}`}
      headerStyleOverride={{ textAlign: "left" }}
    >
      <MfaSetupModalBody
        currentAccount={currentAccount}
        portal={portal}
        onDoneClick={closeDialog}
      >
        <WhiteButton onClick={closeDialog}>Skip</WhiteButton>
      </MfaSetupModalBody>
    </Modal>
  );
};

export * from "./MfaSetupModalBody";
