import { useQuery } from "@apollo/client";
import {
  GetRedoxSourcesQuery,
  GetRedoxSourcesQueryVariables,
} from "@@generated/graphql";
import getRedoxSources from "../graphql/GetRedoxSources.gql";
import { useCurrentAccount } from "../graphql/Account";

export const useHasEmrIntegration = (
  institutionId: string
): {
  hasUploadToOncoEmr: boolean;
  hasUploadToModernizingMedicine: boolean;
  hasUploadToNextGen: boolean;
  hasUploadToRedox: boolean;
} => {
  const { CONFIG } = window;
  let hasUploadToRedox = false;

  const redoxSourceResult = useQuery<
    GetRedoxSourcesQuery,
    GetRedoxSourcesQueryVariables
  >(getRedoxSources, {
    variables: { where: { InstitutionId: parseInt(institutionId) } },
  });

  const [account] = useCurrentAccount();
  if (!account) {
    return {
      hasUploadToOncoEmr: false,
      hasUploadToModernizingMedicine: false,
      hasUploadToNextGen: false,
      hasUploadToRedox,
    };
  }

  if (redoxSourceResult.data?.redoxSources) {
    hasUploadToRedox = redoxSourceResult.data?.redoxSources.length > 0;
  }

  const integrationInstallations = account.institution!.integrations ?? [];

  const checkIntegration = (integrationId: string) =>
    integrationInstallations.some(
      (installation) =>
        installation?.integrationId === integrationId && installation.isEnabled
    );

  return {
    hasUploadToOncoEmr: checkIntegration(
      CONFIG.CONSTANTS.INTEGRATION_TITLE.oncoEMR
    ),
    hasUploadToModernizingMedicine: checkIntegration(
      CONFIG.CONSTANTS.INTEGRATION_TITLE.modernizingMedicine
    ),
    hasUploadToNextGen: checkIntegration(
      CONFIG.CONSTANTS.INTEGRATION_TITLE.nextGen
    ),
    hasUploadToRedox,
  };
};
