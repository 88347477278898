import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { withAuthorizationQuery } from "app/graphql/Authorization";
import {
  GetAuthorizationQuery,
  GetAuthorizationQueryVariables,
} from "@@generated/graphql";
import { Authorization } from "@samacare/graphql";

export const useAuthorization = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { data, refetch } = useQuery<
    GetAuthorizationQuery,
    GetAuthorizationQueryVariables
  >(withAuthorizationQuery, {
    variables: { id: _.toNumber(params.get("id")) },
  });

  return {
    authorization: data?.authorizationById as Authorization | null,
    authorizationRefetch: refetch,
  };
};
