import { createContext, useContext } from "react";

type FormConfigType = Record<string, unknown>;

export const FormConfigContext = createContext<FormConfigType>({});

export const useFormConfig = (key?: string) => {
  const context = useContext(FormConfigContext);
  if (!key) return context;
  return context[key];
};
