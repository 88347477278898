import { PureComponent } from "react";
import Button from "@samacare/design/core/Button";
import LoadingButton from "@samacare/design/core/LoadingButton";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => (props.withoutMargin ? "0" : "20px 0")};
  gap: 10px;
`;

export const UnderlinedButton = styled(Button)`
  color: #6363f2;
  text-decoration: underline;
  border-radius: ${(props) => (props.roundBorders ? "20px" : "0")};
`;

export const SubmitButton = styled(LoadingButton)`
  color: white;
  background-color: #6363f2;
  border-radius: ${(props) => (props.roundBorders ? "20px" : "4px")};
`;

export class SubmitButtons extends PureComponent {
  render() {
    const {
      back,
      backText,
      submit,
      submitText,
      roundBorders = false,
      loading = false,
      disabled,
      submitDisabled,
      withoutMargin = false,
    } = this.props;
    return (
      <Container withoutMargin={withoutMargin}>
        {back ? (
          <UnderlinedButton
            variant="text"
            roundBorders={roundBorders}
            data-cy="actionBack"
            disabled={disabled}
            onClick={back}
          >
            {backText ?? "Back"}
          </UnderlinedButton>
        ) : (
          <div />
        )}
        {submit && (
          <SubmitButton
            data-cy="actionSubmit"
            variant="contained"
            roundBorders={roundBorders}
            disabled={disabled || submitDisabled}
            loading={loading}
            onClick={submit}
          >
            {submitText || "Next"}
          </SubmitButton>
        )}
      </Container>
    );
  }
}

export default SubmitButtons;
