import { useConfig } from "../../app/hooks";
import { getInsuranceType } from "../../app/util/getInsuranceType";

export type InsuranceTypeOption = {
  id: string;
  label: string;
};

const useInsuranceTypeOptions = (): InsuranceTypeOption[] => {
  const { CONSTANTS } = useConfig();

  return Object.entries(CONSTANTS?.INSURANCE_TYPES).map(([id, label]) => ({
    id,
    label: getInsuranceType(label),
  }));
};

export default useInsuranceTypeOptions;
