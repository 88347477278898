import * as React from "react";
import { Box, CircularProgress } from "@samacare/design/core";
import { Redirect } from "react-router-dom";

import { EnrollmentMenu } from "./EnrollmentMenu";
import { useEnrollmentContext } from "./EnrollmentProvider";
import Alert from "@samacare/design/core/Alert";
import { StepProvider } from "./StepProvider";

export const EnrollmentPage: React.FC = ({ children }) => {
  const {
    loading,
    enrollmentProgramQueryLoading,
    error,
    alert,
    onDismissAlert,
    enrollment,
  } = useEnrollmentContext();

  if (error) return <Redirect to="/enrollments" />;

  const isLoading = loading || enrollmentProgramQueryLoading;

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "50px",
          borderBottom: "1px solid rgb(176, 176, 176)",
          margin: "10px",
          fontWeight: 700,
          fontSize: "1.5rem",
        }}
      >
        {enrollment?.id ? `Enrollment ${enrollment.id}` : "New Enrollment"}
      </Box>
      <Box display="flex" justifyContent="space-between">
        {isLoading && <CircularProgress sx={{ mt: 10 }} />}
        {!isLoading && (
          <StepProvider>
            <Box p={2} width="75%" minWidth={600}>
              {alert && (
                <Alert
                  severity="error"
                  onClose={() => onDismissAlert && onDismissAlert()}
                  sx={{ mb: 4 }}
                >
                  {alert}
                </Alert>
              )}
              {children}
            </Box>
            <Box p={2} ml={2} width="15%">
              <EnrollmentMenu />
            </Box>
          </StepProvider>
        )}
      </Box>
    </Box>
  );
};
