import { ListItem, ListItemText, List } from "@samacare/design/core";

export const ModernizingMedicineConfirmationContent: React.VoidFunctionComponent<{
  data: string[] | null;
}> = ({ data }) => {
  return (
    <>
      Potential institution names:&nbsp;
      <List dense>
        {data?.map((name) => {
          return (
            <ListItem key={name}>
              <ListItemText>
                <b>{name}</b>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
      <p>
        Are any of the above names correct?
        <br />
        If not, follow up with practice to confirm the provided fields are
        correct
      </p>
    </>
  );
};

export const NextGenConfirmationContent: React.VoidFunctionComponent<{
  data: string | undefined;
}> = ({ data }) => {
  return (
    <>
      Institution name:&nbsp;
      <b>{data}</b>
      <p>
        Is this correct?
        <br />
        If not, follow up with practice to confirm the site ID and practice ID
        are correct
      </p>
    </>
  );
};

export const RedoxConfirmationContent: React.VoidFunctionComponent<{
  institutionName: string;
  facilityCode: string;
}> = ({ institutionName, facilityCode }) => {
  return (
    <>
      Institution name:&nbsp;
      <b>{institutionName}</b>
      <br />
      Facility code:&nbsp;
      <b>{facilityCode}</b>
      <p>
        Are these correct?
        <br />
        If not, follow up with practice to confirm these inputs are correct
      </p>
    </>
  );
};
