import { useState } from "react";
import styled from "styled-components";
import { withAlert } from "react-alert";

import Modal from "./Modal";
import BaseButton from "./BaseButton";
import { ModalBody, ModalFooter } from "./ModalStyledComponents";
import Upload from "./Upload";

const DismissButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.lightGray};
  border-color: ${(props) => props.theme.lightGray};
  color: white;
`;

const DangerButton = styled.div`
  color: ${(props) => props.theme.red};
  border: 1px solid ${(props) => props.theme.red};
  border-radius: 3px;
  text-align: center;
  padding: 10px;
  margin-left: 10px;

  :hover {
    cursor: pointer;
    font-weight: 700;
    background: ${(props) => props.theme.red};
    color: white;
  }
`;

const ModalContent = styled.div`
  padding: 10px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  ${BaseButton} {
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  margin: 9px 4px 7px 4px;
`;

export const UploadMissingFormModal = ({
  alert,
  close,
  onComplete,
  onDismiss,
}) => {
  const [fileKey, setFileKey] = useState(null);

  return (
    <Modal header="Upload Missing Form" onClick={close} open>
      <ModalBody>
        <ModalContent>
          <div>
            <div>
              <Title>Please upload a copy of the requested form</Title>
              <Upload
                disabled={fileKey != null}
                fullWidth
                onDropError={() =>
                  alert.error("There was an error uploading your file")
                }
                onDropSuccess={async (_file, res) => {
                  const key = res.data?.fields?.key || null;
                  if (key == null) {
                    alert.error("There was an error uploading your file");
                    return;
                  }
                  setFileKey(key);
                  onComplete(key);
                }}
              />
            </div>
          </div>
        </ModalContent>
        <CustomModalFooter>
          <DismissButton onClick={close}>Cancel</DismissButton>
          <DangerButton onClick={onDismiss}>
            I don&apos;t have this form
          </DangerButton>
        </CustomModalFooter>
      </ModalBody>
    </Modal>
  );
};

/**
 * @deprecated Use a MUI component instead
 */
export default withAlert()(UploadMissingFormModal);
