import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

export const createAuthUploadFromAwsKeyMutation = gql`
  mutation createAuthUploadFromAwsKey(
    $awsKey: String!
    $title: String!
    $authorizationId: Int!
    $maxFileSizeMB: Int
  ) {
    createAuthUploadFromAwsKey(
      awsKey: $awsKey
      title: $title
      authorizationId: $authorizationId
      maxFileSizeMB: $maxFileSizeMB
    ) {
      id
      awsKey
      title
    }
  }
`;

export const withFileByAWSKey = gql`
  query fileByAWSKey($awsKey: String!) {
    fileByAWSKey(awsKey: $awsKey)
  }
`;

export const withCreateAuthUploadFromAwsKey = graphql(
  createAuthUploadFromAwsKeyMutation,
  { name: "createAuthUploadFromAwsKey" }
);
