import gql from "graphql-tag";

export const prescriberInfo = gql`
  fragment prescriberInfo on Prescriber {
    id
    firstName
    lastName
    label
    specialtyDescription
    NPI
    TIN
    DEA
    licenseNumber
    specialtyCode
    createdAt
    updatedAt
    source
  }
`;
