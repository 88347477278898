import {
  InsuranceCompany,
  InsuranceCompanySupportedCodingTypes,
} from "@samacare/graphql";

export const filterInsuranceCompaniesByDrugType = (
  insuranceCompanies: InsuranceCompany[],
  drugType: string
): InsuranceCompany[] => {
  // Default to supporting just JCode
  if (
    drugType === InsuranceCompanySupportedCodingTypes.Jcode ||
    drugType == null
  ) {
    return insuranceCompanies.filter(
      (company) =>
        company.supportedCodingTypes ===
          InsuranceCompanySupportedCodingTypes.Jcode ||
        company.supportedCodingTypes ===
          InsuranceCompanySupportedCodingTypes.NdcAndJcode
    );
  }

  return insuranceCompanies.filter(
    (company) =>
      company.supportedCodingTypes ===
        InsuranceCompanySupportedCodingTypes.Ndc ||
      company.supportedCodingTypes ===
        InsuranceCompanySupportedCodingTypes.NdcAndJcode
  );
};
