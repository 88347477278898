import styled from "styled-components";
import OriginalSelect, {
  Props as OriginalProps,
  GroupBase as OriginalGroupBase,
} from "react-select";

export type SelectGroupBase<Option> = OriginalGroupBase<Option>;

export type SelectProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends SelectGroupBase<Option> = SelectGroupBase<Option>
> = OriginalProps<Option, IsMulti, Group>;

/**
 * @deprecated Use component from design/forms instead
 */
export const Select = styled(OriginalSelect)``;
