import React from "react";
import CheckCircleIcon from "@samacare/design/core/icons/CheckCircle";
import Tooltip from "@samacare/design/core/Tooltip";
import Chip, { ChipProps } from "@samacare/design/core/Chip";
import VisibilityIcon from "@samacare/design/core/icons/Visibility";
import VisibilityOffIcon from "@samacare/design/core/icons/VisibilityOff";
import { IconButton, Stack } from "@samacare/design/core";
import ContentCopyIcon from "@samacare/design/core/icons/ContentCopy";

export type MaskedValueProps = {
  name: string;
  value: string;
} & ChipProps;

export const MaskedValue: React.FC<MaskedValueProps> = ({
  name,
  value,
  ...props
}) => {
  const [masked, setMasked] = React.useState(true);
  const [copied, setCopied] = React.useState(false);
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip
        title={
          copied ? "Copied!" : `Click to ${masked ? "reveal" : "hide"} ${name}`
        }
      >
        <Chip
          {...props}
          onClick={(e) => {
            e.stopPropagation();
            setMasked(!masked);
          }}
          icon={masked ? <VisibilityIcon /> : <VisibilityOffIcon />}
          label={masked ? `********` : value}
        />
      </Tooltip>
      <Tooltip title={copied ? "Copied!" : `Copy ${name}`}>
        <IconButton
          size="small"
          color={copied ? "success" : "default"}
          onClick={async (e) => {
            e.stopPropagation();
            await navigator.clipboard.writeText(value);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
          }}
        >
          {copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
