import gql from "graphql-tag";

export default gql`
  fragment patientInfo on Patient {
    id
    dob
    institutionPatientId
    InsuranceCompanyId
    firstName
    lastName
    gender
    address
    city
    zip
    state
    phone
    primaryInsurance {
      memberId
    }
    status
    createdAt
    updatedAt
  }
`;
