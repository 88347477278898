import TabbedHeader from "@@components/MainList/TabbedHeader";
import { Account } from "@samacare/graphql";
import { useQuery } from "@apollo/client";
import { currentAccountQuery } from "app/graphql/Account";
import { PortalLoginsPane } from "app/routes/UserSettings/PortalLoginsPane";
import { useState } from "react";
import * as React from "react";
import { SettingsHeader } from "../../components/Settings/SettingsHeader";
import { SettingsMainContainer } from "../../components/Settings/SettingsMainContainer";

const portalLoginsTab = {
  title: "Portal Logins",
  tabTitle: "Portal Logins",
};
const tabs = [portalLoginsTab];

interface IUserSettingsPageProps {}
export const UserSettingsPage: React.VFC<IUserSettingsPageProps> = () => {
  const [view, setView] = useState(portalLoginsTab);

  const { data } = useQuery<{ currentAccount: Account }>(currentAccountQuery);
  const currentAccount = data?.currentAccount;

  if (currentAccount == null) return null;

  return (
    <SettingsMainContainer>
      <SettingsHeader>{`Settings - ${currentAccount.firstName ?? ""} ${
        currentAccount.lastName ?? ""
      }`}</SettingsHeader>
      <TabbedHeader
        onSelect={(tab) => {
          setView(tab);
        }}
        selected={view}
        tabs={tabs}
        marginBottom="0px"
        showHeaderLine
      />
      {view === tabs[0] && <PortalLoginsPane currentAccount={currentAccount} />}
    </SettingsMainContainer>
  );
};
