import { BaseText } from "Segment/StyledComponents";
import Modal from "../../components/Modal";
import IcdSelector from "../../components/IcdSelector";
import HcpcsSelector from "../../components/HcpcsSelector";
import BaseButton from "../../components/BaseButton";
import CustomCheckbox from "../../components/CustomCheckbox";
import { countMaxSamaTypesByPrefix } from "../../util/inputConfigurationManager";
import {
  icdChangeHandler,
  hcpcsChangeHandler,
} from "../../util/requestDetailsChangeHandlers";

const defaultFields = CONFIG.DEFAULT_FIELDS;

const TestFormModal = (props) => {
  const {
    open,
    closeModal,
    renderTestAuth,
    code,
    drugName,
    set,
    urgentRequest,
    initiationRequest,
    specification,
  } = props;
  const maxHCPCS = countMaxSamaTypesByPrefix(
    specification || [],
    CONFIG.DEFAULT_FIELDS.HCPCS_0.key.substring(0, 6)
  );
  const maxICDs = countMaxSamaTypesByPrefix(
    specification || [],
    CONFIG.DEFAULT_FIELDS.ICD_0.key.substring(0, 4)
  );
  return (
    <Modal
      open={open}
      header="Edit Test Authorization Data"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        style={{
          margin: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>Urgent Request:</div>
        <CustomCheckbox
          checked={urgentRequest}
          onChange={() => {
            set({
              [defaultFields.URGENT_REQUEST.key]: !urgentRequest && "On",
              [defaultFields.NOT_URGENT_REQUEST.key]: urgentRequest && "On",
            });
          }}
        />
        <div>Initiation Request:</div>
        <CustomCheckbox
          checked={initiationRequest}
          onChange={() => {
            set({
              [defaultFields.INITIATION_REQUEST.key]:
                !initiationRequest && "On",
              [defaultFields.CONTINUATION_REQUEST.key]:
                initiationRequest && "On",
            });
          }}
        />
      </div>
      <div style={{ margin: "8px" }}>
        <div style={{ margin: "8px 0" }}>Select ICDs:</div>
        <IcdSelector
          max={maxICDs || 10}
          onChange={(newIcds) => {
            icdChangeHandler(newIcds, set);
          }}
        />
      </div>
      <div
        style={{
          margin: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div style={{ margin: "8px 0" }}>Drug Name:</div>
        <BaseText
          onChange={(e) => {
            set({
              [defaultFields.PRIMARY_DRUG_NAME.key]: e.target.value,
            });
          }}
          value={drugName || ""}
          placeholder="Search by Number"
        />
      </div>
      <HcpcsSelector
        max={maxHCPCS || 10}
        initializedHCPCSCodes={[{ code, drugName }]}
        hideModifiers
        onChange={(newHcpcs) => {
          hcpcsChangeHandler(newHcpcs, set);
        }}
      />
      <BaseButton
        data-cy="controlRenderTestForm"
        style={{ margin: "0 auto" }}
        onClick={() => {
          renderTestAuth();
        }}
      >
        Render Form
      </BaseButton>
    </Modal>
  );
};

export default TestFormModal;
