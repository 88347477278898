import _ from "lodash";

const cleanLowerCase = (string) => string.toLowerCase().replace(/[^a-z]/g, "");
const filterInsuranceCompanyOptions = (company, value) => {
  if (company.isArchived) return false;
  return (
    _.isEmpty(value) ||
    _.some(
      _.filter([company.name, ...company.searchTags], (s) =>
        _.includes(cleanLowerCase(s), cleanLowerCase(value))
      )
    )
  );
};

export default filterInsuranceCompanyOptions;
