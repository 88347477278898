import styled from "styled-components";

const SharedRowStyles = `
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const TableContainer = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
`;

export const TableRow = styled.tr`
  ${SharedRowStyles}
  &:hover {
    background-color: ${(props) => props.theme.lightPurple};
    cursor: pointer;
  }
`;

export const TableRowEdit = styled.tr`
  ${SharedRowStyles}
  height: 175px;
  background-color: ${(props) => props.theme.lightPurple};
`;

export const TableHeader = styled.th`
  text-align: left;
  font-weight: 700;
  color: ${(props) => props.theme.lightGray};
`;

export const InnerTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const TableCell = styled.td`
  padding: 16px;
`;

export const TableCellName = styled.div`
  color: ${(props) => props.theme.purple};
`;

export const LabelHelperText = styled.em`
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.theme.purple};
  margin: 16px;
`;
