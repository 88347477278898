import _ from "lodash";
import {
  DataGridPro,
  GridColDef,
  Stack,
  Link,
  GridFilterListIcon,
  Typography,
} from "@samacare/design/core";
import Chip from "@samacare/design/core/Chip";
import GridWrapper from "../../components/DataGrid/GridWrapper";
import { PatientTreatmentDetailPage } from "./PatientTreatmentDetailPage";
import GridQuickFilter from "../../components/DataGrid/GridQuickFilter";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useConfig } from "../../hooks/config";
import { EnhancedServicesIcon } from "@samacare/component";

import {
  PatientTreatmentsGridRow,
  usePatientTreatmentsContext,
} from "./PatientTreatmentsProvider";
import { PatientPresetFilter } from "@samacare/graphql";
import { format } from "date-fns";

const PatientTreatmentToolBar = ({
  quickFilterText,
}: {
  quickFilterText?: string;
}) => {
  const { setPresetFilter, presetFilter } = usePatientTreatmentsContext();

  return (
    <Stack direction="row" alignItems="center" spacing={1} p={2}>
      <GridQuickFilter
        inputHeightOverride={40}
        inputWidthOverride={300}
        searchWord={quickFilterText}
        data-cy="patient-treatment-quick-filter"
      />
      <Chip
        label="Expiring PAs"
        icon={<GridFilterListIcon />}
        variant={presetFilter ? "filled" : "outlined"}
        color="primary"
        onClick={() => {
          const newFilterValue = presetFilter
            ? undefined
            : PatientPresetFilter.ExpiringAuths;
          setPresetFilter(newFilterValue);
        }}
      />
    </Stack>
  );
};

const RowAction: React.FC<{ label: string }> = ({ label }) => (
  <Link href=" " component="button">
    {label}
  </Link>
);

export const PatientTreatmentsGrid: React.FC = () => {
  const {
    toggleDrawer,
    setActiveRow,
    setPaginationModel,
    paginationModel,
    patientTreatmentCount,
    rows,
    filterModel,
    setFilterModel,
    loading,
  } = usePatientTreatmentsContext();
  const config = useConfig();
  const bvLiteEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEligibilityCheck
  );

  const enhancedMessagingEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEnhancedServicesMessaging
  );

  const columns: GridColDef[] = [
    {
      field: "institutionPatientId",
      headerName: "MRN",
      editable: false,
      minWidth: 100,
    },
    {
      field: "firstName",
      headerName: "First name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "lastName",
      headerName: "Last name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "dob",
      headerName: "Date of birth",
      editable: false,
      minWidth: 180,
      valueFormatter: ({ value }) => format(new Date(value), "MM/dd/yyyy"),
    },
    {
      field: "payerName",
      headerName: "Payer",
      editable: false,
      minWidth: 180,
    },
    {
      field: "drugs",
      headerName: "Drugs / Treatment",
      editable: false,
      minWidth: 300,
      renderCell: (params: { row: PatientTreatmentsGridRow }) => {
        return params.row.treatments.map((treatment, i) => {
          return (
            <Chip
              label={
                <Stack direction="row" spacing={0.75} alignItems="center">
                  <Typography>{treatment.drugName}</Typography>
                  {treatment.availableEnhancedServices &&
                    treatment.availableEnhancedServices.length > 0 &&
                    enhancedMessagingEnabled && (
                      <EnhancedServicesIcon sx={{ fontSize: 18 }} />
                    )}
                </Stack>
              }
              size="small"
              sx={{
                marginLeft: ({ spacing }) => spacing(1),
              }}
              key={i}
            />
          );
        });
      },
    },
    {
      field: "actions",
      headerName: "Action",
      editable: false,
      minWidth: 180,
      renderCell: (params: { row: PatientTreatmentsGridRow }) => {
        return (
          <Stack direction="row" spacing={2}>
            {params.row.showBvSupportNote && <RowAction label="Start BV" />}
            {params.row.hasBvInDraft && <RowAction label="Complete BV" />}

            {params.row.primaryInsurance?.supportsCoverageCheck &&
              bvLiteEnabled &&
              !params.row.primaryInsurance.latestCoverageCheck && (
                <RowAction label="Check Coverage" />
              )}

            {_.find(params.row.treatments, (tx) => tx.expiringAuth) && (
              <RowAction label="Renew PA" />
            )}
            {_.find(
              params.row.treatments,
              (tx) =>
                (tx.bvs ?? []).length > 0 &&
                (tx.authorizations ?? []).length === 0
            ) && <RowAction label="Start PA" />}
          </Stack>
        );
      },
    },
    {
      field: "lastUpdated",
      headerName: "Last updated",
      editable: false,
      minWidth: 180,
      valueFormatter: ({ value }) => format(new Date(value), "MM/dd/yyyy"),
    },
  ];

  const searchWords = filterModel?.quickFilterValues ?? [];
  return (
    <GridWrapper headerText="Patients">
      <DataGridPro
        loading={loading}
        disableColumnFilter
        disableColumnMenu
        slots={{
          toolbar: PatientTreatmentToolBar,
        }}
        rows={rows ?? []}
        columns={columns}
        onRowClick={(params) => {
          toggleDrawer(true);
          setActiveRow(_.find(rows, { id: params.row.id }));
        }}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={patientTreatmentCount ?? 0}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => {
          setFilterModel(newFilterModel);
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: "Search by Patient or Drug name",
        }}
        pageSizeOptions={[25, 50]}
        slotProps={{
          row: {
            "data-cy": "patientTreatmentRow",
          },
          pagination: {
            SelectProps: {
              inputProps: { "data-cy": "patientTreatmentPaginationSelect" },
            },
          },
          toolbar: {
            quickFilterText: searchWords?.[0] ?? null,
          },
        }}
      />
      <PatientTreatmentDetailPage />
    </GridWrapper>
  );
};
