import { Stack, Typography } from "@samacare/design/core";
import { useWatch } from "@samacare/form";
import { QuestionnaireField } from "../../components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { EnrollmentSection } from "./EnrollmentSection";

type EnrollmentFinancialInfoComponentProps = {
  disabled?: boolean;
};

export const EnrollmentFinancialInfoComponent: React.FC<
  EnrollmentFinancialInfoComponentProps
> = ({ disabled }) => {
  const questionnaireData = useWatch({
    name: "questionnaire",
  });

  if (questionnaireData.PatientAssistanceProgram) {
    return (
      <EnrollmentSection title="Financial Information">
        <Stack spacing={2} maxWidth={600}>
          <Typography variant="body1" color="text.primary">
            Must be completed for Patient Assistance Program requests only
          </Typography>
          <QuestionnaireField
            nameOverride="questionnaire.sama-financialInfo-houseHoldSize"
            text="How many people live in the patient’s household?"
            disabled={disabled}
            required
            type={QuestionTypeEnumType.Number}
          />
          <QuestionnaireField
            nameOverride="questionnaire.sama-financialInfo-houseHoldIncome"
            type={QuestionTypeEnumType.Choice}
            text="Total annual household income (including salary/wages; Social Security income; disability income; any other income)"
            answerOption={[
              { valueString: "Less than $150,000" },
              { valueString: "Greater than $150,000" },
            ]}
            required
            disabled={disabled}
          />
          <Typography variant="body1" color="text.primary">
            Supporting documentation may be required. ApellisAssist may also ask
            for proof of income at any time for audit/verification.
          </Typography>
        </Stack>
      </EnrollmentSection>
    );
  }
  return null;
};
