import { Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import Auth from "../Auth";
import Application from "../Application";
import AuthRoute from "../../components/AuthRoute";
import RootProvider from "../../providers/RootProvider";
import UnsupportedBrowser from "../../UnsupportedBrowser";
import ErrorBoundary from "../../components/ErrorBoundary";
import FreshdeskNavigator from "../../components/FreshdeskNavigator";

import createClient from "../../services/apollo";
import DataProvider from "../../providers/DataProvider";
import AuthProvider from "../../providers/AuthProvider";
import AccountProvider from "../../providers/AccountProvider";
import DatadogProvider from "../../providers/DatadogProvider";
import FeatureProvider from "../../providers/FeatureProvider";
import ApplicationProvider from "../../providers/ApplicationProvider";

import Login from "../../routes/Login";
import ResetPassword from "../../routes/ResetPassword";
import ROUTE_PATHS from "../../routes/ROUTE_PATHS";

// These variables are set in client/index.js
// @ts-ignore
const isSupported = !(isIE || isEdge);
const isLegacyAuth = !window.CONFIG.COGNITO_ENABLED;

export const Root = () => (
  <ErrorBoundary name="root">
    <RootProvider>
      {!isSupported && <UnsupportedBrowser />}
      {isLegacyAuth && (
        <AuthProvider>
          <ApolloProvider client={createClient()}>
            <FeatureProvider>
              <DatadogProvider>
                <ApplicationProvider>
                  <Switch>
                    {isLegacyAuth && (
                      <Route component={Login} path={ROUTE_PATHS.LOGIN.path} />
                    )}
                    {isLegacyAuth && (
                      <Route
                        component={ResetPassword}
                        path={ROUTE_PATHS.RESET_PASSWORD.path}
                      />
                    )}
                    <Route path="*">
                      <AccountProvider>
                        <Application />
                        {window.CONFIG.ENABLE_FRESHDESK && (
                          <FreshdeskNavigator />
                        )}
                      </AccountProvider>
                    </Route>
                  </Switch>
                </ApplicationProvider>
              </DatadogProvider>
            </FeatureProvider>
          </ApolloProvider>
        </AuthProvider>
      )}
      {!isLegacyAuth && (
        <AuthProvider>
          <DataProvider>
            <Switch>
              <Route path="/auth">
                <Auth />
              </Route>
              <AuthRoute>
                <AccountProvider>
                  <FeatureProvider>
                    <DatadogProvider>
                      <ApplicationProvider>
                        <Application />
                        {window.CONFIG.ENABLE_FRESHDESK && (
                          <FreshdeskNavigator />
                        )}
                      </ApplicationProvider>
                    </DatadogProvider>
                  </FeatureProvider>
                </AccountProvider>
              </AuthRoute>
            </Switch>
          </DataProvider>
        </AuthProvider>
      )}
    </RootProvider>
  </ErrorBoundary>
);
