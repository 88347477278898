import gql from "graphql-tag";

export const withGetStorySessionsQuery = gql`
  query getStorySessionsByAuthorizationId($authorizationId: Int!) {
    getStorySessionsByAuthorizationId(authorizationId: $authorizationId) {
      id
      createdAt
      createdBy {
        email
      }
      AuthorizationId
      sessionURL
      hasDeletedAuthorization
    }
  }
`;

export const withGetStorySessionByIdQuery = gql`
  query getStorySessionById($id: Int!) {
    getStorySessionById(id: $id) {
      id
      createdAt
      createdBy {
        email
      }
      events
    }
  }
`;

export const withGetStorySessionsByInstitutionOrAccount = gql`
  query getStorySessionsByInstitutionOrAccount(
    $institutionId: Int!
    $accountId: Int
  ) {
    getStorySessionsByInstitutionOrAccount(
      institutionId: $institutionId
      accountId: $accountId
    ) {
      id
      createdAt
      sessionURL
      createdBy {
        email
      }
      AuthorizationId
      hasDeletedAuthorization
    }
  }
`;
