import { useState } from "react";
import * as React from "react";
import PortalCredentialModal from "../routes/UserSettings/PortalCredentialModal";
import { MfaSetupModal } from "./MfaSetupModal";
import {
  Account,
  PortalCredential,
  TrackingStatus,
  Authorization,
  Portal,
} from "@samacare/graphql";
import { Button } from "@samacare/design/core";
import colors from "@samacare/design/core/colors";

interface StatusCheckerErrorButtonProps {
  children?: React.ReactNode;
  currentAccount: Account;
  authorization: Authorization;
}

/**
 * @deprecated Use a MUI component instead
 */
export const StatusCheckerErrorButton: React.VFC<
  StatusCheckerErrorButtonProps
> = (props) => {
  const { children, currentAccount, authorization } = props;

  const { trackingStatus, portal } = authorization;

  const blockedOnMfa = trackingStatus === TrackingStatus.BlockedOnMfa;
  const isWrongCreds = trackingStatus === TrackingStatus.WrongCreds;

  const [selectedPortal, setSelectedPortal] = useState<Portal | null>(null);

  const credentials = (
    currentAccount.portalCredentials as PortalCredential[]
  )?.find((creds) => {
    return (
      creds.PortalId != null && portal != null && creds.PortalId === +portal.id
    );
  });

  const [isPortalCredentialModalOpen, setIsPortalCredentialModalOpen] =
    useState(false);
  const [credentialToEdit, setCredentialToEdit] =
    useState<PortalCredential | null>(null);

  const [isMfaDialogOpen, setIsMfaDialogOpen] = useState(false);

  const handleClosePortalCredentialModal = (shouldOpenMfaDialog: boolean) => {
    setIsPortalCredentialModalOpen(false);
    setCredentialToEdit(null);
    if (shouldOpenMfaDialog) {
      setIsMfaDialogOpen(true);
    }
  };

  const handleOpenCredentialModal = () => {
    setSelectedPortal(portal ?? null);
    setCredentialToEdit(credentials ?? null);
    setIsPortalCredentialModalOpen(true);
  };

  const handleOpenMFAModal = () => {
    setSelectedPortal(portal ?? null);
    setIsMfaDialogOpen(true);
  };

  const handleCloseMfaDialog = () => {
    setIsMfaDialogOpen(false);
    setCredentialToEdit(null);
    setSelectedPortal(null);
  };

  return (
    <div>
      <PortalCredentialModal
        isOpen={isPortalCredentialModalOpen}
        credentialToEdit={credentialToEdit}
        portal={selectedPortal}
        currentAccount={currentAccount}
        currentInstitutionId={authorization.InstitutionId}
        onModalClose={handleClosePortalCredentialModal}
      />
      {selectedPortal != null && (
        <MfaSetupModal
          isOpen={isMfaDialogOpen}
          closeDialog={handleCloseMfaDialog}
          portal={selectedPortal}
          currentAccount={currentAccount}
        />
      )}
      <Button
        variant="outlined"
        sx={{
          backgroundColor: colors.N0,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (blockedOnMfa) {
            handleOpenMFAModal();
          } else if (isWrongCreds) {
            handleOpenCredentialModal();
          }
        }}
      >
        {children}
      </Button>
    </div>
  );
};
