import _ from "lodash";
import CheckIcon from "@samacare/design/core/icons/Check";
import ErrorOutlineOutlinedIcon from "@samacare/design/core/icons/ErrorOutlineOutlined";
import HourglassEmptyOutlinedIcon from "@samacare/design/core/icons/HourglassEmptyOutlined";
import HelpIcon from "@samacare/design/core/icons/Help";
import InsertDriveFileIcon from "@samacare/design/core/icons/InsertDriveFile";
import Chip, { ChipProps } from "@samacare/design/core/Chip";
import InfoOutlinedIcon from "@samacare/design/core/icons/InfoOutlined";
import { useConfig } from "../../hooks";

export const StatusChip: React.FC<{
  status: string;
}> = ({ status }) => {
  const config = useConfig();

  const statuses = config.CONSTANTS.AUTHORIZATION_STATUSES;
  let props: ChipProps;

  switch (status) {
    case statuses.DENIED:
    case statuses.SENDING_FAILED:
    case statuses.CAREMETX_FORM_NOT_FOUND:
      props = {
        icon: <ErrorOutlineOutlinedIcon />,
        color: "error",
      };
      break;
    case statuses.PRESUBMISSION:
      props = {
        icon: <InsertDriveFileIcon />,
        color: "primary",
      };
      break;
    case statuses.EDIT_AND_RESUBMIT:
    case statuses.SENT:
    case statuses.PENDING:
    case statuses.SENDING:
      props = {
        icon: <HourglassEmptyOutlinedIcon />,
        color: "primary",
      };
      break;
    case statuses.APPROVED:
    case statuses.MODIFIED_APPROVAL:
      props = {
        icon: <CheckIcon />,
        color: "success",
      };
      break;
    case statuses.ACTION_REQUIRED:
    case statuses.UNRECOGNIZED:
      props = {
        icon: <HelpIcon />,
        color: "warning",
      };
      break;
    default:
      props = {
        icon: <InfoOutlinedIcon />,
        color: "default",
      };
      break;
  }

  return (
    <Chip
      size="small"
      variant="outlined"
      label={_.startCase(status.replace(/_/g, " "))}
      {...props}
    />
  );
};
