import { useCallback } from "react";
import * as React from "react";
import _ from "lodash";
import { Authorization, Account } from "@samacare/graphql";
import styled from "styled-components";
import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";
import Section, { Items } from "AuthorizationSharedComponents/Section";
import AttachmentUpload from "AuthorizationSharedComponents/AttachmentUpload";
import {
  createTemplateContext,
  renderTemplate,
  placeholderDecorator,
  signatureDecorator,
  renderHtml,
  superscriptDecorator,
} from "./LomnShared";
import { useSelector, useDispatch } from "../../../configureStore";
import { setFormFields } from "../../../reducers/formReducer";
import MarkdownEditor from "@@ui-kit/forms/MarkdownEditor";
import { Panel } from "@@ui-kit/Panel";
import { PrimaryButton } from "@@ui-kit/PrimaryButton";
import { SecondaryButton } from "@@ui-kit/SecondaryButton";
import { ExternalLink } from "@@ui-kit/Icons";
import Modal from "../../Modal";
import { useAlert } from "react-alert";
import Button from "@samacare/design/core/Button";
import Stack from "@samacare/design/core/Stack";
import ExpandLessIcon from "@samacare/design/core/icons/ExpandLess";
import Popper from "@samacare/design/core/Popper";

const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
`;

const Logo = styled.img`
  max-width: 125px;
  position: relative;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

interface ILomnFillProps {
  authorization: Authorization;
  account: Account;
  submit: () => void;
  skip: () => void;
  back: () => void;
}

//This is a debugging component that shows if you use window.localStorage.setItem("showEditor", "true")
const LomnTemplateEditor: React.VFC<{
  authorizationConfig: Authorization["config"];
  authorization: Authorization;
  onChange: (template: string) => void;
}> = ({ authorizationConfig, authorization, onChange }) => {
  const { drugConfiguration } = authorization;
  const [template, setTemplate] = React.useState(
    (authorizationConfig as { USE_APPEAL: boolean | undefined }).USE_APPEAL ===
      true
      ? drugConfiguration?.appealMarkdownTemplate
      : drugConfiguration!.lomnMarkdownTemplate
  );
  const context = createTemplateContext(
    drugConfiguration,
    authorizationConfig,
    authorization.institution?.faxResponseNumber ?? null
  );
  const [err, rendered] = React.useMemo(
    () => renderTemplate(template ?? "", context),
    [template, context]
  );

  return (
    <div>
      <h4>Template</h4>
      {err && <span>{err}</span>}
      <TextArea
        value={template ?? ""}
        onChange={(e) => {
          setTemplate(e.target.value);
          onChange(e.target.value);
        }}
      />
      <h4>Markdown</h4>
      {!_.isError(rendered) && <pre>{rendered}</pre>}
      <h4>Rendered</h4>
      {_.isError(rendered) ? (
        <div>{_.toString(rendered)}</div>
      ) : (
        <MarkdownEditor readOnly markdown={rendered} />
      )}
      <h4>Fields</h4>
      <pre>
        {Object.keys(context as object)
          .map((key) => `<%= ${String(key)} %> : ${String(context[key])}`)
          .join("\n")}
      </pre>
      <pre>
        {Object.keys(context.CONFIG as object)
          .map(
            (key) =>
              `<%= CONFIG.${String(key)} %> : ${String(
                (context.CONFIG as { [key: string]: any })[key]
              )}`
          )
          .join("\n")}
      </pre>
    </div>
  );
};

const LetterTemplateModal: React.VFC<{
  markdown: string;
  onClose: () => void;
  isAppeal: boolean;
}> = ({ markdown, onClose, isAppeal }) => {
  return (
    <Modal
      styleOverride={{ width: "90%", maxHeight: "90%" }}
      header={
        isAppeal
          ? "Appeal Letter Template"
          : "Letter of Medical Necessity Template"
      }
      onClick={() => onClose()}
      open
    >
      <MarkdownEditor
        readOnly
        markdown={markdown}
        customDecorators={[
          placeholderDecorator,
          signatureDecorator,
          superscriptDecorator,
        ]}
      />
      <SecondaryButton data-cy="actionCloseTemplate" onClick={() => onClose()}>
        Close
      </SecondaryButton>
    </Modal>
  );
};

const SkipButton: React.VFC<{
  onSkip: (providedAttachment: boolean) => void;
  isAppeal: boolean;
}> = ({ onSkip, isAppeal }) => {
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLButtonElement>(null);
  return (
    <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <Button
        variant="outlined"
        disabled={open}
        ref={anchorEl}
        onClick={() => setOpen(!open)}
      >
        <ExpandLessIcon
          fontSize="small"
          sx={{ position: "absolute", top: "-4px" }}
        />
        <span style={{ position: "relative", top: "3px" }}>
          Skip {isAppeal ? "Appeals" : "LOMN"} wizard...
        </span>
      </Button>
      <Popper open={open} anchorEl={anchorEl.current} placement="top">
        <Stack
          spacing={0.5}
          direction="column"
          sx={{ paddingBottom: 0.5, backgroundColor: "white" }}
        >
          <Button
            variant="outlined"
            data-cy="actionUseAttachedLomn"
            onClick={() => onSkip(true)}
          >
            I&apos;ve attached my own {isAppeal ? "Appeal" : "LOMN"}
          </Button>
          <Button
            variant="outlined"
            data-cy="actionSkipLomn"
            onClick={() => onSkip(false)}
          >
            Submit without {isAppeal ? "Appeal" : "LOMN"}
          </Button>
        </Stack>
      </Popper>
    </div>
  );
};

const LomnFillStep: React.VFC<ILomnFillProps> = ({
  back,
  skip,
  submit,
  authorization,
  account,
}) => {
  //This lets us access the template editor for debugging the templates
  const showEditor = window.localStorage.getItem("showEditor") === "true";

  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [template, setTemplate] = React.useState<string | null>(null); //This template is only used for dev mode editing
  const [showTemplate, setShowTemplate] = React.useState(false);
  const authorizationConfig = useSelector((state) => state.form.results);
  const { drugConfiguration } = authorization;
  const alert = useAlert();
  const dispatch = useDispatch();
  const set = useCallback(
    (fields: Record<string, unknown>) => dispatch(setFormFields(fields)),
    [dispatch]
  );

  const isAppeal = authorizationConfig.USE_APPEAL === true;

  const getRenderedTemplate = useCallback(
    (templateMode?: boolean): [Error | null, string] => {
      if (!drugConfiguration) return [new Error("No drug configuration"), ""];

      const context = createTemplateContext(
        drugConfiguration,
        authorizationConfig,
        authorization.institution?.faxResponseNumber ?? null,
        templateMode
      );

      const configuredTemplate: string | undefined =
        (authorizationConfig.USE_APPEAL
          ? drugConfiguration.appealMarkdownTemplate
          : drugConfiguration.lomnMarkdownTemplate) ?? undefined;
      const [err, rendered] = renderTemplate(
        template ?? configuredTemplate ?? "",
        context as object
      );

      return [err, rendered];
    },
    [drugConfiguration, authorizationConfig, template]
  );

  const submitButtonClicked = useCallback(
    (skipConfirm?: boolean) => {
      const shouldSkipConfirm = skipConfirm ?? false;

      if (!drugConfiguration) return;

      const [err, rendered] = getRenderedTemplate();

      if (err !== null) {
        alert.error(`Error: Failed to render template.`);
        // eslint-disable-next-line no-console
        console.error(err);
        return;
      }

      const currentMarkdownTrimmed =
        (isAppeal
          ? authorizationConfig.APPEAL_MARKDOWN
          : authorizationConfig.LOMN_MARKDOWN) ?? "".trim();

      const updatedMarkdownTrimmed: string = rendered.trim();
      if (
        !shouldSkipConfirm &&
        currentMarkdownTrimmed.length > 0 &&
        currentMarkdownTrimmed !== updatedMarkdownTrimmed
      ) {
        setShowConfirmModal(true);
      } else {
        if (isAppeal) {
          set({ APPEAL_MARKDOWN: rendered, APPEAL_MODE: "wizard" });
        } else {
          set({ LOMN_MARKDOWN: rendered, LOMN_MODE: "wizard" });
        }

        //TODO figure out how to solve this more elegantly
        setTimeout(() => submit(), 200);
      }
    },
    [drugConfiguration, authorizationConfig, template, alert, set, submit]
  );

  const skipButtonClicked = useCallback(
    (providedAttachment: boolean) => {
      if (isAppeal) {
        set({
          APPEAL_MARKDOWN: null,
          APPEAL_MODE: providedAttachment ? "attached" : "skipped",
        });
      } else {
        set({
          LOMN_MARKDOWN: null,
          LOMN_MODE: providedAttachment ? "attached" : "skipped",
        });
      }
      //TODO figure out how to solve this more elegantly
      setTimeout(() => skip(), 200);
    },
    [set, submit]
  );

  if (!drugConfiguration) return null;

  const fields = isAppeal
    ? drugConfiguration.appealFields
    : drugConfiguration.lomnFields;

  const optionalFields = _.filter(fields, {
    optional: true,
  });
  const mainFields = _.without(fields, ...optionalFields);

  return (
    <div>
      <Panel>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <div>
            {authorizationConfig.USE_APPEAL ? (
              <>
                SamaCare Appeal Letter Service:
                {renderHtml(drugConfiguration.appealFillStepHtml ?? "")}
                <br />
              </>
            ) : (
              <>
                SamaCare Letter of Medical Necessity Service:
                <ul>
                  <li>
                    Including a Letter of Medical Necessity (LOMN) is important
                    and may help avoid delays
                  </li>
                  <li>
                    We have already pre-filled the patient demographic
                    information for you
                  </li>
                </ul>
                Estimate time required: 2 minutes{" "}
              </>
            )}
            <PrimaryButton
              data-cy="actionViewTemplate"
              onClick={() => setShowTemplate(true)}
            >
              View Letter Template
              <ExternalLink />
            </PrimaryButton>
          </div>
          <Logo
            data-cy="actionViewTemplate"
            src="../../assets/pdf_thumbnail.png"
            onClick={() => setShowTemplate(true)}
          />
        </Stack>
      </Panel>
      <Section
        childrenAfterSection
        isModal
        section={{
          title: "",
          items: mainFields as Items,
        }}
      />
      <Section
        childrenAfterSection
        section={{
          title: "Optional Fields",
          items: optionalFields as Items,
        }}
      />

      <AttachmentUpload
        showEMRDocumentOption
        authorization={authorization}
        account={account}
      />

      <FormSubmitButtons
        back={back}
        submit={() => submitButtonClicked()}
        disabled={false}
        loading={false}
      >
        <SkipButton
          isAppeal={isAppeal}
          onSkip={(providedAttachment: boolean) =>
            skipButtonClicked(providedAttachment)
          }
        />
      </FormSubmitButtons>

      <Modal
        header=""
        onClick={() => setShowConfirmModal(false)}
        open={showConfirmModal}
      >
        <p>
          Updated form entry will overwrite any manual changes made on the
          “Edit/Preview” screen. Do you want to continue?
        </p>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <SecondaryButton
            data-cy="actionOverwriteModalCancel"
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            data-cy="actionOverwriteModalContinue"
            onClick={() => submitButtonClicked(true)}
          >
            Continue
          </PrimaryButton>
          <PrimaryButton
            data-cy="actionOverwriteModalContinueNoOverwrite"
            onClick={submit}
          >
            Continue without overwrite
          </PrimaryButton>
        </Stack>
      </Modal>

      {showTemplate && (
        <LetterTemplateModal
          markdown={getRenderedTemplate(true)[1]}
          onClose={() => setShowTemplate(false)}
          isAppeal={authorizationConfig.USE_APPEAL === true}
        />
      )}

      {showEditor && (
        <LomnTemplateEditor
          authorizationConfig={authorizationConfig}
          authorization={authorization}
          onChange={(s) => setTemplate(s)}
        />
      )}
    </div>
  );
};

export default LomnFillStep;
