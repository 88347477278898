import { useMutation } from "@apollo/client";
import DeleteTrackerConfig from "../graphql/DeleteTrackerConfigMutation.gql";

export const useDeleteTrackerConfig = () => {
  const [deleteTrackerConfig] = useMutation(DeleteTrackerConfig, {
    refetchQueries: ["GetTrackerConfigs"],
  });

  return async (trackerConfigId: number) => {
    await deleteTrackerConfig({
      variables: {
        id: trackerConfigId,
      },
    });
  };
};
