import _ from "lodash";
import { useQuery } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import { withDefaultProps } from "./utils";
import { Prescriber } from "@samacare/graphql";
import { useMemo } from "react";
import { PrescribersQuery } from "../../generated/graphql";
import {
  PRESCRIBERS_FIND_ALL_QUERY_NAME,
  upsertPrescriberMutation,
} from "@samacare/hooks-data";

export const prescriberInfo = gql`
  fragment prescriberInfo on Prescriber {
    id
    firstName
    lastName
    label
    specialtyDescription
    NPI
    TIN
    DEA
    licenseNumber
    specialtyCode
    createdAt
    updatedAt
    source
  }
`;

export const allPrescribersQuery = gql`
  query Prescribers {
    prescribersFindAll {
      ...prescriberInfo
    }
  }
  ${prescriberInfo}
`;

export const usePrescribers = () => {
  const { data, loading } = useQuery<PrescribersQuery>(allPrescribersQuery);
  const prescribers = useMemo(
    () => data?.prescribersFindAll ?? [],
    [data]
  ) as Prescriber[];
  return { prescribers, loading };
};

export const withPrescribers = graphql(allPrescribersQuery, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    prescribers: _.get(data, "prescribersFindAll"),
  })),
});

export const withUpsertPrescriber = graphql(upsertPrescriberMutation, {
  name: "upsertPrescriber",
  options: { refetchQueries: () => [PRESCRIBERS_FIND_ALL_QUERY_NAME] },
});
