import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

import {
  AUTHORIZATION_PAGINATION_QUERY_NAME,
  GET_AUTHORIZATION_QUERY_NAME,
} from "./Authorization";

export const withCreateFollowUp = graphql(
  gql`
    mutation createFollowUp($authorizationId: Int!, $type: String!) {
      createFollowUp(authorizationId: $authorizationId, type: $type) {
        id
      }
    }
  `,
  {
    name: "createFollowUp",
    options: () => ({
      refetchQueries: () => [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
        GET_AUTHORIZATION_QUERY_NAME,
      ],
    }),
  }
);

export const withFollowUpSubmit = graphql(
  gql`
    mutation followUpSubmit($id: Int!, $text: String!) {
      followUpSubmit(id: $id, text: $text) {
        id
      }
    }
  `,
  {
    name: "followUpSubmit",
    options: () => ({
      refetchQueries: () => [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
        GET_AUTHORIZATION_QUERY_NAME,
      ],
    }),
  }
);

export const withFollowUpReviewed = graphql(
  gql`
    mutation followUpReviewed($id: Int!) {
      followUpReviewed(id: $id) {
        id
      }
    }
  `,
  {
    name: "followUpReviewed",
    options: () => ({
      refetchQueries: () => [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
        GET_AUTHORIZATION_QUERY_NAME,
      ],
    }),
  }
);
