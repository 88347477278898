import { PureComponent } from "react";
import styled from "styled-components";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import _ from "lodash";
import { withAlert } from "react-alert";
import AsyncSelect from "react-select/async-creatable";
import { InternationalPhone } from "Segment/InternationalPhone";
import { BaseMultiline } from "Segment/StyledComponents";
import colors from "Resources/colors";
import { MdVisibilityOff as VisOffIcon } from "@react-icons/all-files/md/MdVisibilityOff";
import { MdVisibility as VisOnIcon } from "@react-icons/all-files/md/MdVisibility";
import { ReactTooltip } from "@@ui-kit/ReactTooltip";

import RequiredTag from "../../../components/RequiredTag";
import {
  withCreateFormNumber,
  withPossibleFormNumbers,
  searchFormNumbersQuery,
  getForbiddenFormNumberQuery,
  withDeleteFormNumber,
  withUpdateFormNumber,
} from "../../../graphql/FormNumber";
import BaseButton from "../../../components/BaseButton";
import Favorite from "../../../components/Favorite";
import Tab from "@samacare/design/core/Tab";
import Tabs from "@samacare/design/core/Tabs";
import { parseServerErrorMessage } from "../../../util/parsers";
import { formatPhoneForDisplay } from "@samacare/utils";

const Container = styled.div`
  padding: 0;
`;

const ScrollContainer = styled.div`
  height: 275px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  background-color: ${({ isHidden }) => (isHidden ? colors.lightGray : "")};
  opacity: ${({ isHidden }) => (isHidden ? 0.7 : "1")};
`;

const FormDetails = styled.div`
  flex: 1;
  font-weight: ${({ isHighlighted }) => (isHighlighted ? 500 : 300)};
`;

const FormDescription = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.gray};
`;

const SelectFormButton = styled(BaseButton)`
  padding: 5px;
  font-size: 14px;
  margin-left: 10px;
`;

const SelectorTitle = styled.div`
  font-weight: 700;
  margin: 9px 4px 7px 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${BaseButton} {
    margin-left: 10px;
  }
`;

const BaseButtonStretch = styled(BaseButton)`
  width: 100%;
`;

const FormSubmitButton = styled(BaseButton)`
  margin-left: 10px;
  width: 100%;
`;

const EmptyState = styled.div`
  font-size: 16px;
  margin: 20px;
  text-align: center;
`;

const SUGGESTED_AND_FAVORITES_TITLE = "Suggested + Favorites";
export class SelectPhoneList extends PureComponent {
  state = {
    showCreateNumber: false,
    number: "",
    description: "",
    id: "",
    selectedNumber: "",
    selectedTabTitle: SUGGESTED_AND_FAVORITES_TITLE,
    isEditMode: false,
  };

  setNewNumber = async () => {
    const { createFormNumber, onSelect, insuranceCompanyId, alert, client } =
      this.props;
    const { number, description, id, selectedNumber } = this.state;

    if (!number) {
      alert.error("Please create a phone number selection");
    } else if (!description) {
      alert.error("Please add a description");
    } else if (id && number === selectedNumber) {
      await onSelect(id);
    } else {
      try {
        const blacklisted = await client.query({
          query: getForbiddenFormNumberQuery,
          variables: { number },
        });
        const blacklistedResp = blacklisted.data.getForbiddenFormNumber;
        if (blacklistedResp) {
          this.setState({
            description: blacklistedResp.description,
            forbiddenReason: blacklistedResp.forbiddenReason,
            selectedNumber: blacklistedResp.number,
            id: blacklistedResp.id,
          });
        } else {
          const created = await createFormNumber({
            variables: {
              number,
              description,
              insuranceCompanyId,
            },
          });
          await onSelect(created.data.createFormNumber.id);
        }
      } catch (e) {
        alert.error(parseServerErrorMessage(e.message));
      }
    }
  };

  queryNumbers = async (partialNumber) => {
    const { client } = this.props;
    const queriedNumbersRes = await client.query({
      query: searchFormNumbersQuery,
      variables: { partialNumber },
    });
    return _.get(queriedNumbersRes, "data.searchFormNumbers");
  };

  setIsHidden = async (id, shouldHide) => {
    const { updateFormNumber, institutionId } = this.props;

    await updateFormNumber({
      variables: {
        id,
        patch: { isHiddenInstitutionId: _.parseInt(institutionId), shouldHide },
      },
    });
  };

  handleTabSelection = (event, newValue) => {
    this.setState({ selectedTabTitle: newValue });
  };

  generateNumbers = (favoriteNumbers, suggestedNumbers, otherNumbers) => {
    const { onSelect, deleteFormNumber, institutionId } = this.props;
    const { selectedTabTitle } = this.state;
    const suggestedAndFavoriteNumbers = [
      ...favoriteNumbers,
      ...suggestedNumbers,
    ];
    const numberTabGroups = [
      {
        title: SUGGESTED_AND_FAVORITES_TITLE,
        numbers: suggestedAndFavoriteNumbers,
      },
      { title: "Other", numbers: otherNumbers },
    ];

    let selectedNumberTabGroup = _.find(numberTabGroups, {
      title: selectedTabTitle,
    });
    if (_.isEmpty(selectedNumberTabGroup.numbers)) {
      [selectedNumberTabGroup] = _.filter(
        numberTabGroups,
        (group) => !_.isEmpty(group.numbers)
      );
    }

    if (!_.isEmpty(suggestedAndFavoriteNumbers) || !_.isEmpty(otherNumbers)) {
      return (
        <>
          <Tabs
            value={selectedNumberTabGroup.title}
            onChange={this.handleTabSelection}
            variant="scrollable"
            scrollButtons="auto"
          >
            {_.map(_.filter(numberTabGroups, "numbers.length"), (config) => (
              <Tab
                label={config.title}
                value={config.title}
                sx={{
                  fontSize: "12px",
                  padding: "0 0",
                }}
              />
            ))}
          </Tabs>
          <ScrollContainer>
            {_.map(
              _.sortBy(selectedNumberTabGroup.numbers, [
                "isHiddenInstitutionIds",
              ]),
              (number) => {
                const isHidden = _.includes(
                  number.isHiddenInstitutionIds,
                  _.parseInt(institutionId)
                );
                return (
                  <FormContainer
                    key={`numberSelectContainer-numberId-${number.id}`}
                    isHidden={isHidden}
                  >
                    <FormDetails>
                      <Favorite
                        isFavorite={_.includes(favoriteNumbers, number)}
                        numberId={_.parseInt(number.id)}
                      />
                      {formatPhoneForDisplay(number.number)}
                      <FormDescription>{number.description}</FormDescription>
                    </FormDetails>
                    {number.canBeDeletedByEndUser && (
                      <SelectFormButton
                        color="#ff4242"
                        onClick={async () => {
                          await deleteFormNumber({
                            variables: { id: number.id },
                          });
                        }}
                      >
                        Delete
                      </SelectFormButton>
                    )}
                    <div data-tooltip-id="hideNumberButton">
                      <SelectFormButton
                        onClick={async () =>
                          this.setIsHidden(number.id, !isHidden)
                        }
                      >
                        {isHidden ? <VisOffIcon /> : <VisOnIcon />}
                      </SelectFormButton>
                      <ReactTooltip id="hideNumberButton">
                        Click here to to toggle the hide number button!
                      </ReactTooltip>
                    </div>
                    <SelectFormButton
                      data-cy="actionSelectFaxNumber"
                      onClick={() => {
                        onSelect(number.id);
                      }}
                    >
                      Select
                    </SelectFormButton>
                  </FormContainer>
                );
              }
            )}
          </ScrollContainer>
        </>
      );
    }
    return false;
  };

  render() {
    const {
      numbers,
      onBack,
      missingAuthSelected,
      parentStyleOverrides = {},
      onSkip,
      showLocateNumberTip,
    } = this.props;
    const {
      number,
      showCreateNumber,
      description,
      forbiddenReason,
      isEditMode,
    } = this.state;

    let content;
    if (!showCreateNumber) {
      content = (
        <Container>
          {_.get(numbers, "hasNumbers") ? (
            <div>
              {this.generateNumbers(
                numbers.favoriteNumbers,
                numbers.suggestedNumbers,
                numbers.remainingNumbers
              )}
            </div>
          ) : (
            <EmptyState>
              No fax numbers were found matching the specified insurance, plan,
              and form information. Please add a number using the button below.
            </EmptyState>
          )}
        </Container>
      );
    } else {
      content = (
        <Container>
          <SelectorTitle>Fax Number</SelectorTitle>
          {!isEditMode && (
            <AsyncSelect
              defaultOptions
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.number}
              loadOptions={async (input) => {
                const options = await this.queryNumbers(input);
                const formattedOptions = _.map(options, (opt) => {
                  return {
                    ...opt,
                    label: `${formatPhoneForDisplay(opt.number)} - ${
                      opt.description
                    }`,
                  };
                });
                return formattedOptions;
              }}
              filterOptions={(options, string) =>
                options.filter((option) => option.number.startsWith(string))
              }
              onChange={(selected) => {
                if (selected) {
                  this.setState({
                    number: selected.number,
                    description: selected.description,
                    id: selected.id,
                    selectedNumber: selected.number,
                    isEditMode: true,
                  });
                }
              }}
              onCreateOption={(value) => {
                this.setState({
                  showCreateNumber: true,
                  number: value,
                  isEditMode: true,
                });
              }}
              style={{ marginBottom: "260px" }}
            />
          )}
          {isEditMode && (
            <div style={{ marginBottom: "65px" }}>
              <InternationalPhone
                number={number}
                onChange={(e) => {
                  this.setState({ number: e.target.value });
                }}
              />
              {forbiddenReason ? (
                <div>
                  <SelectorTitle style={{ color: "red" }}>
                    WARNING - This number has been blacklisted by SamaCare
                  </SelectorTitle>
                  <div>
                    <b>Description:</b>
                    {` ${description}`}
                  </div>
                  <div>
                    <b>Reason for Blacklisting:</b>
                    {` ${forbiddenReason}`}
                  </div>
                  <SelectorTitle>
                    Are you sure you want to proceed?
                  </SelectorTitle>
                </div>
              ) : (
                <>
                  <SelectorTitle>
                    Description
                    <RequiredTag />
                  </SelectorTitle>
                  <BaseMultiline
                    onChange={(e) => {
                      this.setState({ description: e.target.value });
                    }}
                    value={description}
                  />
                </>
              )}
            </div>
          )}
        </Container>
      );
    }

    return (
      <div style={parentStyleOverrides}>
        {content}
        <ButtonContainer>
          {missingAuthSelected ? (
            <BaseButtonStretch
              color={colors.green}
              onClick={missingAuthSelected}
            >
              Request Support
            </BaseButtonStretch>
          ) : (
            <BaseButtonStretch
              onClick={showLocateNumberTip}
              color={colors.green}
            >
              Help
            </BaseButtonStretch>
          )}
          {onSkip != null && (
            <BaseButtonStretch
              data-cy="selectPhoneList_skipSending_button"
              color={colors.purple}
              onClick={onSkip}
            >
              Skip Sending
            </BaseButtonStretch>
          )}
          <BaseButtonStretch
            color={showCreateNumber ? "#757575" : "#c02d4c"}
            onClick={() => {
              this.setState({
                showCreateNumber: !showCreateNumber,
                forbiddenReason: "",
                number: "",
                id: "",
                isEditMode: false,
              });
            }}
          >
            {showCreateNumber ? "Back" : "Use Unlisted Number"}
          </BaseButtonStretch>
        </ButtonContainer>
        <ButtonContainer>
          {showCreateNumber && (
            <FormSubmitButton disabled={!number} onClick={this.setNewNumber}>
              Use this number
            </FormSubmitButton>
          )}
          {!showCreateNumber && onBack != null && (
            <BaseButtonStretch color={colors.gray} onClick={onBack}>
              Back
            </BaseButtonStretch>
          )}
        </ButtonContainer>
      </div>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withPossibleFormNumbers,
  withCreateFormNumber,
  withDeleteFormNumber,
  withUpdateFormNumber
)(withAlert()(withApollo(SelectPhoneList)));
