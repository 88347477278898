import MuiCircularProgress, {
  CircularProgressProps as MuiCircularProgressProps,
} from "@mui/material/CircularProgress";

import Box from "../Box/Box";
import Typography from "../Typography/Typography";

export type CircularProgressProps = MuiCircularProgressProps & {
  label?: boolean;
};

const CircularProgress: React.FC<CircularProgressProps> = ({
  value = 0,
  label = false,
  ...properties
}) =>
  label ? (
    <Box position="relative" display="inline-flex">
      <MuiCircularProgress {...properties} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" color="text.primary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  ) : (
    <MuiCircularProgress {...properties} />
  );

export default CircularProgress;
