import { updatePatientMutation } from "../graphql/Patient";
import {
  UpdatePatientMutation,
  UpdatePatientMutationVariables,
} from "@@generated/graphql";
import { useMutation } from "@apollo/client";
import { UpdatePatientArgs } from "@samacare/graphql";

export const useUpdatePatient = () => {
  const [updatePatient] = useMutation<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >(updatePatientMutation);

  const update = async ({
    id,
    patch,
  }: {
    id?: number;
    patch: Omit<UpdatePatientArgs, "gender">;
  }) => {
    if (id == null || patch == null) {
      return;
    }
    const result = await updatePatient({
      variables: {
        id,
        patch,
      },
      refetchQueries: ["getPatientById"],
      awaitRefetchQueries: true,
    });

    return result.data?.updatePatientById;
  };

  return update;
};
