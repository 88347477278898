import { useApolloClient, useQuery } from "@apollo/client";

import { useEffect, useMemo, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@samacare/design/core";
import IconButton from "@samacare/design/core/IconButton";
import { Location } from "@samacare/graphql";
import { GetLocationsQuery } from "@@generated/graphql";
import { getLocations } from "../../../graphql/Location";
import EditIcon from "@samacare/design/core/icons/Edit";
import { GridToolbar } from "../../../components/DataGrid/GridToolbar";
import { DeleteLocationIconButton } from "./DeleteLocationIconButton";
import { useRefreshReadySelectedObject } from "../_common/hooks/useRefreshReadySelectedObject";
import { UpsertLocationDialog } from "@samacare/form2";
import {
  formatPhoneForDisplay,
  formatTinForDisplay,
  formatZipForDisplay,
} from "@samacare/utils";
import { useAlert } from "react-alert";

function LocationTableNoResults() {
  return (
    <Box display="flex" justifyContent="center" mt={3}>
      <Typography variant="body">No locations have been added.</Typography>
    </Box>
  );
}

const LocationsTable: React.FC = () => {
  const alert = useAlert();
  const apolloClient = useApolloClient();
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );

  const isDrawerEnabled = false;

  const { data, loading } = useQuery<GetLocationsQuery>(getLocations);
  const locationList = useMemo(
    () => data?.currentAccount?.institution?.locations ?? [],
    [data]
  );

  const [selectedLocationForView, setSelectedLocationForView] =
    useRefreshReadySelectedObject<Location>(locationList as Location[]);

  useEffect(() => {
    if (selectedLocationForView && locationList) {
      const newLocation =
        locationList.find(
          (location) => location.id === selectedLocationForView.id
        ) ?? null;
      setSelectedLocationForView(newLocation as Location);
    }
  }, [locationList]);

  const gridColumns: GridColDef[] = [
    { field: "facilityName", headerName: "Location Name" },
    { field: "nickname", headerName: "Nickname" },
    { field: "address", headerName: "Address" },
    { field: "city", headerName: "City" },
    { field: "state", headerName: "State" },
    {
      field: "zip",
      headerName: "Zip",
      renderCell: (props) => formatZipForDisplay(props.value as string),
    },
    {
      field: "fax",
      headerName: "Fax",
      renderCell: (props) => formatPhoneForDisplay(props.value as string),
    },
    {
      field: "taxId",
      headerName: "Tax Id",
      renderCell: (props) => formatTinForDisplay(props.value as string),
    },
    { field: "PTAN", headerName: "PTAN" },
    { field: "NPI", headerName: "NPI" },
    {
      field: "buttons",
      headerName: "",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (props: GridRenderCellParams) => (
        <Stack direction="row" width="100%" justifyContent="flex-end">
          <IconButton
            onClick={() => setSelectedLocation(props.row as Location)}
          >
            <EditIcon />
          </IconButton>
          <DeleteLocationIconButton location={props.row as Location} />
        </Stack>
      ),
    },
  ];

  return (
    <>
      {!loading && (
        <DataGridPro
          rows={locationList}
          columns={gridColumns}
          onRowClick={(params) => {
            if (isDrawerEnabled) {
              setSelectedLocationForView(params.row as Location);
            }
          }}
          autosizeOnMount
          autosizeOptions={{ expand: true, includeHeaders: true }}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: LocationTableNoResults,
          }}
          slotProps={{
            toolbar: {
              onNewClick: () => setSelectedLocation({} as Location),
              newButtonDataCy: "actionNewLocation",
            },
          }}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
            sorting: {
              sortModel: [
                { field: "facilityName", sort: "asc" },
                { field: "name", sort: "asc" },
              ],
            },
          }}
          sx={{ border: 0, mt: 2 }}
        />
      )}

      <UpsertLocationDialog
        apolloClient={apolloClient}
        open={!!selectedLocation}
        LocationId={selectedLocation?.id}
        onClose={() => setSelectedLocation(null)}
        onAlert={(message) => {
          alert.error(message);
        }}
      />
    </>
  );
};

export default LocationsTable;
