import { deepmerge } from "@mui/utils";
import { GlobalStyles, css } from "@mui/system";
import { LicenseInfo } from "@mui/x-license-pro";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
// eslint-disable-next-line import/no-extraneous-dependencies
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

import LocalizationProvider from "./LocalizationProvider";
import defaultTheme from "./theme";

LicenseInfo.setLicenseKey(
  "fd0d9bf0863b9fb970a2ad1e2d360479Tz05MDk0MCxFPTE3NDc4NTI5MTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

export type ThemeProviderProps = Omit<
  React.ComponentProps<typeof MuiThemeProvider>,
  "theme"
> & {
  theme?: React.ComponentProps<typeof MuiThemeProvider>["theme"];
  popoverContainer?: React.ReactNode;
  cacheContainer?: Node;
};

const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  theme = defaultTheme,
  popoverContainer,
  cacheContainer,
}) => {
  // have to merge the theme with the popover container for theme provider
  // to work in the content script
  const mergedTheme = deepmerge(
    theme,
    popoverContainer
      ? {
          components: {
            MuiPopover: {
              defaultProps: {
                container: popoverContainer,
                sx: {
                  position: "absolute",
                },
              },
            },
            MuiPopper: {
              defaultProps: {
                container: popoverContainer,
                sx: {
                  position: "absolute",
                },
              },
            },
            MuiModal: {
              defaultProps: {
                container: popoverContainer,
                sx: {
                  position: "absolute",
                },
              },
            },
            MuiDialog: {
              defaultProps: {
                container: popoverContainer,
                sx: {
                  position: "absolute",
                },
              },
            },
            MuiMenu: {
              styleOverrides: {
                root: {
                  position: "fixed",
                },
              },
            },
          },
        }
      : {},
  ) as Partial<typeof defaultTheme>;

  const emotionCache = createCache({
    key: "sc-key",
    container: cacheContainer,
    prepend: true,
  });
  return (
    <>
      <GlobalStyles
        styles={css`
          body,
          html {
            height: 100%;
          }
        `}
      />
      <CacheProvider value={emotionCache}>
        <MuiThemeProvider theme={mergedTheme}>
          <CssBaseline />
          <LocalizationProvider>{children}</LocalizationProvider>
        </MuiThemeProvider>
      </CacheProvider>
    </>
  );
};

export default ThemeProvider;
