import _ from "lodash";

import {
  QuestionEnableWhenObjectType,
  QuestionEnabledWhenBehaviourEnumType,
} from "../Questionnaire.types";

export function getQuestionVisibility(
  values: any[] | false,
  enableWhen: QuestionEnableWhenObjectType[] = [],
  enableBehavior: QuestionEnabledWhenBehaviourEnumType = QuestionEnabledWhenBehaviourEnumType.Any
): boolean {
  if (!values) return true;

  const isAnyBehaviour =
    enableBehavior === QuestionEnabledWhenBehaviourEnumType.Any;
  const isAllBehaviour =
    enableBehavior === QuestionEnabledWhenBehaviourEnumType.All;

  const results = [];

  for (let i = 0; i < enableWhen.length; i++) {
    const item = enableWhen[i];
    const { answerBoolean, answerString } = item ?? {};

    const isAnswerBoolean = answerBoolean && answerBoolean != null;
    const isAnswerString = answerString && answerString != null;

    if (isAnswerBoolean) {
      results.push(answerBoolean === values[i]);
    }

    if (isAnswerString) {
      results.push(_.toLower(answerString) === _.toLower(values[i] as string));
    }

    if (isAnyBehaviour && results.includes(true)) {
      // when any of the enableWhen is true, then the question is always visible
      return true;
    }

    if (isAllBehaviour && results.includes(false)) {
      // when isAllBehaviour and visible is false, then the question is always hidden
      return false;
    }
  }

  if (isAllBehaviour) {
    return results.every((result) => result === true);
  }
  return false;
}
