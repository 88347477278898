import * as React from "react";
import styled from "styled-components";
import { useTheme } from "../../app/hooks";
import { Flex, Box } from "../Flexbox";
import * as Icons from "../Icons";
import { Tooltip } from "../Tooltip";
import { ControlMenuItem as BaseControlMenuItem } from "./Item";

const Spacer = styled(Flex)``;

const ControlMenuItem = styled(BaseControlMenuItem)`
  background-color: ${({ theme }) => theme.colors.primaryLight};
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
`;

const Reposition: React.VoidFunctionComponent<{
  onClick: () => void;
  position: "left" | "right";
}> = ({ onClick, position }) => {
  const theme = useTheme();

  let icon;
  switch (position) {
    case "left":
      icon = <Icons.BoxArrowInUpRight color={theme.colors.primaryDark} />;
      break;
    case "right":
      icon = <Icons.BoxArrowInUpLeft color={theme.colors.primaryDark} />;
      break;
  }

  return (
    <Tooltip
      content={`Move to ${position === "right" ? "left" : "right"} side`}
    >
      <Box width="18px" onClick={onClick}>
        {icon}
      </Box>
    </Tooltip>
  );
};

const Minifier: React.VoidFunctionComponent<{
  open: boolean;
  onClick: () => void;
}> = ({ open, onClick }) => {
  const theme = useTheme();

  return (
    <Tooltip content={`${open ? "Collapse" : "Expand"} menu`}>
      <Box width="18px" onClick={onClick}>
        {open ? (
          <Icons.Minus color={theme.colors.primaryDark} />
        ) : (
          <Icons.Plus color={theme.colors.primaryDark} />
        )}
      </Box>
    </Tooltip>
  );
};

export type ControlMenuControlsProps = React.ComponentProps<
  typeof ControlMenuControls
>;

/**
 * @deprecated Use component from design/forms instead
 */
export const ControlMenuControls: React.VoidFunctionComponent<{
  position: "left" | "right";
  onClickToggle: () => void;
  onClickReposition: () => void;
  open: boolean;
}> = ({ onClickReposition, onClickToggle, open, position }) => (
  <ControlMenuItem>
    <Flex
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
    >
      {/* Left */}
      <Reposition position={position} onClick={onClickReposition} />

      {/* Center */}
      <Spacer />

      {/* Right */}
      <Minifier open={open} onClick={onClickToggle} />
    </Flex>
  </ControlMenuItem>
);
