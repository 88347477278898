import { PureComponent } from "react";
import styled from "styled-components";

import AuthorizationDetails from "../../../components/MainList/AuthorizationDetails";

const Title = styled.div`
  color: ${(props) => props.theme.purple};
  font-weight: bold;
  margin-bottom: 5px;
`;

class DetailsSection extends PureComponent {
  render() {
    const { authorization } = this.props;

    return (
      <div>
        <Title>Authorization Details</Title>
        <AuthorizationDetails authorization={authorization} />
      </div>
    );
  }
}

export default DetailsSection;
