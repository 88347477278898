import React, { useMemo, useState } from "react";
import FavoriteForm from "@@components/FavoriteForm";
import { SubmitButton } from "@@components/SubmitButtons";
import { Flex } from "@@ui-kit";
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  IconButton,
} from "@samacare/design";
import ExpandMoreIcon from "@samacare/design/core/icons/ExpandMore";
import ZoomInIcon from "@samacare/design/core/icons/ZoomInIcon";
import { AuthorizationForm, FormNumber } from "@samacare/graphql";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import { formatPhoneForDisplay } from "@samacare/utils";

const fields = window.CONFIG.DEFAULT_FIELDS;

interface FaxFormCardProps {
  form: AuthorizationForm;
  setZoomedForm: (form: AuthorizationForm | undefined) => void;
  isFavorite: boolean;
  onSubmit: ({ formId }: { formId: string }) => void;
  onFavoriteChange: (id: string) => void;
}
const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  border: 1px solid #cfd8dc;
  margin-top: 16px;
  border-radius: 8px !important;
  overflow: hidden;
`;
const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  min-height: auto;
  color: #00000099;
  font-size: 0.9rem;

  &.Mui-focusVisible {
    background-color: #fff;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  color: #00000099;
`;

const StyledArrowIcon = styled.div`
  color: #00000099;
  border-radius: 4px;

  &:hover {
    background-color: #0000000a;
  }
`;

const ImgWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 16px;
  background-color: #eceff1;
  width: calc(100% + 4px);
  margin-left: -2px;
  margin-top: -2px;
  border-radius: 8px 8px 0 0;

  img {
    border-radius: 8px;
    border: 1px solid #cfd8dc;
    min-height: 475px;
  }
`;
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 16px 16px 16px;
`;
const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0 0;
  gap: 8px;
`;
const Tag = styled(Typography)`
  display: inline-flex;
  background-color: #eceff1;
  padding: 3px 8px;
  border-radius: 100px;
`;
const TimeNote = styled(Typography)`
  font-size: 0.9rem;
  color: #00000061;
`;

const MoreText = styled(Typography)`
  position: absolute;
  font-size: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  right: 36px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
`;

const Title = styled(Typography)`
  display: flex;
  letter-spacing: 0.15px;
`;

const StyledFavorite = styled.div`
  display: flex;
  margin-left: auto;
`;

const StyledUseButton = styled(SubmitButton)<{ roundBorders: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  border: 2px solid #00bcd480;
  color: #00bcd480;
  background-color: #fff;

  &:hover {
    color: #fff;
    background-color: #00bcd4;
  }
`;

const SubTitle = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`;
const FormCard = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  border: 2px solid transparent;

  img {
    width: 100%;
  }

  &:hover {
    border-color: #6363f280;
    cursor: pointer;

    ${ImgWrapper} {
      background-color: #6363f21f;
    }

    ${Title}, ${SubTitle} {
      color: #6363f2 !important;
    }

    ${StyledUseButton} {
      display: flex;
    }
  }
`;

const FaxFormCard = ({
  form,
  onSubmit,
  isFavorite,
  setZoomedForm,
  onFavoriteChange,
}: FaxFormCardProps) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);

  const timeNote = () => {
    if (form.lastUsed) {
      return `Last used ${moment(form.lastUsed).fromNow()}`;
    }
  };

  const stopPropagation = <T extends HTMLElement>(
    e: React.MouseEvent<T, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  const handleUseForm = <T extends HTMLElement>(
    e: React.MouseEvent<T, MouseEvent>
  ) => {
    stopPropagation<T>(e);
    if (form.id) {
      onSubmit({ formId: form.id });
    }
  };
  const sortStrings = (stringA: string, stringB: string): number => {
    return stringA.toLowerCase() > stringB.toLowerCase() ? 1 : -1;
  };

  const jCodes: string[] = useMemo(() => {
    return [...((form.formFilter?.jCodes as string[]) || [])].sort(sortStrings);
  }, [form.formFilter?.jCodes, sortStrings]);

  const states: string[] = useMemo(() => {
    return [...((form.formFilter?.states as string[]) || [])].sort(sortStrings);
  }, [form.formFilter?.states, sortStrings]);

  const insuranceTypes: string[] = useMemo(() => {
    return [...((form.formFilter?.insuranceTypes as string[]) || [])].sort(
      sortStrings
    );
  }, [form.formFilter?.insuranceTypes, sortStrings]);

  const insuranceCompaniesNames: string[] = useMemo(
    () =>
      [...((form.insuranceCompaniesNames as string[]) || [])].sort(sortStrings),
    [form.insuranceCompaniesNames, sortStrings]
  );
  return (
    <FormCard key={form.id} onClick={() => onSubmit({ formId: form.id })}>
      <ImgWrapper>
        <img src={form.imgURLs[0]} alt={`${form.title} Form`} />
        <StyledIconButton
          onClick={(e) => {
            stopPropagation<HTMLButtonElement>(e);
            setZoomedForm(form);
          }}
        >
          <ZoomInIcon />
        </StyledIconButton>
        <StyledUseButton
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleUseForm<HTMLButtonElement>(e)
          }
          variant="contained"
          roundBorders
          data-cy="actionFormSelectionConfirm"
        >
          Use Form
        </StyledUseButton>
      </ImgWrapper>
      <DataWrapper>
        <Title variant="h6">
          {form.title}
          <StyledFavorite onClick={(e) => stopPropagation<HTMLDivElement>(e)}>
            <FavoriteForm
              isFavorite={isFavorite}
              formId={_.parseInt(form.id)}
              onChange={onFavoriteChange}
            />
          </StyledFavorite>
        </Title>
        <SubTitle variant="subtitle1">{form.description}</SubTitle>
        {form.lastUsed ? (
          <TimeNote variant="caption">{timeNote()}</TimeNote>
        ) : null}
        <TagsWrapper>
          {jCodes.map((jCode) => (
            <Tag key={jCode + form.id} variant="body2">
              {jCode}
            </Tag>
          ))}
          {states.map((state) => (
            <Tag key={state + form.id} variant="body2">
              {state}
            </Tag>
          ))}
          {insuranceTypes.map((insuranceType) => (
            <Tag key={insuranceType + form.id} variant="body2">
              {(insuranceType && fields[insuranceType]?.title) || ""}
            </Tag>
          ))}
          {insuranceCompaniesNames.map((name) => (
            <Tag key={name + form.id} variant="body2">
              {name}
            </Tag>
          ))}
        </TagsWrapper>
        {form.formNumbers?.[0]?.number ? (
          <StyledAccordion
            disableGutters
            onClick={(e) => stopPropagation<HTMLDivElement>(e)}
            onChange={(e, isExpanded) => setIsDetailsExpanded(isExpanded)}
          >
            <StyledAccordionSummary
              expandIcon={
                <StyledArrowIcon>
                  <ExpandMoreIcon />
                </StyledArrowIcon>
              }
            >
              <Typography variant="body2" margin={0}>
                {formatPhoneForDisplay(form.formNumbers?.[0]?.number || "")}
              </Typography>
              <MoreText variant="body2">
                {isDetailsExpanded ? "See Less" : "See More"}
              </MoreText>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {(form.formNumbers as FormNumber[])
                ?.slice(1)
                ?.map(({ id, number, description }: FormNumber) => (
                  <Flex key={id} flexDirection="column" marginBottom="8px">
                    <Typography variant="body2" margin={0}>
                      {formatPhoneForDisplay(number || "")}
                    </Typography>
                    <Typography variant="body2" margin={0}>
                      {description}
                    </Typography>
                  </Flex>
                ))}
            </StyledAccordionDetails>
          </StyledAccordion>
        ) : null}
      </DataWrapper>
    </FormCard>
  );
};

export default FaxFormCard;
