import React from "react";
import { MenuItem, Typography } from "@samacare/design/core";
import { SxProps } from "@samacare/design/core/styles";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

interface RenderOptionProps {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: {
    id: string;
    title: string;
  };
  state: {
    inputValue: string;
  };
  sx?: SxProps;
}

const renderOptionsAutoSuggest = ({
  props,
  option,
  state,
  sx = {},
}: RenderOptionProps) => {
  const { inputValue } = state;

  const matches = match(option.title, inputValue, { insideWords: true });
  const parts = parse(option.title, matches);

  return (
    <MenuItem
      {...props}
      key={option.id}
      sx={{
        py: 1,
        px: 2,
        border: "none",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "lightGray",
        },
        fontSize: "16px",
        lineHeight: "1.5",
        display: "block",
        whiteSpace: "pre-wrap",
        ...sx,
      }}
    >
      {parts.map((part, index) => (
        <Typography
          key={index}
          component="span"
          sx={{
            fontWeight: part.highlight ? 700 : 400,
            fontSize: "16px",
          }}
        >
          {part.text}
        </Typography>
      ))}
    </MenuItem>
  );
};

export default renderOptionsAutoSuggest;
