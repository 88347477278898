import _ from "lodash";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Box, Grid } from "@samacare/design/core";

import ROUTE_PATHS from "../../routes/ROUTE_PATHS";
import { EnrollmentSection } from "./../../routes/Enrollment/EnrollmentSection";
import {
  useEnrollmentContext,
  useOcrevusQuestionnaire,
} from "../../routes/Enrollment/EnrollmentProvider";
import { EnrollmentPatientInfoHeader } from "../../routes/Enrollment/EnrollmentPatientInfoHeader";
import { EnrollmentServiceResponseParser } from "../../routes/Enrollment/EnrollmentServiceResponseParser";
import { IEnrollmentServiceResponseBodyType } from "../../routes/Enrollment/EnrollmentServiceResponseBodyType";
import { ModelType, TemplateType } from "@samacare/graphql";
import { DownloadAsPdf } from "../../components/AuthorizationSharedComponents/DownloadAsPdf";
import { getPatientConsentStatus } from "../../routes/Enrollment/utils";

import { GenentechOcrevusSummaryResponse } from "./GenentechOcrevusSummaryResponse";

export const GenentechOcrevusSummary: React.FC = () => {
  const history = useHistory();
  const { enrollment } = useEnrollmentContext();
  const { answers } = useOcrevusQuestionnaire();

  const serviceResponsesByService = _.groupBy(
    enrollment?.EnrollmentServiceResponses ?? [],
    "serviceLinkId"
  );
  const latestResponseByService = _.mapValues(
    serviceResponsesByService,
    (responses) => {
      const response = _.first(responses);
      return response?.query
        ? new EnrollmentServiceResponseParser(
            response?.query as IEnrollmentServiceResponseBodyType
          )
        : false;
    }
  );

  return (
    <>
      <EnrollmentPatientInfoHeader />
      <Box sx={{ margin: "30px" }}>
        <EnrollmentSection
          title="Summary"
          adornment={
            <DownloadAsPdf
              template={TemplateType.Enrollment}
              modelType={ModelType.Enrollment}
              modelId={parseInt(enrollment!.id!)}
            />
          }
        >
          <Box sx={{ width: "70%" }}>
            <Grid sx={{ margin: "10px 0", fontWeight: 700 }}>Status:</Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                Enrollment:
              </Grid>
              <Grid item xs={6}>
                Submitted (
                {moment(enrollment?.submittedAt ?? new Date()).format(
                  "MM/DD/YYYY, h a"
                )}
                )
              </Grid>
              <Grid item xs={6}>
                Patient Consent:
              </Grid>
              <Grid item xs={6}>
                {getPatientConsentStatus(enrollment)}
              </Grid>
              {answers?.questionnaire?.["applied-for-bipa"] && (
                <>
                  <Grid item xs={6}>
                    BV/PA:
                  </Grid>
                  <Grid item xs={6}>
                    Included
                  </Grid>
                </>
              )}
              {answers?.questionnaire?.["applied-for-pf"] && (
                <>
                  <Grid item xs={6}>
                    Patient Foundation:
                  </Grid>
                  <Grid item xs={6}>
                    Included
                  </Grid>
                </>
              )}
              {answers?.questionnaire?.["applied-for-pf"] && (
                <>
                  <Grid item xs={6}>
                    Patient Foundation:
                  </Grid>
                  <Grid item xs={6}>
                    Included
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </EnrollmentSection>

        {_.map(
          Object.entries(latestResponseByService),
          ([serviceLinkId, parser]) =>
            parser && (
              <GenentechOcrevusSummaryResponse
                key={`GenentechOcrevusSummaryResponse-${serviceLinkId}`}
                serviceLinkId={serviceLinkId}
                parser={parser}
              />
            )
        )}
      </Box>

      <Button
        type="button"
        variant="outlined"
        size="large"
        onClick={() => {
          history.push(`${ROUTE_PATHS.ENROLLMENTS.path}`);
        }}
      >
        Back to Enrollment List
      </Button>
    </>
  );
};
