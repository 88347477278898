import { useCallback, useEffect, useRef } from "react";
import { throttle } from "lodash";

// This hook will call the given `onTimeout` handler every `timeout` ms
// unless the user clicks somewhere in the window or scrolls.
// A window mouse down event or scroll will reset the timer.
// Set `scrollThrottle` to override the throttle period.
export const useCallAfterIdle = <T,>(
  onTimeout: () => Promise<T>,
  timeout: number,
  scrollThrottle?: number
): void => {
  const timerHandle = useRef<number>();

  const clearTimer = useCallback(() => {
    if (timerHandle.current !== undefined) {
      window.clearTimeout(timerHandle.current);
      timerHandle.current = undefined;
    }
  }, [timerHandle]);

  const createTimer = useCallback(() => {
    timerHandle.current = window.setTimeout(() => {
      const performHandleTimeout = async () => {
        await onTimeout();
        clearTimer();
        createTimer();
      };
      void performHandleTimeout();
    }, timeout);
  }, [timerHandle, onTimeout, clearTimer, timeout]);

  // initialize timer
  useEffect(() => {
    createTimer();
    return clearTimer;
  }, [createTimer, clearTimer]);

  // reset the timer everytime the user clicks (mousedown) or scrolls
  useEffect(() => {
    const handleUserInteraction = () => {
      clearTimer();
      createTimer();
    };
    document.addEventListener("mousedown", handleUserInteraction);

    const handleScroll = throttle(
      handleUserInteraction,
      scrollThrottle ?? 250,
      {
        trailing: false,
      }
    );
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleUserInteraction);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [clearTimer, createTimer, scrollThrottle]);
};
