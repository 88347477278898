import { useQuery } from "@apollo/client";
import getIngestTransformQuery from "../graphql/GetIngestTransformQuery.gql";
import { GetIngestTransformQueryQuery } from "@@generated/graphql";

export const useIngestTransform = () => {
  const { data, loading } = useQuery<GetIngestTransformQueryQuery>(
    getIngestTransformQuery
  );
  return { ingestTransform: data?.getIngestTransform ?? null, loading };
};
