import styled from "styled-components";
import SimplifiedModal from "@@components/SimplifiedModal";
import { Typography } from "@samacare/design/core";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

const FindingFormModal = ({ onClose, onFinish, open }) => (
  <SimplifiedModal
    header="Finding Your Form"
    onClick={onClose}
    submit={onFinish}
    open={open}
    submitText="Report Missing Form"
    back={onClose}
  >
    <Container>
      <Typography variant="body1">
        If you do not see a relevant form, use the member’s insurance card to
        verify the information below.
      </Typography>
      <Details>
        <Typography variant="body2">
          Does the ‘Insurance Issuing State’ match the member’s plan?
        </Typography>
        <Typography variant="body2">
          Does the ‘Insurance Type’ match the member’s plan?
        </Typography>
      </Details>
      <Typography variant="body1">
        If you checked the above details and still do not see a the form, please
        report the missing form to our team.
      </Typography>
    </Container>
  </SimplifiedModal>
);

export default FindingFormModal;
