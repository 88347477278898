import { InsuranceType } from "./generated/segment";

const defaultFields = window.CONFIG.DEFAULT_FIELDS;
export const getSegmentEnumInsuranceTypeFromAuthConfig = (
  authConfig: Record<string, unknown>
) => {
  if (authConfig[defaultFields.IS_MEDICAID.key]) {
    return InsuranceType.Medicaid;
  }
  if (authConfig[defaultFields.IS_MEDICARE_ADVANTAGE.key]) {
    return InsuranceType.MedicareAdvantageOrReplacement;
  }
  if (authConfig[defaultFields.IS_COMMERCIAL.key]) {
    return InsuranceType.Commercial;
  }
  return InsuranceType.Other;
};

export const getSegmentEnumInsuranceTypeFromSamacareInsuranceType = (
  SamacareInsuranceType?: string
) => {
  switch (SamacareInsuranceType) {
    case window.CONFIG.DEFAULT_FIELDS.IS_COMMERCIAL.key:
      return InsuranceType.Commercial;
    case window.CONFIG.DEFAULT_FIELDS.IS_MEDICARE_ADVANTAGE.key:
      return InsuranceType.MedicareAdvantageOrReplacement;
    case window.CONFIG.DEFAULT_FIELDS.IS_MEDICAID.key:
      return InsuranceType.Medicaid;
    default:
      return InsuranceType.Other;
  }
};
