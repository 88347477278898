import { PureComponent } from "react";
import styled from "styled-components";
import { MdClose as CloseIcon } from "@react-icons/all-files/md/MdClose";

import FrozenBackdropHoc from "./FrozenBackdropHoc";

const ModalContainer = styled.div`
  max-height: calc(100vh - 10px);
  overflow-y: auto;
  z-index: ${(props) => props.theme.zTop + props.zIndexOffset};
  background-color: white;
  padding: 15px 15px 10px 15px;
  border-radius: 3px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ModalHeader = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.purple};
`;

const ModalBody = styled.div`
  width: 100%;
`;

const CloseModal = styled(CloseIcon).attrs({
  "aria-label": "Close",
  role: "button",
})`
  position: absolute;
  height: 25px;
  width: 20px;
  right: 15px;
  top: 15px;
  color: ${(props) => props.theme.purple};
  cursor: pointer;
`;

class BaseModal extends PureComponent {
  render() {
    const {
      id,
      children,
      header,
      onClick,
      styleOverride,
      headerStyleOverride,
    } = this.props;
    const zIndexOffset = this.props.zIndexOffset ?? 0;

    return (
      <ModalContainer
        id={id}
        aria-labelledby="modal-header"
        role="dialog"
        style={styleOverride}
        zIndexOffset={zIndexOffset}
      >
        {onClick && (
          <CloseModal
            data-cy="actionCloseModal"
            onClick={(e) => {
              e.stopPropagation();
              onClick(e);
            }}
          />
        )}
        <ModalBody>
          <ModalHeader id="modal-header" style={headerStyleOverride}>
            {header}
          </ModalHeader>
          {children}
        </ModalBody>
      </ModalContainer>
    );
  }
}

/**
 * @deprecated Use a MUI component instead
 */
export const Modal = FrozenBackdropHoc(BaseModal);

/**
 * @deprecated Use a MUI component instead
 */
export default Modal;
