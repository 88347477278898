export default () => ({
    styleOverrides: {
        select: {
            // This style override lets us use an icon as a child of the MenuItem and cleans up some of the spacing around it
            "& .MuiSvgIcon-root": {
                marginRight: "3px",
                marginBottom: "1px",
            },
        },
    },
});
