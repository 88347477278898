import { FC } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

export type HiddenFieldProps = UseControllerProps & {
  required?: boolean;
};
export const HiddenField: FC<HiddenFieldProps> = (props) => {
  const { control } = useFormContext();
  return (
    <Controller
      {...props}
      control={props.control ?? control}
      render={({ field }) => (
        <input {...field} type="hidden" required={props.required} />
      )}
    />
  );
};
