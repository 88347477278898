import { identity } from "lodash";
import { grayscale } from "polished";
import * as React from "react";
import styled from "styled-components";
import { Flex } from "../Flexbox";
import { Label } from "./Label";

export type SwitchProps = React.ComponentProps<typeof Switch>;

const SwitchInset = styled.button.attrs<{
  checked: boolean;
  disabled: boolean;
}>(({ checked, color: _color, disabled, theme }) => ({
  "aria-checked": checked,
  "aria-readonly": disabled,
  role: "switch",
  type: "button",
  color: (disabled ? grayscale : identity)(_color ?? theme.colors.primary),
}))<{ checked: boolean; disabled: boolean }>`
  appearance: none;
  background-color: transparent;
  border-color: ${({ color }) => color};
  border-radius: 9999px;
  border: 1px solid;
  color: ${({ color }) => color};
  height: 24px;
  margin: 0;
  padding: 0;
  width: 40px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  &:hover {
    cursor: pointer;
  }

  :focus: {
    outline: none;
    box-shadow: 0 0 0 2px;
  }

  &[aria-checked="true"] {
    background-color: ${({ color }) => color};
  }
`;

const SwitchToggle = styled.div.attrs<{
  checked: boolean;
  disabled: boolean;
}>(({ disabled, color: _color, theme }) => ({
  "aria-hidden": true,
  color: (disabled ? grayscale : identity)(_color ?? theme.colors.primary),
}))<{
  checked: boolean;
  disabled: boolean;
}>`
  background-color: white;
  border-color: ${({ disabled, theme }) =>
    disabled ? grayscale(theme.colors.primary) : theme.colors.primary};
  border-radius: 9999px;
  border: 1px solid;
  height: 24px;
  margin-left: -1px;
  margin-top: -1px;
  width: 24px;

  transform: translateX(${({ checked }) => (checked ? "16px" : "0")});
  transition-duration: 0.1s;
  transition-property: transform;
  transition-timing-function: ease-out;
`;

/**
 * Switch is a component that is functionally identical to a checkbox but
 * instead of representing "checked" and "unchecked" states, it represents "on"
 * and "off" states. Unlike a checkbox, a Switch cannot be in an indeterminate
 * state; it's either on or off.
 *
 * This component is designed to be used as a controlled component. If you want
 * this element to be used as an uncontrolled component, you'll need to
 * implement state management.
 *
 * This component largely follows the ARIA switch example on MDN; for more
 * documentation, see the
 ** [MDN switch role documentation](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Switch_role)
 *
 * ## When to use
 *
 * Whenever you would use a checkbox but the result should be applied
 * immediately (e.g. not after a form submission).
 *
 * @example
 * <form>
 *   <Switch
 *     label=""
 *     name="ludicrous-speed"
 *     value={ludicrousSpeedEnabled}
 *     onChange={(event) => setLudicrousSpeedEnabled(!ludicrousSpeedEnabled)}
 *   />
 * </form>
 */
/**
 * @deprecated Use component from design/forms instead
 */
export const Switch = React.forwardRef<
  HTMLButtonElement,
  {
    /**
     * Whether or not the switch is in the "on" (true) or "off" (false) state.
     */
    checked?: boolean;
    /**
     * Whether or not the switch can be interacted with.
     */
    disabled?: boolean;
    /**
     * Whether or not the label is padded. Defaults to false when no label is
     * set; otherwise, defaults to true.
     */
    fitted?: boolean;
    /**
     * An id for the input element; used as the [`htmlFor`][1] value for the
     * input and label. Automatically applied to the label element when a string
     * label is passed.
     *
     * If no id is set, `name` will be used as a fallback.
     *
     * [1]: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#attr-for
     */
    id?: string;
    /**
     * A label for the element. When set to a string, the label text is rendered
     * inside a Label element. Alternatively, you can pass in a React node which
     * will be rendered instead of the Label.
     */
    label?: string | React.ReactNode;
    /**
     * The side of the switch to render the label on. Defaults to "right".
     */
    labelAlign?: "right" | "left";
    /**
     * The name of the form element.
     */
    name?: string;
    /**
     * A callback invoked when the Switch has changed value.
     */
    onChange?: (
      event: React.MouseEvent<
        HTMLButtonElement & { checked: boolean },
        MouseEvent
      >
    ) => void;
  }
>(function Switch(
  {
    checked = false,
    disabled = false,
    fitted: _fitted,
    id,
    label = "",
    labelAlign = "right",
    name = "",
    onChange = () => {},
    ...props
  },
  ref
) {
  // Use the `fitted` prop if passed. Otherwise, only set fitted=true if we're
  // rendering a non-falsy label (something other than "" | null | false).
  const fitted = _fitted != null ? _fitted : Boolean(label);
  const labelEl = React.isValidElement(label) ? (
    label
  ) : (
    <Label htmlFor={id ?? name}>{label}</Label>
  );
  return (
    // Vertically center the label and the switch
    <Flex alignItems="center">
      {labelAlign === "left" && labelEl}
      <div
        style={{
          marginLeft: labelAlign === "left" && fitted ? "10px" : undefined,
          marginRight: labelAlign === "right" && fitted ? "10px" : undefined,
        }}
      >
        <SwitchInset
          checked={checked}
          data-testid="switch-inset"
          disabled={disabled}
          id={id ?? name}
          name={name}
          onClick={onChange}
          ref={ref}
          {...props}
        >
          <SwitchToggle
            checked={checked}
            data-testid="switch-toggle"
            disabled={disabled}
          />
        </SwitchInset>
      </div>
      {labelAlign === "right" && labelEl}
    </Flex>
  );
});
