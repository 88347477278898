import _ from "lodash";

import { Stack, Typography } from "@samacare/design";
import { BenefitsVerification } from "@samacare/graphql";

const getStatusLabel = (status?: string | null) => {
  if (status == null) {
    return { label: "-", color: "primary" };
  }

  if (status.toLocaleLowerCase() === "active") {
    return { label: "Active", color: "green" };
  }
  if (status.toLocaleLowerCase() === "expired") {
    return { label: "Inactive", color: "error" };
  }

  return { label: _.capitalize(status), color: "primary" };
};

export const BenefitsVerificationCoverageStatus: React.FC<{
  benefitsVerification: BenefitsVerification;
}> = ({ benefitsVerification }) => {
  const { outcome } = benefitsVerification;
  const { label, color } = getStatusLabel(
    outcome?.data?.insuranceCoverageStatus
  );
  return (
    <Stack flexDirection="row">
      <Typography variant="body1" color={color}>
        {label}
      </Typography>
    </Stack>
  );
};
