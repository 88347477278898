export default () => ({
    styleOverrides: {
        root: {
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
            },
            overflowX: "scroll",
            zIndex: 0,
        },
    },
    defaultProps: {
        pageSizeOptions: [25, 50, 100],
        pagination: true,
    },
});
