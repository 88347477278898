import _ from "lodash";
import { PureComponent } from "react";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import styled from "styled-components";

import colors from "Resources/colors";
import { BaseText } from "Segment/StyledComponents";
import BaseButton from "../../../components/BaseButton";
import { Switch } from "@samacare/design/core";

import {
  withUpsertDrugOption,
  withDeleteDrugOption,
} from "../../../graphql/DrugOptions";
import { CreateNew, SupportEditContainer } from "../SharedStyles";

const StyledInput = styled(BaseText)`
  width: 100%;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const FieldLabel = styled.div`
  width: 30%;
  font-weight: 700;
`;

const baseState = {
  createNewDrug: false,
  updateCode: "",
  updateDrugName: "",
  updateSponsorship: false,
  updateExpensive: false,
};

export class DrugEdit extends PureComponent {
  state = baseState;

  componentDidMount() {
    const { selectedDrug } = this.props;
    this.updateSelectedDrug(selectedDrug);
  }

  componentDidUpdate(prevProps) {
    const { selectedDrug } = this.props;
    if (selectedDrug.id !== prevProps.selectedDrug.id) {
      this.updateSelectedDrug(selectedDrug);
    }
  }

  updateSelectedDrug = (selectedDrug) => {
    if (!_.isEmpty(selectedDrug)) {
      this.setState({
        createNewDrug: false,
        updateCode: selectedDrug.code,
        updateDrugName: selectedDrug.drugName,
        updateSponsorship: selectedDrug.sponsored,
        updateExpensive: selectedDrug.expensive,
      });
    }
  };

  checkForUnsavedChanges = () => {
    const { selectedDrug } = this.props;
    const {
      updateCode,
      updateDrugName,
      createNewDrug,
      updateSponsorship,
      updateExpensive,
    } = this.state;
    if (createNewDrug) {
      return updateCode || updateDrugName;
    }

    return (
      updateCode !== selectedDrug.code ||
      updateDrugName !== selectedDrug.drugName ||
      updateSponsorship !== selectedDrug.sponsored ||
      updateExpensive !== selectedDrug.expensive
    );
  };

  deleteDrug = async () => {
    const { deleteDrugOption, selectedDrug, clearSelectedDrug, alert } =
      this.props;
    try {
      await deleteDrugOption({ variables: { id: selectedDrug.id } });
      clearSelectedDrug();
      alert.info("Successfully deleted drug option");
    } catch (e) {
      alert.error(`Failed to delete ${e}`);
    }
  };

  saveChanges = async () => {
    const { upsertDrugOption, selectedDrug, resetSelectedDrug, alert } =
      this.props;
    const {
      updateCode,
      updateDrugName,
      createNewDrug,
      updateSponsorship,
      updateExpensive,
    } = this.state;
    if (!updateCode || !updateDrugName) {
      alert.error(
        "Please enter a correct JCode, Name and at least one specialty"
      );
    } else {
      try {
        const args = {
          ...(createNewDrug ? {} : { id: selectedDrug.id }),
          code: updateCode,
          drugName: updateDrugName,
          sponsored: updateSponsorship,
          expensive: updateExpensive,
        };
        await upsertDrugOption({ variables: args });
        if (!createNewDrug) {
          resetSelectedDrug();
          alert.info("Successfully updated drug option");
        } else {
          this.setState(baseState);
          alert.info("Successfully created drug option");
        }
      } catch (e) {
        alert.error(`Failed to upsert ${e}`);
      }
    }
  };

  render() {
    const {
      updateCode,
      updateDrugName,
      createNewDrug,
      updateSponsorship,
      updateExpensive,
    } = this.state;
    const { selectedDrug, clearSelectedDrug } = this.props;

    const hasDrug = !_.isEmpty(selectedDrug) || createNewDrug;

    return !hasDrug ? (
      <CreateNew
        data-cy="actionShowCreateNewDrug"
        onClick={() => {
          this.setState({ ...baseState, createNewDrug: true });
        }}
      >
        Click to Create a New Drug
      </CreateNew>
    ) : (
      <SupportEditContainer>
        {createNewDrug && (
          <RowWrapper>
            <div style={{ marginLeft: "10px", fontStyle: "italic" }}>
              Edit New Drug Details and Click Save To Create New Record
            </div>
          </RowWrapper>
        )}
        <RowWrapper>
          <FieldLabel>Code</FieldLabel>
          <StyledInput
            data-cy="fieldDrugJCode"
            onChange={(e) => {
              this.setState({ updateCode: e.target.value });
            }}
            value={updateCode}
            placeholder="Update JCode"
          />
        </RowWrapper>
        <RowWrapper>
          <FieldLabel>Name</FieldLabel>
          <StyledInput
            data-cy="fieldDrugName"
            onChange={(e) => {
              this.setState({ updateDrugName: e.target.value });
            }}
            value={updateDrugName}
            placeholder="Update Drug Name"
          />
        </RowWrapper>
        <RowWrapper>
          <FieldLabel>Sponsored</FieldLabel>
          <div style={{ width: "100%" }}>
            <Switch
              data-cy="fieldDrugSponsorship"
              onChange={() => {
                this.setState((prevState) => ({
                  updateSponsorship: !prevState.updateSponsorship,
                }));
              }}
              checked={!!updateSponsorship}
            />
          </div>
        </RowWrapper>
        <RowWrapper>
          <FieldLabel>Expensive</FieldLabel>
          <div style={{ width: "100%" }}>
            <Switch
              data-cy="fieldDrugExpensive"
              onChange={() => {
                this.setState((prevState) => ({
                  updateExpensive: !prevState.updateExpensive,
                }));
              }}
              checked={!!updateExpensive}
            />
          </div>
        </RowWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BaseButton
            data-cy="actionDeleteDrug"
            color={colors.red}
            style={{ width: "25%", marginLeft: "10px" }}
            onClick={this.deleteDrug}
            disabled={createNewDrug}
          >
            Delete Drug
          </BaseButton>
          <div
            style={{
              width: "35%",
              marginLeft: "10px",
              color: colors.red,
              textAlign: "center",
            }}
          >
            {this.checkForUnsavedChanges() ? "Unsaved Changes" : ""}
          </div>
          <BaseButton
            style={{ width: "20%", marginLeft: "10px" }}
            onClick={() => {
              this.setState({ createNewDrug: false }, clearSelectedDrug);
            }}
          >
            Cancel
          </BaseButton>
          <BaseButton
            style={{ width: "20%", marginLeft: "10px" }}
            onClick={this.saveChanges}
            data-cy="actionSaveDrug"
          >
            Save
          </BaseButton>
        </div>
      </SupportEditContainer>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withUpsertDrugOption,
  withDeleteDrugOption
)(withAlert()(DrugEdit));
