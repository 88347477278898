import { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import strings from "Resources/strings";
import dimensions from "Resources/dimensions";
import { withApollo } from "@apollo/client/react/hoc";
import { withAlert } from "react-alert";
import Button from "@samacare/design/core/Button";

import { login, isAuthenticated } from "../../reducers/session";
import ForgotPasswordButton from "../../components/ForgotPasswordButton";
import { ModalFooter } from "../../components/ModalStyledComponents";
import { ACCOUNT_ID_FILTER_KEY } from "../PCAuthorizations/index";

const LoginBody = styled.div`
  margin: 20px 0;
`;

const LoginInput = styled.input`
  border: ${(props) => `1px solid ${props.theme.lightGray}`};
  padding: 10px ${(props) => props.paddingleft};
  border-radius: 3px;
  box-shadow: none;
  width: 100%;

  &:focus {
    border-color: ${(props) => props.theme.purple};
    outline: 0;
  }
`;

const CustomModalFooter = styled(ModalFooter)`
  flex-direction: column;
  font-size: ${dimensions.font_size};
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: white;
  flex-direction: column;
  width: 400px;
  margin: auto;
`;

const LoginHeader = styled.div`
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid ${(props) => props.theme.lightGray};
  padding: 10px;
`;

const LogoContainer = styled.div`
  padding: 20px;
  text-align: center;
  margin: 50px 20px 20px 20px;
`;

const Logo = styled.img`
  height: 110px;
  width: 110px;
  margin: auto;
`;

const FieldLabel = styled.div`
  font-weight: 700;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #ff8d83;
  padding: 10px;
`;

const TOSCallout = styled.div`
  padding: 20px 10px;
  border-radius: 3px;
  background: #dfdffd;
  margin-top: 20px;
  text-align: center;
`;

const Footer = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.lightGray};
  margin-top: auto;
  text-align: center;
  margin-bottom: 10px;
`;

export class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", error: "" };
  }

  UNSAFE_componentWillMount() {
    isAuthenticated((err) => {
      if (err) {
        const { response } = err;
        const standardResponse = response.status === 401;
        if (!standardResponse) {
          this.setState({ error: strings.LOGIN.GENERAL_ERROR });
        }
      } else {
        this.redirectToHome();
      }
    });
  }

  redirectToHome = async () => {
    const { client, href } = this.props;

    await client.resetStore();

    window.location.href = href || "/";
  };

  onKeydown = (e) => {
    const code = e.keyCode || e.which;
    const ENTER_KEYCODE = 13;

    if (code === ENTER_KEYCODE) {
      this.login();
    }
  };

  login = () => {
    const { email, password } = this.state;
    const { ERROR_TYPES } = CONFIG.CONSTANTS;

    login(email, password, async (err) => {
      if (err) {
        const { response } = err;
        if (response.status === 401 && response.data) {
          switch (response.data) {
            case ERROR_TYPES.EXPIRED_ACCOUNT:
              this.setState({ error: strings.LOGIN.EXPIRED_ACCOUNT });
              break;
            case ERROR_TYPES.EXPIRED_PASSWORD:
              this.setState({ error: strings.LOGIN.EXPIRED_PASSWORD });
              break;
            case ERROR_TYPES.MAX_LOGINS_ATTEMPTED:
              this.setState({ error: strings.LOGIN.MAX_LOGINS_ATTEMPTED });
              break;
            default:
              this.setState({ error: strings.LOGIN.INVALID_LOGIN });
              break;
          }
        } else {
          this.setState({ error: strings.LOGIN.GENERAL_ERROR });
        }
      } else {
        this.redirectToHome();
      }
    });
  };

  render() {
    const { email, password, error } = this.state;
    window.localStorage.removeItem(ACCOUNT_ID_FILTER_KEY);

    return (
      <Container>
        <LogoContainer>
          <Logo src="../../assets/logo.svg" />
        </LogoContainer>
        <LoginHeader>{strings.LOGIN.HEADER}</LoginHeader>
        <LoginBody>
          <FieldLabel>Email</FieldLabel>
          <LoginInput
            data-cy="fieldEmail"
            name="email"
            onChange={(e) => {
              this.setState({ email: e.target.value });
            }}
            onKeyDown={this.onKeydown}
            placeholder={strings.LOGIN.EMAIL}
            type="email"
          />
          <FieldLabel>Password</FieldLabel>{" "}
          <LoginInput
            data-cy="fieldPassword"
            name="password"
            onChange={(e) => {
              this.setState({ password: e.target.value });
            }}
            onKeyDown={this.onKeydown}
            placeholder={strings.LOGIN.PASSWORD}
            type="password"
          />
        </LoginBody>
        <CustomModalFooter>
          <Button
            variant="contained"
            data-cy="actionLogin"
            sx={{ marginBottom: "10px" }}
            onClick={this.login}
            disabled={!email || !password}
          >
            {strings.LOGIN.LOGIN}
          </Button>
          <ForgotPasswordButton email={email} />
        </CustomModalFooter>
        <ErrorMessage>{error}</ErrorMessage>
        {email.endsWith("@novartis.com") && (
          <ErrorMessage>
            Please use{" "}
            <a href="https://provider.samacare.com/login/novartis">
              https://provider.samacare.com/login/novartis
            </a>{" "}
            to login through SSO. Please contact your practice administrator to
            ensure access
          </ErrorMessage>
        )}
        <TOSCallout>{strings.LOGIN.TERMS_OF_SERVICE}</TOSCallout>
        <Footer>
          {"SamaCare Security and Privacy Policies can "}
          <a href="https://docs.google.com/document/d/1ge2QyKbYGgBpy4lcEZlm-FN4LehNtPTYHmyTfjschQI/edit#heading=h.gjdgxs">
            be found here.
          </a>
        </Footer>
      </Container>
    );
  }
}
export const LoginWithAlert = withAlert()(Login);

export default connect((store) => ({ href: store.loginRedirect.redirectUrl }))(
  withApollo(LoginWithAlert)
);
