import * as React from "react";
import { LeftRight } from "@@components/LeftRight";
import { Radio } from "@@ui-kit/forms";

export const ConsentRadioButton: React.FC<{
  onChange: () => void;
  style?: React.CSSProperties;
}> = (props) => (
  <LeftRight>
    <Radio
      name="consent"
      style={{
        height: "16px",
        minWidth: "16px",
        marginTop: "4px",
        marginBottom: "16px",
        ...props.style,
      }}
      onChange={props.onChange}
    />
    <div style={{ marginLeft: "8px", lineHeight: "1.5em" }}>
      {props.children}
    </div>
  </LeftRight>
);
