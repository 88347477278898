import { PureComponent } from "react";
import { compose } from "recompose";
import { withAlert } from "react-alert";

import strings from "Resources/strings";
import { withUpdateAuthorizationAndRefetch } from "../graphql/Authorization";

export class ToggleHideButton extends PureComponent {
  click = async (e) => {
    const { updateAuthorizationAndRefetch, alert, id, hideFromExpiring } =
      this.props;
    e.stopPropagation();
    try {
      await updateAuthorizationAndRefetch({
        variables: { id, patch: { hideFromExpiring: !hideFromExpiring } },
      });
    } catch (err) {
      alert.error(strings.AUTHORIZATIONS.ERROR_HIDING);
    }
  };

  render() {
    const { children } = this.props;
    return <div onClick={this.click}>{children}</div>;
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withUpdateAuthorizationAndRefetch,
  withAlert()
)(ToggleHideButton);
