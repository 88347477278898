import { EnrollmentProgramChannel } from "@samacare/graphql";
import useEnrollmentProgramInUrl from "../../../form/hooks/useEnrollmentProgramInUrl";
import { EnrollmentApellisForm } from "./EnrollmentApellisForm";
import { useStepContext } from "./StepProvider";

import { useEnrollmentContext } from "./EnrollmentProvider";
import GenentechOcrevusForm from "../../enrollments/GenentechOcrevusForm";
import { HorizonGeneralForm } from "../../enrollments/HorizonForm";

export const EnrollmentForm: React.FC = () => {
  const { enrollment } = useEnrollmentContext();
  const enrollmentProgramInUrl = useEnrollmentProgramInUrl();
  const channel =
    enrollment?.EnrollmentProgram?.channel || enrollmentProgramInUrl?.channel;

  const { onNextStep } = useStepContext();

  switch (channel) {
    case EnrollmentProgramChannel.Genentech:
      return <GenentechOcrevusForm onNextStep={onNextStep} />;
    case EnrollmentProgramChannel.Assistrx:
      return <EnrollmentApellisForm onNextStep={onNextStep} />;
    case EnrollmentProgramChannel.Horizon:
      return <HorizonGeneralForm onNextStep={onNextStep} />;
    default:
      return <>Unknown channel</>;
  }
};
