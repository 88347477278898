import styled from "styled-components";

interface SegmentContainerProps {
  isinline?: boolean;
  width?: string;
  isConditional?: boolean;
}
export const SegmentContainer = styled.div<SegmentContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.isinline ? "row" : "column")};
  align-items: ${(props) => (props.isinline ? "center" : "flex-start")};
  margin: 0 10px 0 0;
  width: ${(props) => props.width};
  padding-left: ${(props) => (props.isConditional ? "15px" : 0)};
  border-radius: 3px;
`;

interface SegmentTitleContainerProps {
  paddingBottom?: string;
  isCheckbox?: boolean;
}
export const SegmentTitleContainer = styled.div<SegmentTitleContainerProps>`
  font-weight: 700;
  display: flex;
  align-items: center;
  margin: ${(props) => `9px 4px ${props.paddingBottom ?? "7px"} 4px`};
  cursor: ${(props) => (props.isCheckbox ? "pointer" : "default")};
  flex: ${(props) => (props.isCheckbox ? 2 : "")};
  ${SegmentContainer}.isinline & {
    margin: 10px 0 10px 10px;
  }
`;
