import gql from "graphql-tag";

export default gql`
  fragment patientIntegrationCredentialInfo on PatientDemographicIntegrationCredential {
    id
    title
    username
    password
    loginURL
    officeKey
    isDisabled
    institution {
      name
      id
    }
    latestErrorLogs {
      id
      success
      error
      createdAt
    }
  }
`;
