type MsgType =
  | "Ping"
  | "SetActiveAuth"
  | "LaunchPortal"
  | "SetSupportDetails"
  | "SupportLaunchPortal";

export class WebExtensionPubSub {
  private listeners: ((event: MessageEvent) => void)[];

  public constructor() {
    this.listeners = [];
  }

  public send(type: MsgType, data?: string | Record<string, any>): void {
    window.postMessage(
      { type, data, origin: "SCWebsite" },
      window.location.origin
    );
  }

  public addListener(
    fn: (event: MessageEvent<{ type: string }>) => void
  ): void {
    window.addEventListener("message", fn);
    this.listeners.push(fn);
  }

  public removeListeners(): void {
    this.listeners.map((fn) => window.removeEventListener("message", fn));
    this.listeners = [];
  }
}

/* 
  Usage example (in the component):
  1. Create instance of the class
  2. Add listeners
  3. Use send method to send message to the extension
  4. Don't forget to remove all listeners on component unmount
*/
