import { DialogActions } from "@samacare/design";

interface DialogFooterProps {}
export const DialogFooter: React.FC<DialogFooterProps> = (props) => (
    <DialogActions
      sx={{
        backgroundColor: "#e8e7fd",
        py: 1.25,
        px: 3,
        justifyContent: "flex-end",
      }}
    >
      {props.children}
    </DialogActions>
  );
