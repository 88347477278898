import { createContext, useEffect, useState } from "react";
import { WebExtensionPubSub } from "../services/WebExtensionPubSub";

export const WebExtensionContext = createContext({
  isWebExtensionConnected: false,
});

export function WebExtensionStateProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [state, setState] = useState({ isWebExtensionConnected: false });

  useEffect(() => {
    const pubSub = new WebExtensionPubSub();
    pubSub.addListener((msg) => {
      switch (msg.data?.type) {
        case "Pong": {
          setState((currentState) => ({
            ...currentState,
            isWebExtensionConnected: true,
          }));
          break;
        }
        default:
          break;
      }
    });
    pubSub.send("Ping");
    return () => pubSub.removeListeners();
  }, []);

  return (
    <WebExtensionContext.Provider value={state}>
      {children}
    </WebExtensionContext.Provider>
  );
}
