import { graphql } from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import _ from "lodash";

import { withDefaultProps } from "./utils";

export const ICD10SearchQuery = gql`
  query icd10Search($searchTerm: String!, $query: [String]) {
    icd10(searchTerm: $searchTerm, query: $query)
  }
`;

export const withICD10Search = graphql(ICD10SearchQuery, {
  options: () => ({ variables: { searchTerm: "" } }),
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    icd10: _.get(data, "icd10"),
    icdError: _.get(data, "error"),
  })),
});
