import _ from "lodash";
import { SyntheticEvent } from "react";
import { Stack } from "@samacare/design/core";
import AddressBlock from "../AddressBlock";
import {
  useFormContext,
  AutocompleteField,
  AutocompleteFieldProps,
  TextField,
  TextFieldProps,
  StateFieldProps,
} from "@samacare/form";
import { useQuery } from "@apollo/client";
import { getLocations } from "../../app/graphql/Location";
import {
  GetLocationsQueryVariables,
  GetLocationsQuery,
  LocationInfoFragment,
} from "@@generated/graphql";

import { HelpTag } from "@samacare/layout/HelpTag";

type LocationAutocompleteProps = Omit<
  AutocompleteFieldProps<LocationInfoFragment, false, false, false>,
  "name" | "options"
>;

export type OfficeBlockProps = {
  required?: boolean;
  disabled?: boolean;
  showPtan?: boolean;
  allowNew?: boolean;
  IdInputProps?: TextFieldProps;
  NPIInputProps?: TextFieldProps;
  TINInputProps?: TextFieldProps;
  NameInputProps?: TextFieldProps;
  ZipInputProps?: TextFieldProps;
  CityInputProps?: TextFieldProps;
  StateInputProps?: StateFieldProps;
  AddressInputProps?: TextFieldProps;
  locationAutocompleteProps?: LocationAutocompleteProps;
  PTANInputProps?: TextFieldProps;
  chooseDisabled?: boolean;
  onChange?: (
    e: SyntheticEvent,
    value: LocationInfoFragment | null,
    changeReason: string
  ) => void;
  name?: string;
};

const defaultProps: OfficeBlockProps = {
  required: false,
  disabled: false,
  showPtan: false,
  allowNew: true, // Note: this is a temporary prop to prevent users from entering a new office for BVs (the backend doesnt support it yet)
  IdInputProps: {
    name: "LocationId",
  },
  NameInputProps: {
    name: "location.facilityName",
    label: "Facility Name",
  },
  TINInputProps: {
    name: "location.taxId",
    label: "Office Tax ID",
  },
  NPIInputProps: {
    name: "location.NPI",
    label: "Office NPI",
  },
  AddressInputProps: {
    name: "location.address",
    label: "Address Line 1",
  },
  CityInputProps: {
    name: "location.city",
    label: "City",
  },
  StateInputProps: {
    name: "location.state",
    label: "State",
  },
  ZipInputProps: {
    name: "location.zip",
    label: "Zip",
  },
  PTANInputProps: {
    name: "location.ptan",
    label: "PTAN #",
  },
  locationAutocompleteProps: {},
  chooseDisabled: false,
};

const OfficeBlock: React.FC<OfficeBlockProps> = (rawProps) => {
  const {
    required,
    disabled,
    IdInputProps,
    NameInputProps,
    TINInputProps,
    NPIInputProps,
    AddressInputProps,
    CityInputProps,
    StateInputProps,
    ZipInputProps,
    PTANInputProps,
    locationAutocompleteProps,
    chooseDisabled,
    allowNew,
    showPtan,
    name,
    onChange,
  } = _.merge(
    _.cloneDeep(defaultProps),
    rawProps
  ) as Required<OfficeBlockProps>;
  const { reset, getValues, watch } = useFormContext();

  const { data } = useQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    getLocations
  );

  const cannotChoose = disabled || chooseDisabled;
  const fieldsDisabled = !allowNew || disabled;

  return (
    <Stack gap={2} maxWidth={600}>
      <Stack direction="row" alignItems="center">
        <AutocompleteField<LocationInfoFragment, false, false, false>
          name={name ?? "OfficeBlock"}
          placeholder={cannotChoose ? "" : "Choose..."}
          options={data?.currentAccount?.institution?.locations ?? []}
          filterSelectedOptions
          getOptionLabel={(option) =>
            option?.facilityName != null
              ? `${option?.facilityName}${
                  option?.nickname ? ` (${option.nickname})` : ""
                }`
              : "Unknown"
          }
          onChange={(x, change, reason) => {
            if (onChange) {
              onChange(x, change, reason);
            } else {
              return reason === "selectOption"
                ? reset({
                    ...getValues(),
                    location: {
                      ...getValues().location,
                      ...change,
                    },
                    LocationId: change?.id ?? undefined,
                  })
                : reset({
                    ...getValues(),
                    location: null,
                    LocationId: null,
                  });
            }
          }}
          disabled={cannotChoose}
          value={watch(name ?? "OfficeBlock")}
          {...locationAutocompleteProps}
        />
        {!allowNew && (
          <HelpTag text="To add an office, visit practice settings" />
        )}
      </Stack>
      <TextField
        sx={{ display: "none" }}
        {...IdInputProps}
        type="hidden"
        value={watch(IdInputProps.name)}
      />
      <TextField
        {...NameInputProps}
        required={required}
        disabled={fieldsDisabled}
        fullWidth
      />
      <AddressBlock
        AddressInputProps={AddressInputProps}
        CityInputProps={CityInputProps}
        StateInputProps={StateInputProps}
        ZipInputProps={ZipInputProps}
        disabled={fieldsDisabled}
        required={required}
      >
        <Stack gap={1} direction="row" maxWidth={600}>
          <TextField
            {...TINInputProps}
            required={required}
            disabled={fieldsDisabled}
            fullWidth
          />
          <TextField
            {...NPIInputProps}
            required={required}
            disabled={fieldsDisabled}
            fullWidth
            rules={{
              validate: (value) => {
                if (value !== "") {
                  return value.length === 10;
                }
              },
            }}
          />
        </Stack>
      </AddressBlock>
      {showPtan && (
        <TextField
          required={required}
          disabled={fieldsDisabled}
          fullWidth
          {...PTANInputProps}
        />
      )}
    </Stack>
  );
};

export default OfficeBlock;
