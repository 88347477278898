import _ from "lodash";
import { gql, useMutation, useQuery } from "@apollo/client";
import { createFilterOptions } from "@samacare/design/core/base";
import { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  DialogContent,
} from "@samacare/design/core";
import Chip from "@samacare/design/core/Chip";
import Dialog from "@samacare/design/core/Dialog";
import DialogTitle from "@samacare/design/core/DialogTitle";
import {
  Mutation,
  MutationUpdateAuthorizationTagsArgs,
  Authorization,
} from "@samacare/graphql";
import { GetAllTagsQuery } from "@@generated/graphql";
import {
  ALL_AUTHORIZATIONS_QUERY_NAME,
  AUTHORIZATION_PAGINATION_QUERY_NAME,
} from "../../../graphql/Authorization";
import authorizationInfo from "../../../../app/graphql/fragments/authorizationInfo";

const GET_ALL_TAGS_QUERY_NAME = "GetAllTags";
export const getAllTagsQuery = gql`
  query GetAllTags {
    getAllTags
  }
`;

const updateAuthorizationTagsMutation = gql`
  mutation updateAuthorizationTags($authorizationId: Int!, $tags: [String!]!) {
    updateAuthorizationTags(authorizationId: $authorizationId, tags: $tags) {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;

const filter = createFilterOptions<string>();

const ADD_PREFIX = 'Add "';
const ALREADY_ADDED_POSTFIX = '" is already added';

interface TagsModalProps {
  auth: Authorization;
  onClose: () => void;
}
export const TagsModal: React.VFC<TagsModalProps> = (props) => {
  const [initialTagCount, setInitialTagCount] = useState(-1);
  const [isDirty, setIsDirty] = useState(false);
  const [tagValues, _innerSetTagValues] = useState<string[]>([]);
  const [tagsToSuggest, setTagsToSuggest] = useState<string[]>([]);
  const [currentValue, setCurrentValue] = useState("");
  const setTagValues = useCallback(
    (newTagValues: string[]) => {
      _innerSetTagValues(newTagValues.sort());
    },
    [_innerSetTagValues]
  );

  const { data: institutionTags } = useQuery<GetAllTagsQuery>(getAllTagsQuery);

  const [updateAuthorizationTags] = useMutation<
    Mutation,
    MutationUpdateAuthorizationTagsArgs
  >(updateAuthorizationTagsMutation, {
    refetchQueries: [
      ALL_AUTHORIZATIONS_QUERY_NAME,
      AUTHORIZATION_PAGINATION_QUERY_NAME,
      GET_ALL_TAGS_QUERY_NAME,
    ],
  });

  // This should only run at mount
  useEffect(() => {
    const tvs = props.auth.tags.map((tag) => tag.value);
    setTagValues(tvs);
    setInitialTagCount(tvs.length);
  }, [setTagValues, props.auth.tags]);

  // Set the form as dirty if the tag count changes
  useEffect(() => {
    if (
      !isDirty &&
      initialTagCount !== -1 &&
      initialTagCount !== tagValues.length
    ) {
      setIsDirty(true);
    }
  }, [tagValues, initialTagCount, isDirty, setIsDirty]);

  // Each time we add a value, we want to clear the current value
  useEffect(() => {
    setCurrentValue("");
  }, [tagValues]);

  // So we don't have to deal with duplicates, we'll let the user add the same tag as a no-op
  useEffect(() => {
    setTagsToSuggest(
      _.difference(institutionTags?.getAllTags ?? [], tagValues).sort()
    );
  }, [setTagsToSuggest, institutionTags, tagValues]);

  const handleDelete = (tagValue: string) => {
    const newTagValues = [...tagValues];
    newTagValues.splice(newTagValues.indexOf(tagValue), 1);
    setTagValues(newTagValues);
  };

  const handleTagChange = (value: string) => {
    let newValue = value;
    if (value.startsWith(ADD_PREFIX)) {
      newValue = value.substring(5, value.length - 1);
    }
    if (
      !value.endsWith(ALREADY_ADDED_POSTFIX) &&
      !tagValues.includes(newValue)
    ) {
      setTagValues([...tagValues, newValue]);
    } else {
      setCurrentValue("");
    }
  };

  const handleSave = async () => {
    await updateAuthorizationTags({
      variables: { authorizationId: parseInt(props.auth.id), tags: tagValues },
    });
    props.onClose();
  };

  return (
    <Dialog open onClose={props.onClose} fullWidth>
      <DialogTitle>Manage Tags</DialogTitle>
      <DialogContent>
        <Autocomplete
          disableClearable
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          autoHighlight
          onChange={(event, value) => {
            handleTagChange(value);
          }}
          value={currentValue}
          options={tagsToSuggest}
          onClose={(event: React.SyntheticEvent, reason: string) => {
            // This is a weird workaround. The autocomplete doesn't let an exact match be selected
            // when typed in. Partial matches work and will fall-through here
            if (
              reason === "selectOption" &&
              tagsToSuggest.includes(currentValue)
            ) {
              handleTagChange(currentValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add Tag"
              onKeyDown={(event) => {
                // Needed for after the user clears the suggestions by hitting ESC
                if (event.key === "Enter") {
                  handleTagChange(currentValue);
                }
              }}
            />
          )}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== "" && !isExisting) {
              if (tagValues.includes(inputValue)) {
                filtered.push(`"${inputValue}${ALREADY_ADDED_POSTFIX}`);
              } else {
                filtered.push(`${ADD_PREFIX}${inputValue}"`);
              }
            }
            return filtered;
          }}
          onInputChange={(event, newInputValue) => {
            setCurrentValue(newInputValue);
          }}
          sx={{
            width: 320,
            marginTop: 1,
            marginBottom: 2,
          }}
        />
        <Stack
          direction="row"
          padding={1}
          gap={1}
          flexWrap="wrap"
          marginBottom={2}
          borderRadius={1}
          sx={{ border: (theme) => `1px solid ${theme.palette.grey[400]}` }}
        >
          {tagValues.length === 0 && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: "32px", paddingLeft: "4px" }}
            >
              None
            </Stack>
          )}
          {tagValues.map((value) => (
            <Chip
              key={value}
              label={value}
              onDelete={() => handleDelete(value)}
            />
          ))}
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Button variant="text" onClick={props.onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} disabled={!isDirty}>
            Save
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
