import { useEffect } from "react";

/**
 * Our forms are primarily composed of nested structures, similar to the example below:
 *
 * {
 *   test: string;
 *   patient: {
 *     firstName: string;
 *     lastName: string;
 *   }
 * }
 *
 * When an input field is focused, we aim to focus on the precise input field name.
 *
 * If the `firstErrorKey` corresponds to a valid error object, determined by checking
 * `errors[firstErrorKey] as { message?: string }).message`, we will select the relevant
 * input field (e.g., `test` in the given example).
 *
 * If `firstErrorKey` is associated with a nested object (such as `patient` in this case),
 * we need to traverse one level deeper to retrieve the specific error object.
 *
 * Example of an error object:
 *
 * {
 *   patientStatus: {
 *     message: "This field is required",
 *     ref: {
 *       name: "patientStatus"
 *     },
 *     type: "nullable"
 *   }
 * }
 */

const focusFirstError = (errors: any, path: string[] = []) => {
  const firstErrorKey = Object.keys(errors).find((key) => errors[key]);

  if (firstErrorKey) {
    path.push(firstErrorKey);

    if (errors[firstErrorKey] && errors[firstErrorKey].message) {
      const inputName = path.join(".");
      (
        document.querySelector(`input[name="${inputName}"]`) as HTMLInputElement
      )?.focus();
    } else if (typeof errors[firstErrorKey] === "object") {
      focusFirstError(errors[firstErrorKey], path);
    } else {
      path.pop(); // Backtrack if it's not a valid object
    }
  }
};

export const useFocusFirstError = (errors: any) => {
  useEffect(() => {
    focusFirstError(errors);
  }, [errors]);
};
