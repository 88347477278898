import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";

import faxFilterInfo from "./fragments/faxFilterInfo";
import { withDefaultProps } from "./utils";

export const withFaxFilters = graphql(
  gql`
    query faxFiltersFindAll {
      faxFiltersFindAll {
        ...faxFilterInfo
      }
    }
    ${faxFilterInfo}
  `,
  {
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      faxFilters: _.get(data, "faxFiltersFindAll"),
    })),
  }
);

export const withUpsertFaxFilter = graphql(
  gql`
    mutation upsertFaxFilter($formNumberId: Int!, $states: [String]) {
      upsertFaxFilter(formNumberId: $formNumberId, states: $states) {
        ...faxFilterInfo
      }
    }
    ${faxFilterInfo}
  `,
  {
    name: "upsertFaxFilter",
  }
);
