import React from "react";
import styled from "styled-components";

import { Typography } from "@samacare/design/core";

export const SectionHeader = styled.div`
  color: ${(props) => props.theme.purple};
  font-weight: 400;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

interface FormSectionProps {
  title: string;
  children: React.ReactNode;
}

export const FormSection: React.FC<FormSectionProps> = ({
  title,
  children,
}) => {
  return (
    <div>
      <SectionHeader>
        <Typography variant="subtitle1">{title}</Typography>
      </SectionHeader>
      {children}
    </div>
  );
};
