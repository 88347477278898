import { NetworkStatus, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";
import { useCallback, useState } from "react";
import * as React from "react";
import { useDebounce } from "react-use";
import styled from "styled-components";

import {
  Account,
  Authorization,
  AuthorizationConnectionSortByAttribute,
} from "@samacare/graphql";
import {
  AllAuthorizationsQuery,
  AllAuthorizationsQueryVariables,
} from "@@generated/graphql";
import { Box, Flex, PrimaryButton } from "@@ui-kit";

import { useCallAfterIdle } from "../../hooks/timing";
import { parseAsNonNil } from "../../util/parsers";
import { PatientTile } from "./PatientTile";
import { Patient as TilePatient } from "./PatientTile/interfaces";
import { sortPatientList } from "./sortPatientList";
import portalInformation from "../../graphql/fragments/portalInformation";

const FIVE_MINUTES = 5 * 60 * 1000;
const FIVE_SECONDS = 5 * 1000;

const PageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 900px;
`;

const LoadingContainer = styled.div.attrs({ "aria-label": "Loading" })`
  color: ${({ theme }) => theme.darkGray};
  font-weight: bold;
  margin: 50px 20px 0 20px;
  text-align: center;
  opacity: 0;
`;

const ErrorContainer = styled.div.attrs({ "aria-label": "Error" })`
  color: ${({ theme }) => theme.darkGray};
  font-weight: bold;
  margin: 50px 20px 0 20px;
  text-align: center;
`;

const EmptyState = styled.div`
  color: ${(props) => props.theme.darkGray};
  font-weight: bold;
  margin: 50px 20px 0 20px;
  text-align: center;
`;

const getSortField = (
  sortBy: AuthorizationConnectionSortByAttribute
): "sortByTimestamp" | "dateOfService" => {
  switch (sortBy) {
    case AuthorizationConnectionSortByAttribute.LastUpdated:
      return "sortByTimestamp";
    case AuthorizationConnectionSortByAttribute.ServiceDate:
      return "dateOfService";
  }
};

export const allAuthorizationsQuery = gql`
  query allAuthorizations(
    $filters: AuthorizationsFilters!
    $limit: Int
    $sortBy: AuthorizationConnectionSortByAttribute!
  ) {
    authorizations: authorizationsPaginated(
      filters: $filters
      limit: $limit
      sortBy: $sortBy
    ) {
      authorizations {
        id
        createdAt
        createdById
        correspondences {
          id
          fileURL
        }
        steps {
          key
          title
          number
          active
          section
        }
        tags {
          value
        }
        config
        dateOfCurrentStatus
        dateOfService
        displayReviewWarning
        followUp {
          id
          type
        }
        formDetails
        formType
        formId
        hideFromExpiring
        InstitutionId
        insuranceCompany {
          id
          name
          responseRangeMin
          responseRangeMax
          isArchived
        }
        isArchived
        isReferral
        isResubmittable
        isWebExtension
        isSamaAssist
        enhancedServices
        lastCheckedAt
        latestCorrespondence {
          id
          authorizedProcedures
          code
          endDate
          fileURL
          startDate
          fileAWSKey
        }
        latestNote {
          id
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          isHidden
          note
          updatedAt
        }
        patient {
          institutionPatientId
          id
          authorizationsCount(filters: $filters)
          dob
          firstName
          lastName
          state
          city
          address
          zip
          phone
          gender
        }
        portal {
          ...portalInformation
        }
        PortalId
        portalKey
        portalTitle
        portalAuthorizationId
        requiresAssociationReview
        sortByTimestamp
        status
        submittedAt
        submissionPhoneNumber
        type
        resendToHCP
        trackingStatus
        statusCheckLoginRequiresAttn
        drugCodeType
        SiteId
        site {
          id
          host
        }
      }
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
  ${portalInformation}
`;

export type PatientsAuthorizationListProps = React.ComponentProps<
  typeof PatientsAuthorizationList
>;

export interface AuthPatient {
  id: string | null;
  institutionPatientId: string | null | undefined;
  authorizationsCount: number | null | undefined;
  authorizations: Authorization[] | null;
  dob: string;
  firstName: string;
  lastName: string;
  city: string | null | undefined;
  state: string | null | undefined;
  address: string | null | undefined;
  zip: string | null | undefined;
  gender: string | null | undefined;
  phone: string | null | undefined;
}

export const PatientsAuthorizationList: React.VoidFunctionComponent<{
  setLimit: (newLimit: number) => void;
  perPage?: number;
  variables: AllAuthorizationsQueryVariables;
  filterByExpired: boolean;
  accounts: Account[];
  setIsAllLoadingDone?: (isAllLoadingDone: boolean) => void;
}> = ({
  variables,
  setLimit,
  perPage = 30,
  filterByExpired,
  accounts,
  setIsAllLoadingDone,
}) => {
  const [isLoadingMessageDisabled, setIsLoadingMessageDisabled] =
    useState(false);
  const [debouncedVariables, setDebouncedVariables] = useState(variables);
  useDebounce(() => setDebouncedVariables(variables), 300, [variables]);

  const { data, error, fetchMore, loading, networkStatus, refetch } = useQuery<
    AllAuthorizationsQuery,
    AllAuthorizationsQueryVariables
  >(allAuthorizationsQuery, {
    // Use a cache-and-network fetch policy to pull data from the cache if it
    // exists but always perform a network request to refresh the
    // authorizations.
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    variables: {
      ...debouncedVariables,
      limit: debouncedVariables.limit ?? perPage,
    },
  });

  const idleRefetch = useCallback(async () => {
    setIsLoadingMessageDisabled(true);
    await refetch();
    setIsLoadingMessageDisabled(false);
  }, [refetch]);

  useCallAfterIdle(idleRefetch, FIVE_MINUTES, FIVE_SECONDS);

  // Don't display the loading indicator when we're loading the next page of
  // results; the loading spinner on the load-more button indicates to the user
  // that we're fetching more auths.
  if (
    loading &&
    networkStatus !== NetworkStatus.fetchMore &&
    !isLoadingMessageDisabled
  ) {
    return (
      <PageContainer style={{ paddingTop: "100px" }}>
        <LoadingContainer>Loading...</LoadingContainer>
      </PageContainer>
    );
  }

  if (setIsAllLoadingDone) {
    setIsAllLoadingDone(true);
  }

  if (error) {
    return (
      <PageContainer>
        <ErrorContainer>Failed to load patients.</ErrorContainer>
      </PageContainer>
    );
  }

  if (data == null) {
    return null;
  }

  // Generate a list of Patients with sublists of authorizations, grouped by patient ID; both
  // the list of sublists and each of the sublists should respect the sort order
  // returned by the GraphQL query.
  //
  // To calculate that sort ordering cheaply, we would execute a query something
  // like this:
  //
  // SELECT DISTINCT "PatientId", ${sortBy} FROM "Authorizations" ORDER BY ${sortBy} DESC LIMIT ${pageLimit};

  const patientDict: { [key: string]: boolean } = {};

  const patientMap = data.authorizations.authorizations.reduce<
    Map<string | null, Partial<AuthPatient>>
  >((acc, authorization) => {
    // Sort order as returned by the query is important, so while we're
    // grouping authorizations by patient, maintain the overall order of the
    // authorizations on two dimensions:
    //
    // - *Patients should be sorted by the authorization sort key.*
    // - *Authorizations within a patient should be sorted by the
    //   authorization sort key.
    //
    // `Map`s maintain insertion order, so we can maintain the original
    // query sort ordering on both dimensions by a) inserting patient IDs into
    // the map in the same order as the query results, and b) always
    // appending authorizations to the per-patient list.
    // if (acc[patientId as string]) {
    const patientId = authorization.patient?.id ?? null;
    if (patientId !== null) {
      patientDict[patientId] = true;
    }

    const newPatient = authorization.patient ?? null;

    const existingPatient = acc.get(patientId);

    if (existingPatient !== undefined) {
      existingPatient.authorizations?.push(authorization as Authorization);
    } else if (newPatient === null || newPatient === undefined) {
      // add unassigned patients to separate object
      acc.set(patientId, {
        id: patientId,
        institutionPatientId: null,
        authorizationsCount: null,
        authorizations: [authorization as Authorization],
        dob: "",
        firstName: "",
        lastName: "",
        city: "",
        state: "",
        address: "",
        zip: "",
        gender: "",
        phone: "",
      });
    } else {
      acc.set(patientId, {
        ...newPatient,
        authorizations: [authorization] as Authorization[],
      });
    }
    return acc;
  }, new Map());

  const patientList = Array.from(patientMap.values());

  // sort
  const sortField: string = getSortField(parseAsNonNil(variables.sortBy));
  const patientListSorted = sortPatientList(
    patientList as AuthPatient[],
    sortField
  );

  return (
    <PageContainer>
      <Flex
        flexDirection="column"
        marginBottom="10px"
        style={{ width: "100%", maxWidth: "1352px" }}
      >
        {patientListSorted.length === 0 ? (
          <EmptyState>
            No patients found for the given filters. Change your search filters,
            or create a new authorization using the New Authorization button at
            the top.
          </EmptyState>
        ) : (
          patientListSorted.map((patient) => {
            // These arrays should always have at least one element in them for
            // virtue of how we're accumulating this data. If one did not, it
            // would suggest a bug we'd want to fix.
            return (
              <Box
                key={patient?.id ?? patient?.lastName}
                data-cy={`componentPatientTile${patient?.firstName}${patient?.lastName}`}
                marginBottom="10px"
              >
                <PatientTile
                  accounts={accounts}
                  authorizations={patient?.authorizations ?? null}
                  patient={patient as TilePatient}
                  filterByExpired={filterByExpired}
                  fetchMore={async () => {
                    await fetchMore({
                      variables: {
                        filters: {
                          ...variables.filters,
                          patientId: patient.id ?? null,
                        },
                        limit: patient.authorizations
                          ? patient.authorizations.length + perPage
                          : null,
                      },
                      updateQuery: (previous, { fetchMoreResult }) => {
                        if (fetchMoreResult == null) {
                          return previous;
                        }
                        return {
                          ...variables,
                          ...fetchMoreResult,
                          authorizations: {
                            ...previous.authorizations,
                            ...fetchMoreResult.authorizations,
                            authorizations:
                              // FIXME(ndhoule): This leaks sort implementation
                              // details from the backend to the frontend. We
                              // don't really have a choice, though; because
                              // we're using the authorizations query instead of
                              // the patient.authorizations subquery, we need to
                              // be able to stitch pagination queries into the
                              // original list and mimic the sort order that the
                              // server would use.
                              //
                              // For details on how to get rid of this
                              // implementation detail leak, see the FIXME note
                              // where we're grouping the authorizations.
                              _.orderBy(
                                _.uniqBy(
                                  [
                                    ...previous.authorizations.authorizations,
                                    ...fetchMoreResult.authorizations
                                      .authorizations,
                                  ],
                                  (authorization) => authorization.id
                                ),
                                [getSortField(parseAsNonNil(variables.sortBy))],
                                ["desc"]
                              ),
                            // If we take this from the child pagination query,
                            // we'll use the child's pageInfo for the master
                            // list; this won't be correct.
                            pageInfo: previous.authorizations.pageInfo,
                          },
                        };
                      },
                    });
                  }}
                />
              </Box>
            );
          })
        )}
        {data.authorizations.pageInfo.hasNextPage && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <PrimaryButton
              disabled={loading}
              loading={loading}
              onClick={async () => {
                const limit = (variables.limit ?? perPage) + perPage;
                await fetchMore({
                  variables: {
                    // To paginate, retrieve the first n documents plus an
                    // additional n documents (the "next page").
                    //
                    // FIXME(ndhoule): We should be performing cursor-based
                    // pagination here. As is, each page of data becomes 2x more
                    // expensive to retrieve.
                    limit,
                  },
                });

                setLimit(limit);
              }}
              style={{ paddingLeft: "100px", paddingRight: "100px" }}
            >
              Load More Patients
            </PrimaryButton>
          </Box>
        )}
      </Flex>
    </PageContainer>
  );
};
