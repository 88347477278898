import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

import formFilterInfo from "./fragments/formFilterInfo";

export const withUpsertFormFilter = graphql(
  gql`
    mutation upsertFormFilter(
      $formId: Int!
      $jCodes: [String]
      $states: [String]
      $NDCs: [String]
      $insuranceTypes: [FormFilterInsuranceType!]
      $benefitType: String
    ) {
      upsertFormFilter(
        formId: $formId
        jCodes: $jCodes
        states: $states
        insuranceTypes: $insuranceTypes
        NDCs: $NDCs
        benefitType: $benefitType
      ) {
        ...formFilterInfo
      }
    }
    ${formFilterInfo}
  `,
  {
    name: "upsertFormFilter",
  }
);

export const withDeleteFormOrFaxFilter = graphql(
  gql`
    mutation deleteFormOrFaxFilter($id: Int!, $isFormFilter: Boolean!) {
      deleteFormOrFaxFilter(id: $id, isFormFilter: $isFormFilter)
    }
  `,
  {
    name: "deleteFormOrFaxFilter",
  }
);
