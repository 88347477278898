import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useCurrentAccount } from "../../graphql/Account";

if (window.CONFIG.NODE_ENV !== "dev") {
  datadogRum.init({
    applicationId: "6d8ea4a2-264c-43ee-8d6e-4049b21190fa",
    clientToken: "pubec1eb9e08a6432164ca9661905ea25ce",
    site: "datadoghq.com",
    service: "web",
    env: window.CONFIG.NODE_ENV,
    version: window.BUILD_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });

  datadogRum.startSessionReplayRecording();
}

export const DatadogProvider: React.FC = ({ children }) => {
  const [account] = useCurrentAccount();

  useEffect(() => {
    if (account) {
      datadogRum.setUser({
        id: account.id,
        name: `${account.firstName} ${account.lastName}`,
        email: account.email!,
        isSamaUser: account.isSamaUser,
        isAdmin: account.isAdmin,
      });
    }
  }, [account]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
