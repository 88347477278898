import { useQuery } from "@apollo/client";
import GetPortals from "../graphql/GetPortals.gql";
import { GetPortalsQuery, GetPortalsQueryVariables } from "@@generated/graphql";
import { useMemo } from "react";
import { Portal } from "@samacare/graphql";

export const usePortals = (props?: { includeFallback?: boolean }) => {
  const { data, loading } = useQuery<GetPortalsQuery, GetPortalsQueryVariables>(
    GetPortals,
    {
      variables: {
        where: {
          ...(props?.includeFallback ? { isFallback: [true, false] } : {}),
        },
      },
    }
  );
  const portals = useMemo(() => data?.portals ?? [], [data]) as Portal[];
  return { portals, loading };
};
