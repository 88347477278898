import _ from "lodash";
import styled, { css } from "styled-components";
import Cleave from "cleave.js/react";

export const BasePositionCss = css`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  position: absolute;
`;

export const BaseInputCss = css`
  ${BasePositionCss}
  background-color: ${(props) =>
    !props.disabled && props.isDisplayHidden
      ? props.theme.disabledGray
      : props.background};
  border: none;
  border-bottom: 2px solid ${(props) => props.theme.darkGray};
  box-shadow: none;
  transition: all 0.2s ease-in-out;

  ${({ disabled }) =>
    !disabled &&
    `
  &:focus {
    box-shadow: -4px 2px 5px ${(props) => props.theme.darkGray};
    outline: 0;
    transform: scale(1.1);
  }
  `};

  &:disabled {
    background-color: ${(props) => props.theme.darkGray};
  }

  &:invalid {
    border: 1px solid ${({ theme }) => theme.warningRed};
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDBWMHoiLz48cGF0aCBmaWxsPSIjZmY0MjQyIiBkPSJNMTEgMTVoMnYyaC0yem0wLThoMnY2aC0yem0uOTktNUM2LjQ3IDIgMiA2LjQ4IDIgMTJzNC40NyAxMCA5Ljk5IDEwQzE3LjUyIDIyIDIyIDE3LjUyIDIyIDEyUzE3LjUyIDIgMTEuOTkgMnpNMTIgMjBjLTQuNDIgMC04LTMuNTgtOC04czMuNTgtOCA4LTggOCAzLjU4IDggOC0zLjU4IDgtOCA4eiIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: right center;
    transition: none;
  }
`;

export const BaseInput = styled.input`
  ${BaseInputCss}
`;

export const BaseInputCleave = styled(Cleave)`
  ${BaseInputCss}
`;

export const BaseTextArea = styled.textarea`
  ${BaseInputCss}
  resize: none;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const CheckmarkContainer = styled.label`
  ${BaseInputCss}
  display: block;
  cursor: ${(props) => (props.disabled ? "normal" : "pointer")};
  border-radius: ${(props) =>
    props.isCircle ? Math.max(props.width, props.height) : 0}px;
  background-color: ${(props) => (props.isCircle ? "transparent" : "white")};
  border: 1px solid
    ${(props) =>
      props.isDisplayHidden || props.disabled
        ? props.theme.disabledGray
        : props.background};
  box-shadow: 0 0 3px 3px
    ${(props) =>
      props.isDisplayHidden || props.disabled
        ? props.theme.disabledGray
        : props.background};

  input {
    opacity: 0;
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  }

  input:checked ~ span {
    background-image: url("../../assets/${(props) =>
      props.isCircle
        ? CONFIG.CONSTANTS.IMG_S3_KEYS.CHECK_CIRCLE
        : CONFIG.CONSTANTS.IMG_S3_KEYS.CHECK}");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &:focus {
    box-shadow: -4px 2px 5px ${({ background }) => background};
    outline: 3px solid ${({ background }) => background};
    transform: scale(1.3);
  }

  ${({ disabled }) =>
    !disabled &&
    `
  :hover {
    transform: ${(props) => (props.disabled ? "none" : "scale(1.3)")};
  }
  `}
`;

export const CheckmarkImageContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const handleInputFocus = (target, partInputs, i) => {
  // handle auto input focusing
  const cursorPosition = target.selectionStart;
  if (
    i < _.keys(partInputs).length - 1 &&
    cursorPosition === partInputs[i].placeholder.length
  ) {
    // move to next input
    partInputs[i + 1].ref.element.focus();
    partInputs[i + 1].ref.element.setSelectionRange(0, 0);
  } else if (i > 0 && cursorPosition === 0) {
    // move to previous input
    partInputs[i - 1].ref.element.focus();
  }
};

export const getPropsFromInput = (input) =>
  _.pick(input, [
    "isDisplayHidden",
    "top",
    "left",
    "width",
    "height",
    "tabIndex",
    "background",
    "disabled",
  ]);
