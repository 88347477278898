import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";

import {
  signIn,
  type SignInInput,
  type SignInOutput,
  signInWithRedirect,
  signOut,
  type SignOutInput,
  confirmSignIn,
  type ConfirmSignInInput,
  type ConfirmSignInOutput,
  resetPassword,
  type ResetPasswordInput,
  type ResetPasswordOutput,
  confirmResetPassword,
  type ConfirmResetPasswordInput,
  getCurrentUser,
  type GetCurrentUserOutput,
  fetchAuthSession,
  fetchUserAttributes,
  type FetchUserAttributesOutput,
  sendUserAttributeVerificationCode,
  type SendUserAttributeVerificationCodeInput,
  type SendUserAttributeVerificationCodeOutput,
  confirmUserAttribute,
  type ConfirmUserAttributeInput,
  autoSignIn,
  AuthError,
} from "aws-amplify/auth";
import { datadogRum } from "@datadog/browser-rum";
import { KeyValueStorageInterface } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

import { Amplify } from "aws-amplify";
import { sendMsgToBgScript } from "../../util/bgScriptMsgUtils";
class LocalStorage implements KeyValueStorageInterface {
  public send(type: string, data?: string | Record<string, any>) {
    // this function is async, but we don't need to wait for it to finish
    sendMsgToBgScript({ type: "CognitoMsg", data: { type, data } });
  }

  public async setItem(key: string, value: string): Promise<void> {
    this.send("cognito:setItem", { key, value });
    return localStorage.setItem(key, value);
  }

  public async getItem(key: string): Promise<string | null> {
    return localStorage.getItem(key);
  }

  public async removeItem(key: string): Promise<void> {
    this.send("cognito:removeItem", { key });
    return localStorage.removeItem(key);
  }

  public async clear(): Promise<void> {
    this.send("cognito:clear");
    return localStorage.clear();
  }
}

const url = `${window.CONFIG.SITE_PROTOCOL}${window.CONFIG.SITE_DOMAIN}${
  window.CONFIG.NODE_ENV === "dev" ? ":8080" : ""
}`;

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: window.CONFIG.COGNITO_USER_POOL_ID,
      userPoolClientId: window.CONFIG.COGNITO_CLIENT_ID,
      signUpVerificationMethod: "code",
      loginWith: {
        email: true,
        oauth: {
          domain: window.CONFIG.COGNITO_DOMAIN,
          redirectSignIn: [`${url}`],
          redirectSignOut: [url],
          scopes: [
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin",
          ],
          responseType: "code",
          providers: [
            {
              custom: "azure",
            },
          ],
        },
      },
    },
  },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new LocalStorage());

type AuthSignInState = Omit<SignInOutput, "nextStep">;
type AuthConfirmSignInState = Omit<ConfirmSignInOutput, "nextStep">;
type AuthResetPasswordState = Omit<ResetPasswordOutput, "nextStep">;

type AuthNextStepState =
  | SignInOutput["nextStep"]
  | ResetPasswordOutput["nextStep"];

type AuthState = Partial<
  AuthSignInState &
    AuthConfirmSignInState &
    AuthResetPasswordState &
    GetCurrentUserOutput &
    SendUserAttributeVerificationCodeOutput &
    AuthNextStepState &
    Awaited<ReturnType<typeof fetchAuthSession>>
> & {
  error?: string;
  isChecked: boolean;
  isEmailVerified: boolean;
  attributes?: FetchUserAttributesOutput;
  signInStep?: SignInOutput["nextStep"];
  resetPasswordStep?: ResetPasswordOutput["nextStep"];
};

type AuthContextType = AuthState & {
  loading: boolean;
  onError: (message: string | undefined) => void;
  onReset: () => void;
  onSignIn: (input?: SignInInput) => Promise<void>;
  onSignInWithRedirect: (name: string) => Promise<void>;
  onConfirmSignIn: (input: ConfirmSignInInput) => Promise<void>;
  onSignOut: (input?: SignOutInput) => Promise<void>;
  onResetPassword: (input: ResetPasswordInput) => Promise<void>;
  onConfirmResetPassword: (input: ConfirmResetPasswordInput) => Promise<void>;
  onConfirmUserAttribute: (input: ConfirmUserAttributeInput) => Promise<void>;
  onSendUserAttributeVerificationCode: (
    input: SendUserAttributeVerificationCodeInput
  ) => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const DEFAULT_AUTH_STATE: AuthState = {
  isChecked: false,
  isSignedIn: false,
  isPasswordReset: false,
  isEmailVerified: false,
  missingAttributes: [],
};

export const useAuth = () =>
  useContext<AuthContextType>(AuthContext as React.Context<AuthContextType>);

export const withAuthContext =
  (ChildComponent: React.ComponentType<{ auth: AuthContextType }>): React.FC =>
  (props) => {
    const auth = useAuth();
    return <ChildComponent auth={auth} {...props} />;
  };

export const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [state, setState] = useState<AuthState>(DEFAULT_AUTH_STATE);

  const onError = useCallback(function onReset(message: string | undefined) {
    setError(message);
  }, []);

  const onReset = useCallback(function onReset() {
    setError(void 0);
    setLoading(false);
    setState(DEFAULT_AUTH_STATE);
  }, []);

  function onStateChange(nextState: Partial<AuthState>) {
    // @ts-ignore stupid types from AWS
    setState((prev) => ({
      ...prev,
      ...nextState,
    }));
  }

  const onAuthSuccessful = useCallback(async function onAuthSuccessfulCallback(
    data?: Partial<AuthState>
  ) {
    try {
      const [user, session, attributes] = await Promise.all([
        getCurrentUser(),
        fetchAuthSession(),
        fetchUserAttributes(),
      ]);

      onStateChange({
        ...data,
        ...user,
        ...session,
        attributes,
        isEmailVerified: attributes?.email_verified === "true",
      });

      sendMsgToBgScript({
        type: "SCAuthSuccessful",
      });
    } catch (err) {
      // PasswordResetRequiredException
      if ((err as AuthError).name === "PasswordResetRequiredException") {
        onStateChange({
          isChecked: true,
          resetPasswordStep: {
            resetPasswordStep: "CONFIRM_RESET_PASSWORD_WITH_CODE",
          } as ResetPasswordOutput["nextStep"],
        });
      } else {
        throw err;
      }
    }
  },
  []);

  const onSignInWithRedirect = useCallback(
    async function onSignInWithRedirectCallback(name: string) {
      try {
        setError(void 0);
        setLoading(true);

        await signInWithRedirect({
          provider: {
            custom: name,
          },
        });
      } catch (err) {
        onStateChange(DEFAULT_AUTH_STATE);
        setError((err as Error).message ?? "An error occurred in sign in");
      } finally {
        setLoading(false);
      }
    },
    [setError, setLoading, onStateChange]
  );

  const onSignIn = useCallback(
    async function onSignInCallback(input?: SignInInput) {
      try {
        setError(void 0);
        setLoading(true);
        const { nextStep, ...data } = input
          ? await signIn({
              ...input,
              options: { authFlowType: "USER_PASSWORD_AUTH" },
            })
          : await autoSignIn();

        if (nextStep?.signInStep !== "DONE") {
          onStateChange({
            ...data,
            signInStep: nextStep,
          });
        } else {
          await onAuthSuccessful({
            ...data,
            signInStep: nextStep,
          });
        }
      } catch (err) {
        onStateChange(DEFAULT_AUTH_STATE);

        if ((err as AuthError)?.name === "UserNotFoundException") {
          setError("Incorrect username or password.");
        } else if ((err as AuthError)?.name !== "NotAuthorizedException") {
          switch ((err as Error).message) {
            case "PostAuthentication failed with error User is deactivated.":
              setError("User is deactivated.");
              break;
            case "PostAuthentication failed with error User has expired.":
              setError("User has expired.");
              break;
            default:
              datadogRum.addError(err, { username: input?.username });
              setError(
                (err as Error).message ?? "An error occurred in sign in."
              );
              break;
          }
        } else {
          setError((err as Error).message ?? "An error occurred in sign in.");
        }
      } finally {
        setLoading(false);
      }
    },
    [onAuthSuccessful]
  );

  const value = useMemo(
    () => ({
      error,
      loading,
      ...state,
      onError,
      onReset,
      onSignIn,
      onSignInWithRedirect,
      onConfirmSignIn: async (input: ConfirmSignInInput) => {
        try {
          setError(void 0);
          setLoading(true);
          const { nextStep, ...data } = await confirmSignIn({
            ...input,
            options: { authFlowType: "USER_PASSWORD_AUTH" },
          });

          if (nextStep?.signInStep !== "DONE") {
            onStateChange({
              ...data,
              signInStep: nextStep,
            });
          } else {
            await onAuthSuccessful({
              ...data,
              signInStep: nextStep,
            });
          }
        } catch (err) {
          onStateChange(DEFAULT_AUTH_STATE);
          setError(
            (err as Error).message ?? "An error occurred in confirm sign in"
          );
        } finally {
          setLoading(false);
        }
      },
      onSignOut: async (input?: SignOutInput) => {
        try {
          setError(void 0);
          setLoading(true);
          await signOut(input);
          window.location.reload();
        } catch (err) {
          setError((err as Error).message ?? "An error occurred in sign out");
        }
      },
      onCheckUser: async () => {
        try {
          setLoading(true);
          await onAuthSuccessful({
            isSignedIn: true,
            signInStep: {
              signInStep: "DONE",
            },
            isChecked: true,
          });
        } catch (err) {
          onStateChange({ isSignedIn: false, isChecked: true });
        } finally {
          setLoading(false);
        }
      },
      onResetPassword: async (input: ResetPasswordInput) => {
        try {
          setError(void 0);
          setLoading(true);
          const { nextStep, ...data } = await resetPassword(input);
          onStateChange({ ...data, resetPasswordStep: nextStep });
        } catch (err) {
          setState((prev) => ({ ...prev, isSignedIn: false, isChecked: true }));
          setError(
            (err as Error).message ?? "An error occurred in reset password"
          );
        } finally {
          setLoading(false);
        }
      },
      onConfirmResetPassword: async (input: ConfirmResetPasswordInput) => {
        try {
          setError(void 0);
          setLoading(true);
          await confirmResetPassword(input);
          onReset();
        } catch (err) {
          setState((prev) => ({ ...prev, isSignedIn: false, isChecked: true }));
          setError(
            (err as Error).message ??
              "An error occurred in confirm reset password"
          );
        } finally {
          setLoading(false);
        }
      },
      onConfirmUserAttribute: async (input: ConfirmUserAttributeInput) => {
        try {
          setError(void 0);
          setLoading(true);
          await confirmUserAttribute(input);
          await onSignIn();
        } catch (err) {
          setError(
            (err as Error).message ??
              "An error occurred in confirm reset password"
          );
        } finally {
          setLoading(false);
        }
      },
      onSendUserAttributeVerificationCode: async (
        input: SendUserAttributeVerificationCodeInput
      ) => {
        try {
          setError(void 0);
          setLoading(true);
          onStateChange(await sendUserAttributeVerificationCode(input));
        } catch (err) {
          setError(
            (err as Error).message ??
              "An error occurred in confirm reset password"
          );
        } finally {
          setLoading(false);
        }
      },
    }),
    [
      state,
      error,
      loading,
      onError,
      onReset,
      onSignIn,
      onSignInWithRedirect,
      onAuthSuccessful,
    ]
  );

  useEffect(() => {
    if (window.CONFIG.COGNITO_ENABLED && !loading && !state.isChecked) {
      void value.onCheckUser();
    }
  }, [loading, state.isChecked, value]);

  useEffect(() => {
    // get all keys from local storage
    const keys = Object.keys(localStorage);
    // loop through each key
    keys.forEach((key) => {
      // if the key is a cognito key
      if (key.includes("CognitoIdentityServiceProvider")) {
        // remove the key
        void sendMsgToBgScript({
          type: "CognitoMsg",
          data: {
            type: "cognito:setItem",
            data: { key, value: localStorage[key] },
          },
        });
      }
    });
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
