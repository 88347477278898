import { useCallback } from "react";
import { useDispatch } from "../configureStore";
import { unsetRequiredFields } from "../reducers/formReducer";

export const useUnsetRequired = () => {
  const dispatch = useDispatch();

  return useCallback(
    (toUnset: string | string[]) => {
      dispatch(unsetRequiredFields(toUnset));
    },
    [dispatch]
  );
};
