import * as React from "react";
import {
  AlertProviderProps,
  Provider as AlertProvider,
  positions,
  transitions,
  types,
  useAlert,
} from "react-alert";
import { AlertTemplate } from "./template";

const DEFAULT_OPTIONS: Omit<AlertProviderProps, "template"> = {
  offset: "30px",
  position: positions.TOP_RIGHT,
  timeout: 5000,
  transition: transitions.SCALE,
};

export type ToastProviderProps = React.ComponentProps<typeof ToastProvider>;

export const ToastProvider: React.VoidFunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <AlertProvider template={AlertTemplate} {...DEFAULT_OPTIONS}>
    {children}
  </AlertProvider>
);

export enum ToastKind {
  Info = "info",
  Positive = "positive",
  Warning = "warning",
  Negative = "negative",
}

export interface ToastManager {
  show(content: React.ReactNode, options: { kind: ToastKind }): void;
  info(content: React.ReactNode): void;
  positive(content: React.ReactNode): void;
  warning(content: React.ReactNode): void;
  negative(content: React.ReactNode): void;
}

/**
 * @deprecated Use a MUI component instead
 */
export const useToast = (): ToastManager => {
  const alert = useAlert();

  const showAlert = (
    content: React.ReactNode,
    { kind }: { kind: ToastKind }
  ): void => {
    let type;
    switch (kind) {
      case ToastKind.Info:
        type = types.INFO;
        break;
      case ToastKind.Positive:
        type = types.SUCCESS;
        break;
      case ToastKind.Warning:
        type = types.ERROR;
        break;
      case ToastKind.Negative:
        type = types.ERROR;
        break;
    }

    alert.show(content, { type });
  };

  return {
    show(content, options) {
      showAlert(content, options);
    },
    info(content) {
      showAlert(content, { kind: ToastKind.Info });
    },
    positive(content) {
      showAlert(content, { kind: ToastKind.Positive });
    },
    warning(content) {
      showAlert(content, { kind: ToastKind.Warning });
    },
    negative(content) {
      showAlert(content, { kind: ToastKind.Negative });
    },
  };
};
