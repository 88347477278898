import useInsuranceTypeOptions, {
  type InsuranceTypeOption,
} from "./useInsuranceTypeOptions";

export type { InsuranceTypeOption };

const useInsuranceTypeOption = (
  value: string
): undefined | InsuranceTypeOption => {
  const types = useInsuranceTypeOptions();
  return types.find(({ label, id }) => label === value || id === value);
};

export default useInsuranceTypeOption;
