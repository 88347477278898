import * as React from "react";
import { Box } from "@samacare/design/core";

interface PortalAutofillImageProps {
  supportsAutofill: boolean;
  sx?: React.CSSProperties;
}

export const PortalAutofillImage: React.FC<PortalAutofillImageProps> = ({
  supportsAutofill,
  sx,
}) => {
  return (
    <Box
      component="img"
      src={
        supportsAutofill
          ? "../../../../assets/portal_yes_autofill.png"
          : "../../../../assets/portal_no_autofill.png"
      }
      alt={
        supportsAutofill
          ? "Portal Autofill Enabled"
          : "Portal Autofill Disabled"
      }
      sx={{
        width: 34,
        background: "transparent",
        ...sx,
      }}
    />
  );
};
