import { Box, Typography, Stack } from "@samacare/design/core";
import { DatePickerField } from "@samacare/form";
import { useEnrollmentContext } from "../../app/routes/Enrollment/EnrollmentProvider";
import Link from "@samacare/design/core/Link";

type SignatureBlockProps = {
  isSubmitted?: boolean;
  children?: React.ReactNode;
  description: string;
};

export const SignatureBlock: React.FC<SignatureBlockProps> = ({
  isSubmitted = false,
  children,
  description,
}: SignatureBlockProps) => {
  const { enrollmentProgram } = useEnrollmentContext();

  return (
    <Box>
      <Stack my={2} spacing={2} direction="column">
        <Typography variant="body1" color="text.primary">
          {description}{" "}
          <Link
            href={enrollmentProgram?.pdfUrl ?? ""}
            target="_blank"
            rel="noreferrer"
          >
            View form here
          </Link>
        </Typography>
        {children}
        <DatePickerField
          name="questionnaire.signatureDate"
          disabled={isSubmitted}
          label="Date"
          textFieldProps={{ style: { width: 300 }, required: true }}
        />
      </Stack>
    </Box>
  );
};
