import _ from "lodash";
import { Authorization } from "@samacare/graphql";
import { HelpTag } from "@samacare/layout/HelpTag";
import { LeftRightCenterV } from "../../../components/LeftRight";
import { SegmentContainer } from "../../../components/Segment/SegmentContainer";
import SegmentNumber from "../../../components/Segment/SegmentNumber";
import SegmentOptions from "../../../components/Segment/SegmentOptions";
import SegmentPhone from "../../../components/Segment/SegmentPhone";
import SegmentState from "../../../components/Segment/SegmentState";
import SegmentText from "../../../components/Segment/SegmentText";
import { SegmentTitle } from "../../../components/Segment/SegmentTitle";
import SegmentZip from "../../../components/Segment/SegmentZip";
import { TopBottom } from "@samacare/component";
import { useFieldConfigs } from "../../../hooks/useFieldConfigs";
import { useHighlightRequiredFields } from "../../../hooks/useHighlightRequiredFields";
import { useSet } from "../../../hooks/useSet";
import { FormField } from "./FormField";
import { PatientIdentifierFields } from "./PatientIdentifierFields";

const HEIGHT_REGEX = `^([0-9]*')?([0-9]*)?"?$`;

const df = window.CONFIG.DEFAULT_FIELDS;

const FieldKey = {
  PATIENT_GENDER: "genderSelector",
} as const;

const fields = [
  { key: FieldKey.PATIENT_GENDER },
  df.PATIENT_HEIGHT_INCHES,
  df.PATIENT_WEIGHT_POUNDS,
  df.PATIENT_ADDRESS_LINE,
  df.PATIENT_CITY,
  df.PATIENT_STATE,
  df.PATIENT_ZIP,
  df.PATIENT_PHONE,
];

interface PatientSectionProps {
  auth: Authorization;
  isDisabled: boolean;
  hasIntegrations: boolean;
}
export const PatientSection: React.VFC<PatientSectionProps> = (props) => {
  const set = useSet();
  const highlightRequiredFields = useHighlightRequiredFields();
  const fieldConfigs = useFieldConfigs(fields, props.auth.formFieldConfig);
  const { isDisabled, auth, hasIntegrations } = props;

  return (
    <SegmentContainer style={{ padding: 5 }}>
      <SegmentTitle>Patient</SegmentTitle>
      <TopBottom sx={{ width: "600px" }}>
        <PatientIdentifierFields
          auth={auth}
          isDisabled={isDisabled}
          hasIntegrations={hasIntegrations}
        />

        <LeftRightCenterV style={{ marginTop: "16px", columnGap: "8px" }}>
          <FormField
            title="Gender"
            fieldConfig={fieldConfigs[FieldKey.PATIENT_GENDER]}
            style={{ width: "100%" }}
          >
            <SegmentOptions
              id={`field_${FieldKey.PATIENT_GENDER}`}
              disabled={isDisabled}
              handleChange={set}
              item={{
                options: [df.PATIENT_GENDER_MALE, df.PATIENT_GENDER_FEMALE],
              }}
              highlightIfMissing={
                highlightRequiredFields &&
                fieldConfigs[FieldKey.PATIENT_GENDER].isRequired
              }
            />
          </FormField>
          <FormField
            title={
              <>
                {df.PATIENT_HEIGHT_INCHES.title}
                <HelpTag
                  text={`This field accepts feet with inches. eg 5'3"`}
                />
              </>
            }
            fieldConfig={fieldConfigs[df.PATIENT_HEIGHT_INCHES.key]}
            style={{ width: "100%" }}
          >
            <SegmentText
              disabled={isDisabled}
              handleChange={set}
              item={{
                key: df.PATIENT_HEIGHT_INCHES.key,
                onKeyPress: (e: React.KeyboardEvent) => {
                  const predictedValue =
                    (e.target as HTMLInputElement).value +
                    String.fromCharCode(e.which);
                  if (!predictedValue.match(HEIGHT_REGEX)) e.preventDefault();
                },
                onBlur: (e: React.FocusEvent) => {
                  const target = e.target as HTMLInputElement;
                  if (!target.value) return; // Ignore field if its blank

                  const match = target.value.trim().match(HEIGHT_REGEX);
                  // This is rarely going to be relevant, but can prevent poor behavior if the user pastes an invalid heigh string into the field.
                  if (!match) {
                    set({ [df.PATIENT_HEIGHT_INCHES.key]: "" });
                    return;
                  }

                  const [, feet, inches] = match;

                  const heightInInches =
                    12 * parseInt(feet || "0") + parseInt(inches || "0");

                  set({
                    [df.PATIENT_HEIGHT_INCHES.key]: heightInInches.toString(),
                  });
                },
              }}
              highlightIfMissing={
                highlightRequiredFields &&
                fieldConfigs[df.PATIENT_HEIGHT_INCHES.key].isRequired
              }
            />
          </FormField>
          <FormField
            title="Weight (lbs)"
            fieldConfig={fieldConfigs[df.PATIENT_WEIGHT_POUNDS.key]}
            style={{ width: "100%" }}
          >
            <SegmentNumber
              disabled={isDisabled}
              handleChange={set}
              item={{ key: df.PATIENT_WEIGHT_POUNDS.key }}
              highlightIfMissing={
                highlightRequiredFields &&
                fieldConfigs[df.PATIENT_WEIGHT_POUNDS.key].isRequired
              }
            />
          </FormField>
        </LeftRightCenterV>

        <TopBottom sx={{ marginTop: "16px" }}>
          <FormField
            title="Address"
            fieldConfig={fieldConfigs[df.PATIENT_ADDRESS_LINE.key]}
          >
            <SegmentText
              // style={{ width: "100%" }}
              disabled={isDisabled}
              handleChange={set}
              item={{ key: df.PATIENT_ADDRESS_LINE.key }}
              highlightIfMissing={
                highlightRequiredFields &&
                fieldConfigs[df.PATIENT_ADDRESS_LINE.key].isRequired
              }
            />
          </FormField>

          <LeftRightCenterV style={{ columnGap: "8px" }}>
            <FormField
              title="City"
              fieldConfig={fieldConfigs[df.PATIENT_CITY.key]}
            >
              <SegmentText
                disabled={isDisabled}
                handleChange={set}
                item={{ key: df.PATIENT_CITY.key }}
                highlightIfMissing={
                  highlightRequiredFields &&
                  fieldConfigs[df.PATIENT_CITY.key].isRequired
                }
              />
            </FormField>
            <FormField
              title="State"
              fieldConfig={fieldConfigs[df.PATIENT_STATE.key]}
            >
              <SegmentState
                id={_.camelCase(`field_${df.PATIENT_STATE.key}`)}
                disabled={isDisabled}
                handleChange={set}
                item={{ key: df.PATIENT_STATE.key }}
                width="200px"
                highlightIfMissing={
                  highlightRequiredFields &&
                  fieldConfigs[df.PATIENT_STATE.key].isRequired
                }
              />
            </FormField>
            <FormField
              title="Zip"
              fieldConfig={fieldConfigs[df.PATIENT_ZIP.key]}
            >
              <SegmentZip
                disabled={isDisabled}
                handleChange={set}
                item={{ key: df.PATIENT_ZIP.key }}
                highlightIfMissing={
                  highlightRequiredFields &&
                  fieldConfigs[df.PATIENT_ZIP.key].isRequired
                }
              />
            </FormField>
          </LeftRightCenterV>
        </TopBottom>

        <FormField
          title="Phone"
          fieldConfig={fieldConfigs[df.PATIENT_PHONE.key]}
          style={{ marginTop: "16px" }}
        >
          <SegmentPhone
            cypressTag={_.camelCase(`field_${df.PATIENT_PHONE.key}`)}
            disabled={isDisabled}
            handleChange={set}
            item={{ key: df.PATIENT_PHONE.key }}
            highlightIfMissing={
              highlightRequiredFields &&
              fieldConfigs[df.PATIENT_PHONE.key].isRequired
            }
          />
        </FormField>
      </TopBottom>
    </SegmentContainer>
  );
};
