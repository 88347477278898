import {
  useFormContext,
  AutocompleteField,
  type AutocompleteFieldProps,
} from "@samacare/form";
import useEnrollmentProgramOptions from "./hooks/useEnrollmentProgramOptions";
import { MenuItem } from "@samacare/design";

import type { EnrollmentProgram as EnrollmentProgramType } from "@samacare/graphql";
import { useFeatureFlag, useConfig } from "../app/hooks";
import { EnhancedServicesIcon } from "@samacare/component";
export type EnrollmentProgramAutocompleteProps = Omit<
  AutocompleteFieldProps<EnrollmentProgramType, false, false, false>,
  "options"
>;

export const EnrollmentProgramField: React.FC<
  EnrollmentProgramAutocompleteProps
> = ({ name, ...props }) => {
  const { watch } = useFormContext();
  const config = useConfig();

  const { enrollmentPrograms } = useEnrollmentProgramOptions();
  const enhancedMessagingEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEnhancedServicesMessaging
  );

  return (
    <AutocompleteField<EnrollmentProgramType, false, false, false>
      name={name}
      value={watch(name) ?? ""}
      options={(enrollmentPrograms as EnrollmentProgramType[]) ?? []}
      filterSelectedOptions
      getOptionLabel={(option: EnrollmentProgramType) => option.label ?? ""}
      renderOption={(renderProps, option, { selected }) => (
        <MenuItem {...renderProps} key={option.id} selected={selected}>
          {option.label}
          {enhancedMessagingEnabled && (
            <EnhancedServicesIcon
              sx={{ fontSize: 20 }}
              hover={false}
              key={`icon-${option.id}`}
            />
          )}
        </MenuItem>
      )}
      {...props}
    />
  );
};
