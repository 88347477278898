import { IconType } from "@react-icons/all-files";
import styled from "styled-components";

import { AiFillCamera as BaseCamera } from "@react-icons/all-files/ai/AiFillCamera";
import { AiOutlineCamera as BaseCameraOutline } from "@react-icons/all-files/ai/AiOutlineCamera";
import { AiFillSave as BaseSave } from "@react-icons/all-files/ai/AiFillSave";
import { AiOutlineSave as BaseSaveOutline } from "@react-icons/all-files/ai/AiOutlineSave";
import { BsArrowLeft as BaseArrowLeft } from "@react-icons/all-files/bs/BsArrowLeft";
import { BsArrowLeftShort as BaseArrowLeftShort } from "@react-icons/all-files/bs/BsArrowLeftShort";
import { BsArrowRight as BaseArrowRight } from "@react-icons/all-files/bs/BsArrowRight";
import { BsArrowRightShort as BaseArrowRightShort } from "@react-icons/all-files/bs/BsArrowRightShort";
import { BsBoxArrowInUpLeft as BaseBoxArrowInUpLeft } from "@react-icons/all-files/bs/BsBoxArrowInUpLeft";
import { BsBoxArrowInUpRight as BaseBoxArrowInUpRight } from "@react-icons/all-files/bs/BsBoxArrowInUpRight";
import { GoDash as BaseMinus } from "@react-icons/all-files/go/GoDash";
import { GoPlus as BasePlus } from "@react-icons/all-files/go/GoPlus";
import { RiArrowDropRightLine as BaseCaretRight } from "@react-icons/all-files/ri/RiArrowDropRightLine";
import { RiArrowDropLeftLine as BaseCaretLeft } from "@react-icons/all-files/ri/RiArrowDropLeftLine";
import { HiCheckCircle as BaseCheckCircle } from "@react-icons/all-files/hi/HiCheckCircle";
import { HiOutlineCheckCircle as BaseCheckCircleOutline } from "@react-icons/all-files/hi/HiOutlineCheckCircle";
import { HiXCircle as BaseXCircle } from "@react-icons/all-files/hi/HiXCircle";
import { IoDocument as BaseDocument } from "@react-icons/all-files/io5/IoDocument";
import { IoDocumentOutline as BaseDocumentOutline } from "@react-icons/all-files/io5/IoDocumentOutline";
import { MdDelete as BaseDelete } from "@react-icons/all-files/md/MdDelete";
import { MdEdit as BaseEdit } from "@react-icons/all-files/md/MdEdit";
import { MdPause as BasePause } from "@react-icons/all-files/md/MdPause";
import { MdStop as BaseStop } from "@react-icons/all-files/md/MdStop";
import { MdVisibility as BaseEye } from "@react-icons/all-files/md/MdVisibility";
import { MdNavigateBefore as BaseNavigateBefore } from "@react-icons/all-files/md/MdNavigateBefore";
import { MdNavigateNext as BaseNavigateNext } from "@react-icons/all-files/md/MdNavigateNext";
import { IoMdRefresh as BaseRefresh } from "@react-icons/all-files/io/IoMdRefresh";
import { RiZoomInFill as BaseZoomIn } from "@react-icons/all-files/ri/RiZoomInFill";
import { RiZoomOutFill as BaseZoomOut } from "@react-icons/all-files/ri/RiZoomOutFill";
import { RiZoomInLine as BaseZoomInOutline } from "@react-icons/all-files/ri/RiZoomInLine";
import { RiZoomOutLine as BaseZoomOutOutline } from "@react-icons/all-files/ri/RiZoomOutLine";
import { IoIosWarning as BaseWarning } from "@react-icons/all-files/io/IoIosWarning";
import { MdContentCopy as BaseCopy } from "@react-icons/all-files/md/MdContentCopy";
import { MdLaunch as BaseLaunch } from "@react-icons/all-files/md/MdLaunch";
import { HiExternalLink as BaseExternalLink } from "@react-icons/all-files/hi/HiExternalLink";
import { BiSort as BaseSort } from "@react-icons/all-files/bi/BiSort";
import { BiBell as BaseBell } from "@react-icons/all-files/bi/BiBell";
import { BiBellOff as BaseBellSlash } from "@react-icons/all-files/bi/BiBellOff";

const withStyling = (Icon: IconType): IconType => styled(Icon)`
  color: ${({ color = "inherit" }) => color};
`;

export const ArrowLeft = withStyling(BaseArrowLeft);
export const ArrowLeftShort = withStyling(BaseArrowLeftShort);
export const ArrowRight = withStyling(BaseArrowRight);
export const ArrowRightShort = withStyling(BaseArrowRightShort);
export const Bell = withStyling(BaseBell);
export const BellSlash = withStyling(BaseBellSlash);
export const BoxArrowInUpLeft = withStyling(BaseBoxArrowInUpLeft);
export const BoxArrowInUpRight = withStyling(BaseBoxArrowInUpRight);
export const Camera = withStyling(BaseCamera);
export const CameraOutline = withStyling(BaseCameraOutline);
export const CaretLeft = withStyling(BaseCaretLeft);
export const CaretRight = withStyling(BaseCaretRight);
export const CheckCircle = withStyling(BaseCheckCircle);
export const CheckCircleOutline = withStyling(BaseCheckCircleOutline);
export const Copy = withStyling(BaseCopy);
export const Document = withStyling(BaseDocument);
export const DocumentOutline = withStyling(BaseDocumentOutline);
export const Edit = withStyling(BaseEdit);
export const Eye = withStyling(BaseEye);
export const Delete = withStyling(BaseDelete);
export const Launch = withStyling(BaseLaunch);
export const Minus = withStyling(BaseMinus);
export const NavigateBefore = withStyling(BaseNavigateBefore);
export const NavigateNext = withStyling(BaseNavigateNext);
export const Pause = withStyling(BasePause);
export const Plus = withStyling(BasePlus);
export const Refresh = withStyling(BaseRefresh);
export const Save = withStyling(BaseSave);
export const SaveOutline = withStyling(BaseSaveOutline);
export const Sort = withStyling(BaseSort);
export const Stop = withStyling(BaseStop);
export const Warning = withStyling(BaseWarning);
export const XCircle = withStyling(BaseXCircle);
export const ZoomIn = withStyling(BaseZoomIn);
export const ZoomInOutline = withStyling(BaseZoomInOutline);
export const ZoomOut = withStyling(BaseZoomOut);
export const ZoomOutOutline = withStyling(BaseZoomOutOutline);
export const ExternalLink = withStyling(BaseExternalLink);
