import { useState } from "react";
import { Box } from "@samacare/design";

import {
  PrescriberBlock,
  PrescriberBlockProps,
  TextField,
} from "@samacare/form";
import { Tab, TabPanel, TabContext, TabList } from "@samacare/design/core";

export type ReferringProviderBlockProps = {
  required?: boolean;
  disabled?: boolean;
  PrescriberBlockOverride?: PrescriberBlockProps;
  ReferralPrescriberBlockOverride?: Partial<PrescriberBlockProps>;
};

export const ReferringProviderBlock: React.FC<ReferringProviderBlockProps> = ({
  required = false,
  disabled = false,
  PrescriberBlockOverride,
  ReferralPrescriberBlockOverride,
}) => {
  const [activeTab, setActiveTab] = useState<string>("1");

  return (
    <Box>
      <TabContext value={activeTab}>
        <Box>
          <TabList
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
            onChange={(_event, value: string) => setActiveTab(value)}
          >
            <Tab label="Provider" value="1" />
            <Tab label="Referring Provider" value="2" />
          </TabList>
        </Box>
        <Box>
          <TabPanel value="1">
            <PrescriberBlock
              {...PrescriberBlockOverride}
              required={required}
              disabled={disabled}
            >
              <TextField
                label="State license #"
                name="questionnaire.sama-prescriberInfo-stateLicense"
                disabled={disabled}
                fullWidth
                required
              />
            </PrescriberBlock>
          </TabPanel>
          <TabPanel value="2">
            <PrescriberBlock
              {...ReferralPrescriberBlockOverride}
              disabled={disabled}
              chooseDisabled
              IdInputProps={{ name: "sama-referralPrescriberId" }}
              FirstNameInputProps={{
                name: "questionnaire.sama-referralPrescriberFirstName",
              }}
              LastNameInputProps={{
                name: "questionnaire.sama-referralPrescriberLastName",
              }}
              SpecialtyDescriptionInputProps={{
                name: "questionnaire.sama-referralPrescriberSpecialty",
              }}
              NPIInputProps={{
                name: "questionnaire.sama-referralPrescriberNPI",
              }}
              TINInputProps={{
                name: "questionnaire.sama-referralPrescriberTIN",
              }}
              PTANInputProps={{
                name: "questionnaire.sama-referralPrescriberPTAN",
              }}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};
