import filterTabs from "../routes/Support/SupportAuthorizationList/filterTabs";

export const SET_FILTERS = "SET_FILTERS";

export const initialState = {
  selected: filterTabs[0],
  firstName: "",
  lastName: "",
  filters: null,
  limit: 30,
  sortBy: "LAST_UPDATED",
  insuranceCompanyId: null,
  prescriberId: null,
  locationId: null,
};

export function setAuthorizationListFilters(toSet) {
  return { type: SET_FILTERS, toSet };
}

export default function authorizationList(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, ...action.toSet };
    default:
      return state;
  }
}
