import { RouteProps } from "react-router-dom";
import { useAuthorization } from "@@hooks/useAuthorization";

import LegacyPortalAuthorization from "./PortalAuthorizationLegacy";
import { PortalAuthorizationCopilot } from "./PortalAuthorizationCopilot";
import { PaOriginType } from "@samacare/graphql";

const PortalAuthorization = (props: RouteProps): JSX.Element => {
  const { authorization } = useAuthorization();
  if (
    (authorization?.portal && !authorization?.portal?.isLegacy) ||
    authorization?.paOrigin === PaOriginType.Copilot
  ) {
    return <PortalAuthorizationCopilot />;
  }
  return <LegacyPortalAuthorization {...props} />;
};

export default PortalAuthorization;
