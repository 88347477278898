import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useState } from "react";
import * as React from "react";
import styled from "styled-components";
import Segment from "Segment/Segment";
import {
  Flex,
  Loader,
  PrimaryButton as BasePrimaryButton,
  SecondaryButton as BaseSecondaryButton,
} from "@@ui-kit";
import {
  RetrieveCoverLetterAuthorizationFormQuery,
  RetrieveCoverLetterAuthorizationFormQueryVariables,
} from "@@generated/graphql";
import { useConfig } from "../../hooks";
import Modal from "../Modal";
import { ModalContent as BaseModalContent } from "../ModalStyledComponents";
import { Editor } from "./Editor";
import { Form } from "../../util/inputConfigurationManager";

const PrimaryButton = styled(BasePrimaryButton)`
  min-width: 120px;
`;

const SecondaryButton = styled(BaseSecondaryButton)`
  min-width: 120px;
`;

const ModalBody = styled.div`
  min-height: 100px;
  width: 600px;
`;

const ModalBodyFluid = styled.div`
  min-height: 100px;
`;

const ModalContent = styled(BaseModalContent)`
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
`;

const ButtonContainer = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;

  & > * {
    margin-left: 5px;
  }
`;

enum CoverLetterModalScreen {
  Default,
  AddCoverLetter,
  CustomizeCoverLetter,
}

const retrieveCoverLetterAuthorizationFormQuery = gql`
  query RetrieveCoverLetterAuthorizationForm(
    $filters: AuthorizationFormsFilters
  ) {
    authorizationForms(filters: $filters) {
      edges {
        node {
          id
          description
          hasTaggedSignature
          imgKeys
          imgURLs
          isHiddenInstitutionIds
          pdfURL
          specification
          taggedHeight
          taggedWidth
          title
          updatedAt
        }
      }
    }
  }
`;

export const CoverLetterModal: React.VoidFunctionComponent<{
  close: () => void;
  results: Record<string, unknown>;
  setFormFields: (fields: Record<string, unknown>) => void;
  setRequiredFields: (fields: Record<string, unknown>) => void;
  unsetRequiredFields: (fields: Record<string, unknown>) => void;
  submit: (shouldIncludeCoverLetter?: boolean) => void;
}> = ({
  close,
  results,
  setFormFields,
  setRequiredFields,
  unsetRequiredFields,
  submit,
}) => {
  const config = useConfig();
  const [screen, setScreen] = useState<CoverLetterModalScreen>(
    CoverLetterModalScreen.Default
  );

  const { data, error, loading } = useQuery<
    RetrieveCoverLetterAuthorizationFormQuery,
    RetrieveCoverLetterAuthorizationFormQueryVariables
  >(retrieveCoverLetterAuthorizationFormQuery, {
    variables: {
      filters: {
        title: { eq: config.CONSTANTS.COVER_LETTER_FORM_TITLE },
      },
    },
  });

  return (
    <Modal header="Attach Cover Letter" onClick={close} open>
      <Loader inverted active={loading} />

      {(() => {
        switch (screen) {
          case CoverLetterModalScreen.AddCoverLetter:
            return (
              <ModalBody>
                <ModalContent>
                  <Segment
                    item={config.DEFAULT_FIELDS.COVER_LETTER_NOTE}
                    set={setFormFields}
                  />
                </ModalContent>
                <ButtonContainer>
                  <SecondaryButton
                    onClick={() => setScreen(CoverLetterModalScreen.Default)}
                  >
                    Back
                  </SecondaryButton>
                  <SecondaryButton
                    onClick={() =>
                      setScreen(CoverLetterModalScreen.CustomizeCoverLetter)
                    }
                  >
                    Edit Full Details
                  </SecondaryButton>
                  <PrimaryButton
                    data-cy="actionCoverLetterSubmit"
                    onClick={() => submit(true)}
                  >
                    Attach
                  </PrimaryButton>
                </ButtonContainer>
              </ModalBody>
            );
          case CoverLetterModalScreen.CustomizeCoverLetter: {
            const form = data?.authorizationForms?.edges[0]?.node;

            if (error != null || form == null) {
              return (
                <ModalBody>
                  <ModalContent>
                    There was an unexpected error while handling your request.
                    Please reload the page and try again and, if the issue
                    persists, contact SamaCare.
                  </ModalContent>
                  <ButtonContainer>
                    <SecondaryButton onClick={close}>Close</SecondaryButton>
                  </ButtonContainer>
                </ModalBody>
              );
            }

            return (
              <ModalBodyFluid>
                <ModalContent>
                  <Editor
                    disabled={loading}
                    form={
                      // FIXME(ndhoule): The type here will be screwy until we
                      // change the GraphQL type for AuthorizationForm.specification
                      // to be more specific than "JSON"
                      {
                        ...form,
                        specification: form.specification,
                      } as Form
                    }
                    results={results}
                    segmentLoading={false}
                    setFormFields={setFormFields}
                    setRequiredFields={setRequiredFields}
                    unsetRequiredFields={unsetRequiredFields}
                    width="650px"
                    height="650px"
                  />
                </ModalContent>
                <ButtonContainer>
                  <SecondaryButton
                    onClick={() =>
                      setScreen(CoverLetterModalScreen.AddCoverLetter)
                    }
                  >
                    Back
                  </SecondaryButton>
                  <PrimaryButton onClick={() => submit(true)}>
                    Submit
                  </PrimaryButton>
                </ButtonContainer>
              </ModalBodyFluid>
            );
          }
          case CoverLetterModalScreen.Default:
            return (
              <ModalBody>
                <ModalContent>
                  Would you like to attach a cover sheet? Doing so will
                  highlight key information and improve the chances of an
                  approval.
                </ModalContent>
                <ButtonContainer>
                  <SecondaryButton
                    onClick={() => {
                      submit(false);
                    }}
                    data-cy="actionNoCoverLetter"
                  >
                    No
                  </SecondaryButton>
                  <PrimaryButton
                    data-cy="actionAttachCoverLetter"
                    onClick={() => {
                      setScreen(CoverLetterModalScreen.AddCoverLetter);
                    }}
                  >
                    Yes
                  </PrimaryButton>
                </ButtonContainer>
              </ModalBody>
            );
        }
      })()}
    </Modal>
  );
};
