import gql from "graphql-tag";

import { useLazyQuery } from "@apollo/client";
import { Prescriber } from "@samacare/graphql";
import { useCallback, useState } from "react";
import { prescriberInfo } from "../graphql/fragments/prescriberInfo";

export const findPrescriberByNpiQuery = gql`
  query FindPrescriberByNpi($npi: String!) {
    findPrescriberByNpi(npi: $npi) {
      success
      message
      prescriber {
        ...prescriberInfo
      }
      prescriberFromNpiRegistry {
        firstName
        lastName
        specialtyDescription
        licenseNumber
        specialtyCode
        NPI
        source
      }
    }
  }
  ${prescriberInfo}
`;

export const useFindPrescriberByNpi = () => {
  const [prescriber, setPrescriber] = useState<Prescriber | null>(null);
  const [find, { data, loading }] = useLazyQuery(findPrescriberByNpiQuery, {
    onCompleted: (d) => {
      if (d.findPrescriberByNpi?.prescriber) {
        setPrescriber(d.findPrescriberByNpi.prescriber);
      } else if (d.findPrescriberByNpi?.prescriberFromNpiRegistry) {
        setPrescriber(d.findPrescriberByNpi.prescriberFromNpiRegistry);
      }
    },
    fetchPolicy: "network-only",
  });

  const findPrescriberByNpi = useCallback(
    (npi: string) => {
      find({ variables: { npi } });
    },
    [find],
  );

  return {
    findPrescriberByNpi,
    prescriber,
    isExisting: !!prescriber?.id,
    findPrescriberSuccess: data?.findPrescriberByNpi?.success,
    errorMessage: data?.findPrescriberByNpi?.message,
    loading,
  };
};
