import * as React from "react";
import IntegratedDownloadedFilesSuggestionModal from "AuthorizationSharedComponents/IntegratedDownloadedFilesSuggestionModal";
import Section from "AuthorizationSharedComponents/Section";
import { Account, Authorization, PatientSourceType } from "@samacare/graphql";
import RedoxFilesSuggestionModal from "AuthorizationSharedComponents/RedoxAttachFilesModal";
import { usePatient } from "@@hooks/usePatient";

interface IAttachmentUploadProps {
  showEMRDocumentOption: boolean;
  authorization: Authorization;
  account: Account;
}

const AttachmentUpload: React.VFC<IAttachmentUploadProps> = ({
  showEMRDocumentOption,
  authorization,
  account,
}) => {
  const patientResult = usePatient(+authorization.patient!.id);
  return (
    <Section
      section={{
        items: [
          {
            key: "Attachments",
            title: "Please select additional attachments",
            type: window.CONFIG.CONSTANTS.FORM_TYPES.UPLOAD,
          },
        ],
        title: "Additional attachments",
      }}
      childrenAfterSection
    >
      {/**
       * If an institution has EMR turned on, display the EMR document option:
       * - document download loading indicator
       * OR
       * - Attach From EMR button
       */}

      {patientResult.patient?.patientSource?.sourceType ===
      PatientSourceType.Redox ? (
        <RedoxFilesSuggestionModal
          authorization={authorization}
          account={account}
        />
      ) : showEMRDocumentOption ? (
        <IntegratedDownloadedFilesSuggestionModal
          authorizationId={authorization.id}
          account={account}
        />
      ) : null}
    </Section>
  );
};

export default AttachmentUpload;
