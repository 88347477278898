import { useQuery, ApolloError } from "@apollo/client";
import Query from "./UseInsuranceCompanyOptionsQuery.gql";

import { UseInsuranceCompanyOptionsQuery } from "@@generated/graphql";

export type InsuranceCompanyOption = {
  id: string;
  label: string;
  isArchived: boolean;
};

const useInsuranceCompanyOptions = (): {
  error?: ApolloError;
  loading: boolean;
  insuranceCompanies: InsuranceCompanyOption[] | undefined;
} => {
  const { data, error, loading } =
    useQuery<UseInsuranceCompanyOptionsQuery>(Query);
  return {
    insuranceCompanies: data?.insuranceCompaniesFindAll,
    error,
    loading,
  };
};

export default useInsuranceCompanyOptions;
