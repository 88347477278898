import { MaskedTextField, TextFieldProps } from "@samacare/form2";

export type ZipFieldProps = TextFieldProps;

export const ZipField: React.FC<ZipFieldProps> = (props) => {
  const { label, ...rest } = props;

  return (
    <MaskedTextField
      mask="99999-9999"
      label={label ?? "Zip Code"}
      fullWidth
      rules={{
        pattern: {
          value: /(^\d{5}$)|(^\d{9}$)/,
          message: "Invalid zip code",
        },
      }}
      {...rest}
    />
  );
};
