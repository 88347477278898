import _ from "lodash";
import { useState, PureComponent, useEffect } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import isMobilePhone from "validator/lib/isMobilePhone";
import { Flex } from "@@ui-kit";
import { withPossibleFormNumbers } from "../graphql/FormNumber";
import {
  withSendAuthorization,
  withAuthorizationQuery,
} from "../graphql/Authorization";
import BoldHeaderModal from "./BoldHeaderModal";
import Favorite from "./Favorite";
import Box from "@samacare/design/core/Box";
import Tab from "@samacare/design/core/Tab";
import {
  Button,
  FormLabel,
  Typography,
  Grid,
  Container,
  TabPanel,
  TabContext,
  TabList,
} from "@samacare/design/core";
import FormControl from "@samacare/design/core/FormControl";
import ButtonGroup from "@samacare/design/core/ButtonGroup";
import TextField from "@samacare/design/core/TextField";
import Divider from "@samacare/design/core/Divider";
import Stack from "@samacare/design/core/Stack";
import { InternationalPhone } from "Segment/InternationalPhone";
import { formatPhoneForDisplay } from "@samacare/utils";

const FaxNumberList = ({ numbers, selectedNumber, setSelectedNumber }) => {
  if (!numbers.length)
    return (
      <Flex flexDirection="row" justifyContent="center">
        <Typography variant="subtitle2">
          No matching numbers, please try another tab.
        </Typography>
      </Flex>
    );
  return (
    <Grid container spacing={2}>
      {numbers.map(({ id, isFavorite, number, description }) => (
        <>
          <Grid item xs={4}>
            <Favorite isFavorite={isFavorite} numberId={_.parseInt(id)} />
            &nbsp;
            {formatPhoneForDisplay(number)}
          </Grid>
          <Grid item xs={5}>
            {description}
          </Grid>
          <Grid
            item
            container
            xs={3}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              variant={number === selectedNumber ? "contained" : "outlined"}
              onClick={() => setSelectedNumber(number)}
              size="sm"
            >
              {number === selectedNumber ? "Selected" : "Select"}
            </Button>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

const ResendModal = (props) => {
  const {
    isMultiParty,
    resendToHCP,
    prevPhone,
    isAdministrativeResend,
    numbers,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [reason, setReason] = useState(null);
  const [info, setInfo] = useState("");
  const [selectedNumber, setSelectedNumber] = useState(prevPhone);

  //When the prevPhone updates (the dialog opens), update the selected number
  useEffect(() => {
    setSelectedNumber(prevPhone);
  }, [prevPhone]);

  if (!numbers) return null;

  const favoriteIds = new Set(numbers.favoriteNumbers.map((n) => n.id));
  const { favoriteNumbers, suggestedNumbers, remainingNumbers } = _.mapValues(
    numbers || {},
    (list) =>
      _.map(list, (number) => ({
        ...number,
        isFavorite: favoriteIds.has(number.id),
      }))
  );

  const lastUsedNumber = _.find(
    [...favoriteNumbers, ...suggestedNumbers, ...remainingNumbers],
    { number: prevPhone }
  ) || {
    number: prevPhone,
    description: "",
    isFavorite: false,
  };

  return (
    <BoldHeaderModal
      header="Resend Authorization"
      onClick={props.onHide}
      open={props.show}
    >
      {isAdministrativeResend && (
        <Container>
          <Typography variant="h6" color="primary.main">
            Change number
          </Typography>
          <Divider light />
        </Container>
      )}
      <Stack spacing={2} direction="column" sx={{ width: "650px" }}>
        {!isAdministrativeResend && (
          <Container>
            The&nbsp;
            {isMultiParty && (
              <Box
                component="span"
                color="primary.main"
                sx={{ fontWeight: "bold" }}
              >
                {resendToHCP ? "HCP" : "payer"}
                &nbsp;
              </Box>
            )}
            fax {prevPhone && ` to ${prevPhone}`} has failed 5 times. Sometimes
            a busy fax number may result failures as well.
            <br />
            <br />
            Please confirm or select a new number to resend.
          </Container>
        )}
        {!isMultiParty || !resendToHCP ? (
          <TabContext value={activeTab}>
            <Container>
              <TabList
                sx={{ borderBottom: 1, borderColor: "divider" }}
                onChange={(_event, value) => setActiveTab(value)}
                aria-label="Choose Fax Number Tabs"
              >
                <Tab label="Last Used" />
                <Tab label="Suggested" />
                <Tab label="Favorited" />
                <Tab label="Other" />
                <Tab label="Enter New Fax" />
              </TabList>
            </Container>
            <Box sx={{ height: "200px", maxHeight: "200px", overflow: "auto" }}>
              {[
                [lastUsedNumber],
                suggestedNumbers,
                favoriteNumbers,
                remainingNumbers,
              ].map((sectionNumbers, index) => (
                <TabPanel key="index" value={index}>
                  <FaxNumberList
                    selectedNumber={selectedNumber}
                    setSelectedNumber={setSelectedNumber}
                    numbers={sectionNumbers}
                  />
                </TabPanel>
              ))}
              <TabPanel value={4}>
                <InternationalPhone
                  onChange={(e) => setSelectedNumber(e.target.value)}
                  value={selectedNumber}
                />
              </TabPanel>
            </Box>
          </TabContext>
        ) : (
          <Container>
            <InternationalPhone
              onChange={(e) => setSelectedNumber(e.target.value)}
              value={selectedNumber}
            />
          </Container>
        )}

        {isAdministrativeResend && (
          <Container>
            <Stack direction="column" spacing={1}>
              <Box>
                <Typography variant="h6" color="primary.main">
                  Tell us more
                </Typography>
                <Divider light sx={{ marginBottom: 1 }} />
              </Box>
              <FormControl>
                <FormLabel>Resend Reason</FormLabel>
                <ButtonGroup sx={{ marginBottom: 1 }}>
                  {_.map(
                    CONFIG.CONSTANTS.AUTHORIZATION_RESEND_REASONS,
                    (resendReason) => (
                      <Button
                        data-cy={_.camelCase(
                          `action_resend_reason_${resendReason.key}`
                        )}
                        key={`resend_reason_button_${resendReason.key}`}
                        onClick={() => setReason(resendReason)}
                        variant={
                          reason && resendReason.key === reason.key
                            ? "contained"
                            : "outlined"
                        }
                      >
                        {resendReason.title}
                      </Button>
                    )
                  )}
                </ButtonGroup>
              </FormControl>
              <TextField
                label="Additional Info"
                onChange={(event) => setInfo(event.target.value)}
              />
            </Stack>
          </Container>
        )}
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button variant="outlined" onClick={props.onHide}>
            Cancel
          </Button>
          <Button
            data-cy="actionResend"
            variant="contained"
            onClick={() =>
              props.submit({ number: selectedNumber, reason, info })
            }
            disabled={!selectedNumber || (isAdministrativeResend && !reason)}
          >
            Resend
          </Button>
        </Stack>
      </Stack>
    </BoldHeaderModal>
  );
};

class RequestResendButton extends PureComponent {
  state = {
    open: false,
    prevPhone: "",
    phone: "",
    resendToHCP: null,
    isMultiParty: null,
  };

  getPhoneNumber = async (authorization) => {
    const { isMultiParty, isNovartis } = this.props;
    return _.get(authorization, "resendToHCP", false) &&
      (isMultiParty || isNovartis)
      ? _.get(
          authorization,
          `config.${CONFIG.DEFAULT_FIELDS.FAX_NUMBER.key}`,
          ""
        )
      : _.get(authorization, "submissionPhoneNumber", "");
  };

  toggleModal = async () => {
    const { client, authorizationId } = this.props;
    const { open } = this.state;

    if (open) {
      this.setState({
        open: false,
        phone: "",
      });
    } else {
      const { data } = await client.query({
        query: withAuthorizationQuery,
        variables: { id: authorizationId },
      });
      const isMultiParty =
        data.authorizationById.type ===
        CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key;
      const prevPhone = await this.getPhoneNumber(data.authorizationById);

      this.setState({
        prevPhone,
        open: true,
        resendToHCP: _.get(data, "authorizationById.resendToHCP", null),
        isMultiParty,
      });
    }
  };

  submit = ({ number, reason, info }) => {
    const { sendAuthorization, authorizationId, alert } = this.props;

    if (isMobilePhone(number, "en-US")) {
      sendAuthorization({
        variables: {
          id: authorizationId,
          phone: number,
          resendReasonKey: reason && reason.key,
          resendReasonAdditionalInfo: info,
        },
      })
        .then(() => {
          alert.success("Success!");
          this.toggleModal();
        })
        .catch(() => {
          alert.error("There was an error resending your authorization");
        });
    } else {
      alert.error("Please input a valid phone number");
    }
  };

  render() {
    const { children, isAdministrativeResend, institutionId } = this.props;
    const { open, phone, isMultiParty, resendToHCP, prevPhone } = this.state;
    const numbers = _.cloneDeep(this.props.numbers);
    const shownNumbers =
      numbers &&
      _.mapValues(numbers, (list) =>
        _.reject(list, (number) =>
          (number.isHiddenInstitutionIds || []).includes(
            _.parseInt(institutionId)
          )
        )
      );

    return (
      <div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.toggleModal();
          }}
        >
          {children}
        </div>
        <ResendModal
          isAdministrativeResend={isAdministrativeResend}
          numbers={shownNumbers}
          isMultiParty={isMultiParty}
          resendToHCP={resendToHCP}
          prevPhone={prevPhone}
          phone={phone}
          show={open}
          onHide={this.toggleModal}
          submit={this.submit}
        />
      </div>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withSendAuthorization,
  withPossibleFormNumbers
)(withApollo(withAlert()(RequestResendButton)));
