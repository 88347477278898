import parsePhoneNumber, {
  parsePhoneNumberFromString,
} from "libphonenumber-js";

export const formatPhoneForDisplay = (phone?: string): string => {
  if (!phone) {
    return "";
  }

  const parsedPhone = parsePhoneNumber(phone);
  if (!parsedPhone) {
    return phone;
  }

  const intlPhone = parsedPhone.formatInternational();
  if (intlPhone.startsWith("+1")) {
    return parsedPhone.formatNational();
  }

  return intlPhone;
};

export const isE164 = (phoneNumber: string) => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);

  if (!parsedNumber) {
    return false;
  }
  if (!parsedNumber.isValid()) {
    return false;
  }

  return phoneNumber === parsedNumber.format("E.164");
};
