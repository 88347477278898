import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import Select from "react-select";
import { withAlert } from "react-alert";
import { compose } from "recompose";
import { NdcSelector } from "@@components/NdcSelector";

import { withDeleteFormOrFaxFilter } from "../../../../graphql/FormFilter";
import { withDrugOptions } from "../../../../graphql/DrugOptions";

const { INSURANCE_TYPES, STATES, BENEFIT_TYPES } = CONFIG.CONSTANTS;

const StyledSelect = styled(Select)`
  width: 250px;
  margin-right: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px 0px;
`;

const Remove = styled.div`
  display: flex;
  margin: 0 5px;
`;

const FilterItemWrapper = styled.div`
  border-radius: 3px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.purple};
  margin: 0 8px 16px 0;
  padding: 0 10px;
`;

const Cell = styled.div`
  border-radius: 3px;
  padding: 10px;
  margin: 0 5px;
  border: 1px solid ${(props) => props.theme.purple};
  display: flex;
  align-items: center;

  div {
    color: ${(props) => props.theme.purple};
    min-width: 30px;
    cursor: pointer;
    text-align: right;

    &:hover {
      font-weight: 700;
    }
  }
`;

const DeleteX = styled.div`
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.purple};
  }
`;

const stateOptions = _.map(STATES, (value, key) => ({
  value: key,
  label: value,
}));

class FilterBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      states: props.states,
      jCodes: props.jCodes,
      NDCs: props.NDCs,
      insuranceTypes: props.insuranceTypes || [],
      benefitType: props.benefitType,
    };
  }

  deleteFilter = async () => {
    const { id, alert, associationKey, deleteFunction } = this.props;
    try {
      await deleteFunction({
        variables: { id, isFormFilter: associationKey === "formId" },
      });
    } catch (e) {
      alert.error(`There was an error ${e}`);
    }
  };

  editFilterList = (key, value, isRemove) => {
    const stateHandler = (prevState) => ({
      [key]: isRemove
        ? _.without(prevState[key], value)
        : _.uniq([...prevState[key], value]),
    });
    this.setState(stateHandler, this.updateFilterInfo);
  };

  updateFilterInfo = async () => {
    const { upsertFunction, alert, associationKey, associationId } = this.props;
    try {
      const { states, jCodes, insuranceTypes, benefitType, NDCs } = this.state;
      await upsertFunction({
        [associationKey]: associationId,
        states,
        jCodes,
        insuranceTypes,
        benefitType,
        NDCs,
      });
      alert.info("Successfully updated record");
    } catch (e) {
      const { states, jCodes } = this.props;
      alert.error(`Failed to update ${e}`);
      this.setState({ states, jCodes });
    }
  };

  render() {
    const { associationKey, drugOptions } = this.props;
    const { states, jCodes, insuranceTypes, benefitType, NDCs } = this.state;
    const insuranceOptions = Object.values(INSURANCE_TYPES)
      .map((value) => ({
        value,
        label: _.replace(value, /_/g, " "),
      }))
      .filter(({ value }) => !insuranceTypes.includes(value));
    const benefitOptions = Object.values(BENEFIT_TYPES)
      .map((value) => ({
        value,
        label: _.replace(value, /_/g, " "),
      }))
      .filter(({ value }) => !insuranceTypes.includes(value));

    const showNDCFilter = _.includes(
      [BENEFIT_TYPES.ANY, BENEFIT_TYPES.PHARMACY_ONLY],
      benefitType
    );
    const showJCodeFilter = _.includes(
      [BENEFIT_TYPES.ANY, BENEFIT_TYPES.MEDICAL_ONLY],
      benefitType
    );
    return (
      <FilterItemWrapper>
        <div
          style={{
            display: "flex",
            margin: "10px",
            justifyContent: "space-between",
          }}
        >
          <div>
            Filters will suggest and also hide this form / number when the
            criteria are not met.
          </div>
          <DeleteX onClick={this.deleteFilter}>X</DeleteX>
        </div>
        <Row>
          {_.map(states, (value) => (
            <Cell key={`state-item-${value}`}>
              {value}
              <div
                onClick={() => {
                  this.editFilterList("states", value, true);
                }}
              >
                X
              </div>
            </Cell>
          ))}
          <StyledSelect
            value={null}
            onChange={({ value }) => {
              this.editFilterList("states", value, false);
            }}
            options={stateOptions}
            placeholder="Add a state"
          />
        </Row>
        {associationKey === "formId" && showNDCFilter && (
          <Row>
            {_.map(NDCs, (value) => (
              <Cell key={`ndc-item-${value}`}>
                {value}
                <Remove
                  onClick={() => {
                    this.editFilterList("NDCs", value, true);
                  }}
                >
                  Remove
                </Remove>
              </Cell>
            ))}
            <NdcSelector
              onSelect={({ code }) => {
                this.editFilterList("NDCs", code, false);
              }}
              styleOverride={{ width: "250px", marginRight: "20px" }}
            />
          </Row>
        )}
        {associationKey === "formId" && showJCodeFilter && (
          <Row>
            {_.map(jCodes, (value) => (
              <Cell key={`drug-item-${value}`}>
                {value} (
                {drugOptions &&
                  _.filter(drugOptions, ["code", value])[0].drugName}
                )
                <Remove
                  onClick={() => {
                    this.editFilterList("jCodes", value, true);
                  }}
                >
                  Remove
                </Remove>
              </Cell>
            ))}
            <StyledSelect
              value={null}
              onChange={({ value }) => {
                this.editFilterList("jCodes", value, false);
              }}
              options={_.map(drugOptions, ({ drugName, code }) => ({
                value: code,
                label: `${code} - ${drugName}`,
              }))}
              placeholder="Add a jCode"
            />
          </Row>
        )}
        {associationKey === "formId" && (
          <Row>
            {(insuranceTypes ?? []).map((insuranceType) => (
              <Cell key={insuranceType}>
                {insuranceType}
                <Remove
                  onClick={() => {
                    this.setState(
                      (state) => ({
                        insuranceTypes: state.insuranceTypes.filter(
                          (value) => value !== insuranceType
                        ),
                      }),
                      this.updateFilterInfo
                    );
                  }}
                >
                  Remove
                </Remove>
              </Cell>
            ))}
            <StyledSelect
              value={null}
              onChange={({ value }) => {
                this.setState(
                  (state) => ({
                    insuranceTypes: _.uniq([...state.insuranceTypes, value]),
                  }),
                  this.updateFilterInfo
                );
              }}
              options={insuranceOptions}
              placeholder="Add an Insurance Type"
            />
          </Row>
        )}
        {associationKey === "formId" && (
          <Row>
            <StyledSelect
              onChange={({ value }) => {
                this.setState(
                  {
                    benefitType: value,
                    ...(value === BENEFIT_TYPES.PHARMACY_ONLY
                      ? { jCodes: [] }
                      : {}),
                    ...(value === BENEFIT_TYPES.MEDICAL_ONLY
                      ? { NDCs: [] }
                      : {}),
                  },
                  this.updateFilterInfo
                );
              }}
              options={benefitOptions}
              placeholder="Set Benefit Type"
              value={benefitOptions.find(
                (option) => option.value === benefitType
              )}
            />
          </Row>
        )}
      </FilterItemWrapper>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withDeleteFormOrFaxFilter,
  withDrugOptions
)(withAlert()(FilterBox));
