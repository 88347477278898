import * as React from "react";
import { useTheme } from "../app/hooks";
import { BaseButton, BaseButtonProps } from "./BaseButton";

export type DangerButtonProps = React.ComponentProps<typeof DangerButton>;

/**
 * @deprecated Use a MUI component instead
 */
export const DangerButton: React.FunctionComponent<
  Omit<BaseButtonProps, "backgroundColor">
> = (props) => {
  const theme = useTheme();
  return <BaseButton {...props} backgroundColor={theme.colors.danger} />;
};
