import moment from "moment";
import { Button, Box, Grid } from "@samacare/design/core";
import { EnrollmentSection } from "./EnrollmentSection";
import { useHistory } from "react-router-dom";
import { useEnrollmentContext } from "./EnrollmentProvider";
// import PdfViewer from "../../components/PdfViewer";
import { EnrollmentPatientInfoHeader } from "./EnrollmentPatientInfoHeader";
import { DownloadAsPdf } from "../../components/AuthorizationSharedComponents/DownloadAsPdf";
import {
  ModelType,
  TemplateType,
  EnrollmentProgramTypeColumnGraphQlType,
} from "@samacare/graphql";
import { getPatientConsentStatus } from "./utils";
import ROUTE_PATHS from "../ROUTE_PATHS";

export const EnrollmentApellisSummaryPage: React.FC = () => {
  const { enrollment } = useEnrollmentContext();
  const history = useHistory();

  return (
    <>
      <EnrollmentPatientInfoHeader />
      <Box sx={{ margin: "30px" }}>
        <EnrollmentSection
          title="Summary"
          adornment={
            enrollment?.EnrollmentProgram?.type ===
            EnrollmentProgramTypeColumnGraphQlType.Pdf ? (
              <DownloadAsPdf
                template={TemplateType.Enrollment}
                modelType={ModelType.Enrollment}
                modelId={parseInt(enrollment?.id ?? "")}
                data-cy="downloadButton"
              />
            ) : null
          }
        >
          <Box sx={{ width: "70%" }}>
            <Grid sx={{ margin: "10px 0", fontWeight: 700 }}>Status:</Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                Enrollment:
              </Grid>
              <Grid item xs={6}>
                Submitted (
                {moment(enrollment?.submittedAt ?? new Date()).format(
                  "MM/DD/YYYY, h a"
                )}
                )
              </Grid>
              <Grid item xs={6}>
                Patient Consent:
              </Grid>
              <Grid item xs={6}>
                {getPatientConsentStatus(enrollment)}
              </Grid>
            </Grid>
          </Box>
        </EnrollmentSection>
      </Box>

      <Button
        type="button"
        variant="outlined"
        size="large"
        onClick={() => {
          history.push(`${ROUTE_PATHS.ENROLLMENTS.path}`);
        }}
      >
        Back to Enrollment List
      </Button>
    </>
  );
};
