import * as React from "react";
import styled from "styled-components";

import { PrimaryButton } from "@@ui-kit";
import { Modal } from "../../../components/Modal";

const StyledParagraph = styled.p`
  margin: 18px 0 10px;
`;

const StyledLink = styled.a`
  display: block;
  margin-bottom: 12px;
`;

type Props = { open: boolean; onClose: () => void };
export const WebExtensionInstallModal: React.FC<Props> = ({
  open,
  onClose,
}: Props) => {
  const handleBtnClick = () => {
    window.open(window.CONFIG.CONSTANTS.WEB_EXTENSION_URL);
  };
  return (
    <Modal
      open={open}
      header="SamaCare Web Extension Installation"
      onClick={onClose}
    >
      <div>
        <StyledParagraph>
          Looks like you haven’t installed our extension yet. Click the button
          below to install it. Come back to proceed after.
        </StyledParagraph>
        <StyledLink
          href="https://docsend.com/view/ji9gdd285ga3xcm9"
          target="_blank"
          data-cy="actionViewExtensionUserGuide"
          rel="noreferrer"
        >
          Extension User Guide
        </StyledLink>
        <PrimaryButton
          data-cy="actionInstallExtension"
          onClick={handleBtnClick}
        >
          Install Extension
        </PrimaryButton>
      </div>
    </Modal>
  );
};
