import * as React from "react";
import styled from "styled-components";

export type InputProps = React.ComponentProps<typeof Input>;

/**
 * Input is a component that allows a user to type in text information.
 *
 * ## When to use:
 *
 * When you want to collect free-form information from a user; e.g. a name,
 * an address, an email, a password.
 *
 * @example
 * ```tsx
 * import { Input, Label } from "@@ui-kit/forms";
 *
 * // ...
 *
 * <Label htmlFor="favorite-band">
 *   <Input
 *     id="favorite-band"
 *     error={favoriteBand !== "Megadeth"}
 *     placeholder="It had better be Megadeth"
 *     value={favoriteBand}
 *   />
 *   What's your favorite band?
 * </Label>
 * ```
 */
/**
 * @deprecated Use component from design/forms instead
 */
export const Input = styled.input<{
  /**
   * Whether or not to render the component in an error state.
   */
  error?: boolean;
  /**
   * Whether or not to render the component in a success state.
   */
  success?: boolean;
  /**
   * Whether or not to render the component in a warning state.
   */
  warning?: boolean;
}>`
  border: 1px solid
    ${({
      error = false,
      success = false,
      theme: { colors },
      warning = false,
    }) => {
      if (error) {
        return colors.danger;
      }
      if (warning) {
        return colors.warning;
      }
      if (success) {
        return colors.success;
      }
      return colors.lightGray;
    }};
  border-radius: 3px;
  box-shadow: none;
  padding: 10px;
  width: 100%;

  &:focus {
    border-color: ${({
      error = false,
      success = false,
      theme: { colors },
      warning = false,
    }) => {
      if (error) {
        return colors.danger;
      }
      if (warning) {
        return colors.warning;
      }
      if (success) {
        return colors.success;
      }
      return colors.primary;
    }};
    outline: 0;
  }

  &:disabled {
    background: ${({ theme: { colors } }) => colors.disabledGray};
  }
`;
