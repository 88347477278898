import { useMutation } from "@apollo/client";

import { AssigneeSelect } from "@@components/AssigneeSelect";
import { UpdateBenefitsVerificationMutation } from "../../../../generated/graphql";
import { UpdateBenefitsVerification } from "../graphql/UpdateBenefitsVerification.gql";

interface IBenefitVerificationAssigneeSelectProps {
  benefitsVerificationId: string;
  requestedById?: string | null;
}

export const BenefitsVerificationAssigneeSelect: React.FC<
  IBenefitVerificationAssigneeSelectProps
> = ({ benefitsVerificationId, requestedById }) => {
  const [updateBenefitsVerification] =
    useMutation<UpdateBenefitsVerificationMutation>(UpdateBenefitsVerification);
  return (
    <AssigneeSelect
      defaultValue={requestedById == null ? null : parseInt(requestedById, 10)}
      onChange={async (accountId) =>
        updateBenefitsVerification({
          variables: {
            id: benefitsVerificationId,
            patch: {
              RequestedById: accountId,
            },
          },
        })
      }
    />
  );
};
