import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  Stack,
} from "@samacare/design/core";
import GridQuickFilter from "../../../components/DataGrid/GridQuickFilter";
import { useTheme } from "styled-components";

interface PatientsGridToolbarProps {
  onFilterChange?: (filterValue: string) => void;
  searchWord?: string;
}

export const PatientsGridToolbar: React.VFC<PatientsGridToolbarProps> = ({
  onFilterChange,
  searchWord,
}) => {
  const theme = useTheme();
  const buttonSx = { color: theme.primary };
  return (
    <Stack
      justifyContent="space-between"
      p={1}
      direction="row"
      alignItems="center"
    >
      <GridQuickFilter
        onFilterChange={onFilterChange}
        searchWord={searchWord}
      />
      <Stack direction="row" alignItems="center" spacing={1}>
        <GridToolbarContainer>
          <GridToolbarColumnsButton sx={buttonSx} />
          <GridToolbarDensitySelector sx={buttonSx} />
        </GridToolbarContainer>
      </Stack>
    </Stack>
  );
};
