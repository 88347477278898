import { useEffect, useState } from "react";
import _ from "lodash";
import { NpiCaptureSource, Location } from "@samacare/graphql";
import { useUpsertLocation } from "@samacare/hooks-data";
import { DialogContent, Stack, Typography } from "@samacare/design";
import { DialogFooterWithButtons, DialogHeader } from "@samacare/component";
import {
  FieldError,
  FormProvider,
  StateField,
  TextField,
  TinField,
  ZipField,
  useForm,
} from "@samacare/form2";
import { useTheme } from "@samacare/design/core/styles";
import Chip from "@samacare/design/core/Chip";
import IntlPhoneField from "../IntlPhoneField";

const HelperLabel: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="caption"
      color={theme.palette.grey[600]}
      sx={{ textAlign: "left", pl: "14px" }}
    >
      {children}
    </Typography>
  );
};

interface LocationEditPaneProps {
  location: Location | null;
  isFoundExisting: boolean;
  onClose: (location?: Location | null) => void;
  onBack: () => void;
  onAlert?: (message: string) => void;
  tinRequired?: boolean;
}
export const LocationEditPane: React.FC<LocationEditPaneProps> = (props) => {
  const { location, tinRequired } = props;

  const [initialLocation, setInitialLocation] = useState<Location | null>(null);

  const upsertLocation = useUpsertLocation();

  const methods = useForm<Location>({
    reValidateMode: "onChange",
    defaultValues: {
      zip: location?.zip ?? "",
      taxId: location?.taxId ?? "",
    },
  });

  const { errors } = methods.formState;
  const tinError = _.get(errors, "taxId") as FieldError;

  const onSave = async (l: Location) => {
    try {
      const loc = await upsertLocation(l);
      props.onClose(loc);
    } catch (error) {
      if (error instanceof Error) {
        props.onAlert?.(error.message);
      }
    }
  };

  useEffect(() => {
    methods.reset(location ?? {});
    if (location) {
      setInitialLocation({ ...location });
    }
  }, [location, methods]);

  const isEdit = !!location?.id;
  const showBack = !isEdit || props.isFoundExisting;

  const disabledSourceIsRegistry = location?.source === NpiCaptureSource.Api;

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <DialogHeader
        title={`${isEdit ? "Edit" : "Add"} Location`}
        onClose={() => props.onClose()}
      >
        {props.isFoundExisting && (
          <Chip label="Already Existed" sx={{ ml: 2 }} size="small" />
        )}
      </DialogHeader>
      <FormProvider {...methods}>
        <form style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          >
            <Stack direction="column" spacing={2} p={2}>
              <TextField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                fullWidth
                required
                label="Location Name"
                name="facilityName"
                disabled={
                  disabledSourceIsRegistry && !!initialLocation?.facilityName
                }
              />

              <Stack spacing={0.5}>
                <TextField fullWidth label="Nickname" name="nickname" />
                <HelperLabel>
                  Use nicknames to easily find locations later
                </HelperLabel>
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextField fullWidth required label="NPI" name="NPI" disabled />
                <TextField fullWidth label="PTAN" name="PTAN" />
              </Stack>
              <TextField
                fullWidth
                label="Address"
                name="address"
                disabled={
                  disabledSourceIsRegistry && !!initialLocation?.address
                }
              />
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="City"
                  name="city"
                  disabled={disabledSourceIsRegistry && !!initialLocation?.city}
                />
                <StateField
                  fullWidth
                  label="State"
                  name="state"
                  disabled={
                    disabledSourceIsRegistry && !!initialLocation?.state
                  }
                />
                <ZipField
                  fullWidth
                  label="Zip Code"
                  name="zip"
                  disabled={disabledSourceIsRegistry && !!initialLocation?.zip}
                />
              </Stack>
              <IntlPhoneField
                fullWidth
                label="Fax"
                name="fax"
                disabled={disabledSourceIsRegistry && !!initialLocation?.fax}
              />
              <Stack spacing={0.5}>
                <TinField name="taxId" required={tinRequired} />
                {!tinError && (
                  <HelperLabel>
                    {tinRequired
                      ? "TIN required to track Prior Authorizations"
                      : "Enter Tax Identification Number (TIN) for seamless tracking of Prior Authorizations"}
                  </HelperLabel>
                )}
              </Stack>
            </Stack>
          </DialogContent>
          <DialogFooterWithButtons
            cancelText={showBack ? "Back" : "Close"}
            onCancel={showBack ? props.onBack : () => props.onClose(location)}
            confirmText={isEdit ? `Update Location` : `Add Location`}
            onConfirm={methods.handleSubmit(onSave)}
          />
        </form>
      </FormProvider>
    </Stack>
  );
};
