import * as React from "react";
import Chip from "@samacare/design/core/Chip";
import Autocomplete from "@samacare/design/core/Autocomplete";
import TextField from "@samacare/design/core/TextField";
import { useFormById } from "../../../../hooks/useFormById";
import { useGlobalTags } from "../../../../hooks/useTags";
import { gql, useMutation } from "@apollo/client";
import {
  Mutation,
  MutationUpdateAuthorizationFormTagsArgs,
} from "@samacare/graphql";
import authorizationFormInfo from "../../../../graphql/fragments/authorizationFormInfo";

type TaggingControlProps = {
  tags: string[];
  value: string[];
  onChange: (tags: string[]) => void;
};

export const TaggingControl: React.FC<TaggingControlProps> = ({
  tags,
  value,
  onChange,
}) => {
  return (
    <Autocomplete
      multiple
      options={tags}
      onChange={(_, v) => onChange(v)}
      value={value}
      freeSolo
      renderTags={(v: readonly string[], getTagProps) =>
        v.map((option: string, index: number) => (
          // eslint-disable-next-line react/jsx-key
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      sx={{ width: 400 }}
      renderInput={(params) => (
        <TextField {...params} label="Tags" placeholder="Add tags" />
      )}
    />
  );
};

const updateAuthorizationFormsTagsMutation = gql`
  mutation updateAuthorizationFormTags(
    $authorizationFormId: Int!
    $tags: [String!]!
  ) {
    updateAuthorizationFormTags(
      authorizationFormId: $authorizationFormId
      tags: $tags
    ) {
      ...authorizationFormInfo
    }
  }
  ${authorizationFormInfo}
`;

export const AuthorizationFormTaggingControl: React.FC<{
  authorizationFormId: number;
}> = ({ authorizationFormId }) => {
  const { form } = useFormById(authorizationFormId);
  const { tags } = useGlobalTags();
  const [updateAuthorizationFormTags] = useMutation<
    Mutation,
    MutationUpdateAuthorizationFormTagsArgs
  >(updateAuthorizationFormsTagsMutation);

  const doUpdate = async (updateTags: string[]) => {
    await updateAuthorizationFormTags({
      variables: {
        authorizationFormId,
        tags: updateTags,
      },
    });
  };

  return (
    <TaggingControl
      tags={tags ?? []}
      // This is a hack.. AuthorizationForm.js types arent working properly, they prob need to be migrated to typescript
      value={form ? (form as unknown as { tags: string[] }).tags : []}
      onChange={doUpdate}
    />
  );
};
