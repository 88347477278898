import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, array } from "yup";
import _ from "lodash";
import moment from "moment";
import { useAlert } from "react-alert";
import { useConfig } from "@@hooks/config";
import { Box, Button } from "@samacare/design/core";
import { Authorization, ServiceCode } from "@samacare/graphql";
import {
  useForm,
  PatientBlock,
  IcdField,
  AutocompleteField,
  DateField,
  ServiceCodeDataWrapper,
  TextField,
  Resolver,
  PaProviderBlock,
  PaLocationBlock,
  FormProvider,
} from "@samacare/form";
import { EnrollmentSection } from "../../../routes/Enrollment/EnrollmentSection";
import useInsuranceCompanyOptions, {
  InsuranceCompanyOption,
} from "@samacare/form/hooks/useInsuranceCompanyOptions";
import { useUpdateAuthorization } from "../../../graphql/Authorization";
import {
  useSetPatientOnAuth,
  useRemovePatientOnAuth,
} from "../../../graphql/Patient";
import { isValidDate } from "../../../util/dateUtils";
import { useApolloClient } from "@apollo/client";
import { HeaderTitle } from "../../../constants/HeaderTitle";

type DetailsTabSchema = {
  patient: {
    firstName: string;
    lastName: string;
    dob: string;
  };
  PatientId: string;
  selectedInsuranceCompany?: InsuranceCompanyOption;
  memberId: string;
  portalAuthorizationId?: string;
  startDate: string;
  endDate: string;
  hcpcsCodes: ServiceCode[];
  ICDs: { code: string; description?: string }[];
  prescriber?: {
    id: string;
    DEA: string;
    NPI: string;
    TIN: string;
    firstName: string;
    lastName: string;
    label: string;
    licenseNumber: string;
    PrescriberId: string;
    specialtyCode: string;
    specialtyDescription: string;
  };
  location?: {
    id?: string;
    nickname?: string;
    facilityName?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    taxId?: string;
    NPI?: string;
    fax?: string;
  };
  LocationId: string;
};

const schema = object<DetailsTabSchema>({
  patient: object({
    firstName: string().required(),
    lastName: string().required(),
    dob: string().required(),
  }),
  PatientId: string(),
  selectedInsuranceCompany: object({
    id: string().optional(),
    label: string().optional(),
  }).optional(),
  memberId: string(),
  portalAuthorizationId: string(),
  startDate: string(),
  endDate: string(),
  hcpcsCodes: array(),
  prescriber: object({
    NPI: string().required(),
    TIN: string().nullable(),
    firstName: string().required(),
    lastName: string().required(),
    specialtyCode: string().nullable(),
    specialtyDescription: string(),
  }),
  location: object({
    id: string(),
    nickname: string().nullable(),
    facilityName: string(),
    address: string().nullable(),
    city: string().nullable(),
    state: string().nullable(),
    zip: string().nullable(),
    taxId: string(),
    NPI: string(),
    fax: string().nullable(),
  }),
  LocationId: string().required({ message: "Servicing Location is required" }),
  PrescriberId: string().required({
    message: "Servicing Provider is required",
  }),
});

const mapAuthFieldsToForm = (authorization?: Authorization | null) => {
  if (!authorization) return {};
  return {
    patient: {
      firstName: authorization?.patient?.firstName || "",
      lastName: authorization?.patient?.lastName || "",
      dob: authorization?.patient?.dob || "",
    },
    PatientId: authorization?.patient?.id || "",
    selectedInsuranceCompany: {
      id:
        authorization?.insuranceCompany?.id ||
        authorization?.patient?.primaryInsurance?.insuranceCompany?.id ||
        "",
      label:
        authorization?.insuranceCompany?.name ||
        authorization?.patient?.primaryInsurance?.insuranceCompany?.name ||
        "",
    },
    memberId:
      authorization?.config?.PATIENT_MEMBER_ID ||
      authorization?.patient?.primaryInsurance?.memberId ||
      "",
    portalAuthorizationId:
      (authorization?.portalAuthorizationId as string) || "",
    startDate: (authorization?.config?.DATE_OF_SERVICE as string) || "",
    endDate: (authorization?.config?.END_DATE_OF_SERVICE as string) || "",
    hcpcsCodes: authorization?.HCPCSCodes || [],
    ICDs: _.map(authorization?.ICDs, (icd: string, index: number) => {
      return {
        code: icd,
        description:
          // eslint-disable-next-line prefer-template
          authorization?.config[`ICD_${index}`] +
          ": " +
          authorization?.config[`ICD_DESCRIPTION_${index}`],
      };
    }) as unknown as { code: string; description: string }[],
    prescriber: {
      id: authorization?.formDetails?.prescriberId || "",
      NPI: (authorization?.config?.PRESCRIBER_NPI as string) || "",
      TIN: (authorization?.config?.PRESCRIBER_TIN as string) || "",
      firstName: (authorization?.config?.PRESCRIBER_FIRST_NAME as string) || "",
      lastName: (authorization?.config?.PRESCRIBER_LAST_NAME as string) || "",
      specialtyCode:
        (authorization?.config?.PRESCRIBER_SPECIALTY_CODE as string) || "",
      specialtyDescription:
        (authorization?.config?.PRESCRIBER_SPECIALTY as string) || "",
      licenseNumber:
        (authorization?.config?.PRESCRIBER_LICENSE_NUMBER as string) || "",
      DEA: (authorization?.config?.PRESCRIBER_DEA_NUMBER as string) || "",
      label: (authorization?.config?.PRESCRIBER_LABEL as string) || "",
    },
    location: {
      id: authorization?.formDetails?.locationId || "",
      facilityName: (authorization?.config?.FACILITY_NAME as string) || "",
      nickname: (authorization?.config?.LOCATION_NAME as string) || "",
      address: (authorization?.config?.PRESCRIBER_ADDRESS as string) || "",
      city: (authorization?.config?.PRESCRIBER_CITY as string) || "",
      state: (authorization?.config?.PRESCRIBER_STATE as string) || "",
      zip: (authorization?.config?.PRESCRIBER_ZIP as string) || "",
      taxId: (authorization?.config?.PRESCRIBER_OFFICE_TIN as string) || "",
      NPI: (authorization?.config?.PRESCRIBER_OFFICE_NPI as string) || "",
      fax: (authorization?.config?.PRESCRIBER_OFFICE_FAX as string) || "",
    },
    LocationId: authorization?.formDetails?.locationId || "",
    PrescriberId: authorization?.formDetails?.prescriberId || "",
  };
};

export const DetailsTab = ({
  authorization,
}: {
  authorization: Authorization;
}) => {
  const { insuranceCompanies, loading: insuranceCompaniesLoading } =
    useInsuranceCompanyOptions();

  const [updateAuthorization] = useUpdateAuthorization();
  const [setPatient] = useSetPatientOnAuth();
  const [removePatient] = useRemovePatientOnAuth();
  const alert = useAlert();
  const apolloClient = useApolloClient();

  const { DEFAULT_FIELDS } = useConfig();

  const defaultValues = mapAuthFieldsToForm(authorization);

  const methods = useForm<DetailsTabSchema>({
    resolver: yupResolver(schema) as Resolver<DetailsTabSchema, any>,
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    watch,
    formState: { isDirty },
    setValue,
    getValues,
    reset,
  } = methods;

  const updateAuth = _.debounce(async () => {
    try {
      const formValues = getValues();

      const withUpdatedPatient = formValues.PatientId
        ? await setPatient({
            variables: {
              patientId: parseInt(formValues.PatientId),
              authorizationId: parseInt(authorization.id),
            },
          })
        : await removePatient({
            variables: { authorizationId: parseInt(authorization.id) },
          });

      const hcpcsCodes = _.map(
        formValues.hcpcsCodes,
        ({ code, drugName, drugOptionId }) => ({
          code,
          drugName,
          drugOptionId,
          quantity: 1,
        })
      );
      const IDCCodes = formValues.ICDs;

      const { data } = await updateAuthorization({
        variables: {
          id: parseInt(authorization.id),
          patch: {
            ...(withUpdatedPatient?.data?.setPatientOnAuth ?? {}),
            config: {
              ...authorization.config,
              [DEFAULT_FIELDS.PATIENT_MEMBER_ID.key]: formValues.memberId,
              [DEFAULT_FIELDS.INSURANCE_COMPANY.key]:
                formValues.selectedInsuranceCompany?.label,
              //adding validation for valid date or date should be empty
              [DEFAULT_FIELDS.DATE_OF_SERVICE.key]: isValidDate(
                formValues.startDate
              )
                ? moment(formValues.startDate).format("MM/DD/YYYY")
                : "",
              //adding validation for valid date or date should be empty
              [DEFAULT_FIELDS.END_DATE_OF_SERVICE.key]: isValidDate(
                formValues.endDate
              )
                ? moment(formValues.endDate).format("MM/DD/YYYY")
                : "",
              [DEFAULT_FIELDS.REQUEST_DESCRIPTION.key]: hcpcsCodes[0]?.drugName,
              // Prescriber
              [DEFAULT_FIELDS.PRESCRIBER_NPI.key]: formValues.prescriber?.NPI,
              [DEFAULT_FIELDS.PRESCRIBER_TIN.key]: formValues.prescriber?.TIN,
              [DEFAULT_FIELDS.PRESCRIBER_DEA_NUMBER.key]:
                formValues.prescriber?.DEA,
              [DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME.key]:
                formValues.prescriber?.firstName,
              [DEFAULT_FIELDS.PRESCRIBER_LAST_NAME.key]:
                formValues.prescriber?.lastName,
              [DEFAULT_FIELDS.PRESCRIBER_SPECIALTY_CODE.key]:
                formValues.prescriber?.specialtyCode,
              [DEFAULT_FIELDS.PRESCRIBER_SPECIALTY.key]:
                formValues.prescriber?.specialtyDescription,
              [DEFAULT_FIELDS.PRESCRIBER_LABEL.key]:
                formValues.prescriber?.label,
              // Location
              [DEFAULT_FIELDS.FACILITY_NAME.key]:
                formValues.location?.facilityName ?? null,
              [DEFAULT_FIELDS.LOCATION_NAME.key]:
                formValues.location?.nickname ?? null,
              [DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key]:
                formValues.location?.address ?? null,
              [DEFAULT_FIELDS.PRESCRIBER_CITY.key]:
                formValues.location?.city ?? null,
              [DEFAULT_FIELDS.PRESCRIBER_STATE.key]:
                formValues.location?.state ?? null,
              [DEFAULT_FIELDS.PRESCRIBER_ZIP.key]:
                formValues.location?.zip ?? null,
              [DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key]:
                formValues.location?.taxId ?? null,
              [DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key]:
                formValues.location?.NPI ?? null,
              [DEFAULT_FIELDS.PRESCRIBER_OFFICE_FAX.key]:
                formValues.location?.fax ?? null,
              HCPCS_CODES: _.map(hcpcsCodes, (hc) => hc.code).join(", "),
              [DEFAULT_FIELDS.HCPCS_0.key]: hcpcsCodes[0]?.code,
              HCPCS_1: hcpcsCodes[1]?.code,
              HCPCS_2: hcpcsCodes[2]?.code,
              HCPCS_3: hcpcsCodes[3]?.code,
              HCPCS_4: hcpcsCodes[4]?.code,
              HCPCS_5: hcpcsCodes[5]?.code,
              ICD_CODES: _.map(IDCCodes, (icd) => icd.code).join(", "),
              [DEFAULT_FIELDS.ICD_0.key]: IDCCodes[0]?.code,
              ICD_1: IDCCodes[1]?.code,
              ICD_2: IDCCodes[2]?.code,
              ICD_3: IDCCodes[3]?.code,
              ICD_4: IDCCodes[4]?.code,
              ICD_5: IDCCodes[5]?.code,
              ICD_6: IDCCodes[6]?.code,
              ICD_7: IDCCodes[7]?.code,
              ICD_8: IDCCodes[8]?.code,
              ICD_9: IDCCodes[9]?.code,
              ICD_10: IDCCodes[10]?.code,
              ICD_DESCRIPTION_1: IDCCodes[1]?.description,
              ICD_DESCRIPTION_2: IDCCodes[2]?.description,
              ICD_DESCRIPTION_3: IDCCodes[3]?.description,
              ICD_DESCRIPTION_4: IDCCodes[4]?.description,
              ICD_DESCRIPTION_5: IDCCodes[5]?.description,
              ICD_DESCRIPTION_6: IDCCodes[6]?.description,
              ICD_DESCRIPTION_7: IDCCodes[7]?.description,
              ICD_DESCRIPTION_8: IDCCodes[8]?.description,
              ICD_DESCRIPTION_9: IDCCodes[9]?.description,
              ICD_DESCRIPTION_10: IDCCodes[10]?.description,
            },
            HCPCSCodes: hcpcsCodes,
            DrugOptionId: hcpcsCodes.find((code) => code.drugOptionId)
              ?.drugOptionId,
            ICDs: IDCCodes.map((icd) => icd.code),
            InsuranceCompanyId: formValues.selectedInsuranceCompany?.id || null,
            portalAuthorizationId: formValues.portalAuthorizationId,
            formDetails: {
              prescriberId: formValues.prescriber?.id,
              locationId: formValues.location?.id,
            },
          },
        },
      });

      reset(mapAuthFieldsToForm(data?.updateAuthorizationById));

      alert.success(`Prior Authorization Updated`);
    } catch (e) {
      alert.error(
        `FAILED TO SAVE AUTHORIZATION CHANGES, IF THIS PERSISTS, PLEASE CONTACT SAMACARE`
      );
    }
  }, 1000);

  return (
    <Box maxWidth={600}>
      <FormProvider {...methods}>
        <form>
          <EnrollmentSection title="Patient">
            <PatientBlock
              onSelected={async (patientId, patient) => {
                const insurance = _.find(
                  insuranceCompanies,
                  (ic) => ic.id === patient.primaryInsurance?.InsuranceCompanyId
                );

                setValue("memberId", patient.primaryInsurance?.memberId ?? "", {
                  shouldDirty: true,
                });

                reset(
                  {
                    ...getValues(),
                    memberId: patient.primaryInsurance?.memberId ?? "",
                    selectedInsuranceCompany: {
                      id: patient.primaryInsurance?.InsuranceCompanyId ?? "",
                      label: insurance?.label ?? "",
                    },
                  },
                  { keepDirty: true }
                );
              }}
              onClear={() => {
                setValue("memberId", "", { shouldDirty: true });
                reset(
                  {
                    ...getValues(),
                    memberId: "",
                    selectedInsuranceCompany: {
                      id: "",
                      label: "",
                    },
                  },
                  { keepDirty: true }
                );
              }}
            />
          </EnrollmentSection>
          <EnrollmentSection title="Insurance">
            <AutocompleteField<InsuranceCompanyOption, false, true, false>
              options={_.reject(insuranceCompanies ?? [], "isArchived")}
              label="Payer"
              name="selectedInsuranceCompany"
              loading={insuranceCompaniesLoading}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              value={watch("selectedInsuranceCompany")}
              onChange={(_e, change) => {
                setValue("selectedInsuranceCompany", change, {
                  shouldTouch: true,
                });

                setValue("memberId", "");
              }}
            />
            <TextField
              sx={{ mt: 2 }}
              label="Member ID"
              name="memberId"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </EnrollmentSection>
          <EnrollmentSection title={HeaderTitle.ServicingProvider}>
            <PaProviderBlock
              required
              label={HeaderTitle.ServicingProvider}
              onAlert={(message) => alert.error(message)}
            />
          </EnrollmentSection>
          <EnrollmentSection title={HeaderTitle.ServicingLocation}>
            <PaLocationBlock
              required
              label={HeaderTitle.ServicingLocation}
              onAlert={(message) => alert.error(message)}
              tinRequired
            />
          </EnrollmentSection>
          <EnrollmentSection title="Authorization Info">
            <TextField
              label="Authorization Reference ID"
              name="portalAuthorizationId"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <Box mt={2} display="flex">
              <DateField
                name="startDate"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                sx={{ mr: 2, flexGrow: 1 }}
              />
              <DateField
                name="endDate"
                label="End Date"
                InputLabelProps={{ shrink: true }}
                sx={{ flexGrow: 1 }}
              />
            </Box>
          </EnrollmentSection>
          <EnrollmentSection title="ICD-10 Codes">
            <IcdField name="ICDs" />
          </EnrollmentSection>
          <EnrollmentSection title="Service Info">
            <ServiceCodeDataWrapper
              name="hcpcsCodes"
              drugOptionRequired={false}
              apolloClient={apolloClient}
            />
          </EnrollmentSection>
        </form>
      </FormProvider>
      <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
        <Button
          disabled={!isDirty}
          variant="text"
          sx={{ mr: 2 }}
          onClick={() => reset()}
        >
          Cancel
        </Button>
        <Button
          disabled={!isDirty}
          variant="contained"
          onClick={methods.handleSubmit(updateAuth)}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
