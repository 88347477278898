import { useQuery } from "@apollo/client";

import React, { useEffect, useMemo } from "react";
import {
  Stack,
  Typography,
  Box,
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
  GridRenderCellParams,
} from "@samacare/design/core";
import { Patient } from "@samacare/graphql";
import { PatientsQuery } from "@@generated/graphql";
import { allPatientsQuery } from "../../../graphql/Patient";
import { format as formatDate, parseISO } from "date-fns";
import { PatientsGridToolbar } from "./PatientsGridToolbar";
import { DeletePatientIconButton } from "./DeletePatientIconButton";
import { useConfig, useFeatureFlag } from "../../../hooks";

function PatientTableNoResults() {
  return (
    <Box display="flex" justifyContent="center" mt={3}>
      <Typography variant="body">No patients have been added.</Typography>
    </Box>
  );
}

const PatientsTable: React.FC = () => {
  const config = useConfig();
  const [page, setPage] = React.useState(0);
  const [searchWord, setSearchWord] = React.useState("");
  const [pageSize, setPageSize] = React.useState(25);
  // intially sort by updatedAt
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const isPatientDeleteButtonEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.PatientDeleteButton
  );

  const { data, loading, fetchMore } = useQuery<PatientsQuery>(
    allPatientsQuery,
    {
      variables: { limit: pageSize, offset: 0, searchWord, sortModel },
    }
  );
  const totalCount = data?.patientsFindAll?.count ?? 0;
  const patients = (data?.patientsFindAll?.rows as Patient[]) ?? [];
  const [rows, setRows] = React.useState<Patient[]>(patients);

  // in case data may not be available upon first render
  React.useEffect(() => {
    setRows((data?.patientsFindAll?.rows as Patient[]) ?? []);
  }, [data]);

  const gridColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "institutionPatientId",
        headerName: "MRN/Patient Id",
      },
      { field: "lastName", headerName: "Last Name" },
      { field: "firstName", headerName: "First Name" },
      { field: "dob", headerName: "DOB" },
      { field: "gender", headerName: "Gender" },
      { field: "address", headerName: "Address" },
      { field: "city", headerName: "City" },
      { field: "state", headerName: "State" },
      { field: "zip", headerName: "Zip" },
      { field: "phone", headerName: "Phone" },
      {
        field: "primaryInsurance",
        headerName: "Primary Insurance Member ID",
        valueGetter: (params) => params.row.primaryInsurance?.memberId,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        valueFormatter: (params) =>
          formatDate(parseISO(params.value), "MM/dd/yyyy hh:mm:ss a"),
      },
      {
        field: "updatedAt",
        headerName: "Updated At",
        valueFormatter: (params) =>
          formatDate(parseISO(params.value), "MM/dd/yyyy hh:mm:ss a"),
      },
      {
        field: "buttons",
        headerName: "",
        hideable: false,
        filterable: false,
        sortable: false,
        width: 60,
        renderCell: (props: GridRenderCellParams) => (
          <Stack direction="row" width="100%" justifyContent="flex-end">
            {isPatientDeleteButtonEnabled && (
              <DeletePatientIconButton patient={props.row as Patient} />
            )}
          </Stack>
        ),
      },
    ],
    [isPatientDeleteButtonEnabled]
  );

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    if (page !== newModel.page) {
      setPage(newModel.page);
    }
    if (newModel.pageSize !== pageSize) {
      setPageSize(newModel.pageSize);
      setPage(0);
    }
  };

  const handleSearchWordChange = (model: GridFilterModel) => {
    setSearchWord(model?.quickFilterValues?.[0] ?? "");
  };

  const handleSortChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  useEffect(() => {
    const fetchData = () => {
      const newOffset = page * pageSize;

      void fetchMore({
        variables: { offset: newOffset },
      }).then((result) => {
        const newData = result.data?.patientsFindAll?.rows ?? [];
        if (newData.length > 0) {
          setRows(newData as Patient[]);
        }
      });
    };
    fetchData();
  }, [page, fetchMore]);

  const showList = !loading;

  return (
    <Box>
      {showList && (
        <DataGridPro
          rows={rows}
          columns={gridColumns}
          rowCount={totalCount}
          autosizeOnMount
          autosizeOptions={{ expand: true, includeHeaders: true }}
          slots={{
            toolbar: () => <PatientsGridToolbar searchWord={searchWord} />,
            noRowsOverlay: PatientTableNoResults,
          }}
          paginationMode="server"
          filterMode="server"
          sortingMode="server"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize,
              },
            },
            sorting: { sortModel },
          }}
          onSortModelChange={handleSortChange}
          onFilterModelChange={handleSearchWordChange}
          onPaginationModelChange={handlePaginationModelChange}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          sx={{ border: 0, mt: 2 }}
        />
      )}
    </Box>
  );
};

export default PatientsTable;
