import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

const sendSecureFile = gql`
  mutation sendSecureFile(
    $awsKey: String!
    $subject: String!
    $sendTo: String!
    $message: String
  ) {
    sendSecureFile(
      awsKey: $awsKey
      message: $message
      subject: $subject
      sendTo: $sendTo
    )
  }
`;

export const withSendSecureFile = graphql(sendSecureFile, {
  name: "sendSecureFile",
});
