import { Typography, Stack } from "@samacare/design/core";
import Error from "@samacare/design/core/icons/Error";

export const ErrorStatus = ({ theme }: { theme: any }) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Typography variant="body2" sx={{ display: "inline" }}>
        <strong>Coverage Status:</strong>
      </Typography>
      <Error sx={{ color: theme.palette.error.light }} />
      <Typography variant="body2" sx={{ display: "inline" }}>
        Availity Error - update patient info to try again
      </Typography>
    </Stack>
  );
};
