import * as React from "react";
import styled from "styled-components";

export type LabelProps = React.ComponentProps<typeof Label>;

/**
 * Label is a component that represents a caption for an item in a UI.
 *
 * ## When to use:
 *
 * When you're annotating an element with some text, most commonly in a form.
 * Using a label has various advantages over e.g. a `span`; labels are easier to
 * write automated tests against, will focus their associated input when clicked,
 * offer better accessibility, etc.
 *
 * @example
 * ```tsx
 * import { Input, Label } from "@@ui-kit/forms";
 *
 * // ...
 *
 * <Label htmlFor="favorite-band">
 *   <Input
 *     id="favorite-band"
 *     error={favoriteBand !== "Megadeth"}
 *     placeholder="It had better be Megadeth"
 *     value={favoriteBand}
 *   />
 *   What's your favorite band?
 * </Label>
 * ```
 */
/**
 * @deprecated Use component from design/forms instead
 */
export const Label = styled.label``;
