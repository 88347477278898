import { useQuery } from "@apollo/client";
import getAwsKeysForSkippedRows from "../graphql/GetAwsKeysForSkippedRows.gql";
import { GetAwsKeysForSkippedRowsQuery } from "@@generated/graphql";

export const useAwsKeysForSkippedRows = (ingestJobId: number) => {
  const { data, loading } = useQuery<GetAwsKeysForSkippedRowsQuery>(
    getAwsKeysForSkippedRows,
    { variables: { ingestJobId } }
  );
  return { awsKeys: data?.getAwsKeysForSkippedRows ?? null, loading };
};
