import { Alert, Typography, Link } from "@samacare/design/core";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { UnifiedBenefitsCheckType } from "@samacare/graphql";

export const FullBvNotificationAlert = ({
  patientId,
  drugOptionId,
}: {
  patientId?: string;
  drugOptionId?: string;
}) => {
  if (!patientId) {
    return null;
  }

  return (
    <Alert severity="info" sx={{ width: "600px" }}>
      SamaCare supports Full Benefit Verification for this service.
      <Typography>
        If you want patient coverage check only,{" "}
        <Link
          href={`${window.location.origin}/#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/create?patientId=${patientId}&type=${UnifiedBenefitsCheckType.Lite}&drugOptionId=${drugOptionId}`}
        >
          click here
        </Link>
      </Typography>
    </Alert>
  );
};
