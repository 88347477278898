import { useQuery } from "@apollo/client";
import getLatestIngestJobQuery from "../graphql/GetLatestIngestJobQuery.gql";
import { IngestJob } from "@samacare/graphql";
import { GetLatestIngestJobQueryQuery } from "@@generated/graphql";

export const useLatestIngestJob = () => {
  const { data, loading } = useQuery<GetLatestIngestJobQueryQuery>(
    getLatestIngestJobQuery,
    {
      fetchPolicy: "network-only",
    }
  );
  return {
    ingestJob: (data?.getLatestIngestJob as IngestJob) ?? null,
    loading,
  };
};
