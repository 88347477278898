import { ApolloClient, ApolloProvider } from "@apollo/client";
import { useLocations } from "@samacare/hooks-data";
import {
  BaseLocationAutocomplete,
  BaseLocationAutocompleteProps,
} from "./BaseLocationAutocomplete";

type LocationAutocompleteWithDataProps = Omit<
  BaseLocationAutocompleteProps,
  "locations"
>;
const LocationAutocompleteWithData: React.FC<
  LocationAutocompleteWithDataProps
> = (props) => {
  const { locations } = useLocations();

  return <BaseLocationAutocomplete locations={locations} {...props} />;
};

export type LocationAutocompleteProps = LocationAutocompleteWithDataProps & {
  apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
};
export const LocationAutocomplete: React.FC<LocationAutocompleteProps> = (
  props,
) => {
  const { apolloClient, ...otherProps } = props;

  return (
    <ApolloProvider client={apolloClient as ApolloClient<object>}>
      <LocationAutocompleteWithData {...otherProps} />
    </ApolloProvider>
  );
};
