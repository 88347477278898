import moment from "moment";
import { Box } from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import { Authorization, TrackingStatus } from "@samacare/graphql";

import { isTrackedPortalAuth } from "../../util/authUtils";
import { useConfig } from "../../hooks";

interface LastCheckedProps {
  authorization: Authorization;
}
export const LastChecked: React.FC<LastCheckedProps> = (props) => {
  const { authorization } = props;
  const theme = useTheme();
  const config = useConfig();

  const isTerminal = config.CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES.includes(
    authorization.status
  );
  const isSuccessfullyTracked =
    authorization.trackingStatus === TrackingStatus.CheckedByTracker;

  return isTrackedPortalAuth(authorization) &&
    (isTerminal || isSuccessfullyTracked) &&
    authorization.lastCheckedAt != null ? (
    <Box sx={{ fontSize: "12px", color: theme.palette.text.secondary }}>
      <Box>Status Last Checked:</Box>
      <Box>{moment(authorization.lastCheckedAt).format("MM/DD, h a")}</Box>
    </Box>
  ) : null;
};
