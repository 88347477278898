import gql from "graphql-tag";
import { useCallback } from "react";
import * as React from "react";
import BaseSelect from "react-select";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import {
  MultiInstitutionsFindAllQuery,
  MultiInstitutionsFindAllQueryVariables,
} from "@@generated/graphql";
import colors from "Resources/colors";

const institutionFindAll = gql`
  query multiInstitutionsFindAll {
    institutionsFindAll {
      id
      name
    }
  }
`;

const InstitutionPill = styled.div`
  width: 100%;
  margin: 2px;
  padding: 2px 4px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const MultiInstitutionSelector: React.VoidFunctionComponent<{
  upsertAccountForSamaUsers: (allowedInstitutionIds: string[]) => void;
  allowedInstitutionIds: string[];
  currentInstitutionId: number;
}> = ({
  upsertAccountForSamaUsers,
  allowedInstitutionIds,
  currentInstitutionId,
}) => {
  const { data, error, loading } = useQuery<
    MultiInstitutionsFindAllQuery,
    MultiInstitutionsFindAllQueryVariables
  >(institutionFindAll);

  const removeInstitution = useCallback(
    (id: string) => {
      upsertAccountForSamaUsers(
        [currentInstitutionId.toString()].concat(
          allowedInstitutionIds.filter((institutionId) => institutionId !== id)
        )
      );
    },
    [upsertAccountForSamaUsers, currentInstitutionId, allowedInstitutionIds]
  );

  const addInstitution = useCallback(
    (
      option: {
        value: string;
        label: string;
      } | null
    ) => {
      if (option !== null) {
        upsertAccountForSamaUsers(
          [currentInstitutionId.toString()].concat([
            ...allowedInstitutionIds,
            option.value,
          ])
        );
      }
    },
    [upsertAccountForSamaUsers, currentInstitutionId, allowedInstitutionIds]
  );

  if (error) {
    return <div>Error Loading Institutions...</div>;
  }

  if (loading || data === undefined || data.institutionsFindAll == null) {
    return <div>Loading Institutions...</div>;
  }

  const institutions = data.institutionsFindAll;
  const mappedOptions = institutions
    .filter(({ id }) => !allowedInstitutionIds.includes(id))
    .map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));

  return (
    <div style={{ width: "100%" }}>
      {allowedInstitutionIds.map((id) => {
        return (
          <InstitutionPill
            key={`multi_institution_${id}`}
            style={{
              ...(currentInstitutionId === parseInt(id)
                ? { backgroundColor: colors.gray }
                : {}),
            }}
            onClick={() => {
              if (parseInt(id) !== currentInstitutionId) {
                removeInstitution(id);
              }
            }}
          >
            {institutions.find((institution) => institution.id === id)?.name}
            {currentInstitutionId !== parseInt(id) && (
              <div
                style={{
                  marginLeft: "auto",
                  color: colors.red,
                  fontWeight: "bold",
                }}
              >
                remove
              </div>
            )}
          </InstitutionPill>
        );
      })}
      <BaseSelect
        placeholder="Institutions"
        isLoading={loading}
        options={mappedOptions}
        isMulti={false}
        onChange={addInstitution}
      />
    </div>
  );
};
