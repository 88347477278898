import IconButton from "@samacare/design/core/IconButton";
import { TrackerConfig } from "@samacare/graphql";
import DeleteIcon from "@samacare/design/core/icons/Delete";
import { useConfirmDialog } from "../../../hooks/confirmDialogHook";
import { useDeleteTrackerConfig } from "../../../hooks/useDeleteTrackerConfig";

interface DeleteTrackerConfigIconButtonProps {
  trackerConfig: TrackerConfig;
}
export const DeleteTrackerConfigIconButton: React.VFC<
  DeleteTrackerConfigIconButtonProps
> = (props) => {
  const deleteTrackerConfig = useDeleteTrackerConfig();
  const [ConfirmDialog, confirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog({
      message: `Delete the tracker config for ${props.trackerConfig.trackerKey}?`,
      confirm: async () => deleteTrackerConfig(+props.trackerConfig.id),
      zIndexOffset: 1,
    });

  return (
    <>
      <IconButton onClick={() => setConfirmDialogOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <ConfirmDialog {...confirmDialogProps} />
    </>
  );
};
