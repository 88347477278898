import styled from "styled-components";

export type LinkProps = React.ComponentProps<typeof Link>;

/**
 * A Link component that wraps the standard `a` anchor tag.
 *
 * You should generally only use this component for external links. If you want
 * to redirect the user within the SamaCare web application, you should use the
 * RouterLink component.
 */
/**
 * @deprecated Use a MUI component instead
 */
export const Link = styled.a<{ textDecoration?: "none" | "underline" }>`
  color: ${({ theme: { colors } }) => colors.primary};
  text-decoration: ${({ textDecoration = "underline" }) => textDecoration};
`;
