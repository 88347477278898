import { compose } from "recompose";

import SignatureContainer from "./SignatureContainer";
import { withAddSignature } from "../../../graphql/Signature";
import { withFormById } from "../../../graphql/AuthorizationForm";

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withAddSignature, withFormById)(SignatureContainer);
