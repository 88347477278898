import gql from "graphql-tag";
import { prescriberInfo } from "../fragments/prescriberInfo";

export const upsertPrescriberMutation = gql`
  mutation upsertPrescriber($id: Int, $patch: JSON!) {
    upsertPrescriber(id: $id, patch: $patch) {
      ...prescriberInfo
    }
  }
  ${prescriberInfo}
`;
