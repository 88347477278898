import { Stack, Typography, Card, CardHeader, colors } from "@samacare/design";
import { alpha } from "@samacare/design/core/styles";
import {
  EnhancedServicesIcon,
  EnahanceServiceIndicatorSingular,
} from "../EnhancedServicesIndicator";

export const PaBvTriggerCard = ({
  children,
  drugName,
}: {
  children?: React.ReactNode;
  drugName: string;
}) => (
  <Card sx={{ mt: 1 }}>
    <CardHeader
      sx={{
        backgroundColor: alpha(colors.C500, 0.08),
      }}
      title={
        <Stack direction="row" alignItems="center">
          <Typography variant="body2" mr={1}>
            Drug Verification
          </Typography>
          <EnahanceServiceIndicatorSingular />
          <EnhancedServicesIcon sx={{ fontSize: 18 }} />
        </Stack>
      }
      subheader={`SamaCare can investigate payer requirements and financials for ${drugName}`}
      subheaderTypographyProps={{ variant: "body2" }}
    />
    {children}
  </Card>
);
