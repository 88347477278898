import _ from "lodash";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import {
  QueryGetPortalHubsArgs,
  Query,
  PortalToHubMapping,
} from "@samacare/graphql";
import { getPortalHubsQuery } from "../graphql/Authorization";
import { useConfig } from "./config";

type HubByKey = Record<string, PortalToHubMapping> | null;

export const useHubs = (): {
  hubByPortalKey: HubByKey;
  hubByHubKey: HubByKey;
} => {
  const config = useConfig();
  const portals = config.CONSTANTS.PORTALS_FOR_ALL_INSTITUTIONS;
  const [hubByPortalKey, setHubByPortalKey] = useState<HubByKey>(null);
  const [hubByHubKey, setHubByHubKey] = useState<HubByKey>(null);

  const { data: portalToHubMappingData } = useQuery<
    { getPortalHubs: Query["getPortalHubs"] },
    QueryGetPortalHubsArgs
  >(getPortalHubsQuery, {
    variables: { portalKeys: portals.map((pd) => pd.key) },
  });

  const generateHub = (mapping: PortalToHubMapping) => ({
    portalKey: mapping.portalKey,
    hubKey: mapping.hubKey,
    title: mapping.title,
    loginUrl: mapping.loginUrl ?? undefined,
  });

  useEffect(() => {
    if (
      hubByPortalKey == null &&
      portalToHubMappingData?.getPortalHubs != null &&
      portalToHubMappingData.getPortalHubs.length > 0
    ) {
      const hubByPortalKeyMap = _.reduce(
        portalToHubMappingData.getPortalHubs,
        (acc, mapping) => ({
          ...acc,
          [mapping.portalKey]: generateHub(mapping),
        }),
        {}
      );
      setHubByPortalKey(hubByPortalKeyMap);

      const hubsByHubKeyMap = _.reduce(
        portalToHubMappingData.getPortalHubs,
        (acc, mapping) => ({
          ...acc,
          [mapping.hubKey]: generateHub(mapping),
        }),
        {}
      );
      setHubByHubKey(hubsByHubKeyMap);
    }
  }, [
    portalToHubMappingData,
    setHubByPortalKey,
    setHubByHubKey,
    hubByPortalKey,
  ]);

  return { hubByPortalKey, hubByHubKey };
};
