/*
 * Code in this file is shared between the frontend and the backend. Do not
 * import any modules in this file (they complicate the build process and create
 * ambiguity around what version of the dependency should be used).
 *
 * XXX(ndhoule): The types in this file are not very solid; take everything in
 * here with a grain of salt.
 */

export interface Form {
  imgURLs: string[];
  specification: FormSpecification;
  taggedHeight: number;
  taggedWidth: number;
}

export interface FormSpecification {
  id: string;
  boxes: unknown[];
  items?: unknown[];
  onlyFocusChildrenIfTrue: boolean;
  type: string;
}

export interface FormInput {
  id: string;
  background: string;
  characterBoxes: unknown[];
  displayIf?: string | null | { parentId?: string; x?: number; y?: number };
  height: number;
  importance: unknown;
  items?: FormInput[];
  page: number;
  parentId?: string | null;
  samaTypes: unknown[];
  siblingId: string;
  tabIndex: unknown;
  type: string;
  width: number;
  x: number;
  y: number;
}

export const PORTAL_AUTH_ROUTE = "/portal-authorization";
export const FORM_AUTHORIZATION_ROUTE = "/new-authorization";
export const BENEFITS_VERIFICATION_SUMMARY_ROUTE = "/benefits-verification";
export const ENROLLMENTS_SUMMARY_ROUTE = "/enrollments/create/summary";
export const MULTI_PARTY_ROUTE = "/multi-party";
export const DATE_FORMAT = "MM/DD/YYYY";
export const CUSTOM_FIELDS = {
  MULTI_PARTY_FAX: {
    key: "MULTI_PARTY_FAX",
    title: "Institution multi party fax number",
    value: "+15052092637",
    allowInTagger: false,
  },
  SAMA_FAX: {
    key: "SAMA_FAX",
    title: "Sama fax",
    value: "+15052092637",
    allowInTagger: true,
  },
  TODAY: {
    key: "TODAY",
    title: "Date (today)",
    // PST is reasonable since since all users are in the US, and work 9 - 5
    // In the continental US, we will experience issues with auths submitted between 12am - 3am
    // In Hawaii, we will experience issues with auths submitted between 10pm - 11:59pm
    // Also, purposefully not using an arrow function here to support IE11
    value(): string {
      return new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
        month: "2-digit",
        timeZone: "America/Los_Angeles",
        year: "numeric",
      }).format(new Date());
    },
    allowInTagger: true,
  },
  NUM_PAGES_IN_TRANSMISSION: {
    key: "NUM_PAGES_IN_TRANSMISSION",
    title: "Number of pages in transmission",
    value: "N/A",
    allowInTagger: true,
  },
};

export const ICDRegex = /([A-Z][0-9][A-Z0-9](\.?[A-Z0-9]{0,4})?)/;
export const HCPCSCodeRegex = /^([A-Z]([0-9]{4}))$/;

export const getValue = (
  config: Record<string, unknown> | null,
  id: string,
  samaTypes: string[] | null
): unknown | null => {
  // first check for custom fields
  const customField = Object.values(CUSTOM_FIELDS).find((custom) =>
    (samaTypes ?? []).includes(custom.key)
  );
  if (
    customField != null &&
    config != null &&
    config[customField.key] == null &&
    customField.value != null
  ) {
    // if the custom field is not overridden by the config and it has a value then use it
    return typeof customField.value === "function"
      ? customField.value()
      : customField?.value;
  }
  // next check for authorization specific value
  const value =
    config != null && config[id] != null
      ? config[id]
      : customField != null && config != null
      ? config[customField.key]
      : null;
  if (value != null) {
    return value;
  }
  // otherwise fallback to samaTypes
  if (samaTypes != null && samaTypes.length > 0 && config != null) {
    return samaTypes
      .map((samaType) => config[samaType])
      .filter(
        (v) =>
          // _.compact equivalent
          v !== false && v != null && v !== "" && !Number.isNaN(v)
      )
      .join(" ")
      .trim();
  }

  return null;
};

export const flattenInputs = (
  inputs: FormInput[] | Record<string, FormInput>,
  parentId?: string
): unknown[] =>
  (Array.isArray(inputs) ? inputs : Object.values(inputs)).reduce<unknown[]>(
    (total, input) => {
      if (input.items) {
        const { items, ...rest } = { ...input, parentId };
        return total.concat(flattenInputs(input.items, input.id), rest);
      }

      return total.concat({ ...input, parentId });
    },
    []
  );

export const getPortalAuthURL = (root: string, id: string | number): string =>
  `${root}/#${PORTAL_AUTH_ROUTE}?step=4&id=${id.toString()}`;

export const getFormAuthURL = (root: string, id: string | number): string =>
  `${root}/#${FORM_AUTHORIZATION_ROUTE}?step=6&id=${id.toString()}`;

/**
 *
 * @param s Input drug name possibly containing description
 * @returns Cleaned drug name without description
 */
export const cleanDrugName = (s: string | null | undefined): string | null => {
  if (s == null) {
    return null;
  }
  // Regex matches drug name and description in the following formats:
  // - `Eylea`
  // - `Eylea (some description)`
  // Returns the drug name as capture group `rawName`. It may include extra space and should be trimmed.
  // The regex logic is based on the assumption that the drug names do not contain `(` or `)`.
  const regex = /^(?<rawName>[^()]+)/;
  const regexMatch = regex.exec(s);
  if (regexMatch == null) {
    return null;
  }
  return regexMatch.groups!.rawName.trim();
};
