import { Box, Typography, Stack } from "@samacare/design/core";
import DetailsContainer from "@@components/DetailsContainer";
import PdfViewer from "@@components/PdfViewer";
import { Authorization, File } from "@samacare/graphql";
import styled from "styled-components";
import Modal from "./Modal";

const PDFContainer = styled.div`
  display: flex;
  padding: 10px;
  margin: 0 5px 20px 5px;
  background-color: ${(props) => props.theme.lightGray};
`;

export const AuthorizationFileModal: React.VFC<{
  authorization: Authorization;
  file: File;
  closeModal: () => void;
}> = ({ authorization, file, closeModal }) => {
  return (
    <Modal
      open={file != null}
      header="View response"
      styleOverride={{ padding: "32px" }}
      headerStyleOverride={{ textAlign: "left" }}
      onClick={closeModal}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        maxHeight={({ spacing }) => spacing(80)}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{ marginTop: ({ spacing }) => spacing(2) }}
        >
          <Stack
            margin={({ spacing }) => spacing(1)}
            width={({ spacing }) => spacing(150)}
            spacing={2}
            direction="row"
          >
            <PDFContainer>
              <PdfViewer
                fileURL={file.url as string}
                width="620px"
                height="480px"
              />
            </PDFContainer>
            <Stack direction="column" spacing={2}>
              <Typography color="primary" variant="h6">
                Latest Auth Detail
              </Typography>
              <DetailsContainer authorization={authorization} />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
