import { capitalCase } from "change-case";
import { DialogContent, Stack, Typography } from "@samacare/design";
import {
  DialogFooterWithButtons,
  DialogHeader,
  TopBottomCenterAll,
} from "@samacare/component";

interface SearchErrorPaneProps {
  objectName: string;
  errorMessage: string | undefined | null;
  onBack: () => void;
  onClose: () => void;
  onAddManually: () => void;
}
export const SearchErrorPane: React.FC<SearchErrorPaneProps> = (props) => (
  <Stack sx={{ flexGrow: 1 }}>
    <DialogHeader
      title={`Add ${capitalCase(props.objectName)}`}
      onClose={props.onClose}
    />
    <DialogContent
      sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
    >
      <TopBottomCenterAll
        sx={{
          gap: 2,
          paddingBottom: 8,
          paddingX: 6,
          flexGrow: 1,
        }}
      >
        <Typography variant="body" fontWeight="600" textAlign="center">
          {props.errorMessage}
        </Typography>
      </TopBottomCenterAll>
    </DialogContent>
    <DialogFooterWithButtons
      cancelText="Back"
      onCancel={props.onBack}
      confirmText="Add Manually"
      onConfirm={props.onAddManually}
    />
  </Stack>
);
