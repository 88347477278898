import { Patient } from "@samacare/graphql";
import {
  GetPatientByIdQuery,
  GetPatientByIdQueryVariables,
} from "@@generated/graphql";
import { gql, useQuery } from "@apollo/client";

export const getPatientByIdQuery = gql`
  query getPatientById($id: Int!) {
    patientById(id: $id) {
      id
      dob
      firstName
      lastName
      address
      zip
      gender
      state
      city
      phone
      InsuranceCompanyId
      status
      primaryInsurance {
        id
        memberId
        planType
        insuranceState
        insuranceCompany {
          id
          name
        }
        isValid
        supportsCoverageCheck
        latestCoverageCheck {
          id
          isValid
          isActive
          checkedAt
        }
      }
      secondaryInsurance {
        id
        memberId
        groupNumber
        planType
        insuranceState
        insuranceCompany {
          id
          name
        }
      }
      patientSource {
        id
        name
        sourceType
      }
    }
  }
`;
export const usePatient = (id: number | undefined) => {
  const { data, error, loading } = useQuery<
    GetPatientByIdQuery,
    GetPatientByIdQueryVariables
  >(getPatientByIdQuery, {
    variables: { id: Number(id) },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  return {
    patient: (data?.patientById ?? null) as Patient | null,
    loading,
    error,
  };
};
