import React from "react";
import SimplifiedModal from "@@components/SimplifiedModal";
import { Editor } from "@@components/PDFEditor/Editor";
import { useCoverLetterAuthorizationForm } from "@@hooks/useCoverLetterAuthorizationForm";

const EditCoverLetterModal: (
  props: EditCoverLetterModalProps
) => JSX.Element = ({
  closeModal,
  results,
  setFormFields,
  onSubmit,
}: EditCoverLetterModalProps) => {
  const { form, error, loading } = useCoverLetterAuthorizationForm();

  const failedFetchData = error || !form;

  return (
    <SimplifiedModal
      header="Edit Cover Letter"
      onClick={closeModal}
      submit={failedFetchData ? null : onSubmit}
      open={true}
      submitText="Save Edits"
      back={closeModal}
    >
      {failedFetchData ? (
        <div>
          There was an unexpected error while handling your request. Please
          reload the page and try again and, if the issue persists, contact
          SamaCare.
        </div>
      ) : (
        <Editor
          disabled={loading}
          form={form}
          results={results}
          segmentLoading={false}
          setFormFields={setFormFields}
          setRequiredFields={() => ({})}
          unsetRequiredFields={() => ({})}
          width="750px"
          height="650px"
        />
      )}
    </SimplifiedModal>
  );
};

interface EditCoverLetterModalProps {
  closeModal: () => void;
  results: Record<string, unknown>;
  setFormFields: (fields: Record<string, unknown>) => void;
  onSubmit: () => void;
}

export default EditCoverLetterModal;
