import _ from "lodash";
import styled from "styled-components";

import AuthorizationListManager from "../SupportAuthorizationList/AuthorizationListManager";
import { FailedStatusCheckerModal } from "./FailedStatusCheckerModal";
import { useEffect, useMemo, useState } from "react";
import { Authorization, Portal } from "@samacare/graphql";
import {
  GetUnresolvedFollowUpPortalsAndInstitutionsQuery,
  GetUnresolvedFollowUpPortalsAndInstitutionsQueryVariables,
  GetUnresolvedFollowUpsQuery,
  GetUnresolvedFollowUpsQueryVariables,
} from "@@generated/graphql";
import { gql, useQuery } from "@apollo/client";
import {
  Autocomplete,
  Box,
  Pagination,
  Stack,
  TextField,
} from "@samacare/design/core";
import { useConfig } from "../../../hooks";
import { supportAuthorizationFollowUpsQuery } from "../../../graphql/SupportedAuthorizationFollowUp";
import moment from "moment";
import institutionInfo from "../../../graphql/fragments/institutionInfo";
import portalInformation from "../../../graphql/fragments/portalInformation";

type InstitutionOption = { id: number; label: string };

const ResultsCountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const ScrollContainer = styled.div`
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  min-width: 100px;
  overflow: scroll;
`;

const supportAuthorizationFollowUpPortalsAndInstitutionsQuery = gql`
  query GetUnresolvedFollowUpPortalsAndInstitutions {
    getUnresolvedFollowUpPortalsAndInstitutions {
      institutions {
        ...institutionInfo
      }
      portals {
        ...portalInformation
      }
    }
  }
  ${institutionInfo}
  ${portalInformation}
`;

const SupportedFollowUpsDashboard: React.VFC = () => {
  const config = useConfig();

  const [selectedFollowUpId, setSelectedFollowUpId] = useState<number | null>(
    null
  );
  const [pageNum, setPageNum] = useState<number>(1);
  const [selectedInstitutionOption, setSelectedInstitutionOption] =
    useState<InstitutionOption | null>(null);
  const [selectedPortal, setSelectedPortal] = useState<Portal | null>(null);

  const { data: portalsAndInstitutionsData } = useQuery<
    GetUnresolvedFollowUpPortalsAndInstitutionsQuery,
    GetUnresolvedFollowUpPortalsAndInstitutionsQueryVariables
  >(supportAuthorizationFollowUpPortalsAndInstitutionsQuery);

  // Institution Options
  const institutionOptions = useMemo(() => {
    const institutions =
      portalsAndInstitutionsData?.getUnresolvedFollowUpPortalsAndInstitutions
        ?.institutions ?? [];
    const labeledOptions = institutions.map((i) => ({
      id: parseInt(i.id),
      label: `${i.name} - ${i.practicingSpecialty}`,
    }));
    const sortedOptions = _.sortBy(labeledOptions, "label");
    return sortedOptions;
  }, [portalsAndInstitutionsData]);

  const portals = useMemo(() => {
    const portalList =
      portalsAndInstitutionsData?.getUnresolvedFollowUpPortalsAndInstitutions
        ?.portals ?? [];
    const sortedOptions = _.sortBy(portalList, "title");
    return sortedOptions;
  }, [portalsAndInstitutionsData]);

  // Main Data
  const { data } = useQuery<
    GetUnresolvedFollowUpsQuery,
    GetUnresolvedFollowUpsQueryVariables
  >(supportAuthorizationFollowUpsQuery, {
    variables: {
      pageNum,
      institutionId: selectedInstitutionOption?.id ?? null,
      PortalId: selectedPortal ? +selectedPortal.id : null,
    },
    fetchPolicy: "network-only",
  });
  const followUps = data?.getUnresolvedFollowUps.followUps;
  const totalCount = data?.getUnresolvedFollowUps.totalCount;
  const totalPageCount =
    totalCount &&
    Math.ceil(totalCount / config.CONSTANTS.PORTAL_QUEUE_PAGE_SIZE);

  const auths = useMemo(
    () => followUps && followUps.map((f) => f.authorization),
    [followUps]
  );

  const followUpMadeActiveAtByAuthId = useMemo(() => {
    const map: Record<string, string> = {};
    followUps?.forEach((fu) => {
      map[fu.AuthorizationId] = fu.madeActiveAt;
    });
    return map;
  }, [followUps]);

  const handleResolveClick = (authorization: Authorization) => {
    const followUp = followUps?.find(
      (fu) => fu.AuthorizationId === parseInt(authorization.id)
    );

    setSelectedFollowUpId(followUp?.id ?? null);
  };

  useEffect(() => {
    if (pageNum > 1 && followUps && followUps.length === 0) {
      setPageNum(pageNum - 1);
    }
  }, [pageNum, setPageNum, followUps]);

  let selectedAuthorization;
  let screenshotUrl = null;
  const followUpData = followUps?.find((fu) => fu.id === selectedFollowUpId);

  if (followUpData) {
    ({ authorization: selectedAuthorization, screenshotUrl } = followUpData);
  }

  return (
    <Box ml={3} mt={1} mb={1}>
      <Stack direction="row" gap={1}>
        {institutionOptions && (
          <Autocomplete
            selectOnFocus
            handleHomeEndKeys
            autoHighlight
            onChange={(_event, value) => {
              setSelectedInstitutionOption(value);
            }}
            value={selectedInstitutionOption}
            options={institutionOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="Institution"
              />
            )}
            sx={{
              width: 300,
            }}
          />
        )}
        {portals && (
          <Autocomplete
            selectOnFocus
            handleHomeEndKeys
            autoHighlight
            onChange={(_event, value) => {
              setSelectedPortal(value as Portal);
            }}
            value={selectedPortal}
            options={portals}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="Portal"
              />
            )}
            sx={{
              width: 300,
            }}
          />
        )}
        <ResultsCountContainer>Results: {totalCount}</ResultsCountContainer>
      </Stack>
      <ScrollContainer>
        <AuthorizationListManager
          minWidth="600px"
          authorizations={auths}
          alwaysOpenInBlank
          emptyStateText="None Found"
          tableConfig={{
            headers: ["Age (days)", "Patient", "Status", "Details"],
            columns: [
              {
                type: "customText",
                parseText: (authorization: Authorization) => {
                  return moment().diff(
                    moment(followUpMadeActiveAtByAuthId[authorization.id]),
                    "days"
                  );
                },
              },
              "stackedPatient",
              "status",
              "authorizationDetails",
              {
                type: "customButton",
                text: "Failed Check",
                onClick: handleResolveClick,
              },
            ],
          }}
        />
      </ScrollContainer>

      <Stack direction="row" justifyContent="center">
        {totalPageCount && (
          <Pagination
            count={totalPageCount}
            page={pageNum}
            onChange={(event: React.ChangeEvent<unknown>, page: number) => {
              setPageNum(page);
            }}
          />
        )}
      </Stack>

      {selectedFollowUpId && selectedAuthorization && (
        <FailedStatusCheckerModal
          authorization={selectedAuthorization as Authorization}
          screenshotUrl={screenshotUrl}
          followUpId={selectedFollowUpId}
          onClose={() => {
            setSelectedFollowUpId(null);
          }}
        />
      )}
    </Box>
  );
};

export default SupportedFollowUpsDashboard;
