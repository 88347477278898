import * as React from "react";
import { useLevel } from "react-headings";
import styled, { css } from "styled-components";

const styles = {
  1: css`
    all: unset;

    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  `,
  2: css`
    all: unset;

    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  `,
  3: css`
    all: unset;

    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  `,
  4: css`
    all: unset;

    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  `,
  5: css`
    all: unset;

    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  `,
  6: css`
    all: unset;

    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  `,
};

const H1 = styled.h1<{ styleLevel: Level }>`
  ${({ styleLevel }) => styles[styleLevel]}
`;

const H2 = styled.h2<{ styleLevel: Level }>`
  ${({ styleLevel }) => styles[styleLevel]}
`;

const H3 = styled.h3<{ styleLevel: Level }>`
  ${({ styleLevel }) => styles[styleLevel]}
`;

const H4 = styled.h4<{ styleLevel: Level }>`
  ${({ styleLevel }) => styles[styleLevel]}
`;

const H5 = styled.h5<{ styleLevel: Level }>`
  ${({ styleLevel }) => styles[styleLevel]}
`;

const H6 = styled.h6<{ styleLevel: Level }>`
  ${({ styleLevel }) => styles[styleLevel]}
`;

export type Level = 1 | 2 | 3 | 4 | 5 | 6;

const getComponentForLevel = (level: Level) => {
  switch (level) {
    case 1:
      return H1;
    case 2:
      return H2;
    case 3:
      return H3;
    case 4:
      return H4;
    case 5:
      return H5;
    case 6:
      return H6;
  }
};

export type HeadingProps = React.ComponentProps<typeof Heading>;

/**
 * Heading is a component that automatically determines the heading level based
 * on how deeply nested within `Section`s it is. For example:
 *
 * ```ts
 * <Section>
 *   <Header>I am a level 1 header</Header>
 *
 *   <Section>
 *   <Header>I am a level 2 header</Header>
 *   <Header>I am a level 2 header</Header>
 *
 *     <Section>
 *     <Header>I am a level 3 header</Header>
 *     </Section>
 *   </Section>
 * </Section>
 * ```
 *
 * You can style any header as if it were another level header by passing the
 * styleLevel prop; for example:
 *
 * ```ts
 * <Header styleLevel={6}>I'm an h1, but I'm styled like an h6</Header>
 * ````
 *
 * ## When to use:
 *
 * Any time you would use a standard header (h1-h6) element.
 */
/**
 * @deprecated Use a MUI component instead
 */
export const Heading: React.VoidFunctionComponent<
  Omit<
    React.ComponentProps<
      typeof H1 & typeof H2 & typeof H3 & typeof H4 & typeof H5 & typeof H6
    >,
    "as" | "forwardedAs"
  > & {
    styleLevel?: Level;
  }
> = ({ styleLevel: _styleLevel, ...props }) => {
  const { level } = useLevel();
  const styleLevel = _styleLevel ?? level;
  const Component = getComponentForLevel(level);

  return <Component styleLevel={styleLevel} {...props} />;
};
