import FormSubmitButtons from "@@components/AuthorizationSharedComponents/FormSubmitButtons";
import Modal from "@@components/Modal";
import { useMemo, useState, useCallback } from "react";
import * as React from "react";
import {
  Typography,
  TextField,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";

interface IConfirmDialogProps {
  open: boolean;
  zIndexOffset: number;
  message: string | React.ReactNode;
  back: () => void;
  confirm: () => void;
  confirmText?: string;
  submitText?: string;
  backText?: string;
  title?: string;
}
const ConfirmDialog: React.VFC<IConfirmDialogProps> = (props) => {
  const theme = useTheme();
  const [userConfirmationText, setUserConfirmationText] = useState("");
  const isConfirmedDisabled = props.confirmText
    ? userConfirmationText.toLowerCase() !== props.confirmText.toLowerCase()
    : false;

  React.useEffect(() => {
    if (!props.open) {
      setUserConfirmationText("");
    }
  }, [props.open]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !isConfirmedDisabled) {
      props.confirm();
    }
  };

  return (
    <Modal open={props.open} zIndexOffset={props.zIndexOffset}>
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>
        {typeof props.message === "string" ? (
          <Typography color={theme.palette.text.primary}>
            {props.message}
          </Typography>
        ) : (
          props.message
        )}

        {props.confirmText && (
          <TextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            label="Institution"
            value={userConfirmationText}
            placeholder={props.confirmText}
            onChange={(e) => setUserConfirmationText(e.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ marginTop: 2, width: "100%" }}
            InputLabelProps={{ shrink: true }}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ width: "100%" }}>
        <FormSubmitButtons
          backText={props.backText ?? "No"}
          back={props.back}
          submitText={props.submitText ?? "Yes"}
          submit={props.confirm}
          submitDisabled={isConfirmedDisabled}
          style={{ width: "100%", marginTop: 0 }}
        />
      </DialogActions>
    </Modal>
  );
};

interface UseConfirmDialogProps {
  message: string | React.ReactNode;
  confirm: (input?: string) => void | Promise<void>;
  zIndexOffset: number;
  confirmText?: string;
  submitText?: string;
  backText?: string;
  title?: string;
}
export const useConfirmDialog = ({
  message,
  confirm,
  zIndexOffset,
  confirmText,
  submitText,
  backText,
  title,
}: UseConfirmDialogProps): [
  typeof ConfirmDialog,
  IConfirmDialogProps,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const [open, setOpen] = useState<boolean>(false);

  const back = useCallback(() => setOpen(false), [setOpen]);
  const confirmCallback = useCallback(() => {
    void confirm();
    setOpen(false);
  }, [confirm, setOpen]);

  const dialogProps = useMemo(
    () => ({
      open,
      zIndexOffset,
      message,
      back,
      confirm: confirmCallback,
      confirmText,
      submitText,
      backText,
      title,
    }),
    [
      open,
      zIndexOffset,
      message,
      back,
      confirmCallback,
      confirmText,
      submitText,
      backText,
      title,
    ]
  );

  return [ConfirmDialog, dialogProps, setOpen];
};
