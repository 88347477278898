import _ from "lodash";
import {
  Account,
  Authorization,
  AuthorizationType,
  PaOriginType,
} from "@samacare/graphql";
import { Flex } from "../../../ui-kit";
import { useConfig } from "../../hooks";
import { ActionRequiredBanner } from "../../routes/PCAuthorizations/PatientTile";
import { ActionRequiredRowContainer } from "../../routes/PCAuthorizations/PatientTile/ActionRequiredRowContainer";
import {
  isTrackedPortalAuth,
  needCurrentUserAction,
} from "../../util/authUtils";
import { StatusCheckerErrorButton } from "../StatusCheckerErrorButton";
import UpdateAuthorizationStatusButton from "../UpdateAuthorizationStatusButton";
import { Box, Button, Typography, Link } from "@samacare/design";
import VisibilityIcon from "@samacare/design/core/icons/Visibility";
import { interpolate } from "../../util/stringUtils";

interface ActionBannersProps {
  authorization: Authorization;
  currentAccount: Account;
}
export const ActionBanners: React.VFC<ActionBannersProps> = (props) => {
  const { authorization, currentAccount } = props;

  const config = useConfig();

  let portalUrl =
    authorization?.portal?.deepLink || authorization?.portal?.loginUrl;
  portalUrl = interpolate(portalUrl, authorization);

  return (
    <>
      {/**
       * If the auth API-tracked, do not display the action banner
       */}
      {isTrackedPortalAuth(authorization) &&
        config.CONSTANTS.PROGRESS_AUTHORIZATION_STATUSES.includes(
          authorization.status
        ) &&
        (authorization.portalAuthorizationId == null ||
          authorization.portalAuthorizationId === "") && (
          <Flex
            flexDirection="column"
            sx={{ width: "100%", marginTop: "10px" }}
            data-cy="missingIdentifierBanner"
          >
            <ActionRequiredRowContainer>
              <ActionRequiredBanner>
                Please add authorization ID from the portal to enable tracking.
                <UpdateAuthorizationStatusButton
                  authorization={authorization}
                  styleOverrides={{
                    width: "200px",
                    fontSize: "16px",
                    height: "35px",
                  }}
                >
                  Add ID
                </UpdateAuthorizationStatusButton>
              </ActionRequiredBanner>
            </ActionRequiredRowContainer>
          </Flex>
        )}

      {(!_.isNil(authorization.portalKey) ||
        !_.isNil(authorization.PortalId)) &&
        authorization.isWebExtension &&
        (authorization?.paOrigin !== PaOriginType.Copilot ||
          authorization?.portal?.isLegacy) &&
        authorization.formDetails?.currentStep != null &&
        authorization.formDetails?.currentStep >= 3 &&
        authorization.status ===
          config.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION && (
          <Flex
            flexDirection="column"
            sx={{ width: "100%", marginTop: "10px" }}
            data-cy="didYouSubmitThisBanner"
          >
            <ActionRequiredRowContainer>
              <ActionRequiredBanner>
                Did you submit this? Click here to update the status and
                tracking information
                <UpdateAuthorizationStatusButton
                  authorization={authorization}
                  styleOverrides={{
                    width: "200px",
                    fontSize: "16px",
                    height: "35px",
                  }}
                >
                  Update
                </UpdateAuthorizationStatusButton>
              </ActionRequiredBanner>
            </ActionRequiredRowContainer>
          </Flex>
        )}

      {authorization?.type === AuthorizationType.Portal &&
        authorization?.portal &&
        !authorization?.portal?.isFallback && (
          <Box
            sx={{
              backgroundColor: "#e1f5fe",
              height: "52px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 15px",
              marginLeft: "5px",
              marginTop: "10px",
            }}
          >
            <Typography variant="body1">
              For the most recent status, visit{" "}
              <Link
                href={portalUrl}
                target="_blank"
                sx={{ color: "unset", textDecorationColor: "unset" }}
              >
                payer’s website
              </Link>
              .
            </Typography>

            <Button
              variant="outlined"
              sx={{ backgroundColor: "#ffffff", pl: 1, pr: 1 }}
              onClick={() =>
                window.open(portalUrl, "_blank", "noopener noreferrer")
              }
            >
              <VisibilityIcon sx={{ mr: 1 }} />
              Check Status in Portal
            </Button>
          </Box>
        )}
      {needCurrentUserAction(authorization, currentAccount) && (
        <Flex
          flexDirection="column"
          sx={{ width: "100%", marginTop: "10px" }}
          data-cy="loginErrorsBanner"
        >
          <ActionRequiredRowContainer>
            <ActionRequiredBanner>
              Unable to check status.
              <StatusCheckerErrorButton
                key={`StatusCheckerErrorButton-id-${authorization.id}`}
                currentAccount={currentAccount}
                authorization={authorization}
                data-cy="resolveButton"
              >
                Resolve
              </StatusCheckerErrorButton>
            </ActionRequiredBanner>
          </ActionRequiredRowContainer>
        </Flex>
      )}
    </>
  );
};
