import * as React from "react";
import styled from "styled-components";

export type RadioProps = React.ComponentProps<typeof Radio>;

/**
 * Radio is a component that allows a single value to be selected from multiple
 * choices with the same `name` value.
 *
 * ## When to use:
 *
 * When offering a user a choice between multiple pre-defined values. A radio
 * button is functionally equivalent to a dropdown/select list; you can use
 * either in place of each other.
 *
 * @example
 * ```tsx
 * import { Label, Radio } from "@@ui-kit/forms";
 *
 * // ...
 *
 * <Label>
 *   <Radio value="daily" />
 *   Daily
 * </Label>
 *
 * <Label>
 *   <Radio value="weekly" />
 *   Weekly
 * </Label>
 *
 * <Label>
 *   <Radio value="monthly" />
 *   Monthly
 * </Label>
 * ```
 *
/**
 * @deprecated Use component from design/forms instead
 */

export const Radio = styled.input.attrs({ type: "radio" })`
  margin-left: 5px;
  margin-right: 5px;

  :hover {
    cursor: pointer;
  }
`;
