import _ from "lodash";

const A_S = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;

const ALL_ACTIVE = _.without(_.values(A_S), A_S.ARCHIVED);
export default [
  {
    title: "Authorizations",
    statuses: ALL_ACTIVE,
    tabTitle: "Mine",
    onlyMe: true,
    onlyExpired: false,
    isArchived: false,
    shouldShowLocationFilter: true,
    emptyState:
      'You have not created any authorizations yet. To create a new authorization use the "New Authorization" button at the top.',
  },
  {
    title: "Authorizations",
    statuses: ALL_ACTIVE,
    tabTitle: "All",
    onlyMe: false,
    onlyExpired: false,
    isArchived: false,
    shouldShowLocationFilter: true,
    emptyState:
      'There are no authorizations yet. To create a new authorization use the "New Authorization" button at the top.',
  },
  {
    title: "Authorizations",
    statuses: CONFIG.CONSTANTS.APPROVED_STATUSES,
    tabTitle: "Expiring",
    onlyMe: false,
    onlyExpired: true,
    hideFromExpiring: false,
    shouldShowLocationFilter: true,
    emptyState: "No authorizations have expired yet.",
  },
  {
    title: "Authorizations",
    statuses: ALL_ACTIVE,
    tabTitle: "Archived",
    onlyMe: false,
    onlyExpired: false,
    isArchived: true,
    shouldShowLocationFilter: true,
    emptyState: "No authorizations have been archived yet.",
  },
];
