import * as React from "react";
import { useRouteMatch, Switch, Route, generatePath } from "react-router-dom";

import { EnrollmentPage } from "./EnrollmentPage";
import { EnrollmentForm } from "./EnrollmentForm";
import { EnrollmentRoutes } from "./Enrollment.types";
import { EnrollmentProgram } from "./EnrollmentProgram";
import { EnrollmentRedirect } from "./EnrollmentRedirect";
import { EnrollmentProvider } from "./EnrollmentProvider";
import { EnrollmentSummaryPage } from "./EnrollmentSummaryPage";
import { EnrollmentSubmissionPage } from "./EnrollmentSubmissionPage";

export const Enrollment: React.FC = () => {
  const { path, params } = useRouteMatch<{ enrollmentId: string }>();

  return (
    <EnrollmentProvider>
      <EnrollmentPage>
        <Switch>
          <Route
            path={generatePath(path, {
              view: EnrollmentRoutes.submission,
              enrollmentId: params.enrollmentId,
            })}
          >
            <EnrollmentSubmissionPage />
          </Route>
          <Route
            path={generatePath(path, {
              view: EnrollmentRoutes.program,
              enrollmentId: params.enrollmentId,
            })}
          >
            <EnrollmentProgram />
          </Route>
          <Route
            path={generatePath(path, {
              view: EnrollmentRoutes.details,
              enrollmentId: params.enrollmentId,
            })}
          >
            <EnrollmentForm />
          </Route>
          <Route
            path={generatePath(path, {
              view: EnrollmentRoutes.summary,
              enrollmentId: params.enrollmentId,
            })}
          >
            <EnrollmentSummaryPage />
          </Route>
          <Route>
            <EnrollmentRedirect />
          </Route>
        </Switch>
      </EnrollmentPage>
    </EnrollmentProvider>
  );
};
