import _ from "lodash";
import { Mutation, MutationUpdatePortalArgs, Portal } from "@samacare/graphql";

import { useMutation } from "@apollo/client";
import UpdatePortal from "../graphql/UpdatePortalMutation.gql";

const useUpdatePortal = () => {
  const [updatePortal] = useMutation<
    { updatePortal: Mutation["updatePortal"] },
    MutationUpdatePortalArgs
  >(UpdatePortal, {
    refetchQueries: ["GetPortals"],
  });

  const update = async (portal: Portal) => {
    const result = await updatePortal({
      variables: {
        id: +portal.id,
        patch: _.omit(portal, ["id", "__typename"]),
      },
    });

    return result.data?.updatePortal;
  };

  return update;
};

export { useUpdatePortal };
