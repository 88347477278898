import { withAlert } from "react-alert";
import { PureComponent } from "react";
import styled from "styled-components";

import FilterBox from "./FilterBox";

const EmptyFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 100%;
  margin: 0 8px 16px 0;
  height: 200px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.lightPurple};
  text-align: center;
  &:hover {
    border: 1px solid ${(props) => props.theme.purple};
    cursor: pointer;
    background-color: white;
  }
`;

class FiltersContainer extends PureComponent {
  addFilter = async () => {
    const { upsertFunction, alert, associationKey, associationId } = this.props;
    try {
      await upsertFunction({ variables: { [associationKey]: associationId } });
      alert.info("Successfully created filter");
    } catch (e) {
      alert.error(`Failed to update ${e}`);
    }
  };

  render() {
    const {
      filter,
      upsertFunction,
      deleteFunction,
      associationId,
      associationKey,
      styleOverrides,
    } = this.props;

    return (
      <div style={{ display: "flex", ...(styleOverrides || {}) }}>
        {filter ? (
          <FilterBox
            states={filter.states}
            NDCs={filter.NDCs}
            jCodes={filter.jCodes}
            insuranceTypes={filter.insuranceTypes}
            benefitType={filter.benefitType}
            type={filter.type}
            key={`filter-box-${filter.id}`}
            id={filter.id}
            upsertFunction={upsertFunction}
            deleteFunction={deleteFunction}
            associationKey={associationKey}
            associationId={associationId}
          />
        ) : (
          <EmptyFilter
            onClick={() => {
              this.addFilter();
            }}
          >
            Click to create a filter
          </EmptyFilter>
        )}
      </div>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default withAlert()(FiltersContainer);
