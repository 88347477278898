import gql from "graphql-tag";

export const locationInfo = gql`
  fragment locationInfo on Location {
    id
    nickname
    address
    city
    state
    zip
    fax
    facilityName
    taxId
    NPI
    PTAN
    source
  }
`;
