import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

export const createAuthorizationErrorMutation = gql`
  mutation createAuthorizationError(
    $authorizationId: Int!
    $type: String!
    $message: String
  ) {
    createAuthorizationError(
      authorizationId: $authorizationId
      type: $type
      message: $message
    ) {
      id
    }
  }
`;

export const withCreateAuthorizationError = graphql(
  createAuthorizationErrorMutation,
  {
    name: "createAuthorizationError",
  }
);
