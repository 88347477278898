import { useLocation, useHistory } from "react-router-dom";

export const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();

  const getQueryParam = (param: string): string | null => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const setQueryParam = (param: string, value: string | null): void => {
    const searchParams = new URLSearchParams(location.search);
    if (value) {
      searchParams.set(param, value);
    } else {
      searchParams.delete(param);
    }
    history.replace({ search: searchParams.toString() });
  };

  return { getQueryParam, setQueryParam };
};
