import gql from "graphql-tag";
import formFilterInfo from "./formFilterInfo";
import formNumberInfo from "./formNumberInfo";

export default gql`
  fragment authorizationFormInfo on AuthorizationForm {
    updatedAt
    id
    pdfURL
    title
    description
    specification
    imgURLs
    imgKeys
    taggedWidth
    taggedHeight
    hasTaggedSignature
    isHiddenInstitutionIds
    type
    isArchived
    formFilter {
      jCodes
      NDCs
    }
    approvalRate
    approvalCount
    tags
    lastUsed
    insuranceCompaniesNames
    formNumbers {
      ...formNumberInfo
    }
    formFilter {
      ...formFilterInfo
    }
  }
  ${formNumberInfo}
  ${formFilterInfo}
`;
