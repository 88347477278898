import { useCallback } from "react";
import * as React from "react";
import _ from "lodash";
import { useDrag } from "react-dnd-new";
import { IoMdMove as MoveIcon } from "@react-icons/all-files/io/IoMdMove";
import { MdDelete } from "@react-icons/all-files/md/MdDelete";

import styled from "styled-components";

import { useConfig } from "../../hooks";
import { CUSTOM_FIELDS } from "../../../../server/src/shared_code";
import {
  PRIMARY_DRUG_NAME_LABEL,
  PRIMARY_NDC_LABEL,
} from "./PDFEditorForManuallyUploadedForm";

const StyledButton = styled.a<{
  color: string;
  cursor: string;
}>`
  width: 16px;
  height: 16px;
  font-size: 16px;
  visibility: hidden;
  color: ${(props) => props.theme.primary};
  cursor: ${(props) => props.cursor};
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 5px;

  &:hover {
    color: ${(props) => props.color};
  }
`;

const StyledSelect = styled.select`
  width: auto;
  left: 0;
  top: 0;
  font-size: 13px;
  height: 22px;
  background-color: rgb(189, 189, 245);
  border: none;
`;

const TaggerWrapper = styled.div<{
  left: number;
  top: number;
  opacity: number;
}>`
  width: auto;
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  opacity: ${(props) => props.opacity};
  height: auto;
  align-items: center;

  &:hover {
    ${StyledButton} {
      visibility: visible;
    }
  }
`;

const ValueContainer = styled.div`
  width: auto;
  left: 0;
  top: 0;
  font-size: 13px;
  height: 22px;
  background-color: rgb(189, 189, 245);
  padding: 5px;
  font-weight: bold;
`;

export interface ManuallyConfiguredBoxes {
  height: number;
  page: number;
  width: number;
  x: number;
  y: number;
  id: string;
  value: string | null;
  key: string | null;
}

export interface OptionGroup {
  name: string;
  fields: {
    [key: string]: string;
  };
}

export const dragType = "manualyUploadedFormTagger";
export const TaggerForManuallyUploadedForm: React.FunctionComponent<{
  id: string;
  x: number;
  y: number;
  setSelectedId: (id: string | null) => void;
  setRemoveId: (id: string | null) => void;
  removeItem: (id: string) => void;
  setItems: (items: ManuallyConfiguredBoxes[]) => void;
  setHoveredId: (id: string | null) => void;
  items: ManuallyConfiguredBoxes[];
  hoveredId: string | null;
  optionGroups: OptionGroup[];
}> = ({
  id,
  x,
  y,
  setSelectedId,
  setRemoveId,
  removeItem,
  setItems,
  items,
  setHoveredId,
  hoveredId,
  optionGroups,
}) => {
  const config = useConfig();
  const { CHARACTER_WIDTH_MULTIPLIER_AVG, CHARACTER_WIDTH_MULTIPLIER } =
    config.CONSTANTS;

  const calculateBoxSize = (value: string) => {
    let boxSize = 0;
    for (const char of value) {
      const charMultiplier = _.findKey(
        CHARACTER_WIDTH_MULTIPLIER,
        (characters) => _.includes(characters, char)
      );
      const charWidth =
        parseFloat(
          !_.isNil(charMultiplier)
            ? charMultiplier.toString()
            : CHARACTER_WIDTH_MULTIPLIER_AVG.toString()
        ) *
        16 *
        (10 / value.length);
      boxSize += charWidth;
    }
    return boxSize;
  };

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: dragType,
    item: { id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const updateItems = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setItems(
        items.map((item) => {
          if (item.id === id) {
            const [key, value] = e.target.value.split(": ");
            return {
              ...item,
              value: value.trim(),
              key: key.trim(),
              width: calculateBoxSize(value.trim()),
            };
          }
          return item;
        })
      );
    },
    [id, items, setItems]
  );
  const defaultFields = config.DEFAULT_FIELDS;
  const mandatoryFields = [
    defaultFields.PATIENT_FIRST_NAME.title,
    defaultFields.PATIENT_LAST_NAME.title,
    defaultFields.PATIENT_DOB.title,
    defaultFields.PRESCRIBER_FIRST_NAME.title,
    defaultFields.PRESCRIBER_LAST_NAME.title,
    defaultFields.PRESCRIBER_NPI.title,
    CUSTOM_FIELDS.SAMA_FAX.title,
    PRIMARY_DRUG_NAME_LABEL,
    PRIMARY_NDC_LABEL,
  ];

  return (
    <TaggerWrapper
      key={id}
      left={x}
      top={y}
      onMouseDown={(e) => {
        e.stopPropagation();
        setSelectedId(id);
      }}
      onMouseEnter={() => setHoveredId(id)}
      onMouseLeave={() => setHoveredId(null)}
      ref={preview}
      opacity={isDragging ? 0.1 : 1}
    >
      <StyledButton ref={drag} color="grey" cursor="move">
        <MoveIcon />
      </StyledButton>
      {id !== hoveredId ? (
        <ValueContainer>
          {items.find((ele) => ele.id === id)?.value ?? "Select your option"}
        </ValueContainer>
      ) : (
        <StyledSelect onChange={updateItems}>
          <option value="" selected disabled hidden>
            Select your option
          </option>
          {optionGroups.map((optionGroup, ind) => {
            return (
              <optgroup
                label={optionGroup.name}
                key={`option-${optionGroup.name}-${ind.toString()}`}
              >
                {Object.keys(optionGroup.fields).map((key) => (
                  <option
                    value={`${key}: ${
                      optionGroup.fields[
                        key as keyof typeof optionGroup.fields
                      ] ?? ""
                    }
                      `}
                    key={key}
                    selected={
                      items.find((ele) => ele.id === id)?.value ===
                      optionGroup.fields[key as keyof typeof optionGroup.fields]
                    }
                  >
                    {_.includes(mandatoryFields, key)
                      ? `${key}: ${
                          optionGroup.fields[
                            key as keyof typeof optionGroup.fields
                          ] ?? ""
                        } *
                        `
                      : `${key}: ${
                          optionGroup.fields[
                            key as keyof typeof optionGroup.fields
                          ] ?? ""
                        }
                    `}
                  </option>
                ))}
              </optgroup>
            );
          })}
        </StyledSelect>
      )}
      <StyledButton
        color="red"
        cursor="pointer"
        type="button"
        onMouseDown={(e) => {
          e.stopPropagation();
          setRemoveId(id);
          removeItem(id);
        }}
      >
        <MdDelete />
      </StyledButton>
    </TaggerWrapper>
  );
};
