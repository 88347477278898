import { useCallback } from "react";
import { useDispatch } from "../configureStore";
import { setFormFields } from "../reducers/formReducer";

export const useSet = () => {
  const dispatch = useDispatch();

  return useCallback(
    (fields: Record<string, unknown>) => {
      dispatch(setFormFields(fields));
    },
    [dispatch]
  );
};
