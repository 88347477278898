import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { deepmerge } from "@mui/utils";
import { GlobalStyles, css } from "@mui/system";
import { LicenseInfo } from "@mui/x-license-pro";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
// eslint-disable-next-line import/no-extraneous-dependencies
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import LocalizationProvider from "./LocalizationProvider";
import defaultTheme from "./theme";
LicenseInfo.setLicenseKey("fd0d9bf0863b9fb970a2ad1e2d360479Tz05MDk0MCxFPTE3NDc4NTI5MTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");
const ThemeProvider = ({ children, theme = defaultTheme, popoverContainer, cacheContainer, }) => {
    // have to merge the theme with the popover container for theme provider
    // to work in the content script
    const mergedTheme = deepmerge(theme, popoverContainer
        ? {
            components: {
                MuiPopover: {
                    defaultProps: {
                        container: popoverContainer,
                        sx: {
                            position: "absolute",
                        },
                    },
                },
                MuiPopper: {
                    defaultProps: {
                        container: popoverContainer,
                        sx: {
                            position: "absolute",
                        },
                    },
                },
                MuiModal: {
                    defaultProps: {
                        container: popoverContainer,
                        sx: {
                            position: "absolute",
                        },
                    },
                },
                MuiDialog: {
                    defaultProps: {
                        container: popoverContainer,
                        sx: {
                            position: "absolute",
                        },
                    },
                },
                MuiMenu: {
                    styleOverrides: {
                        root: {
                            position: "fixed",
                        },
                    },
                },
            },
        }
        : {});
    const emotionCache = createCache({
        key: "sc-key",
        container: cacheContainer,
        prepend: true,
    });
    return (_jsxs(_Fragment, { children: [_jsx(GlobalStyles, { styles: css `
          body,
          html {
            height: 100%;
          }
        ` }), _jsx(CacheProvider, { value: emotionCache, children: _jsxs(MuiThemeProvider, { theme: mergedTheme, children: [_jsx(CssBaseline, {}), _jsx(LocalizationProvider, { children: children })] }) })] }));
};
export default ThemeProvider;
