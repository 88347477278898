import {
  isBefore as isBeforeDate,
  isValid as isValidDate,
  parse as parseDate,
  sub as subDate,
} from "date-fns";

export const validateDateOfBirth = (
  val: unknown
): string | boolean | undefined => {
  if (val === undefined || val === "") {
    return;
  }

  if (typeof val !== "string") {
    return "Date is not valid";
  }

  const formatted = `${val.substring(0, 2)}/${val.substring(
    2,
    4
  )}/${val.substring(4)}`;
  const date = parseDate(formatted, "MM/dd/yyyy", new Date());

  if (!isValidDate(date)) {
    return "Date is not valid";
  }

  if (isBeforeDate(date, subDate(new Date(), { years: 150 }))) {
    return "Date is too far in the past";
  }
};

export const validatePhone = (val: unknown): string | boolean | undefined => {
  if (val === undefined || val === "") {
    return;
  }

  if (typeof val !== "string") {
    return "Phone number is not valid";
  }

  if (val.length !== 10) {
    return "Phone number must be 10 digits";
  }
};
