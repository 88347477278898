import _ from "lodash";
import { compose } from "recompose";
import { PureComponent } from "react";
import styled from "styled-components";

import { withFollowUpReviewed } from "../../graphql/FollowUp";
import EditAndResubmitButton from "../EditAndResubmitButton";
import RequestResendButton from "../RequestResendButton";
import FollowUpButton from "../FollowUpButton";
import UploadMissingFormButton from "../UploadMissingFormButton";
import ActionRequiredRowContainer from "./ActionRequiredRowContainer";
import BaseButton from "../BaseButton";

export const ActionRequiredBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  white-space: normal;
  padding: 15px;
  margin-left: 5px;

  b {
    margin: 0 5px;
  }
`;

const FollowUpButtons = styled(BaseButton)`
  margin-left: 10px;
  width: 175px;
`;

const { FOLLOW_UP_TYPES } = CONFIG.CONSTANTS;
const TYPES_TO_DISMISS_ON_CLICK = [FOLLOW_UP_TYPES.EDIT_AND_RESUBMIT];
class AutomatedRow extends PureComponent {
  dismiss = async () => {
    const { followUpReviewed, row } = this.props;
    await followUpReviewed({ variables: { id: row.model.followUp.id } });
  };

  render() {
    const { i, keyOff, row } = this.props;
    const authorization = row.model;
    const { patient } = authorization;
    const patientName = `${_.upperFirst(patient.firstName)} ${_.upperFirst(
      patient.lastName
    )}`;

    let textContent;
    let buttonContent;
    let ButtonComponent;
    if (authorization.followUp.type === FOLLOW_UP_TYPES.GENERAL_QUESTIONS) {
      textContent =
        `This authorization for ${patientName} requires a response to follow-up questions prior to receiving a response.` +
        ' You can respond to the payer directly through SamaCare by clicking "Respond to payer"';
      buttonContent = "Respond to payer";
      ButtonComponent = FollowUpButton;
    } else if (authorization.followUp.type === FOLLOW_UP_TYPES.PRINTABLE_FORM) {
      textContent =
        `This authorization for ${patientName} requires a response to a payer form that needs filling out prior to receiving a response.` +
        ' You can print the form by clicking "Print payer response"';
      buttonContent = "Print payer response";
      ButtonComponent = FollowUpButton;
    } else if (
      authorization.followUp.type === FOLLOW_UP_TYPES.EDIT_AND_RESUBMIT
    ) {
      textContent =
        `This authorization for ${patientName} requires changes be made to the information originally supplied payer form.` +
        ' Click "Edit and Resubmit" to make edits to your original fax and re-send the authorization';
      buttonContent = "Edit and Resubmit";
      ButtonComponent = EditAndResubmitButton;
    } else if (authorization.followUp.type === FOLLOW_UP_TYPES.RESUBMIT) {
      textContent =
        `This authorization for ${patientName} requires resending to this payer at a different fax number.` +
        ' You can resubmit this authorization to the payer directly through SamaCare by clicking "Resubmit"';
      buttonContent = "Resubmit";
      ButtonComponent = RequestResendButton;
    } else if (authorization.followUp.type === FOLLOW_UP_TYPES.MISSING_FORM) {
      textContent = `This authorization for ${patientName} requires an additional form. You can upload the form and resubmit this authorization to the payer directly through SamaCare by clicking "Upload Form".`;
      buttonContent = "Upload Form";
      ButtonComponent = UploadMissingFormButton;
    }

    return (
      <ActionRequiredRowContainer keyOff={keyOff} i={i} row={row}>
        <ActionRequiredBanner>
          {textContent}
          <FollowUpButtons onClick={this.dismiss}>Dismiss</FollowUpButtons>
          <ButtonComponent
            authorizationId={authorization.id}
            correspondence={authorization.latestCorrespondence}
            dismissFollowUp={this.dismiss}
            followUp={authorization.followUp}
            insuranceCompanyId={authorization.insuranceCompany?.id ?? null}
          >
            <FollowUpButtons
              onClick={() => {
                if (
                  _.includes(
                    TYPES_TO_DISMISS_ON_CLICK,
                    authorization.followUp.type
                  )
                ) {
                  this.dismiss();
                }
              }}
            >
              {buttonContent}
            </FollowUpButtons>
          </ButtonComponent>
        </ActionRequiredBanner>
      </ActionRequiredRowContainer>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withFollowUpReviewed)(AutomatedRow);
