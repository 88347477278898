import * as React from "react";
import styled from "styled-components";

type StackProps = {
  gap?: string;
  direction?: "row" | "column";
  children: React.ReactNode;
};

const defaultProps: Partial<StackProps> = {
  gap: "4px",
  direction: "row",
};

//TODO implement direction property
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props: Required<StackProps>) => props.direction};
  > * {
    flex: 1 1 auto;
    margin-right: ${(props: Required<StackProps>) => props.gap};
  }
  > *:last-child {
    margin-right: 0;
  }
`;

export const Stack = (props: StackProps): React.ReactElement => {
  const requiredProps = { ...defaultProps, ...props } as Required<StackProps>;
  return <StyledDiv {...requiredProps}>{props.children}</StyledDiv>;
};
