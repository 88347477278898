import { LoadingButton, Stack } from "@samacare/design";
import Dialog from "@samacare/design/core/Dialog";
import DialogActions from "@samacare/design/core/DialogActions";
import DialogContent from "@samacare/design/core/DialogContent";
import { Typography } from "@samacare/design/core";
import Upload from "@@components/Upload";
import { AxiosResponse } from "axios";

export const UploadModal = ({
  open,
  onClose,
  onDropSuccess,
  onDropError,
  loading = false,
  uploadWarning = false,
}: {
  open: boolean;
  onClose: () => void;
  onDropSuccess: (_p: null, res: AxiosResponse<any>) => Promise<void>;
  onDropError: () => void;
  loading?: boolean;
  uploadWarning?: boolean;
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth={true}>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Typography variant="h6" color="primary.main">
            Upload Communication
          </Typography>
          {uploadWarning && (
            <Typography variant="lead">
              Files uploaded here are NOT sent to the payer. Files are for your
              record keeping only.
            </Typography>
          )}
          <Upload onDropSuccess={onDropSuccess} onDropError={onDropError} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          data-cy="actionClose"
          variant="contained"
          type="submit"
          onClick={onClose}
          loading={loading}
        >
          Done
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
