import * as React from "react";
import { Typography, Box } from "@samacare/design/core";
import { useTheme } from "styled-components";
import { CheckCircle } from "@@ui-kit/Icons";

import { WidgetIcon } from "./WidgetIcon";

export const PurpleContainerForModal: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: `1px solid ${theme.primary}`,
        borderRadius: "8px",
        padding: 7.5,
        textAlign: "center",
        position: "relative",
        my: 2.5,
        mx: "auto",
        maxWidth: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          position: "absolute",
          top: "3px",
          left: "10px",
          marginBottom: 0.6,
        }}
      >
        {[0, 1, 2].map((index) => (
          <Box
            key={index}
            sx={{
              height: "3px",
              width: "3px",
              borderRadius: "50%",
              backgroundColor: theme.primary,
              display: "inline-block",
              my: 0,
              mx: 0.25,
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          height: "1px",
          width: "100%",
          backgroundColor: theme.primary,
          position: "absolute",
          top: "10px",
          left: 0,
        }}
      />
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          whiteSpace: "nowrap",
          paddingBottom: 2.5,
          color: theme.primary,
        }}
      >
        <CheckCircle color={theme.green} size="20px" /> Prior Authorization
        Submitted
      </Typography>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          bottom: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <WidgetIcon />
      </Box>
    </Box>
  );
};
