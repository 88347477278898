import MuiPopper, {
  PopperProps,
  PopperPlacementType,
} from "@mui/material/Popper";

const Popper: React.FC<PopperProps> = MuiPopper;

export default Popper;

export type { PopperProps, PopperPlacementType };
