import * as React from "react";
import _ from "lodash";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { EditorState, convertFromRaw, ContentBlock } from "draft-js";
import "./MarkdownEditor.css";

interface MarkdownEditorProps {
  defaultMarkdown?: string;
  customDecorators?: {
    strategy: (
      block: ContentBlock,
      callback: (start: number, end: number) => void
    ) => void;
    component: React.ComponentType;
  }[];
  markdown?: string;
  onChange?(markdown: string): void;
  readOnly?: boolean;
}

const markdownToState = (md?: string) => {
  if (md === undefined) return undefined;
  const rawData = markdownToDraft(md);
  const contentState = convertFromRaw(rawData);
  return EditorState.createWithContent(contentState);
};

const MarkdownEditor: React.VFC<MarkdownEditorProps> = ({
  defaultMarkdown,
  markdown,
  onChange,
  ...props
}) => {
  const additionalProps = {
    editorState: markdownToState(markdown),
    defaultEditorState: markdownToState(defaultMarkdown),
  };

  return (
    <Editor
      toolbar={{
        options: ["inline", "fontSize", "textAlign", "history"],
        inline: {
          options: ["bold", "italic", "underline", "superscript"],
        },
        fontSize: {
          options: [10, 16, 24, 48, 96],
        },
      }}
      {...props}
      {..._.omitBy(additionalProps, (v) => _.isUndefined(v))}
      toolbarHidden={props.readOnly}
      onChange={(e) => onChange && onChange(draftToMarkdown(e))}
    />
  );
};

export default MarkdownEditor;
