import { DrugOption, Query } from "@samacare/graphql";
import { useQuery } from "@apollo/client";
import { getDrugOptionsQuery } from "../graphql/DrugOptions";

export const useDrugOptions = (): [data: DrugOption[], loading: boolean] => {
  const { loading, data } = useQuery<{
    getDrugOptions: Query["getDrugOptions"];
  }>(getDrugOptionsQuery);

  return [data?.getDrugOptions ?? [], loading];
};
