import _ from "lodash";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  Box,
  Alert,
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@samacare/design/core";
import { useState, useEffect, useMemo } from "react";
import { StatusChip } from "./StatusChipForHealixDashboard";
import DashboardGql from "./Dashboard.gql";

import { Authorization } from "@samacare/graphql";
import { DashboardQuery, DashboardQueryVariables } from "@@generated/graphql";
import { GridToolbar } from "../../components/DataGrid/GridToolbar";

type DashboardAuthorization = {
  id: string;
  type: string;
  status: string;
  portalTitle: string;
  submittedAt: string;
  dateOfService: string;
  insuranceName: string;
  institutionName: string;
  firstName: string;
  lastName: string;
  dob: string;
  isArchived: string;
  updatedAt: string;
  createdBy: string;
  location: string;
  hcpcs: string;
  hcpcsName: string;
  prescriber: string;
  terminalAt: string;
  isSamaAssist: string;
};

type DashboardAuthorizationMapping = {
  [key in keyof DashboardAuthorization]: string | string[];
};

const mapping: DashboardAuthorizationMapping = {
  id: "id",
  type: "type",
  portalTitle: "portal.title",
  status: "status",
  terminalAt: "terminalAt",
  isSamaAssist: "isSamaAssist",
  submittedAt: "submittedAt",
  isArchived: "isArchived",
  updatedAt: "updatedAt",
  dateOfService: "dateOfService",
  insuranceName: "insuranceCompany.name",
  institutionName: "institution.name",
  firstName: "patient.firstName",
  lastName: "patient.lastName",
  dob: "patient.dob",
  hcpcs: "config.HCPCS_0",
  hcpcsName: "config.PRIMARY_DRUG_NAME",
  location: "config.LOCATION_NAME",
  createdBy: ["createdBy.firstName", "createdBy.lastName"],
  prescriber: ["config.PRESCRIBER_FIRST_NAME", "config.PRESCRIBER_LAST_NAME"],
};

function getDataGridMapping(node: Authorization): DashboardAuthorization {
  const data: Partial<DashboardAuthorizationMapping> = {};
  const entries = Object.entries(mapping);

  for (const [key, target] of entries) {
    if (typeof target === "string") {
      data[key as keyof DashboardAuthorizationMapping] = _.get(
        node,
        target,
        ""
      ) as string;
    } else {
      data[key as keyof DashboardAuthorizationMapping] = target.map(
        (item) => _.get(node, item, "") as string
      );
    }
  }

  return data as DashboardAuthorization;
}

function getDataMapping(field: string) {
  const result = _.get(mapping, field, "") as string | string[];
  return typeof result === "string" ? result : result[0];
}

const columns: GridColDef<DashboardAuthorization>[] = [
  { field: "id", headerName: "ID", type: "number" },
  {
    headerName: "Institution",
    field: "institutionName",
  },
  {
    headerName: "First Name",
    field: "firstName",
  },
  {
    headerName: "Last Name",
    field: "lastName",
  },
  {
    headerName: "Date of Birth",
    field: "dob",
    type: "date",
    valueGetter: ({ value }) => value && moment.utc(value).toDate(),
    valueFormatter: ({ value }) =>
      value && moment.utc(value).format("MM/DD/YYYY"),
  },
  {
    headerName: "Status",
    field: "status",
    type: "singleSelect",
    valueOptions: Object.values(
      window.CONFIG.CONSTANTS.AUTHORIZATION_STATUSES
    ).map((value) => ({
      value,
      label: _.startCase(value.replace(/_/g, " ")),
    })),
    renderCell: ({ value }) => <StatusChip status={value} />,
  },
  {
    headerName: "Type",
    field: "type",
    type: "singleSelect",
    valueOptions: [
      "external",
      "form",
      "portal",
      "portal_external",
      "referral",
      "multi_party",
      "enrollment",
    ].map((value) => ({
      value,
      label: _.startCase(value.replace(/_/g, " ")),
    })),
    valueFormatter: ({ value }) => value && _.startCase(_.lowerCase(value)),
  },
  {
    headerName: "Drug Code",
    field: "hcpcs",
  },
  {
    headerName: "Drug Name",
    field: "hcpcsName",
  },
  {
    headerName: "Date of Service",
    field: "dateOfService",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    headerName: "Payer",
    field: "insuranceName",
  },
  {
    headerName: "Portal",
    field: "portalTitle",
    filterable: false,
  },
  {
    headerName: "Prescriber",
    field: "prescriber",
    valueGetter: ({ value }) => `${value[0]} ${value[1]}`,
  },
  {
    headerName: "Location",
    field: "location",
  },
  {
    headerName: "Assignee",
    field: "createdBy",
    valueGetter: ({ value }) => `${value[0]} ${value[1]}`,
  },
  {
    headerName: "Submitted",
    field: "submittedAt",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
  },
  {
    headerName: "Terminal",
    field: "terminalAt",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
  },
  {
    headerName: "Last Updated",
    field: "updatedAt",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && new Date(value).toLocaleString(),
  },
  {
    headerName: "SamaAssist",
    field: "isSamaAssist",
    type: "boolean",
    filterable: false,
    sortable: false,
  },
  {
    headerName: "Archived",
    field: "isArchived",
    type: "boolean",
  },
];

export const Dashboard: React.FC = () => {
  /**
   * @state sortModel
   */
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "id", sort: "asc" },
  ]);
  /**
   * @state filterModel
   */
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  /**
   * @state paginationModel
   */
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  /**
   * @memo variables
   */
  const variables = useMemo<DashboardQueryVariables>(
    () => ({
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
      sort: sortModel.map(({ field, sort }) => ({
        field: getDataMapping(field),
        sort: sort?.toUpperCase() as "ASC" | "DESC",
      })),
      filter: {
        logicOperator: filterModel.logicOperator,
        items: filterModel.items
          .filter(
            ({ value, operator }) => ["isEmpty"].includes(operator) || value
          )
          .map(({ field, operator, value }) => ({
            field: getDataMapping(field),
            type:
              columns.find(({ field: columnField }) => columnField === field)
                ?.type ?? "string",
            operator,
            value: typeof value === "string" ? value : value?.join(","),
          })),
      },
    }),
    [paginationModel, filterModel, sortModel]
  );

  const { data, error, loading } = useQuery<
    DashboardQuery,
    DashboardQueryVariables
  >(DashboardGql, { variables, fetchPolicy: "network-only" });

  const { rows = [], count } = data?.authorizations || {};

  const [rowCountState, setRowCountState] = useState(count ?? 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      count !== undefined ? count : prevRowCountState
    );
  }, [count, setRowCountState]);

  return (
    <Box mx={4} pb={10} height="90%">
      <h1>Dashboard</h1>
      {error && <Alert severity="error">{error.message}</Alert>}
      <DataGridPro
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            disableSearch: true,
          },
        }}
        loading={loading}
        columns={columns}
        rows={
          rows?.map((row) => getDataGridMapping(row as Authorization)) ?? []
        }
        rowCount={rowCountState}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        filterMode="server"
        onFilterModelChange={setFilterModel}
      />
    </Box>
  );
};
