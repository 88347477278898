import { Box } from "@samacare/design/core";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "@samacare/form";

import { EnrollmentSection } from "./EnrollmentSection";
import { useEnrollmentContext } from "./EnrollmentProvider";
import useUpdateNewEnrollmentById from "../../hooks/useUpdateNewEnrollmentById";
import { EnrollmentActions } from "./EnrollmentActions";

import { EnrollmentProgramField } from "../../../form/EnrollmentProgramField";
import { useStepContext } from "./StepProvider";

import type { EnrollmentProgram as EnrollmentProgramType } from "@samacare/graphql";

type EnrollmentProgramForm = {
  EnrollmentProgram: EnrollmentProgramType | null;
};

export const EnrollmentProgram: React.FC = () => {
  const alert = useAlert();
  const history = useHistory();
  const { onNextStep } = useStepContext();
  const [update, { loading }] = useUpdateNewEnrollmentById();
  const { enrollment, isSubmitted } = useEnrollmentContext();

  const search = new URLSearchParams(history.location.search);

  const methods = useForm<EnrollmentProgramForm>({
    defaultValues: {
      // It seems like the react-hook-form DefaultValues<T> type does something weird
      // to the nested type that makes questionnaire.customisations not compatible..
      // @ts-ignore
      EnrollmentProgram: enrollment?.EnrollmentProgram || null,
    },
  });

  const { watch } = methods;

  const onSubmit = async (data: EnrollmentProgramForm) => {
    const id = data?.EnrollmentProgram?.id;

    if (id != null && enrollment?.id != null) {
      try {
        await update({
          variables: {
            id: Number(enrollment?.id),
            patch: {
              EnrollmentProgramId: parseInt(id),
            },
          },
        });
        alert.info("Successfully update enrollment program!");
      } catch (error) {
        alert.error("There was an error updating enrollment program");
      }
      onNextStep();
    } else if (id && search.get("programId") !== id) {
      onNextStep(`?programId=${id}`);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        id="EnrollmentProgram"
        name="EnrollmentProgram"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box sx={{ width: "50%" }}>
          <EnrollmentSection title="Program">
            <EnrollmentProgramField
              name="EnrollmentProgram"
              disabled={isSubmitted}
            />
          </EnrollmentSection>
        </Box>
        <EnrollmentActions
          loading={loading}
          disabled={watch().EnrollmentProgram == null}
        />
      </form>
    </FormProvider>
  );
};
