import _ from "lodash";

export const removeNonDigits = (text: string): string =>
  _.replace(text, /\D/g, "");

// Will replace all instances of {{key}} in the template string with the value of the key in the variables object
export function interpolate(
  template: string | null | undefined,
  variables: Record<string, any>
) {
  return template
    ? template.replace(/\{\{(\w+)\}\}/g, (_x, key) => variables[key] || "")
    : "";
}
