import axios from "axios";
import {
  ColumnMap,
  IngestJob,
  Mutation,
  MutationCreateIngestJobArgs,
} from "@samacare/graphql";
import createIngestJobMutation from "../graphql/CreateIngestJobMutation.gql";

import { useMutation } from "@apollo/client";
import { S3UploadInfo, prepareS3UploadData } from "../util/s3Utils";
import { NOT_IN_FILE } from "../routes/Admin/Upload/UploadConstants";

const generateColumnMappings = (
  fromByToMappings: Map<string, string>,
  fileColumnNames: string[]
) => {
  const toByFromMap = new Map<string, string | null>();
  for (const colName of fileColumnNames) {
    toByFromMap.set(colName, null);
  }

  fromByToMappings.forEach((fromValue, toValue) => {
    if (fromValue !== NOT_IN_FILE) {
      toByFromMap.set(fromValue, toValue);
    }
  });

  const columnMappings: ColumnMap[] = [];
  toByFromMap.forEach((toValue, fromValue) => {
    columnMappings.push({ from: fromValue, to: toValue });
  });

  return columnMappings;
};

const useCreateIngestJob = () => {
  const [createIngestJob] = useMutation<
    { createIngestJob: Mutation["createIngestJob"] },
    MutationCreateIngestJobArgs
  >(createIngestJobMutation);

  const create = async (
    file: File,
    fromByToMappings: Map<string, string> | null,
    fileColumnNames: string[]
  ): Promise<IngestJob | null> => {
    // When no columnMappings are passed in, we are using an existing transform
    const columnMappings =
      fromByToMappings &&
      generateColumnMappings(fromByToMappings, fileColumnNames);
    const result = await createIngestJob({
      variables: {
        fileName: file.name,
        columnMappings,
      },
    });

    const { presignedPost } = result.data?.createIngestJob ?? {};

    if (presignedPost) {
      const fileUploadData = prepareS3UploadData(
        file,
        presignedPost.fields as S3UploadInfo
      );
      await axios.post(presignedPost?.url, fileUploadData);
    }

    return result.data?.createIngestJob?.ingestJob ?? null;
  };

  return create;
};

export default useCreateIngestJob;
