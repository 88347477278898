import { useQuery } from "@apollo/client";

import { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Box,
  DataGridPro,
  GridColDef,
  Stack,
  GridRenderCellParams,
  IconButton,
} from "@samacare/design/core";
import { TrackerConfig } from "@samacare/graphql";
import { GetTrackerConfigsQuery } from "@@generated/graphql";
import EditIcon from "@samacare/design/core/icons/Edit";
import { GridToolbar } from "../../../components/DataGrid/GridToolbar";
import GridWrapper from "../../../components/DataGrid/GridWrapper";

import GetTrackerConfigs from "../../../graphql/GetTrackerConfigs.gql";
import { LeftRightCenterAll } from "@samacare/component";
import { UpsertTrackerConfigDialog } from "./UpsertTrackerConfigDialog";
import { DeleteTrackerConfigIconButton } from "./DeleteTrackerConfigIconButton";
import { wait } from "../../../../helpers/wait";
import Check from "@samacare/design/core/icons/Check";
import Close from "@samacare/design/core/icons/Close";

const getLabelForCountColumn = (
  value: string[],
  max: number,
  shortName: string
) => {
  if (value.length === 0) {
    return "All";
  } else if (value.length <= max) {
    return value.map((v) => v).join(", ");
  }
  return `(${value.length}) ${shortName}`;
};

export const TrackerConfigs: React.FC = () => {
  const [selectedTrackerConfigForEdit, setSelectedTrackerConfigForEdit] =
    useState<TrackerConfig | null>(null);
  const [showList, setShowList] = useState(false);

  const { data, loading } = useQuery<GetTrackerConfigsQuery>(GetTrackerConfigs);
  const trackerConfigList = useMemo(
    () => data?.trackerConfigs ?? [],
    [data]
  ) as TrackerConfig[];

  const gridColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "enabled",
      headerName: "Enabled",
      width: 50,
      renderCell: (props) => (props.value ? <Check /> : <Close />),
    },
    { field: "trackerKey", headerName: "Tracker Keys", width: 200 },
    {
      field: "runAtHoursInLaTz",
      headerName: "Run At Hours (LA Tz)",
      width: 150,
    },
    {
      field: "authTypes",
      headerName: "Auth Types",
      width: 100,
      renderCell: (props) =>
        getLabelForCountColumn(props.value, 1, "Auth Types"),
    },
    {
      field: "portalKeys",
      headerName: "Portal Keys",
      width: 100,
      renderCell: (props) => getLabelForCountColumn(props.value, 1, "Keys"),
    },
    {
      field: "portalIds",
      headerName: "Portal Ids",
      width: 100,
      renderCell: (props) => getLabelForCountColumn(props.value, 7, "Ids"),
    },
    {
      field: "insuranceCompanyIds",
      headerName: "Insurance Company Ids",
      width: 100,
      renderCell: (props) =>
        getLabelForCountColumn(props.value, 7, "Companies"),
    },
    { field: "batchKind", headerName: "Batch Kind", width: 150 },
    {
      field: "exclusions",
      headerName: "# Exclusions",
      width: 100,
      renderCell: (props) => props.value.length,
    },
    {
      field: "buttons",
      headerName: "",
      hideable: false,
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (props: GridRenderCellParams) => (
        <Stack direction="row" width="100%" justifyContent="flex-end">
          <IconButton
            onClick={() =>
              setSelectedTrackerConfigForEdit(props.row as TrackerConfig)
            }
          >
            <EditIcon />
          </IconButton>
          <DeleteTrackerConfigIconButton
            trackerConfig={props.row as TrackerConfig}
          />
        </Stack>
      ),
    },
  ];

  // Autosize on mount was not working when just gating with loading,
  // needed to add the wait to ensure proper autosizing
  // Still doesn't work sometimes and breaks when new is clicked
  useEffect(() => {
    if (trackerConfigList && !loading) {
      void (async () => {
        await wait(250);
        setShowList(true);
      })();
    }
  }, [trackerConfigList, loading]);

  return (
    <GridWrapper sx={{ marginLeft: 0, marginRight: 0, marginTop: 2 }}>
      {showList && (
        <DataGridPro
          rows={trackerConfigList}
          columns={gridColumns}
          autosizeOnMount
          autosizeOptions={{ expand: true }}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: () => (
              <Box display="flex" justifyContent="center">
                <Typography variant="body">
                  No tracker configs have been added
                </Typography>
              </Box>
            ),
            noResultsOverlay: () => (
              <LeftRightCenterAll sx={{ height: "27px" }}>
                <Typography variant="body">No tracker configs found</Typography>
              </LeftRightCenterAll>
            ),
          }}
          slotProps={{
            toolbar: {
              onNewClick: () =>
                setSelectedTrackerConfigForEdit({} as TrackerConfig),
              newButtonDataCy: "actionNewTrackerConfig",
            },
          }}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
            sorting: {
              sortModel: [{ field: "title", sort: "asc" }],
            },
          }}
          sx={{ border: 0 }}
        />
      )}

      <UpsertTrackerConfigDialog
        trackerConfig={selectedTrackerConfigForEdit}
        trackerConfigList={trackerConfigList}
        onClose={() => setSelectedTrackerConfigForEdit(null)}
      />
    </GridWrapper>
  );
};
