import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
} from "@apollo/client";
import axios from "axios";
import uuid from "uuid/v4";
import { setContext } from "@apollo/client/link/context";
import { signOut, fetchAuthSession } from "aws-amplify/auth";
import { push } from "connected-react-router";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

import store from "../reduxStore";
import ROUTE_PATHS from "../routes/ROUTE_PATHS";
import { setLoginRedirect } from "../reducers/loginRedirectReducer";

export default function createClient(): ApolloClient<NormalizedCacheObject> {
  const isLegacyAuth = !window.CONFIG.COGNITO_ENABLED;

  const headersLink = setContext(async () => {
    const session = await fetchAuthSession();
    const headers: Record<string, string> = {
      "X-Request-Id": uuid(),
    };

    if (!isLegacyAuth && session.tokens?.idToken) {
      headers.Authorization = `Bearer ${session.tokens.idToken}`;
    }
    return {
      headers,
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) =>
        // eslint-disable-next-line no-console
        console.error(`[GraphQL error]: Message: ${message}`)
      );
    }

    if (isLegacyAuth) {
      if (
        networkError &&
        (networkError as ServerError)?.statusCode === 401 &&
        !window.location.href.includes(ROUTE_PATHS.RESET_PASSWORD_BASE.path)
      ) {
        // Don't redirect to '/login', otherwise we'll enter a redirect loop
        if (!window.location.href.includes(ROUTE_PATHS.LOGIN.path)) {
          store.dispatch(setLoginRedirect(window.location.href));
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        axios.post("/logout");
        store.dispatch(push(ROUTE_PATHS.LOGIN.path));
      } else {
        // eslint-disable-next-line no-console
        console.warn(`[Network error]`, networkError);
      }
    }

    if (
      !isLegacyAuth &&
      networkError &&
      (networkError as ServerError).statusCode === 401
    ) {
      void signOut();
      window.location.reload();
    } else if (!isLegacyAuth && networkError) {
      // eslint-disable-next-line no-console
      console.warn(`[Network error]`, networkError);
    }
  });

  const uploadLink = createUploadLink({
    uri: "/graphql",
    credentials: "same-origin",
  });

  return new ApolloClient({
    link: ApolloLink.from([headersLink, errorLink, uploadLink]),
    cache: new InMemoryCache(),
  });
}
