import React, { ChangeEvent, Ref, ForwardedRef } from "react";
import _ from "lodash";
import { useSelector, type State } from "../../../app/configureStore";
import { BaseText } from "./StyledComponents";
import { SegmentNote } from "./SegmentNote";

interface SegmentTextProps {
  item: {
    key: string;
    maxLength?: number;
    onBlur?: (e: React.FocusEvent) => void;
    onKeyPress?: (e: React.KeyboardEvent) => void;
  };
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChange: (value: Record<string, any>) => void;
  disabled?: boolean;
  highlightIfMissing?: boolean;
  style?: React.CSSProperties;
}

const SegmentText = React.forwardRef(
  (
    {
      item,
      onChange,
      handleChange,
      disabled,
      highlightIfMissing,
      style,
    }: SegmentTextProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const authorizationConfig = useSelector((state) => state.form.results);
    const result = item.key
      ? authorizationConfig[item.key as keyof State["form"]["results"]]
      : null;
    const onBlur = _.get(item, "onBlur", () => {});
    const onKeyPress = _.get(item, "onKeyPress", () => {});

    return (
      <>
        <BaseText
          ref={ref as ForwardedRef<HTMLInputElement>}
          style={style}
          disabled={disabled}
          onChange={(event) => {
            if (!_.isNil(onChange)) {
              onChange(event);
            }
            let val = event.target.value;
            if (item.maxLength) {
              val = event.target.value.slice(0, item.maxLength);
            }
            handleChange({ [item.key]: val });
          }}
          id={item.key}
          data-cy={_.camelCase(`field_${item.key}`)}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          value={(result as string) || ""}
          autoComplete="new-password"
          {...{ highlight: highlightIfMissing ? !result : undefined }} //Hack to make typescript happy
        />
        <SegmentNote field={item.key} />
      </>
    );
  }
);

export default SegmentText;
