import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import Section from "AuthorizationSharedComponents/Section";
import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";
import { transformSections } from "../../../util/sectionConfigurationManager";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const { DEFAULT_FIELDS } = CONFIG;

export class Prescriber extends PureComponent {
  state = {
    isSubmitting: false,
  };

  /*
   * This PDF submit functionality originally exists in FORM_DETAILS step (STEP 4)
   * for PA submission.
   * In Novartis PA submission workflow, we removed the FORM_DETAILS step,
   * so we added the novartisSubmitOverride in
   * PRESCRIBER_AND_LOCATION_DETAILS step (STEP 3)
   * for PDF submission
   */
  novartisSubmitOverride = () => {
    const { onSubmit, authorization } = this.props;
    onSubmit(
      () => {
        this.setState({ isSubmitting: true });
      },
      () => {
        this.setState({ isSubmitting: false });
      },
      () => {
        if (
          !_.includes(
            [
              CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key,
              CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key,
            ],
            authorization.type
          )
        ) {
          return false;
        }
        return true;
      },
      this.onInvalidFormState
    );
  };

  render() {
    const { back, nextStep, authorization, disabled, children, isNovartis } =
      this.props;
    const { isSubmitting } = this.state;
    return (
      <FormContainer>
        <Section
          section={
            transformSections(
              [
                {
                  items: isNovartis
                    ? [
                        [
                          DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME,
                          DEFAULT_FIELDS.PRESCRIBER_LAST_NAME,
                        ],
                        [DEFAULT_FIELDS.PRESCRIBER_NPI],
                        [
                          {
                            ...DEFAULT_FIELDS.PRESCRIBER_ADDRESS,
                            required: true,
                          },
                        ],
                        [
                          { ...DEFAULT_FIELDS.PRESCRIBER_CITY, required: true },
                          {
                            ...DEFAULT_FIELDS.PRESCRIBER_STATE,
                            required: true,
                          },
                          { ...DEFAULT_FIELDS.PRESCRIBER_ZIP, required: true },
                        ],
                      ]
                    : [
                        [
                          DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME,
                          DEFAULT_FIELDS.PRESCRIBER_LAST_NAME,
                        ],
                        [DEFAULT_FIELDS.PRESCRIBER_NPI],
                        [DEFAULT_FIELDS.PRESCRIBER_ADDRESS],
                        [
                          DEFAULT_FIELDS.PRESCRIBER_CITY,
                          DEFAULT_FIELDS.PRESCRIBER_STATE,
                          DEFAULT_FIELDS.PRESCRIBER_ZIP,
                        ],
                      ],
                  title: "Provider & Location Details",
                },
              ],
              authorization.formFieldConfig
            )[0]
          }
          disabled={disabled}
        />
        {!children &&
          authorization.status !==
            CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PREMIUM_RX_SUPPORT && (
            <FormSubmitButtons
              back={back}
              submitText={
                authorization.type ===
                  CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key &&
                authorization.submissionPhoneNumber == null
                  ? "Complete"
                  : undefined
              }
              submit={
                isNovartis
                  ? () => {
                      this.novartisSubmitOverride();
                      nextStep;
                    }
                  : nextStep
              }
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          )}
      </FormContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    disabled: state.form.disabled || ownProps.disabled,
  };
}

export default connect(mapStateToProps, () => {})(withAlert()(Prescriber));
