import * as React from "react";
import styled from "styled-components";
import { HelpTag } from "../../../layout/HelpTag";

export interface Tab {
  title: string;
  help?: string;
  tabTitle: string;
  value?: string;
}

const Container = styled.div<{
  showHeaderLine: boolean;
  marginBottom?: string;
}>`
  display: flex;
  border-bottom: ${(props) =>
    props.showHeaderLine ? `1px solid ${props.theme.darkGray}` : null};
  margin-bottom: ${(props) =>
    props.marginBottom != null ? props.marginBottom : "20px"};
`;

const FilterContainer = styled.div<{
  selected: boolean;
}>`
  padding: 7px 12px;
  text-align: center;
  color: ${(props) => (props.selected ? "black" : props.theme.darkGray)};
  font-weight: 700;
  border-bottom: 3px solid
    ${(props) => (props.selected ? props.theme.purple : "transparent")};
  margin-right: 5px;

  &:hover {
    cursor: pointer;
    color: black;
    border-bottom: 3px solid ${(props) => props.theme.purple};
  }
`;

const TabbedHeader: React.VFC<{
  marginBottom?: string;
  showHeaderLine: boolean;
  style?: React.CSSProperties;
  selected: Tab | undefined;
  tabs: Tab[];
  filters?: React.ReactNode;
  onSelect: (tab: Tab) => void;
}> = ({
  marginBottom,
  showHeaderLine = true,
  style,
  tabs,
  selected = tabs[0],
  filters,
  onSelect,
}) => {
  return (
    <Container marginBottom={marginBottom} showHeaderLine={showHeaderLine}>
      {tabs.map((tab) => (
        <FilterContainer
          id={`pendo_adminTabbedHeader_${tab.tabTitle}`}
          data-cy={`actionSignatureTab_${tab.tabTitle}`}
          key={tab.tabTitle}
          onClick={() => {
            onSelect(tab);
          }}
          selected={tab.tabTitle === selected.tabTitle}
          style={style}
        >
          {tab.tabTitle}
          {tab.help && <HelpTag text={tab.help} size="sm" />}
        </FilterContainer>
      ))}
      {filters}
    </Container>
  );
};

/**
 * @deprecated Use a MUI component instead
 */
export default TabbedHeader;
