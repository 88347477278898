import { PureComponent } from "react";
import styled from "styled-components";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
} from "AuthorizationSharedSteps/SignatureSection/SignatureInput";

import { generateInput } from "../taggerUtils";
import InputRender from "./InputRender";
import DroppableTagger from "./DroppableTagger";
import { displayByPropType, flatInputPropType } from "../taggerPropTypes";

const Container = styled.div`
  width: ${(props) => props.theme.pdfWidth};
  min-width: ${(props) => props.theme.pdfWidth};
  position: relative;
  margin: 0 10px;
  height: ${(props) => props.theme.pdfHeight};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
`;

const PDFImage = styled.img`
  width: 100%;
  z-index: ${(props) => props.theme.zDefault};
  border: 1px solid ${(props) => props.theme.lightGray};
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.zLow};
`;

const MIN_SIZE = 12;
const CREATING_ID = "creating";

const types = CONFIG.CONSTANTS.TAGGER_INPUT_TYPES;

const getDefaultType = (box) => {
  if (box.width < MIN_SIZE + 5 && box.height < MIN_SIZE + 5) {
    return types.CHECK.key;
  }
  return null;
};

const getMinDimensions = (type) => {
  const scale = 4;

  switch (type) {
    case types.SIGNATURE.key:
      return {
        x: Math.floor(CANVAS_WIDTH / scale),
        y: Math.floor(CANVAS_HEIGHT / scale),
      };
    case types.CHECK.key:
    case types.CHECK_CIRCLE.key:
    case types.RADIO.key:
    case types.RADIO_CIRCLE.key:
      return { x: MIN_SIZE, y: MIN_SIZE };
    default:
      return { x: 12, y: 12 };
  }
};

const NO_BOX_TYPES = _.map([types.RADIO_GROUP], "key");
class Tagger extends PureComponent {
  static propTypes = {
    addBox: PropTypes.bool.isRequired,
    createInput: PropTypes.func.isRequired,
    currentPageNumber: PropTypes.number.isRequired,
    displayBy: displayByPropType.isRequired,
    inputs: PropTypes.arrayOf(flatInputPropType).isRequired,
    selectedBoxIndex: PropTypes.number,
    selectedId: PropTypes.string.isRequired,
    setImageRef: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    updateInput: PropTypes.func.isRequired,
  };

  state = {
    creating: null,
  };

  overlay = null;

  create = (event) => {
    const { inputs, selectedId, addBox, currentPageNumber } = this.props;
    const bounds = this.overlay.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;

    const newBox = {
      x,
      y,
      width: MIN_SIZE,
      height: MIN_SIZE,
      page: currentPageNumber,
    };

    const selected = _.find(inputs, { id: selectedId });
    if (selected && (_.isEmpty(selected.boxes) || addBox)) {
      if (!_.includes(NO_BOX_TYPES, selected.type)) {
        // When creating a box on an input without boxes, or on multibox, concat a new box
        this.setState({
          creating: {
            ...selected,
            boxes: (selected.boxes || []).concat(newBox),
          },
        });
      } else {
        // When creating a box on a non-boxable element, create a new input
        this.setState({
          creating: {
            id: CREATING_ID,
            type: types.TEXT.key,
            boxes: [newBox],
            items: {},
          },
        });
      }
    } else {
      const newInput = generateInput({
        id: CREATING_ID,
        type: types.TEXT.key,
        boxes: [newBox],
      });
      this.setState({ creating: _.omit(newInput, "items") });
    }
  };

  onMouseMove = (event) => {
    const { creating } = this.state;

    if (creating) {
      const bounds = this.overlay.getBoundingClientRect();

      const x = event.clientX - bounds.left;
      const y = event.clientY - bounds.top;

      const newBoxes = _.map(creating.boxes, (box, i) => {
        if (i !== creating.boxes.length - 1) {
          return box;
        }

        return {
          ...box,
          height: Math.max(y - box.y, getMinDimensions(creating.type).y),
          width: Math.max(x - box.x, getMinDimensions(creating.type).x),
        };
      });

      this.setState({
        creating: { ...creating, boxes: newBoxes },
      });
    }
  };

  render() {
    const {
      setImageRef,
      currentPage,
      inputs,
      selectedId,
      setSelected,
      updateInput,
      createInput,
      displayBy,
      selectedBoxIndex,
      currentPageNumber,
      canFreeResize,
    } = this.props;
    const { creating } = this.state;

    let toShow = inputs;
    if (creating) {
      toShow = inputs.concat(creating);
    }

    return (
      <Container>
        <PDFImage
          data-cy="controlPdfImage"
          ref={(ref) => setImageRef(ref)}
          src={currentPage}
        />
        <DroppableTagger>
          <Overlay
            data-cy="controlPdfImageOverlay"
            ref={(overlay) => {
              this.overlay = overlay;
            }}
            onMouseDown={this.create}
            onMouseUp={() => {
              if (creating && creating.id === CREATING_ID) {
                createInput(
                  {
                    ..._.omit(creating, "id"),
                    type: getDefaultType(creating.boxes[0]),
                  },
                  _.get(_.find(inputs, { id: selectedId }), "parentId")
                );

                this.setState({ creating: null });
              } else if (creating) {
                updateInput(creating.id, creating, {
                  selectedBoxIndex: creating.boxes.length - 1,
                });

                this.setState({ creating: null });
              }
            }}
            onMouseMove={this.onMouseMove}
          >
            {_.map(toShow, (item, index) => (
              <InputRender
                key={`tagger_input_${item.id}_${index}`}
                input={item}
                isSelected={selectedId === item.id}
                setSelected={setSelected}
                selectedBoxIndex={selectedBoxIndex}
                updateInput={updateInput}
                overlay={this.overlay}
                canDrag={creating !== item}
                displayBy={displayBy}
                currentPageNumber={currentPageNumber}
                canFreeResize={canFreeResize}
                minWidth={getMinDimensions(item.type).x}
                minHeight={getMinDimensions(item.type).y}
              />
            ))}
          </Overlay>
        </DroppableTagger>
      </Container>
    );
  }
}

export default Tagger;
