import Backdrop from "../Backdrop";

import "./LoadingSpinner.css";

const LoadingSpinner = ({ open }) => {
  if (open) {
    return (
      <div>
        <Backdrop />
        <div className="loader" />
      </div>
    );
  }
  return <span />;
};

/**
 * @deprecated Use a MUI component instead
 */
export default LoadingSpinner;
