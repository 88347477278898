import { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import Select from "react-select";

const StyledSelect = styled(Select)`
  width: 100%;
  border: ${(props) => (props.highlight ? `2px solid ${props.theme.red}` : "")};
`;

class SegmentOptions extends PureComponent {
  handleOptionChange = (keyToSet) => {
    const { handleChange, item } = this.props;
    handleChange(
      _.assign(
        _.reduce(
          item.options,
          (toSet, obj) => _.assign(toSet, { [obj.key]: null }),
          {}
        ),
        { [keyToSet]: "On" }
      )
    );
  };

  render() {
    const { item, result, disabled, highlightIfMissing, id } = this.props;

    const options = _.map(item.options, (option) => ({
      id: option.key,
      value: option.key,
      label: option.title,
    }));
    const onBlur = _.get(item, "onBlur", () => {});
    return (
      <StyledSelect
        id={id}
        highlight={highlightIfMissing && !result}
        isDisabled={disabled}
        onChange={(e) => {
          this.handleOptionChange(_.get(e, "value"));
          onBlur(_.get(e, "value"));
        }}
        options={options}
        value={options.find((option) => option.value === result)}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            minHeight: "46px",
          }),
        }}
        isClearable
        isSearchable
      />
    );
  }
}

function mapStateToProps(state, props) {
  return _.reduce(
    _.pick(state.form.results, _.map(props.item.options, "key")),
    (total, value, key) => (value ? { result: key } : total),
    { result: null }
  );
}

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentOptions);
