import styled, { keyframes } from "styled-components";
import { invert } from "polished";

function identity<T>(value: T): T {
  return value;
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export type SpinnerProps = React.ComponentProps<typeof Spinner>;

const DEFAULT_INSET_COLOR = "hsl(0, 0%, 25%)";

const DEFAULT_BAR_COLOR = "hsl(0, 0%, 100%)";

/**
 * @deprecated Use a MUI component instead
 */
export const Spinner = styled.div.attrs({
  "aria-busy": true,
  "aria-label": "Loading Indicator",
})<{
  color?: string;
  colorTop?: string;
  height?: number;
  inverted?: boolean;
  width?: number;
}>`
  animation: ${spin} 1s infinite linear;
  border: 4px solid
    ${({ inverted = false, color }) =>
      color ?? (inverted ? invert : identity)(DEFAULT_INSET_COLOR)};
  border-radius: 50%;
  border-top-color: ${({ inverted = false, colorTop }) =>
    colorTop ?? (inverted ? invert : identity)(DEFAULT_BAR_COLOR)};
  height: ${({ height = 20 }) => height}px;
  width: ${({ width = 20 }) => width}px;
`;
