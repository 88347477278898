import { PureComponent } from "react";
import styled from "styled-components";
import _ from "lodash";
import Resizable from "re-resizable";
import PropTypes from "prop-types";

import DraggableTag from "./DraggableTag";
import { displayByPropType, flatInputPropType } from "../taggerPropTypes";

const CONTAINER_BORDER_WIDTH = 1;
const Container = styled.div`
  .resizeable {
    border: ${CONTAINER_BORDER_WIDTH}px dotted black;
  }
  .selected-resize {
    border: ${CONTAINER_BORDER_WIDTH}px solid red;
  }
`;

const Tag = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.backgroundColor};
  font-size: 10px;
  font-weight: 700;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
`;

const ItemContainer = styled.div`
  top: ${(props) => props.y - CONTAINER_BORDER_WIDTH}px;
  left: ${(props) => props.x - CONTAINER_BORDER_WIDTH}px;
  position: absolute;
`;

const ErrorText = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.red};
`;

const replaceBox = (boxes, updated, boxIndex) =>
  _.map(boxes, (box, i) => {
    if (i === boxIndex) {
      return updated;
    }
    return box;
  });

const IMPORTANCES = CONFIG.CONSTANTS.INPUT_IMPORTANCE;
class InputRender extends PureComponent {
  static propTypes = {
    canDrag: PropTypes.bool.isRequired,
    currentPageNumber: PropTypes.number.isRequired,
    displayBy: displayByPropType.isRequired,
    input: flatInputPropType.isRequired,
    isSelected: PropTypes.bool.isRequired,
    minHeight: PropTypes.number.isRequired,
    minWidth: PropTypes.number.isRequired,
    selectedBoxIndex: PropTypes.number,
    setSelected: PropTypes.func.isRequired,
    updateInput: PropTypes.func.isRequired,
  };

  state = { selectedOffset: {} };

  selectBox = (e, boxIndex) => {
    e.stopPropagation();

    const { setSelected, input } = this.props;
    const bounds = e.target.getBoundingClientRect();

    this.setState({
      selectedOffset: {
        x: Math.floor(e.clientX - bounds.x),
        y: Math.floor(e.clientY - bounds.y),
      },
    });

    setSelected(input.id, boxIndex);
  };

  updateInputWithNewBox = (newBox, boxIndex) => {
    const { input, updateInput } = this.props;

    updateInput(input.id, {
      ...input,
      boxes: replaceBox(input.boxes, newBox, boxIndex),
    });
  };

  moveSelected = (e, boxIndex) => {
    const { input, overlay } = this.props;
    const { selectedOffset } = this.state;

    const overlayBoundingRect = overlay.getBoundingClientRect();
    const x = e.clientX - overlayBoundingRect.x - selectedOffset.x;
    const y = e.clientY - overlayBoundingRect.y - selectedOffset.y;

    const updatedBox = { ...input.boxes[boxIndex], x, y };

    this.updateInputWithNewBox(updatedBox, boxIndex);
  };

  getTypeDisplay = (input) => {
    const it = CONFIG.CONSTANTS.TAGGER_INPUT_TYPES;
    const typeDisplay = _.get(
      _.find(
        CONFIG.CONSTANTS.TAGGER_INPUT_TYPES,
        (type) => type.key === input.type
      ),
      "title"
    );

    if (
      _.includes(
        [
          it.CHECK_CIRCLE.title,
          it.CHECK.title,
          it.RADIO_CIRCLE.title,
          it.RADIO.title,
        ],
        typeDisplay
      )
    ) {
      const titleToAbbreviationMap = {
        [it.CHECK_CIRCLE.title]: "crcl",
        [it.CHECK.title]: "chk",
        [it.RADIO_CIRCLE.title]: "crcl",
        [it.RADIO.title]: "rad",
      };
      return titleToAbbreviationMap[typeDisplay];
    }

    return typeDisplay;
  };

  render() {
    const {
      input,
      isSelected,
      canDrag,
      displayBy,
      selectedBoxIndex,
      currentPageNumber,
      minHeight,
      minWidth,
    } = this.props;

    return (
      <Container>
        {_.map(_.filter(input.boxes, { page: currentPageNumber }), (box, i) => {
          const isSelectedBox = isSelected && selectedBoxIndex === i;
          const paddedSize = CONTAINER_BORDER_WIDTH * 2;

          let errorMessage = "";
          if (box.height < minHeight - paddedSize) {
            errorMessage += "Too Short ";
          }
          if (box.width < minWidth - paddedSize) {
            errorMessage += "Too Narrow ";
          }

          return (
            <ItemContainer
              key={`${input.id}${i}`}
              {...box}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onMouseDown={(e) => {
                this.selectBox(e, i);
              }}
            >
              <Resizable
                className={`${
                  isSelectedBox ? "selected-resize" : "resizeable"
                }`}
                size={{
                  width: box.width + paddedSize,
                  height: box.height + paddedSize,
                }}
                snap={{}}
                onResizeStop={(e, direction, ref, d) => {
                  this.updateInputWithNewBox(
                    {
                      ...box,
                      width: box.width + d.width,
                      height: box.height + d.height,
                    },
                    i
                  );
                }}
                enable={{ right: true, bottom: true, bottomRight: true }}
              >
                <DraggableTag
                  id={`drag_box_${input.id}_${i}`}
                  canDrag={canDrag}
                >
                  <Tag
                    draggable={canDrag}
                    onDragEnd={(e) => {
                      this.moveSelected(e, i);
                    }}
                    {...box}
                    selected={isSelectedBox}
                    backgroundColor={_.get(
                      _.find(IMPORTANCES, { key: input.importance }),
                      "color"
                    )}
                  >
                    {displayBy.value === "type" && this.getTypeDisplay(input)}
                    {displayBy.value === "samaType" &&
                      ((input.samaTypes || []).join(", ") || "N/A")}
                    {displayBy.value === "boxIndex" && i}
                    {displayBy.value === "charBoxes" &&
                      (box.characterBoxes || "N/A")}
                    {displayBy.value === "parentId" &&
                      (input.parentId || "N/A")}
                    {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                  </Tag>
                </DraggableTag>
              </Resizable>
            </ItemContainer>
          );
        })}
      </Container>
    );
  }
}

export default InputRender;
