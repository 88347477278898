import { SyntheticEvent, useState } from "react";
import _ from "lodash";
import { useAlert } from "react-alert";
import moment from "moment";
import Creatable from "react-select/creatable";
import Modal from "../../Modal";

import { ModalBodyXL } from "../../ModalStyledComponents";
import { BaseCleave, BaseText } from "../../Segment/StyledComponents";
import { withAuthorizationQuery } from "../../../graphql/Authorization";
import { useUploadDocumentsToEmr } from "../../../graphql/Emr";
import CustomCheckbox from "../../CustomCheckbox";
import { DATE_FORMAT } from "../../../../../server/src/shared_code";
import LoginEmr from "./LoginEmr";
import { AwsDocument } from "../../../util/authUtils";
import {
  GetAuthorizationQuery,
  GetAuthorizationQueryVariables,
} from "@@generated/graphql";
import { Authorization } from "@samacare/graphql";
import { useConfig } from "@@hooks/config";
import { PropsValue } from "react-select";
import { Button, Stack, Typography } from "@samacare/design";
import { useQuery } from "@apollo/client";
import { Box } from "@samacare/design/core";
import { NextGenDebugModal } from "./NextGenDebugModal";

type Option = {
  label?: string;
  name: string;
};

export const UploadToEmr: React.VoidFunctionComponent<{
  authorization: Authorization;
  hasUploadToOncoEmr: boolean;
  hasUploadToModernizingMedicine: boolean;
  hasUploadToNextGen: boolean;
  hasUploadToRedox: boolean;
  children: React.ReactNode;
  useMocks?: boolean;
}> = ({
  authorization,
  hasUploadToOncoEmr,
  hasUploadToModernizingMedicine,
  hasUploadToNextGen,
  hasUploadToRedox,
  children,
  useMocks,
}) => {
  const { CONSTANTS } = useConfig();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);
  const [errorContext, setErrorContext] = useState("");
  const [documentsToUpload, setDocumentsToUpload] = useState<AwsDocument[]>([]);
  const [isMissingCredentials, setIsMissingCredentials] = useState(false);
  const [patientMRN, setPatientMRN] = useState(
    _.get(authorization, "patient.institutionPatientId")
  );
  const [documentLabelOptions, setDocumentLabelOptions] = useState<Option[]>([
    { name: "Insurance Authorization" },
    { name: "Insurance Notification" },
  ]);

  const uploadDocumentsToEmr = useUploadDocumentsToEmr(
    authorization.InstitutionId!
  );

  const { data, loading } = useQuery<
    GetAuthorizationQuery,
    GetAuthorizationQueryVariables
  >(withAuthorizationQuery, { variables: { id: parseInt(authorization.id) } });

  const toggleUploadModal = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsUploadModalOpen(!isUploadModalOpen);
  };

  const handleChange = async (name: string, form: { id: string }) => {
    setDocumentsToUpload((prevDocuments: AwsDocument[]) =>
      prevDocuments.map((doc) => (doc.id === form.id ? { ...doc, name } : doc))
    );
  };

  const handleSubmit = async (documents: AwsDocument[]) => {
    if (useMocks) {
      alert.info("Successfully uploaded files to EMR!");
      setIsUploadModalOpen(false);
    } else if (!patientMRN) {
      alert.error("Please verify that you have added a Patient MRN");
    } else if (!_.every(documents, (doc) => doc.name) && !hasUploadToNextGen) {
      alert.error("Please verify that each document has a selected name");
    } else {
      if (
        hasUploadToNextGen ||
        hasUploadToOncoEmr ||
        hasUploadToModernizingMedicine ||
        hasUploadToRedox
      ) {
        setIsLoading(true);
        setIsUploadModalOpen(false);

        alert.info("Documents are being uploaded!");
      }

      const { error, requiresDebug } = await uploadDocumentsToEmr({
        patientMRN,
        documents,
        authorizationId: authorization.id,
      });

      if (error) {
        if (
          _.includes(
            error.message,
            CONSTANTS.ERROR_TYPES.EMR_MISSING_CREDENTIALS
          )
        ) {
          setIsMissingCredentials(true);
        } else if (requiresDebug) {
          setErrorContext(error.message);
          setIsDebugModalOpen(true);
        } else {
          alert.error(error.message);
        }
      } else {
        alert.info("Successfully uploaded files to EMR!");
        setIsUploadModalOpen(false);
      }
      setIsLoading(false);
    }
  };

  const showDateWarning = (date: string | undefined) =>
    date &&
    moment(date, DATE_FORMAT, true).isValid() &&
    !moment(date, DATE_FORMAT).isBetween(
      moment().subtract(1, "month"),
      moment().add(1, "month")
    );

  const filteredCorrespondences = _.filter(
    data?.authorizationById?.correspondences,
    "fileURL"
  );
  const correspondenceForms = _.map(
    _.sortBy(filteredCorrespondences, "createdAt"),
    ({ fileAWSKey, createdAt, fileURL, id }) => ({
      awsKey: fileAWSKey,
      title: hasUploadToModernizingMedicine
        ? `Authorization Correspondence - ${moment(createdAt).format(
            "MM-DD-YYYY"
          )}`
        : `Authorization Correspondence - ${moment(createdAt).format(
            DATE_FORMAT
          )}`,
      fileURL,
      id,
    })
  );

  return (
    <div>
      <Modal header="Loading..." onClick={toggleUploadModal} open={isLoading}>
        <ModalBodyXL style={{ width: "550px" }} />
      </Modal>
      <Modal
        styleOverride={{ overflowY: "inherit" }}
        header="Upload Documents to EMR"
        onClick={toggleUploadModal}
        open={isUploadModalOpen}
      >
        <ModalBodyXL style={{ width: "750px" }}>
          <div>
            {isUploadModalOpen && (
              <>
                {(loading || !data) && (
                  <Box pt={2} pb={2} textAlign="center">
                    <Typography>Loading...</Typography>
                  </Box>
                )}
                {data && _.isEmpty(correspondenceForms) && (
                  <Box pt={2} pb={2} textAlign="center">
                    <Typography>
                      No responses have been uploaded to this authorization!
                    </Typography>
                  </Box>
                )}
                {data && correspondenceForms && (
                  <>
                    <Typography textAlign="center" fontWeight="bold" pt={2}>
                      Please verify the EMR MRN and select the documents you
                      would like to upload.
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        overflowY: "inherit",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        mt={1}
                        mr={1}
                        textAlign="left"
                        variant="subtitle2"
                        color="grey"
                      >
                        Patient EMR MRN:
                      </Typography>
                      <BaseText
                        onChange={(e) => {
                          setPatientMRN(e.target.value);
                        }}
                        value={patientMRN || ""}
                        style={{ width: "250px", marginTop: "10px" }}
                      />
                    </div>
                    {_.map(correspondenceForms, (form) => {
                      const selectedCorrespondence: AwsDocument | undefined =
                        _.find(documentsToUpload, {
                          id: form.id,
                        });

                      return (
                        <>
                          <div
                            style={{
                              margin: "10px 0px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CustomCheckbox
                              checked={!!selectedCorrespondence}
                              onChange={() => {
                                if (selectedCorrespondence) {
                                  const updatedDocs: AwsDocument[] = _.reject(
                                    documentsToUpload,
                                    { id: form.id }
                                  );
                                  setDocumentsToUpload(updatedDocs);
                                } else {
                                  setDocumentsToUpload([
                                    ...documentsToUpload,
                                    form,
                                  ] as AwsDocument[]);
                                }
                              }}
                            />
                            <div style={{ margin: "0 20px 0 10px" }}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={form.fileURL as string}
                              >
                                {form.title}
                              </a>
                            </div>
                          </div>
                          {selectedCorrespondence && !hasUploadToNextGen && (
                            <>
                              <div
                                style={{
                                  margin: "10px 0px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ marginRight: "10px" }}>
                                  Document Name:
                                </div>
                                <div style={{ width: "225px" }}>
                                  <Creatable
                                    getOptionValue={(option: Option) =>
                                      option.name ?? option.label
                                    }
                                    getOptionLabel={(option: Option) =>
                                      option.name ?? option.label
                                    }
                                    onChange={async (option) => {
                                      const name = _.get(option, "name");
                                      await handleChange(name as string, form);
                                    }}
                                    options={documentLabelOptions}
                                    onCreateOption={async (name) => {
                                      await handleChange(name, form);
                                      setDocumentLabelOptions([
                                        ...documentLabelOptions,
                                        { name },
                                      ]);
                                    }}
                                    placeholder="Select or type to create"
                                    value={
                                      selectedCorrespondence.name
                                        ? ({
                                            name: selectedCorrespondence.name,
                                          } as PropsValue<Option>)
                                        : undefined
                                    }
                                  />
                                </div>
                                <div style={{ marginLeft: "15px" }}>
                                  Document Date:
                                </div>
                                <BaseCleave
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setDocumentsToUpload(
                                      (prevDocuments: AwsDocument[]) =>
                                        prevDocuments.map((doc) =>
                                          doc.id === form.id
                                            ? { ...doc, date: e.target.value }
                                            : doc
                                        )
                                    );
                                  }}
                                  options={{
                                    date: true,
                                    datePattern: ["m", "d", "Y"],
                                    delimiter: "/",
                                  }}
                                  value={
                                    selectedCorrespondence.date ||
                                    moment().format(DATE_FORMAT)
                                  }
                                  style={{
                                    width: "125px",
                                    marginLeft: "10px",
                                  }}
                                />
                              </div>
                              <Box
                                mt={1}
                                display="flex"
                                justifyContent="center"
                              >
                                {showDateWarning(
                                  selectedCorrespondence.date
                                ) && (
                                  <Typography variant="caption" color="error">
                                    Warning - document date too far in the past
                                    or future can lead to an upload error
                                  </Typography>
                                )}
                              </Box>
                            </>
                          )}
                        </>
                      );
                    })}
                    <Stack
                      justifyContent="flex-end"
                      direction="row"
                      spacing={2}
                      mt={3}
                    >
                      <Button onClick={toggleUploadModal}>Cancel</Button>
                      <Button
                        variant="contained"
                        onClick={async () => handleSubmit(documentsToUpload)}
                      >
                        Upload
                      </Button>
                    </Stack>
                  </>
                )}
              </>
            )}
          </div>
        </ModalBodyXL>
      </Modal>
      <div onClick={toggleUploadModal}>{children}</div>
      <NextGenDebugModal
        errorContext={errorContext}
        onClose={() => setIsDebugModalOpen(false)}
        open={isDebugModalOpen}
      />
      {isMissingCredentials && (
        <LoginEmr
          open={isMissingCredentials}
          closeModal={() => {
            setIsMissingCredentials(false);
          }}
          documentsToUpload={documentsToUpload}
          authorization={authorization}
          handleSubmit={handleSubmit}
          patientMRN={patientMRN}
        />
      )}
    </div>
  );
};

export default UploadToEmr;
