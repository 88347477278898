import _ from "lodash";
import { useState } from "react";
import { Authorization } from "@samacare/graphql";
import { DisassociateCorrespondenceMutation } from "@@generated/graphql";
import { Box, Tabs, Tab, Typography, Button } from "@samacare/design/core";
import moment from "moment";
import { useCurrentAccount } from "../../../graphql/Account";
import { useConfig } from "@@hooks/config";

import CorrespondenceViewer from "@@components/CorrespondenceViewer";
import AlertModal from "@@components/AlertModal";
import {
  ALL_AUTHORIZATIONS_QUERY_NAME,
  AUTHORIZATION_PAGINATION_QUERY_NAME,
  GET_AUTHORIZATION_QUERY_NAME,
  disassociateCorrespondenceMutation,
} from "../../../graphql/Authorization";
import { GET_AUTHORIZATION_LOGS_QUERY_NAME } from "@@hooks/useAuthorizationLogs";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";

const FilesTab: React.VFC<{
  authorization: Authorization;
  restrictCorrespondences: boolean;
}> = ({ authorization, restrictCorrespondences }) => {
  const [selectedFormId, setSelectedFormId] = useState(
    _.get(_.find(authorization.correspondences, { isLatest: true }), "id")
  );
  const [showIncorrectAssociationModal, setShowIncorrectAssociationModal] =
    useState(false);
  const [currentAccount] = useCurrentAccount();
  const { CONSTANTS } = useConfig();
  const alert = useAlert();
  const { correspondences } = authorization;
  const currentCorrespondence =
    _.find(correspondences, { id: selectedFormId }) ||
    _.find(correspondences, { isLatest: true });

  const {
    DATE_FORMAT,
    CORRESPONDENCE_LABEL_TYPES,
    AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES,
  } = CONSTANTS;

  const labelStrings = {
    [CORRESPONDENCE_LABEL_TYPES.FAX_CONFIRMATION]: "Sent Fax Confirmation",
    [CORRESPONDENCE_LABEL_TYPES.PENDING_RESPONSE]: "Pending",
  };

  const tabs = _.map(correspondences, ({ createdAt, id, label }, i) => {
    const formattedLabel = `${labelStrings[label as string] ?? "File"} ${
      i + 1
    }[${moment(createdAt).format("M/D")}]`;
    return {
      title:
        formattedLabel ||
        `${moment(createdAt).format(DATE_FORMAT)} (${i + 1} of ${
          correspondences.length
        })`,
      tabTitle:
        formattedLabel ||
        `${moment(createdAt).format(DATE_FORMAT)} (${i + 1} of ${
          correspondences.length
        })`,
      id,
    };
  });

  const [disassociateCorrespondence] =
    useMutation<DisassociateCorrespondenceMutation>(
      disassociateCorrespondenceMutation,
      {
        refetchQueries: [
          GET_AUTHORIZATION_LOGS_QUERY_NAME,
          ALL_AUTHORIZATIONS_QUERY_NAME,
          AUTHORIZATION_PAGINATION_QUERY_NAME,
          GET_AUTHORIZATION_QUERY_NAME,
        ],
      }
    );

  const handleDisassociate = async (
    correspondenceId: string,
    authorizationId: string
  ) => {
    try {
      await disassociateCorrespondence({
        variables: {
          authorizationId,
          correspondenceId,
        },
      });
      alert.success("File removed.");
      setShowIncorrectAssociationModal(false);
    } catch (e) {
      alert.error("Unable to remove file");
    }
  };

  return (
    <Box sx={{ minHeight: ({ spacing }) => spacing(60) }}>
      {restrictCorrespondences && !currentAccount?.isSamaUser ? (
        <Typography>
          Viewing submissions and payer responses has been restricted for this
          user.
        </Typography>
      ) : (
        <>
          <Tabs
            onClick={(e) => e.stopPropagation()}
            value={_.find(tabs, { id: selectedFormId })}
            onChange={(_x, selection) => {
              setSelectedFormId(selection.id);
            }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              marginBottom: "20px",
            }}
          >
            {tabs.map((tab) => (
              <Tab
                label={tab.title}
                key={tab.title}
                value={tab}
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  borderBottom: 1,
                  borderColor: "grey.A400",
                }}
              />
            ))}
          </Tabs>

          {_.includes(
            AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES,
            authorization.status
          ) &&
            currentCorrespondence?.label !==
              CORRESPONDENCE_LABEL_TYPES.FAX_CONFIRMATION &&
            _.get(currentCorrespondence, "fileURL") && (
              <Box
                padding={2.5}
                margin="5px"
                sx={{
                  backgroundColor: (theme) => theme.palette.grey[300],
                }}
              >
                {"Is this response attached to the wrong place? "}
                <Button
                  onClick={() => {
                    setShowIncorrectAssociationModal(true);
                  }}
                >
                  Click here
                </Button>
                {" to have SamaCare resolve this issue."}
              </Box>
            )}

          {_.get(currentCorrespondence, "fileURL") ? (
            <CorrespondenceViewer
              correspondence={currentCorrespondence as object}
              height="800px"
              width="100%"
            />
          ) : (
            <div>
              {_.isEmpty(correspondences)
                ? `There are no responses associated with this authorization`
                : `This response was created without an associated file.
                  This happens when a response is received over the phone or if the user hasn't uploaded the associated form for this response.
                  You can view the notes in the main list to find more details on the response or add a form below.
                `}
            </div>
          )}
        </>
      )}
      <AlertModal
        header="Confirm Incorrect Response"
        open={showIncorrectAssociationModal}
        closeModal={() => {
          setShowIncorrectAssociationModal(false);
        }}
        content={
          <Typography variant="subtitle2">
            {`By clicking below, you will send this response back to SamaCare
              ${
                currentCorrespondence?.isLatest
                  ? `and set this authorization back to the pending status`
                  : ""
              }`}
          </Typography>
        }
        buttons={
          <Button
            style={{ width: "100%" }}
            key="disassociateCorrespondence"
            onClick={async () =>
              handleDisassociate(
                currentCorrespondence?.id as string,
                authorization.id
              )
            }
          >
            I am sure
          </Button>
        }
      />
    </Box>
  );
};

export default FilesTab;
