import { Button } from "@samacare/design";

interface UnderlineButtonProps {
  disabled?: boolean;
  onClick: () => void | Promise<void>;
}
export const UnderlineButton: React.FC<UnderlineButtonProps> = (props) => (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{
        textDecoration: "underline",
      }}
    >
      {props.children}
    </Button>
  );
