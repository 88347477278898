import { Alert, Typography, Link } from "@samacare/design/core";

export const LiteBvNotificationAlert = () => {
  return (
    <Alert severity="info" sx={{ width: "600px" }}>
      SamaCare checks the patient coverage at the health plan level.
      <Typography>
        Please share your interest for Drug Specific information,{" "}
        <Link href=" " data-cy="drugSpecificInterestButton">
          click here
        </Link>
      </Typography>
    </Alert>
  );
};
