import {
  Box,
  Typography,
  Stack,
  Alert,
  CircularProgress,
  LoadingButton,
} from "@samacare/design/core";
import { useTheme, alpha } from "@samacare/design/core/styles";
import { useConfig } from "../../hooks/config";
import { Patient } from "@samacare/graphql";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useCurrentAccount } from "../../graphql/Account";
import { StatusDetailsForTreatmentView } from "./StatusDetailsForTreatmentView";
import { useEffect, useState } from "react";

interface InsuranceCoverageCheckProps {
  patient: Patient | null;
  isLoading: boolean;
  onRunClick: () => void;
  disabled?: boolean;
  eligibilityCheckError: boolean;
  autoRun?: boolean;
}

const CoverageCheckAlert = ({
  children,
  sxOverride,
}: {
  children: React.ReactNode;
  sxOverride?: any;
}) => {
  const theme = useTheme();

  return (
    <Alert
      severity="info"
      icon={false}
      sx={{
        justifyContent: "center",
        backgroundColor: alpha(theme.palette.primary.main, 0.04),
        "& .MuiAlert-message": {
          width: "100%",
        },
        ...sxOverride,
      }}
    >
      {children}
    </Alert>
  );
};

export const InsuranceCoverageCheckWithAlert = ({
  patient,
  isLoading,
  onRunClick,
  disabled = false,
  eligibilityCheckError,
  autoRun = false,
}: InsuranceCoverageCheckProps) => {
  const config = useConfig();
  const [currentAccount] = useCurrentAccount();
  const [runCount, setRunCount] = useState(0);
  const isEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEligibilityCheck
  );

  const latestCoverageCheck = patient?.primaryInsurance?.latestCoverageCheck;

  // If the latest coverage check isn't valid, we allow re-running 1x, in case the insurance information has been
  // updated since that last check. If it's still invalid after the first run, then subsequent runs won't change that,
  // and we want to display a helpful error message.
  const isInvalid = runCount === 0 && !latestCoverageCheck?.isValid;
  const shouldCheckCoverage =
    (!latestCoverageCheck || isInvalid) && !eligibilityCheckError;

  const onRun = () => {
    setRunCount(runCount + 1);
    onRunClick();
  };

  useEffect(() => {
    // we want to run the check automatically if the patient's primary insurance and insurance state combination supports BV lite and there is no prior record
    if (
      shouldCheckCoverage &&
      patient?.primaryInsurance?.supportsCoverageCheck &&
      autoRun
    ) {
      onRun();
    }
  }, []);

  if (
    shouldCheckCoverage &&
    patient?.primaryInsurance?.supportsCoverageCheck &&
    !autoRun
  ) {
    return (
      <CoverageCheckAlert>
        <Stack spacing={2} direction="row" alignItems="center">
          <LoadingButton
            data-cy="actionRunEligibilityCheck"
            type="button"
            onClick={onRun}
            loading={isLoading}
            disabled={disabled || eligibilityCheckError}
            variant="contained"
            sx={{ width: "fit-content" }}
            size="small"
          >
            Check Coverage
          </LoadingButton>
          <Typography variant="body2" color="textPrimary">
            Instantly check this patient’s insurance status
          </Typography>
        </Stack>
      </CoverageCheckAlert>
    );
  }

  //if the patient's primary insurance and insurance state combination does not support BV lite
  //or BV lite is not enabled
  if (!isEnabled || !patient?.primaryInsurance?.supportsCoverageCheck) {
    return <Box />;
  }

  if (isLoading) {
    return (
      <CoverageCheckAlert>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={16} />
          <Typography variant="body2" color="textPrimary">
            Checking patient coverage
          </Typography>
        </Stack>
      </CoverageCheckAlert>
    );
  }

  return (
    <CoverageCheckAlert sxOverride={{ justifyContent: "flex-start" }}>
      <Stack spacing={1} direction="column">
        <StatusDetailsForTreatmentView
          patient={patient}
          currentAccount={currentAccount}
          latestCoverageCheck={latestCoverageCheck}
          eligibilityCheckError={eligibilityCheckError}
          onRunClick={onRun}
          isLoading={isLoading}
          disabled={disabled}
        />
      </Stack>
    </CoverageCheckAlert>
  );
};
