import styled from "styled-components";

const BackdropContainer = styled.div`
  background-color: #1f1f1f;
  opacity: 0.6;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${(props) => props.theme.zUpper + props.zIndexOffset};
`;

const Backdrop = ({ onClick = () => {}, zIndexOffset = 0 }) => (
  <BackdropContainer onClick={onClick} zIndexOffset={zIndexOffset} />
);

/**
 * @deprecated Use a MUI component instead
 */
export default Backdrop;
