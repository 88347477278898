import { PureComponent } from "react";
import RrwebPlayer from "rrweb-player";
import Select from "react-select";
import * as rrweb from "rrweb";
import { Query } from "@apollo/client/react/components";
import { withApollo, graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import { MdAutorenew } from "@react-icons/all-files/md/MdAutorenew";
import colors from "Resources/colors";

import { BaseText } from "../../components/Segment/StyledComponents";
import BaseButton from "../../components/BaseButton";
import {
  withGetStorySessionsQuery,
  withGetStorySessionByIdQuery,
  withGetStorySessionsByInstitutionOrAccount,
} from "../../graphql/SamaStory";
import { withInstitutions } from "../../graphql/Institution";
import { withAccountsQuery } from "../../graphql/Account";

const StyledSelect = styled(Select)`
  width: 350px;
  margin: 0 25px 10px;
`;

const ScrollContainer = styled.div`
  max-height: 700px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const SAMA_STORY_PLAYER_ID = "samaPlayer";
export class SamaStoryPlayer extends PureComponent {
  state = {
    sessions: [],
    authorizationId: null,
    selectedSession: null,
    signedUrls: [],
    renderSearchByAuthorization: false,
    institutionId: null,
    accounts: [],
    accountId: null,
  };

  async componentDidUpdate(prevProps, prevState) {
    const { storySessions, client } = this.props;
    const {
      authorizationId,
      signedUrls,
      renderSearchByAuthorization,
      institutionId,
    } = this.state;

    if (
      !_.isEmpty(storySessions) &&
      !authorizationId &&
      !renderSearchByAuthorization
    ) {
      const [, authId] = window.location.hash.split("authorizationId=");

      this.setState({
        sessions: storySessions,
        authorizationId: authId,
        renderSearchByAuthorization: true,
      });
    }

    if (institutionId && institutionId !== prevState.institutionId) {
      const options = await client.query({
        query: withAccountsQuery,
        variables: { institutionId },
        fetchPolicy: "no-cache",
      });

      if (!_.isEmpty(options.data.accountsFindAll)) {
        this.setState({ accounts: options.data.accountsFindAll });
      }
    }

    if (
      !_.isEmpty(signedUrls) &&
      !_.isEqual(signedUrls, prevState.signedUrls)
    ) {
      const events = await this.fetchFilesAndReturnEvents();
      const target = document.getElementById(SAMA_STORY_PLAYER_ID);
      target.innerHTML = "";
      // eslint-disable-next-line no-new
      new RrwebPlayer({
        target,
        props: {
          events,
          unpackFn: rrweb.unpack,
          tags: { LIVE_VIEWER_STARTED: "red" },
          skipInactive: false,
        },
      });
    }
  }

  async fetchFilesAndReturnEvents() {
    const { signedUrls } = this.state;

    const chunks = _.chunk(signedUrls, 40);
    const playerEvents = [];
    for (const chunk of chunks) {
      const chunkEvents = await Promise.all(
        _.map(chunk, async (signedUrl) => {
          const eventFile = await fetch(signedUrl);
          const contents = await eventFile.text();
          return _.map(contents.split(/\n/), JSON.parse);
        })
      );
      playerEvents.push(_.flatten(chunkEvents));
    }
    return _.flatten(playerEvents);
  }

  async handleSearchForStorySessions() {
    const { client } = this.props;
    const {
      authorizationId,
      institutionId,
      accountId,
      renderSearchByAuthorization,
    } = this.state;

    const clientQuery = renderSearchByAuthorization
      ? {
          query: withGetStorySessionsQuery,
          variables: { authorizationId: _.toNumber(authorizationId) },
          fetchPolicy: "no-cache",
        }
      : {
          query: withGetStorySessionsByInstitutionOrAccount,
          variables: { institutionId, accountId },
          fetchPolicy: "no-cache",
        };

    const options = await client.query(clientQuery);
    const data = renderSearchByAuthorization
      ? options.data.getStorySessionsByAuthorizationId
      : options.data.getStorySessionsByInstitutionOrAccount;

    if (!_.isEmpty(data)) {
      this.setState({ sessions: data });
    } else {
      const target = document.getElementById(SAMA_STORY_PLAYER_ID);
      target.innerHTML = "";
      this.setState({ selectedSession: null, sessions: [] });
    }
  }

  render() {
    const { institutions } = this.props;
    const {
      sessions,
      selectedSession,
      authorizationId,
      renderSearchByAuthorization,
      institutionId,
      accounts,
      accountId,
    } = this.state;

    const institutionOptions = _.map(institutions ?? [], ({ id, name }) => ({
      value: id,
      label: name,
    }));

    const accountOptions = _.map(accounts, ({ id, email }) => ({
      value: id,
      label: email,
    }));

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <BaseButton
            style={{ width: "350px", margin: "10px 25px" }}
            onClick={() => {
              this.setState({
                renderSearchByAuthorization: !renderSearchByAuthorization,
              });
            }}
          >
            <div style={{ marginRight: "25px" }}>
              {renderSearchByAuthorization
                ? "Search by User/Practice"
                : "Search by Authorization ID"}
            </div>
            <MdAutorenew />
          </BaseButton>
          {renderSearchByAuthorization ? (
            <>
              <div style={{ margin: "10px 25px", fontWeight: "bold" }}>
                Authorization ID
              </div>
              <BaseText
                value={authorizationId || ""}
                onChange={(e) => {
                  this.setState({ authorizationId: e.target.value });
                }}
                placeholder="AuthorizationId"
                style={{
                  width: "350px",
                  margin: "0 25px 10px",
                }}
              />
            </>
          ) : (
            <>
              <div style={{ margin: "10px 25px", fontWeight: "bold" }}>
                Institution
              </div>
              <StyledSelect
                value={institutionOptions.find(
                  (item) => item.value === institutionId
                )}
                onChange={(selected) => {
                  this.setState({ institutionId: _.get(selected, "value") });
                }}
                options={institutionOptions}
                isClearable
                isSearchable
              />
              {institutionId && (
                <>
                  <div style={{ margin: "10px 25px", fontWeight: "bold" }}>
                    User (Email)
                  </div>
                  <StyledSelect
                    value={accountOptions.find(
                      (item) => item.value === accountId
                    )}
                    onChange={(selected) => {
                      this.setState({ accountId: _.get(selected, "value") });
                    }}
                    options={accountOptions}
                    isClearable
                    isSearchable
                  />
                </>
              )}
            </>
          )}
          <BaseButton
            style={{ width: "350px", margin: "15px 25px" }}
            onClick={async () => this.handleSearchForStorySessions()}
          >
            Search
          </BaseButton>
          {sessions.length > 0 && (
            <div
              style={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "35px",
              }}
            >
              Session Playlist
            </div>
          )}
          <ScrollContainer>
            {_.map(_.sortBy(sessions, "createdAt").reverse(), (session) => (
              <BaseButton
                onClick={() => {
                  if (session.sessionURL) {
                    window.open(session.sessionURL, "_blank");
                  } else if (selectedSession?.id !== session.id) {
                    const target =
                      document.getElementById(SAMA_STORY_PLAYER_ID);
                    target.innerHTML = "";
                    this.setState({ selectedSession: session });
                  }
                }}
                style={{
                  width: "350px",
                  margin: "10px 25px",
                  ...(session.hasDeletedAuthorization ? { color: "red" } : {}),
                  ...(session.id === selectedSession?.id
                    ? { color: "white", backgroundColor: colors.purple }
                    : {}),
                }}
              >
                {`Auth ${session.AuthorizationId}, ${moment(
                  session.createdAt
                ).format("MM/DD HH:mm")}, ${session.createdBy.email} (${
                  session.sessionURL ? "FullStory" : ""
                })`}
              </BaseButton>
            ))}
          </ScrollContainer>
        </div>
        <div>
          {sessions.length === 0 && (
            <div style={{ margin: "50px 0 0 75px", fontWeight: "bold" }}>
              No story sessions to view!
            </div>
          )}
          {selectedSession && (
            <>
              <div
                style={{
                  margin: "25px 50px 15px",
                  fontWeight: "bold",
                  fontSize: "larger",
                }}
              >
                {`Session ${selectedSession.id}, Auth ${
                  selectedSession.AuthorizationId
                }, ${moment(selectedSession.createdAt).format("MM/DD HH:mm")}`}
              </div>
              <Query
                query={withGetStorySessionByIdQuery}
                variables={{ id: selectedSession.id }}
                fetchPolicy="no-cache"
                onCompleted={(data) =>
                  this.setState({
                    signedUrls: data.getStorySessionById.events,
                  })
                }
              >
                {({ loading, data }) => {
                  if (loading || !data) {
                    return (
                      <div style={{ fontWeight: "bold", margin: "15px 50px" }}>
                        Loading...
                      </div>
                    );
                  }
                  if (data) {
                    return (
                      <div style={{ fontWeight: "bold", margin: "15px 50px" }}>
                        Loading {data.getStorySessionById.events?.length} event
                        files.{" "}
                        {data.getStorySessionById.events?.length <= 2
                          ? "The recorded session you are loading is <= 1 minute long"
                          : ""}
                      </div>
                    );
                  }

                  return null;
                }}
              </Query>
            </>
          )}
          <div id={SAMA_STORY_PLAYER_ID} />
        </div>
      </div>
    );
  }
}

const withGetStorySessions = graphql(withGetStorySessionsQuery, {
  options: (props) => {
    const params = new URLSearchParams(props.location.search);
    return {
      fetchPolicy: "no-cache",
      variables: { authorizationId: _.toNumber(params.get("authorizationId")) },
    };
  },
  props({ data }) {
    return { storySessions: data.getStorySessionsByAuthorizationId };
  },
});

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withGetStorySessions,
  withInstitutions
)(withApollo(SamaStoryPlayer));
