import { Button, Box, LoadingButton } from "@samacare/design/core";

import { useStepContext } from "./StepProvider";
import { EnrollmentRoutes } from "./Enrollment.types";
import { useEnrollmentContext } from "./EnrollmentProvider";

type EnrollmentActionsProps = {
  loading?: boolean;
  onNextClick?: () => void;
  disabled?: boolean;
  formName?: string;
};

export const EnrollmentActions: React.FC<EnrollmentActionsProps> = ({
  disabled,
  onNextClick,
  loading = false,
}) => {
  const { next, onNextStep, onPreviousStep, active } = useStepContext();
  const { onDismissAlert, isSubmitted } = useEnrollmentContext();

  return (
    <Box display="flex" justifyContent="space-between" py={5}>
      <Button
        type="button"
        variant="outlined"
        size="large"
        disabled={loading}
        onClick={() => {
          onDismissAlert();
          onPreviousStep();
        }}
      >
        Back
      </Button>
      {next && (
        <LoadingButton
          type={onNextClick || isSubmitted ? "button" : "submit"}
          onClick={() => {
            if (isSubmitted) onNextStep();
            else if (onNextClick) onNextClick();
          }}
          variant="contained"
          size="large"
          loading={loading}
          disabled={disabled}
          data-cy={
            active === EnrollmentRoutes.submission
              ? "actionEnrollmentSubmit"
              : "actionEnrollmentNextStep"
          }
        >
          {active === EnrollmentRoutes.submission
            ? "Submit Enrollment"
            : "Continue"}
        </LoadingButton>
      )}
    </Box>
  );
};
