import { useCallback } from "react";
import * as React from "react";
import styled from "styled-components";
import Section from "AuthorizationSharedComponents/Section";

import { NdcSelector } from "@@components/NdcSelector";
import { PrimaryButton } from "@@ui-kit";
import RequiredTag from "../RequiredTag";
import { useConfig } from "../../hooks";
import {
  FilterFormBenefitType,
  InsuranceCompanySupportedCodingTypes,
} from "@samacare/graphql";

const SelectorTitle = styled.div`
  font-weight: 700;
  margin: 9px 4px 7px 4px;
`;

export const PrimaryNdc: React.VoidFunctionComponent<{
  results: Record<string, string>;
  set: (config: {
    PRIMARY_DRUG_NAME: string;
    PRIMARY_NDC: string;
    DRUG_CODE_TYPE: string;
    REQUEST_DESCRIPTION: string;
  }) => void;
}> = ({ results, set }) => {
  const config = useConfig();
  const setNDC = useCallback(
    (NDCRes: { brandName: string; code: string } | null) => {
      if (NDCRes != null) {
        set({
          [config.DEFAULT_FIELDS.PRIMARY_DRUG_NAME.key]: NDCRes.brandName,
          [config.DEFAULT_FIELDS.PRIMARY_NDC.key]: NDCRes.code,
          [config.DEFAULT_FIELDS.BENEFIT_TYPES.key]:
            FilterFormBenefitType.PharmacyOnly,
          [config.DEFAULT_FIELDS.DRUG_CODE_TYPE.key]:
            InsuranceCompanySupportedCodingTypes.Ndc,
          [config.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key]:
            NDCRes.brandName.toUpperCase(),
        });
      }
    },
    [set, config]
  );

  const unsetNDC = useCallback(() => {
    setNDC({ brandName: "", code: "" });
  }, [setNDC]);

  return (
    <Section
      childrenAfterSection
      section={{
        items: results[config.DEFAULT_FIELDS.PRIMARY_NDC.key]
          ? [
              { ...config.DEFAULT_FIELDS.PRIMARY_DRUG_NAME, disabled: true },
              { ...config.DEFAULT_FIELDS.PRIMARY_NDC, disabled: true },
            ]
          : [],
        title: "Search for NDC",
      }}
    >
      <div style={{ width: "600px" }}>
        {!results[config.DEFAULT_FIELDS.PRIMARY_NDC.key] && (
          <div>
            <SelectorTitle>
              NDC (search by Brand Name or NDC)
              <RequiredTag />
            </SelectorTitle>
            <NdcSelector onSelect={setNDC} />
          </div>
        )}
        {results[config.DEFAULT_FIELDS.PRIMARY_NDC.key] && (
          <PrimaryButton style={{ marginTop: "10px" }} onClick={unsetNDC}>
            Reset Selection
          </PrimaryButton>
        )}
      </div>
    </Section>
  );
};
