import styled from "styled-components";

import Modal from "../Modal";
import BaseButton from "../BaseButton";
import RequiredTag from "../RequiredTag";
import { ModalBody, ModalFooter, ModalContent } from "../ModalStyledComponents";

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  ${BaseButton} {
    margin-top: 30px;
    width: 100%;
    height: 40px;
  }
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;
`;

const RequiredFieldsModal = ({ open, closeModal, content, header }) => (
  <Modal
    header={header || "Please complete all required fields"}
    open={open}
    onClick={closeModal}
  >
    <ModalBody>
      <CustomModalContent>
        {content ? (
          <div>{content}</div>
        ) : (
          <div>
            {"Required fields are marked by a "}
            <RequiredTag />
            {" and should be highlighted in red"}
          </div>
        )}
      </CustomModalContent>
      <CustomModalFooter>
        <BaseButton data-cy="actionReturn" onClick={closeModal}>
          Return
        </BaseButton>
      </CustomModalFooter>
    </ModalBody>
  </Modal>
);

export default RequiredFieldsModal;
