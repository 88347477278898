import Box from "@samacare/design/core/Box";
import { styled } from "@samacare/design/core/styles";

/**
 * @deprecated Use a MUI component instead
 */
export const Flex = styled(Box)({
  display: "flex",
});

/**
 * @deprecated Use a MUI component instead
 */
export { Box };
