import _ from "lodash";
import { PureComponent } from "react";
import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import colors from "Resources/colors";
import { MdTouchApp as TouchIcon } from "@react-icons/all-files/md/MdTouchApp";
import { MdWarning } from "@react-icons/all-files/md/MdWarning";
import { ReactTooltip } from "@@ui-kit/ReactTooltip";

import strings from "Resources/strings";
import UpdateAuthorizationStatusButton from "@@components/UpdateAuthorizationStatusButton";
import CancelAuthorizationSendButton from "@@components/CancelAuthorizationSendButton";
import RequestResendButton from "@@components/RequestResendButton";
import EditAndResubmitButton from "@@components/EditAndResubmitButton";
import FollowUpButton from "@@components/FollowUpButton";
import BaseButton from "@@components/BaseButton";
import ExpectedPayerResponseTime from "./ExpectedPayerResponseTime";
import hasActionableFollowUp from "../../../util/hasActionableFollowUp";
import { parseAsNonNull } from "../../../util/parsers";
import { PlacesType } from "react-tooltip";
import { Authorization, AuthorizationCorrespondence } from "@samacare/graphql";

const { CONSTANTS, NODE_ENV } = window.CONFIG;

const Status = styled.div<{ statusColor: string }>`
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
  border-radius: 5px;
  background: ${({ statusColor }) => statusColor};
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ReviewStatus = styled.div`
  margin-top: 3px;
  font-size: 12px;
  color: ${({ theme }) => theme.warningRed};
  font-weight: bold;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  max-width: 175px;
`;

const StatusButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 2px 10px;
`;

const StyledErrorIcon = styled(TouchIcon)`
  margin-right: 4px;
`;

const StyledWarningIcon = styled(MdWarning)`
  margin-right: 4px;
`;

const StatusActionButton = styled(BaseButton)`
  border: 0;
  background: transparent;
  border-style: dotted;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px;
  color: ${({ theme }) => theme.darkPurple};

  &:hover {
    color: white;
    background: ${({ theme }) => theme.purple};
  }
`;

const StatusActionText = styled.div`
  padding: 5px;
  font-size: 14px;
  color: ${(props) => props.theme.darkGray};
`;

const getColorForStatus = (status: string): string => {
  switch (status) {
    case CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION:
      return colors.presubmissionPink;
    case CONSTANTS.AUTHORIZATION_STATUSES.SENT:
      return "#ffa747";
    case CONSTANTS.AUTHORIZATION_STATUSES.SENDING:
      return "#ffa747";
    case CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED:
      return "#ff4242";
    case CONSTANTS.AUTHORIZATION_STATUSES.PENDING:
      return "#00f2d4";
    case CONSTANTS.AUTHORIZATION_STATUSES.ACTION_REQUIRED:
      return "#0099ff";
    case CONSTANTS.AUTHORIZATION_STATUSES.MODIFIED_APPROVAL:
      return "#4fcbe0";
    case CONSTANTS.AUTHORIZATION_STATUSES.APPROVED:
      return "#2BBF96";
    case CONSTANTS.AUTHORIZATION_STATUSES.DENIED:
      return "#ff4242";
    case CONSTANTS.AUTHORIZATION_STATUSES.DUPLICATE:
      return colors.orange;
    default:
      return colors.dullGray;
  }
};

const editableStatuses = [
  ...CONSTANTS.AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES,
  CONSTANTS.AUTHORIZATION_STATUSES.SENT,
  CONSTANTS.AUTHORIZATION_STATUSES.SENT_TO_HCP,
];

export type RequestStatusButtonProps = React.ComponentProps<
  typeof RequestStatusButton
>;

export class RequestStatusButton extends PureComponent<{
  authorization: Authorization;
  disabled?: boolean;
  tooltipPlace?: PlacesType | "top-start";
  layoutDirection?: "row" | "column";
}> {
  private getStatusTextAndColor = () => {
    const { authorization } = this.props;
    const statusColor = getColorForStatus(authorization.status);

    const statusLabel = {
      [CONSTANTS.AUTHORIZATION_STATUSES.SENT_TO_HCP]: "Sent to HCP",
      [CONSTANTS.AUTHORIZATION_STATUSES.SENT]: "Sent to Payer",
    };

    return (
      <StatusButtonWrapper>
        <Status statusColor={statusColor} />
        <div style={{ whiteSpace: "normal" }}>
          {statusLabel[authorization.status] ||
            _.upperFirst(authorization.status.replace(/_/g, " "))}
        </div>
      </StatusButtonWrapper>
    );
  };

  private getStatusActionButton = () => {
    const { authorization } = this.props;

    if (authorization.followUp != null) {
      // Denial next steps should superceed all other actions
      if (
        authorization.followUp.type ===
        CONSTANTS.FOLLOW_UP_TYPES.EDIT_AND_RESUBMIT
      ) {
        return (
          <EditAndResubmitButton
            key={`editAndResubmitButton-id-${authorization.id}`}
            authorizationId={authorization.id}
          >
            <StatusActionButton>
              <StyledErrorIcon />
              Edit and Resubmit
            </StatusActionButton>
          </EditAndResubmitButton>
        );
      }
      if (authorization.followUp.type === CONSTANTS.FOLLOW_UP_TYPES.RESUBMIT) {
        return (
          <RequestResendButton
            key={`authRequestResendButton-id-${authorization.id}`}
            authorizationId={authorization.id}
            insuranceCompanyId={authorization.insuranceCompany?.id}
            authorizationFormId={authorization.formId}
          >
            <StatusActionButton>
              <StyledErrorIcon />
              Resend
            </StatusActionButton>
          </RequestResendButton>
        );
      }
      const tooltipId = `followUp_${authorization.id}`;
      const tooltip = (
        <ReactTooltip id={tooltipId}>
          {/* @ts-expect-error(ndhoule): This will go away when/if we make followUp.type more specific */}
          {strings.FOLLOW_UPS[authorization.followUp.type]}
        </ReactTooltip>
      );
      return hasActionableFollowUp(authorization) ? (
        <FollowUpButton
          key={`respondToPayerButton-id-${authorization.id}`}
          authorization={authorization}
          authorizationId={authorization.id}
          followUp={authorization.followUp}
          // hasActionableFollowUp checks that latestCorrespondence is not null
          // but doesn't currently perform a type assertion
          correspondence={parseAsNonNull(
            authorization?.latestCorrespondence as AuthorizationCorrespondence
          )}
        >
          <StatusActionButton data-tooltip-id={tooltipId}>
            {tooltip}
            <StyledErrorIcon />
            Follow Up Required
          </StatusActionButton>
        </FollowUpButton>
      ) : (
        <StatusActionText data-tooltip-id={tooltipId}>
          {tooltip}
          <StyledWarningIcon data-tooltip-id={`followUp_${authorization.id}`} />
          Follow Up Required
        </StatusActionText>
      );
    }

    switch (authorization.status) {
      case CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED:
        return (
          <RequestResendButton
            key={`authRequestResendButton-id-${authorization.id}`}
            authorizationId={authorization.id}
            insuranceCompanyId={authorization.insuranceCompany?.id}
            authorizationFormId={authorization.formId}
          >
            <StatusActionButton>
              <StyledErrorIcon />
              Resend
            </StatusActionButton>
          </RequestResendButton>
        );
      case CONSTANTS.AUTHORIZATION_STATUSES.SENDING:
        return (
          <CancelAuthorizationSendButton
            key={`authCancelAuthorizationSendButtonButton-id-${authorization.id}`}
            authorization={authorization}
          >
            <StatusActionButton>
              {strings.AUTHORIZATIONS.CANCEL_AUTH_SEND_BUTTON}
            </StatusActionButton>
          </CancelAuthorizationSendButton>
        );
      default:
        break;
    }
    return null;
  };

  private isEditable = () => {
    const { authorization, disabled = false } = this.props;

    if (
      disabled ||
      authorization.type === CONSTANTS.AUTHORIZATION_TYPES.PORTAL_EXTERNAL.key
    ) {
      return false;
    }

    if (
      _.includes(
        CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES,
        authorization.status
      ) &&
      moment() > moment(authorization.dateOfCurrentStatus).add(2, "weeks")
    ) {
      return false;
    }

    // Allow web extension auth statuses to be updated
    if (authorization.isWebExtension) {
      return true;
    }

    return (
      NODE_ENV !== "production" ||
      _.includes(editableStatuses, authorization.status)
    );
  };

  public render(): JSX.Element {
    const { authorization } = this.props;
    const showReviewStatus =
      _.includes(
        CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES,
        authorization.status
      ) && authorization.displayReviewWarning;
    const showPayerResponseTimes =
      ![
        ...CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES,
        CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION,
        CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED,
        CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT,
        CONSTANTS.AUTHORIZATION_STATUSES.SENT_TO_HCP,
      ].includes(authorization.status) &&
      authorization.insuranceCompany != null;

    return (
      <StatusWrapper
        key={`authStatusIndicator-id-${authorization.id}`}
        style={{
          flexDirection: this.props.layoutDirection ?? "column",
          alignItems:
            this.props.layoutDirection === "row" ? "center" : "flex-start",
          gap: this.props.layoutDirection === "row" ? "0.5rem" : 0,
        }}
      >
        <div style={{ display: "flex" }}>
          {this.isEditable() ? (
            <UpdateAuthorizationStatusButton
              authorization={authorization}
              tooltipPlace={this.props.tooltipPlace}
            >
              {this.getStatusTextAndColor()}
            </UpdateAuthorizationStatusButton>
          ) : (
            <StatusWrapper>{this.getStatusTextAndColor()}</StatusWrapper>
          )}
        </div>
        {this.getStatusActionButton()}
        {showReviewStatus && (
          <ReviewStatus>
            Please review the response associated to this auth.
          </ReviewStatus>
        )}
        {showPayerResponseTimes && (
          <ExpectedPayerResponseTime
            max={authorization.insuranceCompany?.responseRangeMax ?? null}
            min={authorization.insuranceCompany?.responseRangeMin ?? null}
          />
        )}
      </StatusWrapper>
    );
  }
}
