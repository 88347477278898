import {
  DialogTitle,
  GridCloseIcon,
  IconButton,
  Stack,
  Typography,
} from "@samacare/design";
import { useTheme } from "@samacare/design/core/styles";
import {
  LeftRightCenterV,
  LeftRightSpreadCenterV,
} from "../LeftRight/LeftRight";

interface DialogHeaderProps {
  title: string;
  onClose?: () => void;
}
export const DialogHeader: React.FC<DialogHeaderProps> = (props) => {
  const theme = useTheme();
  return (
    <Stack sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}>
      <DialogTitle>
        <LeftRightSpreadCenterV>
          <LeftRightCenterV>
            <Typography>{props.title}</Typography>
            {props.children}
          </LeftRightCenterV>
          {props.onClose && (
            <IconButton
              aria-label="close"
              onClick={props.onClose}
              sx={{
                color: theme.palette.grey[600],
              }}
            >
              <GridCloseIcon />
            </IconButton>
          )}
        </LeftRightSpreadCenterV>
      </DialogTitle>
    </Stack>
  );
};
