import { connect } from "react-redux";
import * as React from "react";
import _ from "lodash";

import { BaseText } from "./StyledComponents";

const SegmentNpi: React.VFC<{
  item: { key: string; onBlur: () => Record<string, never> };
  disabled: boolean;
  result: string;
  highlightIfMissing: boolean;
  cypressTag: string;
  ref: (instance: HTMLInputElement | null) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (config: { [key: string]: string }) => void;
}> = ({
  item,
  disabled,
  result,
  highlightIfMissing,
  cypressTag,
  ref,
  onChange,
  handleChange,
}) => {
  const onBlur: () => void = _.get(item, "onBlur", () => {});

  return (
    <BaseText
      ref={ref}
      disabled={disabled}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        // We want to prevent the user from entering more than 10 digits
        if (event.target.value.length <= 10) {
          if (!_.isNil(onChange)) {
            onChange(event);
          }

          handleChange({ [item.key]: event.target.value });
        }
      }}
      data-cy={cypressTag}
      id={item.key}
      onBlur={onBlur}
      value={result || ""}
      // This is the only solution I've tried that removes auto complete
      // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
      autoComplete="new-password"
      // @ts-ignore
      highlight={highlightIfMissing && result?.length !== 10}
    />
  );
};

function mapStateToProps(state: any, props: { item: { key: string } }) {
  const { key } = props.item;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  return key ? { result: state.form.results[key] } : { result: null };
}

export default connect(mapStateToProps)(SegmentNpi);
