import { Stack } from "@samacare/design/core";
import { useWatch } from "@samacare/form";

import { EnrollmentSection } from "../../app/routes/Enrollment/EnrollmentSection";
import QuestionnaireField from "../../app/components/Questionnaire/QuestionnaireField";

import { QuestionTypeEnumType } from "@samacare/graphql";

export type PrescriptionInfoBlockForOcrevusProps = {
  isSubmitted?: boolean;
};

export const PrescriptionInfoBlockForOcrevus: React.FC<
  PrescriptionInfoBlockForOcrevusProps
> = ({ isSubmitted = false }) => {
  const shipmentOption = useWatch({
    name: "questionnaire.shipmentOption",
  });

  const drugFrequency = useWatch({
    name: "questionnaire.sama-drug-frequency",
  });

  const hasOtherDrugFrequency = drugFrequency === "Other";

  return (
    <EnrollmentSection
      title="Prescription Information"
      description={
        <>
          If preferred, you may attach a written prescription or submit the
          prescription electronically.
          <br />
          {shipmentOption === "Upfront"
            ? "An eSigned Prescriber Foundation Form cannot be used as a prescription for upfront shipments. To Submit a valid prescription you can Provide a verbal prescription to our specialty pharmacy by calling (833) 888-4363 or Submit an ePrescription to MedVantx (AmeriPharm), NCPDP/NABP: 4351968, NPI: 1073692745."
            : ""}
        </>
      }
    >
      <Stack spacing={2} my={4} maxWidth={600}>
        <Stack direction="row" spacing={2}>
          <QuestionnaireField
            id="sama-drug-frequency"
            text="Frequency of administration"
            type={QuestionTypeEnumType.Choice}
            disabled={isSubmitted}
            required
            radioGroupProps={{ row: true }}
            answerOption={[
              {
                valueString: "Day 1 & Day 15",
              },
              {
                valueString: "Every 6 months",
              },
              {
                valueString: "Other",
              },
            ]}
            enableWhen={[
              {
                answerBoolean: true,
                answerString: null,
                id: "applied-for-pf",
                operator: "=",
                question: "applied-for-pf",
              },
            ]}
          />
        </Stack>
        {hasOtherDrugFrequency && (
          <QuestionnaireField
            id="sama-drug-frequency-other"
            text="Other Frequency"
            type={QuestionTypeEnumType.String}
            disabled={isSubmitted}
            required
          />
        )}
        <Stack direction="row" spacing={2} maxWidth={600}>
          <QuestionnaireField
            id="sama-dosage"
            text="Dosage (mg)"
            type={QuestionTypeEnumType.Number}
            disabled={isSubmitted}
            rules={{ max: 99999 }}
            required
          />
          <QuestionnaireField
            id="sama-days-of-supply"
            text="Days of supply"
            type={QuestionTypeEnumType.Number}
            disabled={isSubmitted}
            rules={{ max: 99999 }}
            required
          />
          <QuestionnaireField
            id="sama-refills"
            text="Refill(s)"
            type={QuestionTypeEnumType.Number}
            disabled={isSubmitted}
            rules={{ max: 12 }}
          />
        </Stack>
        <QuestionnaireField
          id="sama-patient-instructions"
          text="Patient Instructions"
          type={QuestionTypeEnumType.String}
          disabled={isSubmitted}
          required
        />
        <QuestionnaireField
          id="ocrevus-other-medicines-prescribed"
          text="Other Medicines Prescribed"
          type={QuestionTypeEnumType.String}
          disabled={isSubmitted}
          enableWhen={[
            {
              answerBoolean: true,
              answerString: null,
              id: "applied-for-pf",
              operator: "=",
              question: "applied-for-pf",
            },
          ]}
        />
      </Stack>
    </EnrollmentSection>
  );
};
