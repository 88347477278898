import gql from "graphql-tag";

export const SET_FAVORITE_FORM = gql`
  mutation setFavoriteForm($authorizationFormId: Int) {
    setFavorite(authorizationFormId: $authorizationFormId)
  }
`;

export const DELETE_FAVORITE_FORM = gql`
  mutation deleteFavoriteForm($authorizationFormId: Int) {
    deleteFavorite(authorizationFormId: $authorizationFormId)
  }
`;
