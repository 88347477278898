import * as React from "react";
import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";
import MarkdownEditor from "@@ui-kit/forms/MarkdownEditor";
import { useSelector, useDispatch } from "../../../configureStore";
import { setFormFields } from "../../../reducers/formReducer";
import AttachmentUpload from "AuthorizationSharedComponents/AttachmentUpload";
import RequiredFieldModal from "AuthorizationSharedComponents/RequiredFieldModal";
import { Panel } from "@@ui-kit/Panel";
import _ from "lodash";
import {
  placeholderDecorator,
  PlaceholderSpan,
  signatureDecorator,
  PLACEHOLDER_REGEX,
  superscriptDecorator,
} from "./LomnShared";
import { Authorization, Account } from "@samacare/graphql";

interface ILomnEditProps {
  authorization: Authorization;
  account: Account;
  submit: () => void;
  back: () => void;
}

const LomnEditStep: React.VFC<ILomnEditProps> = ({
  back,
  submit,
  authorization,
  account,
}) => {
  const [requiredModalState, setRequiredModalState] = React.useState<{
    open: boolean;
    header?: React.ReactNode;
    content?: React.ReactNode;
  }>({
    open: false,
  });
  const dispatch = useDispatch();
  const authorizationConfig = useSelector((state) => state.form.results);
  const isAppeal = authorizationConfig.USE_APPEAL === true;

  const set = (fields: Record<string, unknown>) =>
    dispatch(setFormFields(fields));
  const defaultMarkdown = useSelector((state) =>
    isAppeal
      ? state.form.results.APPEAL_MARKDOWN
      : state.form.results.LOMN_MARKDOWN
  );

  const checkAndSubmit = React.useCallback(() => {
    const markdown =
      (isAppeal
        ? authorizationConfig.APPEAL_MARKDOWN
        : authorizationConfig.LOMN_MARKDOWN) ?? "";

    const placeholderMatches = markdown.match(PLACEHOLDER_REGEX);
    if (placeholderMatches !== null) {
      setRequiredModalState({
        open: true,
        header: "Please fill or remove the following placeholder text:",
        content: (
          <>
            {_.map(placeholderMatches, (placeholder) => (
              <PlaceholderSpan style={{ display: "block" }}>
                {placeholder}
              </PlaceholderSpan>
            ))}
          </>
        ),
      });
    } else if (
      authorization.institution?.faxResponseNumber !== undefined &&
      authorization.institution?.faxResponseNumber !== null &&
      !markdown.includes(authorization.institution?.faxResponseNumber)
    ) {
      setRequiredModalState({
        open: true,
        header: "Fax number not found",
        content: `Please include the SamaCare fax number (${authorization.institution?.faxResponseNumber}) in the Letter of Medical Necessity`,
      });
    } else {
      submit();
    }
  }, [authorizationConfig]);

  if (defaultMarkdown === undefined) return null;

  return (
    <div>
      <Panel style={{ marginBottom: "16px" }}>
        <div>
          Review your{" "}
          {isAppeal ? "Appeal letter" : "Letter of Medical Necessity form"},
          make any additional edits directly and submit.
        </div>
        <div>
          Remember to remove any{" "}
          <PlaceholderSpan>[[Placeholders]]</PlaceholderSpan> from the template.
        </div>
      </Panel>
      <MarkdownEditor
        defaultMarkdown={defaultMarkdown}
        customDecorators={[
          placeholderDecorator,
          signatureDecorator,
          superscriptDecorator,
        ]}
        onChange={(v) =>
          isAppeal ? set({ APPEAL_MARKDOWN: v }) : set({ LOMN_MARKDOWN: v })
        }
      />
      <AttachmentUpload
        showEMRDocumentOption
        authorization={authorization}
        account={account}
      />
      <FormSubmitButtons
        back={back}
        submit={checkAndSubmit}
        disabled={false}
        loading={false}
      />

      <RequiredFieldModal
        closeModal={() => {
          setRequiredModalState({ open: false });
        }}
        header={requiredModalState.header}
        open={requiredModalState.open}
        content={requiredModalState.content ?? ""}
      />
    </div>
  );
};

export default LomnEditStep;
