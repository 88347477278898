import { useState, useEffect } from "react";
import { Stack, CircularProgress, Typography } from "@samacare/design/core";
import { useAuth } from "../../providers/AuthProvider";

type AuthIdentityProps = {
  name: string;
};

export const AuthIdentity: React.FC<AuthIdentityProps> = ({ name = "" }) => {
  const { onSignInWithRedirect } = useAuth();
  const [countdown, setCountdown] = useState<number>(3);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (countdown === 0) {
      void onSignInWithRedirect(name);
    } else {
      timeout = setTimeout(() => {
        setCountdown(countdown - 1);
        timeout = void 0;
      }, 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  });

  return (
    <Stack spacing={4} alignItems="center">
      <CircularProgress />
      <Typography variant="body">
        Redirecting to your identity provider in <strong>{countdown}s</strong>
        ...
      </Typography>
    </Stack>
  );
};
