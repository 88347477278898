import { Variants, colorForVariant } from "../app/theme";
import * as React from "react";
import styled from "styled-components";

type PanelProps = {
  children: React.ReactNode;
  text?: Variants;
  border?: Variants;
  bg?: Variants;
  style?: React.CSSProperties;
};

const defaultProps: Partial<PanelProps> = {
  text: "primary",
  border: "primary",
  bg: "white",
};

const StyledDiv = styled.div`
  box-sizing: border-box;

  border: 2px solid
    ${(props) =>
      colorForVariant(
        props,
        (props as unknown as Required<PanelProps>).border
      )};
  border-radius: 8px;
  padding: 15px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  color: ${(props) =>
    colorForVariant(props, (props as unknown as Required<PanelProps>).text)};

  background-color: ${(props) =>
    colorForVariant(props, (props as unknown as Required<PanelProps>).bg)};
`;

/**
 * @deprecated Use a MUI component instead
 */
export const Panel = (props: PanelProps): React.ReactElement => {
  const requiredProps = { ...defaultProps, ...props } as Required<PanelProps>;
  return <StyledDiv {...requiredProps}>{props.children}</StyledDiv>;
};
