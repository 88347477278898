import _ from "lodash";
import { TopBottom } from "@samacare/component";
import { FieldConfig } from "../../../hooks/useFieldConfigs";
import { FieldLabel } from "./FieldLabel";

interface FormFieldProps {
  fieldConfig: FieldConfig;
  title?: string | React.ReactNode;
  style?: React.CSSProperties;
}
export const FormField: React.FC<FormFieldProps> = (props) => {
  if (props.fieldConfig.isHidden) return null;
  return (
    <TopBottom sx={props.style}>
      {props.title && (
        <FieldLabel
          htmlFor={`field_${_.camelCase(props.fieldConfig.key)}`}
          title={props.title}
          isRequired={props.fieldConfig.isRequired}
        />
      )}
      {props.children}
    </TopBottom>
  );
};
