import { gql, useMutation } from "@apollo/client";
import { Location } from "@samacare/graphql";
import { locationInfo } from "../graphql/fragments/locationInfo";
import { LOCATIONS_QUERY } from "../constants/Locations";

export const upsertLocationMutation = gql`
  mutation upsertLocation($id: Int, $patch: JSON!) {
    upsertLocation(id: $id, patch: $patch) {
      ...locationInfo
    }
  }
  ${locationInfo}
`;

export const useUpsertLocation = () => {
  const [upsertLocation] = useMutation(upsertLocationMutation, {
    refetchQueries: () => [LOCATIONS_QUERY],
  });

  return async (location: Location) => {
    const l = await upsertLocation({
      variables: {
        id: location.id,
        patch: {
          ...location,
        },
      },
    });
    return l.data?.upsertLocation;
  };
};
