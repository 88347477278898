import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { AuthorizationForm } from "@samacare/graphql";
import styled from "styled-components";
import FaxFormCard from "./FaxFormCard";
import FaxFormFilter, { FaxFormFilters } from "./FaxFormFilter";
import ZoomFormModal from "./ZoomFormModal";

interface FaxFormCardsListProps {
  forms: AuthorizationForm[];
  onSubmit: ({ formId }: { formId: string }) => void;
  favoriteForms: AuthorizationForm[];
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const FaxFormCardsList = ({
  forms,
  onSubmit,
  favoriteForms,
}: FaxFormCardsListProps) => {
  const [favoriteFormIds, setFavoriteFormIds] = useState<Set<string>>(
    new Set(favoriteForms.map((f) => f.id))
  );
  const [zoomedForm, setZoomedForm] = useState<AuthorizationForm | undefined>();
  const [filter, setFilter] =
    useState<keyof typeof FaxFormFilters>("recommended");

  const recentlyUsedForms = useMemo(
    () =>
      forms.filter(
        (form) =>
          form.lastUsed && moment().subtract(1, "month") < moment(form.lastUsed)
      ),
    [forms]
  );

  const visibleForms = useMemo(() => {
    switch (filter) {
      case "favorite":
        return favoriteForms;
      case "recentlyUsed":
        return recentlyUsedForms;
      default:
        return forms;
    }
  }, [forms, recentlyUsedForms, favoriteForms, filter]);

  useEffect(() => {
    if (forms.length && !visibleForms.length) {
      setFilter("recommended");
    }
  }, [visibleForms, forms.length]);

  return (
    <>
      <FaxFormFilter
        filter={filter}
        amount={{
          recommended: forms.length,
          favorite: favoriteForms.length,
          recentlyUsed: recentlyUsedForms.length,
        }}
        onChange={(newFilter) => setFilter(newFilter)}
      />
      <Container>
        {(visibleForms.length ? visibleForms : forms)?.map((form) => (
          <FaxFormCard
            key={form.id}
            form={form}
            setZoomedForm={setZoomedForm}
            isFavorite={favoriteFormIds.has(form.id)}
            onSubmit={onSubmit}
            onFavoriteChange={(id: string) => {
              setFavoriteFormIds((prevFavoriteFormIds) => {
                const updatedFavorites = new Set(prevFavoriteFormIds);
                if (updatedFavorites.has(id)) {
                  updatedFavorites.delete(id);
                } else {
                  updatedFavorites.add(id);
                }
                return updatedFavorites;
              });
            }}
          />
        ))}
        <ZoomFormModal
          pdfURL={zoomedForm?.pdfURL}
          onClose={() => setZoomedForm(undefined)}
          onFinish={() => {
            if (zoomedForm?.id) {
              onSubmit({ formId: zoomedForm.id });
              setZoomedForm(undefined);
            }
          }}
          title={zoomedForm?.title || ""}
        />
      </Container>
    </>
  );
};

export default FaxFormCardsList;
