import { ApolloClient, ApolloProvider } from "@apollo/client";
import { useInstitutionPrescribers } from "@samacare/hooks-data";
import {
  BaseProviderAutocomplete,
  BaseProviderAutocompleteProps,
} from "./BaseProviderAutocomplete";

type ProviderAutocompleteWithDataProps = Omit<
  BaseProviderAutocompleteProps,
  "providers"
>;
const ProviderAutocompleteWithData: React.FC<
  ProviderAutocompleteWithDataProps
> = (props) => {
  const [data, loading] = useInstitutionPrescribers();

  return (
    <BaseProviderAutocomplete providers={data} loading={loading} {...props} />
  );
};

export type ProviderAutocompleteProps = ProviderAutocompleteWithDataProps & {
  apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
};
export const ProviderAutocomplete: React.FC<ProviderAutocompleteProps> = (
  props,
) => {
  const { apolloClient, ...otherProps } = props;

  return (
    <ApolloProvider client={apolloClient as ApolloClient<object>}>
      <ProviderAutocompleteWithData {...otherProps} />
    </ApolloProvider>
  );
};
