import { useState } from "react";
import { compose } from "recompose";
import { useAlert } from "react-alert";

import { withUpdateAuthorization } from "../../graphql/Authorization";
import Modal from "../../components/Modal";
import { FormList } from "../NewAuthorization/SelectFormModal/FormList";

const ReferralSelectFormModal = ({
  updateAuthorization,
  authorization,
  nextStep,
  open,
  close,
  referralForms,
}) => {
  const alert = useAlert();
  const [requestNewForm, setRequestNewForm] = useState(false);

  const selectForm = async (selectedFormId) => {
    try {
      await updateAuthorization({
        variables: { id: authorization.id, patch: { formId: selectedFormId } },
      });
      await nextStep();
    } catch {
      alert.error("There was an error selecting an insurance form");
    }
  };

  return (
    <Modal
      header={requestNewForm ? "" : "Select Insurance Form"}
      onClick={close}
      open={open}
    >
      <FormList
        isSimplifiedUx={false}
        authorizationId={authorization.id}
        onSelect={selectForm}
        referralForms={referralForms}
        requestNewForm={requestNewForm}
        setRequestNewForm={(value) => setRequestNewForm(value)}
      />
    </Modal>
  );
};

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withUpdateAuthorization)(ReferralSelectFormModal);
