import { PureComponent } from "react";
import styled from "styled-components";
import _ from "lodash";
import PropTypes from "prop-types";

import { inputPropType } from "../taggerPropTypes";
import InputHierarchyRender from "./InputHierarchyRender";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 850px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const ID_PREFIX = "InputHierarchyElem";
class InputHeirarchy extends PureComponent {
  static propTypes = {
    deleteInput: PropTypes.func.isRequired,
    inputs: PropTypes.arrayOf(inputPropType).isRequired,
    moveNode: PropTypes.func.isRequired,
    selectedBoxIndex: PropTypes.number,
    selectedId: PropTypes.string,
    setSelected: PropTypes.func.isRequired,
    updateInput: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
  };

  ref = null;

  render() {
    const {
      inputs,
      selectedId,
      setSelected,
      moveNode,
      updateInput,
      deleteInput,
      selectedBoxIndex,
      width,
    } = this.props;

    return (
      <Container
        width={width}
        ref={(ref) => {
          this.ref = ref;
        }}
      >
        {_.isEmpty(inputs) ? (
          <div>Click Anywhere on the form to create the first input</div>
        ) : (
          _.map(inputs, (input) => (
            <InputHierarchyRender
              key={`heirachyView_${input.id}`}
              idPrefix={ID_PREFIX}
              moveNode={moveNode}
              selectedId={selectedId}
              setSelected={setSelected}
              selectedBoxIndex={selectedBoxIndex}
              input={input}
              updateInput={updateInput}
              deleteInput={deleteInput}
            />
          ))
        )}
      </Container>
    );
  }
}

export default InputHeirarchy;
