import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import Select from "react-select";
import { InternationalPhone } from "Segment/InternationalPhone";
import { BaseMultiline } from "Segment/StyledComponents";

import { withCreateFormNumber } from "../../../../graphql/FormNumber";
import BaseButton from "../../../../components/BaseButton";
import Modal from "../../../../components/Modal";
import { ModalBodyLarge } from "../../../../components/ModalStyledComponents";
import { parseServerErrorMessage } from "../../../../util/parsers";

const Container = styled.div`
  padding: 20px 0;
`;

const SelectorTitle = styled.div`
  font-weight: 700;
  margin: 9px 4px 7px 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const FormSubmitButton = styled(BaseButton)`
  margin-left: 10px;
`;

const WarningText = styled.div`
  margin-top: 10px;
  color: ${(props) => props.theme.red};
  font-weight: 700;
`;

export class CreateFormNumberModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      number: props.number,
      description: "",
      insuranceCompanyId: null,
    };
  }

  setNewNumber = () => {
    const { createFormNumber, onClose, alert, formId, onCreate } = this.props;
    const { number, description, insuranceCompanyId } = this.state;

    if (!number || !description) {
      alert.error("Missing required field");
      return;
    }

    createFormNumber({
      variables: {
        number,
        description,
        insuranceCompanyId,
        createWithoutInstitution: true,
        formId,
      },
    })
      .then(() => {
        alert.success("Success");
        if (onCreate) onCreate();
        onClose();
      })
      .catch((e) => {
        alert.error(parseServerErrorMessage(e.message));
      });
  };

  render() {
    const { insuranceCompanies, onClose } = this.props;
    const { number, description, insuranceCompanyId } = this.state;

    return (
      <Modal header="Add Fax Number" onClick={onClose} open>
        <ModalBodyLarge>
          <Container>
            <SelectorTitle>Fax Number</SelectorTitle>
            <InternationalPhone
              number={number}
              onChange={(e) => {
                this.setState({ number: e.target.value });
              }}
            />
            <SelectorTitle>Associate an insurance company</SelectorTitle>
            <Select
              onChange={(selected) => {
                this.setState({
                  insuranceCompanyId: _.get(selected, "id", null),
                });
              }}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              options={insuranceCompanies}
              value={
                _.find(insuranceCompanies, { id: insuranceCompanyId }) || {}
              }
            />
            {insuranceCompanyId && (
              <WarningText>
                Only associate a number to a payer if it is NOT form specific
                and should be visible for all users selecting this Insurance
                Company
              </WarningText>
            )}
            <SelectorTitle>Description</SelectorTitle>
            <BaseMultiline
              onChange={(e) => {
                this.setState({ description: e.target.value });
              }}
              value={description}
            />
          </Container>
          <ButtonContainer>
            <FormSubmitButton onClick={this.setNewNumber}>
              Set New Number
            </FormSubmitButton>
          </ButtonContainer>
        </ModalBodyLarge>
      </Modal>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withCreateFormNumber)(
  withAlert()(CreateFormNumberModal)
);
