import React, { ReactNode } from "react";
import { Paper, Typography } from "@samacare/design";
import styled from "styled-components";

type BlockProps = {
  contentFit?: boolean;
};

type StyledPaperProps = {
  title: string;
  children: ReactNode;
  subTitle?: string;
  contentFit?: boolean;
};

const Block = styled(Paper).withConfig({
  shouldForwardProp: (prop) =>
    prop !== ("contentFit" as unknown as keyof StyledPaperProps),
})`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  border-radius: 4px;
  border-top: 4px solid rgba(99, 99, 242, 0.5);
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  ${(props: BlockProps) => (props.contentFit ? "width: fit-content;" : "")};
`;

const Header = styled.div`
  padding: 16px;
`;

const Body = styled.div`
  padding: 16px;
`;

const StyledPaper = ({
  title,
  subTitle,
  children,
  contentFit,
}: StyledPaperProps) => (
  <Block contentFit={contentFit}>
    <Header>
      <Typography variant="h6">{title}</Typography>
      {subTitle && <Typography variant="body2">{subTitle}</Typography>}
    </Header>
    <Body>{children}</Body>
  </Block>
);

export default StyledPaper;
