const modifiers = [
  { code: "A1", description: "Dressing for one wound" },
  { code: "A2", description: "Dressing for two wounds" },
  { code: "A3", description: "Dressing for three wounds" },
  { code: "A4", description: "Dressing for four wounds" },
  { code: "A5", description: "Dressing for five wounds" },
  { code: "A6", description: "Dressing for six wounds" },
  { code: "A7", description: "Dressing for seven wounds" },
  { code: "A8", description: "Dressing for eight wounds" },
  { code: "A9", description: "Dressing for 9 or more wound" },
  { code: "AA", description: "Anesthesia perf by anesgst" },
  { code: "AE", description: "Registered dietician" },
  { code: "AF", description: "Specialty physician" },
  { code: "AG", description: "Primary physician" },
  { code: "AH", description: "Clinical psychologist" },
  { code: "AI", description: "Principal physician of rec" },
  { code: "AJ", description: "Clinical social worker" },
  { code: "AK", description: "Non participating physician" },
  { code: "AO", description: "Prov declined alt pmt method" },
  { code: "AP", description: "No dtmn of refractive state" },
  { code: "AQ", description: "Physician service hpsa area" },
  { code: "AR", description: "Physician scarcity area" },
  { code: "AW", description: "Item w a surgical dressing" },
  { code: "AX", description: "Item w dialysis services" },
  { code: "AY", description: "Item/service not for esrd tx" },
  { code: "AZ", description: "Physician serv in dent hpsa" },
  { code: "BA", description: "Item w pen services" },
  { code: "BL", description: "Spec acquisition blood prods" },
  { code: "BP", description: "Bene electd to purchase item" },
  { code: "BR", description: "Bene elected to rent item" },
  { code: "BU", description: "Bene undecided on purch/rent" },
  { code: "CA", description: "Procedure payable inpatient" },
  { code: "CC", description: "Procedure code change" },
  { code: "CD", description: "Amcc test for esrd or mcp md" },
  { code: "CE", description: "Med neces amcc tst sep reimb" },
  { code: "CF", description: "Amcc tst not composite rate" },
  { code: "CG", description: "Policy criteria applied" },
  { code: "CR", description: "Catastrophe/disaster related" },
  { code: "CT", description: "Ct does not meet nema standa" },
  { code: "ED", description: "Hct>39% or hgb>13g>=3 cycle" },
  { code: "EE", description: "Hct>39% or hgb>13g<3 cycle" },
  { code: "EM", description: "Emer reserve supply (esrd)" },
  { code: "EP", description: "Medicaid epsdt program svc" },
  { code: "ET", description: "Emergency services" },
  { code: "EX", description: "Expatriate beneficiary" },
  { code: "EY", description: "No md order for item/service" },
  { code: "FP", description: "Svc part of family plan pgm" },
  { code: "FX", description: "X-ray taken using film" },
  { code: "FY", description: "Computed radiography x-ray" },
  { code: "G1", description: "Urr reading of less than 60" },
  { code: "G2", description: "Urr reading of 60 to 64.9" },
  { code: "G3", description: "Urr reading of 65 to 69.9" },
  { code: "G4", description: "Urr reading of 70 to 74.9" },
  { code: "G5", description: "Urr reading of 75 or greater" },
  { code: "G6", description: "Esrd patient <6 dialysis/mth" },
  { code: "G7", description: "Payment limits do not apply" },
  { code: "G9", description: "Mac for at risk patient" },
  { code: "GB", description: "Claim resubmitted" },
  { code: "GC", description: "Resident/teaching phys serv" },
  { code: "GD", description: "Unit of service > mue value" },
  { code: "GE", description: "Resident prim care exception" },
  { code: "GH", description: "Diag mammo to screening mamo" },
  { code: "GK", description: "Actual item/service ordered" },
  { code: "GM", description: "Multiple transports" },
  { code: "GN", description: "Op speech language service" },
  { code: "GO", description: "Op occupational therapy serv" },
  { code: "GP", description: "Op pt services" },
  { code: "GQ", description: "Telehealth store and forward" },
  { code: "GS", description: "Epo/darbepoietin reduced 25%" },
  { code: "GT", description: "Interactivetelecommunication" },
  { code: "GV", description: "Attending phys not hospice" },
  { code: "GW", description: "Service unrelated to term co" },
  { code: "GZ", description: "Not reasonable and necessary" },
  { code: "H9", description: "Court-ordered" },
  { code: "HA", description: "Child/adolescent program" },
  { code: "HD", description: "Pregnant/parenting program" },
  { code: "HE", description: "Mental health program" },
  { code: "HF", description: "Substance abuse program" },
  { code: "HG", description: "Opioid addiction tx program" },
  { code: "HH", description: "Mental hlth/substance abs pr" },
  { code: "HI", description: "Men hlth intel/dev disab pgm" },
  { code: "HJ", description: "Employee assistance program" },
  { code: "HK", description: "Spec hgh rsk mntl hlth pop p" },
  { code: "HL", description: "Intern" },
  { code: "HM", description: "Less than bachelor degree lv" },
  { code: "HN", description: "Bachelors degree level" },
  { code: "HO", description: "Masters degree level" },
  { code: "HP", description: "Doctoral level" },
  { code: "HQ", description: "Group setting" },
  { code: "HR", description: "Family/couple w client prsnt" },
  { code: "HS", description: "Family/couple w/o client prs" },
  { code: "HT", description: "Multi-disciplinary team" },
  { code: "HU", description: "Child welfare agency funded" },
  { code: "HV", description: "Funded state addiction agncy" },
  { code: "HW", description: "State mntl hlth agncy funded" },
  { code: "HX", description: "County/local agency funded" },
  { code: "HY", description: "Funded by juvenile justice" },
  { code: "HZ", description: "Criminal justice agncy fund" },
  { code: "J1", description: "Cap no-pay for prescript num" },
  { code: "J4", description: "Dmepos comp bid furn by hosp" },
  { code: "JA", description: "Administered intravenously" },
  { code: "JB", description: "Administered subcutaneously" },
  { code: "JC", description: "Skin substitute graft" },
  { code: "JD", description: "Skin sub not used as a graft" },
  { code: "JE", description: "Administered via dialysate" },
  { code: "JF", description: "Compounded drug" },
  { code: "JG", description: "340b acquired drug" },
  { code: "JW", description: "Discarded drug not administe" },
  { code: "K0", description: "Lwr ext prost functnl lvl 0" },
  { code: "K1", description: "Lwr ext prost functnl lvl 1" },
  { code: "KA", description: "Wheelchair add-on option/acc" },
  { code: "KC", description: "Repl special pwr wc intrface" },
  { code: "KD", description: "Drug/biological dme infused" },
  { code: "KE", description: "Bid under round 1 dmepos cb" },
  { code: "KF", description: "Fda class iii device" },
  { code: "KG", description: "Dmepos comp bid prgm no 1" },
  { code: "KK", description: "Dmepos comp bid prgm no 2" },
  { code: "KL", description: "Dmepos mailorder comp bid" },
  { code: "KM", description: "Rplc facial prosth new imp" },
  { code: "KN", description: "Rplc facial prosth old mod" },
  { code: "KO", description: "Single drug unit dose form" },
  { code: "KP", description: "First drug of multi drug u d" },
  { code: "KQ", description: "2nd/subsqnt drg multi drg ud" },
  { code: "KS", description: "Glucose monitor supply" },
  { code: "KT", description: "Item from noncontract supply" },
  { code: "KU", description: "Dmepos comp bid prgm no 3" },
  { code: "KW", description: "Dmepos comp bid prgm no 4" },
  { code: "KX", description: "Documentation on file" },
  { code: "KY", description: "Dmepos comp bid prgm no 5" },
  { code: "KZ", description: "New cov not implement by m+c" },
  { code: "L1", description: "Separately payable lab test" },
  { code: "LC", description: "Lft circum coronary artery" },
  { code: "LD", description: "Left ant des coronary artery" },
  { code: "LL", description: "Lease/rental (appld to pur)" },
  { code: "LM", description: "Left main coronary artery" },
  { code: "LR", description: "Laboratory round trip" },
  { code: "LS", description: "Fda-monitored iol implant" },
  { code: "LT", description: "Left side" },
  { code: "M2", description: "Medicare secondary payer" },
  { code: "MS", description: "6-mo maint/svc fee parts/lbr" },
  { code: "NR", description: "New when rented" },
  { code: "NU", description: "New equipment" },
  { code: "P1", description: "Normal healthy patient" },
  { code: "P2", description: "Patient w/mild syst disease" },
  { code: "P3", description: "Patient w/severe sys disease" },
  { code: "P4", description: "Pt w/sev sys dis threat life" },
  { code: "P5", description: "Pt not expect surv w/o oper" },
  { code: "P6", description: "Brain-dead pt organs removed" },
  { code: "PC", description: "Wrong surgery on patient" },
  { code: "PD", description: "Inp admit w/in 3 days" },
  { code: "PI", description: "Pet tumor init tx strat" },
  { code: "PL", description: "Progressive addition lenses" },
  { code: "PM", description: "Post mortem" },
  { code: "PT", description: "Clrctal screen to diagn" },
  { code: "Q0", description: "Invest clinical research" },
  { code: "Q1", description: "Routine clinical research" },
  { code: "Q3", description: "Live donor surgery/services" },
  { code: "Q4", description: "Svc exempt - ordrg/rfrng md" },
  { code: "Q7", description: "One class a finding" },
  { code: "Q8", description: "Two class b findings" },
  { code: "Q9", description: "1 class b & 2 class c fndngs" },
  { code: "QC", description: "Single channel monitoring" },
  { code: "QD", description: "Rcrdg/strg in sld st memory" },
  { code: "QE", description: "Prescribed oxygen < 1 lpm" },
  { code: "QF", description: "Prscrbd oxygen >4 lpm & port" },
  { code: "QG", description: "Prescribed oxygen > 4 lpm" },
  { code: "QH", description: "Oxygen cnsrvg dvc w del sys" },
  { code: "QL", description: "Patient died after amb call" },
  { code: "QM", description: "Ambulance arr by provider" },
  { code: "QN", description: "Ambulance furn by provider" },
  { code: "QQ", description: "Qualified cdsm consulted" },
  { code: "QS", description: "Monitored anesthesia care" },
  { code: "QT", description: "Rcrdg/strg tape analog recdr" },
  { code: "QW", description: "Clia waived test" },
  { code: "QX", description: "Crna svc w/ md med direction" },
  { code: "QY", description: "Medically directed crna" },
  { code: "QZ", description: "Crna svc w/o med dir by md" },
  { code: "RC", description: "Right coronary artery" },
  { code: "RE", description: "Furnish full compliance rems" },
  { code: "RI", description: "Ramus intermedius cor artery" },
  { code: "RR", description: "Rental (dme)" },
  { code: "RT", description: "Right side" },
  { code: "SA", description: "Nurse practitioner w physici" },
  { code: "SB", description: "Nurse midwife" },
  { code: "SC", description: "Medically necessary serv/sup" },
  { code: "SE", description: "State/fed funded program/ser" },
  { code: "SG", description: "Asc facility service" },
  { code: "SH", description: "2nd concurrent infusion ther" },
  { code: "SJ", description: "3rd concurrent infusion ther" },
  { code: "SK", description: "High risk population" },
  { code: "SL", description: "State supplied vaccine" },
  { code: "SM", description: "Second opinion" },
  { code: "SN", description: "Third opinion" },
  { code: "SQ", description: "Item ordered by home health" },
  { code: "SS", description: "Hit in infusion suite" },
  { code: "ST", description: "Related to trauma or injury" },
  { code: "SU", description: "Performed in phys office" },
  { code: "SV", description: "Drugs delivered not used" },
  { code: "SW", description: "Serv by cert diab educator" },
  { code: "SY", description: "Contact w/high-risk pop" },
  { code: "SZ", description: "Habilitative services" },
  { code: "TD", description: "Rn" },
  { code: "TE", description: "Lpn/lvn" },
  { code: "TF", description: "Intermediate level of care" },
  { code: "TG", description: "Complex/high tech level care" },
  { code: "TL", description: "Early intervention ifsp" },
  { code: "TM", description: "Individualized ed prgrm(iep)" },
  { code: "TN", description: "Rural/out of service area" },
  { code: "TQ", description: "Bls by volunteer amb providr" },
  { code: "TR", description: "School-based iep out of dist" },
  { code: "TS", description: "Follow-up service" },
  { code: "TT", description: "Additional patient" },
  { code: "TW", description: "Back-up equipment" },
  { code: "UE", description: "Used durable med equipment" },
  { code: "UK", description: "Svc on behalf client-collat" },
  { code: "UN", description: "Two patients served" },
  { code: "UP", description: "Three patients served" },
  { code: "UQ", description: "Four patients served" },
  { code: "UR", description: "Five patients served" },
  { code: "US", description: "Six or more patients served" },
  { code: "V1", description: "Demonstration modifier 1" },
  { code: "V2", description: "Demonstration modifier 2" },
  { code: "V3", description: "Demonstration modifier 3" },
  { code: "V5", description: "Vascular catheter" },
  { code: "V6", description: "Arteriovenous graft" },
  { code: "V7", description: "Arteriovenous fistula" },
  { code: "V8", description: "Infection present" },
  { code: "V9", description: "No infection present" },
  { code: "VM", description: "Mdpp virtual make-up session" },
  { code: "VP", description: "Aphakic patient" },
  { code: "ZA", description: "Novartis/sandoz" },
  { code: "ZB", description: "Pfizer/hospira" },
  { code: "ZC", description: "Merck/samsung bioepis" },
  { code: "AM", description: "Physician, team member svc" },
  { code: "AS", description: "Assistant at surgery service" },
  { code: "AT", description: "Acute treatment" },
  { code: "AU", description: "Uro, ostomy or trach item" },
  { code: "AV", description: "Item w prosthetic/orthotic" },
  { code: "BO", description: "Nutrition oral admin no tube" },
  { code: "CB", description: "Esrd bene part a snf-sep pay" },
  { code: "CH", description: "0 percent impaired, ltd, res" },
  { code: "CI", description: "1 to <20 percent impaired" },
  { code: "CJ", description: "20 to <40 percent impaired" },
  { code: "CK", description: "40 to <60 percent impaired" },
  { code: "CL", description: "60 to <80 percent impaired" },
  { code: "CM", description: "80 to <100 percent impaired" },
  { code: "CN", description: "100 percent impaired, ltd" },
  { code: "CP", description: "C-apc adjunctive service" },
  { code: "E1", description: "Upper left eyelid" },
  { code: "E2", description: "Lower left eyelid" },
  { code: "E3", description: "Upper right eyelid" },
  { code: "E4", description: "Lower right eyelid" },
  { code: "EJ", description: "Subsequent claim" },
  { code: "F1", description: "Left hand, second digit" },
  { code: "F2", description: "Left hand, third digit" },
  { code: "F3", description: "Left hand, fourth digit" },
  { code: "F4", description: "Left hand, fifth digit" },
  { code: "F5", description: "Right hand, thumb" },
  { code: "F6", description: "Right hand, second digit" },
  { code: "F7", description: "Right hand, third digit" },
  { code: "F8", description: "Right hand, fourth digit" },
  { code: "F9", description: "Right hand, fifth digit" },
  { code: "FA", description: "Left hand, thumb" },
  { code: "FB", description: "Item provided without cost" },
  { code: "G8", description: "Monitored anesthesia care" },
  { code: "GA", description: "Liability waiver ind case" },
  { code: "GF", description: "Nonphysician serv c a hosp" },
  { code: "GG", description: "Payment screen mam + diagmam" },
  { code: "GJ", description: "Opt out provider of er srvc" },
  { code: "GL", description: "Upgraded item, no charge" },
  { code: "GR", description: "Service by va resident" },
  { code: "GU", description: "Liability waiver rout notice" },
  { code: "GX", description: "Voluntary liability notice" },
  { code: "GY", description: "Statutorily excluded" },
  { code: "HB", description: "Adult program non-geriatric" },
  { code: "HC", description: "Adult program geriatric" },
  { code: "J2", description: "Cap restock of emerg drugs" },
  { code: "J3", description: "Cap drug unavail thru cap" },
  { code: "KB", description: ">4 modifiers on claim" },
  { code: "KH", description: "Dmepos ini clm, pur/1 mo rnt" },
  { code: "KI", description: "Dmepos 2nd or 3rd mo rental" },
  { code: "KJ", description: "Dmepos pen pmp or 4-15mo rnt" },
  { code: "KR", description: "Rental item partial month" },
  { code: "NB", description: "Drug specific nebulizer" },
  { code: "PN", description: "Non-excepted off-campus svc" },
  { code: "PO", description: "Excepted off-campus service" },
  { code: "QJ", description: "Patient in state/locl custod" },
  { code: "QK", description: "Med dir 2-4 cncrnt anes proc" },
  { code: "QP", description: "Individually ordered lab tst" },
  { code: "RA", description: "Replacement of dme item" },
  { code: "RB", description: "Replacement part, dme item" },
  { code: "RD", description: "Drug admin not incident-to" },
  { code: "SD", description: "Serv by home infusion rn" },
  { code: "SF", description: "2nd opinion ordered by pro" },
  { code: "T1", description: "Left foot, second digit" },
  { code: "T2", description: "Left foot, third digit" },
  { code: "T3", description: "Left foot, fourth digit" },
  { code: "T4", description: "Left foot, fifth digit" },
  { code: "T5", description: "Right foot, great toe" },
  { code: "T6", description: "Right foot, second digit" },
  { code: "T7", description: "Right foot, third digit" },
  { code: "T8", description: "Right foot, fourth digit" },
  { code: "T9", description: "Right foot, fifth digit" },
  { code: "TA", description: "Left foot, great toe" },
  { code: "TB", description: "Tracking 340b acquired drug" },
  { code: "TH", description: "Ob tx/srvcs prenatl/postpart" },
  { code: "TJ", description: "Child/adolescent program gp" },
  { code: "TK", description: "Extra patient or passenger" },
  { code: "TP", description: "Med transprt unloaded vehicl" },
  { code: "TU", description: "Overtime payment rate" },
  { code: "TV", description: "Holiday/weekend payment rate" },
  { code: "U1", description: "M/caid care lev 1 state def" },
  { code: "U2", description: "M/caid care lev 2 state def" },
  { code: "U3", description: "M/caid care lev 3 state def" },
  { code: "U4", description: "M/caid care lev 4 state def" },
  { code: "U5", description: "M/caid care lev 5 state def" },
  { code: "U6", description: "M/caid care lev 6 state def" },
  { code: "U7", description: "M/caid care lev 7 state def" },
  { code: "U8", description: "M/caid care lev 8 state def" },
  { code: "U9", description: "M/caid care lev 9 state def" },
  { code: "UA", description: "M/caid care lev 10 state def" },
  { code: "UB", description: "M/caid care lev 11 state def" },
  { code: "UC", description: "M/caid care lev 12 state def" },
  { code: "UD", description: "M/caid care lev 13 state def" },
  { code: "XE", description: "Separate encounter" },
  { code: "XP", description: "Separate practitioner" },
  { code: "XS", description: "Separate organ/structure" },
  { code: "XU", description: "Unusual separate service" },
  { code: "AD", description: "Md supervision, >4 anes proc" },
  { code: "DA", description: "Oral health assess, not dent" },
  { code: "EA", description: "Esa, anemia, chemo-induced" },
  { code: "EB", description: "Esa, anemia, radio-induced" },
  { code: "EC", description: "Esa, anemia, non-chemo/radio" },
  { code: "FC", description: "Part credit, replaced device" },
  { code: "KV", description: "Dmepos item, profession serv" },
  { code: "PA", description: "Surgery, wrong body part" },
  { code: "PB", description: "Surgery, wrong patient" },
  { code: "Q2", description: "Demo procedure, service" },
  { code: "UF", description: "Services provided, morning" },
  { code: "UG", description: "Services provided, afternoon" },
  { code: "UH", description: "Services provided, evening" },
  { code: "UJ", description: "Services provided, night" },
  { code: "CS", description: "Gulf oil 2010 spill related" },
  { code: "K2", description: "Lwr ext prost functnl lvl 2" },
  { code: "K3", description: "Lwr ext prost functnl lvl 3" },
  { code: "K4", description: "Lwr ext prost functnl lvl 4" },
  { code: "PS", description: "Pet tumor subsq tx strategy" },
  { code: "Q5", description: "Recip bill arr subs md or pt" },
  { code: "Q6", description: "Fee/time comp subst md or pt" },
  { code: "TC", description: "Technical component" },
  { code: "X1", description: "Continuous/broad services" },
  { code: "X2", description: "Continuous/focused services" },
  { code: "X3", description: "Episodic/broad services" },
  { code: "X4", description: "Episodic/focused services" },
  { code: "X5", description: "Svc req by another clinician" },
];

export default modifiers;
