import styled from "styled-components";

const DEFAULT_CELL_PADDING_PX = 8;

export const Th = styled.th`
  box-sizing: border-box;
  padding-bottom: ${DEFAULT_CELL_PADDING_PX}px;
  padding-left: ${DEFAULT_CELL_PADDING_PX}px;
  padding-right: ${DEFAULT_CELL_PADDING_PX}px;
  padding-top: ${DEFAULT_CELL_PADDING_PX}px;
`;

export const Tr = styled.tr`
  box-sizing: border-box;
  background-color: white;

  &:hover {
    filter: brightness(90%);
  }
`;

export const Td = styled.td`
  box-sizing: border-box;
  padding-bottom: ${DEFAULT_CELL_PADDING_PX}px;
  padding-left: ${DEFAULT_CELL_PADDING_PX}px;
  padding-right: ${DEFAULT_CELL_PADDING_PX}px;
  padding-top: ${DEFAULT_CELL_PADDING_PX}px;
`;

export const THead = styled.thead`
  box-sizing: border-box;
  text-align: left;

  ${Tr} {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
`;

export const TBody = styled.tbody`
  box-sizing: border-box;

  ${Tr}:not(:last-child) {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
`;

const BaseTable = styled.table`
  border-spacing: 0px;
  box-sizing: border-box;
  min-width: 100%;
`;

type Table = typeof BaseTable & {
  Head: typeof THead;
  Th: typeof Th;
  Body: typeof TBody;
  Tr: typeof Tr;
  Td: typeof Td;
};

export const Table: Table = Object.assign(BaseTable, {
  Head: THead,
  Th,
  Body: TBody,
  Tr,
  Td,
});
