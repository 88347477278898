import { PureComponent } from "react";
import { withAlert } from "react-alert";
import { compose } from "recompose";
import strings from "Resources/strings";
import styled from "styled-components";
import { BaseText } from "Segment/StyledComponents";
import Button from "@samacare/design/core/Button";

import { withResetPassword } from "../graphql/Account";
import AlertModal from "./AlertModal";

const SectionTitle = styled.div`
  color: ${(props) => props.theme.gray};
  font-weight: 700;
  margin-top: 10px;
  margin-right: auto;
`;

const Message = styled.div`
  color: ${(props) => (props.warn ? props.theme.red : props.theme.green)};
  text-align: center;
  margin-top: 20px;
`;

const { RETURN_CODES } = CONFIG.CONSTANTS;

class ForgotPasswordButton extends PureComponent {
  state = { openModal: false, email: "" };

  onOpenModal = (e) => {
    const { email } = this.props;
    e.stopPropagation();
    this.setState({
      openModal: true,
      email,
      isRequesting: false,
      message: "",
      shouldShowButton: true,
    });
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  sendResetPasswordLink = () => {
    const { resetPassword, alert } = this.props;
    const { email } = this.state;
    this.setState({ isRequesting: true });
    resetPassword({
      variables: {
        email,
      },
    })
      .then((response) => {
        switch (response.data.resetPassword.code) {
          case RETURN_CODES.OK:
            this.setState({
              message: strings.RESET_PASSWORD.SUCCESS_SENDING_LINK,
              shouldShowButton: false,
            });
            break;
          case RETURN_CODES.ACCOUNT_NOT_FOUND:
            this.setState({
              message:
                "SamaCare does not have an account on file for this email. " +
                "Please reach out to help@samacare.com or your practice administrator if you need to create a new account.",
              isRequesting: false,
            });
            break;
          default:
            alert.error("Unexpected error. Please try again.");
            break;
        }
      })
      .catch(() => {
        alert.error(strings.RESET_PASSWORD.ERROR_SENDING_LINK);
        this.setState({ isRequesting: false });
      });
  };

  render() {
    const { openModal, email, message, isRequesting, shouldShowButton } =
      this.state;

    return (
      <div>
        <AlertModal
          buttons={
            shouldShowButton ? (
              <Button
                sx={{ width: "100%" }}
                onClick={this.sendResetPasswordLink}
                disabled={isRequesting || !email}
              >
                {strings.RESET_PASSWORD.SEND_RESET_LINK}
              </Button>
            ) : null
          }
          content={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SectionTitle>
                {strings.RESET_PASSWORD.CONFIRM_EMAIL}
              </SectionTitle>
              <BaseText
                type="email"
                placeholder={strings.COMMON.EMAIL_PLACEHOLDER}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
                value={email}
                onKeyPress={({ key }) =>
                  key === "Enter" && this.sendResetPasswordLink()
                }
              />
              {message && (
                <Message
                  warn={message !== strings.RESET_PASSWORD.SUCCESS_SENDING_LINK}
                >
                  {message}
                </Message>
              )}
            </div>
          }
          header={strings.RESET_PASSWORD.HEADER}
          open={openModal}
          closeModal={this.onCloseModal}
        />

        <Button fullWidth onClick={this.onOpenModal}>
          Forgot Password
        </Button>
      </div>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withResetPassword)(withAlert()(ForgotPasswordButton));
