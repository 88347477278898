import _ from "lodash";

import { Stack, Typography } from "@samacare/design";
import { UnifiedBenefitsCheckStatus as UnifiedBenefitsCheckStatusType } from "@samacare/graphql";

import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";

const getStatusLabel = (status?: UnifiedBenefitsCheckStatusType | null) => {
  if (status === UnifiedBenefitsCheckStatusType.Active) {
    return { label: "Active", color: "green" };
  }
  if (status === UnifiedBenefitsCheckStatusType.Inactive) {
    return { label: "Inactive", color: "error" };
  }

  return { label: _.capitalize(status || "-"), color: "primary" };
};

export const UnifiedBenefitsCheckCoverageStatus: React.FC = () => {
  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();
  const { label, color } = getStatusLabel(unifiedBenefitsCheck?.status);
  return (
    <Stack flexDirection="row">
      <Typography variant="subtitle2" color={color}>
        {label}
      </Typography>
    </Stack>
  );
};
