import { useQuery, useMutation } from "@apollo/client";
import {
  Box,
  LoadingButton,
  Typography,
  Stack,
  CircularProgress,
  Paper,
} from "@samacare/design/core";
import { Authorization } from "@samacare/graphql";
import { useTheme } from "@samacare/design/core/styles";
import {
  GetBenefitsVerificationByPatientAndDrugQueryQuery,
  GetBenefitsVerificationByPatientAndDrugQueryQueryVariables,
  CreateABvFromAnAuthMutation,
  CreateABvFromAnAuthMutationVariables,
} from "@@generated/graphql";
import { useConfig } from "../../hooks/config";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import ROUTES from "../../routes/ROUTE_PATHS";
import GetBenefitsVerificationByPatientAndDrugQuery from "./GetBenefitsVerificationByPatientAndDrugQuery.gql";
import CreateABvFromAnAuth from "./CreateBvFromAuthMutation.gql";
import InfoOutlinedIcon from "@samacare/design/core/icons/InfoOutlined";

interface SponsoredBvCreationForSummaryPageProps {
  auth: Authorization;
}

export const SponsoredBvCreationForSummaryPage = ({
  auth,
}: SponsoredBvCreationForSummaryPageProps) => {
  const { patient, HCPCSCodes, DrugOptionId } = auth;

  const config = useConfig();
  const theme = useTheme();

  const isEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBenefitsVerification
  );

  const { data, loading } = useQuery<
    GetBenefitsVerificationByPatientAndDrugQueryQuery,
    GetBenefitsVerificationByPatientAndDrugQueryQueryVariables
  >(GetBenefitsVerificationByPatientAndDrugQuery, {
    skip: !patient?.id || !DrugOptionId,
    variables: {
      patientId: Number(patient?.id),
      DrugOptionId,
    },
  });

  const [createBvFromAuth] = useMutation<
    CreateABvFromAnAuthMutation,
    CreateABvFromAnAuthMutationVariables
  >(CreateABvFromAnAuth, {
    refetchQueries: [
      GetBenefitsVerificationByPatientAndDrugQuery,
      "allBenefitsVerifications",
      "GetAuthorization",
    ],
  });

  const mostRecentBv = data?.getBenefitsVerificationsByPatientAndDrug?.bvs?.[0];
  const bvNotSupported =
    DrugOptionId == null ||
    data?.getBenefitsVerificationsByPatientAndDrug?.isBvSupported === false;

  if (!isEnabled || !DrugOptionId || mostRecentBv != null || bvNotSupported) {
    return <Box />;
  }

  return (
    <Paper
      elevation={1}
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        padding: 2,
        marginY: 2,
        marginLeft: 0.5,
      }}
    >
      {loading && <CircularProgress />}

      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Stack spacing={2} direction="row" alignItems="center">
          <InfoOutlinedIcon sx={{ color: theme.palette.primary.main }} />
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            {`Benefit Verification available for ${
              (HCPCSCodes ?? [])[0]?.drugName
            }`}
          </Typography>
        </Stack>

        <LoadingButton
          data-cy="actionCreateBenefitsVerificationFromPa"
          type="button"
          size="small"
          variant="text"
          onClick={async () => {
            const res = await createBvFromAuth({
              variables: {
                authId: Number(auth.id),
              },
            });
            window.open(
              `${window.location.origin}/#${ROUTES.BENEFITS_VERIFICATIONS.path}/edit/${res?.data?.createBvFromAuth}`,
              "_blank"
            );
          }}
          sx={{ marginTop: ({ spacing }) => spacing(1) }}
        >
          Create Benefit Verification Now
        </LoadingButton>
      </Stack>
    </Paper>
  );
};
