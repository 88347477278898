import * as React from "react";
import mousetrap from "mousetrap";

export function mouseTrap(
  Base: typeof React.Component
): typeof React.Component {
  return class extends React.Component {
    public __mousetrapBindings: string[] = [];

    public bindShortcut(key: string, callback: () => void) {
      mousetrap.bind(key, callback);
      this.__mousetrapBindings.push(key);
    }

    public unbindShortcut(key: string) {
      const index = this.__mousetrapBindings.indexOf(key);

      if (index > -1) {
        this.__mousetrapBindings.splice(index, 1);
      }

      mousetrap.unbind(key);
    }

    public unbindAllShortcuts() {
      if (this.__mousetrapBindings.length < 1) {
        return;
      }

      this.__mousetrapBindings.forEach((binding) => {
        mousetrap.unbind(binding);
      });
      this.__mousetrapBindings = [];
    }

    public componentWillUnmount() {
      this.unbindAllShortcuts();
    }

    public render() {
      return (
        <Base
          {...this.props}
          bindShortcut={this.bindShortcut.bind(this)}
          unbindShortcut={this.unbindShortcut.bind(this)}
        />
      );
    }
  };
}

export default mouseTrap;
