import { PureComponent } from "react";
import { withAlert } from "react-alert";
import { compose } from "recompose";
import { ReactTooltip } from "@@ui-kit/ReactTooltip";

import strings from "Resources/strings";
import { withCancelAuthorizationSend } from "../graphql/Authorization";

class CancelAuthorizationSendButton extends PureComponent {
  cancelAuthorizationSend = () => {
    const { authorization, cancelAuthorizationSend, alert } = this.props;

    cancelAuthorizationSend({ variables: { id: authorization.id } })
      .then(() =>
        alert.success(strings.UPDATE_AUTH_STATUS.SUCCESS_CANCELLING_SEND)
      )
      .catch(() =>
        alert.error(strings.UPDATE_AUTH_STATUS.ERROR_CANCELLING_SEND)
      );
  };

  render() {
    const { children } = this.props;

    return (
      <div>
        <div
          data-tooltip-id="cancelAuthorizationSendButton"
          data-cy="actionCancelAuthorizationSend"
          onClick={(e) => {
            e.stopPropagation();
            this.cancelAuthorizationSend();
          }}
        >
          <ReactTooltip id="cancelAuthorizationSendButton">
            {strings.AUTHORIZATIONS.CANCEL_AUTH_SEND_BUTTON_TOOLTIP}
          </ReactTooltip>
          {children}
        </div>
      </div>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withCancelAuthorizationSend)(
  withAlert()(CancelAuthorizationSendButton)
);
