import * as React from "react";
import { HTML5Backend } from "react-dnd-html5-backend-new";
import { DndProvider } from "react-dnd-new";
import { PDFEditorForManuallyUploadedForm } from "./PDFEditorForManuallyUploadedForm";
import { Authorization } from "../PCAuthorizations/PatientTile/interfaces";

export const PDFEditorWrapperForManuallyUploadedForm: React.FunctionComponent<{
  onBack: () => void;
  onSubmit: (
    onStart: () => Promise<void>,
    onEnd: () => void,
    shouldStart: () => boolean
  ) => void;
  authorization: Authorization;
  samaFaxNumber: string;
}> = ({ onBack, onSubmit, authorization, samaFaxNumber }) => (
  <DndProvider backend={HTML5Backend}>
    <PDFEditorForManuallyUploadedForm
      onBack={onBack}
      nextStep={({ onStart, onEnd, shouldStart }) =>
        onSubmit(onStart, onEnd, shouldStart)
      }
      authorization={authorization}
      samaFaxNumber={samaFaxNumber}
    />
  </DndProvider>
);
