import { SearchResultItem } from "../types";
import { formatDrug } from "../gridUtils";
import { Box } from "@samacare/design";
import { useFeatureFlag } from "@@hooks/useFeatureFlag";
import { useConfig } from "@@hooks/config";
import { EnhancedServicesIcon } from "@samacare/component";

export const GridCellDrug: React.FC<{ drug: SearchResultItem["drug"] }> = ({
  drug,
}) => {
  const config = useConfig();
  const displayText = formatDrug(drug);

  const enhancedMessagingEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEnhancedServicesMessaging
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {displayText}
      </span>
      {enhancedMessagingEnabled && (
        <EnhancedServicesIcon sx={{ fontSize: 20 }} />
      )}
    </Box>
  );
};
