import { useStateOptions, StateOption } from "./useStateOptions";

export type { StateOption };

export const useStateOption = (
  value: string,
): { states: StateOption[]; selectedState: StateOption | null } => {
  const states = useStateOptions();
  return {
    states,
    selectedState:
      states.find(({ label, id }) => label === value || id === value) || null,
  };
};
