import { AuthorizationFileModal } from "@@components/AuthorizationFileModal";
import _ from "lodash";
import { useState } from "react";
import {
  Account,
  Authorization,
  AuthorizationCorrespondence,
  AuthorizationLogType,
  File,
} from "@samacare/graphql";
import { GetAuthorizationLogsQuery } from "@@generated/graphql";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@samacare/design/core";
import AssociateCorrespondenceModal from "@@components/AssociateCorrespondenceModal";
import ViewIcon from "@samacare/design/core/icons/FilePresentOutlined";
import DownloadIcon from "@samacare/design/core/icons/FileDownloadOutlined";
import Tooltip from "@samacare/design/core/Tooltip/Tooltip";
import moment from "moment";
import { Stack } from "@samacare/design";
import { useCurrentAccount } from "../../../graphql/Account";

type Details = {
  status?: string;
  sendingFaxError?: string;
};

const AUTHORIZATION_LOGS_VIEWABLE_BY_PRACTICE_USER = [
  AuthorizationLogType.StatusChange,
  AuthorizationLogType.UndoAutoAssociation,
  AuthorizationLogType.UndoManualAssociation,
  AuthorizationLogType.SuccessfulFaxAssociation,
  AuthorizationLogType.SuccessfulStatusCheck,
  AuthorizationLogType.Duplication,
  AuthorizationLogType.Submission,
  AuthorizationLogType.ManualAssociation,
  AuthorizationLogType.UserUploadedFile,
  AuthorizationLogType.AuthorizationAssigned,
  AuthorizationLogType.SuccessfulFaxTransmission,
  AuthorizationLogType.AuthorizationAssigned,
  AuthorizationLogType.RemoveUploadedFile,
];

const hasErrorText = (details: Details) => {
  return Boolean(details.sendingFaxError);
};

const generateEventTypeText = (
  event: AuthorizationLogType,
  assignee: Account | null,
  details: Details
): string => {
  switch (event) {
    case AuthorizationLogType.UndoAutoAssociation:
    case AuthorizationLogType.UndoManualAssociation:
      return "File removed from Auth";
    case AuthorizationLogType.SuccessfulStatusCheck:
    case AuthorizationLogType.SuccessfulFaxAssociation:
      return "Response uploaded";
    case AuthorizationLogType.Duplication:
      return "Duplicated";
    case AuthorizationLogType.Submission:
      return "Submitted";
    case AuthorizationLogType.Resubmission:
      return "Resubmitted";
    case AuthorizationLogType.ManualAssociation:
      return "File manually associated";
    case AuthorizationLogType.RemoveUploadedFile:
      return "File deleted";
    case AuthorizationLogType.UserUploadedFile:
      return "Uploaded file";
    case AuthorizationLogType.AuthorizationAssigned:
      return `Assigned to ${assignee?.firstName} ${assignee?.lastName}`;
    case AuthorizationLogType.StatusChange:
      return `Status changed to ${_.startCase(_.camelCase(details?.status))}`;
    case AuthorizationLogType.SuccessfulFaxTransmission:
      return "Fax transmitted";
    default:
      return _.startCase(event);
  }
};

const generateEventText = (
  event: AuthorizationLogType,
  assignee: Account | null,
  details: Details
): string => {
  const eventText = generateEventTypeText(event, assignee, details);
  if (hasErrorText(details)) {
    return `${eventText} - ${details.sendingFaxError}`;
  }
  return eventText;
};

const generateUserText = (
  account: Account | null,
  isLoggedInUserSamaUser: boolean
): string => {
  // show sama user info for event if available and logged in user is a sama user
  if (account) {
    if (isLoggedInUserSamaUser && account.isSamaUser) {
      return `SamaCare – ${account.firstName} ${account.lastName}`;
    }
    return `${account.firstName} ${account.lastName}`;
  }

  // hide user info if unavailable or if sama user and logged in user is a practice user
  return "SamaCare";
};

const HistoryTab: React.VFC<{
  authorization: Authorization;
  authorizationLogs: GetAuthorizationLogsQuery["getAuthorizationLog"] | null;
  authForm?: boolean;
}> = ({ authorization, authorizationLogs, authForm }) => {
  const [showCorrespondenceModal, setShowCorrespondenceModal] = useState(false);
  const [selectedCorrespondence, setSelectedCorrespondence] =
    useState<null | AuthorizationCorrespondence>(null);
  const [allowFileDisassociation, setAllowFileDisassociation] =
    useState<boolean>(false);
  const [allowFileRemoval, setAllowFileRemoval] = useState<boolean>(false);
  const [denyFileChanges, setDenyFileChanges] = useState<null | boolean>(null);
  const [selectedFile, setSelectedFile] = useState<null | File>(null);
  const [currentAccount] = useCurrentAccount();

  const handleViewCorrespondence = async (
    file: AuthorizationCorrespondence | null,
    type: AuthorizationLogType
  ) => {
    if (file) {
      const hasNoAuth = file.AuthorizationId != null;
      await Promise.all([
        setSelectedCorrespondence(file),
        // allow userUploadedFiles to be removed not disassociated
        // hide delete button for correspondences not linked to an auth
        setAllowFileRemoval(
          _.includes([AuthorizationLogType.UserUploadedFile], type) && hasNoAuth
        ),
        // hide 'wrong file' button for correspondences not linked to an auth
        setAllowFileDisassociation(
          !_.includes(
            [
              AuthorizationLogType.Submission,
              AuthorizationLogType.UserUploadedFile,
              AuthorizationLogType.UndoAutoAssociation,
              AuthorizationLogType.UndoManualAssociation,
              AuthorizationLogType.RemoveUploadedFile,
            ],
            type
          ) && hasNoAuth
        ),
        // prevent users from updating status based on a file uploaded by them
        setDenyFileChanges(
          _.includes(
            [
              AuthorizationLogType.SuccessfulFaxTransmission,
              AuthorizationLogType.UserUploadedFile,
            ],
            type
          )
        ),
      ]);

      setShowCorrespondenceModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowCorrespondenceModal(false);
    setAllowFileDisassociation(false);
    setAllowFileRemoval(false);
    setDenyFileChanges(null);
  };
  const handleFile = (file: File) => {
    setSelectedFile(file);
  };
  const handleCloseFileModal = () => {
    setSelectedFile(null);
  };

  return (
    <Box sx={{ minHeight: ({ spacing }) => spacing(60) }}>
      {authorizationLogs && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Events</TableCell>
              <TableCell align="left">User</TableCell>
              <TableCell align="left">Time</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {authorizationLogs
              ?.filter(({ type }) =>
                _.includes(AUTHORIZATION_LOGS_VIEWABLE_BY_PRACTICE_USER, type)
              )
              .map(
                (
                  {
                    type,
                    account,
                    file,
                    createdAt,
                    assignee,
                    details,
                    authorizationCorrespondence,
                  },
                  ind
                ) => {
                  const hasDownloadLink = file?.downloadUrl;
                  const hasSubmittedFile =
                    authForm &&
                    type === AuthorizationLogType.Submission &&
                    Boolean(file);
                  const hasCorrespondence = Boolean(
                    authorizationCorrespondence?.fileURL
                  );

                  return (
                    <TableRow
                      key={`${type}_${ind}`}
                      sx={{ height: ({ spacing }) => spacing(6) }}
                    >
                      <TableCell
                        scope="row"
                        sx={{ borderBottomStyle: "hidden", width: "50%" }}
                      >
                        {generateEventText(
                          type,
                          assignee as Account,
                          details as Details
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ borderBottomStyle: "hidden" }}
                      >
                        {generateUserText(
                          account as Account,
                          currentAccount?.isSamaUser || false
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ borderBottomStyle: "hidden" }}
                      >
                        {moment(createdAt).format("YYYY/MM/DD, HH:mmA")}
                      </TableCell>
                      {authForm && hasSubmittedFile && (
                        <TableCell
                          align="left"
                          sx={{ borderBottomStyle: "hidden" }}
                        >
                          <Stack direction="row" spacing={2}>
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleFile(file as File);
                              }}
                              disabled={!hasSubmittedFile}
                            >
                              <ViewIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() =>
                                window.open(
                                  file?.downloadUrl as string,
                                  "blank"
                                )
                              }
                              disabled={!hasDownloadLink}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      )}
                      {hasCorrespondence && (
                        <Tooltip
                          title={
                            hasCorrespondence
                              ? ""
                              : "This response was created without an associated file. This happens when a response is received over the phone or if the user hasn't uploaded the associated form for this response. You can view the notes in the main list to find more details on the response or add a form below."
                          }
                        >
                          <TableCell
                            align="left"
                            sx={{ borderBottomStyle: "hidden" }}
                          >
                            <Stack direction="row" spacing={2}>
                              <IconButton
                                size="small"
                                onClick={async () => {
                                  await handleViewCorrespondence(
                                    authorizationCorrespondence as AuthorizationCorrespondence,
                                    type
                                  );
                                }}
                                disabled={!hasCorrespondence}
                              >
                                <ViewIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  window.open(
                                    file?.downloadUrl as string,
                                    "blank"
                                  )
                                }
                                disabled={!hasDownloadLink}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </Tooltip>
                      )}
                    </TableRow>
                  );
                }
              )}
          </TableBody>
        </Table>
      )}

      {showCorrespondenceModal && (
        <AssociateCorrespondenceModal
          authorization={authorization}
          closeModal={() => handleCloseModal()}
          correspondence={selectedCorrespondence ?? undefined}
          allowDisassociation={allowFileDisassociation}
          allowRemoval={allowFileRemoval}
          allowChanges={
            !denyFileChanges &&
            selectedCorrespondence != null &&
            _.get(authorization, "latestCorrespondence.fileAWSKey") ===
              selectedCorrespondence.fileAWSKey
          }
        />
      )}
      {!!selectedFile && (
        <AuthorizationFileModal
          authorization={authorization}
          closeModal={handleCloseFileModal}
          file={selectedFile}
        />
      )}
    </Box>
  );
};

export default HistoryTab;
