import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";

import React, { useState } from "react";
import EditIcon from "@samacare/design/core/icons/Edit";
import {
  PatientIntegrationCredentialsFindAllQuery,
  PatientIntegrationCredentialsFindAllQueryVariables,
  UpsertPatientIntegrationCredentialMutation,
  UpsertPatientIntegrationCredentialMutationVariables,
} from "@@generated/graphql";
import { Box, Button, DataGridPro, GridColDef } from "@samacare/design/core";
import { useGridFilterUrlSync } from "../../BenefitsVerifications/gridHooks";

import {
  PATIENT_INTEGRATION_FIND_ALL_QUERY_NAME,
  patientIntegrationCredentialsQuery,
  upsertPatientIntegrationCredentialMutation,
} from "../../../graphql/PatientIntegrationCredential";
import MaskedValue from "@samacare/form/MaskedValue";
import { IconButton, Stack } from "@samacare/design";
import { EditSyncerDialog } from "./EditSyncerDialog";
import { useAlert } from "react-alert";
import { parseISO } from "date-fns";
import Tooltip from "@samacare/design/core/Tooltip/Tooltip";
import GridWrapper from "../../../components/DataGrid/GridWrapper";
import { GridToolbar } from "../../../components/DataGrid/GridToolbar";

export type PatientSyncersGridRow = {
  id: string;
  institution: string;
  institutionId: string;
  integrationTitle: string;
  username: string;
  password: string;
  loginUrl: string;
  officeKey: string;
  isDisabled: boolean;
  lastRun: Date;
  lastRunIsSuccess: boolean;
  summary: string;
};

export const PatientSyncers: React.FC = () => {
  const [selectedSyncer, setSelectedSyncer] =
    useState<PatientSyncersGridRow | null>(null);

  const { data: credentialsData, loading: credentialsLoading } = useQuery<
    PatientIntegrationCredentialsFindAllQuery,
    PatientIntegrationCredentialsFindAllQueryVariables
  >(patientIntegrationCredentialsQuery);

  const { filterModel, setFilterModel } = useGridFilterUrlSync();

  const [upsertPatientIntegrationCredential] = useMutation<
    UpsertPatientIntegrationCredentialMutation,
    UpsertPatientIntegrationCredentialMutationVariables
  >(upsertPatientIntegrationCredentialMutation, {
    refetchQueries: [PATIENT_INTEGRATION_FIND_ALL_QUERY_NAME],
  });

  const alert = useAlert();

  const handleChangeStatus = async ({
    isDisabled,
    id,
    institutionId,
    integrationTitle,
  }: PatientSyncersGridRow) => {
    try {
      await upsertPatientIntegrationCredential({
        variables: {
          id: parseInt(id),
          institutionId: parseInt(institutionId),
          patch: {
            title: integrationTitle,
            isDisabled: !isDisabled,
          },
        },
      });
      alert.info(`Successfully ${isDisabled ? "enabled" : "disabled"} syncer`);
    } catch (e) {
      alert.error(`Unable to ${isDisabled ? "enable" : "disable"} syncer`);
    }
  };

  const columns = (): GridColDef[] => [
    {
      field: "institution",
      headerName: "Institution",
      flex: 1,
    },
    {
      field: "integrationTitle",
      headerName: "Integration Title",
      flex: 1,
    },
    {
      field: "username",
      headerName: "username",
      flex: 1,
      renderCell: (params: { row: PatientSyncersGridRow }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: ({ spacing }) => spacing(12),
          }}
        >
          <MaskedValue name="username" value={params.row.username} />
        </Box>
      ),
    },
    {
      field: "password",
      headerName: "Password",
      flex: 1,
      renderCell: (params: { row: PatientSyncersGridRow }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: ({ spacing }) => spacing(12),
          }}
        >
          <MaskedValue name="password" value={params.row.password} />
        </Box>
      ),
    },
    {
      field: "loginUrl",
      headerName: "Login URL",
      flex: 1,
    },
    {
      field: "officeKey",
      headerName: "Office Key",
      flex: 1,
    },
    {
      field: "lastRun",
      headerName: "Last Run",
      flex: 1,
      renderCell: (params: { row: PatientSyncersGridRow }) => {
        const { lastRun } = params.row;
        return lastRun ? (
          <Tooltip title={moment(lastRun).format("LLLL")}>
            <span>{moment(lastRun).format("L")}</span>
          </Tooltip>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "summary",
      headerName: "Summary",
      flex: 1,
      renderCell: (params: { row: PatientSyncersGridRow }) => {
        const { lastRunIsSuccess, summary } = params.row;
        return lastRunIsSuccess ? "Success" : summary;
      },
    },
    {
      field: "isDisabled",
      headerName: "",
      flex: 1,
      renderCell: (params: { row: PatientSyncersGridRow }) => {
        const { id, isDisabled } = params.row;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: ({ spacing }) => spacing(10),
            }}
          >
            {id && (
              <Button
                size="small"
                variant={isDisabled ? "contained" : "outlined"}
                onClick={async () => {
                  await handleChangeStatus(params.row);
                }}
              >
                {isDisabled ? "Enable" : "Disable"}
              </Button>
            )}
          </Box>
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      hideable: false,
      filterable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: PatientSyncersGridRow }) => (
        <Stack direction="row" justifyContent="flex-end">
          <IconButton onClick={() => setSelectedSyncer(params.row)}>
            <EditIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const patientSyncers: PatientSyncersGridRow[] = (
    credentialsData?.patientIntegrationCredentialsFindAll || []
  ).map<PatientSyncersGridRow>((item) => {
    const latestErrorLog = item?.latestErrorLogs?.slice(-1)[0]; // Get the last error log
    return {
      id: item?.id,
      institution: item?.institution?.name,
      institutionId: item?.institution?.id,
      integrationTitle: item?.title,
      username: item?.username,
      password: item?.password,
      loginUrl: item?.loginURL,
      officeKey: item?.officeKey,
      isDisabled: item?.isDisabled,
      lastRun: latestErrorLog?.createdAt
        ? parseISO(latestErrorLog.createdAt)
        : null,
      summary: latestErrorLog?.error,
      lastRunIsSuccess: latestErrorLog?.success,
    } as PatientSyncersGridRow;
  });

  return (
    <GridWrapper>
      <DataGridPro
        disableRowSelectionOnClick
        onCellClick={(params, event) => {
          const isActionCell = !!(event.target as HTMLElement).closest(
            ".MuiDataGrid-actionsCell"
          );
          const isSelectCell = !!(event.target as HTMLElement).closest(
            ".MuiDataGrid-cell--editable"
          );

          if (isActionCell || isSelectCell) {
            event.stopPropagation();
          }
        }}
        autoHeight
        columnVisibilityModel={{ patientId: false }}
        loading={credentialsLoading}
        rows={patientSyncers}
        columns={columns()}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            onNewClick: async () =>
              setSelectedSyncer({} as PatientSyncersGridRow),
            newButtonDataCy: "actionNewPatientSyncer",
            disableExport: true,
            disableSearch: true,
          },
        }}
      />
      <EditSyncerDialog
        syncer={selectedSyncer}
        onClose={() => setSelectedSyncer(null)}
      />
    </GridWrapper>
  );
};
