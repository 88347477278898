import { useQuery } from "@apollo/client";
import React, { useRef } from "react";

import {
  SearchCareMetxEnrollmentResponseByBvIdQuery,
  SearchCareMetxEnrollmentResponseByBvIdQueryVariables,
} from "@@generated/graphql";
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Tooltip,
  Typography,
} from "@samacare/design/core";
import InfoOutlinedIcon from "@samacare/design/core/icons/InfoOutlined";
import { getInsuranceType } from "../../../util/getInsuranceType";
import GetCareMetxEnrollmentResponseByBenefitsVerificationId from "../graphql/GetCareMetxEnrollmentResponseByBenefitsVerificationIdQuery.gql";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import { CareMetxMedeBvCoverageStatus } from "./CareMetxMedeBvCoverageStatus";
import { UnifiedBenefitsCheckStatus } from "./UnifiedBenefitsCheckStatus";

type CareMetxEnrollmentResponse = NonNullable<
  SearchCareMetxEnrollmentResponseByBvIdQuery["getCareMetxEnrollmentResponseByBenefitsVerificationId"]
>;

type CopayStatus = NonNullable<CareMetxEnrollmentResponse["copayStatus"]>;

const CopayDetails: React.FC<{ copayStatus: CopayStatus }> = ({
  copayStatus,
}) => {
  return (
    <Stack>
      <Typography variant="h6">{copayStatus.details?.cardnumber}</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          RxBIN:
        </Typography>
        <Typography variant="body2">{copayStatus.details?.bin}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          PCN:
        </Typography>
        <Typography variant="body2">{copayStatus.details?.pcn}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          Group:
        </Typography>
        <Typography variant="body2">{copayStatus.details?.group}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          Effective:
        </Typography>
        <Typography variant="body2">
          {copayStatus.details?.effectivedate}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          Expiry:
        </Typography>
        <Typography variant="body2">
          {copayStatus.details?.expirydate}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const CareMetxMedeBvSummary: React.FC = () => {
  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();

  const benefitsVerificationId = unifiedBenefitsCheck?.fullData?.id;

  const { data, loading } = useQuery<
    SearchCareMetxEnrollmentResponseByBvIdQuery,
    SearchCareMetxEnrollmentResponseByBvIdQueryVariables
  >(GetCareMetxEnrollmentResponseByBenefitsVerificationId, {
    variables: {
      BenefitsVerificationId: benefitsVerificationId ?? null,
    },
    skip: !benefitsVerificationId,
  });

  const { enrollmentStatus, copayStatus } =
    data?.getCareMetxEnrollmentResponseByBenefitsVerificationId || {};

  const anchorEl = useRef(null);

  if (!unifiedBenefitsCheck) {
    return <Box />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TabContext value="summary">
      <TabList>
        <Tab label="Summary" value="summary" />
      </TabList>
      <TabPanel value="summary" sx={{ paddingX: 0 }}>
        <Grid container padding={0}>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle2">
                Benefits Verification Status
              </Typography>
              <UnifiedBenefitsCheckStatus />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle2">Coverage Status</Typography>
              <CareMetxMedeBvCoverageStatus />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Payer</Typography>
              <Typography variant="body1">
                {unifiedBenefitsCheck.insuranceCompanyData?.name || "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Plan Type</Typography>
              <Typography variant="body1">
                {getInsuranceType(
                  unifiedBenefitsCheck.insurancePolicyData?.planType
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Member ID</Typography>
              <Typography variant="body1">
                {unifiedBenefitsCheck.insurancePolicyData?.memberId || "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Insurance State</Typography>
              <Typography variant="body1">
                {unifiedBenefitsCheck.insurancePolicyData?.insuranceState ||
                  "-"}
              </Typography>
            </Box>
          </Grid>
          {enrollmentStatus && (
            <Grid item xs={6}>
              <Box marginTop={2}>
                <Typography variant="subtitle2">
                  Amgen HUB Patient ID
                </Typography>
                <Typography variant="body1">
                  {enrollmentStatus.details?.hubPatientId || "-"}
                </Typography>
              </Box>
            </Grid>
          )}
          {copayStatus && (
            <Grid item xs={6}>
              <Box marginTop={2}>
                <Typography variant="subtitle2">Copay Status</Typography>
                <Stack direction="row" spacing={1}>
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {copayStatus.status}
                  </Typography>
                  <Tooltip
                    placement="bottom"
                    title={<CopayDetails copayStatus={copayStatus} />}
                    sx={{ color: "rgba(0, 0, 0, 0.4)" }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "rgba(224, 247, 250, 1)",
                          borderRadius: "4px",
                          border: "1px solid rgba(178, 235, 242, 1)",
                          color: "rgba(0, 0, 0, 1)",
                          padding: "16px",
                          width: "204px",
                        },
                      },
                    }}
                  >
                    <InfoOutlinedIcon ref={anchorEl} />
                  </Tooltip>
                </Stack>
              </Box>
            </Grid>
          )}
        </Grid>
      </TabPanel>
    </TabContext>
  );
};
