import styled from "styled-components";

/**
 * @deprecated Use a MUI component instead
 */
export const ModalBody = styled.div`
  width: 400px;
`;

/**
 * @deprecated Use a MUI component instead
 */
export const ModalBodyLarge = styled.div`
  width: 600px;
`;

/**
 * @deprecated Use a MUI component instead
 */
export const ModalBodyXL = styled.div`
  width: 800px;
`;

/**
 * @deprecated Use a MUI component instead
 */
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

/**
 * @deprecated Use a MUI component instead
 */
export const ModalContent = styled.div`
  text-align: center;
`;
