import { Authorization, Account, PortalToHubMapping } from "@samacare/graphql";

export const isDisplayPortalSpecificDetails = (
  authorization: Authorization
): boolean =>
  authorization.type ===
    window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.value &&
  !authorization.isWebExtension;

export const isDisplayWebExtensionDetails = (
  authorization: Authorization
): boolean =>
  authorization.type ===
    window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.value &&
  authorization.isWebExtension;

export const doesHubRequireMFA = (
  currentAccount: Account,
  hub?: PortalToHubMapping | null
): boolean =>
  hub != null &&
  (window.CONFIG.CONSTANTS.MFA_REQUIRING_HUB_KEYS as string[]).includes(
    hub.hubKey
  ) &&
  Boolean(currentAccount.institution?.featureFlags?.PortalMFA);

export const doesPortalRequireMFA = (
  currentAccount: Account,
  hub?: PortalToHubMapping | null
): boolean =>
  hub != null &&
  (window.CONFIG.CONSTANTS.MFA_REQUIRING_HUB_KEYS as string[]).includes(
    hub.hubKey
  ) &&
  Boolean(currentAccount.institution?.featureFlags?.PortalMFA);
