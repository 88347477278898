import {
  QuestionTypeEnumType,
  MedicationRequestDiagnosis,
  QuestionEnabledWhenBehaviourEnumType,
} from "@samacare/graphql";

import _ from "lodash";
import { useWatch, useFormContext } from "@samacare/form";
import { QuestionnaireField } from "../../components/Questionnaire";
import { useEnrollmentContext } from "../../routes/Enrollment/EnrollmentProvider";

function isMultipleSclerosis(icdCodes: MedicationRequestDiagnosis[]): boolean {
  if (!icdCodes) {
    return false;
  }
  return _.some(icdCodes, (v) => v.code === "G35");
}

export const GenentechOcrevusFormQuestions: React.FC = () => {
  const { control } = useFormContext();

  const diagnoses = useWatch({
    control,
    name: "diagnoses",
  }) as MedicationRequestDiagnosis[];

  const { isSubmitted } = useEnrollmentContext();
  const hasMultipleSclerosis = isMultipleSclerosis(diagnoses);

  return (
    <>
      <QuestionnaireField
        id="has-treatment-started"
        text="Has treatment started?"
        type={QuestionTypeEnumType.Choice}
        disabled={isSubmitted}
        required
        answerOption={[
          {
            valueString: "Yes",
          },
          {
            valueString: "No",
          },
        ]}
        enableWhen={[
          {
            answerBoolean: true,
            answerString: null,
            id: "applied-for-pf",
            operator: "=",
            question: "applied-for-pf",
          },
        ]}
      />
      {hasMultipleSclerosis && (
        <QuestionnaireField
          id="indication"
          text="Indication"
          type={QuestionTypeEnumType.Choice}
          disabled={isSubmitted}
          required={hasMultipleSclerosis}
          answerOption={[
            {
              valueString: "RMS",
            },
            {
              valueString: "PPMS",
            },
            {
              valueString: "Other",
            },
          ]}
          enableWhen={[
            {
              answerBoolean: true,
              answerString: null,
              id: "applied-for-bipa",
              operator: "=",
              question: "applied-for-bipa",
            },
            {
              answerBoolean: true,
              answerString: null,
              id: "applied-for-pf",
              operator: "=",
              question: "applied-for-bipa",
            },
            {
              answerBoolean: true,
              answerString: null,
              id: "applied-for-copay",
              operator: "=",
              question: "applied-for-copay",
            },
            {
              answerBoolean: true,
              answerString: null,
              id: "applied-for-appeals",
              operator: "=",
              question: "applied-for-appeals",
            },
          ]}
        />
      )}
      {hasMultipleSclerosis && (
        <QuestionnaireField
          id="indication-other"
          text="Indication - Other"
          type={QuestionTypeEnumType.String}
          disabled={isSubmitted}
          enableBehavior={QuestionEnabledWhenBehaviourEnumType.All}
          enableWhen={[
            {
              answerBoolean: null,
              answerString: "other",
              id: "indication",
              operator: "=",
              question: "indication",
            },
          ]}
        />
      )}
      <QuestionnaireField
        id="pa-in-place"
        text="Is prior authorization in place?"
        type={QuestionTypeEnumType.Choice}
        disabled={isSubmitted}
        required
        answerOption={[
          {
            valueString: "Yes",
          },
          {
            valueString: "No",
          },
        ]}
        enableWhen={[
          {
            answerBoolean: true,
            answerString: null,
            id: "applied-for-bipa",
            operator: "=",
            question: "applied-for-bipa",
          },
        ]}
      />
      <QuestionnaireField
        id="authorization"
        text="Authorization #"
        type={QuestionTypeEnumType.String}
        disabled={isSubmitted}
        required
        enableBehavior={QuestionEnabledWhenBehaviourEnumType.All}
        enableWhen={[
          {
            answerBoolean: true,
            answerString: null,
            id: "applied-for-bipa",
            operator: "=",
            question: "applied-for-bipa",
          },
          {
            answerBoolean: null,
            answerString: "Yes",
            id: "pa-in-place",
            operator: "=",
            question: "pa-in-place",
          },
        ]}
      />
      <QuestionnaireField
        id="dispensing-through"
        text="Dispensing Through:"
        type={QuestionTypeEnumType.Choice}
        disabled={isSubmitted}
        required
        answerOption={[
          {
            valueString: "Specialty Pharmacy",
          },
          {
            valueString: "Buy and Bill",
          },
          {
            valueString: "N/A",
          },
        ]}
        enableWhen={[
          {
            answerBoolean: true,
            answerString: null,
            id: "applied-for-bipa",
            operator: "=",
            question: "applied-for-bipa",
          },
        ]}
      />
      <QuestionnaireField
        id="treatment-coordination"
        text="Treatment Coordination?"
        type={QuestionTypeEnumType.Choice}
        disabled={isSubmitted}
        required
        answerOption={[
          {
            valueString:
              "Yes, please provide assistance locating an infusion site for patient",
          },
          {
            valueString:
              "No, please do not provide assistance locating infusion site",
          },
        ]}
        enableWhen={[
          {
            answerBoolean: true,
            answerString: null,
            id: "applied-for-bipa",
            operator: "=",
            question: "applied-for-bipa",
          },
        ]}
      />
      <QuestionnaireField
        id="ocrevus-drug-allergies"
        text="Drug Allergies"
        type={QuestionTypeEnumType.Choice}
        disabled={isSubmitted}
        required
        answerOption={[
          {
            valueString: "No Known",
          },
          {
            valueString: "Aspirin",
          },
          {
            valueString: "Penicillin",
          },
          {
            valueString: "Other",
          },
        ]}
        enableWhen={[
          {
            answerBoolean: true,
            answerString: null,
            id: "applied-for-pf",
            operator: "=",
            question: "applied-for-pf",
          },
        ]}
      />
      <QuestionnaireField
        id="ocrevus-other-allergies"
        text="Other Allergies"
        type={QuestionTypeEnumType.String}
        disabled={isSubmitted}
        required
        enableBehavior={QuestionEnabledWhenBehaviourEnumType.All}
        enableWhen={[
          {
            answerBoolean: true,
            answerString: null,
            id: "applied-for-pf",
            operator: "=",
            question: "applied-for-pf",
          },
          {
            answerBoolean: null,
            answerString: "other",
            id: "ocrevus-drug-allergies",
            operator: "=",
            question: "ocrevus-drug-allergies",
          },
        ]}
      />
    </>
  );
};
