import { useCallback } from "react";
import * as React from "react";
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from "react-number-format";
import { Input, InputProps } from "./Input";

export type NumericInputProps = React.ComponentProps<typeof NumericInput>;

/**
 * @deprecated Use component from design/forms instead
 */
export const NumericInput: React.VoidFunctionComponent<
  {
    as?: NumberFormatProps["customInput"];
    format: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    style?: React.CSSProperties;
    error?: unknown;
    cypressTag?: string;
  } & InputProps
> = React.forwardRef(
  ({ as = Input, format, onChange, error, cypressTag, ...rest }, ref) => {
    const onValueChange = useCallback(
      (target: NumberFormatValues) => {
        if (onChange != null) {
          onChange(target.value);
        }
      },
      [onChange]
    );

    return (
      <NumberFormat
        data-cy={cypressTag}
        customInput={as}
        format={format}
        getInputRef={ref}
        isNumericString
        onValueChange={onChange != null ? onValueChange : undefined}
        {...rest}
      />
    );
  }
);
