import { PureComponent } from "react";
import { connect } from "react-redux";
import { withAlert } from "react-alert";

import { InternationalPhone } from "Segment/InternationalPhone";
import { isValidE164PhoneNumber } from "../../util/phoneUtils";

export class SegmentFax extends PureComponent {
  setNumber = (number) => {
    const { handleChange, item } = this.props;
    handleChange(item && item.key ? { [item.key]: number } : number);
  };

  onBlur = () => {
    const { result, alert } = this.props;
    if (!isValidE164PhoneNumber(result)) {
      this.setNumber("");
      alert.error("Phone numbers must be in E164");
    }
  };

  render() {
    const { result, disabled, highlightIfMissing, cypressTag } = this.props;
    return (
      <InternationalPhone
        cypressTag={cypressTag}
        highlight={highlightIfMissing && !result}
        disabled={disabled}
        onChange={(e) => {
          this.setNumber(e.target.value || "");
        }}
        number={result || ""}
        onBlur={this.onBlur}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return {
    result: props.override || state.form.results[(props.item || {}).key],
  };
}

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAlert()(SegmentFax));
