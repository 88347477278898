import * as React from "react";
import styled from "styled-components";
import { Flex } from "@@ui-kit";

const LegendItem = styled(Flex)<{ color: string }>`
  align-items: center;
  color: ${(props) => props.color};
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 10px;
  margin-top: 0;
`;

const ColorBlock = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 3px;
  height: 20px;
  margin-right: 5px;
  width: 20px;
`;

export interface LegendItem {
  key: string;
  title: string;
  color: string;
}

export type LegendProps = React.ComponentProps<typeof Legend>;

export const Legend: React.VoidFunctionComponent<{
  items: LegendItem[];
}> = ({ items }) => (
  <Flex flexDirection="row">
    {items.map((item) => (
      <LegendItem key={`item_legend_${item.key}`} color={item.color}>
        <ColorBlock color={item.color} />
        {item.title}
      </LegendItem>
    ))}
  </Flex>
);
