import RefreshBenefitsCheckLiteMutationGql from "../routes/BenefitsVerifications/graphql/RefreshBenefitsCheckLite.gql";
import { useMutation } from "@apollo/client";
import { RefreshBenefitsCheckLiteMutationMutation } from "@@generated/graphql";

export const useRunEligibilityCheck = () => {
  return useMutation<RefreshBenefitsCheckLiteMutationMutation>(
    RefreshBenefitsCheckLiteMutationGql,
    {
      refetchQueries: ["SearchUnifiedBenefitsChecks"],
    }
  );
};
