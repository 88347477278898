import Typography, { TypographyProps } from "@mui/material/Typography";

function factory(
  type: React.ElementType
): React.FC<
  TypographyProps<React.ElementType, { component?: React.ElementType }>
> {
  return ({ children, ...props }) => (
    <Typography component={type} {...props}>
      {children}
    </Typography>
  );
}

export default factory;
