import { Provider } from "react-redux";
import { Global, css } from "@emotion/react";
import { ThemeProvider } from "styled-components";
import { ConnectedRouter } from "connected-react-router";
import MuiThemeProvider from "@samacare/design/core/ThemeProvider";

import { theme } from "../../theme";

import AlertProvider from "../AlertProvider";
import store, { history } from "../../reduxStore";
import { ConfigProvider } from "../../hooks/config";

import "./reset.css";
import "sanitize.css/sanitize.css";

export const RootProvider: React.FC = ({ children }) => (
  <>
    <Global
      styles={css`
        html,
        body,
        #app {
          color: #212121;
          height: 100%;
          font-family: "Lato", "Avenir", "Helvetica", sans-serif;
          margin: 0;
          padding: 0;
        }
        *::placeholder {
          color: #212121;
        }
      `}
    />
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <MuiThemeProvider>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <ConfigProvider value={window.CONFIG}>{children}</ConfigProvider>
            </ConnectedRouter>
          </Provider>
        </MuiThemeProvider>
      </AlertProvider>
    </ThemeProvider>
  </>
);
