import { PureComponent } from "react";
import styled from "styled-components";
import _ from "lodash";
import Select from "react-select";
import PropTypes from "prop-types";

import { flatInputPropType } from "./taggerPropTypes";
import { defaultOnlyFocusChildrenIfTrue } from "./taggerUtils";
import BaseButton from "../../components/BaseButton";
import TaggerDeleteButton from "./TaggerDeleteButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContainer = styled.div`
  margin: 5px 0;
`;

const MenuButton = styled(BaseButton)`
  margin-top: 10px;

  background-color: ${(props) => (props.active ? props.theme.green : "white")};
  border-color: ${(props) =>
    props.active ? props.theme.green : props.theme.purple};
  color: ${(props) => (props.active ? "white" : props.theme.purple)};
  flex: 1 0 45%;
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Arrow = styled(BaseButton)`
  width: 24px;
  margin: 8px;
`;

const ButtonsContainer = styled(SectionContainer)`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
`;

const StyledSelect = styled(Select)`
  margin: 10px 0;
`;

const SpecificationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Fill = styled.div`
  width: 15px;
  height: 15px;
  background: ${(props) =>
    props.isFilled ? props.theme.purple : "transparent"};
`;

const CheckboxContainer = styled.div`
  border: 1px solid ${(props) => props.theme.purple};
  border-radius: 3px;
  padding: 2px;
  cursor: pointer;
  margin-right: 10px;

  ${Fill} {
    border-radius: 3px;
  }
`;

const CharacterBoxes = styled.input`
  border: 1px solid ${(props) => props.theme.purple};
  width: 40px;
  margin-left: auto;
  &:disabled {
    background: ${(props) => props.theme.disabledGray};
  }
`;

const SamatypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  font-weight: 700;

  ${TaggerDeleteButton} {
    margin-left: auto;
  }
`;

const types = CONFIG.CONSTANTS.TAGGER_INPUT_TYPES;
class InputSpecification extends PureComponent {
  static propTypes = {
    addBoxActive: PropTypes.bool.isRequired,
    addChild: PropTypes.func.isRequired,
    addRootInput: PropTypes.func.isRequired,
    input: flatInputPropType,
    moveToRoot: PropTypes.func.isRequired,
    selectedBoxIndex: PropTypes.number,
    setAddBox: PropTypes.func.isRequired,
    updateInput: PropTypes.func.isRequired,
  };

  addChild = () => {
    const { input, addChild } = this.props;

    addChild(input.id, {
      id: `${Math.round(Math.random() * 10000)}`,
      type: types.TEXT.key,
    });
  };

  moveToRoot = () => {
    const { input, moveToRoot } = this.props;
    moveToRoot(input.id);
  };

  render() {
    const {
      input,
      updateInput,
      setAddBox,
      addBoxActive,
      addRootInput,
      selectedBoxIndex,
      moveAllBoxes,
    } = this.props;

    const selectedBox = _.get(input, `boxes[${selectedBoxIndex}]`);
    return (
      <Container>
        {input ? (
          <div>
            <SectionContainer data-cy="controlPdfFieldType">
              Field Types
              <StyledSelect
                value={input.type || ""}
                onChange={(option) => {
                  const onlyFocusChildrenIfTrue =
                    defaultOnlyFocusChildrenIfTrue(option.value);
                  updateInput(input.id, {
                    ...input,
                    type: option.value,
                    onlyFocusChildrenIfTrue,
                  });
                }}
                options={_.sortBy(
                  _.map(types, (option) => ({
                    value: option.key,
                    label: option.title,
                  })),
                  "label"
                )}
                placeholder="Select an input type..."
                isClearable={false}
              />
            </SectionContainer>
            <SectionContainer data-cy="controlPdfSamaType">
              Sama Types
              <StyledSelect
                value=""
                placeholder="Select a sama type..."
                onChange={(option) => {
                  const newType = option.value;

                  updateInput(input.id, {
                    ...input,
                    samaTypes: (input.samaTypes || []).concat(newType),
                  });
                }}
                options={_.map(
                  _.filter(
                    _.values(CONFIG.DEFAULT_FIELDS).concat(
                      _.values(CONFIG.CONSTANTS.CUSTOM_FIELDS)
                    ),
                    "allowInTagger"
                  ),
                  (option) => ({
                    value: option.key,
                    label: option.key.replace(/_/g, " "),
                  })
                )}
              />
              {_.map(input.samaTypes, (type, typeIndex) => (
                <SamatypeContainer
                  key={`specification_samaType_${input.id}_${typeIndex}`}
                >
                  {type}
                  <TaggerDeleteButton
                    onClick={() => {
                      updateInput(input.id, {
                        ...input,
                        samaTypes: _.without(input.samaTypes, type),
                      });
                    }}
                  >
                    x
                  </TaggerDeleteButton>
                </SamatypeContainer>
              ))}
            </SectionContainer>
            <ButtonsContainer>
              <MenuButton onClick={addRootInput}>Add root input</MenuButton>
              <MenuButton onClick={this.moveToRoot}>Move to root</MenuButton>
              <MenuButton
                active={addBoxActive}
                onClick={() => {
                  setAddBox(!addBoxActive);
                }}
              >
                {`${addBoxActive ? "Exit" : "Enter"} Multibox Mode`}
              </MenuButton>
            </ButtonsContainer>
            <SpecificationRow
              onClick={() => {
                updateInput(input.id, {
                  ...input,
                  onlyFocusChildrenIfTrue: !input.onlyFocusChildrenIfTrue,
                });
              }}
            >
              <CheckboxContainer>
                <Fill isFilled={input.onlyFocusChildrenIfTrue} />
              </CheckboxContainer>
              Only focus children if true
            </SpecificationRow>
            {selectedBox && (
              <SectionContainer>
                <SpecificationRow>
                  <CheckboxContainer
                    onClick={() => {
                      const newBoxes = _.map(input.boxes, (box, i) => {
                        if (i === selectedBoxIndex) {
                          return {
                            ...box,
                            characterBoxes: box.characterBoxes ? null : 5,
                          };
                        }
                        return box;
                      });
                      updateInput(input.id, { ...input, boxes: newBoxes });
                    }}
                  >
                    <Fill
                      isFilled={
                        selectedBox.characterBoxes !== undefined &&
                        selectedBox.characterBoxes !== null
                      }
                    />
                  </CheckboxContainer>
                  Split into character boxes
                  <CharacterBoxes
                    type="number"
                    value={selectedBox.characterBoxes || ""}
                    onChange={(e) => {
                      const newBoxes = _.map(input.boxes, (box, i) => {
                        if (i === selectedBoxIndex) {
                          return { ...box, characterBoxes: e.target.value };
                        }
                        return box;
                      });
                      updateInput(input.id, { ...input, boxes: newBoxes });
                    }}
                    disabled={
                      selectedBox.characterBoxes === undefined ||
                      selectedBox.characterBoxes === null
                    }
                  />
                </SpecificationRow>
              </SectionContainer>
            )}
          </div>
        ) : (
          <div style={{ margin: 10 }}>Select a box to configure it here.</div>
        )}
        <div style={{ margin: "8px 0" }}>Move boxes on this page:</div>
        <ArrowContainer>
          <Arrow
            onClick={() => {
              moveAllBoxes({ y: -1, x: 0 });
            }}
          >
            ↑
          </Arrow>
          <Arrow
            onClick={() => {
              moveAllBoxes({ y: 0, x: -1 });
            }}
          >
            ←
          </Arrow>
          <Arrow
            onClick={() => {
              moveAllBoxes({ y: 0, x: 1 });
            }}
          >
            →
          </Arrow>
          <Arrow
            onClick={() => {
              moveAllBoxes({ y: 1, x: 0 });
            }}
          >
            ↓
          </Arrow>
        </ArrowContainer>
      </Container>
    );
  }
}

export default InputSpecification;
