import _ from "lodash";
import moment from "moment";
import styled, { css } from "styled-components";

import BaseButton from "@@components/BaseButton";
import AuthorizationDetails from "@@components/MainList/AuthorizationDetails";
import RequestStatus from "@@components/MainList/RequestStatus";

const { DATE_FORMAT } = CONFIG.CONSTANTS;

const PatientData = styled.div`
  color: ${(props) => props.theme.purple};
  display: flex;
  flex-direction: row;
`;

export const OverflowAndEllipsis = css`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DescriptionContainer = styled.div`
  max-width: 200px;
  line-break: auto;
  white-space: normal;
`;

const InsuranceContainer = styled.div`
  color: ${(props) => props.theme.darkGray};
  ${OverflowAndEllipsis}
`;

const PatientContainer = styled.div`
  ${OverflowAndEllipsis}
`;

export const getDescription = (description, dateOfService) => {
  if (dateOfService) {
    return `[${dateOfService}] ${description}`;
  }
  // Older auths won't have a date of service
  if (description) {
    return `${description}`;
  }
  // Auths that aren't yet initiated will not have a description or date of service
  return "";
};

const getTableCells = (columns = [], authorization) => {
  const patientName = authorization.patient
    ? `${authorization.patient.lastName}, ${authorization.patient.firstName}`
    : "No patient set";
  const insuranceCompany =
    (authorization.portalTitle
      ? `[Portal] ${authorization.portalTitle}`
      : null) ||
    _.get(authorization, "insuranceCompany.name", "No associated insurance");

  return _.map(columns, (column) => {
    const columnType = _.get(column, "type") || column;
    switch (columnType) {
      case "patient":
        return (
          <PatientData key={`authPatientData-id-${authorization.id}`}>
            <PatientContainer>
              {patientName}
              <InsuranceContainer key={`insurance-id-${authorization.id}`}>
                {insuranceCompany}
              </InsuranceContainer>
            </PatientContainer>
          </PatientData>
        );
      case "stackedPatient":
        return (
          <PatientContainer>
            {patientName}
            <InsuranceContainer key={`insurance-id-${authorization.id}`}>
              {insuranceCompany}
            </InsuranceContainer>
            {getDescription(
              authorization.config[
                CONFIG.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key
              ],
              authorization.config[CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE.key]
            )}
            <div>
              {`${authorization.config[CONFIG.DEFAULT_FIELDS.HCPCS_0.key]} -` +
                ` ${
                  authorization.config[
                    CONFIG.DEFAULT_FIELDS.PRIMARY_DRUG_NAME.key
                  ]
                } `}
            </div>
          </PatientContainer>
        );
      case "description":
        return (
          <DescriptionContainer key={`description-id-${authorization.id}`}>
            {getDescription(
              authorization.config[
                CONFIG.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key
              ],
              authorization.config[CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE.key]
            )}
          </DescriptionContainer>
        );
      case "status":
        return (
          <RequestStatus
            key={`authStatusIndicator-id-${authorization.id}`}
            authorization={authorization}
            disableTooltip={true}
          />
        );
      case "disabledStatus":
        return (
          <RequestStatus
            key={`disabledAuthStatusIndicator-id-${authorization.id}`}
            authorization={authorization}
            disableTooltip={true}
            disabled
          />
        );
      case "authorizationDetails":
        return (
          <AuthorizationDetails
            key={`AuthorizationDetailsFor_${authorization.id}`}
            authorization={authorization}
          />
        );
      case "createdAt":
        return (
          <div key={`created-at-id-${authorization.id}`}>
            {moment(authorization.createdAt).format(DATE_FORMAT)}
          </div>
        );
      case "statusIndicator":
        return (
          <div key={`authStatusIndicator-id-${authorization.id}`}>
            {_.upperFirst(authorization.status.replace(/_/g, " "))}
          </div>
        );
      case "patientDOB":
        return (
          <div key={`patientDOB-${authorization.id}`}>
            {moment
              .parseZone(_.get(authorization, "patient.dob"))
              .format(DATE_FORMAT)}
          </div>
        );
      case "submissionDate":
        return (
          <div key={`submissionDate-${authorization.id}`}>
            {moment(authorization.submittedAt).format(DATE_FORMAT)}
          </div>
        );
      case "customText":
        return <div>{column.parseText(authorization)}</div>;
      case "memberId":
        return (
          <div key={`memberId-${authorization.id}`}>
            {authorization.config[CONFIG.DEFAULT_FIELDS.PATIENT_MEMBER_ID.key]}
          </div>
        );
      case "customButton":
        if (column.checkIsHidden && column.checkIsHidden(authorization)) {
          return <div />;
        }

        if (column.elOverride) {
          return column.elOverride(authorization);
        }
        return (
          <BaseButton
            key={`customButton-${authorization.id}`}
            onClick={(e) => {
              e.stopPropagation();
              column.onClick(authorization);
            }}
            {...column.customProps}
          >
            {column.getSecondaryText
              ? column.getSecondaryText(authorization.id) || column.text
              : column.text}
          </BaseButton>
        );
      default:
        throw new Error(`Unexpected column type ${column}`);
    }
  });
};

export default getTableCells;
