import _ from "lodash";

import { Stack, Typography } from "@samacare/design";
import { UnifiedBenefitsCheckStatus as UnifiedBenefitsCheckStatusType } from "@samacare/graphql";

import { getLastUpdatedLabel } from "../bvUtils";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";

const getStateLabel = (status?: UnifiedBenefitsCheckStatusType | null) => {
  if (status === UnifiedBenefitsCheckStatusType.Draft) {
    return { label: "Draft", color: "grey" };
  }
  if (
    status === UnifiedBenefitsCheckStatusType.Active ||
    status === UnifiedBenefitsCheckStatusType.Success
  ) {
    return { label: "Success", color: "green" };
  }

  if (status === UnifiedBenefitsCheckStatusType.Inactive) {
    return { label: "Completed", color: "green" };
  }

  if (status === UnifiedBenefitsCheckStatusType.Error) {
    return { label: "Error", color: "error" };
  }

  return { label: _.capitalize(status || "-"), color: "primary" };
};

export const UnifiedBenefitsCheckStatus: React.FC<{
  showlastUpdated?: boolean;
}> = ({ showlastUpdated = true }) => {
  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();

  const { label, color } = getStateLabel(unifiedBenefitsCheck?.status);
  const lastUpdated = (unifiedBenefitsCheck?.updatedAt ||
    unifiedBenefitsCheck?.createdAt) as string;
  return (
    <Stack flexDirection="row">
      <Typography variant="subtitle2" color={color}>
        {label}
      </Typography>
      {showlastUpdated && (
        <Typography variant="body1" marginLeft={1}>
          (Last updated {getLastUpdatedLabel(lastUpdated)})
        </Typography>
      )}
    </Stack>
  );
};
