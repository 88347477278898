import styled from "styled-components";

/**
 * FormContainer is a component that is designed to be used in conjunction
 * with FormControl. The purpose of this component is to evenly display
 * multiple FormControl components alongside each other for instances where
 * more than one FormControl needs to be displayed on the same row of the form.
 */
/**
 * @deprecated Use component from design/forms instead
 */
export const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
