import _ from "lodash";
// import { Authorization } from "./PatientTile/interfaces";
import { Authorization } from "@samacare/graphql";
import { AuthPatient } from "./PatientsAuthorizationList";

export const sortPatientList = (
  patientList: AuthPatient[],
  sortField: string
): AuthPatient[] =>
  _.orderBy(
    patientList,
    [
      function (patientArr: AuthPatient) {
        return (
          new Date(
            patientArr.authorizations?.[0]?.[
              sortField as keyof Authorization
            ] as string
          ).getTime() || 0
        );
      },
    ],
    ["desc"]
  );
