import _ from "lodash";
import {
  Mutation,
  MutationUpdateTrackerConfigArgs,
  TrackerConfig,
} from "@samacare/graphql";

import { useMutation } from "@apollo/client";
import UpdateTrackerConfig from "../graphql/UpdateTrackerConfigMutation.gql";

const useUpdateTrackerConfig = () => {
  const [updateTrackerConfig] = useMutation<
    { createTrackerConfig: Mutation["updateTrackerConfig"] },
    MutationUpdateTrackerConfigArgs
  >(UpdateTrackerConfig, {
    refetchQueries: ["GetTrackerConfigs"],
  });

  const create = async (trackerConfig: TrackerConfig) => {
    const result = await updateTrackerConfig({
      variables: {
        id: +trackerConfig.id,
        patch: _.omit(trackerConfig, ["id", "__typename"]),
      },
    });

    return result.data?.createTrackerConfig;
  };

  return create;
};

export { useUpdateTrackerConfig };
