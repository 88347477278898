import { BaseButton } from "@@ui-kit/BaseButton";
import colors from "Resources/colors";
import styled from "styled-components";

export const WhiteButton = styled(BaseButton)`
  color: ${colors.purple};
  background: ${colors.white};

  transition: all 45ms ease-in-out;

  &:hover {
    background: ${colors.purple};
    color: ${colors.white};
  }
`;
