export default (theme) => ({
    styleOverrides: {
        asterisk: {
            color: theme.palette.error.main,
            "&$error": {
                color: theme.palette.error.main,
            },
        },
        label: Object.assign({ color: theme.palette.text.primary }, theme.typography.body1),
    },
});
