import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";

import { withDefaultProps } from "./utils";
import patientIntegrationCredentialInfo from "./fragments/patientIntegrationCredentialInfo";

export const PATIENT_INTEGRATION_FIND_ALL_QUERY_NAME =
  "PatientIntegrationCredentialsFindAll";

export const patientIntegrationCredentialsQuery = gql`
  query PatientIntegrationCredentialsFindAll {
    patientIntegrationCredentialsFindAll {
      ...patientIntegrationCredentialInfo
    }
  }
  ${patientIntegrationCredentialInfo}
`;

export const withPatientIntegrationCredentials = graphql(
  patientIntegrationCredentialsQuery,
  {
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      patientIntegrationCredentials: _.get(
        data,
        "patientIntegrationCredentialsFindAll"
      ),
    })),
    options: { fetchPolicy: "network-only" },
  }
);

export const patientIntegrationTitlesQuery = gql`
  query patientIntegrationTitlesFindAll {
    patientIntegrationTitlesFindAll
  }
`;

export const withPatientIntegrationTitles = graphql(
  patientIntegrationTitlesQuery,
  {
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      patientIntegrationTitles: _.get(data, "patientIntegrationTitlesFindAll"),
    })),
  }
);

export const upsertPatientIntegrationCredentialMutation = gql`
  mutation upsertPatientIntegrationCredential(
    $id: Int
    $institutionId: Int!
    $patch: UpsertPatientIntegrationCredentialMutationPatch!
  ) {
    upsertPatientIntegrationCredential(
      id: $id
      institutionId: $institutionId
      patch: $patch
    ) {
      ...patientIntegrationCredentialInfo
    }
  }
  ${patientIntegrationCredentialInfo}
`;

export const withUpsertPatientIntegrationCredential = graphql(
  upsertPatientIntegrationCredentialMutation,
  {
    name: "upsertPatientIntegrationCredential",
    options: {
      refetchQueries: () => [PATIENT_INTEGRATION_FIND_ALL_QUERY_NAME],
    },
  }
);
