import _ from "lodash";
import { PureComponent } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import styled from "styled-components";
import { withAlert } from "react-alert";
import { BaseText } from "Segment/StyledComponents";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";

import CorrespondenceViewer from "../../components/CorrespondenceViewer";
import Upload from "../../components/Upload";
import BaseButton from "../../components/BaseButton";
import { withAccountsQuery } from "../../graphql/Account";
import { withFileByAWSKey } from "../../graphql/File";
import { withSendSecureFile } from "../../graphql/SendSecureFile";
import { withGetInstitutionsByIds } from "../../graphql/Institution";

const TwoPaneContainer = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const SendDetailsContainer = styled.div`
  padding: 10px;
  width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${BaseText} {
    margin: 10px;
  }
`;

const Scrollable = styled.div`
  margin: 8px 0;
  overflow: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: ${(props) => props.selected && props.theme.lightPurple};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.lightPurple};
  }
`;
const correspondenceViewerStyles = { width: "500px", height: "600px" };
const DarkGray = styled.div`
  color: ${(props) => props.theme.darkGray};
`;

const Seperator = styled.div`
  margin: 10px 0;
`;

const InputLabel = styled.div`
  text-align: center;
  width: 100%;
  font-weight: 700;
  margin: 10px 0;
`;

const correspondenceQuery = gql`
  query withCorrespondenceFullText($id: Int!) {
    correspondenceById(id: $id) {
      fileURL
      mimeType
      fileAWSKey
      InstitutionId
    }
  }
`;

const { PRESCRIBER_TYPES, DEFAULT_USER } = CONFIG.CONSTANTS;
export const filterUsers = ({ type, isExpired, firstName, lastName, email }) =>
  type === PRESCRIBER_TYPES.PRESCRIBER ||
  (firstName === DEFAULT_USER.firstName &&
    lastName === DEFAULT_USER.lastName) ||
  !email ||
  isExpired;

const baseState = {
  sendTo: "",
  droppedFile: {},
  accounts: {},
  institution: { id: "", name: "" },
  sending: false,
  subject: "",
  message: "",
};

class SendSecureFile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...baseState,
    };
  }

  fetchInstitutionName = async (institutionId) => {
    const { client, alert } = this.props;

    try {
      const options = await client.query({
        query: withGetInstitutionsByIds,
        variables: { institutionIds: [institutionId] },
        fetchPolicy: "no-cache",
      });
      const data = options.data.getInstitutionsByIds;

      if (!_.isEmpty(data)) {
        return data[0].name;
      }
      return "";
    } catch {
      alert.error("There was an error fetching institution name");
      return "";
    }
  };

  fetchAccountInfo = async (institutionId) => {
    const { client, alert } = this.props;

    try {
      const options = await client.query({
        query: withAccountsQuery,
        variables: { institutionId },
        fetchPolicy: "no-cache",
      });

      return options.data.accountsFindAll;
    } catch {
      alert.error("There was an error fetching account info");
      return [];
    }
  };

  onDropSuccess = (__, res) => {
    const { client } = this.props;
    client
      .query({
        query: withFileByAWSKey,
        variables: { awsKey: res.data.fields.key },
      })
      .then(({ data }) => {
        this.setState({
          ...baseState,
          droppedFile: {
            fileAWSKey: res.data.fields.key,
            fileURL: data.fileByAWSKey,
            mimeType: res.data["content-type"],
          },
        });
      })
      .catch(this.uploadError);
  };

  uploadError = (e) => {
    const { alert } = this.props;
    alert.error(`There was an error uploading the document. ${e}`);
  };

  send = async (awsKey) => {
    const { alert, sendSecureFile } = this.props;
    const { sendTo, subject, message } = this.state;

    try {
      this.setState({ sending: true });
      await sendSecureFile({
        variables: { sendTo, awsKey, message, subject },
      });
      this.setState({ ...baseState });
      alert.success("Success");
    } catch (e) {
      alert.error(`Error ${e}`);
      this.setState({ sending: false });
    }
  };

  renderInnerContents = (
    file = this.state.droppedFile,
    correspondenceId = ""
  ) => {
    const hasFile = !_.isEmpty(file);
    const { sendTo, sending, subject, message, accounts, institution } =
      this.state;
    const users = _.sortBy(_.reject(accounts, filterUsers), "lastName");

    return (
      <TwoPaneContainer>
        {!hasFile ? (
          <div style={correspondenceViewerStyles} />
        ) : (
          <CorrespondenceViewer
            {...correspondenceViewerStyles}
            correspondence={file}
          />
        )}
        <SendDetailsContainer>
          {!hasFile && (
            <Upload
              onDrop={this.onDrop}
              onDropSuccess={this.onDropSuccess}
              onDropError={this.uploadError}
            />
          )}
          {hasFile && (
            <InputLabel>
              READY TO SEND YOUR DELIGHTFUL FILE (only one upload a time)
            </InputLabel>
          )}
          {correspondenceId && (
            <>
              {!_.isEmpty(institution) && institution.name && (
                <InputLabel>{`Be sure you're sending to a "${institution.name}" user`}</InputLabel>
              )}
              {!_.isEmpty(users) && (
                <Scrollable style={{ height: "200px" }}>
                  {_.map(users, ({ id, firstName, lastName, email }) => (
                    <ItemContainer
                      selected={sendTo === email}
                      onClick={() => {
                        this.setState({ sendTo: email });
                      }}
                      key={`user-${id}`}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >{`${firstName} ${lastName}`}</div>
                        <DarkGray>{email}</DarkGray>
                      </div>
                    </ItemContainer>
                  ))}
                </Scrollable>
              )}
            </>
          )}
          <Seperator>
            {!sending && (
              <div>
                <BaseText
                  placeholder="Email"
                  onChange={(e) => {
                    this.setState({ sendTo: e.target.value });
                  }}
                  value={sendTo}
                />
                <BaseText
                  placeholder="Subject"
                  onChange={(e) => {
                    this.setState({ subject: e.target.value });
                  }}
                  value={subject}
                />
                <BaseText
                  placeholder="Message"
                  onChange={(e) => {
                    this.setState({ message: e.target.value });
                  }}
                  value={message}
                />
              </div>
            )}
          </Seperator>
          <div style={{ display: "flex" }}>
            <BaseButton
              style={{ width: "200px" }}
              color="red"
              disabled={!hasFile}
              onClick={() => {
                this.setState(baseState);
              }}
            >
              Clear File
            </BaseButton>
            <BaseButton
              style={{ width: "200px", marginLeft: "10px" }}
              disabled={!(hasFile && sendTo)}
              onClick={() => this.send(file.fileAWSKey)}
            >
              Send Message
            </BaseButton>
          </div>
        </SendDetailsContainer>
      </TwoPaneContainer>
    );
  };

  render() {
    const params = new URLSearchParams(window.location.href);
    let correspondenceId = params.get("correspondenceId");

    return (
      <div>
        {correspondenceId && (
          <Query
            query={correspondenceQuery}
            variables={{ id: correspondenceId }}
            onCompleted={async (data) => {
              const { InstitutionId } = data.correspondenceById;
              const name = await this.fetchInstitutionName(InstitutionId);
              const accounts = await this.fetchAccountInfo(InstitutionId);
              this.setState({
                institution: { id: InstitutionId, name },
                accounts,
              });
            }}
          >
            {({ data, error, loading }) => {
              if (loading) {
                return <div />;
              }

              if (error) {
                correspondenceId = null;
              }

              const { mimeType, fileURL, fileAWSKey } = data.correspondenceById;

              const fileInput = {
                fileURL,
                mimeType,
                fileAWSKey,
              };

              return this.renderInnerContents(fileInput, correspondenceId);
            }}
          </Query>
        )}
        {!correspondenceId && this.renderInnerContents()}
      </div>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withSendSecureFile)(
  withAlert()(withApollo(SendSecureFile))
);
