import {
  Authorization,
  Enrollment,
  MutationUpdateEnrollmentByIdArgs,
} from "@samacare/graphql";
import {
  GetEnrollmentDetailsQuery,
  GetEnrollmentDetailsQueryVariables,
} from "@@generated/graphql";
import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  useMutation,
  useQuery,
} from "@apollo/client";
import Maybe from "graphql/tsutils/Maybe";

import { cleanDrugName } from "../../../../../server/src/shared_code";
import enrollmentInfo from "../../../graphql/fragments/enrollmentInfo";

export const GET_ENROLLMENT_DETAILS_GQL = gql`
  query GetEnrollmentDetails($authorizationId: Int!) {
    getEnrollmentDetails(authorizationId: $authorizationId) {
      success
      moduleHtml
      enrollment {
        ...enrollmentInfo
      }
    }
  }
  ${enrollmentInfo}
`;

export const useEnrollmentDetails = (
  authorizationId: string
): {
  isEnrollmentFailed: boolean;
  isEnrollmentDetailsLoading: boolean;
  fetchedModuleHtml: string | null;
  enrollment: Enrollment | null;
} => {
  const { data, loading } = useQuery<
    GetEnrollmentDetailsQuery,
    GetEnrollmentDetailsQueryVariables
  >(GET_ENROLLMENT_DETAILS_GQL, {
    variables: {
      authorizationId: Number(authorizationId),
    },
    fetchPolicy: "network-only",
  });

  return {
    isEnrollmentFailed: data ? !data.getEnrollmentDetails.success : false,
    isEnrollmentDetailsLoading: loading,
    fetchedModuleHtml: data?.getEnrollmentDetails.moduleHtml ?? null,
    enrollment: data
      ? (data.getEnrollmentDetails.enrollment as Enrollment)
      : null,
  };
};

const UPDATE_AUTH_ENROLLMENT_GQL = gql`
  mutation UpdateEnrollmentById($id: Int!, $patch: EnrollmentUpdatePatchArgs!) {
    updateEnrollmentById(id: $id, patch: $patch) {
      ...enrollmentInfo
    }
  }
  ${enrollmentInfo}
`;

export const useUpdateEnrollmentById = (): ((
  options?: MutationFunctionOptions<
    Maybe<Enrollment>,
    MutationUpdateEnrollmentByIdArgs
  >
) => Promise<FetchResult<Maybe<Enrollment>>>) => {
  const mutation = useMutation<
    Maybe<Enrollment>,
    MutationUpdateEnrollmentByIdArgs
  >(UPDATE_AUTH_ENROLLMENT_GQL);
  const [updateEnrollmentById] = mutation;

  return updateEnrollmentById;
};

const getDrugKey = (s?: string): string | null => {
  const cleaned = cleanDrugName(s);
  if (cleaned == null) {
    return null;
  }
  return cleaned.toLowerCase();
};

export const useEnrollmentProgramName = (drugName?: string): string => {
  const key = getDrugKey(drugName);
  switch (key) {
    case "eylea":
      return "Eylea4U";
    case "syfovre":
      return "SYFOVRE's patient access program";
    default:
      return "support programs";
  }
};

export const useCanSkipEnrollment = (authorization: Authorization): boolean => {
  const authConfig = authorization.config as { PRIMARY_DRUG_NAME: string };
  const { PRIMARY_DRUG_NAME: drugName } = authConfig;
  const key = getDrugKey(drugName);
  switch (key) {
    case "eylea":
      return false;
    case "syfovre":
      return true;
    case "pavblu":
      return true;
    default:
      return false;
  }
};
