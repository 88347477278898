import axios from "axios";
import { PureComponent } from "react";
import { compose } from "recompose";
import _ from "lodash";
import styled from "styled-components";
import { withAlert } from "react-alert";
import saveAs from "file-saver";
import JSZip from "jszip";
import Select from "react-select";

import client from "../../services/client";
import AlertModal from "../../components/AlertModal";
import { withUpsertForm } from "../../graphql/AuthorizationForm";
import BaseButton from "../../components/BaseButton";
import getFileByExtension from "../../util/getFileByExtension";
import {
  removeInvalidRadioGroups,
  setTabIndexOnBoxes,
  trimBoxesByMaxPageLength,
  refineAnnotations,
} from "./taggerUtils";
import ImportConfigButton from "./ImportConfigButton";
import ExportConfigButton from "./ExportConfigButton";
import { MdArrowBack as ArrowBackIcon } from "@react-icons/all-files/md/MdArrowBack";
import { MdArrowForward as ArrowForwardIcon } from "@react-icons/all-files/md/MdArrowForward";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled(FlexContainer)`
  padding: 5px 0;
`;

const SelectContainer = styled.div`
  margin-left: auto;
`;

const PageController = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImportInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

const RelativeButton = styled(BaseButton)`
  position: relative;
  margin-right: 10px;
`;

const ToggleButton = styled(BaseButton)`
  margin: 0 auto;
  background-color: ${(props) =>
    props.selected ? props.theme.purple : "white"};
  color: ${(props) => (props.selected ? "white" : props.theme.purple)};
`;

const PageCountLabel = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #e8e8e8;
  border-radius: 3px;
`;

class TaggerHeader extends PureComponent {
  state = {
    annotations: null,
    pageKeys: null,
    testPDF: null,
    pdfKey: null,
    importedRemoteFormId: null,
  };

  newPDF = null;

  currentPDF = null;

  getFormMetadata = () => {
    const { imageContainer } = this.props;
    const { pageKeys } = this.state;

    return {
      NODE_ENV: CONFIG.NODE_ENV,
      pageKeys,
      taggedWidth: imageContainer.getBoundingClientRect().width,
      taggedHeight: imageContainer.getBoundingClientRect().height,
    };
  };

  resetState = () => {
    const { setFormId } = this.props;
    this.setState(
      {
        annotations: null,
        pageKeys: null,
        testPDF: null,
        pdfKey: null,
        importedRemoteFormId: null,
      },
      () => {
        setFormId(null);
      }
    );
  };

  export = () => {
    const { inputs } = this.props;
    const zip = new JSZip();

    zip.file("output.pdf", this.currentPDF);
    zip.file(
      "output.json",
      JSON.stringify(setTabIndexOnBoxes(removeInvalidRadioGroups(inputs)))
    );
    zip.file("output_metadata.json", JSON.stringify(this.getFormMetadata()));
    zip
      .generateAsync({ type: "blob" })
      .then((blob) => saveAs(blob, "tagged.zip"));
  };

  upload = (pdfFile) => {
    const { alert, setLoading, newUpload } = this.props;

    setLoading(true);

    let file = pdfFile;
    if (!file) {
      const input = this.newPDF;
      file = getFileByExtension(input.files, "pdf");
    }

    const pdf = new FormData();

    pdf.append("file", file);

    return client
      .post("/taggerUpload", pdf)
      .then((res) => {
        newUpload(res.data.pages);
        this.currentPDF = file;
        this.resetState();
        const { data } = res;

        this.setState({
          // set annotations to null rather than empty annotations object
          annotations: data.annotations || null,
          pageKeys: data.pageKeys,
          testPDF: null,
          pdfKey: data.pdfKey,
        });

        this.newPDF.value = "";
      })
      .catch((err) => {
        alert.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  importConfig = () => {
    const { setNewInputs, alert } = this.props;
    const input = this.imported;

    if (input.files.length < 3) {
      return alert.error(
        "Ensure you're uploading all three exported files associated with a PDF configuration"
      );
    }

    const config = _.find(input.files, (file) => file.name === "output.json");
    this.currentPDF = getFileByExtension(input.files, "pdf");
    this.resetState();

    return this.upload(this.currentPDF).then(() => {
      const formConfigReader = new FileReader();
      formConfigReader.onload = (event) => {
        const { pageKeys } = this.state;
        const inputs = JSON.parse(event.target.result);
        const maxPages = (pageKeys && pageKeys.length) || 0;
        // trimBoxesByMaxPageLength is used because users will sometimes use the output.json of
        // a prior tagged form (large amt of overlap) on a new PDF.
        // The pain point this solves is that users will have to delete excess boxes if the prior output.json
        // had an excess amt of pages relative to the new pdf, and therefore excess boxes tagged
        setNewInputs(trimBoxesByMaxPageLength(inputs, maxPages));
      };
      formConfigReader.readAsText(config);
    });
  };

  importRemoteConfig = (id, pdfURL, specification, imgKeys) => {
    const { setNewInputs, setNewImage, alert, setLoading, setFormId } =
      this.props;
    setLoading(true);
    this.resetState();
    // Set new specs
    setNewInputs(specification);
    // Set keys
    this.setState({ pageKeys: imgKeys, importedRemoteFormId: id }, () => {
      setFormId(id);
    });
    client
      .post("/imageUrls", { pageKeys: imgKeys })
      .then((res) => {
        setNewImage(res.data.pages);
        setLoading(false);
      })
      .catch(() => {
        alert.error("There was an error generating image URLs");
      });

    axios
      .get(pdfURL, { responseType: "blob" })
      .then((res) => {
        const file = new File([res.data], "output.pdf", {
          type: "application/pdf",
        });
        this.currentPDF = file;
      })
      .catch(() => {
        alert.error("There was an error setting the PDF");
      });
  };

  upsertForm = async (id, { title = "", description = "" } = {}) => {
    const { upsertForm, inputs, setFormId, alert } = this.props;
    const { pdfKey, pageKeys } = this.state;
    const cleanedInputs = removeInvalidRadioGroups(inputs);
    try {
      if (id) {
        if (pdfKey) {
          await upsertForm({
            variables: {
              formId: id,
              pdfKey,
              imgKeys: pageKeys,
              specification: cleanedInputs,
              metadata: this.getFormMetadata(),
            },
          });
        } else {
          await upsertForm({
            variables: {
              formId: id,
              specification: cleanedInputs,
              metadata: this.getFormMetadata(),
            },
          });
        }
        setFormId(id);
        this.setState({ importedRemoteFormId: id });
      } else {
        const { data } = await upsertForm({
          variables: {
            formId: id,
            pdfKey,
            imgKeys: pageKeys,
            specification: cleanedInputs,
            metadata: this.getFormMetadata(),
            title,
            description,
          },
        });
        this.setState({ importedRemoteFormId: data.upsertForm.id }, () => {
          setFormId(data.upsertForm.id);
        });
      }
      alert.success("Changes saved!");
    } catch {
      alert.error("Error upserting form");
    }
  };

  render() {
    const {
      pageLength,
      currentPage,
      step,
      displayByOptions,
      displayBy,
      setDisplayBy,
      toggleTestView,
      isTestView,
      togglePayerNumberFilterEdit,
      numberPayerFilterEdit,
      formId,
    } = this.props;
    const { testPDF, importedRemoteFormId } = this.state;

    return (
      <Container>
        {!isTestView && (
          <FlexContainer>
            <RelativeButton>
              <ImportInput
                data-cy="actionImportPdf"
                type="file"
                accept="application/pdf"
                ref={(ref) => (this.newPDF = ref)}
                onChange={() => {
                  this.upload();
                }}
              />
              Import a PDF
            </RelativeButton>
            <RelativeButton
              disabled={!this.state.annotations}
              onClick={() => {
                this.props.setNewInputs({
                  ...refineAnnotations(
                    this.state.annotations,
                    this.props.imageContainer.getBoundingClientRect().height
                  ),
                });
                this.setState({
                  annotations: null,
                });
                this.props.alert.info("Autotagged Form");
              }}
            >
              Autotag Form
            </RelativeButton>

            <ImportConfigButton
              importLocalConfig={(importedFilesRef) => {
                this.imported = importedFilesRef;
                this.importConfig();
              }}
              importRemoteConfig={this.importRemoteConfig}
            />
            <ExportConfigButton
              exportLocally={this.export}
              upsertForm={this.upsertForm}
              importedRemoteFormId={importedRemoteFormId}
            />
            <RelativeButton
              onClick={() => {
                this.upsertForm(importedRemoteFormId);
              }}
              disabled={!formId}
            >
              Save
            </RelativeButton>

            <PageController>
              <BaseButton
                disabled={pageLength === 0 || currentPage === 0}
                onClick={() => {
                  step();
                }}
              >
                <ArrowBackIcon />
              </BaseButton>
              <PageCountLabel>
                {pageLength > 0
                  ? `${currentPage + 1} / ${pageLength}`
                  : "0 / 0"}
              </PageCountLabel>
              <BaseButton
                disabled={pageLength === 0 || currentPage === pageLength - 1}
                onClick={() => {
                  step(true);
                }}
              >
                <ArrowForwardIcon />
              </BaseButton>
            </PageController>
          </FlexContainer>
        )}

        <ToggleButton
          data-cy="controlRenderFakeData"
          disabled={pageLength === 0}
          selected={isTestView}
          onClick={() => {
            toggleTestView(!isTestView);
          }}
        >
          Render Fake Data
        </ToggleButton>

        {pageLength > 0 && !isTestView && formId && (
          <ToggleButton
            selected={numberPayerFilterEdit}
            onClick={togglePayerNumberFilterEdit}
          >
            Modify Payers Numbers Or Filters
          </ToggleButton>
        )}
        <SelectContainer>
          Displaying:
          <StyledSelect
            value={displayBy}
            onChange={setDisplayBy}
            options={displayByOptions}
            isClearable={false}
            isSearchable={false}
          />
        </SelectContainer>
        <AlertModal
          buttons={
            <BaseButton
              style={{ width: "100%" }}
              onClick={() => {
                this.setState({ testPDF: null });
              }}
            >
              Close
            </BaseButton>
          }
          content={
            <div>
              <a target="_blank" rel="noopener noreferrer" href={testPDF}>
                Download test PDF
              </a>
            </div>
          }
          header="Download Test PDF"
          open={!!testPDF}
          closeModal={() => {
            this.setState({ testPDF: null });
          }}
        />
      </Container>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withUpsertForm)(withAlert()(TaggerHeader));
