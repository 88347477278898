import moment from "moment";
import { ColumnErrorCode } from "./ColumnErrorCode";
import {
  PatientColumn,
  patientColumnDetails,
  requiredPatientColumns,
} from "./PatientColumns";
import { NOT_IN_FILE } from "./UploadConstants";
import { isValidPhoneNumber } from "../../../util/phoneUtils";
import { isValidZipCode } from "../../../util/zipUtils";

// One to one mapping only, need to unmap anything already using this field
export const resetAllMatches = (
  fromByToMappings: Map<string, string>,
  headerName: string
) => {
  fromByToMappings.forEach((fromValue, toValue) => {
    if (fromValue === headerName) {
      fromByToMappings.set(toValue, NOT_IN_FILE);
    }
  });
};

export const generateAutoMappings = (fileColumnNames: string[]) => {
  const autoFromByToMappings = new Map<string, string>();
  for (const toField of Object.keys(patientColumnDetails)) {
    const { searchTextList } = patientColumnDetails[toField as PatientColumn];

    const found = fileColumnNames.find((ff) =>
      searchTextList.some((searchText) => ff.toLowerCase().includes(searchText))
    );

    if (found) {
      resetAllMatches(autoFromByToMappings, found);
      autoFromByToMappings.set(toField, found);
    } else {
      autoFromByToMappings.set(toField, NOT_IN_FILE);
    }
  }
  return autoFromByToMappings;
};

const getRowColumnValue = (
  rows: any[],
  mappings: Map<string, string>,
  field: string,
  i: number
) => {
  const firstRow = rows[i] as Record<string, string>;
  return firstRow[mappings.get(field) ?? ""];
};

export const generateErrorMappings = (
  fromByToMappings: Map<string, string>,
  rows: any[]
) => {
  const newErrorMappings = new Map();
  for (const field of [...fromByToMappings.keys()]) {
    const isRequired = requiredPatientColumns.includes(field as PatientColumn);
    if (fromByToMappings.get(field) === NOT_IN_FILE && isRequired) {
      newErrorMappings.set(field, ColumnErrorCode.Required);
      continue;
    }

    const value = getRowColumnValue(rows, fromByToMappings, field, 0)?.trim();
    if (!value) {
      if (isRequired) {
        newErrorMappings.set(field, ColumnErrorCode.MissingData);
      }
      continue;
    }

    switch (field) {
      case PatientColumn.DOB.toString(): {
        const momentObj = moment(new Date(value));
        if (!momentObj.isValid()) {
          newErrorMappings.set(field, ColumnErrorCode.InvalidDate);
        }
        break;
      }
      case PatientColumn.State.toString(): {
        if (!/^[A-Z]{2}$/.test(value.toUpperCase())) {
          newErrorMappings.set(field, ColumnErrorCode.InvalidState);
        }
        break;
      }
      case PatientColumn.Phone.toString(): {
        if (!isValidPhoneNumber(value)) {
          newErrorMappings.set(field, ColumnErrorCode.InvalidPhone);
        }
        break;
      }
      case PatientColumn.Zip.toString(): {
        if (!isValidZipCode(value)) {
          newErrorMappings.set(field, ColumnErrorCode.InvalidZip);
        }
        break;
      }
      case PatientColumn.FirstName.toString():
      case PatientColumn.LastName.toString(): {
        if (!/^[^0-9]+$/.test(value)) {
          newErrorMappings.set(field, ColumnErrorCode.InvalidName);
        }
        break;
      }
      case PatientColumn.Sex.toString(): {
        if (!/^(male|m|female|f|other|unknown|u)$/.test(value.toLowerCase())) {
          newErrorMappings.set(field, ColumnErrorCode.InvalidSex);
        }
        break;
      }
      case PatientColumn.MRN.toString(): {
        const secondMrnValue =
          rows.length > 1 &&
          getRowColumnValue(rows, fromByToMappings, field, 1);
        if (secondMrnValue && value === secondMrnValue) {
          newErrorMappings.set(field, ColumnErrorCode.DuplicateMrn);
        }
        break;
      }
    }
  }
  return newErrorMappings;
};
