import * as React from "react";
import { Typography, Button, Box } from "@samacare/design/core";
import { useTheme } from "styled-components";

import { DialogModal } from "@@components/DialogModal";
import { ChromeWebStoreImage } from "@@components/ChromeWebStoreImage";
import { DialogCopilotButton } from "@@components/DialogCopilotButton";
import { PurpleContainerForModal } from "./PurpleContainerForModal";
import { LearnMoreText } from "./LearnMoreText";

export const DownloadExtensionDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  webExtensionUrl: string;
}> = ({ open, onClose, webExtensionUrl }) => {
  const theme = useTheme();

  const handleWebExtensionClick = () => {
    window.open(webExtensionUrl, "_blank", "noopener,noreferrer");
  };

  const content = (
    <Box>
      <Box
        sx={{
          border: `2px solid ${theme.orange}`,
          borderRadius: "8px",
          padding: 2.5,
          textAlign: "center",
          position: "relative",
          maxWidth: "400px",
          margin: "0 auto",
        }}
      >
        <Typography gutterBottom align="center">
          Works in <b>All Portals</b>. Download today.
        </Typography>
        <PurpleContainerForModal />
        <Typography align="center" sx={{ marginTop: 2.5 }}>
          <ChromeWebStoreImage />
        </Typography>
      </Box>
      <LearnMoreText />
    </Box>
  );

  const actions = (
    <>
      <Button
        onClick={onClose}
        sx={{ color: theme.orange, textDecoration: "underline" }}
      >
        Close
      </Button>
      <DialogCopilotButton
        text="Download CoPilot"
        onClick={handleWebExtensionClick}
      />
    </>
  );

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      title="Download CoPilot to Capture Portal PAs in SamaCare"
      content={content}
      actions={actions}
      isBackgroundYellow
    />
  );
};
