import styled from "styled-components";

const Required = styled.span`
  color: ${(props) => props.theme.red};
  font-weight: 700;
  font-size: ${(props) => props.fontSize ?? "30px"};
  line-height: 0px;

  span {
    vertical-align: sub;
  }
`;

const RequiredTag = ({ fontSize } = {}) => (
  <Required fontSize={fontSize}>
    <span>*</span>
  </Required>
);

/**
 * @deprecated Use a MUI component instead
 */
export default RequiredTag;
