import React, { useState } from "react";
import ConfirmationModal from "../SelectFormModal/ConfirmationModal";
import FindingFormModal from "../SelectFormModal/FindingFormModal";
import ReportMissingFormModal from "../SelectFormModal/ReportMissingFormModal";

interface MissingFormModalProps {
  showMemo: boolean;
  authorizationId: string;
  insuranceCompanyId?: string | undefined;
  setShowMemo: (showMemo: boolean) => void;
  setFormId: (formId?: number | null) => void;
  state?: string | undefined;
  isMultiPartyAuth?: boolean;
}

const MissingFormModal = ({
  setShowMemo,
  setFormId,
  showMemo,
  isMultiPartyAuth,
  authorizationId,
  insuranceCompanyId,
  state,
}: MissingFormModalProps) => {
  const [requestNewForm, setRequestNewForm] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  if (showConfirmation) {
    return (
      <ConfirmationModal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      />
    );
  }

  if (showMemo) {
    return (
      <FindingFormModal
        onClose={() => setShowMemo(false)}
        onFinish={() => {
          setShowMemo(false);
          setFormId(null);
          setRequestNewForm(true);
        }}
        open={showMemo}
      />
    );
  }
  if (requestNewForm) {
    return (
      <ReportMissingFormModal
        onClose={() => setRequestNewForm(false)}
        open={requestNewForm}
        authorizationId={authorizationId}
        insuranceCompanyId={insuranceCompanyId}
        state={state}
        authType={
          isMultiPartyAuth
            ? window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key
            : null
        }
        onFinish={() => {
          setRequestNewForm(false);
          setShowConfirmation(true);
        }}
      />
    );
  }

  return null;
};

export default MissingFormModal;
