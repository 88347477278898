import moment from "moment";

import { LoadingButton, Typography, Stack } from "@samacare/design/core";
import CheckCircleIcon from "@samacare/design/core/icons/CheckCircle";
import CancelIcon from "@samacare/design/core/icons/CancelIcon";
import { useTheme } from "@samacare/design/core/styles";
import OpenInNewIcon from "@samacare/design/core/icons/OpenInNew";
import {
  Patient,
  InsuranceCoverageCheckType,
  Account,
} from "@samacare/graphql";
import ROUTE_PATHS from "../../routes/ROUTE_PATHS";
import { segmentTypewriter } from "../../../analytics/analytics";
import { getSegmentEnumInsuranceTypeFromSamacareInsuranceType } from "../../../analytics/util";
import { ViewSource } from "../../../analytics/generated/segment";

const getInsuranceStatus = ({
  latestCoverageCheck,
  theme,
}: {
  latestCoverageCheck: InsuranceCoverageCheckType;
  theme: any;
}): {
  message: string | null;
  icon: JSX.Element | null;
} => {
  if (!latestCoverageCheck.isActive) {
    return {
      message: "Inactive",
      icon: <CancelIcon sx={{ color: theme.palette.error.light }} />,
    };
  }
  return {
    message: "Active insurance",
    icon: <CheckCircleIcon sx={{ color: theme.palette.success.light }} />,
  };
};

export const StatusDetails = ({
  eligibilityCheckError,
  latestCoverageCheck,
  currentAccount,
  patient,
}: {
  eligibilityCheckError: boolean;
  latestCoverageCheck: InsuranceCoverageCheckType;
  currentAccount: Account | null;
  patient: Patient | null;
}) => {
  const theme = useTheme();
  return (
    <>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography
          variant="body2"
          sx={{
            display: "inline",
          }}
        >
          <strong>Coverage Status:</strong>
        </Typography>
        {getInsuranceStatus({ latestCoverageCheck, theme }).icon}
        <Typography
          variant="body2"
          sx={{
            display: "inline",
          }}
        >
          {getInsuranceStatus({ latestCoverageCheck, theme }).message}
        </Typography>
      </Stack>

      {!eligibilityCheckError && (
        <Stack spacing={2} direction="row" alignItems="center">
          <LoadingButton
            size="small"
            onClick={() => {
              segmentTypewriter.viewBvLite({
                accountId: +currentAccount!.id,
                email: currentAccount!.email!,
                isAdmin: currentAccount!.isAdmin,
                institutionIsTop: currentAccount!.institution!.isTop,
                institutionName: currentAccount!.institution!.name,
                institutionSpecialty:
                  currentAccount!.institution!.practicingSpecialty ?? undefined,
                insuranceName:
                  patient?.primaryInsurance?.insuranceCompany?.name ?? "",
                insuranceState: patient?.primaryInsurance?.insuranceState ?? "",
                insuranceType:
                  getSegmentEnumInsuranceTypeFromSamacareInsuranceType(
                    patient?.primaryInsurance?.planType ?? undefined
                  ),
                viewSource: ViewSource.Pa,
              });
              window.open(
                `${
                  window.location.origin
                }/#${`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${latestCoverageCheck.id}`}`,
                "_blank"
              );
            }}
            endIcon={<OpenInNewIcon />}
            sx={{ maxWidth: "fit-content", padding: 0 }}
          >
            <u>View Financial Details</u>
          </LoadingButton>

          <Typography variant="body2" sx={{ display: "inline" }}>
            Last checked on{" "}
            {moment(latestCoverageCheck.checkedAt).format("YYYY-MM-DD HH:mm")}
          </Typography>
        </Stack>
      )}
    </>
  );
};
