import React, { useContext, useMemo } from "react";
import { Authorization } from "@samacare/graphql";
import VisibilityIcon from "@samacare/design/core/icons/Visibility";
import { Box, Button, Link } from "@samacare/design/core";
import { WebExtensionContext } from "../contexts/webExtension";
import Tooltip from "@samacare/design/core/Tooltip";
import { segmentTypewriter } from "../../analytics/analytics";
import {
  parseAsUnknownRecordWithDefault,
  parseHcpcsValuesFromRecord,
} from "../util/parsers";
import { getSegmentEnumInsuranceTypeFromAuthConfig } from "../../analytics/util";
import { useAccount } from "../providers/AccountProvider";
import { PaType } from "analytics/generated/segment";
import { interpolate } from "../util/stringUtils";

const ViewOnPortalLink: React.FC<{
  authorization: Authorization;
}> = ({ authorization }) => {
  const { isWebExtensionConnected } = useContext(WebExtensionContext);

  let portalUrlFound;

  if (authorization.portalKey === "COVER_MY_MEDS") {
    portalUrlFound = authorization.portalAuthorizationId
      ? `${portalUrlFound}${authorization.portalAuthorizationId}`
      : `https://portal.covermymeds.com/requests/current/1`;
  }

  if (!portalUrlFound) {
    portalUrlFound =
      authorization.portal?.deepLink || authorization.portal?.loginUrl;
    portalUrlFound = interpolate(portalUrlFound, authorization);
  }

  const authConfig = parseAsUnknownRecordWithDefault(authorization.config, {});
  const hcpcsCodes = parseHcpcsValuesFromRecord(authConfig);
  const defaultFields = window.CONFIG.DEFAULT_FIELDS;
  const insuranceName = authConfig[
    defaultFields.INSURANCE_COMPANY.key
  ] as string;
  const insuranceState = authConfig[
    defaultFields.INSURANCE_STATE.key
  ] as string;
  const insuranceType = getSegmentEnumInsuranceTypeFromAuthConfig(authConfig);

  // do not show link for Blue cross complete, no tracking is done
  const isBlueCrossComplete = authorization.portalKey === "BLUE_CROSS_COMPLETE";

  const account = useAccount();

  const authorizationCorrespondenceCodes = useMemo(() => {
    return authorization.correspondences
      .map((correspondence) => correspondence.code)
      .filter((code) => code !== null) as string[];
  }, [authorization.correspondences]);

  return (authorization.portalKey || authorization.PortalId) &&
    !authorization.portal?.isFallback &&
    portalUrlFound &&
    isWebExtensionConnected &&
    !isBlueCrossComplete ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Tooltip title="Click here to launch the portal and check the status">
        <Button startIcon={<VisibilityIcon />} sx={{ width: "200px" }}>
          <Link
            href={portalUrlFound}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ textDecoration: "none" }}
            data-cy="actionViewOnPortalLink"
            onClick={(e) => {
              e.stopPropagation();

              if (account) {
                segmentTypewriter.portalStatusChecked({
                  accountId: +account.id,
                  email: account.email!,
                  isAdmin: account.isAdmin,
                  institutionIsTop: account.institution!.isTop,
                  institutionName: account.institution!.name,
                  institutionSpecialty:
                    account.institution!.practicingSpecialty ?? undefined,
                  internalAuthId: +authorization.id,
                  paType:
                    (authorization.type as unknown as PaType) ?? undefined,
                  hcpcsCodes,
                  insuranceName,
                  insuranceState,
                  insuranceType,
                  authorizationCorrespondenceCodes,
                  portalAuthorizationId:
                    authorization.portalAuthorizationId ?? undefined,
                });
              }
            }}
          >
            Check Status in Portal
          </Link>
        </Button>
      </Tooltip>
    </Box>
  ) : null;
};

export default ViewOnPortalLink;
