import { useConfig } from "../../app/hooks";

export const useGenderOptions = (): string[] => {
  const { DEFAULT_FIELDS } = useConfig();

  return [
    DEFAULT_FIELDS?.PATIENT_GENDER_MALE,
    DEFAULT_FIELDS?.PATIENT_GENDER_FEMALE,
    DEFAULT_FIELDS?.PATIENT_GENDER_OTHER,
  ].map(({ title }) => title);
};
