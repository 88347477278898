import * as React from "react";
import styled from "styled-components";
import { Flex, Box } from "./Flexbox";
import { Spinner } from "./Spinner";

export type BaseButtonProps = React.ComponentProps<typeof BaseButton>;

/**
 * BaseButton is a button component that should be used as the building block
 * for semantic buttons. It has the base functionality you'd expect out of a
 * button and can be customized for more semantic use cases.
 *
 * It defaults to `type="button"`, unlike the the `<button>` HTML element (which
 * defaults to `type="submit"`). This is usually what you want in React
 * applications, but if it's not, you can specify the button type using the
 * `type` prop.
 *
 * ## When to use:
 *
 * This button should be used as a building block for more semantic button
 * components (e.g. `PrimaryButton`); it should not be used directly in
 * application code.
 *
 * @example
 * ```tsx
 * import styled from "styled-components";
 * import { BaseButton } from "./BaseButton";
 *
 * export const WarningButton = styled(BaseButton).attrs(({ theme }) => ({
 *   color: theme.warning,
 * }))``;
 *
 * ```
 */
/**
 * @deprecated Use a MUI component instead
 */
export const BaseButton = styled.button
  .withConfig({
    shouldForwardProp(prop, defaultValidatorFn) {
      // These props are valid HTML attributes and styled-components will
      // forward them to the underlying HTML element; however, we don't use them
      // for the same purpose as the HTML attribute spec. Avoid passing them
      // down to avoid errors.
      //
      // https://github.com/emotion-js/emotion/tree/4c7b6de1ff294e45d1e9757dbe37b961cded211b/packages/is-prop-valid
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes
      if (["color", "loading"].includes(prop)) {
        return false;
      }
      return defaultValidatorFn(prop);
    },
  })
  .attrs<{
    /**
     * The background color of the button.
     */
    backgroundColor?: string;
    children?: React.ReactNode;
    /**
     * The primary color of the button. Used for the button border and font.
     */
    color?: string;
    /**
     * Whether or not the user can interact with the button. Disables the button
     * and changes its color, which can be customized via the `disabledColor`
     * and `disabledBackgroundColor` props.
     */
    disabled?: boolean;
    /**
     * The primary color of the button when the button is disabled. Used for the
     * button border and font.
     */
    disabledColor?: string;
    /**
     * The background color of the button when disabled.
     */
    disabledBackgroundColor?: string;
    /**
     * An element to render to the left of the button contents. Useful for
     * rendering icons, etc.
     */
    enhancer?: React.ReactNode;
    /**
     * Whether or not the button should occupy the full width of the parent
     * container.
     */
    fluid?: boolean;
    /**
     * Inverts the button's colors.
     *
     * By default, the button has a solid background and solid border; on hover
     * it has a white background and a solid border. Setting this to `true`
     * inverts that behavior.
     */
    inverted?: boolean;
    /**
     * Display a loading indicator (spinner).
     *
     * Note that by default, setting `loading={true}` does not disable the
     * button; if this is the behavior you want, you should disable the button
     * depending on the loadin state: e.g. `disabled={loading}`.
     */
    loading?: boolean;
  }>(
    ({
      backgroundColor: _backgroundColor,
      children,
      color: _color,
      disabled = false,
      disabledBackgroundColor: _disabledBackgroundColor,
      disabledColor = "white",
      enhancer,
      inverted = false,
      loading = false,
      onClick,
      theme,
      type = "button",
    }) => {
      const disabledBackgroundColor =
        _disabledBackgroundColor ?? theme.disabledGray;
      const backgroundColor = disabled
        ? disabledBackgroundColor
        : _backgroundColor ?? theme.purple;
      const color = disabled ? disabledColor : _color ?? "white";
      return {
        color: !inverted ? color : backgroundColor,
        backgroundColor: !inverted ? backgroundColor : color,
        onClick:
          onClick == null
            ? onClick
            : (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (!disabled) {
                  onClick(event);
                }
              },
        children: (
          <span>
            {loading && (
              <Flex
                alignItems="center"
                flexDirection="row"
                justifyContent="center"
                style={{ position: "relative" }}
              >
                <Box height="100%" style={{ position: "absolute" }}>
                  <Spinner />
                </Box>
              </Flex>
            )}
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              style={{ visibility: loading ? "hidden" : "visible" }}
            >
              {enhancer != null && <Box marginRight="4px">{enhancer}</Box>}
              <Box>{children}</Box>
            </Flex>
          </span>
        ),
        disabled,
        disabledColor,
        loading,
        type,
      };
    }
  )<{
  backgroundColor?: string;
  children?: React.ReactNode;
  color?: string;
  disabled?: boolean;
  disabledBackgroundColor?: string;
  disabledColor?: string;
  enhancer?: React.ReactNode;
  fluid?: boolean;
  inverted?: boolean;
  loading?: boolean;
}>`
  display: inline-block;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-color: ${({ backgroundColor, color, inverted = false }) =>
    inverted ? color : backgroundColor};
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  color: ${({ color, disabled, disabledBackgroundColor }) =>
    disabled! ? disabledBackgroundColor : color};
  padding: 10px;
  width: ${({ fluid = false }) => (fluid ? "100%" : undefined)};

  &:hover {
    border-color: ${({ backgroundColor, color, inverted = false }) =>
      inverted ? color : backgroundColor};
    cursor: ${({ disabled }) => (disabled! ? "" : "pointer")};
    color: ${({ backgroundColor, disabled, disabledBackgroundColor }) =>
      disabled! ? disabledBackgroundColor : backgroundColor};
    background: ${({ backgroundColor, color, disabled }) =>
      disabled! ? backgroundColor : color};

    ${Spinner} {
      border-top-color: ${({ backgroundColor, color, disabled }) =>
        disabled! ? backgroundColor : color};
    }
  }

  &:focus {
    outline: 0;
  }
`;
