import _ from "lodash";
import { Stack } from "@samacare/design";
import { AutocompleteField, TextField } from "@samacare/form2";
import { ServiceQuantityTypeCode } from "@samacare/graphql";
import { useFormContext } from "react-hook-form";

const toLabel = (value: string) => _.startCase(_.lowerCase(value));

const QuantityTypeOrder: ServiceQuantityTypeCode[] = [
  ServiceQuantityTypeCode.Units,
  ServiceQuantityTypeCode.Visits,
  ServiceQuantityTypeCode.Hours,
  ServiceQuantityTypeCode.Days,
  ServiceQuantityTypeCode.Months,
];

type QuantityProps = {
  disabled: boolean;
  required: boolean;
  name: string;
};

const ensurePositiveValue = (value: string): string => {
  if (value === "") return "";

  const parsedValue = Number(value);

  if (!Number.isInteger(parsedValue) || parsedValue === 0) {
    return "1";
  }

  return parsedValue < 0
    ? Math.abs(parsedValue).toString()
    : parsedValue.toString();
};

export const QuantityAndType: React.FC<QuantityProps> = ({
  disabled,
  required,
  name,
}) => {
  const { watch, setValue } = useFormContext();
  const quantityTypeTag = `${name}.quantityType`;
  const quantityType = watch(quantityTypeTag);
  const quantityTag = `${name}.quantity`;

  return (
    <Stack direction="row" spacing={1}>
      <AutocompleteField<string, false, true, false>
        disableClearable
        getOptionLabel={(val: string) => toLabel(val)}
        options={QuantityTypeOrder}
        label="Quantity type"
        name={quantityTypeTag}
        required={required}
        disabled={disabled}
        value={quantityType ?? null}
        isOptionEqualToValue={(option, value) => option === value || value === ""}
        data-cy="fieldHcpcsCodeQuantityType"
      />
      <TextField
        name={`${name}.quantity`}
        label="Quantity"
        placeholder={quantityType ? `Number of ${quantityType}` : ""}
        required={required}
        disabled={disabled}
        fullWidth
        type="number"
        InputProps={{ inputProps: { min: 1 } }}
        onChange={(e) => setValue(quantityTag, ensurePositiveValue(e.target.value))}
        data-cy="fieldHcpcsCodeQuantity"
      />
    </Stack>
  );
};
