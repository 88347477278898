import { StateAbbrToName } from "@samacare/constants";

export type StateOption = {
  id: string;
  label: string;
};

export const useStateOptions = (): StateOption[] =>
  Object.entries(StateAbbrToName).map(([id, label]) => ({
    id,
    label,
  }));
