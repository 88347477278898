import * as React from "react";
import styled from "styled-components";
import { BaseButton } from "./BaseButton";

export type PrimaryButtonProps = React.ComponentProps<typeof PrimaryButton>;

/**
 * @deprecated Use a MUI component instead
 */
export const PrimaryButton = styled(BaseButton).attrs(({ color, theme }) => ({
  color: color ?? theme.colors.primary,
}))``;
