import _ from "lodash";
import styled from "styled-components";
import { useSet } from "../../hooks/useSet";
import { Item, Items } from "../AuthorizationSharedComponents/Section";
import Segment from "./Segment";

const LEFT_PADDING = "7px";

const countToSize: Record<number, string> = {
  1: "large",
  2: "med",
  3: "small",
};

interface WrapperProps {
  highlight?: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  padding-top: 5px;
  border: ${(props) => (props.highlight ? "1px solid red" : "")};
`;

const MultiSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

interface SegmentsProps {
  items: Items;
  disabled: boolean;
  highlightAsMissing?: boolean;
  isModal?: boolean;
}
export const SegmentFieldContainer: React.VFC<SegmentsProps> = (props) => {
  const set = useSet();

  return (
    <Wrapper highlight={props.highlightAsMissing}>
      {_.map(props.items, (item, i) => {
        if (_.isArray(item) && item.length > 0) {
          return (
            <MultiSegment key={`MultiSegment${item[0].title}${i}`}>
              {_.map(item, (subItem, index) => (
                <Segment
                  key={`${subItem.title}${index}`}
                  disabled={props.disabled}
                  item={subItem}
                  leftPadding={LEFT_PADDING}
                  set={set}
                  size={countToSize[item.length]}
                />
              ))}
            </MultiSegment>
          );
        }
        return (
          <Segment
            key={`${(item as Item).title}${i}`}
            disabled={props.disabled}
            item={item}
            leftPadding={LEFT_PADDING}
            set={set}
            size="large"
            defaultValue={(item as Item).defaultValue}
            isModal={props.isModal}
          />
        );
      })}
    </Wrapper>
  );
};
