import { MaskedTextField, TextFieldProps } from "@samacare/form2";

export type TinFieldProps = TextFieldProps;

export const TinField: React.FC<TinFieldProps> = (props) => {
  const { label, ...rest } = props;

  return (
    <MaskedTextField
      label={label || "Tax Identification Number (TIN)"}
      mask="99-9999999"
      fullWidth
      rules={{
        pattern: {
          value: /(^\d{9}$)/,
          message: "TIN must be nine digits",
        },
      }}
      {...rest}
    />
  );
};
