import React from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Box, Stack } from "@samacare/design/core";
import { UnifiedBenefitsCheckType } from "@samacare/graphql";
import { UpsertBenefitsCheckPatientMutationMutation } from "@@generated/graphql";
import { RightSideMenu } from "../components";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { usePatient } from "@@hooks/usePatient";
import CircularProgress from "@samacare/design/core/CircularProgress";
import { SelectBenefitVerificationTypeDataType } from "../types";
import { BenefitsVerificationServiceAndPatientSelectionForm } from "../components/BenefitsVerificationServiceAndPatientSelectionForm";
import { transformUrlInfoToTypeSelectionFormValues } from "../formUtils";
import UpsertBenefitsCheckPatientMutationGql from "../graphql/UpsertBenefitsCheckPatient.gql";
import { useAccount } from "../../../providers/AccountProvider";

type SelectBenefitsVerificationTypeFormProps = {
  disabled?: boolean;
};

export const SelectBenefitsVerificationType: React.VoidFunctionComponent = ({
  disabled = false,
}: SelectBenefitsVerificationTypeFormProps) => {
  const alert = useAlert();
  const history = useHistory();
  const account = useAccount();
  const location = useLocation();

  const patientId = new URLSearchParams(location.search).get("patientId");
  const drugOptionId = new URLSearchParams(location.search).get("drugOptionId");
  const type = new URLSearchParams(location.search).get("type");

  const patientResult = usePatient(Number(patientId));
  const [upsertPatient] =
    useMutation<UpsertBenefitsCheckPatientMutationMutation>(
      UpsertBenefitsCheckPatientMutationGql
    );

  const defaultValues = transformUrlInfoToTypeSelectionFormValues({
    patient: patientResult.patient,
    drugOptionId,
    type: type as UnifiedBenefitsCheckType,
  });

  if (patientResult.loading) {
    return <CircularProgress />;
  }

  const onSubmit = async (data: SelectBenefitVerificationTypeDataType) => {
    const { DrugOptionId, PatientId, patient, benefitsCheckType } = data;

    let existingPatientId = PatientId ?? null;
    if (existingPatientId == null) {
      const patientResponse = await upsertPatient({
        variables: {
          InstitutionId: account?.institution?.id,
          id: PatientId,
          patientData: patient,
        },
      });
      existingPatientId =
        patientResponse.data?.upsertBenefitsCheckPatient?.id ?? null;
    }

    if (DrugOptionId) {
      history.push(
        `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/create?drugOptionId=${DrugOptionId}&patientId=${existingPatientId}&type=${benefitsCheckType}`
      );
    } else {
      history.push(
        `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/create?patientId=${existingPatientId}&type=${UnifiedBenefitsCheckType.Lite}`
      );
    }

    alert.info("Successfully update Benefits Verification Type!");
  };

  return (
    <Box
      data-cy="componentBenefitsVerificationTypeSelection"
      width="100%"
      height="100%"
      position="absolute"
      top="0"
      left="0"
      p={2}
    >
      <Box
        sx={{
          width: "100%",
          height: "50px",
          borderBottom: "1px solid rgb(176, 176, 176)",
          margin: "10px",
          fontWeight: 700,
          fontSize: "1.5rem",
        }}
      >
        New Benefits Verification
      </Box>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Box py={4} px={2}>
          <BenefitsVerificationServiceAndPatientSelectionForm
            disabled={disabled}
            onSubmit={onSubmit}
            defaultValues={
              defaultValues as SelectBenefitVerificationTypeDataType
            }
          />
        </Box>
        <Box width="230px" margin={7}>
          <RightSideMenu activeStep={0} />
        </Box>
      </Stack>
    </Box>
  );
};
