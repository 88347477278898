import { Box, Grid } from "@samacare/design/core";
import { useEnrollmentContext } from "./EnrollmentProvider";

export const EnrollmentPatientInfoHeader: React.FC = () => {
  const { enrollment } = useEnrollmentContext();
  const patient = enrollment?.MedicationRequest?.patient;

  return (
    <Box
      sx={{
        flexGrow: 0.5,
        boxShadow: 1,
        borderRadius: "0.3rem",
        padding: "10px",
      }}
    >
      <Grid sx={{ margin: "10px", fontWeight: 700, fontSize: "1.2rem" }}>
        {`${patient?.lastName}, ${patient?.firstName} `}
        {`( ${patient?.dob} )`}
      </Grid>
      <Grid sx={{ margin: "10px" }}>
        {enrollment?.EnrollmentProgram?.label}
      </Grid>
    </Box>
  );
};
