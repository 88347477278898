import { useDrugOptions } from "./useDrugOptions";
import { useFeatureFlagNames } from "./config";
import { useFeatureFlag } from "./useFeatureFlag";
import { cleanDrugName } from "../../../server/src/shared_code";

export const useEnabledDrugOptions = () => {
  const [allDrugs, loading] = useDrugOptions();
  const featureFlags = useFeatureFlagNames();
  const enabledDrugs =
    useFeatureFlag<{ drugName: string; drugOptionId: number }[]>(
      featureFlags.AvailableBenefitsVerificationsDrugsV2
    ) ?? [];

  if (loading) {
    return { loading, drugOptions: [] };
  }

  // build a set of enabled drug ids
  const enabledDrugIds = enabledDrugs.map((drug) => drug.drugOptionId);
  const enabledDrugNames = enabledDrugs.map((drug) =>
    cleanDrugName(drug.drugName)?.toLowerCase()
  );
  const enabledDrugIdSet = new Set<number>(enabledDrugIds);

  // show drug option if the drug id is enabled in the feature flag
  const drugOptions = allDrugs.filter((drug) =>
    enabledDrugIdSet.has(parseInt(drug.id, 10))
  );

  return {
    loading: false,
    drugOptions,
    enabledDrugIds,
    enabledDrugNames,
  };
};
