import styled from "styled-components";

export type TextButtonProps = React.ComponentProps<typeof TextButton>;

export const TextButton = styled.div.attrs({ role: "button" })<{
  disabled?: boolean;
}>`
  color: ${({ theme }) => theme.purple};
  padding: 10px;
  text-decoration: ${({ disabled = false }) =>
    disabled ? "none" : "underline"};
  cursor: pointer;
`;

/**
 * @deprecated Use a MUI component instead
 */
export default TextButton;
