import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";
import styled from "styled-components";
import { Typography, FormHelperText } from "@samacare/design/core";
import RequiredTag from "@@components/RequiredTag";

const Container = styled.div`
  display: flex;
  font-weight: 700;
  flex-direction: column;
  width: 100%;

  .fax-number-input {
    height: 100%;
    border: ${(props) =>
      props.error
        ? "1px solid #d32f2f"
        : props.highlight
        ? "2px solid rgb(211, 47, 47)"
        : "1px solid rgb(176, 176, 176)"};
    padding: 10px;
    border-radius: 3px;
    box-shadow: none;
    color: ${(props) => (props.error ? "#d32f2f" : "inherit")}
`;

const StyledFormHelperText = styled(FormHelperText)`
  margin: 4px 14px 0 14px;
`;

const Header = styled.div`
  display: flex;
  margin: 2px 0;
  gap: 5px;
`;

export const InternationalPhone = ({
  onChange,
  number,
  name,
  disabled = false,
  highlight = false,
  placeholder = "+1 555 515 5175",
  cypressTag = "",
  onBlur = () => {},
  label = "",
  required = false,
  error = "",
}) => {
  const handleOnChange = (val) => {
    onChange({ target: { value: val } });
  };

  return (
    <Container highlight={highlight} error={error}>
      {label && (
        <Header>
          <Typography variant="caption">{label}</Typography>
          {required && <RequiredTag fontSize="18px" />}
        </Header>
      )}
      <PhoneInput
        data-cy={cypressTag}
        placeholder={placeholder}
        onChange={handleOnChange}
        name={name}
        value={number}
        disabled={disabled}
        onBlur={onBlur}
        defaultCountry="US"
        className="fax-number-input"
      />
      {error && <StyledFormHelperText error>{error}</StyledFormHelperText>}
    </Container>
  );
};
