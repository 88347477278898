import { useMutation } from "@apollo/client";
import { Prescriber } from "@samacare/graphql";
import {
  PRESCRIBERS_BY_INSTITUTION_QUERY_NAME,
  PRESCRIBERS_FIND_ALL_QUERY_NAME,
} from "../constants/Prescribers";
import { upsertPrescriberMutation } from "../graphql/mutations/upsertPrescriber";

export const useUpsertPrescriber = () => {
  const [upsertPrescriber] = useMutation(upsertPrescriberMutation, {
    refetchQueries: () => [
      PRESCRIBERS_FIND_ALL_QUERY_NAME,
      PRESCRIBERS_BY_INSTITUTION_QUERY_NAME,
    ],
  });

  return async (prescriber: Prescriber) => {
    const p = await upsertPrescriber({
      variables: {
        id: prescriber.id,
        patch: {
          ...prescriber,
        },
      },
    });
    return p.data?.upsertPrescriber;
  };
};
