import _ from "lodash";
import { useEffect, useState, useMemo } from "react";
import { useAlert } from "react-alert";
import colors from "Resources/colors";
import CorrespondenceViewer from "../CorrespondenceViewer";
import { File, setAttachment } from "../../reducers/formReducer";
import { createAuthUploadFromAwsKeyMutation } from "../../graphql/File";
import {
  GET_AUTHORIZATION_QUERY_NAME,
  withAuthorizationQuery,
} from "../../graphql/Authorization";
import BaseButton from "../BaseButton";
import Modal from "../Modal";
import List from "@samacare/design/core/List";
import {
  ListItemButton,
  ListItemText,
  Box,
  Divider,
  Button,
  LoadingButton,
  Tabs,
  Tab,
  Typography,
  Tooltip,
} from "@samacare/design/core";
import {
  Account,
  Authorization,
  IntegratedEmrFilesDownloaded,
  IntegrationInstallation,
} from "@samacare/graphql";
import {
  CreateAuthUploadFromAwsKeyMutation,
  CreateAuthUploadFromAwsKeyMutationVariables,
  GetAuthorizationQuery,
  GetAuthorizationQueryVariables,
} from "@@generated/graphql";
import { useConfig } from "@@hooks/config";
import { useDispatch, useSelector } from "../../configureStore";
import { useMutation, useQuery } from "@apollo/client";
import { Spinner } from "@@ui-kit";
import {
  getFilteredFiles,
  shouldDisplayEMRLoading,
} from "../../util/authUtils";
import {
  IntegrationFileAttachButtonContainer,
  IntegrationFileAttachButtonContainerSimplified,
} from "AuthorizationSharedComponents/IntegrationFileAttachButtonContainer";
import { shouldUseMocks } from "../../util/mockResponses";
import { useLazySignedUrl } from "@@hooks/useLazySignedUrl";

export type SelectedFileType = Omit<IntegratedEmrFilesDownloaded, "url"> & {
  fileURL: IntegratedEmrFilesDownloaded["url"];
};

const extractDetails = (url: string) => {
  const urlObj = new URL(url);
  const hostParts = urlObj.hostname.split(".");
  const bucket = hostParts.length > 0 ? hostParts[0] : "";
  const name = urlObj.pathname.split("/").pop();

  return { name, bucket };
};

export const IntegratedDownloadedFilesSuggestionModal: React.VoidFunctionComponent<{
  authorizationId: string;
  account?: Account;
  maxFileSizeMB?: number;
  isSimplifiedUx?: boolean;
}> = ({ authorizationId, account, maxFileSizeMB, isSimplifiedUx }) => {
  const dispatch = useDispatch();
  const config = useConfig();
  const alert = useAlert();

  const set = (file: File) => dispatch(setAttachment(file));

  const attachments = useSelector((state) => state.form.attachments);
  const { data, refetch } = useQuery<
    GetAuthorizationQuery,
    GetAuthorizationQueryVariables
  >(withAuthorizationQuery, { variables: { id: parseInt(authorizationId) } });
  const [createAuthUploadFromAwsKey] = useMutation<
    CreateAuthUploadFromAwsKeyMutation,
    CreateAuthUploadFromAwsKeyMutationVariables
  >(createAuthUploadFromAwsKeyMutation, {
    refetchQueries: [GET_AUTHORIZATION_QUERY_NAME],
  });

  const authorization = data?.authorizationById;

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<null | SelectedFileType>(
    null
  );

  const refetchAuth = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  let interval: NodeJS.Timeout | undefined;
  useEffect(() => {
    if (!authorization?.integratedEMRDocumentsDownloaded) {
      interval = setInterval(refetchAuth, 1000 * 10);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const categories = _.uniq(
    _.map(authorization?.integratedEMRFilesDownloaded, "category")
  );

  const filteredFiles = getFilteredFiles(
    categories[selectedCategory],
    authorization?.integratedEMRFilesDownloaded as IntegratedEmrFilesDownloaded[],
    attachments
  );

  const { getSignedUrl, signedUrl } = useLazySignedUrl();

  const { MOCKED_EMR_FILE, MOCK_EMR_CATEGORY } = config.CONSTANTS;
  const { NODE_ENV } = config;

  const { name, bucket } = useMemo(
    () => extractDetails(MOCKED_EMR_FILE.url),
    [MOCKED_EMR_FILE.url]
  );

  useEffect(() => {
    if (getSignedUrl && name && bucket) {
      void getSignedUrl(name, bucket);
    }
  }, [getSignedUrl, name, bucket]);

  if (
    shouldUseMocks({
      env: NODE_ENV,
      account,
      institution: account?.institution,
    })
  ) {
    categories.push(MOCK_EMR_CATEGORY);
    filteredFiles.push({ ...MOCKED_EMR_FILE, url: signedUrl });
  }

  const selectFile = async (
    awsFile: IntegratedEmrFilesDownloaded,
    ind: number
  ) => {
    setSelectedFile({
      ...awsFile,
      fileURL: awsFile?.url,
    });
    setSelectedIndex(ind);
  };

  useEffect(() => {
    if (authorization?.integratedEMRDocumentsDownloaded && interval) {
      clearInterval(interval);
    }
  }, [authorization?.integratedEMRDocumentsDownloaded]);

  const attachSelectedFile = async () => {
    try {
      if (!authorization || !selectedFile) {
        throw new Error();
      }
      const fileResp = await createAuthUploadFromAwsKey({
        variables: {
          authorizationId: parseInt(authorization?.id),
          title: selectedFile.title as string,
          awsKey: selectedFile.key as string,
          maxFileSizeMB,
        },
      });

      const file = fileResp?.data?.createAuthUploadFromAwsKey;

      if (file) {
        set({ title: file.title, awsKey: file.awsKey as string, id: file.id });
        setSelectedFile(null);
        setSelectedIndex(null);
      }
    } catch (e) {
      if (e instanceof Error && _.includes(e.message, "too large")) {
        alert.error(
          `The selected file is larger than the allowed limit of ${maxFileSizeMB}MB`
        );
      } else {
        alert.error(
          "There was an error uploading your file, please try again or reach out to us if this problem continues"
        );
      }
    }
  };

  const checkIsNextGenOrModMed = (accountParam: Account | undefined) =>
    _.get(accountParam, "institution.integrations", [])?.filter(
      (installation: IntegrationInstallation) =>
        [
          config.CONSTANTS.INTEGRATION_TITLE.modernizingMedicine,
          config.CONSTANTS.INTEGRATION_TITLE.nextGen,
        ].includes(installation.integrationId) && installation.isEnabled
    ).length > 0;

  const ContainerComponent = isSimplifiedUx
    ? IntegrationFileAttachButtonContainerSimplified
    : IntegrationFileAttachButtonContainer;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Modal
        header="Select uploads from EMR"
        onClick={() => {
          setOpen(!open);
        }}
        open={open}
        headerStyleOverride={{ overflowY: "hidden" }}
      >
        <Box m={1} display="flex" flexDirection="row" sx={{ width: "1200px" }}>
          {selectedFile ? (
            <Box display="flex" flexDirection="column">
              <Typography
                ml={1}
                sx={{
                  height: ({ spacing }) => spacing(6),
                  width: ({ spacing }) => spacing(60),
                  whiteSpace: "noWrap",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                }}
                variant="subtitle2"
              >
                {selectedFile.title}
              </Typography>
              <CorrespondenceViewer
                correspondence={selectedFile}
                width="700px"
                height="550px"
              />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column">
              <Typography
                ml={1}
                sx={{
                  height: ({ spacing }) => spacing(6),
                  width: ({ spacing }) => spacing(60),
                  display: "flex",
                  alignItems: "center",
                }}
                variant="subtitle2"
              >
                N/A
              </Typography>
              <Box
                sx={{
                  width: "720px",
                  height: "570px",
                  backgroundColor: ({ palette }) => palette.colors.NA200,
                }}
                display="flex"
                justifyContent="center"
              >
                <Typography pt={6} variant="h5">
                  No document selected
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            display="flex"
            flexDirection="column"
            pl={1}
            sx={{ width: "40%", marginBottom: "20px" }}
          >
            <Tabs
              value={selectedCategory}
              onChange={(e, value: number) => setSelectedCategory(value)}
            >
              {_.map(categories, (category) => (
                <Tab label={category} key={`integratedFiles_${category}`} />
              ))}
            </Tabs>
            <List
              sx={{ height: ({ spacing }) => spacing(60), overflow: "scroll" }}
            >
              {_.map(filteredFiles, (file, i) => (
                <ListItemButton
                  key={`filtered_file_${file?.key}`}
                  selected={
                    categories[selectedCategory] === selectedFile?.category &&
                    selectedIndex === i
                  }
                  onClick={async () => selectFile(file, i)}
                >
                  <ListItemText primary={file?.title} secondary={file?.date} />
                </ListItemButton>
              ))}
            </List>
            <Divider />
            <Box pt={4} display="flex" justifyContent="center">
              <Tooltip
                placement="left"
                title="Click if you do not see the document you are looking
                for"
              >
                <LoadingButton
                  variant="outlined"
                  disabled={
                    authorization?.integratedEMRDocumentsDownloaded ?? false
                  }
                  loading={loading}
                  onClick={refetchAuth}
                >
                  Load More
                </LoadingButton>
              </Tooltip>
              <Button
                sx={{ marginLeft: ({ spacing }) => spacing(2) }}
                onClick={attachSelectedFile}
                disabled={selectedFile === null}
                variant="contained"
              >
                Attach
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {shouldDisplayEMRLoading({
        integrations: _.get(account, "institution.integrations", []),
        isNextGenOrModMed: checkIsNextGenOrModMed(account),
        authorization: authorization as Authorization,
      }) && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Spinner
            style={{ marginRight: "10px" }}
            colorTop={colors.darkPurple}
            color={colors.purple}
          />
          Checking EMR for possible patient attachments... (this can take
          several minutes)
        </div>
      )}
      {(!_.isEmpty(filteredFiles) ||
        (authorization?.integratedEMRDocumentsDownloaded &&
          checkIsNextGenOrModMed(account))) && (
        <ContainerComponent>
          <BaseButton
            style={{ margin: "auto" }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            Attach from EMR
          </BaseButton>
        </ContainerComponent>
      )}
    </div>
  );
};

export default IntegratedDownloadedFilesSuggestionModal;
