import { ChangeEvent } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@samacare/design/core";
import { InsuranceCompanySupportedCodingTypes } from "@samacare/graphql";
import colors from "../../../../resources/colors";
import { LeftRightCenterV } from "../../../components/LeftRight";
import { useSelector } from "../../../configureStore";
import { useConfig } from "../../../hooks";
import { useSet } from "../../../hooks/useSet";

const colorOverride = {
  "&.Mui-checked": {
    color: colors.purple,
  },
};

const MedicalOrPharmacyRadioButtons: React.FC = () => {
  const results = useSelector((state) => state.form.results);
  const config = useConfig();
  const set = useSet();
  const df = config.DEFAULT_FIELDS;

  const selectedValue =
    results[df.DRUG_CODE_TYPE.key] ??
    InsuranceCompanySupportedCodingTypes.Jcode;

  const setDrugTypesBasedOnBenefitType = (
    event: ChangeEvent,
    codeType: string
  ) => {
    if (codeType === InsuranceCompanySupportedCodingTypes.Jcode) {
      set({
        [df.DRUG_CODE_TYPE.key]: InsuranceCompanySupportedCodingTypes.Jcode,
        [df.PRIMARY_NDC.key]: null,
        [df.PRIMARY_DRUG_NAME.key]: null,
        [df.REQUEST_DESCRIPTION.key]: null,
      });
    } else if (codeType === InsuranceCompanySupportedCodingTypes.Ndc) {
      set({
        [df.DRUG_CODE_TYPE.key]: InsuranceCompanySupportedCodingTypes.Ndc,
        [df.HCPCS_0.key]: null,
        [df.ALL_HCPCS.key]: null,
        [df.PRIMARY_DRUG_NAME.key]: null,
        [df.REQUEST_DESCRIPTION.key]: null,
      });
    }
  };

  return (
    <RadioGroup value={selectedValue} onChange={setDrugTypesBasedOnBenefitType}>
      <LeftRightCenterV>
        <FormControlLabel
          value={InsuranceCompanySupportedCodingTypes.Jcode}
          control={<Radio sx={colorOverride} />}
          label="Medical"
        />
        <FormControlLabel
          value={InsuranceCompanySupportedCodingTypes.Ndc}
          control={<Radio sx={colorOverride} />}
          label="Pharmacy"
        />
      </LeftRightCenterV>
    </RadioGroup>
  );
};

export { MedicalOrPharmacyRadioButtons };
