import { PureComponent } from "react";
import styled from "styled-components";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import saveAs from "file-saver";
import JSZip from "jszip";
import _ from "lodash";
import { withAlert } from "react-alert";

import Modal from "../../Modal";
import BaseButton from "../../BaseButton";
import { ModalBody, ModalFooter } from "../../ModalStyledComponents";

const ModalContent = styled.div`
  font-size: 16px;
`;

const ModalText = styled.div`
  text-align: center;
  padding: 25px 0;
  font-weight: 700;
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  font-size: 16px;
  ${BaseButton} {
    width: 150px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const GrayButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.lightGray};
  border-color: ${(props) => props.theme.lightGray};
  color: white;
`;

const getPortalAuthorizationUploads = gql`
  query GetPortalAuthorizationAttachments($id: Int!) {
    authorizationById(id: $id) {
      portalUploadAttachments
    }
  }
`;

class DownloadPortalAttachmentsButton extends PureComponent {
  state = { open: false };

  toggleModal = (e) => {
    e.stopPropagation();

    const { open } = this.state;

    this.setState({ open: !open });
  };

  export = async (attachments) => {
    const { authorization, alert } = this.props;
    const patientFirstName =
      authorization.config[CONFIG.DEFAULT_FIELDS.PATIENT_FIRST_NAME.key];
    const patientLastName =
      authorization.config[CONFIG.DEFAULT_FIELDS.PATIENT_LAST_NAME.key];
    const dateOfService =
      authorization.config[CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE.key];
    const zip = new JSZip();

    _.each(attachments, (attachment, i) =>
      zip.file(`file_${i + 1}.pdf`, attachment.Body.data)
    );

    try {
      const blob = await zip.generateAsync({ type: "blob" });
      saveAs(
        blob,
        `Auth_${patientFirstName}_${patientLastName}_${dateOfService}.zip`
      );
      this.setState({ open: false });
    } catch {
      alert.error("There was an error downloading your attachments");
    }
  };

  render() {
    const { children, authorization } = this.props;
    const { open } = this.state;

    return (
      <div>
        <Modal
          header="Download Attachments"
          onClick={this.toggleModal}
          open={open}
        >
          <ModalBody>
            <ModalContent>
              {open && (
                <Query
                  query={getPortalAuthorizationUploads}
                  variables={{ id: authorization.id }}
                >
                  {({ data, loading }) => {
                    if (loading || !data) {
                      return (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "25px",
                            fontWeight: "500",
                          }}
                        >
                          Loading...
                        </div>
                      );
                    }

                    if (
                      _.isEmpty(
                        _.get(data.authorizationById, "portalUploadAttachments")
                      )
                    ) {
                      return (
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: "25px",
                            fontWeight: "500",
                          }}
                        >
                          No files have been attached to this authorization!
                        </div>
                      );
                    }

                    return (
                      <>
                        <ModalText>
                          Would you like to download the attachments uploaded to
                          this authorization?
                        </ModalText>
                        <CustomModalFooter>
                          <GrayButton
                            onClick={() => {
                              this.export(
                                data.authorizationById.portalUploadAttachments
                              );
                            }}
                          >
                            Yes
                          </GrayButton>
                          <GrayButton onClick={this.toggleModal}>No</GrayButton>
                        </CustomModalFooter>
                      </>
                    );
                  }}
                </Query>
              )}
            </ModalContent>
          </ModalBody>
        </Modal>
        <div onClick={this.toggleModal}>{children}</div>
      </div>
    );
  }
}

/**
 * @deprecated Use a MUI component instead
 */
export default withAlert()(DownloadPortalAttachmentsButton);
