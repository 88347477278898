import styled from "styled-components";

export const SectionHeader = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 48px;
  color: ${(props) => props.theme.lightGray};
  margin-top: 24px;
`;
