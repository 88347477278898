import {
  Mutation,
  MutationDuplicateAuthorizationArgs,
} from "@samacare/graphql";

import { useMutation } from "@apollo/client";
import {
  AUTHORIZATION_PAGINATION_QUERY_NAME,
  duplicateAuthorizationMutation,
} from "../graphql/Authorization";

interface DuplicateArgs {
  authorizationId: number;
  setNewForm: boolean;
  portalKey?: string;
  portalId?: number;
  isWebExtension: boolean;
  insuranceName?: string;
  isExternal: boolean;
}

const useDuplicateAuthorization = () => {
  const [duplicateAuthorization] = useMutation<
    { duplicateAuthorization: Mutation["duplicateAuthorization"] },
    MutationDuplicateAuthorizationArgs
  >(duplicateAuthorizationMutation, {
    refetchQueries: ["allAuthorizations", AUTHORIZATION_PAGINATION_QUERY_NAME],
  });

  const duplicate = async (args: DuplicateArgs) => {
    const result = await duplicateAuthorization({
      variables: args,
    });

    return result.data?.duplicateAuthorization;
  };

  return duplicate;
};

export { useDuplicateAuthorization };
