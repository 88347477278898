import { Dialog } from "@samacare/design";
import { useTheme } from "@samacare/design/core/styles";

interface ISamaDialogProps {
  open: boolean;
  onClose: () => void;
}
export const SamaDialog: React.FC<ISamaDialogProps> = (props) => {
  const theme = useTheme();
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll="paper"
      sx={{
        "& .MuiDialog-paper": {
          borderTop: `4px solid ${theme.palette.primary.light}`,
          borderRadius: "8px",
          width: "600px",
        },
      }}
    >
      {props.children}
    </Dialog>
  );
};
