import { useEffect, useMemo } from "react";
import { FormFieldConfig } from "@samacare/graphql";
import { useSetRequired } from "./useSetRequired";
import { useUnsetRequired } from "./useUnsetRequired";

const df = window.CONFIG.DEFAULT_FIELDS;

const AllAdditionalFieldKeys: string[] = [df.SERVICE_TYPE_CODE.key];

export interface FieldConfig {
  key: string;
  isHidden: boolean;
  isRequired: boolean;
  options?: { key: string }[];
}

export const useFieldConfigs = (
  fields: { key: string; required?: boolean; options?: { key: string }[] }[],
  formFieldConfig?: FormFieldConfig | null
) => {
  const setRequired = useSetRequired();
  const unsetRequired = useUnsetRequired();

  const fieldConfigs: Record<string, FieldConfig> = useMemo<
    Record<string, FieldConfig>
  >(() => {
    const map: Record<string, FieldConfig> = {};
    fields.forEach((field) => {
      const isHidden = AllAdditionalFieldKeys.includes(field.key)
        ? !formFieldConfig?.additionalFields?.includes(field.key)
        : formFieldConfig?.hiddenFields?.includes(field.key) ?? false;
      const isRequired =
        !isHidden &&
        !!(
          field.required || formFieldConfig?.requiredFields?.includes(field.key)
        );

      map[field.key] = {
        key: field.key,
        isHidden,
        isRequired,
        options: field.options,
      };
    });

    return map;
  }, [formFieldConfig, fields]);

  useEffect(() => {
    Object.values(fieldConfigs).forEach((fieldConfig) => {
      if (fieldConfig.isRequired) {
        if (fieldConfig.options != null) {
          setRequired(fieldConfig.options.map((option) => option.key));
        } else {
          setRequired(fieldConfig.key);
        }
      }
    });
    return () => {
      Object.values(fieldConfigs).forEach((fieldConfig) => {
        unsetRequired(fieldConfig.key);
      });
    };
  }, [fieldConfigs, setRequired, unsetRequired]);

  return fieldConfigs;
};
