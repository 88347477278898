import _ from "lodash";
import { Grid } from "@samacare/design/core";
import React, { Fragment } from "react";

import AccordionSection from "../../../layout/AccordionSection";
import { EnrollmentSection } from "./../../routes/Enrollment/EnrollmentSection";
import { EnrollmentServiceResponseParser } from "../../routes/Enrollment/EnrollmentServiceResponseParser";

const serviceLinkText = {
  "applied-for-bipa": "BIPA Service Response",
  "applied-for-pf": "Patient Foundation Service Response",
  "applied-for-starter": "Starter Service Response",
};

export const GenentechOcrevusSummaryResponse: React.VFC<{
  serviceLinkId: string;
  parser: EnrollmentServiceResponseParser;
}> = ({ serviceLinkId, parser }) => {
  return (
    <EnrollmentSection
      title={serviceLinkText[serviceLinkId as keyof typeof serviceLinkText]}
    >
      {_.map(parser.getSummarySections(), (section, idx) => {
        if (section) {
          return (
            <AccordionSection
              key={idx}
              defaultExpanded={idx === 0}
              title={section.getType()}
            >
              <Grid container spacing={1}>
                {_.map(section.getAttributes(), ({ key, value }) => (
                  <Fragment key={key}>
                    <Grid item xs={6}>
                      {key}
                    </Grid>
                    <Grid item xs={6}>
                      {value}
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </AccordionSection>
          );
        }
      })}
      <AccordionSection title="Raw Response">
        {_.map(parser.getResponseTextNodes(), (text) => (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </AccordionSection>
    </EnrollmentSection>
  );
};
