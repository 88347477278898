import _ from "lodash";
import { PureComponent } from "react";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import styled from "styled-components";
import Select from "react-select";
import { BaseText } from "Segment/StyledComponents";

import DrugEdit from "./DrugEdit";
import { withDrugOptions } from "../../../graphql/DrugOptions";
import {
  SupportTwoPane,
  SupportListContainer,
  SupportTable,
  SupportTableHeader,
  SupportTableRow,
  SupportCell,
  SupportScroll,
} from "../SharedStyles";

const StyledSelect = styled(Select)`
  margin-left: 10px;
  width: 275px;
`;

const InputLabel = styled.div`
  width: 100%;
  font-weight: 700;
  margin: 10px 0;
`;

const sortByOptions = ["code", "drugName"];

const columns = ["code", "drugName"];

class SupportDrugOptions extends PureComponent {
  state = { searchText: "", sortBy: sortByOptions[0], selectedDrug: {} };

  clearSelectedDrug = () => {
    this.setState({ selectedDrug: {} });
  };

  resetSelectedDrug = () => {
    const { drugOptions } = this.props;
    this.setState((prevState) => ({
      selectedDrug: _.find(drugOptions, { id: prevState.selectedDrug.id }),
    }));
  };

  render() {
    const { searchText, sortBy, selectedDrug } = this.state;
    const { drugOptions } = this.props;
    const filteredOptions = _.filter(
      drugOptions,
      ({ code, drugName }) =>
        _.includes(code.toUpperCase(), searchText.toUpperCase()) ||
        _.includes(drugName.toUpperCase(), searchText.toUpperCase())
    );

    return (
      <SupportTwoPane>
        <SupportListContainer>
          <div style={{ display: "flex" }}>
            <InputLabel style={{ width: "100%" }}>
              Select a Drug Option to Edit
            </InputLabel>
            <InputLabel style={{ marginLeft: "10px", width: "275px" }}>
              Sort by:
            </InputLabel>
          </div>
          <div style={{ display: "flex", height: "38px" }}>
            <BaseText
              data-cy="fieldSearchDrugByJCode"
              value={searchText}
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
              }}
              placeholder="Search by HCPCS Code or Drug Name"
            />
            <StyledSelect
              value={{ value: sortBy, label: sortBy }}
              onChange={({ value }) => {
                this.setState({ sortBy: value });
              }}
              options={_.map(sortByOptions, (option) => ({
                value: option,
                label: option,
              }))}
              isClearable={false}
              isSearchable={false}
            />
          </div>
          <SupportScroll>
            <SupportTable>
              <thead>
                <tr>
                  {_.map(columns, (name) => (
                    <SupportTableHeader key={`column-${name}`}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>{`${_.upperFirst(name)} `}</div>
                        <div style={{ marginLeft: "3px", fontSize: "30px" }}>
                          {sortBy === name ? "▾" : ""}
                        </div>
                      </div>
                    </SupportTableHeader>
                  ))}
                </tr>
              </thead>
              <tbody>
                {_.map(_.sortBy(filteredOptions, sortBy), (drug) => (
                  <SupportTableRow
                    data-cy="componentDrugOptionRow"
                    key={`drug-id-${drug.id}`}
                    onClick={() => {
                      this.setState({ selectedDrug: drug });
                    }}
                    selected={drug.id === selectedDrug.id}
                  >
                    <SupportCell>{drug.code}</SupportCell>
                    <SupportCell>{drug.drugName}</SupportCell>
                  </SupportTableRow>
                ))}
              </tbody>
            </SupportTable>
          </SupportScroll>
        </SupportListContainer>
        <DrugEdit
          selectedDrug={selectedDrug}
          clearSelectedDrug={this.clearSelectedDrug}
          resetSelectedDrug={this.resetSelectedDrug}
        />
      </SupportTwoPane>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withDrugOptions)(withAlert()(SupportDrugOptions));
