export const formatZipForDisplay = (rawZip?: string): string => {
  const zip = rawZip?.trim();
  if (!zip) {
    return "";
  }
  if (zip.length === 9) {
    return `${zip.slice(0, 5)}-${zip.slice(5)}`;
  }
  return zip;
};
