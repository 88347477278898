import { useState } from "react";
import { Stack, SxProps } from "@samacare/design";
import { useApolloClient } from "@apollo/client";
import { Prescriber } from "@samacare/graphql";
import {
  EntityCard,
  ProviderAutocomplete,
  UpsertPrescriberDialog,
  useFormContext,
} from "@samacare/form2";
import { formatTinForDisplay } from "@samacare/utils";

export type PaProviderBlockProps = {
  sx?: SxProps;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  label?: string;
  onAlert?: (message: string) => void;
};

export const PaProviderBlock: React.FC<PaProviderBlockProps> = (props) => {
  const { watch, setValue, trigger } = useFormContext();
  const [prescriberToEdit, setPrescriberToEdit] = useState<Prescriber | null>(
    null,
  );

  const apolloClient = useApolloClient();

  const [isNewPrescriberDialogOpen, setIsNewPrescriberDialogOpen] =
    useState<boolean>(false);

  const prescriber = watch("prescriber");
  const PrescriberId = watch("PrescriberId");

  const clearPrescriber = async () => {
    setValue("prescriber", null, { shouldDirty: true });
    setValue("PrescriberId", null, {
      shouldDirty: true,
    });
    await trigger();
  };

  const handleEdit = () => {
    setPrescriberToEdit({
      ...prescriber,
      id: prescriber.id ?? PrescriberId,
    });
    setIsNewPrescriberDialogOpen(true);
  };

  const isPrescriberSelected =
    prescriber && (prescriber.NPI || prescriber.firstName);

  return (
    <Stack gap={2} maxWidth={600} width={600} mb={1} sx={props.sx}>
      {isPrescriberSelected ? (
        <EntityCard
          title={`${prescriber.firstName} ${prescriber.lastName || ""}`}
          subtitle={prescriber.label}
          subtitle2={prescriber.specialtyDescription}
          onEdit={prescriber.id ? handleEdit : undefined}
          onRemove={clearPrescriber}
          disabled={props.disabled}
          dataItems={[
            { label: "NPI", value: prescriber.NPI },
            { label: "License", value: prescriber.licenseNumber },
            { label: "TIN", value: formatTinForDisplay(prescriber.TIN) },
            { label: "DEA", value: prescriber.DEA },
          ]}
          warningMessage={
            !prescriber.TIN && !props.disabled
              ? "Enter TIN for seamless tracking of Prior Authorizations"
              : undefined
          }
          warningButtonText="Update"
          onWarningButtonClick={handleEdit}
        />
      ) : (
        <ProviderAutocomplete
          apolloClient={apolloClient}
          name={props.name || "prescriber"}
          placeholder="Choose..."
          label={props.label}
          disabled={props.disabled}
          required={props.required}
          filterSelectedOptions
          onNew={() => {
            setPrescriberToEdit({} as Prescriber);
            setIsNewPrescriberDialogOpen(true);
            clearPrescriber();
          }}
          onChange={(_event, change) => {
            setValue("prescriber", change, { shouldDirty: true });
            setValue("PrescriberId", change?.id ?? null, {
              shouldDirty: true,
            });
          }}
        />
      )}
      <UpsertPrescriberDialog
        apolloClient={apolloClient}
        open={isNewPrescriberDialogOpen}
        PrescriberId={prescriberToEdit?.id}
        onClose={async (p: Prescriber | null | undefined) => {
          setIsNewPrescriberDialogOpen(false);
          if (p) {
            setValue("prescriber", p, { shouldDirty: true });
            setValue("PrescriberId", p?.id, { shouldDirty: true });
          }
        }}
        onAlert={props.onAlert}
      />
    </Stack>
  );
};
