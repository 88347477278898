import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { prescriberInfo } from "../graphql/fragments/prescriberInfo";

const prescriberByIdQuery = gql`
  query PrescriberById($id: Int!) {
    prescriberById(id: $id) {
      ...prescriberInfo
    }
  }
  ${prescriberInfo}
`;

export const usePrescriber = (id: number | string | undefined | null) => {
  const { data, loading } = useQuery(prescriberByIdQuery, {
    variables: { id: id ?? -1 },
  });

  return {
    prescriber: data?.prescriberById ?? null,
    loading,
  };
};
