import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import getIngestJobQuery from "../graphql/GetIngestJobQuery.gql";
import { IngestJob, PipelineStage } from "@samacare/graphql";

const POLLING_PERIOD = 2 * 1000; // 2 seconds

const isDone = (ij: IngestJob) =>
  ij.currentStage === PipelineStage.Finished ||
  ij.currentStage === PipelineStage.Failed;

const useAutoRefreshIngestJob = () => {
  const [ingestJob, setIngestJob] = useState<IngestJob | null>(null);
  const { data, startPolling, stopPolling } = useQuery<{
    getIngestJobById: IngestJob;
  }>(getIngestJobQuery, {
    variables: { ingestJobId: ingestJob?.id },
    skip: !ingestJob,
  });

  useEffect(() => {
    if (ingestJob && !isDone(ingestJob)) {
      startPolling(POLLING_PERIOD);
    } else {
      stopPolling();
    }
  }, [ingestJob, startPolling, stopPolling]);

  useEffect(() => {
    if (data) {
      const ij = data.getIngestJobById;
      setIngestJob(ij);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, [stopPolling]);

  return { ingestJob, setIngestJob };
};

export default useAutoRefreshIngestJob;
