import moment from "moment";
import styled from "styled-components";
import { Query } from "@apollo/client/react/components";
import _ from "lodash";
import colors from "Resources/colors";

import Modal from "./Modal";
import LoadingSpinner from "./LoadingSpinner";
import BaseButton from "./BaseButton";
import { ModalBody, ModalFooter } from "./ModalStyledComponents";
import { withAuthorizationQuery } from "../graphql/Authorization";

const GrayButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.lightGray};
  border-color: ${(props) => props.theme.lightGray};
  color: white;
`;

const ModalContent = styled.div`
  padding: 20px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  ${BaseButton} {
    width: 150px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const SelectorTitle = styled.div`
  font-weight: 700;
  margin: 9px 0 7px 0;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DATE_FORMAT = "MM/DD/YYYY h a";

const DownloadFormsButton = ({ authorizationId, onClick, open, header }) => (
  <Modal header={header} onClick={onClick} open={open}>
    <ModalBody>
      <ModalContent>
        <SelectorTitle>
          Click on the form(s) you would like to download
        </SelectorTitle>
        {open && (
          <Query
            query={withAuthorizationQuery}
            variables={{ id: authorizationId }}
          >
            {({ data, loading }) => {
              if (loading) {
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <LoadingSpinner
                      style={{ marginRight: "10px" }}
                      colorTop={colors.darkPurple}
                      color={colors.purple}
                    />
                    Populating downloads list...
                  </div>
                );
              }
              const { correspondences, submittedFormURL, submittedAt } =
                data.authorizationById;
              const filteredCorrespondences = _.filter(
                correspondences,
                "fileURL"
              );
              const correspondenceForms = _.map(
                _.sortBy(filteredCorrespondences, "createdAt"),
                ({ fileURL, createdAt }) => ({
                  url: fileURL,
                  title: `Authorization Correspondence - ${moment(
                    createdAt
                  ).format(DATE_FORMAT)}`,
                })
              );
              const submittedAuth = !submittedFormURL
                ? []
                : [
                    {
                      title: `Submitted authorization - ${moment(
                        submittedAt
                      ).format(DATE_FORMAT)}`,
                      url: submittedFormURL,
                    },
                  ];
              const links = [...submittedAuth, ...correspondenceForms];

              if (_.isEmpty(links)) {
                return <div>There are no forms to download at this time</div>;
              }
              return (
                <LinkContainer>
                  {_.map(links, (form) => (
                    <a
                      style={{ margin: "8px 0" }}
                      key={`authorization_${authorizationId}_form_${form.title}`}
                      href={form.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {form.title}
                    </a>
                  ))}
                </LinkContainer>
              );
            }}
          </Query>
        )}
      </ModalContent>
      <CustomModalFooter>
        <GrayButton onClick={onClick}>Close</GrayButton>
      </CustomModalFooter>
    </ModalBody>
  </Modal>
);

/**
 * @deprecated Use a MUI component instead
 */
export default DownloadFormsButton;
