import styled from "styled-components";
import { compose } from "recompose";
import { MdFavorite as FavoriteIcon } from "@react-icons/all-files/md/MdFavorite";
import { MdFavoriteBorder as EmptyFavoriteIcon } from "@react-icons/all-files/md/MdFavoriteBorder";
import { withSetFavorite, withDeleteFavorite } from "../graphql/Favorite";

const FavoriteIconStyled = styled(({ isFavorite, isSimplifiedUx, ...rest }) => (
  <FavoriteIcon {...rest} />
))`
  display: ${({ isFavorite }) => (isFavorite ? "inline" : "none")};
  color: ${(props) => props.theme.purple};
  margin: ${(props) => (props.isSimplifiedUx ? "0px" : "0px 3px 2px 0px")};
  height: ${(props) => (props.isSimplifiedUx ? "24px" : "20px")};
  width: ${(props) => (props.isSimplifiedUx ? "24px" : "20px")};
  cursor: pointer;
`;
const EmptyFavoriteIconStyled = styled(
  ({ isFavorite, isSimplifiedUx, ...rest }) => <EmptyFavoriteIcon {...rest} />
)`
  display: ${({ isFavorite }) => (isFavorite ? "none" : "inline")};
  color: ${(props) => props.theme.purple};
  margin: ${(props) => (props.isSimplifiedUx ? "0px" : "0px 3px 2px 0px")};
  height: ${(props) => (props.isSimplifiedUx ? "24px" : "20px")};
  width: ${(props) => (props.isSimplifiedUx ? "24px" : "20px")};
  cursor: pointer;
`;

const IconWrapper = styled(({ isFavorite, ...rest }) => <span {...rest} />)`
  &:hover {
    ${FavoriteIconStyled} {
      display: ${({ isFavorite }) => (isFavorite ? "none" : "inline")};
    }
    ${EmptyFavoriteIconStyled} {
      display: ${({ isFavorite }) => (isFavorite ? "inline" : "none")};
    }
  }
`;

const Favorite = ({
  isFavorite,
  setFavorite,
  deleteFavorite,
  formId,
  numberId,
  isSimplifiedUx,
}) => {
  const handleClick = () => {
    const variables = {
      authorizationFormId: formId,
      formNumberId: numberId,
    };
    if (isFavorite) {
      deleteFavorite({ variables });
    } else {
      setFavorite({ variables });
    }
  };

  return (
    <IconWrapper
      isFavorite={isFavorite}
      data-cy={
        isFavorite ? "actionDeleteFavoriteForm" : "actionSetFavoriteForm"
      }
      onClick={handleClick}
    >
      <FavoriteIconStyled
        isFavorite={isFavorite}
        isSimplifiedUx={isSimplifiedUx}
      />
      <EmptyFavoriteIconStyled
        isFavorite={isFavorite}
        isSimplifiedUx={isSimplifiedUx}
      />
    </IconWrapper>
  );
};

export default compose(withSetFavorite, withDeleteFavorite)(Favorite);
