import { Box, BoxProps } from "@samacare/design";

export const LeftRightCenterV: React.FC<BoxProps> = ({ sx, children }) => (
  <Box display="flex" alignItems="center" sx={sx}>
    {children}
  </Box>
);

export const LeftRightCenterAll: React.FC<BoxProps> = ({ sx, children }) => (
  <Box display="flex" alignItems="center" justifyContent="center" sx={sx}>
    {children}
  </Box>
);

export const LeftRightSpreadCenterV: React.FC<BoxProps> = ({
  sx,
  children,
}) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    sx={sx}
  >
    {children}
  </Box>
);
