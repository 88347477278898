import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { BaseText } from "Segment/StyledComponents";
import Select from "react-select";
import { PrimaryButton, useToast } from "@@ui-kit";

import Upload from "../Upload";
import Modal from "../Modal";

import { ModalContent } from "../ModalStyledComponents";

const ContentContainer = styled(ModalContent)`
  padding: 10px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
`;
const Spacer = styled.div`
  margin: 5px 0;
`;

const userReportedErrorTypes = [
  {
    value: 1,
    label: "Tagged Incorrectly",
    showUpload: false,
    showFreeText: true,
  },
  {
    value: 2,
    label: "This is an outdated form",
    showUpload: true,
    showFreeText: false,
  },
  { value: 3, label: "Other", showUpload: false, showFreeText: true },
];

const reportErrorGQL = gql`
  mutation reportTaggingError($title: String, $message: String) {
    reportError(title: $title, message: $message)
  }
`;

export const TaggerErrorModal: React.VoidFunctionComponent<{
  form: { description: string; title: string };
  close: () => void;
  reportErrorUserDetails: string;
}> = ({ form, close, reportErrorUserDetails }) => {
  const defaultError = {
    value: 0,
    label: "",
    showUpload: false,
    showFreeText: false,
  };
  const [reportErrorMutation] = useMutation(reportErrorGQL);
  const toast = useToast();
  const [errorType, setErrorType] = useState(defaultError);
  const [errorNote, setErrorNote] = useState("");
  const [uploadedFormKey, setUploadedFormKey] = useState("");

  const submitError = useCallback(async () => {
    if (errorType.value === defaultError.value) {
      toast.negative("Please select a type of error to report");
    } else {
      try {
        await reportErrorMutation({
          variables: {
            title: "Form error",
            message: _.compact([
              `User has reported an error with the form titled: ${form.title}`,
              `description: ${form.description}`,
              `They have reported that: ${errorType.label} `,
              errorNote !== "" &&
                `They have provided the freeform note: ${errorNote}`,
              uploadedFormKey !== "" &&
                `The user has loaded an example form with key: ${uploadedFormKey}. Please ask an engineer to retrieve the file for you`,
              `The user is: ${reportErrorUserDetails}`,
            ]).join("<br/>"),
          },
        });
        toast.info("Error reported");
      } catch {
        toast.negative("There was an error reporting your error");
      }
      setErrorType(defaultError);
      close();
    }
  }, [
    reportErrorMutation,
    setErrorType,
    close,
    defaultError,
    form,
    errorNote,
    uploadedFormKey,
    reportErrorUserDetails,
    toast,
    errorType,
  ]);

  const errorOptions = userReportedErrorTypes.map((config) => {
    return {
      value: config.value,
      label: config.label,
    };
  });

  return (
    <Modal
      styleOverride={{ overflowY: "visible" }}
      header="Report Error"
      onClick={close}
      open
    >
      <ContentContainer>
        <Select
          isClearable
          isMulti={false}
          name="reportedError"
          onChange={(option) => {
            if (option != null) {
              const reportedType = userReportedErrorTypes.find(
                (config) => config.value === option.value
              );
              if (reportedType != null) {
                setErrorType(reportedType);
              }
            } else {
              setErrorType(defaultError);
            }

            setErrorNote("");
            setUploadedFormKey("");
          }}
          options={errorOptions}
          value={{ value: errorType.value, label: errorType.label }}
        />
        <Spacer />
        {errorType.showFreeText && (
          <>
            <BaseText
              value={errorNote}
              onChange={(e) => {
                setErrorNote(e.target.value);
              }}
              placeholder="Please add details..."
              style={{ marginLeft: 0 }}
            />
            <Spacer />
          </>
        )}
        {errorType.showUpload && (
          <>
            <div style={{ marginBottom: "10px" }}>
              Please upload the most recent copy of the form if you have one
            </div>
            <Upload
              fullWidth
              onDropError={() =>
                toast.negative("There was an error uploading your file")
              }
              onDropSuccess={async (x, res) => {
                const key = (res.data?.fields?.key as string) || null;
                if (key == null) {
                  toast.negative("There was an error uploading your file");
                  return;
                }
                setUploadedFormKey(key);
                toast.info("File uploaded");
              }}
            />
            {uploadedFormKey !== "" && (
              <div>
                File Uploaded! Uploading another file will override your
                previous upload
              </div>
            )}
            <Spacer />
          </>
        )}
        <Spacer />
        <PrimaryButton
          style={{ marginTop: "auto" }}
          key="reportError"
          fluid
          onClick={submitError}
        >
          Report
        </PrimaryButton>
      </ContentContainer>
    </Modal>
  );
};
