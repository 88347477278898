import { FC } from "react";
import { Box, Typography, useTheme } from "@samacare/design";
import Add from "@samacare/design/core/icons/Add";

export type AddListItemProps = React.HTMLAttributes<HTMLLIElement> & {
  label: string;
};

export const AddListItem: FC<AddListItemProps> = (props) => {
  const { label, ...otherProps } = props;
  const theme = useTheme();
  return (
    <Box
      component="li"
      {...otherProps}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Add fontSize="small" sx={{ marginRight: 1 }} color="primary" />
      <Typography variant="body1" color={theme.palette.primary.main}>
        {label}
      </Typography>
    </Box>
  );
};
