import { Button, LoadingButton } from "@samacare/design/core";
import Dialog from "@samacare/design/core/Dialog";
import DialogActions from "@samacare/design/core/DialogActions";
import DialogContent from "@samacare/design/core/DialogContent";
import DialogContentText from "@samacare/design/core/DialogContentText";

export const ConfirmationDialog = ({
  open,
  onClose,
  formName,
  children = "Confirm submission?",
  backButtonText = "Cancel",
  confirmButtonText = "Confirm",
  loading = false,
  onConfirm = undefined,
}: {
  open: boolean;
  onClose: () => void;
  formName?: string;
  children?: React.ReactNode;
  backButtonText?: string;
  confirmButtonText?: string;
  loading?: boolean;
  onConfirm?: () => void;
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth={true}>
      <DialogContent>
        <DialogContentText id="dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: ({ spacing }) => spacing(2) }}>
        <Button
          data-cy="actionBack"
          variant="outlined"
          disabled={loading}
          onClick={onClose}
        >
          {backButtonText}
        </Button>
        <LoadingButton
          data-cy="actionConfirm"
          variant="contained"
          form={formName}
          type="submit"
          onClick={onConfirm}
          loading={loading}
        >
          {confirmButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
