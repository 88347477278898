import { useState } from "react";
import * as React from "react";
import styled from "styled-components";

import Modal from "../../../components/Modal";
import CorrespondenceViewer from "../../../components/CorrespondenceViewer";
import { PrimaryButton } from "@@ui-kit";
import AssociationModal from "../AssociationModal";
import { HCPResponseModal } from "../HCPResponseModal";

const TwoPaneContainer = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const AuthorizationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 80%;
  height: 100px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

export type AssociationViewRouterProps = {
  onClose: () => void;
  correspondence: { id: number; originatingFaxNumber: string };
  setCorrespondenceOnAuthorization: () => Promise<void>;
  currentAuthorization: {
    type: string;
    submissionPhoneNumber: string;
    id: number;
  };
};

const AssociationViewRouter: React.VoidFunctionComponent<
  AssociationViewRouterProps
> = ({
  onClose,
  correspondence,
  setCorrespondenceOnAuthorization,
  currentAuthorization,
}) => {
  /*
   * ViewRouter workflow:
   * Auth for Novartis -> selectType view => association view (from payer)
   *                                   OR => hcpResponse view (from HCP)
   * Auth for all other institution => association view
   *
   * There are three view options:
   *
   * 1. selectType View: For Novartis only. This view is for our support team to identify where
   *    the response is coming from
   * 2. hcpResponse View: For Novartis only. After our support team identify the response
   *    is from a HCP(health care provider, rather than a payer), we will render hcpResponse
   *    view
   * 3. association View: The current auth is not Novartis related OR after the selectType View,
   *    our support team identify this auth is from a payer
   */
  const { submissionPhoneNumber, id } = currentAuthorization;
  const faxNumber = submissionPhoneNumber;
  // Always selecting association - currently never allowing end users to see inbound Novartis faxes from doctor office
  const [view, setView] = useState("association");

  if (view === "selectType") {
    return (
      <Modal onClick={onClose} open header="">
        <TwoPaneContainer>
          <CorrespondenceViewer
            correspondence={correspondence}
            width="700px"
            height="550px"
          />
          <AuthorizationDetailsContainer>
            <h1>Who is this response from?</h1>
            <ButtonWrapper>
              <PrimaryButton
                onClick={() => {
                  setView("hcpResponse");
                }}
              >
                HCP
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  setView("association");
                }}
              >
                Payer
              </PrimaryButton>
            </ButtonWrapper>
          </AuthorizationDetailsContainer>
        </TwoPaneContainer>
      </Modal>
    );
  } else if (view === "hcpResponse") {
    return (
      <HCPResponseModal
        onClose={onClose}
        correspondence={correspondence}
        faxNumber={faxNumber}
        authorizationId={id}
        setCorrespondenceOnAuthorization={setCorrespondenceOnAuthorization}
      />
    );
  }
  return (
    <AssociationModal
      onClose={onClose}
      authorizationId={id}
      correspondence={correspondence}
      setCorrespondenceOnAuthorization={setCorrespondenceOnAuthorization}
    />
  );
};

export default AssociationViewRouter;
