import { Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

export const AlertProvider: React.FC = ({ children }) => {
  return (
    <Provider
      template={AlertTemplate}
      {...{
        position: "top right",
        timeout: 10_000,
        offset: "30px",
        transition: "scale",
        containerStyle: {
          zIndex: "2147483647",
        },
      }}
    >
      {children}
    </Provider>
  );
};
