import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { ReactTooltip } from "@@ui-kit/ReactTooltip";
import _ from "lodash";

import { BaseInput, getPropsFromInput } from "./BaseInput";
import { TextInputProps } from "./types";
import strings from "../../../../resources/strings";

const InputOption = styled.div`
  background-color: ${(props) => props.theme.disabledGray};
  transform: scale(1.1);

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.lightGray};
  }
`;

interface InputOption {
  value: string;
  label: string;
}

const formatInputOptionLabel = (key: string): string =>
  _.startCase((key ?? "").split("_")[0].toLowerCase());

const TextSelect: React.VFC<{
  input: TextInputProps;
  results: Record<string, string>;
  groupedKeys: Record<string, string>;
  inputType: string;
}> = ({ input, results, groupedKeys, inputType }) => {
  const [isValuePicked, setIsValuePicked] = useState<boolean>(false);
  const [inFocus, setInFocus] = useState<boolean>(false);
  const [inputOptions, setInputOptions] = useState<InputOption[]>([]);
  const [shouldShowOptions, setShouldShowOptions] = useState<boolean>(false);

  const isOptionInInputOptions = useCallback(
    (option: InputOption) => {
      return option.value && inputOptions.some((o) => o.value === option.value);
    },
    [inputOptions]
  );

  const getInputOption = useCallback(
    (option: string, label: string): InputOption => {
      return {
        value: option,
        label,
      };
    },
    []
  );

  useEffect(() => {
    const { samaTypes } = input;
    let hasMatchingProvider = false;
    const providerInputOptionKey = samaTypes[0];
    const providerInputOptionLabel = formatInputOptionLabel(
      providerInputOptionKey
    );
    let providerInputOption: InputOption;
    let matchingProviderInputOption: InputOption;

    // We handle name fields separately since we concatenate the first and last name
    if (
      samaTypes.filter((samaType) => samaType.toLowerCase().includes("name"))
        .length > 1
    ) {
      const firstNameKey =
        samaTypes.find((samaType) => samaType.includes("FIRST_NAME")) ?? "";
      const lastNameKey =
        samaTypes.find((samaType) => samaType.includes("LAST_NAME")) ?? "";
      const matchingProviderFirstNameKey = groupedKeys[firstNameKey];
      const matchingProviderLastNameKey = groupedKeys[lastNameKey];
      const inputOptionLabel = formatInputOptionLabel(
        matchingProviderFirstNameKey
      );
      providerInputOption = getInputOption(
        `${results[firstNameKey]} ${results[lastNameKey]}`,
        providerInputOptionLabel
      );

      hasMatchingProvider =
        Boolean(results[matchingProviderFirstNameKey]) &&
        Boolean(results[matchingProviderLastNameKey]);
      matchingProviderInputOption = getInputOption(
        `${results[matchingProviderFirstNameKey]} ${results[matchingProviderLastNameKey]}`,
        inputOptionLabel
      );
    } else {
      providerInputOption = getInputOption(
        results[providerInputOptionKey],
        providerInputOptionLabel
      );
      const key = samaTypes[0];
      const matchingProviderKey = groupedKeys[key];
      const inputOptionLabel = formatInputOptionLabel(matchingProviderKey);

      hasMatchingProvider = Boolean(results[matchingProviderKey]);
      matchingProviderInputOption = getInputOption(
        results[matchingProviderKey],
        inputOptionLabel
      );
    }

    // Sometimes referring provider will be tagged as provider with the sama type
    // This check will ensure that both options are added to input options
    if (hasMatchingProvider) {
      if (isOptionInInputOptions(matchingProviderInputOption) === false) {
        setInputOptions((prev) => [...prev, matchingProviderInputOption]);
      }

      // This logic should only fire initially before the user has selected an input option or started
      // typing in a value to populate the input options
      if (
        isOptionInInputOptions(providerInputOption) === false &&
        !isValuePicked
      ) {
        setInputOptions((prev) => [...prev, providerInputOption]);
      }
    }

    if (inputOptions.length > 1) {
      if (!isValuePicked) {
        // If the user has not yet selected an option, and there are 2 or more options, leave the field blank.
        // This will force the user to select a value so they don't skim the form and leave an incorrect value
        // as autofilled in the form
        input.onChange({ [input.id]: "" });
      }

      setShouldShowOptions(true);
    }
  }, [
    input.samaTypes,
    input,
    inputOptions,
    getInputOption,
    isOptionInInputOptions,
    results,
    groupedKeys,
    isValuePicked,
  ]);

  const getInputValue = () => {
    if (!isValuePicked && inputOptions.length > 1) {
      return "";
    }
    if (input.value) {
      return input.value;
    }
    return "";
  };

  return (
    <div
      style={{
        position: "absolute",
        left: input.left,
        top: input.top,
        zIndex: inFocus ? 1 : 0,
      }}
      onBlur={() => {
        setInFocus(false);
      }}
    >
      <BaseInput
        type={inputType ?? "text"}
        {..._.omit(getPropsFromInput(input), ["top", "left"])}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (input.characterBoxes != null) {
            // validate character boxes if necessary
            e.target.setCustomValidity(
              e.target.value.length > input.characterBoxes
                ? strings.PDF_EDITOR.CHAR_BOX_ERROR(input.characterBoxes)
                : ""
            );
          }
          input.onChange({ [input.id]: e.target.value });
          setIsValuePicked(true);
        }}
        onFocus={() => setInFocus(true)}
        value={getInputValue()}
        data-tooltip-id={`input_${input.id}_tooltip`}
      />
      {inFocus &&
        shouldShowOptions &&
        inputOptions.map((option, idx) => {
          return (
            <InputOption
              key={option.value}
              id={option.value}
              style={{
                height: "24px",
                paddingTop: "2px",
                marginTop: idx === 0 ? "24px" : "0px",
                paddingLeft: "5px",
                width: getPropsFromInput(input).width * 1.15,
              }}
              // We use onMouseDown instead of onClick because when the user clicks on one of the
              // options, the onBlur event happens before onClick, so the drop down goes away
              // without firing onClick. onMouseDown happens before onBlur, which avoids this.
              onMouseDown={(e) => {
                e.stopPropagation(); // We stop event propagation because onBlur doesn't need to fire
                input.onChange({ [input.id]: option.value });
                setIsValuePicked(true);
              }}
            >
              {`${option.value} - ${option.label}`}
            </InputOption>
          );
        })}
      {input.characterBoxes != null &&
        input.value &&
        input.value.length > input.characterBoxes && (
          <ReactTooltip id={`input_${input.id}_tooltip`}>
            {strings.PDF_EDITOR.CHAR_BOX_ERROR(input.characterBoxes)}
          </ReactTooltip>
        )}
    </div>
  );
};

export default TextSelect;
