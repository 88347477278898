import { Box } from "@samacare/design";

export const SimpleSeparator = () => (
  <Box
    sx={{
      height: "1px",
      width: "100%",
      backgroundColor: ({ palette }) => palette.neutral.main,
      marginTop: ({ spacing }) => spacing(2),
      marginBottom: ({ spacing }) => spacing(2),
    }}
  />
);
