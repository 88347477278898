import gql from "graphql-tag";
import authorizationInfo from "./fragments/authorizationInfo";
import portalInformation from "./fragments/portalInformation";

export const supportAuthorizationFollowUpsQuery = gql`
  query getUnresolvedFollowUps(
    $pageNum: Int!
    $institutionId: Int
    $PortalId: Int
  ) {
    getUnresolvedFollowUps(
      pageNum: $pageNum
      institutionId: $institutionId
      PortalId: $PortalId
    ) {
      followUps {
        createdAt
        madeActiveAt
        id
        AuthorizationId
        screenshotUrl
        authorization {
          ...authorizationInfo
          trackingStatus
          institution {
            id
            name
            practicingSpecialty
          }
          portal {
            ...portalInformation
          }
        }
      }
      totalCount
    }
  }
  ${authorizationInfo}
  ${portalInformation}
`;

export const checkOnFailedStatusCheckMutation = gql`
  mutation checkOnFailedStatusCheck(
    $authId: Int!
    $foundStatus: String
    $statusNotFoundReason: String
    $portalAuthorizationId: String
    $newNoteText: String
    $isAddScreenshotAsCorrespondence: Boolean
    $awsKeysForNewCorrespondences: [String!]
  ) {
    checkOnFailedStatusCheck(
      authId: $authId
      foundStatus: $foundStatus
      statusNotFoundReason: $statusNotFoundReason
      portalAuthorizationId: $portalAuthorizationId
      newNoteText: $newNoteText
      isAddScreenshotAsCorrespondence: $isAddScreenshotAsCorrespondence
      awsKeysForNewCorrespondences: $awsKeysForNewCorrespondences
    )
  }
`;
