// @ts-expect-error(ndhoule): Remove when global.CONFIG is typed
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const DEFAULT_PDF_WIDTH = CONFIG.CONSTANTS.PDF_STANDARD_DIMENSIONS
  .width as number;

// @ts-expect-error(ndhoule): Remove when global.CONFIG is typed
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const DEFAULT_PDF_HEIGHT = CONFIG.CONSTANTS.PDF_STANDARD_DIMENSIONS
  .height as number;

// @ts-expect-error(ndhoule): Remove when global.CONFIG is typed
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const PDF_SCALE_MULTIPLIER = CONFIG.CONSTANTS
  .SCALE_PDF_DISPLAY_MULTIPLIER as number;

export default {
  font_size: "16px",
  pdfWidth: `${(DEFAULT_PDF_WIDTH * PDF_SCALE_MULTIPLIER).toString()}px`,
  pdfHeight: `${(DEFAULT_PDF_HEIGHT * PDF_SCALE_MULTIPLIER).toString()}px`,
  zDefault: 0,
  zLow: 1,
  zMiddle: 2,
  zUpper: 3,
  zTop: 4,
  zTopPlus1: 5,
  zTopPlus2: 6,
  zTopPlus3: 7,
  zHelpWidget: 1050,
  zHelpWidgetPlus1: 1051,
  zMax: 2147483647,
};
