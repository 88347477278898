import * as React from "react";
import styled from "styled-components";

export type FieldsetProps = React.ComponentProps<typeof Fieldset>;

/**
 * Fieldset is a component used to group multiple controls and labels within a
 * form.
 *
 * ## When to use:
 *
 * When you're creating a form that has multiple logically grouped inputs for a
 * single value; e.g. a set of radio buttons, multiple checkboxes for a single
 * value.
 *
 * @example
 * ```tsx
 * import { Fieldset, Label, Radio } from "@@ui-kit/forms";
 *
 * // ...
 *
 * <form>
 *   <Fieldset error={hasError}>
 *     <legend>How often do you eat burritos?</legend>
 *
 *     <Label>
 *       <Radio value="daily" />
 *       Daily
 *     </Label>
 *
 *     <Label>
 *       <Radio value="weekly" />
 *       Weekly
 *     </Label>
 *
 *     <Label>
 *       <Radio value="monthly" />
 *       Monthly
 *     </Label>
 *   </Fieldset>
 * </form>
 * ```
 */
/**
 * @deprecated Use component from design/forms instead
 */
export const Fieldset = styled.fieldset<{
  /**
   * Whether or not to render the component in an error state.
   */
  error?: boolean;
}>`
  /* Reset default fieldset styles */
  border: none;
  padding: 0;

  border-color: ${({ error = false, theme }) =>
    error ? theme.red : undefined};
  border-radius: ${({ error = false }) => (error ? "3px" : undefined)};
`;
