import React from "react";

import { Stack, Typography } from "@samacare/design/core";

import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import { BenefitsCheckBenefitDetails } from "./BenefitsCheckBenefitDetails";

export const BenefitsCheckLiteDetails: React.FC = () => {
  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();

  if (!unifiedBenefitsCheck) {
    return null;
  }

  return (
    <Stack>
      <Stack direction="row" display="flex">
        <Typography
          fontSize={12}
          lineHeight={2}
          marginLeft={1}
          color="rgba(29, 36, 51, 0.65);"
        >
          *Insurance info provided via Availity
        </Typography>
      </Stack>
      <Stack direction="column" marginTop={2}>
        <BenefitsCheckBenefitDetails
          name="Professional (Physician) Visit - Office"
          defaultExpanded
        />
        <BenefitsCheckBenefitDetails name="Health Benefit Plan Coverage" />
      </Stack>
    </Stack>
  );
};
