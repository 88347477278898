import { IntegrationInstallation } from "@samacare/graphql";
import AlertTitle from "@samacare/design/core/AlertTitle";
import moment from "moment";
import { Box, Alert } from "@samacare/design/core";

export const DocumentUploadStatus: React.VoidFunctionComponent<{
  integrationInstallation: IntegrationInstallation | undefined;
}> = ({ integrationInstallation }) => {
  const shouldShowSuccessAlert =
    integrationInstallation?.isEnabled &&
    integrationInstallation?.documentUploadLastSuccessfullyCheckedAt;

  return (
    <Box width={({ spacing }) => spacing(45)}>
      {shouldShowSuccessAlert ? (
        <Alert severity="success">
          <AlertTitle>Healthy document upload</AlertTitle>
          <p>{`Last successfully checked ${moment(
            integrationInstallation?.documentUploadLastSuccessfullyCheckedAt
          ).format("MM/DD/YYYY")}`}</p>
          <p>Please check again to test current functionality</p>
        </Alert>
      ) : (
        <Alert severity="warning">
          <AlertTitle>Unknown document upload status</AlertTitle>
        </Alert>
      )}
    </Box>
  );
};
