import _ from "lodash";

const types = CONFIG.CONSTANTS.TAGGER_INPUT_TYPES;

const getValidationError = (nonFlattenedInput) => {
  if (nonFlattenedInput.type === types.RADIO_GROUP.key) {
    if (
      !_.every(nonFlattenedInput.items, (item) =>
        _.includes([types.RADIO.key, types.RADIO_CIRCLE.key], item.type)
      )
    ) {
      return "Radio Group only supports radio children";
    }
    if (nonFlattenedInput.boxes.length > 0) {
      return "Radio Group does not support boxes";
    }
  }

  if (nonFlattenedInput.type === types.DATE3.key) {
    if (nonFlattenedInput.boxes.length !== 3) {
      return "3 part date expects 3 boxes";
    }
  }

  if (nonFlattenedInput.type === types.PHONE2.key) {
    if (nonFlattenedInput.boxes.length !== 2) {
      return "2 part phone expects 2 boxes";
    }
  }

  if (nonFlattenedInput.type === types.PHONE3.key) {
    if (nonFlattenedInput.boxes.length !== 3) {
      return "3 part phone expects 3 boxes";
    }
  }

  return null;
};

export default getValidationError;
