import styled from "styled-components";
import RequiredTag from "../RequiredTag";

const Wrapper = styled.div`
  color: ${(props) => props.theme.purple};
  border-bottom: 1px solid #cccccc;
  padding: $7px;
  font-weight: 700;
  font-size: 18px;
  margin-top: 20px;
  width: 100%;
`;

interface SegmentTitleProps {
  showRequiredTag?: boolean;
  style?: React.CSSProperties;
}
export const SegmentTitle: React.FC<SegmentTitleProps> = (props) => (
  <Wrapper style={props.style}>
    {props.children}
    {props.showRequiredTag ? <RequiredTag /> : null}
  </Wrapper>
);
