import * as React from "react";
import styled from "styled-components";

import BaseButton from "../../../components/BaseButton";
import Modal from "@@components/Modal";
import {
  ModalBody,
  ModalFooter,
  ModalContent,
} from "../../../components/ModalStyledComponents";

const CustomModalFooter = styled(ModalFooter)`
  justify-content: space-between;
  ${BaseButton} {
    margin-top: 10px;
    width: 100%;
    height: 40px;
  }
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;
`;

const ChangeFormModal: React.VoidFunctionComponent<{
  open: boolean;
  close(): void;
  selectNewWorkflow(): void;
  useExistingWorkflow(): void;
}> = ({ open, close, selectNewWorkflow, useExistingWorkflow }) => (
  <Modal header="Change Form" open={open} onClick={close}>
    <ModalBody>
      <CustomModalContent>
        Would you like to select a form for a new insurance company or different
        drug?
      </CustomModalContent>
      <CustomModalFooter>
        <BaseButton
          style={{ marginRight: "4px", width: "100%" }}
          onClick={useExistingWorkflow}
        >
          No
        </BaseButton>
        <BaseButton
          style={{ marginLeft: "4px", width: "100%" }}
          onClick={selectNewWorkflow}
        >
          Yes
        </BaseButton>
      </CustomModalFooter>
    </ModalBody>
  </Modal>
);

export default ChangeFormModal;
