import _ from "lodash";

interface ResType {
  data?: {
    loading?: boolean;
    error?: boolean;
    refetch?: () => void;
  };
}
export function withDefaultProps(cb: (res: ResType) => any) {
  return (res: ResType): any => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const props = cb(res);

    // typecasting to avoid logic changes resulting in downstream errors
    if (_.get(res, "data.loading") as boolean) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      props.loading = true;
    }
    // typecasting to avoid logic changes resulting in downstream errors
    if (_.get(res, "data.error") as boolean) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      props.error = true;
    }
    // typecasting to avoid logic changes resulting in downstream errors
    if (_.get(res, "data.refetch") as boolean) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      props.refetch = res.data!.refetch;
    }
    return props;
  };
}
