import { useMemo } from "react";
import { Stack } from "@samacare/design/core";
import Chip from "@samacare/design/core/Chip";
import { Authorization } from "@samacare/graphql";

interface TagsListProps {
  auth: Authorization;
  onClick: () => void;
}
export const TagsList: React.VFC<TagsListProps> = (props) => {
  const sortedTagValues = useMemo(
    () => props.auth.tags.map((t) => t.value).sort(),
    [props.auth.tags]
  );
  return (
    <Stack
      direction="row"
      gap={1}
      padding={1}
      borderRadius={2}
      flexWrap="wrap"
      justifyContent="flex-end"
      sx={{
        cursor: "pointer",
        transition: "background-color 0.1618s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.grey[200],
        },
      }}
      onClick={props.onClick}
    >
      {sortedTagValues.map((tagValue) => (
        <Chip
          key={tagValue}
          label={tagValue}
          size="small"
          sx={{ cursor: "pointer" }}
        />
      ))}
    </Stack>
  );
};
