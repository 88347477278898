import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import { Box, Button, LoadingButton, Stack } from "@samacare/design/core";
import { useForm, FormProvider, PatientBlock } from "@samacare/form";
import { BenefitsCheckOptionsField } from "../components";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";
import { SelectBenefitVerificationTypeDataType } from "../types";
import { UnifiedBenefitsCheckType } from "@samacare/graphql";

type BenefitsVerificationServiceAndPatientSelectionFormProps = {
  disabled?: boolean;
  defaultValues: SelectBenefitVerificationTypeDataType;
  onSubmit: (data: SelectBenefitVerificationTypeDataType) => void;
};

export const BenefitsVerificationServiceAndPatientSelectionForm: React.VoidFunctionComponent<
  BenefitsVerificationServiceAndPatientSelectionFormProps
> = ({ defaultValues, onSubmit, disabled }) => {
  const history = useHistory();
  const methods = useForm<SelectBenefitVerificationTypeDataType>({
    defaultValues,
  });

  const { watch, getValues, setValue } = methods;

  const { PatientId, DrugOptionId, patient } = watch();

  return (
    <FormProvider {...methods}>
      <form
        id="BenefitsVerificationType"
        name="BenefitsVerificationType"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack direction="column">
          <EnrollmentSection title="Patient">
            <PatientBlock required disabled={disabled} />
          </EnrollmentSection>
          <Box sx={{ width: "70%" }}>
            <EnrollmentSection title="Benefit Verification Services">
              <BenefitsCheckOptionsField
                typeFieldName="benefitsCheckType"
                drugOptionIdFieldName="DrugOptionId"
                data-cy="selectBenefitsVerificationType"
              />
            </EnrollmentSection>
          </Box>
          <Box display="flex" justifyContent="space-between" py={10}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              onClick={() => {
                history.push(ROUTE_PATHS.BENEFITS_VERIFICATIONS.path);
              }}
            >
              Back
            </Button>
            <Stack direction="row" spacing={1}>
              <Button
                variant="text"
                type="button"
                onClick={() => {
                  setValue("DrugOptionId", undefined);
                  setValue("benefitsCheckType", UnifiedBenefitsCheckType.Lite);
                  onSubmit(getValues());
                }}
                disabled={PatientId == null && _.isEmpty(patient)}
              >
                Continue without Drug
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                data-cy="actionBenefitsVerificationNextStep"
                disabled={
                  DrugOptionId == null ||
                  (PatientId == null && _.isEmpty(patient))
                }
              >
                Continue
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
};
