import { PureComponent } from "react";
import { connect } from "react-redux";

import { SocialCleave } from "./BaseComponents";

class SegmentSocial extends PureComponent {
  setNumber = (e) => {
    const { handleChange, item } = this.props;
    handleChange({ [item.key]: e.target.rawValue });
  };

  render() {
    const { result, disabled, highlightIfMissing } = this.props;
    return (
      <SocialCleave
        highlight={highlightIfMissing && !result}
        disabled={disabled}
        onChange={this.setNumber}
        number={result || ""}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return { result: props.override || state.form.results[props.item.key] };
}

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentSocial);
