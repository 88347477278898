import { useLocation } from "react-router-dom";

import useEnrollmentProgramOptions from "./useEnrollmentProgramOptions";

import type { EnrollmentProgram } from "@samacare/graphql";

const useEnrollmentProgramInUrl = (): EnrollmentProgram | null => {
  const location = useLocation();
  const programId = new URLSearchParams(location.search).get("programId");
  const { enrollmentPrograms, loading, error } = useEnrollmentProgramOptions();
  if (error || loading || programId == null) {
    return null;
  }

  return (
    enrollmentPrograms?.find(
      (program: EnrollmentProgram) => program.id === programId
    ) ?? null
  );
};

export default useEnrollmentProgramInUrl;
