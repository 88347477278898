import _ from "lodash";
import moment from "moment";

import { BenefitsVerificationOutcomeDrugBuyBillStatus } from "@samacare/graphql";

import {
  DATE,
  DROPDOWN,
  ID,
  NUMBER,
  PHONE,
  TEXT,
  TIMESTAMP,
} from "./BenefitsVerificationConstants";
import { humanizeBoolean } from "./humanizers";
import { Option } from "./interfaces";

export const humanizeDropdownValue = (data: string): string => {
  if (data === BenefitsVerificationOutcomeDrugBuyBillStatus.Medical.valueOf())
    return "Medical/Buy and Bill";

  return _.startCase(_.toLower(data));
};

export const humanizeBVOutcomeValue = (
  data: string | number | boolean | null,
  editType: string | null
): string => {
  if (data == null) return "N/A";

  switch (editType) {
    case ID:
      return String(data).slice(-5);
    case TIMESTAMP:
      return moment(String(data)).format("L LT");
    case NUMBER:
      return (data as number).toLocaleString();
    case DROPDOWN:
      return _.isBoolean(data)
        ? humanizeBoolean(data)
        : humanizeDropdownValue(data as string);
    case PHONE:
      return `${(data as string).slice(0, 3)}-${(data as string).slice(
        3,
        6
      )}-${(data as string).slice(6, 10)}`;
    case DATE:
      return moment(String(data)).format("L");
    case null:
    case TEXT:
      return String(data);
    default:
      return "N/A";
  }
};

export const getStateOptions = (statesConfigObj: {
  [key: string]: string;
}): Option[] =>
  _.map(statesConfigObj, (state: string, abbr: string) => ({
    value: abbr,
    label: state,
  }));

export const formatZip = (value: string): string => {
  if (value.length === 9) {
    return `${value.slice(0, 5)}-${value.slice(5)}`;
  }
  return value;
};

export const humanizeDbValue = (
  value: string | null | boolean | undefined | number | string[]
): string => {
  if (value == null || value === "") return "Unknown";

  if (typeof value === "number") return value.toString();
  if (typeof value === "boolean") return humanizeBoolean(value);
  if (_.isArray(value)) return value.length > 0 ? value.join(", ") : "Unknown";

  return value;
};

export const getLastUpdatedLabel = (value?: string | null) => {
  if (value == null) {
    return "-";
  }
  const parsed = moment(value);
  if (!parsed.isValid()) {
    return "-";
  }
  return parsed.format("MM/DD/YYYY HH:mmA");
};
