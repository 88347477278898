import MuiPopper from "@samacare/design/core/Popper";
import IconButton from "@samacare/design/core/IconButton";
import { useRef, useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { LeftRightCenterV } from "../app/components/LeftRight";
import { OutsideAlerter } from "../app/components/OutsideAlerter";

const Popped = styled(LeftRightCenterV)`
  padding: 16px;
  background: ${(props) => props.theme.white};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

/**
 * @deprecated Use a MUI component instead
 */
export const IconPopper: React.FC<{
  target: React.ReactNode;
  targetAriaLabel: string;
}> = ({ children, target, targetAriaLabel }) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const popperTargetRef = useRef<HTMLDivElement | null>(null);
  const disablePopperClick = useRef(false);

  const isOpen = Boolean(anchorEl);
  const popperId = isOpen ? "simple-popper" : undefined;

  const handleClick = () => {
    // Needed to put a throttle in since I couldn't get the OutsideAlerter to work
    // when around the target as well.
    if (!disablePopperClick.current) {
      setAnchorEl(anchorEl ? null : popperTargetRef.current);
      if (!disablePopperClick.current) {
        disablePopperClick.current = true;
        setTimeout(() => {
          disablePopperClick.current = false;
        }, 100);
      }
    }
  };

  return (
    <>
      <div ref={popperTargetRef}>
        <IconButton
          aria-describedby={popperId}
          aria-label={targetAriaLabel}
          onClick={handleClick}
        >
          {target}
        </IconButton>
      </div>
      <MuiPopper
        id={popperId}
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
      >
        <OutsideAlerter onOutside={handleClick}>
          <Popped>{children}</Popped>
        </OutsideAlerter>
      </MuiPopper>
    </>
  );
};
