import { useState } from "react";
import { withAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import UploadMissingFormModal from "./UploadMissingFormModal";
import { reportMissingInformationMutation } from "../graphql/AuthorizationForm";
import strings from "Resources/strings";

const { MISSING_INFORMATION_TYPES } = CONFIG.CONSTANTS;

export const UploadMissingFormButton = ({
  alert,
  authorizationId,
  children,
  dismissFollowUp,
  insuranceCompanyId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [reportMissingInformation] = useMutation(
    reportMissingInformationMutation
  );

  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        role="button"
        tabIndex="-1"
      >
        {children}
      </div>
      {showModal && (
        <UploadMissingFormModal
          close={closeModal}
          onComplete={async (fileKey) => {
            try {
              await reportMissingInformation({
                variables: {
                  type: MISSING_INFORMATION_TYPES.FORM,
                  authorizationId,
                  insuranceCompanyId,
                  details: "Uploaded missing form for denied authorization",
                  state: "N/A",
                  fileKey,
                },
              });
              await dismissFollowUp();
              closeModal();
              alert.info("Form successfully uploaded!");
            } catch (e) {
              alert.error(strings.MISSING_INFORMATION.ERROR);
            }
          }}
          onDismiss={async () => {
            await dismissFollowUp();
            closeModal();
          }}
        />
      )}
    </div>
  );
};

/**
 * @deprecated Use a MUI component instead
 */
export default withAlert()(UploadMissingFormButton);
