import { useLazyQuery } from "@apollo/client";
import { FileByAwsKeyQuery } from "@@generated/graphql";
import { fileByAWSKeyQuery } from "../graphql/FileByAWSKey.gql";
import { useCallback } from "react";

export const useLazySignedUrl = () => {
  const [originalGetSignedUrl, { data, loading }] =
    useLazyQuery<FileByAwsKeyQuery>(fileByAWSKeyQuery);

  const getSignedUrl = useCallback(
    async (awsKey: string, bucket: string) => {
      await originalGetSignedUrl({ variables: { awsKey, bucket } });
    },
    [originalGetSignedUrl]
  );

  return {
    getSignedUrl,
    signedUrl: (data?.fileByAWSKey as string) ?? null,
    loading,
  };
};
