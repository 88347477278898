import _ from "lodash";
import styled from "styled-components";

import PdfViewer from "./PdfViewer";

const PDFContainer = styled.div`
  display: flex;
  padding: 10px;
  margin: 0 5px 20px 5px;
  background-color: ${(props) => props.theme.lightGray};
`;

/*
  The way I got PDF images (some that are 2 - 3 pages long) to work properly is to create a really long div as an image
  and to create a div window over it.
  The image div is statically 2500px to accommodate all sizes of pdf images, this is to allow us to set the background-size to contain
  and still have properly sized super long images.
*/
const ImageContainer = styled(PDFContainer)`
  height: ${(props) => props.heightOverride || "700px"};
  min-width: ${(props) => props.widthOverride || "500px"};
  overflow: scroll;
`;

const ResponseImage = styled.div`
  background-image: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  min-height: 2500px;
  min-width: 100%;

  ${(props) =>
    props.expandOnHover
      ? "&:hover { width: 1000px; height: 1000px; transition: all 1s ease-out; }"
      : ""}
`;

function CorrespondenceViewer({
  correspondence,
  height,
  width,
  expandOnHover,
}) {
  if (!_.get(correspondence, "fileURL")) {
    return <div />;
  }

  if (
    correspondence.mimeType &&
    correspondence.mimeType !== "application/pdf"
  ) {
    return (
      <ImageContainer heightOverride={height} widthOverride={width}>
        <ResponseImage
          src={correspondence.fileURL}
          expandOnHover={expandOnHover}
        />
      </ImageContainer>
    );
  }

  return (
    <PDFContainer>
      <PdfViewer
        fileURL={correspondence.fileURL}
        height={height}
        width={width}
        mimeTypeOverride={correspondence.mimeType}
      />
    </PDFContainer>
  );
}

/**
 * @deprecated Use a MUI component instead
 */
export default CorrespondenceViewer;
