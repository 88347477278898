import { PureComponent } from "react";

import Backdrop from "./Backdrop";

const NO_SCROLL = "noscroll";

const withFrozenBackdrop = (WrappedComponent) =>
  class WithFrozenBackdrop extends PureComponent {
    componentDidMount() {
      const { open } = this.props;
      if (open) {
        document.body.classList.add(NO_SCROLL);
      }
    }

    componentWillUnmount() {
      document.body.classList.remove(NO_SCROLL);
    }

    componentDidUpdate(prevProps) {
      const { open } = this.props;
      if (prevProps.open !== open) {
        if (open) {
          document.body.classList.add(NO_SCROLL);
        } else {
          document.body.classList.remove(NO_SCROLL);
        }
      }
    }

    render() {
      const {
        open,
        onClick,
        isPreventCloseOnBackdropClick = false,
        zIndexOffset = 0,
      } = this.props;

      if (open) {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Backdrop
              zIndexOffset={zIndexOffset}
              onClick={(e) => {
                e.stopPropagation();
                if (onClick && !isPreventCloseOnBackdropClick) {
                  onClick(e);
                }
              }}
            />
            <WrappedComponent {...this.props} />
          </div>
        );
      }
      return <div />;
    }
  };

/**
 * @deprecated Use a MUI component instead
 */
export default withFrozenBackdrop;
