import {
  RetrieveCoverLetterAuthorizationFormQuery,
  RetrieveCoverLetterAuthorizationFormQueryVariables,
} from "@@generated/graphql";

import { gql, useQuery } from "@apollo/client";

export const getCoverLetterAuthorizationFormQuery = gql`
  query RetrieveCoverLetterAuthorizationForm(
    $filters: AuthorizationFormsFilters
  ) {
    authorizationForms(filters: $filters) {
      edges {
        node {
          id
          description
          hasTaggedSignature
          imgKeys
          imgURLs
          isHiddenInstitutionIds
          pdfURL
          specification
          taggedHeight
          taggedWidth
          title
          updatedAt
        }
      }
    }
  }
`;

export const useCoverLetterAuthorizationForm = () => {
  const { data, error, loading } = useQuery<
    RetrieveCoverLetterAuthorizationFormQuery,
    RetrieveCoverLetterAuthorizationFormQueryVariables
  >(getCoverLetterAuthorizationFormQuery, {
    variables: {
      filters: {
        title: { eq: window.CONFIG.CONSTANTS.COVER_LETTER_FORM_TITLE },
      },
    },
  });

  return {
    form: data?.authorizationForms?.edges[0]?.node,
    loading,
    error,
  };
};
