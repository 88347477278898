var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MuiCircularProgress from "@mui/material/CircularProgress";
import Box from "../Box/Box";
import Typography from "../Typography/Typography";
const CircularProgress = (_a) => {
    var { value = 0, label = false } = _a, properties = __rest(_a, ["value", "label"]);
    return label ? (_jsxs(Box, { position: "relative", display: "inline-flex", children: [_jsx(MuiCircularProgress, Object.assign({}, properties)), _jsx(Box, { top: 0, left: 0, bottom: 0, right: 0, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", children: _jsx(Typography, { variant: "caption", color: "text.primary", children: `${Math.round(value)}%` }) })] })) : (_jsx(MuiCircularProgress, Object.assign({}, properties)));
};
export default CircularProgress;
