import { ValidationError, boolean, object, string } from "yup";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { useQuery, ApolloError } from "@apollo/client";
import { createContext, useContext, useMemo, useState } from "react";

import EnrollmentProviderQueryFile from "./EnrollmentProviderQuery.gql";
import EnrollmentProgramQuery from "./EnrollmentProgramQuery.gql";
import useCreateNewEnrollment from "../../hooks/useCreateNewEnrollment";
import useUpdateNewEnrollmentById from "../../hooks/useUpdateNewEnrollmentById";

import { EnrollmentRoutes } from "./Enrollment.types";

import {
  getQuestionnaireSchema,
  getQuestionnaireDefaultValues,
  type QuestionObjectType,
  type QuestionnaireSchemaType,
  type QuestionnaireResponseType,
  type QuestionnaireResponseAnswerObjectType,
} from "../../components/Questionnaire";

import {
  NewEnrollmentObjectType,
  NewEnrollmentStatusEnumType,
  EnrollmentProgram as EnrollmentProgramGraphqlType,
} from "@samacare/graphql";

import {
  EnrollmentProviderQuery,
  EnrollmentProviderQueryVariables,
  EnrollmentProgramQuery as EnrollmentProgramQueryType,
  EnrollmentProgramQueryVariables,
} from "@@generated/graphql";

import ROUTES from "../ROUTE_PATHS";

type IEnrollmentContext = {
  alert?: string;
  error?: ApolloError;
  loading?: boolean;
  isSubmitted?: boolean;
  enrollment?: Partial<NewEnrollmentObjectType>;
  onAlert: (alert: string) => void;
  onDismissAlert: () => void;
  onPatientSelected: (patientId: string) => Promise<void>;
  programId?: string | null;
  enrollmentProgram?: EnrollmentProgramGraphqlType;
  enrollmentProgramQueryLoading?: boolean;
};

const EnrollmentContext = createContext<IEnrollmentContext>({
  onAlert: () => {},
  onDismissAlert: () => {},
  onPatientSelected: async () => {},
});

export const EnrollmentProvider: React.FC = ({ children }) => {
  const [alert, setAlert] = useState<string | undefined>();
  const { enrollmentId } = useParams<{ enrollmentId?: string | undefined }>();

  const location = useLocation();
  const programId = new URLSearchParams(location.search).get("programId");
  const history = useHistory();

  const { data, error, loading } = useQuery<
    EnrollmentProviderQuery,
    EnrollmentProviderQueryVariables
  >(EnrollmentProviderQueryFile, {
    variables: { enrollmentId: Number(enrollmentId) },
    skip: !enrollmentId,
  });

  const enrollment: NewEnrollmentObjectType | undefined = useMemo(() => {
    if (loading || error) return;
    return data?.getEnrollmentById as NewEnrollmentObjectType;
  }, [data, loading, error]);

  const {
    data: enrollmentProgramData,
    error: enrollmentProgramQueryError,
    loading: enrollmentProgramQueryLoading,
  } = useQuery<EnrollmentProgramQueryType, EnrollmentProgramQueryVariables>(
    EnrollmentProgramQuery,
    {
      variables: {
        enrollmentProgramId: parseInt(programId!),
      },
      skip: !programId,
    }
  );

  const enrollmentProgramRaw: EnrollmentProgramGraphqlType | undefined =
    useMemo(() => {
      if (enrollmentProgramQueryLoading || enrollmentProgramQueryError) return;
      return enrollmentProgramData?.getEnrollmentProgramById as EnrollmentProgramGraphqlType;
    }, [
      enrollmentProgramData,
      enrollmentProgramQueryLoading,
      enrollmentProgramQueryError,
    ]);

  const [createNewEnrollment] = useCreateNewEnrollment();
  const [update] = useUpdateNewEnrollmentById();
  const alertTool = useAlert();

  async function onPatientSelected(patientId: string) {
    if (enrollment?.id == null) {
      const createEnrollmentResult = await createNewEnrollment({
        variables: {
          patientId,
          EnrollmentProgramId: programId,
        },
      });
      history.push(
        `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.details}/${createEnrollmentResult?.data?.createNewEnrollment?.id}`
      );
    } else {
      try {
        await update({
          variables: {
            id: Number(enrollment?.id),
            patch: {
              MedicationRequest: {
                PatientId: patientId,
              },
            },
          },
        });
        alertTool.info(
          "Successfully set a new patient to the current enrollment!"
        );
      } catch (err) {
        alertTool.error("There was an error changing to a new patient");
      }
    }
  }

  const value: IEnrollmentContext = useMemo(() => {
    return {
      alert,
      error,
      loading,
      enrollment,
      programId,
      onPatientSelected,
      enrollmentProgram: (enrollment?.EnrollmentProgram ??
        enrollmentProgramRaw) as EnrollmentProgramGraphqlType,
      enrollmentProgramQueryLoading,
      isSubmitted:
        enrollment?.status === NewEnrollmentStatusEnumType.Submitted ||
        enrollment?.status === NewEnrollmentStatusEnumType.Completed,
      onAlert(title: string) {
        setAlert(title);
      },
      onDismissAlert() {
        setAlert(void 0);
      },
    };
  }, [
    alert,
    error,
    loading,
    enrollment,
    programId,
    enrollmentProgramQueryLoading,
    enrollmentProgramData,
  ]);

  return (
    <EnrollmentContext.Provider value={value}>
      {children}
    </EnrollmentContext.Provider>
  );
};

export const useEnrollmentContext = (): IEnrollmentContext =>
  useContext<IEnrollmentContext>(EnrollmentContext);

export const useOcrevusQuestionnaire = (): {
  schema?: QuestionnaireSchemaType;
  answers?: { questionnaire?: QuestionnaireResponseType };
} => {
  const { enrollment } = useEnrollmentContext();
  const questions =
    (enrollment?.EnrollmentProgram?.questionnaire
      ?.all as QuestionObjectType[]) ?? [];

  const questionnaireSchema = getQuestionnaireSchema(questions, {
    "sama-do-not-contact": boolean(),
    "sama-office-phone": string().required(),
    "sama-office-fax": string().required(),
    // TODO: figure out why `indication` validation is not behaving as expected in `getQuestionnaireSchema`
    indication: string().oneOf(["RMS", "PPMS", "Other"]).nullable(),
  }).test("service-selection", "A service must be selected", (data) => {
    if (data["applied-for-bipa"] && data["applied-for-pf"]) {
      return new ValidationError(
        "Only one service is allowed to be selected",
        null,
        "service-selection"
      );
    }

    if (data["applied-for-bipa"] || data["applied-for-pf"]) {
      return true;
    }

    return new ValidationError(
      "A service must be selected",
      null,
      "service-selection"
    );
  });
  const answers =
    getQuestionnaireDefaultValues(
      object({ questionnaire: questionnaireSchema }),
      (enrollment?.questionnaire?.answers ??
        []) as QuestionnaireResponseAnswerObjectType[]
    ) ?? {};
  return { schema: questionnaireSchema, answers };
};

export const useApellisQuestionnaire = (): {
  answers?: { questionnaire?: QuestionnaireResponseType };
} => {
  const { enrollment } = useEnrollmentContext();

  return {
    answers: {
      questionnaire: (
        (enrollment?.questionnaire?.answers ??
          []) as QuestionnaireResponseAnswerObjectType[]
      ).reduce<QuestionnaireResponseType>((acc, item) => {
        return { [item.id]: item?.value ?? undefined, ...acc };
      }, {}),
    },
  };
};
