import React, { useState } from "react";
import {
  Alert,
  Button,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Link,
} from "@samacare/design/core";
import DownloadIcon from "@samacare/design/core/icons/Download";
import DownloadDoneIcon from "@samacare/design/core/icons/DownloadDone";
import { useCurrentAccount } from "../../../graphql/Account";

import { useMutation } from "@apollo/client";
import SFTPCreateQuery from "./SFTPCreateQuery.gql";

export const SFTPCreate: React.FC = () => {
  // State for managing popover and download state
  const [account] = useCurrentAccount();
  const [open, setOpen] = React.useState(false);
  const [hasKeysDownloaded, setKeysDownloaded] = useState<boolean>(false);
  const [createSftp, { data, loading, error }] = useMutation(SFTPCreateQuery, {
    refetchQueries: ["currentAccount"],
  });

  const sftpKey = account?.institution?.sftpKey;
  const username = sftpKey?.sftpUsername;
  const sshPrivateKey = data?.addSFTPKey.sshPrivateKey;

  const hasPrivateKey = !!data?.addSFTPKey.sshPrivateKey;

  const onCreateSFTPKey = async () => {
    await createSftp();
    setOpen(true);
    setKeysDownloaded(false);
  };

  const action = username ? "Regenerate SSH Key" : "Generate New SSH Key";

  return (
    <Box p={4}>
      {error && (
        <Alert color="error" sx={{ mb: 4 }}>
          Error generating SSH key. Please contact support.
        </Alert>
      )}
      <Typography color="primary" variant="h6" gutterBottom>
        SFTP Setup
      </Typography>
      <Typography color="CaptionText" gutterBottom mb={2}>
        See our{" "}
        <Link
          href="https://samacare-help.freshdesk.com/support/solutions/articles/72000617426-sftp-integration"
          target="_blank"
          rel="noreferrer"
        >
          help documentation
        </Link>{" "}
        for further instructions on how to set up an SFTP integration with
        SamaCare.
      </Typography>

      <Button
        id="ssh-key-button"
        onClick={onCreateSFTPKey}
        disabled={loading}
        variant="outlined"
        sx={{
          width: "200px",
          mb: 4,
        }}
      >
        {loading ? "Generating SSH Key..." : action}
      </Button>

      {username && (
        <>
          <Typography color="primary" variant="h6" gutterBottom>
            SFTP Login Details
          </Typography>
          <Typography color="CaptionText" gutterBottom mb={2}>
            Use these details to connect to the SFTP server. If you cannot find
            your private key you can regenerate above.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Username: </strong>
            {username}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Private key: </strong>
            ********
          </Typography>
        </>
      )}

      <Dialog open={open}>
        <DialogTitle sx={{ color: "primary" }}>
          New SSH Key created!
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            Download your unique SSH key. This key is{" "}
            <strong>non-recoverable</strong>. If you lose it, come back to this
            page to generate a new one.
          </DialogContentText>
          <DialogContentText
            mb={2}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Button
              variant="outlined"
              endIcon={<DownloadIcon />}
              disabled={!hasPrivateKey}
              sx={{ cursor: "pointer" }}
              onClick={() => setKeysDownloaded(true)}
              href={URL.createObjectURL(
                new Blob([sshPrivateKey], { type: "text/plain" })
              )}
              download={`${username}.key`}
            >
              Private Key
            </Button>

            {hasKeysDownloaded && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <DownloadDoneIcon />
                <Typography
                  color="green"
                  variant="body2"
                  sx={{ margin: "0 10px" }}
                >
                  Downloaded
                </Typography>
              </Box>
            )}
          </DialogContentText>
          <DialogActions>
            {hasKeysDownloaded && (
              <Button
                sx={{ position: "absolute", bottom: "10px", right: "10px" }}
                variant="contained"
                onClick={() => setOpen(false)}
              >
                I&apos;ve downloaded the Keys
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
