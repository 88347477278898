import { Components } from "@mui/material";

export default (): Components["MuiDataGrid"] => ({
  styleOverrides: {
    root: {
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
      overflowX: "scroll",
      zIndex: 0,
    },
  },
  defaultProps: {
    pageSizeOptions: [25, 50, 100],
    pagination: true,
  },
});
