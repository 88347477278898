import { Stack } from "@samacare/design/core";
import { QuestionnaireField } from "../../components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { useWatch } from "@samacare/form";

export type HorizonKrystexxaCoAdministrationBlockProps = {
  isDisabled?: boolean;
};

export const HorizonKrystexxaCoAdministrationBlock: React.FC<
  HorizonKrystexxaCoAdministrationBlockProps
> = ({ isDisabled = false }) => {
  const questionnaireData = useWatch({
    name: "questionnaire",
  });
  return (
    <Stack spacing={2} maxWidth={600}>
      <QuestionnaireField
        id="ifImmunomodulatorPrescribed"
        type={QuestionTypeEnumType.Choice}
        text="Is there an immunomodulator prescribed?"
        disabled={isDisabled}
        answerOption={[{ valueString: "Yes" }, { valueString: "No" }]}
      />
      {questionnaireData.ifImmunomodulatorPrescribed === "Yes" && (
        <QuestionnaireField
          id="coAdministrationMedicationInfo"
          type={QuestionTypeEnumType.Choice}
          text="If yes, please indicate below:"
          disabled={isDisabled}
          answerOption={[
            { valueString: "methotrexate" },
            { valueString: "Other" },
          ]}
        />
      )}
    </Stack>
  );
};
