import _ from "lodash";
import { PureComponent } from "react";
import { withAlert } from "react-alert";
import { compose } from "recompose";
import strings from "../../resources/strings";
import { withUpdateAccountInstitution } from "../graphql/Account";
import { withInstitutions } from "../graphql/Institution";

import {
  MenuItem,
  Autocomplete,
  TextField,
  ListItemText,
} from "@samacare/design/core";

const renderOption = (props, option, { selected }) => (
  <MenuItem {...props} key={option.value} selected={selected}>
    <ListItemText primary={option.label} />
  </MenuItem>
);

export class SwitchCurrentInstitution extends PureComponent {
  handleChange = async (selectedOption, target) => {
    const { updateAccountInstitution, alert } = this.props;

    if (selectedOption) {
      try {
        await updateAccountInstitution({
          variables: {
            InstitutionId: target.value,
          },
        });
        alert.success(strings.SWITCH_INSTITITIONS.SUCCESS);
        setTimeout(() => window.location.reload(), 1000);
      } catch {
        alert.error(strings.SWITCH_INSTITITIONS.ERROR);
      }
    }
  };

  render() {
    const { account, institutions } = this.props;

    if (
      !_.get(account, "isSamaUser") &&
      (_.isEmpty(_.get(account, "allowedInstitutionIds")) ||
        _.get(account, "allowedInstitutionIds.length") === 1)
    ) {
      return null;
    }

    return (
      <Autocomplete
        data-cy="foo"
        options={_.map(
          _.sortBy(institutions, (institution) =>
            institution.name.toLowerCase()
          ),
          ({ id, name }) => ({
            label: name,
            value: id,
          })
        )}
        renderInput={(params) => (
          <TextField
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            {...params}
          />
        )}
        renderOption={renderOption}
        disableClearable
        value={{
          label: account.institution.name,
          value: account.institution.id,
        }}
        onChange={this.handleChange}
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          width: (theme) => theme.spacing(26),
          "& .MuiInputBase-input": {
            color: (theme) => theme.palette.error.main,
          },
        }}
        componentsProps={{
          paper: {
            sx: {
              width: (theme) => theme.spacing(52),
            },
          },
        }}
      />
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withUpdateAccountInstitution,
  withInstitutions
)(withAlert()(SwitchCurrentInstitution));
