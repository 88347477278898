import RequiredTag from "../../../components/RequiredTag";
import { SegmentTitleContainer } from "../../../components/Segment/SegmentContainer";

interface FieldLabelProps {
  title: string | React.ReactNode;
  htmlFor: string;
  isRequired?: boolean;
}
export const FieldLabel: React.VFC<FieldLabelProps> = (props) => (
  <SegmentTitleContainer>
    <label htmlFor={props.htmlFor}>{props.title}</label>
    {props.isRequired && <RequiredTag />}
  </SegmentTitleContainer>
);
