import authorizationCorrespondenceInfo from "../graphql/fragments/authorizationCorrespondenceInfo";
import {
  GetAuthorizationLogsQuery,
  GetAuthorizationLogsQueryVariables,
} from "@@generated/graphql";
import { ApolloError, gql, useQuery } from "@apollo/client";

export const GET_AUTHORIZATION_LOGS_QUERY_NAME = "getAuthorizationLogs";

export const getAuthorizationLogsQuery = gql`
  query getAuthorizationLogs($authorizationId: Int!) {
    getAuthorizationLog(authorizationId: $authorizationId) {
      createdAt
      type
      details
      assignee {
        firstName
        lastName
      }
      account {
        firstName
        lastName
        isSamaUser
      }
      file {
        title
        url
        awsKey
        downloadUrl
      }
      authorizationCorrespondence {
        ...authorizationCorrespondenceInfo
      }
    }
  }
  ${authorizationCorrespondenceInfo}
`;

export const useAuthorizationLogs = (
  authorizationId?: number
): {
  authorizationLogs: GetAuthorizationLogsQuery["getAuthorizationLog"] | null;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, error, loading } = useQuery<
    GetAuthorizationLogsQuery,
    GetAuthorizationLogsQueryVariables
  >(getAuthorizationLogsQuery, {
    variables: { authorizationId: authorizationId ?? -1 },
  });

  return {
    authorizationLogs: data?.getAuthorizationLog ?? null,
    loading,
    error,
  };
};
