import { Stack } from "@samacare/design/core";

import AddressBlock, { AddressBlockProps } from "../AddressBlock/AddressBlock";
import {
  useFormContext,
  AutocompleteField,
  AutocompleteFieldProps,
  TextField,
  TextFieldProps,
  PhoneField,
  PhoneFieldProps,
} from "@samacare/form";

export type PlaceOfServiceBlockProps = {
  required?: boolean;
  disabled?: boolean;
  PlaceOfServicNameInputProps?: TextFieldProps;
  PlaceOfServicTypeInputProps?: AutocompleteFieldProps<
    string,
    false,
    true,
    false
  >;
  AddressBlockInputProps?: AddressBlockProps;
  placeOfServiceProps?: TextFieldProps;
  ContactNameInputProps?: TextFieldProps;
  PhoneInputProps?: PhoneFieldProps;
};

export const PlaceOfServiceBlock: React.FC<PlaceOfServiceBlockProps> = ({
  required = false,
  disabled = false,
  PlaceOfServicNameInputProps = {
    name: "placeOfService.name",
    label: "Facility Name",
  },
  PlaceOfServicTypeInputProps = {
    name: "placeOfService.type",
    label: "Facility Type",
  },
  AddressBlockInputProps = {
    AddressInputProps: {
      name: "placeOfService.address",
    },
    CityInputProps: {
      name: "placeOfService.city",
    },
    StateInputProps: {
      name: "placeOfService.state",
    },
    ZipInputProps: {
      name: "placeOfService.zip",
    },
  },
  ContactNameInputProps = {
    name: "placeOfService.contactName",
    label: "Contact Name",
  },
  PhoneInputProps = {
    name: "placeOfService.phone",
    label: "Phone",
  },
}) => {
  const { watch } = useFormContext();

  return (
    <Stack spacing={2} maxWidth={600}>
      <TextField
        {...PlaceOfServicNameInputProps}
        fullWidth
        required={required}
        disabled={disabled}
      />
      <AutocompleteField<string, false, true, false>
        required={required}
        disabled={disabled}
        disableClearable
        placeholder="type"
        value={watch(PlaceOfServicTypeInputProps.name) ?? ""}
        {...PlaceOfServicTypeInputProps}
        options={[
          "Ambulatory Surgical Center",
          "Home Infusion Center Hospital",
          "Infusion / Injection Center",
          "Practice",
          "Skilled Nursing / Nursing Home",
          "Unknown",
        ]}
      />
      <AddressBlock required={required} {...AddressBlockInputProps}>
        <Stack direction="row" spacing={2}>
          <TextField
            {...ContactNameInputProps}
            required={required}
            disabled={disabled}
            fullWidth
          />
          <PhoneField
            {...PhoneInputProps}
            fullWidth
            required={required}
            disabled={disabled}
          />
        </Stack>
      </AddressBlock>
    </Stack>
  );
};
