import { useFormContext } from "@samacare/form";

import PasswordField, { type PasswordFieldProps } from "../PasswordField";

export type PasswordBlockProps = {
  NewPasswordFieldProps?: Partial<PasswordFieldProps>;
  ConfirmPasswordFieldProps?: Partial<PasswordFieldProps>;
};

export const PasswordBlock: React.FC<PasswordBlockProps> = ({
  NewPasswordFieldProps = {},
  ConfirmPasswordFieldProps = {},
}) => {
  const { getValues } = useFormContext();
  return (
    <>
      <PasswordField
        name="newPassword"
        label="New password"
        autoComplete="new-password"
        rules={{
          minLength: {
            value: 14,
            message: "At least 14 characters",
          },
          validate: {
            upperCase: (value: string) =>
              /(?=.*[A-Z])/.test(value) ||
              "At least 1 uppercase character (A-Z)",
            lowerCase: (value: string) =>
              /(?=.*[a-z])/.test(value) ||
              "At least 1 lowercase character (a-z)",
            number: (value: string) =>
              /(?=.*\d)/.test(value) || "At least 1 number (0-9)",
            specialChar: (value: string) =>
              new RegExp(
                "(?=.*[~!@#$%^&*_\\-+=`|\\\\(){}[\\]:;\"'<>,.?/])"
              ).test(value) || "At least 1 special character (i.e. #, $, @, %)",
          },
        }}
        {...NewPasswordFieldProps}
      />
      <PasswordField
        name="confirmPassword"
        label="Confirm new password"
        autoComplete="confirm-new-password"
        rules={{
          validate: (value) =>
            value === getValues()?.newPassword || "The passwords do not match",
        }}
        {...ConfirmPasswordFieldProps}
      />
    </>
  );
};
