export const humanizePlanActiveStatus = (
  value: boolean | null | undefined
): string => {
  switch (value) {
    case true:
      return "Active";
    case false:
      return "Inactive";
    default:
      return "Unknown";
  }
};

export const humanizeInsurancePlanTitle = (
  companyName: string | null | undefined,
  planName: string | null | undefined
): string => {
  if (companyName == null || planName == null) {
    if (companyName == null && planName != null) {
      return planName;
    }

    if (planName == null && companyName != null) {
      return companyName;
    }

    return "Unknown";
  }

  if (companyName === planName) {
    // Annoyingly, sometimes Ris gives us the same value for plan and for
    // company name, probably when they don't know one or the other. In that
    // scenario, if we don't deduplicate the names, we end up with something
    // that looks like this:
    //
    // GEISINGER HEALTH PLAN MEDICAID - GEISINGER HEALTH PLAN MEDICAID
    return planName;
  }

  return `${companyName} - ${planName}`;
};

export const humanizeNumber = (value: number): string => value.toString();

export const humanizeMaybeNumber = (value: number | null | undefined): string =>
  value == null ? "Unknown" : humanizeNumber(value);

export const humanizeBoolean = (value: boolean | null): string =>
  value ? "Yes" : "No";

export const humanizeMaybeBoolean = (
  value: boolean | null | undefined,
  emptyString = "-"
): string => (value != null ? humanizeBoolean(value) : emptyString);

export const humanizeMaybeString = (value: string | null | undefined): string =>
  value !== "" && value != null ? value : "Unknown";

export const humanizePlanIdentifier = (
  value: string | null | undefined
): string => (value != null ? value : "");

export const humanizePercentage = (
  value: number,
  fractionDigits = 0
): string => {
  return value.toFixed(fractionDigits);
};

export const humanizeMaybePercentage = (
  value: number | null | undefined,
  fractionDigits = 0
): string => {
  return value == null ? "Unknown" : humanizePercentage(value, fractionDigits);
};

export const humanizeDollars = (value: number, fractionDigits = 2): string => {
  return `$${value.toFixed(fractionDigits)}`;
};

export const humanizeMaybeDollars = (
  value: number | null | undefined,
  fractionDigits = 2
): string => {
  return value == null ? "Unknown" : humanizeDollars(value, fractionDigits);
};
