import styled from "styled-components";

import { Flex } from "@@ui-kit";
import { Typography } from "@samacare/design/core";

export const BvDataRow = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
`;

export const SectionHeader = styled.div`
  color: ${(props) => props.theme.purple};
  font-weight: 400;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const BodyTitleText = styled.div`
  min-width: 225px;
`;

export const BvContentText = styled.div`
  text-align: left;
  margin-top: 4px;
`;

interface ReadonlyFieldProps {
  label: string;
  value?: string | null;
  nullValueText?: string;
}

export const ReadonlyField: React.FC<ReadonlyFieldProps> = ({
  label,
  value,
  nullValueText = "-",
}: ReadonlyFieldProps) => {
  return (
    <BvDataRow>
      <BodyTitleText>
        <Typography variant="subtitle2" color="rgba(0, 0, 0, 0.6)">
          {label}
        </Typography>
      </BodyTitleText>
      <BvContentText>
        <Typography variant="body2">{value ?? nullValueText}</Typography>
      </BvContentText>
    </BvDataRow>
  );
};
