import { gql, useMutation } from "@apollo/client";

import { AssigneeSelect } from "@@components/AssigneeSelect";
import { GET_AUTHORIZATION_LOGS_QUERY_NAME } from "@@hooks/useAuthorizationLogs";
import { Authorization } from "@samacare/graphql";

const reassignAuthorizationMutation = gql`
  mutation reassingAuthorization($authorizationId: Int!, $accountId: Int!) {
    reassingAuthorization(
      authorizationId: $authorizationId
      accountId: $accountId
    ) {
      id
      createdById
    }
  }
`;

interface IAuthorizationAssigneeSelectProps {
  authorization: Authorization;
}

export const AuthorizationAssigneeSelect: React.FC<
  IAuthorizationAssigneeSelectProps
> = ({ authorization }) => {
  const [reassignAuthorization] = useMutation(reassignAuthorizationMutation, {
    refetchQueries: [GET_AUTHORIZATION_LOGS_QUERY_NAME],
  });
  return (
    <AssigneeSelect
      defaultValue={parseInt(authorization.createdById as string)}
      onChange={async (accountId) =>
        reassignAuthorization({
          variables: {
            authorizationId: authorization.id,
            accountId,
          },
        })
      }
    />
  );
};
