import { EnrollmentSection } from "./EnrollmentSection";
import { IcdField } from "@samacare/form";

export type PrimaryAndSecondaryIcdCodeBlockProps = {
  isSubmitted?: boolean;
};

export const PrimaryAndSecondaryIcdCodeBlock: React.FC<
  PrimaryAndSecondaryIcdCodeBlockProps
> = ({ isSubmitted = false }) => {
  return (
    <>
      <EnrollmentSection
        title="Geographic Atrophy Diagnosis"
        description="Select one diagnosis as primary. For additional diagnoses, please use the “secondary diagnosis” section below."
      >
        <IcdField
          name="diagnoses"
          required
          disabled={isSubmitted}
          override={{
            options: [
              "H35.3134",
              "H35.3124",
              "H35.3114",
              "H35.3133",
              "H35.3123",
              "H35.3113",
            ],
            limit: 1,
          }}
        />
      </EnrollmentSection>
      <EnrollmentSection title="Secondary diagnosis">
        <IcdField
          name="questionnaire.secondaryDiagnoses"
          disabled={isSubmitted}
          override={{
            limit: 1,
          }}
        />
      </EnrollmentSection>
    </>
  );
};
