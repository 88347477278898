import styled from "styled-components";

const CheckmarkImage = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 2px solid ${(props) => props.theme.purple};
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => props.theme.purple};
    opacity: 0.7;
  }

  &::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CheckboxContainer = styled.label`
  position: relative;
  cursor: pointer;
  height: 25px;
  width: 25px;

  input {
    height: 0;
    width: 0;
    opacity: 0;
  }

  ${CheckmarkImage} {
    background-color: ${(props) => (props.checked ? props.theme.purple : "")};
  }
`;

function CustomCheckbox({ checked, onChange, defaultChecked, cypressTag }) {
  return (
    <CheckboxContainer checked={checked}>
      <input
        onChange={onChange}
        type="checkbox"
        defaultChecked={defaultChecked}
      />
      <CheckmarkImage data-cy={cypressTag} />
    </CheckboxContainer>
  );
}

/**
 * @deprecated Use a MUI component instead
 */
export default CustomCheckbox;
