import { PureComponent } from "react";

import { genericInput } from "../InputPropTypes";
import { BaseInputCleave, getPropsFromInput } from "./BaseInput";

class DateInput extends PureComponent {
  static propTypes = {
    input: genericInput.isRequired,
  };

  render() {
    const { input } = this.props;

    return (
      <BaseInputCleave
        {...getPropsFromInput(input)}
        onChange={(e) => {
          input.onChange({ [input.id]: e.target.value });
        }}
        value={input.value || ""}
        options={{ date: true, datePattern: ["m", "d", "Y"], delimiter: "/" }}
        placeholder={CONFIG.CONSTANTS.DATE_FORMAT}
      />
    );
  }
}

export default DateInput;
