import { Stack } from "@samacare/design";
import { PhoneField, TextField } from "@samacare/form";

export type RequesterBlockProps = {
  required?: boolean;
};

const RequesterBlock: React.FC<RequesterBlockProps> = ({ required }) => {
  return (
    <Stack direction="row" spacing={1} maxWidth={600}>
      <TextField
        name="requester.contactName"
        label="Contact name"
        required={required}
        fullWidth
      />
      <PhoneField
        name="requester.phone"
        label="Phone"
        fullWidth
        required={required}
      />
      <PhoneField name="requester.fax" label="Fax" fullWidth />
    </Stack>
  );
};

export default RequesterBlock;
