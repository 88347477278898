import {
  QuestionnaireSchemaType,
  QuestionnaireResponseType,
  QuestionnaireResponseAnswerObjectType,
} from "../Questionnaire.types";

export function getQuestionnaireDefaultValues(
  schema: QuestionnaireSchemaType,
  answers: QuestionnaireResponseAnswerObjectType[] = []
) {
  const defaulValues = answers.reduce<QuestionnaireResponseType>(
    (acc, item) => {
      return { [item.id]: item.value ?? undefined, ...acc };
    },
    {}
  );

  return schema.cast(
    { questionnaire: defaulValues },
    { stripUnknown: true, assert: false }
  );
}
