import { useQuery, ApolloError } from "@apollo/client";
import Query from "./UseEnrollmentProgramOptionsQuery.gql";

import type { EnrollmentProgram } from "@samacare/graphql";

import type {
  UseEnrollmentProgramOptionsQuery,
  UseEnrollmentProgramOptionsQueryVariables,
} from "@@generated/graphql";

const useEnrollmentProgramOptions = (): {
  error?: ApolloError;
  loading: boolean;
  enrollmentPrograms?: EnrollmentProgram[];
} => {
  const { data, error, loading } = useQuery<
    UseEnrollmentProgramOptionsQuery,
    UseEnrollmentProgramOptionsQueryVariables
  >(Query);
  return {
    enrollmentPrograms: data?.getEnrollmentPrograms as EnrollmentProgram[],
    error,
    loading,
  };
};

export default useEnrollmentProgramOptions;
