import moment from "moment";
import _ from "lodash";
import React, { PureComponent } from "react";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import styled from "styled-components";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { formatPhoneForDisplay } from "@samacare/utils";
import CreateFormNumberModal from "../components/CreateFormNumberModal";
import CreateInsuranceCompanyModal from "../components/CreateInsuranceCompanyModal";
import FormConfirmationView from "../../../NewAuthorization/SelectFormModal/FormConfirmationView";
import Modal from "../../../../components/Modal";
import EditFormTitle from "../components/EditFormTitle";
import FiltersContainer from "../components/FiltersContainer";
import BaseButton from "../../../../components/BaseButton";
import {
  withAssociateForm,
  withUpdateAuthorizationForm,
} from "../../../../graphql/AuthorizationForm";
import { withInsuranceCompanies } from "../../../../graphql/InsuranceCompany";
import {
  withDeleteFormOrFaxFilter,
  withUpsertFormFilter,
} from "../../../../graphql/FormFilter";
import { withCurrentAccount } from "../../../../graphql/Account";
import filterInsuranceCompanyOptions from "../../../../util/filterInsuranceCompanyOptions";
import { AuthorizationFormTaggingControl } from "./TaggingControl";
import { withApollo } from "@apollo/client/react/hoc";
import { fetchFormNumbers, fetchForms } from "./formsNumbersUtils";
import { BaseText } from "Segment/StyledComponents";
import { Scrollable } from "./FaxEdit";
import { InternationalPhone } from "Segment/InternationalPhone";
import { Button, CircularProgress } from "@samacare/design/core";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PayerSelectionContainer = styled.div`
  display: flex;
  height: 100px;
  flex-wrap: wrap;
`;

const FormSelectionContainer = styled.div`
  display: flex;
  height: 100px;
  flex-wrap: wrap;
  margin-bottom: 200px;
`;

const StyledSelect = styled(Select)`
  width: 70%;
  margin-right: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: ${(props) => (props.isTagger ? "column" : "row")};
`;

const ViewContainer = styled.div`
  flex-${(props) => (props.isTagger ? "shrink" : "grow")}: 1;

  ${BaseButton} {
    margin-left: auto;
  }
`;

const InputLabel = styled.div`
  width: 100%;
  font-weight: 700;
  margin: 10px 0;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.selected ? props.theme.lightPurple : "transparent"};

  &:hover {
    background-color: ${(props) => props.theme.lightPurple};
  }
`;

const ScrollContainer = styled.div`
  max-height: ${(props) => (props.isTagger ? "200px" : "400px")};
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const CreateFaxNumberButton = styled(Button)`
  margin: 5px 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const homogenizeString = (string) =>
  string.replace(/[^A-Za-z]/g, "").toLowerCase();

const formatPhoneLabel = ({ number, description, forbiddenReason }) => {
  return `${forbiddenReason ? "[BLACKLISTED] " : ""}${formatPhoneForDisplay(
    number
  )} - ${description}`;
};

class FormEdit extends PureComponent {
  state = {
    selectedFormId: null,
    selectedPayerId: null,
    payersUpdate: [],
    numbersUpdate: [],
    newInsuranceCompany: null,
    newFormNumber: null,
    archivedStatus: null,
    forms: [],
    formNumbers: [],
    formNumbersLoading: false,
    formInputValue: "",
    searchNumber: "",
  };

  MIN_SEARCH_NUMBER_LIMIT = 4;

  componentDidMount() {
    const { formId, forms } = this.props;
    const selected = _.find(forms, { id: formId });
    if (selected) {
      this.setForm(selected);
    }
  }

  componentDidUpdate = () => {
    const { formId, forms: propsForms } = this.props;
    const { selectedFormId, forms: stateForms, filterId } = this.state;

    let selected;
    if (formId) {
      selected = _.find(propsForms, { id: formId });
      if (selected && formId !== selected.id) {
        this.setForm(selected);
      }
    } else if (selectedFormId) {
      selected = _.find(stateForms, { id: selectedFormId });
      if (filterId !== selected.formFilter?.id) {
        this.setState({ filterId: selected.formFilter?.id });
      }
    }
  };

  editFormAssociation = (key, formNumberOrInsuranceCompany, isRemove) => {
    const stateHandler = (prevState) => ({
      [key]: isRemove
        ? _.reject(prevState[key], { id: formNumberOrInsuranceCompany.id })
        : [...prevState[key], formNumberOrInsuranceCompany],
    });
    this.setState(stateHandler, this.updateForm);
  };

  setForm = (selected) => {
    this.setState({
      selectedFormId: selected.id,
      payersUpdate: selected.insuranceCompanies,
      numbersUpdate: selected.formNumbers,
      archivedStatus: selected.isArchived,
      filterId: selected.formFilter?.id,
    });
  };

  unsetForm = () => {
    this.setState({
      selectedFormId: null,
      payersUpdate: [],
      numbersUpdate: [],
      archivedStatus: null,
      filterId: null,
      forms: [],
    });
  };

  handleFetchForms = async (ids) => {
    const { selectedPayerId, formInputValue } = this.state;
    const { client } = this.props;
    const forms = await fetchForms(
      client,
      selectedPayerId,
      formInputValue,
      ids
    );
    this.setState({ forms });
  };

  debouncedFetchForms = _.debounce(this.handleFetchForms, 1000);

  archiveForm = async () => {
    const { updateAuthorizationForm, alert } = this.props;
    const { selectedFormId, archivedStatus } = this.state;
    try {
      await updateAuthorizationForm({
        variables: {
          id: selectedFormId,
          patch: {
            isArchived: !archivedStatus,
          },
        },
      });
      await this.handleFetchForms();
      alert.info(
        `Successfully ${archivedStatus ? "unarchived" : "archived"} record`
      );
      this.setState({ archivedStatus: !archivedStatus });
    } catch (e) {
      alert.error(`Failed to update ${e}`);
    }
  };

  updateForm = async () => {
    const { associateForm, alert } = this.props;
    const { selectedFormId, payersUpdate, numbersUpdate, forms } = this.state;

    try {
      await associateForm({
        variables: {
          id: selectedFormId,
          insuranceCompanies: _.map(payersUpdate, "id"),
          formNumbers: _.map(numbersUpdate, "id"),
        },
      });
      const ids = _.map(forms, "id");
      await this.handleFetchForms(ids);
    } catch (e) {
      alert.error(`Error, ${e.message}`);
    }
  };

  handleFetchFormNumbers = async () => {
    const { searchNumber } = this.state;
    const { client } = this.props;

    this.setState({ formNumbersLoading: true });
    const formNumbers = await fetchFormNumbers(client, searchNumber);
    this.setState({ formNumbers, formNumbersLoading: false });
  };

  debouncedFetchFormNumbers = _.debounce(this.handleFetchFormNumbers, 1000);

  upsertFormFilter = async (vars) => {
    try {
      const { upsertFormFilter } = this.props;
      const { selectedFormId, forms } = this.state;

      await upsertFormFilter({
        variables: {
          formId: selectedFormId,
          ...vars,
        },
      });

      await this.handleFetchForms();
      const updatedForm = _.find(forms, { id: selectedFormId });
      this.setState({ filterId: updatedForm.formFilter?.id });
    } catch (e) {
      alert.error(`Error, ${e.message}`);
    }
  };

  deleteFormFilter = async () => {
    try {
      const { filterId } = this.state;
      if (filterId) {
        const { deleteFormOrFaxFilter } = this.props;

        await deleteFormOrFaxFilter({
          variables: {
            id: filterId,
            isFormFilter: true,
          },
        });
        await this.handleFetchForms();
      }
    } catch (e) {
      alert.error(`Error, ${e.message}`);
    }
  };

  handleFormSelection = (selectedFormId) => {
    const selected = this.state.forms.find(
      (form) => form.id === selectedFormId
    );
    this.setForm(selected);
  };

  handleSearchNumberUpdate = (e) => {
    const { value } = e.target;
    this.setState({ searchNumber: value }, () => {
      if (value && value.length > this.MIN_SEARCH_NUMBER_LIMIT) {
        this.debouncedFetchFormNumbers();
      } else {
        this.setState({ formNumbers: [] });
      }
    });
  };

  render() {
    const { formId, insuranceCompanies } = this.props;
    const {
      selectedFormId,
      selectedPayerId,
      newFormNumber,
      newInsuranceCompany,
      showViewModal,
      showEditModal,
      forms,
      formNumbers,
      formNumbersLoading,
      formInputValue,
      searchNumber,
    } = this.state;
    const augmentedForms = _.map(forms, (form) => ({
      ...form,
      displayValue: `${form.isArchived ? "[Archived] " : ""}[${moment(
        form.updatedAt
      ).format("MM/DD/YY")}] ${form.title} - ${form.description}`,
    }));
    const augmentedPayers = _.map(insuranceCompanies, (company) => ({
      ...company,
      displayValue: company.name,
    }));
    const sortedAugmentedPayers = _.orderBy(augmentedPayers, [
      (payer) => payer.name.toLowerCase(),
    ]);
    const selectedForm = _.find(augmentedForms, { id: selectedFormId });
    const selectedPayer = _.find(augmentedPayers, { id: selectedPayerId });
    const removeButtonText = !formId ? "Remove" : "X";

    const formsNumbersToDisplay = formNumbers.filter((n) => {
      if (n.isHidden) return false;
      if (selectedForm)
        return !selectedForm.formNumbers.some((f) => f.id === n.id);
      return true;
    });

    const formatOptionLabel = ({ displayValue, isArchived }) => (
      <div style={{ display: "flex" }}>
        <div>{displayValue}</div>
        <div
          style={{
            marginLeft: "20px",
            color: "red",
          }}
        >
          {isArchived && "Archived"}
        </div>
      </div>
    );

    return (
      <MainContainer>
        {!formId && (
          <>
            <PayerSelectionContainer>
              <InputLabel>Select a payer</InputLabel>
              <StyledSelect
                isClearable
                formatOptionLabel={formatOptionLabel}
                onChange={(selected) => {
                  const selectedId = selected ? selected.id : null;
                  this.setState({ selectedPayerId: selectedId }, () => {
                    if (selectedId) {
                      this.handleFetchForms();
                    }
                    this.unsetForm();
                  });
                }}
                labelKey="payerDisplayValue"
                valueKey="payerId"
                getOptionLabel={(option) => option.displayValue}
                getOptionValue={(option) => option.id}
                options={sortedAugmentedPayers.filter(
                  (payer) => payer.isArchived === false
                )}
                filterOption={(option, string) => {
                  return homogenizeString(option.data.name).startsWith(
                    homogenizeString(string)
                  );
                }}
                value={selectedPayer}
              />
            </PayerSelectionContainer>
            <FormSelectionContainer>
              <InputLabel>Select a form</InputLabel>
              <div>
                <BaseText
                  data-cy="controlSelectForm"
                  style={{ width: "250px" }}
                  onChange={(e) => {
                    this.setState({ formInputValue: e.target.value }, () => {
                      if (e.target.value.length > 3) {
                        if (!selectedPayerId) {
                          this.unsetForm();
                          this.debouncedFetchForms();
                        }
                      }
                    });
                  }}
                  value={formInputValue}
                  placeholder="Search (min. 4 characters)"
                />

                <Scrollable
                  data-cy="scrollableFormList"
                  style={{ height: "200px", width: "600px" }}
                >
                  {augmentedForms
                    .filter((form) => {
                      const matchesPayerId =
                        !selectedPayerId ||
                        form.insuranceCompanies.some(
                          (company) => company.id === selectedPayerId
                        );
                      const matchesInputValue = homogenizeString(
                        form.displayValue
                      ).includes(homogenizeString(formInputValue));
                      return matchesPayerId && matchesInputValue;
                    })
                    .map(({ id, displayValue }) => (
                      <ItemContainer
                        selected={selectedFormId === id}
                        onClick={() => {
                          this.handleFormSelection(id);
                        }}
                        key={`form-${id}`}
                      >
                        <div
                          style={{
                            maxWidth: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {displayValue}
                          </div>
                        </div>
                      </ItemContainer>
                    ))}
                </Scrollable>
              </div>
              {!_.isEmpty(selectedForm) && (
                <>
                  {!selectedPayerId && (
                    <BaseButton
                      style={{ width: "10%", margin: "0 8px", height: "35%" }}
                      onClick={() => {
                        this.unsetForm();
                      }}
                    >
                      Clear
                    </BaseButton>
                  )}
                  <BaseButton
                    style={{ width: "10%", margin: "0 8px", height: "35%" }}
                    onClick={() => {
                      this.setState({ showEditModal: true });
                    }}
                  >
                    Change Name
                  </BaseButton>
                  <BaseButton
                    style={{ width: "10%", margin: "0 8px", height: "35%" }}
                    onClick={() => {
                      this.setState({ showViewModal: true });
                    }}
                  >
                    View
                  </BaseButton>
                  <BaseButton
                    style={{ width: "10", height: "35%" }}
                    onClick={this.archiveForm}
                  >
                    {selectedForm.isArchived ? "Unarchive" : "Archive"}
                  </BaseButton>
                </>
              )}
            </FormSelectionContainer>
          </>
        )}
        {showViewModal && (
          <Modal
            onClick={() => {
              this.setState({ showViewModal: false });
            }}
            open
            header="Form Preview"
          >
            <FormConfirmationView
              formId={selectedFormId}
              onBack={() => {
                this.setState({ showViewModal: false });
              }}
              isSupport
            />
          </Modal>
        )}
        {showEditModal && (
          <EditFormTitle
            id={selectedFormId}
            title={selectedForm.title}
            description={selectedForm.description}
            refetchQuery={this.handleFetchForms}
            clear={() => {
              this.setState({ showEditModal: false });
            }}
          />
        )}
        <ContentContainer isTagger={formId}>
          {selectedFormId && (
            <ViewContainer style={{ marginRight: "16px" }}>
              <InputLabel>Associate or Create Fax Number</InputLabel>
              <InternationalPhone
                number={searchNumber}
                onChange={this.handleSearchNumberUpdate}
              />
              {searchNumber > this.MIN_SEARCH_NUMBER_LIMIT ? (
                formsNumbersToDisplay?.length > 0 ? (
                  <>
                    <InputLabel>Existing Fax Numbers</InputLabel>
                    {formNumbersLoading ? (
                      <LoaderContainer>
                        <CircularProgress />
                      </LoaderContainer>
                    ) : (
                      <ScrollContainer isTagger={formId}>
                        {_.map(formsNumbersToDisplay, (number) => (
                          <ItemContainer
                            style={
                              number.forbiddenReason ? { color: "red" } : {}
                            }
                            key={`formNumber_${number.id}`}
                          >
                            <div style={{ marginLeft: "5px" }}>
                              {formatPhoneLabel(number)}
                            </div>
                            <BaseButton
                              onClick={() => {
                                this.editFormAssociation(
                                  "numbersUpdate",
                                  number
                                );
                              }}
                            >
                              Select
                            </BaseButton>
                          </ItemContainer>
                        ))}
                      </ScrollContainer>
                    )}
                  </>
                ) : (
                  <CreateFaxNumberButton
                    variant="outlined"
                    onClick={() => {
                      this.setState({
                        newFormNumber: searchNumber,
                        searchNumber: "",
                      });
                    }}
                  >
                    Create New Number
                  </CreateFaxNumberButton>
                )
              ) : (
                ""
              )}
              <>
                <InputLabel>Form Fax Numbers</InputLabel>
                <ScrollContainer isTagger={formId}>
                  {_.map(selectedForm?.formNumbers, (number) => (
                    <ItemContainer
                      style={number.forbiddenReason ? { color: "red" } : {}}
                      key={`formNumber_${number.id}`}
                    >
                      <div style={{ marginLeft: "5px" }}>
                        {formatPhoneLabel(number)}
                      </div>
                      <BaseButton
                        onClick={() => {
                          this.editFormAssociation(
                            "numbersUpdate",
                            number,
                            true
                          );
                        }}
                      >
                        {removeButtonText}
                      </BaseButton>
                    </ItemContainer>
                  ))}
                </ScrollContainer>
              </>

              <InputLabel>Form Tagging</InputLabel>
              <AuthorizationFormTaggingControl
                authorizationFormId={selectedFormId}
              />
              {newFormNumber != null && (
                <CreateFormNumberModal
                  onClose={() => {
                    this.setState({ newFormNumber: null });
                  }}
                  insuranceCompanies={insuranceCompanies}
                  number={newFormNumber}
                  formId={selectedFormId}
                  onCreate={async () => {
                    await this.handleFetchForms(_.map(forms, "id"));
                  }}
                />
              )}
            </ViewContainer>
          )}
          {selectedFormId && (
            <ViewContainer data-cy="controlAssociateInsuranceCompany">
              <InputLabel>Associate or Create Insurance Company</InputLabel>
              <Creatable
                value={null}
                placeholder="Associate an insurance company"
                getOptionLabel={(option) => option.label ?? option.name}
                getOptionValue={(option) => option.id}
                onChange={(company) => {
                  this.editFormAssociation("payersUpdate", company, false);
                }}
                options={_.sortBy(insuranceCompanies, "name")}
                filterOption={(option, string) => {
                  if (!option?.data?.searchTags) return true;
                  return filterInsuranceCompanyOptions(option.data, string);
                }}
                isValidNewOption={(inputValue) => inputValue.length > 0}
                onCreateOption={(company) => {
                  this.setState({ newInsuranceCompany: company });
                }}
              />
              <InputLabel>Insurance Companies</InputLabel>
              <ScrollContainer data-cy="controlInsuranceCompanies">
                {_.map(selectedForm.insuranceCompanies, (company) => (
                  <ItemContainer key={`formNumber_${company.id}`}>
                    <div style={{ marginLeft: "5px" }}>{company.name}</div>
                    <BaseButton
                      data-cy={_.camelCase(`actionRemove${company.name}`)}
                      onClick={() => {
                        this.editFormAssociation("payersUpdate", company, true);
                      }}
                    >
                      {removeButtonText}
                    </BaseButton>
                  </ItemContainer>
                ))}
              </ScrollContainer>
              {newInsuranceCompany && (
                <CreateInsuranceCompanyModal
                  onClose={() => {
                    this.setState({ newInsuranceCompany: null });
                  }}
                  name={newInsuranceCompany}
                />
              )}
            </ViewContainer>
          )}
        </ContentContainer>
        {selectedFormId && (
          <FiltersContainer
            styleOverrides={{ marginBottom: "600px" }}
            filter={selectedForm.formFilter}
            associationId={selectedFormId}
            upsertFunction={this.upsertFormFilter}
            deleteFunction={this.deleteFormFilter}
            associationKey="formId"
          />
        )}
      </MainContainer>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withAssociateForm,
  withInsuranceCompanies,
  withUpsertFormFilter,
  withUpdateAuthorizationForm,
  withCurrentAccount,
  withDeleteFormOrFaxFilter
)(withApollo(withAlert()(FormEdit)));
