import { useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { Loader } from "@@ui-kit";
import InputManager from "../InputManager";
import { useConfig } from "../../../hooks";
import {
  Form,
  transformSpecToInputs,
} from "../../../util/inputConfigurationManager";
import {
  DimensionRatios,
  calculateDimensionRatio,
  getChildren,
  getDisplayInput,
  getSiblings,
} from "./utils";

export const Container = styled.div<{ width?: string }>`
  width: ${({ theme, width }) => width ?? theme.pdfWidth};
  position: relative;
  margin-bottom: 20px;
`;

export const OverlayShadow = styled.div`
  position: absolute;
  background-color: black;
  opacity: 0.2;
  z-index: ${(props) => props.theme.zLow};
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.zMiddle};
  height: 100%;
  width: 100%;
`;

export const ScrollContainer = styled.div<{ height?: string }>`
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  min-height: ${({ theme, height }) => height ?? theme.pdfWidth};
  max-height: ${({ theme, height }) => height ?? theme.pdfWidth};
  overflow: scroll;
`;

export const PDFImage = styled.img`
  user-select: none;
  width: 100%;
  z-index: ${(props) => props.theme.zDefault};
`;

export const Editor: React.VoidFunctionComponent<{
  disabled: boolean;
  form: Form;
  height?: string;
  results: unknown;
  scrollRef?: React.MutableRefObject<null>;
  segmentLoading: boolean;
  setFormFields: (fields: Record<string, unknown>) => void;
  setRequiredFields: (fields: Record<string, unknown>) => void;
  unsetRequiredFields: (fields: Record<string, unknown>) => void;
  width?: string;
}> = ({
  disabled,
  form,
  height,
  results,
  scrollRef,
  segmentLoading,
  setFormFields,
  setRequiredFields,
  unsetRequiredFields,
  width,
}) => {
  const inputTypes = useConfig().CONSTANTS.TAGGER_INPUT_TYPES;

  // Images are loaded on demand and we don't want the user to be able to
  // interact with the editor until all of the images have finished loading.
  const [dimensionRatios, setDimensionRatios] = useState(
    form.imgURLs.reduce<DimensionRatios>((acc, _key, i) => {
      return { ...acc, [i]: null };
    }, {})
  );
  const areAllPagesLoaded = Object.values(dimensionRatios).every(
    (val) => val != null
  );
  const inputs = transformSpecToInputs(form.specification);

  return (
    <Container width={width}>
      <Loader active={!areAllPagesLoaded} />

      <OverlayShadow />
      <ScrollContainer height={height} ref={scrollRef}>
        <Overlay>
          {
            // Because of the way these components are structured, InputManager
            // doesn't have a direct dependency on dimensionRatios and won't
            // rerender when it's updated. Be sure to only render this once
            // we've finished loading all of the images, and don't update
            // dimensionRatios once this has rendered.
            areAllPagesLoaded &&
              inputs.map((input) => (
                <InputManager
                  key={`InputManager_${input.id}_${input.siblingId}`}
                  formattedInput={getDisplayInput({
                    dimensionRatios,
                    disabled,
                    form,
                    input,
                    inputs,
                    results,
                    segmentLoading,
                    setFormFields,
                    inputTypes,
                  })}
                  inputChildren={{
                    ...getChildren({
                      dimensionRatios,
                      disabled,
                      form,
                      input,
                      inputs,
                      results,
                      segmentLoading,
                      setFormFields,
                      inputTypes,
                    }),
                  }}
                  results={results}
                  setRequired={setRequiredFields}
                  unsetRequired={unsetRequiredFields}
                  siblings={getSiblings({
                    dimensionRatios,
                    disabled,
                    form,
                    input,
                    inputs,
                    results,
                    segmentLoading,
                    setFormFields,
                    inputTypes,
                  })}
                />
              ))
          }
        </Overlay>

        {form.imgURLs.map((imgURL, i) => (
          <PDFImage
            key={imgURL}
            onLoad={(event) => {
              setDimensionRatios({
                ...dimensionRatios,
                [i]: calculateDimensionRatio(form, event.currentTarget),
              });
            }}
            src={imgURL}
          />
        ))}
      </ScrollContainer>
    </Container>
  );
};
