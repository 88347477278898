import _ from "lodash";
import { useEffect, useState } from "react";
import { array, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAlert } from "react-alert";
import { Stack } from "@samacare/design";
import { Button, DialogContent } from "@samacare/design/core";
import Dialog from "@samacare/design/core/Dialog";
import DialogActions from "@samacare/design/core/DialogActions";
import DialogTitle from "@samacare/design/core/DialogTitle";
import { InsuranceCompany, Portal, TrackerConfig } from "@samacare/graphql";
import {
  FormProvider,
  useForm,
  Resolver,
  AutocompleteField,
  TextField,
  CheckboxField,
} from "@samacare/form";
import { useConfig } from "../../../hooks";
import { useCreateTrackerConfig } from "../../../hooks/useCreateTrackerConfig";
import { useUpdateTrackerConfig } from "../../../hooks/useUpdateTrackerConfig";
import { usePortals } from "../../../hooks/usePortals";
import { useInsuranceCompanies } from "../../../graphql/InsuranceCompany";

const trackerConfigSchema = object<TrackerConfig>({
  trackerKey: string().required("Tracker Key is required"),
  batchKind: string().nullable(),
  authTypes: array(string()),
  portalKeys: array(string()),
  portalIds: array(string()),
  insuranceCompanyIds: array(string()),
});

interface UpsertTrackerConfigDialogProps {
  trackerConfig: TrackerConfig | null;
  trackerConfigList: TrackerConfig[];
  onClose: () => void;
}
export const UpsertTrackerConfigDialog: React.FC<
  UpsertTrackerConfigDialogProps
> = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const alert = useAlert();
  const config = useConfig();
  const createTrackerConfig = useCreateTrackerConfig();
  const updateTrackerConfig = useUpdateTrackerConfig();
  const { portals } = usePortals();
  const { insuranceCompanies } = useInsuranceCompanies();

  const methods = useForm<TrackerConfig>({
    resolver: yupResolver(trackerConfigSchema) as Resolver<TrackerConfig, any>,
  });

  const onSave = async (trackerConfig: TrackerConfig) => {
    try {
      if (isEdit) {
        await updateTrackerConfig(trackerConfig);
      } else {
        await createTrackerConfig(trackerConfig);
      }
      alert.success("Success");
      props.onClose();
    } catch (err: unknown) {
      const e = err as Error;
      alert.error(
        `There was an error creating this trackerConfig, ${e.message}`
      );
    }
  };

  useEffect(() => {
    if (props.trackerConfig) {
      methods.reset(props.trackerConfig);
      setIsEdit(!!props.trackerConfig.id);
    } else {
      methods.reset({
        // isLegacy: true,
      });
    }
  }, [props.trackerConfig, methods, setIsEdit]);

  const title = isEdit ? "Edit Tracker Config" : "Add Tracker Config";

  const allAuthTypes = Object.values(config.CONSTANTS.AUTHORIZATION_TYPES).map(
    (t) => t.value
  );

  const portalIds = methods.watch("portalIds") || [];
  const selectedPortals = _.compact(
    portalIds.map((id) => portals.find((p) => +p.id === id))
  );

  const insuranceCompanyIds = methods.watch("insuranceCompanyIds") || [];
  const selectedInsuranceCompanies = _.compact(
    insuranceCompanyIds.map((id) =>
      insuranceCompanies.find((p) => +p.id === id)
    )
  );

  const trackerConfigId = methods.watch("id");
  const allHours = [...Array(24).keys()];

  return (
    <Dialog
      open={!!props.trackerConfig}
      onClose={props.onClose}
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle>{title}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSave)}>
          <DialogContent sx={{ width: 600 }}>
            <Stack spacing={2}>
              {trackerConfigId && <TextField name="id" label="ID" disabled />}
              <AutocompleteField<string, false, true, false>
                options={config.CONSTANTS.TRACKER_KEYS}
                filterSelectedOptions
                name="trackerKey"
                label="Tracker Key"
                value={methods.watch("trackerKey") || ""}
              />
              <AutocompleteField<number, true, true, false>
                options={allHours}
                multiple
                filterSelectedOptions
                name="runAtHoursInLaTz"
                label="Run at Hours (LA Tz)"
                value={methods.watch("runAtHoursInLaTz") || []}
              />
              <AutocompleteField<string, true, true, false>
                options={allAuthTypes}
                multiple
                filterSelectedOptions
                name="authTypes"
                label="Authorization Types"
                value={methods.watch("authTypes") || []}
              />
              <AutocompleteField<string, true, true, false>
                options={config.CONSTANTS.ENABLED_PORTAL_KEYS}
                multiple
                filterSelectedOptions
                name="portalKeys"
                label="Portal Keys"
                value={methods.watch("portalKeys") || []}
              />
              <AutocompleteField<Portal, true, true, false>
                options={portals}
                getOptionLabel={(option) => option.title}
                setValueAs={(ps: Portal[]) => ps.map((p) => +p.id)}
                multiple
                filterSelectedOptions
                name="portalIds"
                label="Portals"
                value={selectedPortals}
              />
              <AutocompleteField<Partial<InsuranceCompany>, true, true, false>
                options={insuranceCompanies as InsuranceCompany[]}
                getOptionLabel={(option) => option.name as string}
                setValueAs={(ics: InsuranceCompany[]) => ics.map((p) => +p.id)}
                multiple
                filterSelectedOptions
                name="insuranceCompanyIds"
                label="Insurance Companies"
                value={selectedInsuranceCompanies}
              />
              <AutocompleteField<string, false, true, false>
                options={config.CONSTANTS.BATCH_KINDS}
                filterSelectedOptions
                name="batchKind"
                label="Batch Kind"
                value={methods.watch("batchKind") || ""}
              />
              <CheckboxField
                label="Enabled"
                name="enabled"
                checked={methods.watch("enabled")}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="actionCloseUpsertTrackerConfigDialog"
              variant="text"
              onClick={() => {
                methods.reset();
                props.onClose();
              }}
            >
              Close
            </Button>
            <Button
              data-cy="actionSaveTrackerConfig"
              type="submit"
              variant="contained"
              color="primary"
            >
              {isEdit ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
