import {
  useFormContext,
  AutocompleteField,
  type AutocompleteFieldProps,
} from "@samacare/form";
import { DrugOption, UnifiedBenefitsCheckType } from "@samacare/graphql";
import {
  useEnabledDrugOptions,
  useDrugOptions,
  useFeatureFlag,
  useConfig,
} from "../../../hooks";
import {
  EnhancedServicesIcon,
  EnahanceServiceIndicatorSingular,
} from "@samacare/component";
import { Divider, MenuItem, Stack, Typography } from "@samacare/design";

export type FullBenefitsCheckOption = {
  type: UnifiedBenefitsCheckType.Full;
  drug: DrugOption;
};

export type LiteBenefitsCheckOption = {
  type: UnifiedBenefitsCheckType.Lite;
  drug: DrugOption;
};

export type BenefitsCheckOption =
  | FullBenefitsCheckOption
  | LiteBenefitsCheckOption;

const useBenefitsCheckOptions = ({
  type,
  drugOptionId,
}: {
  type: UnifiedBenefitsCheckType | null;
  drugOptionId: string | null;
}): {
  loading: boolean;
  selected: BenefitsCheckOption | null;
  options: BenefitsCheckOption[];
} => {
  const [allDrugs, loading] = useDrugOptions();
  const { loading: getEnabledDrugLoading, drugOptions } =
    useEnabledDrugOptions();

  // build bv lite option + full bv drug options
  const allDrugOptionsForBvLite = allDrugs.map(
    (drug) =>
      ({
        type: UnifiedBenefitsCheckType.Lite,
        drug,
      } as LiteBenefitsCheckOption)
  );
  const liteOptions = allDrugs
    .filter(
      (drug) => !drugOptions.some((enabledDrug) => enabledDrug.id === drug.id)
    )
    .map(
      (drug) =>
        ({
          type: UnifiedBenefitsCheckType.Lite,
          drug,
        } as LiteBenefitsCheckOption)
    );

  const fullOptions = drugOptions.map((drug) => ({
    type: UnifiedBenefitsCheckType.Full,
    drug,
  })) as FullBenefitsCheckOption[];

  // find selected item based on form values
  let selected: BenefitsCheckOption | null = null;
  if (type === UnifiedBenefitsCheckType.Lite) {
    selected =
      allDrugOptionsForBvLite.find(
        (option) => option.drug.id === drugOptionId
      ) ?? null;
  } else if (drugOptionId == null) {
    selected = null;
  } else {
    selected =
      fullOptions.find((option) => option.drug.id === drugOptionId) ?? null;
  }

  return {
    loading: loading || getEnabledDrugLoading,
    selected,
    options: [
      ...fullOptions,
      // only include lite option when BV lite feature is enabled
      ...liteOptions,
    ],
  };
};

/////////////////////////////////////////
// AutocompleteField component helpers //
/////////////////////////////////////////

const getOptionLabel = (option: BenefitsCheckOption) => {
  const { drug } = option;
  return `${drug.code}: ${drug.drugName!}`;
};

const renderOptionByFlag = (enhancedMessagingEnabled: boolean) => {
  return (
    props: object,
    option: BenefitsCheckOption,
    { selected }: { selected: boolean }
  ) => {
    const { type, drug } = option;

    return (
      <MenuItem {...props} key={drug.id} selected={selected}>
        {drug.code}: {drug.drugName!}{" "}
        {enhancedMessagingEnabled && type === UnifiedBenefitsCheckType.Full && (
          <EnhancedServicesIcon
            sx={{ fontSize: 20 }}
            hover={false}
            key={`icon-${drug.id}`}
          />
        )}
      </MenuItem>
    );
  };
};

const isOptionEqualToValue = (
  option: BenefitsCheckOption,
  value: BenefitsCheckOption
) => option.type === value.type && option.drug.id === value.drug.id;

const setValueAs = (selected: BenefitsCheckOption | null) => {
  if (!selected) {
    return null;
  }

  return `${UnifiedBenefitsCheckType.Full}${selected.drug.id}`;
};

///////////////
// Component //
///////////////

export type BenefitsCheckOptionsProps = Omit<
  AutocompleteFieldProps<BenefitsCheckOption, false, false, false>,
  "options" | "name"
> & {
  typeFieldName: string;
  drugOptionIdFieldName: string;
  disableLiteOption?: boolean;
};

export const BenefitsCheckOptionsField: React.FC<BenefitsCheckOptionsProps> = ({
  typeFieldName,
  drugOptionIdFieldName,
  ...props
}) => {
  const { watch, setValue } = useFormContext();
  const typeValue = watch(typeFieldName) as UnifiedBenefitsCheckType | null;
  const drugOptionId = watch(drugOptionIdFieldName) as string | null;

  const config = useConfig();
  const enhancedMessagingEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEnhancedServicesMessaging
  );

  // Only show the selected value in dropdown
  // Or if no value is selected, show all enabled options
  const { loading, options, selected } = useBenefitsCheckOptions({
    type: typeValue,
    drugOptionId,
  });

  if (loading) {
    return null;
  }

  return (
    <AutocompleteField<BenefitsCheckOption, false, false, false>
      sx={{ width: 600 }}
      placeholder="Type to search service..."
      label="Service"
      name={`${typeFieldName}-${drugOptionIdFieldName}`}
      loading={loading}
      options={options}
      value={selected}
      getOptionLabel={getOptionLabel}
      renderOption={renderOptionByFlag(enhancedMessagingEnabled ?? false)}
      groupBy={(option) =>
        option.type === UnifiedBenefitsCheckType.Lite
          ? "Patient Coverage Verification Only"
          : "Drug Verification Available"
      }
      renderGroup={({ key, group, children }) => (
        <>
          {Number(key) > 0 && <Divider />}
          <Stack direction="row" alignItems="center">
            <Typography
              variant="subtitle2"
              sx={{ padding: "6px 7px 6px 16px" }}
              key={key}
            >
              {group}
            </Typography>
            {enhancedMessagingEnabled &&
              group === "Drug Verification Available" && (
                <EnahanceServiceIndicatorSingular />
              )}
          </Stack>
          {children}
        </>
      )}
      isOptionEqualToValue={isOptionEqualToValue}
      setValueAs={setValueAs}
      filterSelectedOptions
      onChange={(e, newValue) => {
        if (newValue == null) {
          setValue(typeFieldName, null);
          setValue(drugOptionIdFieldName, null);
        } else {
          setValue(typeFieldName, newValue.type);
          setValue(drugOptionIdFieldName, newValue.drug.id);
        }
      }}
      {...props}
    />
  );
};
