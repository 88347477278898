import * as React from "react";
import { useTheme } from "@samacare/design/core/styles";
import { Alert, Stack, Typography, Box, SxProps } from "@samacare/design/core";

import { ChromeWebStoreImage } from "./ChromeWebStoreImage";
import { DialogCopilotButton } from "./DialogCopilotButton";
import { PortalAutofillImage } from "./PortalAutofillImage";

interface ExtensionAlertBannerProps {
  isDownloadExtensionBanner: boolean;
  portalName?: string;
  supportsAutofill?: boolean;
  sx?: SxProps;
}

export const ExtensionAlertBanner: React.FC<ExtensionAlertBannerProps> = ({
  isDownloadExtensionBanner,
  portalName,
  supportsAutofill,
  sx,
}) => {
  const theme = useTheme();
  const handleWebExtensionClick = () => {
    window.open(
      window.CONFIG.CONSTANTS.WEB_EXTENSION_URL,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Stack
      sx={{
        width: "100%",
        mb: 2,
        maxWidth: isDownloadExtensionBanner ? "1352px" : "600px",
        ...sx,
      }}
      spacing={2}
    >
      <Box
        component={isDownloadExtensionBanner ? "a" : "div"}
        href={
          isDownloadExtensionBanner
            ? window.CONFIG.CONSTANTS.WEB_EXTENSION_URL
            : undefined
        }
        target={isDownloadExtensionBanner ? "_blank" : undefined}
        rel={isDownloadExtensionBanner ? "noopener noreferrer" : undefined}
        sx={{
          textDecoration: "none",
          width: "100%",
        }}
      >
        <Alert
          action={
            isDownloadExtensionBanner && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  marginRight: 1.25,
                }}
              >
                <DialogCopilotButton
                  text="Download CoPilot"
                  onClick={handleWebExtensionClick}
                  sx={{ marginTop: "-4px" }}
                />
              </Box>
            )
          }
          sx={{
            backgroundColor:
              isDownloadExtensionBanner ||
              (!isDownloadExtensionBanner && !supportsAutofill)
                ? theme.palette.warning.mediumLight
                : theme.palette.success.veryLight,
            color:
              isDownloadExtensionBanner ||
              (!isDownloadExtensionBanner && !supportsAutofill)
                ? theme.palette.warning.veryDark
                : theme.palette.success.dark,
            "& .MuiAlert-icon": {
              display: "none",
            },
            py: 1,
            px: 2,
            minHeight: "64px",
            display: "flex",
            alignItems: "center",
            cursor: isDownloadExtensionBanner ? "pointer" : "default",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            {!isDownloadExtensionBanner && supportsAutofill !== undefined ? (
              <>
                <PortalAutofillImage supportsAutofill={supportsAutofill} />
                <Stack direction="column" spacing={0.5}>
                  {" "}
                  <Typography sx={{ fontWeight: 550, fontSize: "16px" }}>
                    Enter details to enable status checks and generate enhanced
                    reports
                  </Typography>
                  <Typography sx={{ fontWeight: 500, fontSize: "15px" }}>
                    {supportsAutofill
                      ? `Click the purple lotus flower to autofill once you're in ${portalName}`
                      : `Autofill function is not yet supported in ${portalName}`}
                  </Typography>
                </Stack>
              </>
            ) : (
              <>
                <ChromeWebStoreImage />
                <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                  Now Available - CoPilot Chrome Extension, Captures and Stores
                  PAs From any Portal
                </Typography>
              </>
            )}
          </Stack>
        </Alert>
      </Box>
    </Stack>
  );
};
