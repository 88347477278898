import _ from "lodash";
import { useState } from "react";
import styled from "styled-components";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import strings from "Resources/strings";
import colors from "Resources/colors";
import Modal from "./Modal";
import { ModalBody, ModalContent } from "./ModalStyledComponents";
import BaseButton from "./BaseButton";
import { useAccount } from "../providers/AccountProvider";
import { segmentTypewriter } from "../../analytics/analytics";
import { DeleteReason } from "../../analytics/generated/segment";
import { createAuthorizationErrorMutation } from "../graphql/AuthorizationError";
import { Box } from "@@ui-kit";

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;
  font-size: 16px;
  margin-top: 5px;
`;

const ErrorButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;

  ${BaseButton} {
    margin-top: 10px;
  }
`;

const ResponseTextArea = styled.textarea`
  resize: none;
  height: 200px;
  padding: 10px 5px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;

  &:focus {
    outline: 0;
  }
`;

const GrayButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.lightGray};
  border-color: ${(props) => props.theme.lightGray};
  color: white;
`;

const { AUTHORIZATION_ERROR_TYPES } = CONFIG.CONSTANTS;
const ERRORS_REQUIRING_EXPLANATION = [
  AUTHORIZATION_ERROR_TYPES.FORM_RENDER_INCORRECT,
  AUTHORIZATION_ERROR_TYPES.OTHER,
];

const FormAuthorizationErrorModal = ({
  open,
  close,
  authorization,
  deleteAuth,
  authorizationType,
}) => {
  const [explanationStatus, setExplanationStatus] = useState(null);
  const [formText, setFormText] = useState("");
  const alert = useAlert();
  const account = useAccount();

  const [createAuthorizationError] = useMutation(
    createAuthorizationErrorMutation
  );

  const onClose = () => {
    setExplanationStatus(null);
    setFormText("");
    close();
  };

  const authorizationId = parseInt(authorization.id);

  const sendSegmentEvent = (type) => {
    segmentTypewriter.paDeleted({
      accountId: parseInt(account.id),
      email: account.email,
      isAdmin: account.isAdmin,
      institutionIsTop: account.institution.isTop,
      institutionName: account.institution.name,
      institutionSpecialty: account.institution.practicingSpecialty,
      internalAuthId: authorizationId,
      paType: authorization.type,
      deleteReason: type,
    });
  };

  const handleCreateAuthorizationError = async (type, message) => {
    if (_.includes(ERRORS_REQUIRING_EXPLANATION, type) && !message) {
      alert.error("Explanation is required for this error type");
    } else {
      try {
        await createAuthorizationError({
          variables: { authorizationId, type, message },
        });
        alert.success(strings.REPORT_ERROR.ERROR_SUBMIT_SUCCESS);
      } catch (e) {
        alert.error(strings.REPORT_ERROR.ERROR_DETAILS_FAILURE);
      } finally {
        deleteAuth();
        sendSegmentEvent(DeleteReason.Mistagged);
        onClose();
      }
    }
  };

  if (explanationStatus) {
    return (
      <Modal
        header="Please provide a description of the issue"
        onClick={onClose}
        open={open}
      >
        <ModalBody>
          <CustomModalContent>
            <ErrorButtonContainer>
              <ResponseTextArea
                value={formText}
                onChange={(e) => setFormText(e.target.value)}
              />
              <Box display="flex" justifyContent="space-between">
                <BaseButton
                  color={colors.lightGray}
                  style={{ width: "45%" }}
                  onClick={() => setExplanationStatus(null)}
                >
                  Back
                </BaseButton>
                <BaseButton
                  style={{ width: "45%" }}
                  onClick={() =>
                    handleCreateAuthorizationError(explanationStatus, formText)
                  }
                >
                  Submit
                </BaseButton>
              </Box>
            </ErrorButtonContainer>
          </CustomModalContent>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      header={`Delete this ${authorizationType}`}
      onClick={onClose}
      open={open}
    >
      <ModalBody>
        <CustomModalContent>
          {`I'm deleting this ${authorizationType} because...`}
          <ErrorButtonContainer>
            <BaseButton
              onClick={() =>
                setExplanationStatus(
                  AUTHORIZATION_ERROR_TYPES.FORM_RENDER_INCORRECT
                )
              }
            >
              This form is mistagged
            </BaseButton>
            <BaseButton
              onClick={async () => {
                deleteAuth();
                sendSegmentEvent(DeleteReason.Other);
                onClose();
              }}
            >
              Not needed / Other reasons
            </BaseButton>
            <GrayButton onClick={onClose}>Cancel</GrayButton>
          </ErrorButtonContainer>
        </CustomModalContent>
      </ModalBody>
    </Modal>
  );
};

export default FormAuthorizationErrorModal;
