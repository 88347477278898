import { useMutation } from "@apollo/client";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { useCallback } from "react";
import { useAlert } from "react-alert";
import {
  Controller,
  FieldError,
  UseFormRegister,
  useForm,
} from "@samacare/form";
import OriginalSelect, { CSSObjectWithLabel } from "react-select";
import styled from "styled-components";

import { Flex } from "@@ui-kit";
import { Form, Input, NumericInput } from "@@ui-kit/forms";
import { Grid, Typography } from "@samacare/design/core";
import {
  BenefitsVerification,
  BenefitsVerificationOutcome,
} from "@samacare/graphql";

import { withUpdateBenefitsVerificationOutcomeMutation } from "../../graphql/BenefitsVerificationOutcome";
import {
  DATE,
  DROPDOWN,
  HCPCS_OBJECT,
  ID,
  NUMBER,
  PHONE,
  TEXT,
  TIMESTAMP,
  col1Info,
  col2Info,
} from "./BenefitsVerificationConstants";
import { useBenefitsVerificationContext } from "./BeneftsVerificationProvider";
import { humanizeBVOutcomeValue } from "./bvUtils";
import { validateDateOfBirth, validatePhone } from "./common";
import { FormSection } from "./components/FormSection";
import {
  ColumnDataContainer,
  DirtyFieldsErrorType,
  EditableFieldsErrorType,
  HCPCSCodeDetails,
  Option,
  OutcomeData,
  booleanDict,
  genericDict,
} from "./interfaces";

// inputs
const StyledSelect = styled(OriginalSelect)`
  display: flex;
  margin-bottom: 12px;
  width: 100%;
  height: 24px;
  min-height: 24px;
`;

const StyledNumericInput = styled(NumericInput)`
  display: flex;
  width: 100%;
  height: 35px;
  margin-bottom: 4px;
  border-color: hsl(0, 0%, 80%);
  padding: 8px;
  border: 1px solid
    ${({ error = false, theme: { colors } }) => {
      if (error === true) {
        return colors.red;
      }
      return "hsl(0, 0%, 80%)";
    }};
`;

const StyledInput = styled(Input)`
  padding: 8px;
  width: 100%;
  height: 32px;
  margin-bottom: 4px;
  border-color: hsl(0, 0%, 80%);
`;

const DataCol = styled(Flex)`
  flex-direction: column;
  width: 492px;
  justify-content: flex-start;
  padding-right: 28px;
`;

const BodyTitleText: React.FC = ({ children }) => (
  <Typography
    variant="subtitle2"
    display="inline-block"
    color="rgba(0, 0, 0, 0.6)"
  >
    {children}
  </Typography>
);

const VALIDATE = "validate";

const FormField: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <>
      <Grid item xs={6} marginTop={1}>
        <BodyTitleText>{label}</BodyTitleText>
      </Grid>
      <Grid item xs={6} marginTop={1}>
        {children}
      </Grid>
    </>
  );
};

export const EditableFieldsContainer: React.VoidFunctionComponent<{
  colData: ColumnDataContainer[];
  register: UseFormRegister<genericDict>;
  outcomeData?: OutcomeData | null;
  errors: EditableFieldsErrorType;
  dirtyFields: DirtyFieldsErrorType;
}> = ({ colData, outcomeData, errors, dirtyFields }) => {
  return (
    <DataCol>
      {colData.map((section) => {
        const { title, data } = section;
        const rows = data.map(({ key, label, editType, options }) => {
          const value = outcomeData == null ? null : outcomeData[key];

          switch (editType) {
            case ID:
              return (
                <FormField key={key} label={label}>
                  <Typography
                    variant="body2"
                    data-cy={_.camelCase(`field_${key}`)}
                    key={key}
                  >
                    {String(value).slice(-5)}
                  </Typography>
                </FormField>
              );
            case TIMESTAMP:
              return (
                <FormField key={key} label={label}>
                  <Typography
                    variant="body2"
                    data-cy={_.camelCase(`field_${key}`)}
                    key={key}
                  >
                    {moment(String(value)).format("MM/DD/YYYY HH:MMA")}
                  </Typography>
                </FormField>
              );
            case NUMBER:
              return (
                <FormField key={key} label={label}>
                  <Controller
                    name={key}
                    render={({ field }) => (
                      <StyledNumericInput
                        data-cy={_.camelCase(`field_${key}`)}
                        key={key}
                        format="$########"
                        {...field}
                      />
                    )}
                  />
                </FormField>
              );
            case PHONE:
              return (
                <FormField key={key} label={label}>
                  <Controller
                    data-cy={_.camelCase(`field_${key}`)}
                    key={key}
                    rules={{
                      validate:
                        dirtyFields[key] != null ? validatePhone : undefined,
                    }}
                    name={key}
                    render={({ field }) => (
                      <StyledNumericInput
                        error={
                          errors[key] != null &&
                          (errors[key] as FieldError)?.type === VALIDATE
                        }
                        placeholder="###-###-####"
                        format="###-###-####"
                        {...field}
                      />
                    )}
                  />
                </FormField>
              );
            case DATE:
              return (
                <FormField key={key} label={label}>
                  <Controller
                    name={key}
                    rules={{
                      validate:
                        dirtyFields[key] != null
                          ? validateDateOfBirth
                          : undefined,
                    }}
                    render={({ field }) => (
                      <StyledNumericInput
                        data-cy={_.camelCase(`field_${key}`)}
                        key={key}
                        error={
                          errors[key] != null &&
                          (errors[key] as FieldError)?.type === VALIDATE
                        }
                        placeholder="MM/DD/YYYY"
                        format="##/##/####"
                        {...field}
                      />
                    )}
                  />
                </FormField>
              );
            case DROPDOWN:
              return (
                <FormField key={key} label={label}>
                  <Controller
                    name={key}
                    render={({ field }) => (
                      <StyledSelect
                        data-cy={_.camelCase(`field_${key}`)}
                        key={key}
                        styles={{
                          control: (baseStyles) =>
                            ({
                              ...baseStyles,
                              width: "100%",
                              height: "36px",
                              minHeight: "28px",
                              padding: "0px",
                            } as CSSObjectWithLabel),
                          placeholder: (defaultStyles) => {
                            return {
                              ...defaultStyles,
                              color: "black",
                            } as CSSObjectWithLabel;
                          },
                        }}
                        // isClearable
                        placeholder={
                          value != null &&
                          humanizeBVOutcomeValue(value as string, DROPDOWN)
                        }
                        options={options ?? []}
                        {...field}
                      />
                    )}
                  />
                </FormField>
              );
            case TEXT:
              return (
                <FormField key={key} label={label}>
                  <Controller
                    name={key}
                    render={({ field }) => (
                      <StyledInput
                        data-cy={_.camelCase(`field_${key}`)}
                        key={key}
                        {...field}
                      />
                    )}
                  />
                </FormField>
              );
            case HCPCS_OBJECT:
              if (value == null) {
                return;
              }

              return [
                ...(value as unknown as HCPCSCodeDetails[]).map(({ code }) => {
                  return (
                    <FormField key={key} label={label}>
                      <Controller
                        name={code}
                        render={({ field }) => <StyledInput {...field} />}
                      />
                    </FormField>
                  );
                }),
              ];
            case null:
              return (
                <FormField key={key} label={label}>
                  <Typography
                    variant="body2"
                    data-cy={_.camelCase(`field_${key}`)}
                    key={key}
                  >
                    {value}
                  </Typography>
                </FormField>
              );
            default:
              return null;
          }
        });
        return (
          <FormSection title={title} key={title}>
            <Grid container>{rows}</Grid>
          </FormSection>
        );
      })}
    </DataCol>
  );
};

const createDefaultValuesForBenefitsVerification = (
  benefitsVerification: BenefitsVerification
) => {
  const outcome = benefitsVerification.outcome as BenefitsVerificationOutcome;
  const outcomeData = outcome?.data;

  return {
    ...outcomeData,
    startDate:
      outcomeData?.startDate != null
        ? moment(outcomeData?.startDate).format("MMDDYYYY")
        : null,
    endDate:
      outcomeData?.endDate != null
        ? moment(outcomeData?.endDate).format("MMDDYYYY")
        : null,
    HCPCSCodeDetails: null,
  };
};

export const EditOutcomeContainer: React.VoidFunctionComponent<{
  onClose: () => void;
}> = ({ onClose }) => {
  const context = useBenefitsVerificationContext();
  const benefitsVerification = context.benefitsVerification!;

  const hideSecondaryInsuranceInfo =
    benefitsVerification?.request.secondaryInsuranceDetails == null;

  const formContext = useForm<genericDict>({
    defaultValues:
      createDefaultValuesForBenefitsVerification(benefitsVerification),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    register,
  } = formContext;

  const alert = useAlert();
  const [updateBenefitsVerificationOutcome] = useMutation(
    withUpdateBenefitsVerificationOutcomeMutation
  );

  // TODO clean this up... no reason for so much logic here
  const onSubmit = useCallback(
    async ({ ...input }: genericDict) => {
      const TYPENAME = "__typename";
      // Create dictionary for dropdown keys
      const dropdownDict: booleanDict = {};
      const shortdateDict: booleanDict = {};
      const HCPCSCodeDict: booleanDict = {};

      [...col1Info, ...col2Info].forEach(({ data }) => {
        data.forEach((obj) => {
          if (obj.editType === DROPDOWN) {
            dropdownDict[obj.key] = true;
          }
          if (obj.editType === DATE) {
            shortdateDict[obj.key] = true;
          }
        });
      });

      const HCPCSCodes =
        (benefitsVerification?.request.drugDetails?.hcpcsCodes as string[]) ??
        [];
      HCPCSCodes.forEach((code) => (HCPCSCodeDict[code] = true));

      const outcomeFieldInputs: genericDict = {};
      const HCPCSCodeDetailsInput: {
        code: string;
        response: string;
      }[] = [];
      for (const key in input) {
        const val = input[key];
        // skip unedited fields and __typename field from DB data
        if (val == null || key === TYPENAME) {
          continue;
        }
        // convert dropdown values into strings/booleans
        else if (dropdownDict[key]) {
          outcomeFieldInputs[key] = (val as Option).value;
        }
        // convert date values into short date format
        else if (shortdateDict[key]) {
          // outcomeFieldInputs[key] = new Date(humanizeShortDate(val as string));
          outcomeFieldInputs[key] = moment(val as string, "MMDDYYYY").toDate();
        }
        // HCPCS stuff
        else if (HCPCSCodeDict[key]) {
          HCPCSCodeDetailsInput.push({ code: key, response: val as string });
        }
        // return any edited non-dropdown fields in given form
        else {
          outcomeFieldInputs[key] = val;
        }
      }

      outcomeFieldInputs.HCPCSCodeDetails = HCPCSCodeDetailsInput;

      try {
        await updateBenefitsVerificationOutcome({
          variables: {
            patch: {
              benefitsVerificationId: benefitsVerification.id,
              outcomeFields: {
                ...outcomeFieldInputs,
              },
            },
          },
          refetchQueries: ["getBenefitsVerificationById"],
        });
        alert.success("Benefits Verification Outcome updated");
        onClose();
      } catch (err) {
        alert.error(`Error: ${(err as Error).message}`);
      }
    },
    [close, alert, updateBenefitsVerificationOutcome, benefitsVerification]
  );

  return (
    <Form
      context={formContext}
      id="formBenefitsVerification"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container>
        <Grid item xs={6}>
          <EditableFieldsContainer
            outcomeData={
              benefitsVerification.outcome?.data as OutcomeData | null
            }
            register={register}
            colData={col1Info}
            errors={errors as EditableFieldsErrorType}
            dirtyFields={dirtyFields as DirtyFieldsErrorType}
          />
        </Grid>
        <Grid item xs={6}>
          <EditableFieldsContainer
            outcomeData={
              benefitsVerification.outcome?.data as OutcomeData | null
            }
            register={register}
            colData={
              hideSecondaryInsuranceInfo
                ? col2Info.filter(
                    ({ title }) => title !== "Seconday Insurance Details"
                  )
                : col2Info
            }
            errors={errors as EditableFieldsErrorType}
            dirtyFields={dirtyFields as DirtyFieldsErrorType}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
