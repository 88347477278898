import { useCallback } from "react";
import { useDispatch } from "../configureStore";
import { setPrescriberId } from "../reducers/formReducer";

export const useSetPrescriberId = () => {
  const dispatch = useDispatch();

  return useCallback(
    (PrescriberId: string) => {
      dispatch(setPrescriberId(PrescriberId));
    },
    [dispatch]
  );
};
