import * as React from "react";
import styled from "styled-components";
import { invert, transparentize } from "polished";
import { Spinner } from "./Spinner";
import { Flex, Box } from "./Flexbox";

function identity<T>(value: T): T {
  return value;
}

const Container = styled(Flex)<{
  inverted: boolean;
  overlayColor?: string;
  overlayTransparency?: number;
}>`
  background-color: ${({
    inverted = false,
    overlayColor,
    overlayTransparency = 0.15,
  }) =>
    transparentize(
      overlayTransparency,
      overlayColor ?? (inverted ? invert : identity)("hsl(0, 0%, 0%)")
    )};
  color: ${({ inverted }) =>
    (inverted ? invert : identity)("hsl(0, 0%, 100%)")};

  padding: 1em;
  & > * {
    margin-bottom: 0.6em;
  }

  /* The overlay should expand to fill its parent and ignore padding, etc. */
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  /* Center the spinner and text */
  align-items: center;
  flex-direction: column;
  justify-content: center;

  /* Display the loader on top of other elements */
  z-index: 1000;
`;

export type LoaderProps = React.ComponentProps<typeof Loader>;

/**
 * Loader is an overlay that is displayed above loading content. It takes the
 * dimensions of the element it wraps and displays a (configurable) loading
 * spinner and message, which is diplayed on top of an overlay (which is semi-
 * transparent black by default).
 */
/**
 * @deprecated Use a MUI component instead
 */
export const Loader: React.VoidFunctionComponent<{
  active: boolean;
  content?: React.ReactNode;
  inverted?: boolean;
  overlayColor?: string;
  overlayTransparency?: number;
}> = ({ active, content = "Loading…", inverted = false, ...rest }) =>
  active ? (
    <Container inverted={inverted} {...rest}>
      <Box>
        <Spinner inverted={inverted} width={50} height={50} />
      </Box>
      <Box>{content}</Box>
    </Container>
  ) : null;
