import * as React from "react";
import InfoOutlinedIcon from "@samacare/design/core/icons/InfoOutlined";
import { Tooltip } from "@samacare/design/core";

type HelpTagProps = {
  text: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

const SIZE_MAP = {
  xs: "1.1em",
  sm: "1.2em",
  md: "1.3em",
  lg: "1.4em",
  xl: "1.8em",
};

// We send a pendo track event if the user hovers over this for more than a few seconds
const PENDO_TRACK_DELAY_MS = 2000;

export const HelpTag: React.VFC<HelpTagProps> = (props) => {
  const openTime = React.useRef<number | null>(null);
  return (
    <Tooltip
      enterDelay={200}
      title={props.text}
      onOpen={() => (openTime.current = new Date().getTime())}
      onClose={() => {
        if (!openTime.current) return;
        const delta = new Date().getTime() - openTime.current;
        openTime.current = null;

        if (!window.pendo || delta < PENDO_TRACK_DELAY_MS) return; // They didn't hover for very long, ignore it

        window.pendo.track("HelpTagHovered", {
          text: props.text,
          hoverTime: delta,
          url: window.location.href,
        });
      }}
    >
      <InfoOutlinedIcon
        sx={{ mx: 1, fontSize: SIZE_MAP[props.size ?? "md"] }}
        color="inherit"
      />
    </Tooltip>
  );
};
