import { Box, Typography, CircularProgress } from "@samacare/design/core";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";

export const AuthRoute: React.FC = ({ children, ...props }) => {
  const { loading, isEmailVerified, isSignedIn, isChecked } = useAuth();

  const isLoading = loading || !isChecked;
  const isAuthenticated = isSignedIn && isEmailVerified;

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        mt={20}
        flexDirection="column"
        height="100%"
      >
        <CircularProgress size={60} />
        <Typography variant="body1" color="primary" mt={2}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
