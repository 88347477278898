import { ApolloError } from "@apollo/client";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import CircularProgress from "@samacare/design/core/CircularProgress";
import { Account, BenefitsVerification } from "@samacare/graphql";

import { useBenefitsVerification } from "../../hooks/useBenefitsVerification";
import { useAccount } from "../../providers/AccountProvider";

type BenefitsVerificationContextType = {
  loading: boolean;
  errors?: ApolloError;
  currentAccount?: Account | null;
  benefitsVerification: BenefitsVerification | null;
};

const BenefitsVerificationContext =
  createContext<BenefitsVerificationContextType>({
    loading: false,
    currentAccount: null,
    benefitsVerification: null,
  });

export const useBenefitsVerificationContext =
  (): BenefitsVerificationContextType =>
    useContext<BenefitsVerificationContextType>(BenefitsVerificationContext);

export const BenefitsVerificationProvider: React.FC = ({ children }) => {
  const params = useParams<{ id?: string | undefined }>();
  const bvResult = useBenefitsVerification(params.id);
  const currentAccount = useAccount();

  const value = useMemo(() => {
    return {
      loading: bvResult.loading,
      errors: bvResult.error,
      benefitsVerification: bvResult.benefitsVerification,
      currentAccount,
    };
  }, [bvResult, currentAccount]);

  if (bvResult.loading) {
    return <CircularProgress />;
  }
  return (
    <BenefitsVerificationContext.Provider value={value}>
      {children}
    </BenefitsVerificationContext.Provider>
  );
};
