import factory from "./Typography.factory";

export { default } from "./Typography";
export type { TypographyProps } from "./Typography";

export const Span = factory("span");
export const Heading1 = factory("h1");
export const Heading2 = factory("h2");
export const Heading3 = factory("h3");
export const Heading4 = factory("h4");
export const Heading5 = factory("h5");
export const Heading6 = factory("h6");
export const Paragraph = factory("p");
