import gql from "graphql-tag";
import _ from "lodash";
import { graphql } from "@apollo/client/react/hoc";

import { CURRENT_ACCOUNT_QUERY_NAME } from "./Account";
import institutionInfo from "./fragments/institutionInfo";
import detailedInstitutionInfo from "./fragments/detailedInstitutionInfo";
import { withDefaultProps } from "./utils";

export const withGetInstitutionsByIds = gql`
  query getInstitutionsByIds($institutionIds: [Int]) {
    getInstitutionsByIds(institutionIds: $institutionIds) {
      ...institutionInfo
      featureFlagsRaw
      launchDarklyFeatureFlags {
        EnableHealixDashboard
        InstitutionFormFilter {
          allowList
          blockList
        }
        AvailableEnrollmentPrograms
        EnableLetterOfMedicalNecessity
        EnableEligibilityCheck
        EnableBenefitsVerification
        CareMetxEnrollmentLD
        EnablePatientFoundation
        EnableNovartisIptacopan
        EnableNovartisRLT
        SamaAssist
        NextGenDataSync
        SimplifiedFaxWorkflowUx
      }
    }
  }
  ${institutionInfo}
`;

export const allInstitutionsQuery = gql`
  query Institutions {
    institutionsFindAll {
      ...institutionInfo
    }
  }
  ${institutionInfo}
`;

export const withInstitutions = graphql(allInstitutionsQuery, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    institutions: _.get(data, "institutionsFindAll"),
  })),
});

export const SUPPORT_INSTITUTION_QUERY = "supportInstitutionQuery";
export const DETAILED_INSTITUTIONS_QUERY = "DetailedInstitutions";
const withDetailedInstitutionsQuery = gql`
  query DetailedInstitutions {
    institutionsFindAll {
      ...detailedInstitutionInfo
    }
  }
  ${detailedInstitutionInfo}
`;

export const withDetailedInstitutions = graphql(withDetailedInstitutionsQuery, {
  options: { fetchPolicy: "network-only" },
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    institutions: _.get(data, "institutionsFindAll"),
  })),
});

export const updateInstitutionForSamaUsersMutation = gql`
  mutation updateInstitutionForSamaUsers($id: Int!, $patch: JSON!) {
    updateInstitutionForSamaUsers(id: $id, patch: $patch) {
      ...institutionInfo
    }
  }
  ${institutionInfo}
`;

export const withUpdateInstitutionForSamaUsers = graphql(
  updateInstitutionForSamaUsersMutation,
  {
    name: "updateInstitutionForSamaUsers",
    options: () => ({
      refetchQueries: () => [
        DETAILED_INSTITUTIONS_QUERY,
        SUPPORT_INSTITUTION_QUERY,
      ],
    }),
  }
);

const withUpdateInstitutionUploadCategoryMutation = gql`
  mutation updateInstitutionUploadCategory($emrUploadCategory: String!) {
    updateInstitutionUploadCategory(emrUploadCategory: $emrUploadCategory) {
      ...institutionInfo
    }
  }
  ${institutionInfo}
`;

export const withUpdateInstitutionUploadCategory = graphql(
  withUpdateInstitutionUploadCategoryMutation,
  {
    name: "updateInstitutionUploadCategory",
    options: () => ({
      refetchQueries: () => [CURRENT_ACCOUNT_QUERY_NAME],
    }),
  }
);

const withCreateInstitutionMutation = gql`
  mutation createInstitution {
    createInstitution {
      ...institutionInfo
    }
  }
  ${institutionInfo}
`;

export const withCreateInstitution = graphql(withCreateInstitutionMutation, {
  name: "createInstitution",
  options: () => ({
    refetchQueries: () => [
      DETAILED_INSTITUTIONS_QUERY,
      SUPPORT_INSTITUTION_QUERY,
    ],
  }),
});

export const deleteInstitutionMutation = gql`
  mutation deleteInstitution($id: Int!) {
    deleteInstitution(id: $id)
  }
`;

export const withDeleteInstitution = graphql(deleteInstitutionMutation, {
  name: "deleteInstitution",
  options: () => ({ refetchQueries: () => [DETAILED_INSTITUTIONS_QUERY] }),
});

export const updateInstitutionMutation = gql`
  mutation updateInstitution($id: Int!, $patch: InstitutionUpdatePatch!) {
    updateInstitution(id: $id, patch: $patch) {
      id
    }
  }
`;
