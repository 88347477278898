import {
  BenefitsVerificationOutcomeData,
  BenefitsVerificationOutcomeDrugBuyBillStatus,
  BenefitsVerificationOutcomeInsuranceCoverageStatus,
  BenefitsVerificationOutcomeStatus,
} from "@samacare/graphql";

export const ID = "id";
export const TIMESTAMP = "timestamp";
export const DROPDOWN = "dropdown";
export const DATE = "date";
export const TEXT = "text";
export const NUMBER = "number";
export const PHONE = "phone";
export const HCPCS_OBJECT = "HCPCSObject";

const YesNoOptions: FormOption[] = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
  {
    label: "Unknown",
    value: null,
  },
];

export interface FormOption {
  label: string;
  value: string | boolean | null;
}

export interface FormFieldDefinition {
  label: string;
  key: keyof BenefitsVerificationOutcomeData;
  editType:
    | typeof ID
    | typeof TIMESTAMP
    | typeof NUMBER
    | typeof TEXT
    | typeof DROPDOWN
    | typeof PHONE
    | typeof DATE
    | typeof HCPCS_OBJECT
    | null;
  options: FormOption[] | null;
}

export interface FormSectionDefinition {
  title: string;
  data: FormFieldDefinition[];
}

export const col1Info: FormSectionDefinition[] = [
  {
    title: "Benefits Verification Status",
    data: [
      {
        label: "BV Status",
        key: "status",
        editType: DROPDOWN,
        options: [
          {
            label: "Pending",
            value: BenefitsVerificationOutcomeStatus.Pending,
          },
          {
            label: "Success",
            value: BenefitsVerificationOutcomeStatus.Success,
          },
          {
            label: "Error",
            value: BenefitsVerificationOutcomeStatus.Error,
          },
        ],
      },
      {
        label: "Request ID",
        key: "id",
        editType: ID,
        options: null,
      },
      {
        label: "Requested By",
        key: "requestedBy",
        editType: null,
        options: null,
      },
      {
        label: "Reference #",
        key: "externalReferenceNumber",
        editType: TEXT,
        options: null,
      },
      {
        label: "Additional Notes",
        key: "notes",
        editType: TEXT,
        options: null,
      },
    ],
  },
  {
    title: "Prior Authorization",
    data: [
      {
        label: "Auth Required",
        key: "isAuthRequired",
        editType: DROPDOWN,
        options: YesNoOptions,
      },
      {
        label: "Auth On file",
        key: "isAuthOnFile",
        editType: DROPDOWN,
        options: YesNoOptions,
      },
      {
        label: "Auth #",
        key: "authNumber",
        editType: TEXT,
        options: null,
      },
      {
        label: "Plan Start Date",
        key: "startDate",
        editType: DATE,
        options: null,
      },
      {
        label: "Plan End Date",
        key: "endDate",
        editType: DATE,
        options: null,
      },
      {
        label: "Fax #",
        key: "payorPaFax",
        editType: PHONE,
        options: null,
      },
      {
        label: "Phone #",
        key: "payorPaPhone",
        editType: PHONE,
        options: null,
      },
      {
        label: "Authorization Process",
        key: "authProcess",
        editType: TEXT,
        options: null,
      },
      {
        label: "Step Therapy Required",
        key: "isStepTherapyRequired",

        editType: DROPDOWN,
        options: YesNoOptions,
      },
      {
        label: "Step Therapy Process",
        key: "stepTherapyProcess",

        editType: TEXT,
        options: null,
      },
    ],
  },
];

export const col2Info: FormSectionDefinition[] = [
  {
    title: "Insurance Details",
    data: [
      {
        label: "Coverage",
        key: "insuranceCoverageStatus",
        editType: DROPDOWN,
        options: [
          {
            label: "Active",
            value: BenefitsVerificationOutcomeInsuranceCoverageStatus.Active,
          },
          {
            label: "Expired",
            value: BenefitsVerificationOutcomeInsuranceCoverageStatus.Expired,
          },
          {
            label: "Unknown",
            value: BenefitsVerificationOutcomeInsuranceCoverageStatus.Unknown,
          },
        ],
      },
      {
        label: "Drug / Code covered by Plan",
        key: "isDrugCoveredByPlan",
        editType: DROPDOWN,
        options: YesNoOptions,
      },
      {
        label: "Drug covered under",
        key: "drugBuyBillStatus",
        editType: DROPDOWN,
        options: [
          {
            label: "Medical/Buy and Bill",
            value: BenefitsVerificationOutcomeDrugBuyBillStatus.Medical,
          },
          {
            label: "Pharmacy",
            value: BenefitsVerificationOutcomeDrugBuyBillStatus.Pharmacy,
          },
          {
            label: "Unknown",
            value: BenefitsVerificationOutcomeDrugBuyBillStatus.Unknown,
          },
        ],
      },
      {
        label: "Outcome Determined at",
        key: "updatedAt",
        editType: TIMESTAMP,
        options: null,
      },
    ],
  },
  {
    title: "Seconday Insurance Details",
    data: [
      {
        label: "Coverage",
        key: "secondaryinsuranceCoverageStatus",
        editType: DROPDOWN,
        options: [
          {
            label: "Active",
            value: BenefitsVerificationOutcomeInsuranceCoverageStatus.Active,
          },
          {
            label: "Expired",
            value: BenefitsVerificationOutcomeInsuranceCoverageStatus.Expired,
          },
          {
            label: "Unknown",
            value: BenefitsVerificationOutcomeInsuranceCoverageStatus.Unknown,
          },
        ],
      },
      {
        label: "Drug / Code covered by Plan",
        key: "isDrugCoveredBySecondaryInsurance",
        editType: DROPDOWN,
        options: YesNoOptions,
      },
      {
        label: "Drug covered under",
        key: "drugBuyBillStatusForSecondaryInsurance",
        editType: DROPDOWN,
        options: [
          {
            label: "Medical/Buy and Bill",
            value: BenefitsVerificationOutcomeDrugBuyBillStatus.Medical,
          },
          {
            label: "Pharmacy",
            value: BenefitsVerificationOutcomeDrugBuyBillStatus.Pharmacy,
          },
          {
            label: "Unknown",
            value: BenefitsVerificationOutcomeDrugBuyBillStatus.Unknown,
          },
        ],
      },
      {
        label: "Outcome Determined at",
        key: "updatedAt",
        editType: TIMESTAMP,
        options: null,
      },
    ],
  },
  {
    title: "Financial Details",
    data: [
      {
        label: "Copay - Office Visit",

        key: "copayOffice",
        editType: TEXT,
        options: null,
      },
      {
        label: "Copay - Drug",

        key: "copayDrug",
        editType: TEXT,
        options: null,
      },
      {
        label: "Copay - Services",

        key: "copayServices",
        editType: TEXT,
        options: null,
      },

      {
        label: "Copay - Specialist",

        key: "copaySpecialist",
        editType: TEXT,
        options: null,
      },
      {
        label: "Copay - PCP",
        key: "copayPcp",
        editType: TEXT,
        options: null,
      },
      {
        label: "Deductible Met",
        key: "isDeductibleMet",
        editType: DROPDOWN,
        options: YesNoOptions,
      },
      {
        label: "Deduct. Amount Accumulated",
        key: "deductibleTotal",
        editType: NUMBER,
        options: null,
      },
      {
        label: "Deduct. Amount Remaining",
        key: "deductibleRemaining",
        editType: NUMBER,
        options: null,
      },
      {
        label: "Deductible Applied to OOP",
        key: "isDeductibleAppliedToOop",
        editType: DROPDOWN,
        options: YesNoOptions,
      },
      {
        label: "Out-of-Pocket Met",
        key: "isOopMet",
        editType: DROPDOWN,
        options: YesNoOptions,
      },
      {
        label: "Out-of-Pocket Max",
        key: "oopMax",
        editType: NUMBER,
        options: null,
      },
      {
        label: "Out-of-Pocket Remaining",
        key: "oopRemaining",
        editType: NUMBER,
        options: null,
      },
      {
        label: "Add'l Details for",
        key: "HCPCSCodeDetails",
        editType: HCPCS_OBJECT,
        options: null,
      },
    ],
  },
];
