import _ from "lodash";

import { Location } from "@samacare/graphql";
import {
  AutocompleteField,
  useFormContext,
  type AutocompleteFieldProps,
} from "@samacare/form2";
import { createFilterOptions } from "@mui/base";
import { AutocompleteOnChangeType } from "@samacare/design/core/Autocomplete/Autocomplete";
import { AddListItem } from "../AddListItem";

export type BaseLocationAutocompleteProps = Omit<
  AutocompleteFieldProps<Location, false, false, false>,
  "options"
> & {
  locations: Location[];
  onChange: AutocompleteOnChangeType<Location, false, false, false>;
  onNew?: () => void;
};

const defaultProps = {
  disabled: false,
  LocationAutocompleteInputProps: {},
  chooseDisabled: false,
  name: "PaLocationBlock",
  onChange: () => {},
  noOptionsText: "No options",
};

const filter = createFilterOptions<Location>();

export const BaseLocationAutocomplete: React.FC<
  BaseLocationAutocompleteProps
> = (rawProps) => {
  const props = _.merge(_.cloneDeep(defaultProps), rawProps);
  const {
    locations,
    autoHighlight,
    disabled,
    chooseDisabled,
    onNew,
    onChange,
    error,
    helperText,
    label,
    ...otherAutocompleteProps
  } = props;

  const { trigger } = useFormContext();

  const cannotChoose = disabled || chooseDisabled;

  const formatForDisplay = (option: Location) =>
    option.nickname
      ? `${option.facilityName} (${option.nickname})`
      : option.facilityName;

  const getOptionLabel = (option: Location) =>
    option.id === "NEW" ? "" : formatForDisplay(option);

  return (
    <AutocompleteField<Location, false, false, false>
      disablePortal
      data-cy="controlLocationAutocomplete"
      options={locations}
      placeholder={cannotChoose ? "" : "Choose..."}
      filterSelectedOptions
      autoHighlight={autoHighlight ?? true}
      filterOptions={(opts, params) => {
        const filtered = filter(opts, params);

        if (onNew) {
          filtered.push({ id: "NEW" } as Location);
        }

        return filtered;
      }}
      getOptionLabel={getOptionLabel}
      onChange={async (e, v, reason) => {
        if (v?.id === "NEW" && onNew) {
          onNew();
        } else if (onChange) {
          onChange(e, v, reason);
          await trigger();
        }
      }}
      renderOption={(renderOptionProps, option) => {
        if (option.id === "NEW") {
          return <AddListItem {...renderOptionProps} label="Add Location" />;
        }

        return <li {...renderOptionProps}>{formatForDisplay(option)}</li>;
      }}
      disabled={cannotChoose}
      label={label}
      error={error}
      helperText={helperText || (error ? `${label} is required` : "Required")}
      {...otherAutocompleteProps}
    />
  );
};
