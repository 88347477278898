import { matchPath } from "react-router-dom";
import _ from "lodash";
import * as React from "react";
import ROUTE_PATHS from "app/routes/ROUTE_PATHS";
import { Account } from "@samacare/graphql";
import { useApplicationContext } from "app/providers/ApplicationProvider";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import SettingsOutlinedIcon from "@samacare/design/core/icons/SettingsOutlined";
import PersonOutlinedIcon from "@samacare/design/core/icons/PersonOutlined";
import HelpOutlineIcon from "@samacare/design/core/icons/HelpOutline";
import { useDispatch } from "../../configureStore";
import { push } from "connected-react-router";
import Tooltip from "@samacare/design/core/Tooltip/Tooltip";

export const Settings: React.VoidFunctionComponent<{
  account: Account;
}> = ({ account }) => {
  const { isNavigationMenuOpen } = useApplicationContext();
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <List sx={{ borderRadius: theme.spacing(1) }}>
      {[
        {
          id: "pendo_userSettings_menuItem",
          Icon: PersonOutlinedIcon,
          isEnabled: true,
          route: ROUTE_PATHS.USER_SETTINGS_ROUTE,
        },
        {
          id: "pendo_adminSettings",
          Icon: SettingsOutlinedIcon,
          isEnabled: account?.isAdmin || account?.isSamaUser,
          route: ROUTE_PATHS.ADMIN_ROUTE,
        },
        {
          id: "pendo_helpCenter_menuItem",
          Icon: HelpOutlineIcon,
          isEnabled: true,
          route: ROUTE_PATHS.HELP_CENTER_ROUTE,
        },
      ].map(({ id, route, Icon, isEnabled }) => {
        const { path, headerText } = route;
        const isSelected =
          matchPath(location.hash.slice(1), {
            exact: true,
            path,
          }) != null;
        if (!isEnabled) return;
        return (
          <ListItem
            disablePadding
            key={_.snakeCase(headerText)}
            data-cy={`action${_.upperFirst(_.camelCase(headerText))}`}
            id={id}
            sx={{
              borderRadius: theme.spacing(1),
              display: "block",
              backgroundColor: ({ palette }) =>
                isSelected ? palette.colors.P50 : "white",
            }}
          >
            <Tooltip
              title={isNavigationMenuOpen ? "" : headerText}
              placement="right"
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  borderRadius: theme.spacing(1),
                  justifyContent: isNavigationMenuOpen ? "initial" : "center",
                }}
                onClick={() => {
                  // if it is help center external link, navigate directly there
                  if (path.startsWith("http")) {
                    window.open(path, "_blank");
                  } else {
                    dispatch(push(path));
                  }
                }}
                disableGutters
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    mx: 1.5,
                  }}
                >
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={headerText}
                  sx={{
                    opacity: isNavigationMenuOpen ? 1 : 0,
                    transition: "ease-in-out .3s",
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        );
      })}
    </List>
  );
};
