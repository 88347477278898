import _ from "lodash";
import styled from "styled-components";
import colors from "Resources/colors";
import { useAlert } from "react-alert";

import getAuthorizationRoute from "../util/getAuthorizationRoute";
import Modal from "./Modal";
import PortalSelectionModal from "./PortalSelectionModal";
import BaseButton from "./BaseButton";
import LoadingSpinner from "./LoadingSpinner";
import {
  ModalBodyXL,
  ModalContent,
  ModalFooter,
} from "./ModalStyledComponents";
import React, { useContext, useState } from "react";
import { useConfig, useFeatureFlag } from "../hooks";
import { useHistory } from "react-router-dom";
import { useDuplicateAuthorization } from "../hooks/useDuplicateAuthorization";
import { useAccount } from "../providers/AccountProvider";
import { Authorization } from "@samacare/graphql";
import { WebExtensionContext } from "../contexts/webExtension";

const CustomModalContent = styled(ModalContent)`
  padding: 20px 0;
  font-size: 16px;
  white-space: normal;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  justify-content: center;
  ${BaseButton} {
    margin-left: 10px;
  }
`;

const HelperText = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.darkGray};
`;

const BackButton = styled(BaseButton)`
  color: white;
  border-color: ${(props) => props.theme.lightGray};
  background-color: ${(props) => props.theme.lightGray};
  &:hover {
    color: white;
    background-color: ${(props) =>
      props.disabled ? props.theme.lightGray : props.theme.gray};
    border-color: ${(props) =>
      props.disabled ? props.theme.lightGray : props.theme.gray};
  }
`;

interface DuplicateAuthorizationProps {
  setNewForm?: boolean;
  newPortalKey?: string;
  newPortalId?: number;
  isWebExtension?: boolean;
  insuranceName?: string;
  isExternal?: boolean;
}

interface DuplicateAuthorizationButtonProps {
  authorization: Authorization;
}
export const DuplicateAuthorizationButton: React.FC<
  React.PropsWithChildren<DuplicateAuthorizationButtonProps>
> = ({ authorization, children }) => {
  const config = useConfig();
  const alert = useAlert();
  const history = useHistory();
  const account = useAccount();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFormOptions, setShowFormOptions] = useState(false);
  const [selectPortal, setSelectPortal] = useState(false);

  const { isWebExtensionConnected } = useContext(WebExtensionContext);
  const enableBlendedPortalWorkflow = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBlendedPortalWorkflow
  );

  const duplicateAuth = useDuplicateAuthorization();

  const { portalKey, isReferral, formId, type } = authorization;

  const availablePortals = config.CONSTANTS.PORTALS_FOR_ALL_INSTITUTIONS;
  const stepOne = !showFormOptions && !selectPortal;
  const hidePortals = isReferral || _.isEmpty(availablePortals);
  const isExternalAuth =
    type === config.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key;

  const toAuthorization = (id: string, routePrefix: string) => {
    const route = `${routePrefix}?step=1&id=${id}`;
    history.push(route);
  };

  const toggleModal = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setOpen(!open);
  };

  const duplicateAuthorization = async ({
    setNewForm = false,
    newPortalKey,
    newPortalId,
    isWebExtension = false,
    insuranceName,
    isExternal = false,
  }: DuplicateAuthorizationProps) => {
    const brandName = config.CONSTANTS.SAMACARE_BRAND_NAME;

    setLoading(true);
    try {
      const duplicated = await duplicateAuth({
        authorizationId: +authorization.id,
        setNewForm,
        portalKey: newPortalKey,
        portalId: newPortalId,
        isWebExtension,
        insuranceName,
        isExternal,
      });

      if (!duplicated) {
        throw new Error("Failed to duplicate authorization");
      }

      setLoading(false);
      toggleModal();

      toAuthorization(duplicated.id, getAuthorizationRoute(duplicated));
    } catch (e) {
      alert.error(
        `There was an error duplicating your authorization. If this problem persists, please contact ${brandName}.`
      );
      setLoading(false);
      toggleModal();
    }
  };

  return (
    <div>
      <Modal
        header="Duplicate Authorization"
        onClick={toggleModal}
        open={open}
        styleOverride={{ overflowY: "visible" }}
      >
        <ModalBodyXL>
          <CustomModalContent>
            <div>
              Duplicating an authorization will create a new
              <span style={{ color: colors.presubmissionPink }}> draft </span>
              authorization based off the original.&nbsp;
              {!portalKey &&
                !formId &&
                "The form that was used to create this auth has been removed."}
              {portalKey &&
                "If creating a Form Authorization then all non-structured information will be lost."}
            </div>
            <div style={{ marginTop: "10px" }}>
              Your patient&apos;s clinical information may be out of date in the
              new authorization. Be sure to double check your data before
              submitting.
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingSpinner
                  style={{ marginRight: "10px" }}
                  colorTop={colors.darkPurple}
                  color={colors.purple}
                />
                <span>Creating duplicate authorization...</span>
              </div>
            ) : (
              <HelperText>{`I want to duplicate this authorization ${
                stepOne ? "as a" : "with"
              }...`}</HelperText>
            )}
          </CustomModalContent>

          <CustomModalFooter>
            {stepOne && !loading && (
              <>
                <BaseButton
                  onClick={
                    formId
                      ? () => {
                          setShowFormOptions(true);
                        }
                      : async () => duplicateAuthorization({ setNewForm: true })
                  }
                >
                  New Form Authorization
                </BaseButton>
                {!hidePortals && (
                  <BaseButton
                    onClick={() => {
                      setSelectPortal(true);
                    }}
                  >
                    New Portal Authorization
                  </BaseButton>
                )}
                <BaseButton
                  onClick={async () =>
                    duplicateAuthorization({
                      // Since external authorizations are assigned a default form, we don’t update the reference to the form.
                      isExternal: true,
                    })
                  }
                >
                  New External Authorization
                </BaseButton>
              </>
            )}
            {showFormOptions && !loading && (
              <>
                <div>
                  <BackButton
                    onClick={() => {
                      setShowFormOptions(false);
                    }}
                  >
                    Back
                  </BackButton>
                </div>
                {!isExternalAuth && (
                  <BaseButton onClick={async () => duplicateAuthorization({})}>
                    Same Form and Number
                  </BaseButton>
                )}
                <BaseButton
                  onClick={async () =>
                    duplicateAuthorization({ setNewForm: true })
                  }
                >
                  Different Form and Number
                </BaseButton>
              </>
            )}
            {selectPortal && (
              <PortalSelectionModal
                open={selectPortal}
                institution={account?.institution}
                isWebExtensionConnected={isWebExtensionConnected}
                enableBlendedPortalWorkflow={enableBlendedPortalWorkflow}
                closeModal={() => {
                  setSelectPortal(false);
                }}
                onSelect={async (portal) => {
                  setSelectPortal(false);
                  await duplicateAuthorization({
                    setNewForm: true,
                    newPortalKey: portal.key,
                    newPortalId: +portal.id,
                    isWebExtension: true,
                    insuranceName: portal.insuranceCompanyName,
                  });
                }}
              />
            )}
          </CustomModalFooter>
        </ModalBodyXL>
      </Modal>
      <div onClick={toggleModal}>{children}</div>
    </div>
  );
};
