import gql from "graphql-tag";

export default gql`
  fragment noteInfo on Note {
    id
    createdAt
    updatedAt
    note
    isHidden

    createdBy {
      id
      firstName
      lastName
    }
  }
`;
