import { withApollo } from "@apollo/client/react/hoc";
import _ from "lodash";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import styled from "styled-components";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";
import { PatientSection } from "AuthorizationSharedComponents/Patient";
import { PrimaryNdc } from "AuthorizationSharedComponents/PrimaryNdc";
import Section from "AuthorizationSharedComponents/Section";
import SegmentSelector from "Segment/SegmentSelector";

import {
  FilterFormBenefitType,
  InsuranceCompanySupportedCodingTypes,
} from "@samacare/graphql";
import Modal from "../../../components/Modal";
import RequiredTag from "../../../components/RequiredTag";
import { withCurrentAccount } from "../../../graphql/Account";
import { withInsuranceCompanies } from "../../../graphql/InsuranceCompany";
import {
  withCreatePatientOnAuthorization,
  withUpdatePatient,
} from "../../../graphql/Patient";
import { setFormFields } from "../../../reducers/formReducer";
import { filterInsuranceCompaniesByDrugType } from "../../../util/filterInsuranceCompaniesByDrugType";
import filterInsuranceCompanyOptions from "../../../util/filterInsuranceCompanyOptions";
import MissingInformationModal from "../../NewAuthorization/MissingInformationModalContent";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SingleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const TextButton = styled.div`
  padding: 5px 10px;
  margin: 0 0 3px 10px;
  text-decoration: underline;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.darkPurple};
    font-weight: 700;
  }
`;

const getPlansFromParent = (companies, selectedCompanyName) => {
  const selectedCompany = _.find(companies, { name: selectedCompanyName });
  return selectedCompany
    ? _.filter(companies, {
        planParentCompanyId: selectedCompany.id,
      })
    : [];
};

const NOVARTIS_OPTIONS = [
  {
    label: "Cosentyx - 0078-0639-41",
    drugName: "Cosentyx",
    value: "0078-0639-41",
  },
  {
    label: "Cosentyx - 0078-1056-97",
    drugName: "Cosentyx",
    value: "0078-1056-97",
  },
  {
    label: "Cosentyx - 0078-0639-97",
    drugName: "Cosentyx",
    value: "0078-0639-97",
  },
  {
    label: "Cosentyx - 0078-0639-98",
    drugName: "Cosentyx",
    value: "0078-0639-98",
  },
  {
    label: "Cosentyx - 0078-0639-68",
    drugName: "Cosentyx",
    value: "0078-0639-68",
  },
  {
    label: "Cosentyx - 0078-0657-61",
    drugName: "Cosentyx",
    value: "0078-0657-61",
  },
  {
    label: "UnoReady (1x300mg/mL) 0078-1070-68",
    drugName: "Cosentyx",
    value: "0078-1070-68",
  },
  {
    label: "Cosentyx (unclassified biologics, secukinumab)",
    drugName: "Cosentyx",
    value: "J3590",
  },
  {
    label: "Cosentyx (unclassified drugs, secukinumab)",
    drugName: "Cosentyx",
    value: "J3490",
  },
];

const NOVARTIS_OPTIONS_IPTACOPAN = [
  {
    label: "Iptacopan - 0078-1189-20",
    drugName: "Iptacopan",
    value: "0078-1189-20",
  },
];

const NOVARTIS_OPTIONS_RLT = [
  { label: "Pluvicto", drugName: "Pluvicto", value: "A9607" },
  { label: "Lutathera", drugName: "Lutathera", value: "A9513" },
  { label: "Locametz", drugName: "Locametz", value: "A9800" },
  { label: "Netspot", drugName: "Netspot", value: "A9587" },
];

const {
  AUTHORIZATION_STATUSES,
  MISSING_INFORMATION_TYPES,
  AUTHORIZATION_TYPES,
} = CONFIG.CONSTANTS;
const fields = CONFIG.DEFAULT_FIELDS;
export class InitializeSection extends PureComponent {
  state = { showMissingInfoModal: false, showFormToggleModal: false };

  companySelect = null;

  submit = async () => {
    const {
      insuranceCompanies,
      authorization,
      isInvalid,
      results,
      saveChanges,
      createPatientOnAuthorization,
      dob,
      firstName,
      lastName,
      toggleFormModal,
      nextStep,
    } = this.props;

    let requiredFieldKeys = [
      fields.INSURANCE_STATE.key,
      fields.INSURANCE_COMPANY.key,
    ];

    if (
      getPlansFromParent(
        insuranceCompanies,
        results[fields.INSURANCE_COMPANY.key]
      ).length > 0
    ) {
      requiredFieldKeys = requiredFieldKeys.concat(fields.INSURANCE_PLAN.key);
    }

    if (!(results[fields.PRIMARY_NDC.key] || results[fields.HCPCS_0.key])) {
      isInvalid(true);
      return;
    }

    if (isInvalid(_.some(requiredFieldKeys, (key) => !results[key]))) {
      return;
    }

    let createdPatientId = null;
    if (!authorization.patient) {
      const res = await createPatientOnAuthorization({
        variables: {
          authorizationId: authorization.id,
          patientProps: {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            dob,
          },
        },
      });
      createdPatientId = res.data.createPatientOnAuthorization.patient.id;
    }

    if (
      (authorization.formId && authorization.submissionPhoneNumber) ||
      authorization.status === AUTHORIZATION_STATUSES.PREMIUM_RX_SUPPORT
    ) {
      await this.success();
    } else {
      await saveChanges(createdPatientId);
      if (toggleFormModal) {
        toggleFormModal();
      } else {
        nextStep();
      }
    }
  };

  success = () => {
    const {
      nextStep,
      authorization,
      dob,
      firstName,
      lastName,
      createPatientOnAuthorization,
      toggleFormModal,
    } = this.props;
    const { showFormToggleModal } = this.state;

    if (!authorization.patient) {
      createPatientOnAuthorization({
        variables: {
          authorizationId: authorization.id,
          patientProps: {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            dob,
          },
        },
      }).then(nextStep);
    } else {
      if (showFormToggleModal) {
        this.setState({ showFormToggleModal: !showFormToggleModal });
        if (toggleFormModal) {
          toggleFormModal();
        } else {
          nextStep();
        }
      }

      nextStep();
    }
  };

  render() {
    const {
      authorization,
      insuranceCompanies,
      lastName,
      firstName,
      dob,
      disabled,
      set,
      results,
      account,
      state,
      isNovartis,
      highlightRequiredFields,
      flags,
    } = this.props;
    const { showMissingInfoModal } = this.state;
    const selectedCompany = _.find(insuranceCompanies, {
      name: results[fields.INSURANCE_COMPANY.key],
    });

    if (!account) {
      return <div />;
    }

    const insuranceSection = [
      fields.INSURANCE_TYPE,
      fields.PATIENT_MEMBER_ID,
      fields.INSURANCE_STATE,
    ];

    let insurancePlans = getPlansFromParent(
      insuranceCompanies,
      results[fields.INSURANCE_COMPANY.key]
    );
    if (insurancePlans.length > 0) {
      insurancePlans = _.concat(insurancePlans, selectedCompany);
    }
    const selectedPlan = _.find(insurancePlans, {
      name: results[fields.INSURANCE_PLAN.key],
    });

    const enableIptacopan =
      flags[
        CONFIG.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableNovartisIptacopan
      ];
    const enableRLT =
      flags[CONFIG.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableNovartisRLT];
    const drugOptions = [
      ...NOVARTIS_OPTIONS,
      ...(enableIptacopan ? NOVARTIS_OPTIONS_IPTACOPAN : []),
      ...(enableRLT ? NOVARTIS_OPTIONS_RLT : []),
    ];

    return (
      <FormContainer>
        <PatientSection
          authorizationId={authorization.id}
          firstName={firstName}
          lastName={lastName}
          dob={dob}
          patientId={_.get(authorization, "patient.id")}
          currentStep={authorization.formDetails.currentStep}
        />
        <Section
          section={{
            items: isNovartis
              ? [
                  { ...fields.PATIENT_ADDRESS_LINE, required: true },
                  [
                    { ...fields.PATIENT_CITY, required: true },
                    { ...fields.PATIENT_STATE, required: true },
                    { ...fields.PATIENT_ZIP, required: true },
                  ],
                  [{ ...fields.PATIENT_PHONE, required: true }],
                ]
              : [
                  fields.PATIENT_ADDRESS_LINE,
                  [
                    fields.PATIENT_CITY,
                    fields.PATIENT_STATE,
                    fields.PATIENT_ZIP,
                  ],
                  [fields.PATIENT_PHONE],
                ],
            title: "Patient Address",
          }}
        />
        {isNovartis && (
          <Section
            section={{
              items: [fields.NOVARTIS_PATIENT_ID],
              title: "Novartis Patient ID",
            }}
          />
        )}
        {isNovartis ? (
          <Section
            section={{
              items: [],
              title: "Product",
            }}
          >
            <SegmentSelector
              id="fieldMultipartyDrugSelection"
              disabled={disabled}
              highlight={highlightRequiredFields}
              onChange={(ndc) => {
                if (ndc) {
                  if (ndc.value.startsWith("J")) {
                    // It's a JCode, so we should set it as such and clear out NDC
                    set({
                      [CONFIG.DEFAULT_FIELDS.PRIMARY_DRUG_NAME.key]:
                        ndc.drugName,
                      [CONFIG.DEFAULT_FIELDS.BENEFIT_TYPES.key]:
                        FilterFormBenefitType.MedicalOnly,
                      [CONFIG.DEFAULT_FIELDS.DRUG_CODE_TYPE.key]:
                        InsuranceCompanySupportedCodingTypes.Jcode,
                      [CONFIG.DEFAULT_FIELDS.HCPCS_0.key]: ndc.value,
                      [CONFIG.DEFAULT_FIELDS.PRIMARY_NDC.key]: null,
                    });
                  } else {
                    set({
                      [CONFIG.DEFAULT_FIELDS.PRIMARY_DRUG_NAME.key]:
                        ndc.drugName,
                      [CONFIG.DEFAULT_FIELDS.BENEFIT_TYPES.key]:
                        FilterFormBenefitType.PharmacyOnly,
                      [CONFIG.DEFAULT_FIELDS.DRUG_CODE_TYPE.key]:
                        InsuranceCompanySupportedCodingTypes.Ndc,
                      [CONFIG.DEFAULT_FIELDS.HCPCS_0.key]: null,
                      [CONFIG.DEFAULT_FIELDS.PRIMARY_NDC.key]: ndc.value,
                    });
                  }
                } else {
                  set({
                    [CONFIG.DEFAULT_FIELDS.PRIMARY_DRUG_NAME.key]: null,
                    [CONFIG.DEFAULT_FIELDS.DRUG_CODE_TYPE.key]: null,
                    [CONFIG.DEFAULT_FIELDS.HCPCS_0.key]: null,
                    [CONFIG.DEFAULT_FIELDS.PRIMARY_NDC.key]: null,
                  });
                }
              }}
              value={_.find(drugOptions, {
                value: results[CONFIG.DEFAULT_FIELDS.PRIMARY_NDC.key],
              })}
              options={drugOptions}
              title={
                <TitleContainer>
                  Primary Product Requested
                  <RequiredTag />
                </TitleContainer>
              }
            />
          </Section>
        ) : (
          <PrimaryNdc results={results} set={set} />
        )}
        <Section
          childrenAfterSection
          section={{
            items: [
              { ...CONFIG.DEFAULT_FIELDS.FAX_NUMBER, required: true },
              { ...fields.REQUESTED_BY, title: "Contact Person / Name" },
            ],
            title: "HCP Details",
          }}
          disabled={disabled}
        />
        <Section
          childrenAfterSection
          section={{
            items: insuranceSection,
            title: "Patient Insurance Information",
          }}
        >
          <SingleLine>
            <SegmentSelector
              id="fieldInsuranceOrganization"
              disabled={disabled}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              highlight={highlightRequiredFields}
              onChange={(company) => {
                if (company) {
                  set({
                    [fields.INSURANCE_COMPANY.key]: company.name,
                    [fields.INSURANCE_PLAN.key]: null,
                  });
                } else {
                  set({ [fields.INSURANCE_COMPANY.key]: null });
                }
                this.setState({ showFormToggleModal: true });
              }}
              options={
                insuranceCompanies &&
                _.sortBy(
                  filterInsuranceCompaniesByDrugType(
                    insuranceCompanies,
                    results[fields.DRUG_CODE_TYPE.key]
                  ),
                  "name"
                )
              }
              setRef={(ref) => (this.companySelect = ref)}
              title={
                <TitleContainer>
                  Insurance Organization
                  <RequiredTag />
                </TitleContainer>
              }
              value={selectedCompany}
              sorted
              filterOption={(option, value) =>
                filterInsuranceCompanyOptions(option.data, value)
              }
            />
            {authorization.formId == null && (
              <TextButton
                onClick={() => {
                  this.setState({ showMissingInfoModal: true });
                }}
              >
                Request missing payers
              </TextButton>
            )}
          </SingleLine>
          {insurancePlans.length > 0 && (
            <SingleLine>
              <SegmentSelector
                onChange={(company) => {
                  if (company) {
                    set({ [fields.INSURANCE_PLAN.key]: company.name });
                  } else {
                    set({ [fields.INSURANCE_PLAN.key]: null });
                  }
                }}
                options={_.sortBy(insurancePlans, "name")}
                title={
                  <TitleContainer>
                    Insurance Plan
                    <RequiredTag />
                  </TitleContainer>
                }
                value={selectedPlan}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                sorted
                filterOption={(option, value) =>
                  filterInsuranceCompanyOptions(option.data, value)
                }
              />
            </SingleLine>
          )}
        </Section>
        {showMissingInfoModal && (
          <Modal
            onClick={() => {
              this.setState({ showMissingInfoModal: false });
            }}
            open={showMissingInfoModal}
            styleOverride={{ padding: "20px" }}
          >
            <MissingInformationModal
              type={MISSING_INFORMATION_TYPES.INSURANCE_COMPANY}
              authType={AUTHORIZATION_TYPES.MULTI_PARTY.key}
              authorizationId={authorization.id}
              onCancel={() => {
                this.setState({ showMissingInfoModal: false });
              }}
              insuranceCompanyId={_.get(selectedCompany, "id")}
              state={state}
            />
          </Modal>
        )}
        <FormSubmitButtons submit={this.submit} />
      </FormContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.form.results[fields.PATIENT_FIRST_NAME.key],
    lastName: state.form.results[fields.PATIENT_LAST_NAME.key],
    dob: state.form.results[fields.PATIENT_DOB.key],
    state: state.form.results[fields.INSURANCE_STATE.key],
    highlightRequiredFields: state.form.highlightRequiredFields,
    disabled: state.form.disabled,
    results: state.form.results,
  };
}

const mapDispatchToProps = (dispatch) => ({
  set(toSet) {
    dispatch(setFormFields(toSet));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withCreatePatientOnAuthorization,
    withUpdatePatient,
    withInsuranceCompanies,
    withCurrentAccount
  )(withApollo(withLDConsumer()(InitializeSection)))
);
