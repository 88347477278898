import styled from "styled-components";
import IconButton from "@samacare/design/core/IconButton";
import CloseIcon from "@samacare/design/core/icons/Close";
import moment from "moment";
import { useConfirmDialog } from "../../../hooks/confirmDialogHook";
import { AuthorizationCorrespondence } from "@samacare/graphql";
import FlagIcon from "@samacare/design/core/icons/Flag";
import { Stack, Tooltip } from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const ResponseOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`;

interface ResponseItemProps {
  response: AuthorizationCorrespondence;
  onHide: (AuthorizationCorrespondenceId: string) => void;
}
export const ResponseItem: React.FC<ResponseItemProps> = (props) => {
  const { response } = props;
  const theme = useTheme();

  const [ConfirmDialog, confirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog({
      message: "Remove response from the queue?",
      confirm: () => {
        props.onHide(response.id);
      },
      zIndexOffset: 1,
    });

  let flagTooltipText = "";
  if (response.rejectedAuthorizationIds.length > 0) {
    if (response.rejectedAuthorizationIds.length === 1) {
      flagTooltipText = `Incorrect Association removed from Auth #${response.rejectedAuthorizationIds[0]}`;
    } else {
      const authIdsText = response.rejectedAuthorizationIds
        .map((id) => `#${id}`)
        .join(", ");
      flagTooltipText = `Incorrect Associations removed from Auths ${authIdsText}`;
    }
  }

  return (
    <>
      <Container>
        <div>
          <Stack direction="row" spacing={1}>
            <div
              data-cy="componentSupportResponses"
              style={{ fontSize: 14, whiteSpace: "nowrap" }}
            >{`${response.id}`}</div>
            {response.rejectedAuthorizationIds.length > 0 && (
              <Tooltip title={flagTooltipText}>
                <FlagIcon sx={{ color: theme.palette.colors.R700 }} />
              </Tooltip>
            )}
          </Stack>
          <div style={{ fontSize: 10 }}>
            {moment(response.createdAt).format("MM/DD h:mm a")}
          </div>
        </div>
        <ResponseOptionContainer
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Tooltip title="Remove from the queue" enterDelay={162}>
            <IconButton
              onClick={(e) => {
                if (e != null) {
                  e.stopPropagation();
                }
                setConfirmDialogOpen(true);
              }}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </Tooltip>
        </ResponseOptionContainer>
      </Container>
      <ConfirmDialog {...confirmDialogProps} />
    </>
  );
};
