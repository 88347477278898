import _ from "lodash";

import { AutocompleteOnChangeType } from "@samacare/design/core/Autocomplete/Autocomplete";
import { Prescriber } from "@samacare/graphql";
import {
  AutocompleteField,
  useFormContext,
  type AutocompleteFieldProps,
} from "@samacare/form2";
import { createFilterOptions } from "@mui/base";
import { AddListItem } from "../AddListItem";

export type BaseProviderAutocompleteProps = Omit<
  AutocompleteFieldProps<Prescriber, false, false, false>,
  "options"
> & {
  providers: Prescriber[];
  onChange: AutocompleteOnChangeType<Prescriber, false, false, false>;
  onNew?: () => void;
};

const defaultProps = {
  disabled: false,
  PrescriberAutocompleteInputProps: {},
  chooseDisabled: false,
  name: "PrescriberBlock",
  onChange: () => {},
  noOptionsText: "No options",
  inputValue: undefined,
  onInputChange: undefined,
  value: undefined,
};

const filter = createFilterOptions<Prescriber>();

export const BaseProviderAutocomplete: React.FC<
  BaseProviderAutocompleteProps
> = (rawProps) => {
  const props = _.merge(_.cloneDeep(defaultProps), rawProps);
  const {
    disabled,
    autoHighlight,
    chooseDisabled,
    name,
    providers,
    onChange,
    onNew,
    ...otherAutocompleteProps
  } = props;

  const { trigger } = useFormContext();

  const cannotChoose = disabled || chooseDisabled;

  const formatForDisplay = (option: Prescriber) =>
    option?.firstName == null
      ? "Unknown"
      : `${option?.firstName} ${option?.lastName ?? ""}${
          option?.label ? ` (${option?.label})` : ""
        }`;

  const getOptionLabel = (option: Prescriber) =>
    option.id === "NEW" ? "" : formatForDisplay(option);

  return (
    <AutocompleteField<Prescriber, false, false, false>
      disablePortal
      name={name}
      data-cy="controlProviderAutocomplete"
      options={providers}
      placeholder={cannotChoose ? "" : "Choose..."}
      filterSelectedOptions
      autoHighlight={autoHighlight ?? true}
      filterOptions={(opts, params) => {
        const filtered = filter(opts, params);

        if (onNew) {
          filtered.push({ id: "NEW" } as Prescriber);
        }

        return filtered;
      }}
      getOptionLabel={getOptionLabel}
      onChange={async (e, v, reason) => {
        if (v?.id === "NEW" && onNew) {
          onNew();
        } else if (onChange) {
          onChange(e, v, reason);
          await trigger();
        }
      }}
      renderOption={(renderOptionProps, option) => {
        if (option.id === "NEW") {
          return <AddListItem {...renderOptionProps} label="Add Provider" />;
        }

        return <li {...renderOptionProps}>{formatForDisplay(option)}</li>;
      }}
      disabled={cannotChoose}
      {...otherAutocompleteProps}
    />
  );
};
