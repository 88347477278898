import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

export const ALL_BENEFITS_VERIFICATIONS_QUERY_NAME = "allBenefitsVerifications";

export const withUpdateBenefitsVerificationOutcomeMutation = gql`
  mutation updateBenefitsVerificationOutcome(
    $patch: UpdateBenefitsVerificationOutcomePatch
  ) {
    updateBenefitsVerificationOutcome(patch: $patch) {
      benefits_verification_id
      created_at
      state
      data
      errors
    }
  }
`;

export const withUpdateBenefitsVerificationOutcome = graphql(
  withUpdateBenefitsVerificationOutcomeMutation,
  {
    name: "updateBenefitsVerificationOutcome",
  }
);
