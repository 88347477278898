import { Box, Flex, PrimaryButton, RouterLink } from "@@ui-kit";
import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  generatePath,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  InsuranceCompany,
  withInsuranceCompanies,
} from "../../graphql/InsuranceCompany";
import SendSecureFile from "./SendSecureFile";
import SupportDrugOptions from "./SupportDrugOptions";
import SupportFormsNumbers from "./SupportFormsNumbers";
import SupportInstitutions from "./SupportInstitutions";
import { PatientSyncers } from "./SupportPatientSyncers/PatientSyncersGrid";
import SupportPayers from "./SupportPayers";
import SupportResponses from "./SupportResponses";
import SupportedFollowUpsDashboard from "./SupportedAuthorizationFollowUps";
import ErrorBoundary from "../../components/ErrorBoundary";
import { FaxTestDashboard } from "./FaxTestDashboard/FaxTestDashboard";
import { useConfig } from "../../hooks";
import _ from "lodash";
import { Portals } from "./SupportPortals/Portals";
import { TrackerConfigs } from "./TrackerConfigs/TrackerConfigs";

const BaseSupport: React.VoidFunctionComponent<{
  insuranceCompanies: InsuranceCompany[];
}> = ({ insuranceCompanies }) => {
  const { path } = useRouteMatch();
  const { view: currentView } = useParams<{ view: string | undefined }>();
  const config = useConfig();

  const views = _.compact([
    {
      children: <SupportedFollowUpsDashboard />,
      isDefault: true,
      cypressTag: "actionSupportedAuths",
      key: "supported-follow-ups",
      title: "Portal Queue",
    },
    {
      children: <SupportResponses insuranceCompanies={insuranceCompanies} />,
      cypressTag: "actionResponses",
      key: "responses",
      title: "Responses",
    },
    {
      children: <SupportInstitutions />,
      cypressTag: "actionInstitutions",
      key: "institutions",
      title: "Institutions",
    },
    {
      children: <SupportPayers />,
      cypressTag: "actionPayers",
      key: "payers",
      title: "Payers",
    },
    {
      children: <SupportFormsNumbers />,
      cypressTag: "actionFormNumbers",
      key: "forms-numbers",
      title: "Forms & Numbers",
    },
    {
      children: <SupportDrugOptions />,
      cypressTag: "actionDrugs",
      key: "drug-options",
      title: "Drugs",
    },
    {
      children: <SendSecureFile />,
      cypressTag: "actionSendFile",
      key: "secure-file",
      title: "Send File",
    },
    {
      children: <PatientSyncers />,
      cypressTag: "actionPatientSyncers",
      key: "patient-syncer",
      title: "Patient Syncers",
    },
    {
      children: <Portals />,
      cypressTag: "actionPortals",
      key: "portals",
      title: "Portals",
    },
    {
      children: <TrackerConfigs />,
      cypressTag: "actionTrackerConfigs",
      key: "trackerConfigs",
      title: "Tracker Configs",
    },
    config.NODE_ENV !== "production" && {
      children: <FaxTestDashboard />,
      cypressTag: "actionFaxTestDashboard",
      key: "fax-text",
      title: "Test Faxes",
    },
  ]);

  const defaultView = views.find(({ isDefault = false }) => isDefault) ?? null;

  return (
    <Flex flexDirection="column" padding="10px">
      <Flex flexDirection="row" marginBottom="16px">
        {views.map((view) => (
          <Box key={view.key} marginRight="8px">
            <ErrorBoundary name={view.key}>
              <RouterLink key={view.key} to={view.key}>
                <PrimaryButton
                  inverted={currentView !== view.key}
                  data-cy={view.cypressTag}
                >
                  {view.title}
                </PrimaryButton>
              </RouterLink>
            </ErrorBoundary>
          </Box>
        ))}
      </Flex>

      <Box>
        <Switch>
          {views.map((view) => (
            <Route
              key={view.title}
              path={generatePath(path, { view: view.key })}
            >
              {view.children}
            </Route>
          ))}

          {defaultView != null && (
            <Route>
              <Redirect to={generatePath(path, { view: defaultView.key })} />
            </Route>
          )}
        </Switch>
      </Box>
    </Flex>
  );
};

export type SupportProps = React.ComponentType<typeof Support>;

export const Support = withInsuranceCompanies(BaseSupport);
