import _ from "lodash";

import { GridValueGetterParams } from "@samacare/design";

import { cleanDrugName } from "../../../../server/src/shared_code";
import { SearchResultItem } from "./types";

export const nestedValueGetter = (
  args: GridValueGetterParams<SearchResultItem>
) => _.get(args.row, args.field) as unknown;

export const formatDrug = (drug: SearchResultItem["drug"]) => {
  if (drug == null) {
    return null;
  }

  const { drugName, code } = drug;
  const name = cleanDrugName(drugName);
  return _.compact([name, code]).join(" - ");
};
