import { PureComponent } from "react";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@samacare/design/core";
import { BaseText } from "Segment/StyledComponents";
import { withUpsertForm } from "../../../../graphql/AuthorizationForm";

class EditFormTitle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { title: props.title, description: props.description };
  }

  upsertForm = async () => {
    const { id, upsertForm, clear, alert, refetchQuery } = this.props;
    const { title, description } = this.state;
    try {
      await upsertForm({ variables: { formId: id, description, title } });
      alert.info("Successfully updated form name and description");
      await refetchQuery();
      clear();
    } catch (e) {
      alert.error(`Failed to update ${e}`);
    }
  };

  render() {
    const { clear } = this.props;
    const { title, description } = this.state;
    return (
      <Dialog
        onClose={() => {
          clear();
        }}
        open
      >
        <DialogTitle>Edit Authorization Form Title and Description</DialogTitle>
        <DialogContent>
          <BaseText
            style={{ width: "500px", marginTop: "16px 0" }}
            onChange={(e) => {
              this.setState({ title: e.target.value });
            }}
            value={title}
            placeholder="Title"
          />
          <BaseText
            style={{ width: "500px", margin: "8px 0" }}
            onChange={(e) => {
              this.setState({ description: e.target.value });
            }}
            value={description}
            placeholder="Description"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.upsertForm}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withUpsertForm)(withAlert()(EditFormTitle));
