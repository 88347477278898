import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import EditAndResubmitButton from "@@components/EditAndResubmitButton";
import RequestResendButton from "@@components/RequestResendButton";
import FollowUpButton from "@@components/FollowUpButton";
import UploadMissingFormButton from "@@components/UploadMissingFormButton";
import BaseButton from "@@components/BaseButton";
import { ActionRequiredRowContainer } from "./ActionRequiredRowContainer";
import { useConfig } from "../../../hooks";
import { parseAsNonNull } from "../../../util/parsers";
import ViewOnPortalLink from "../../../components/ViewOnPortalLink";
import {
  Authorization,
  FollowUp,
  Patient,
  AuthorizationCorrespondence,
} from "@samacare/graphql";

export const ActionRequiredBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  white-space: normal;
  padding: 15px;
  margin-left: 5px;

  b {
    margin: 0 5px;
  }
`;

const FollowUpButtons = styled(BaseButton)`
  margin-left: 10px;
  width: 175px;
`;

const markFollowUpAsReviewedMutation = gql`
  mutation markFollowUpAsReviewed($id: Int!) {
    followUpReviewed(id: $id) {
      id
    }
  }
`;

export type FollowUpRowProps = React.ComponentProps<typeof FollowUpRow>;

export const FollowUpRow: React.VoidFunctionComponent<{
  authorization: Authorization;
}> = ({ authorization }) => {
  const followUp = parseAsNonNull(authorization.followUp as FollowUp);
  const latestCorrespondence = parseAsNonNull(
    authorization.latestCorrespondence
  );
  const patientName = `${_.upperFirst(
    parseAsNonNull(authorization.patient as Patient).firstName
  )} ${_.upperFirst(
    parseAsNonNull(authorization.patient as Patient).lastName
  )}`;

  const config = useConfig();
  const [markFollowUpAsReviewed] = useMutation(markFollowUpAsReviewedMutation, {
    refetchQueries: ["allAuthorizations"],
  });

  const typesToDismissOnClick: string[] = [
    config.CONSTANTS.FOLLOW_UP_TYPES.EDIT_AND_RESUBMIT,
  ];
  const dismiss = async () => {
    await markFollowUpAsReviewed({
      variables: { id: followUp.id },
    });
  };

  let textContent;
  let buttonContent;
  let ButtonComponent;
  if (followUp.type === config.CONSTANTS.FOLLOW_UP_TYPES.GENERAL_QUESTIONS) {
    textContent =
      `This authorization for ${patientName} requires a response to follow-up questions prior to receiving a response.` +
      ' You can respond to the payer directly through SamaCare by clicking "Respond to payer"';
    buttonContent = "Respond to payer";
    ButtonComponent = FollowUpButton;
  } else if (
    followUp.type === config.CONSTANTS.FOLLOW_UP_TYPES.PRINTABLE_FORM
  ) {
    textContent =
      `This authorization for ${patientName} requires a response to a payer form that needs filling out prior to receiving a response.` +
      ' You can print the form by clicking "Print payer response"';
    buttonContent = "Print payer response";
    ButtonComponent = FollowUpButton;
  } else if (
    followUp.type === config.CONSTANTS.FOLLOW_UP_TYPES.EDIT_AND_RESUBMIT
  ) {
    textContent =
      `This authorization for ${patientName} requires changes be made to the information originally supplied payer form.` +
      ' Click "Edit and Resubmit" to make edits to your original fax and re-send the authorization';
    buttonContent = "Edit and Resubmit";
    ButtonComponent = EditAndResubmitButton;
  } else if (followUp.type === config.CONSTANTS.FOLLOW_UP_TYPES.RESUBMIT) {
    textContent =
      `This authorization for ${patientName} requires resending to this payer at a different fax number.` +
      ' You can resubmit this authorization to the payer directly through SamaCare by clicking "Resubmit"';
    buttonContent = "Resubmit";
    ButtonComponent = RequestResendButton;
  } else if (followUp.type === config.CONSTANTS.FOLLOW_UP_TYPES.MISSING_FORM) {
    textContent = `This authorization for ${patientName} requires an additional form. You can upload the form and resubmit this authorization to the payer directly through SamaCare by clicking "Upload Form".`;
    buttonContent = "Upload Form";
    ButtonComponent = UploadMissingFormButton;
  } else {
    return (
      <div>Unexpected error encountered while displaying authorization.</div>
    );
  }

  return (
    <ActionRequiredRowContainer>
      <ActionRequiredBanner>
        {textContent}
        <FollowUpButtons onClick={dismiss}>Dismiss</FollowUpButtons>
        <ViewOnPortalLink authorization={authorization} />
        <ButtonComponent
          authorizationId={authorization.id}
          correspondence={latestCorrespondence as AuthorizationCorrespondence}
          dismissFollowUp={dismiss}
          followUp={followUp}
          insuranceCompanyId={authorization.insuranceCompany?.id ?? null}
        >
          <FollowUpButtons
            onClick={async () => {
              if (typesToDismissOnClick.includes(followUp.type)) {
                await dismiss();
              }
            }}
          >
            {buttonContent}
          </FollowUpButtons>
        </ButtonComponent>
      </ActionRequiredBanner>
    </ActionRequiredRowContainer>
  );
};
