import * as React from "react";
import { useState } from "react";
import { setLocale } from "yup";

import { Form } from "@@ui-kit/forms";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, LoadingButton, Stack } from "@samacare/design/core";
import {
  useForm,
  AddressBlock,
  IcdField,
  InsuranceBlock,
  OfficeBlock,
  PatientInformationBlock,
  PrescriberBlock,
  Resolver,
} from "@samacare/form";
import { UnifiedBenefitsCheckType } from "@samacare/graphql";

import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { useConfig } from "../../../hooks/config/index";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";
import { buildPavbluFormSchema } from "../formUtils";
import { RequestBenefitVerificationFormDataType } from "../types";
import { useFocusFirstError } from "../../../hooks/useFocusFirstError";
import { BenefitsVerificationServiceAndPatientBlock } from "./BenefitsVerificationServiceAndPatientBlock";

type BenefitsVerificationRequestFormProps = {
  canChangePatient?: boolean;
  editable?: boolean;
  defaultValues: RequestBenefitVerificationFormDataType;
  onSubmit: (data: RequestBenefitVerificationFormDataType) => void;
  onBack: () => void;
};

export const BenefitsVerificationRequestFormPavblu: React.VoidFunctionComponent<
  BenefitsVerificationRequestFormProps
> = ({ editable = true, defaultValues, onSubmit, onBack }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const config = useConfig();

  const eyleaEnabledIcdCodes = config.CONSTANTS.SUPPORTED_ICD_CODE_EYLEA_ARRAY;
  // init bv request form
  const schema = buildPavbluFormSchema();
  const form = useForm<RequestBenefitVerificationFormDataType>({
    resolver: yupResolver(schema) as Resolver<
      RequestBenefitVerificationFormDataType,
      any
    >,
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  setLocale({
    mixed: {
      required: "This field is required",
    },
  });

  const {
    trigger,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;
  const benefitsCheckType = watch("benefitsCheckType");
  useFocusFirstError(errors);

  const canSubmit =
    benefitsCheckType == null ||
    benefitsCheckType === UnifiedBenefitsCheckType.Full;

  return (
    <Form
      context={form}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <BenefitsVerificationServiceAndPatientBlock
        patientId={watch().PatientId}
        drugOptionId={watch().DrugOptionId}
        isFullBv
      >
        <IcdField
          name="icds"
          required
          disabled={!editable}
          override={{
            options: eyleaEnabledIcdCodes,
            limit: 1,
          }}
        />
      </BenefitsVerificationServiceAndPatientBlock>
      <EnrollmentSection title="Patient Information">
        <Stack spacing={2} direction="column">
          <AddressBlock disabled={!editable} required />
          <PatientInformationBlock
            disabled={!editable}
            PhoneInputProps={{
              usePhoneType: true,
              disabled: !editable,
              PhoneTypeProps: {
                name: "patient.phoneType",
                disabled: !editable,
                required: true,
              },
              required: true,
            }}
            required
          />
        </Stack>
      </EnrollmentSection>
      <EnrollmentSection title="Primary Insurance Information">
        <InsuranceBlock
          disabled={!editable}
          InsuranceTypeInputProps={{
            name: "primaryInsurance.planType",
          }}
          InsuranceMemberIdInputProps={{
            name: "primaryInsurance.memberId",
          }}
          InsuranceStateInputProps={{
            name: "primaryInsurance.insuranceState",
          }}
          InsuranceCompanyInputProps={{
            name: "primaryInsurance.InsuranceCompanyId",
          }}
          PhoneInputProps={{
            name: "primaryInsuranceCompanyPhone",
          }}
          useGroupNumber
          InsuranceGroupNumberInputProps={{
            name: "primaryInsurance.groupNumber",
          }}
          required
        />
      </EnrollmentSection>

      <EnrollmentSection title="Secondary Insurance Information">
        <InsuranceBlock
          disabled={!editable}
          InsuranceTypeInputProps={{
            name: "secondaryInsurance.planType",
          }}
          InsuranceMemberIdInputProps={{
            name: "secondaryInsurance.memberId",
          }}
          InsuranceStateInputProps={{
            name: "secondaryInsurance.insuranceState",
          }}
          InsuranceCompanyInputProps={{
            name: "secondaryInsurance.InsuranceCompanyId",
          }}
          PhoneInputProps={{
            name: "secondaryInsuranceCompanyPhone",
          }}
          useGroupNumber
          InsuranceGroupNumberInputProps={{
            name: "secondaryInsurance.groupNumber",
          }}
        />
      </EnrollmentSection>

      <EnrollmentSection title="Provider Details">
        <PrescriberBlock required disabled={!editable} allowNew={false} />
      </EnrollmentSection>

      <EnrollmentSection title="Location Details">
        <OfficeBlock
          required
          disabled={!editable}
          allowNew={false}
          PTANInputProps={{ disabled: !editable, name: "locationPTAN" }}
        />
      </EnrollmentSection>

      <Stack
        direction="row"
        sx={{ justifyContent: "space-between" }}
        marginTop={1}
      >
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <LoadingButton
          variant="contained"
          disabled={!editable || !canSubmit}
          data-cy="actionSubmitNewBenefitsVerification"
          onClick={async () => (await trigger()) && setShowConfirm(true)}
        >
          Submit
        </LoadingButton>
      </Stack>
      <ConfirmationDialog
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={async () => {
          setShowConfirm(false);
          await handleSubmit(onSubmit)();
        }}
      />
    </Form>
  );
};
