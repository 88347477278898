import { Stack } from "@samacare/design/core";

import { QuestionnaireField } from "../../app/components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { useWatch } from "@samacare/form";

export type SiteOfServiceBlockForAssistRxProps = {
  disabled?: boolean;
};

export const SiteOfServiceBlockForAssistRx: React.FC<
  SiteOfServiceBlockForAssistRxProps
> = ({ disabled = false }) => {
  const questionnaireData = useWatch({
    name: "questionnaire",
  });
  return (
    <Stack spacing={2} maxWidth={600}>
      <QuestionnaireField
        id="siteOfService"
        type={QuestionTypeEnumType.Choice}
        disabled={disabled}
        answerOption={[
          { valueString: "Physician Office" },
          { valueString: "Hospital Outpatient" },
          { valueString: "Ambulatory Surgical Center" },
          { valueString: "Other" },
        ]}
        radioGroupProps={{
          row: false,
        }}
        text="Please select a site of service below:"
        required
      />
      {questionnaireData.siteOfService === "Other" && (
        <QuestionnaireField
          id="serviceSite"
          text="Site of Service"
          required
          disabled={disabled}
        />
      )}
    </Stack>
  );
};
