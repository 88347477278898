import { IconButton, MenuItem, Stack } from "@samacare/design";
import { EnhancedServicesIcon } from "@samacare/component";
import DeleteIcon from "@samacare/design/core/icons/Delete";
import { AutocompleteField } from "@samacare/form2";
import { createFilterOptions } from "@samacare/design/core/base";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import { EnhancedServiecesEnum, ServiceCode } from "@samacare/graphql";
import { Rule } from "./components/Rule";
import { QuantityAndType, DatesOfService } from "./optionalFields";

// eslint-disable-next-line no-shadow
export enum ServiceCodeOptionalFields {
  Quantity = "quantity",
  DateOfService = "dateOfService",
}

interface ServiceCodeInputProps {
  name: string;
  required?: boolean;
  disabled?: boolean;
  handleDelete?: () => void;
  optionalFields?: ServiceCodeOptionalFields[];
  drugOptionRequired?: boolean;
  serviceCodes: ServiceCode[];
  isFirst: boolean;
  loading: boolean;
  highlightedEnhancedServices?: EnhancedServiecesEnum[];
}

const renderOption = (highlightedEnhancedServices: EnhancedServiecesEnum[] | undefined) => (
    props: object,
    option: ServiceCode,
    { selected }: { selected: boolean }
  ) => {
    const { drugName, code, enhancedServices, drugOptionId } = option;
    const shouldHighlight = !_.isEmpty(highlightedEnhancedServices) && 
      !_.isEmpty(enhancedServices) && 
      !_.isEmpty(_.intersection(highlightedEnhancedServices, enhancedServices))

    return (
      <MenuItem {...props} key={drugOptionId ?? (code + drugName)} selected={selected}>
        {code}: {drugName!}{" "}
        {shouldHighlight && <EnhancedServicesIcon
            sx={{ fontSize: 20 }}
            hover={false}
            key={`icon-${code}`}
          />}
      </MenuItem>
    );
  };

export const ServiceCodeInput: React.FC<ServiceCodeInputProps> = ({
  required = true,
  disabled = false,
  handleDelete,
  drugOptionRequired,
  optionalFields,
  name,
  serviceCodes,
  isFirst,
  loading,
  highlightedEnhancedServices
}) => {
  const { watch } = useFormContext();
  const selected = watch(name);
  
  return (
    <Stack spacing={2} width="100%">
      {!isFirst && <Rule />}
      <Stack direction="row" spacing={1}>
        <AutocompleteField<ServiceCode, true, true, false>
          disablePortal
          loading={loading}
          required={required}
          disabled={disabled}
          placeholder="Type to search services..."
          label={isFirst ? "Primary Service Code" : "Additional Service Code"}
          options={serviceCodes}
          name={name}
          value={selected?.code ? selected : null}
          filterSelectedOptions
          filterOptions={createFilterOptions({ limit: 50 })}
          getOptionLabel={(option: ServiceCode) =>
            `${option.code}: ${option.drugName}`
          }
          renderOption={renderOption(highlightedEnhancedServices)}
          sx={{ flex: 4 }}
          isOptionEqualToValue={(option: ServiceCode, value: ServiceCode) =>
            option.code === value.code
          }
          disableClearable
        />

        {!(isFirst && drugOptionRequired) && handleDelete && (
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      {optionalFields?.includes(ServiceCodeOptionalFields.Quantity) && (
        <QuantityAndType required={required} disabled={disabled} name={name} />
      )}
      {optionalFields?.includes(ServiceCodeOptionalFields.DateOfService) && (
        <DatesOfService required={required} disabled={disabled} name={name} />
      )}
    </Stack>
  );
};
