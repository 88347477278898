import gql from "graphql-tag";

export default gql`
  fragment accountInfo on Account {
    id
    phone
    extension
    firstName
    lastName
    isSamaUser
    isAdmin
    email
    isReadOnly
    isDeactivated
    hidePortalTutorial
    portalMfaPhoneNumber
    mfaConfiguredPortalIds
    portalCredentials
    isSystemUser
    InstitutionId

    institution {
      name
      id
      faxResponseNumber
      multiPartyFaxNumber
      featureFlags {
        Enrollments
        OptionalSignature
        MultiPartyAuthorization
        pharmacyAndMedicalBenefitFormsEnabled
        skipSendingToPhaxio
        Novartis
        NovartisProduction
        CareMetxEnrollment
        ReferringProvider
        PortalMFA
        BenefitsVerification
      }
      ReferralForms {
        id
        title
        pdfURL
        description
      }
      practicingSpecialty
      emrUploadCategory
      capturesPatientConsent
      integrations {
        integrationId
        isEnabled
      }
      sftpKey {
        id
        sftpUsername
      }
      isTop
      isTest
    }
  }
`;
