import { useCallback } from "react";
import { useDispatch } from "../configureStore";
import { setRequiredFields } from "../reducers/formReducer";

export const useSetRequired = () => {
  const dispatch = useDispatch();

  return useCallback(
    (toSet: string | string[]) => {
      dispatch(setRequiredFields(toSet));
    },
    [dispatch]
  );
};
