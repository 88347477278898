import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import { useMutation } from "@apollo/client";
import {
  UploadDocumentsToModernizingMedicineMutation,
  UploadDocumentsToNextGenMutation,
  UploadDocumentsToOncoEmrMutation,
} from "@@generated/graphql";
import { AwsDocument, containsUnnamedDocuments } from "../util/authUtils";
import moment from "moment";
import _ from "lodash";
import { useConfig } from "@@hooks/config";
import {
  Mutation,
  MutationUploadDocumentsToRedoxArgs,
} from "@samacare/graphql";
import uploadDocumentsToRedoxMutation from "../graphql/UploadDocumentsToRedoxMutation.gql";
import { useHasEmrIntegration } from "@@hooks/useHasEmrIntegrations";

export const uploadDocumentsToOncoEMRMutation = gql`
  mutation uploadDocumentsToOncoEMR(
    $institutionPatientId: String!
    $authorizationId: Int!
    $files: [JSON]!
  ) {
    uploadDocumentsToOncoEMR(
      institutionPatientId: $institutionPatientId
      authorizationId: $authorizationId
      files: $files
    )
  }
`;

export const uploadDocumentsToModernizingMedicineMutation = gql`
  mutation uploadDocumentsToModernizingMedicine(
    $institutionPatientId: String!
    $files: [JSON]!
    $authorizationId: Int!
  ) {
    uploadDocumentsToModernizingMedicine(
      institutionPatientId: $institutionPatientId
      files: $files
      authorizationId: $authorizationId
    ) {
      success
      message
    }
  }
`;
export const withUploadDocumentToModernizingMedicine = graphql(
  uploadDocumentsToModernizingMedicineMutation,
  {
    name: "uploadDocumentsToModernizingMedicine",
  }
);

export const getTestPatientsFromNextGen = gql`
  query getTestPatientsFromNextGen(
    $institutionId: Int!
    $firstName: String!
    $lastName: String!
  ) {
    getTestPatientsFromNextGen(
      institutionId: $institutionId
      firstName: $firstName
      lastName: $lastName
    ) {
      testPatients {
        id
        name
      }
      errorMessage
    }
  }
`;

export const getInstitutionNameFromNextGen = gql`
  query getInstitutionNameFromNextGen($siteId: String!, $practiceId: String!) {
    getInstitutionNameFromNextGen(siteId: $siteId, practiceId: $practiceId) {
      institutionName
      errorMessage
    }
  }
`;

export const getInstitutionNamesFromModernizingMedicine = gql`
  query getInstitutionNamesFromModernizingMedicine(
    $username: String!
    $password: String!
    $firmUrlPrefix: String!
  ) {
    getInstitutionNamesFromModernizingMedicine(
      username: $username
      password: $password
      firmUrlPrefix: $firmUrlPrefix
    ) {
      institutionNames
      errorMessage
    }
  }
`;

export const upsertNextGenIntegrationInstallation = gql`
  mutation upsertNextGenIntegrationInstallation(
    $siteId: String!
    $practiceId: String!
    $documentType: String!
    $institutionId: Int!
    $isEnabled: Boolean
  ) {
    upsertNextGenIntegrationInstallation(
      siteId: $siteId
      practiceId: $practiceId
      documentType: $documentType
      institutionId: $institutionId
      isEnabled: $isEnabled
    ) {
      success
      message
    }
  }
`;

export const upsertRedoxIntegrationInstallation = gql`
  mutation upsertRedoxIntegrationInstallation(
    $facilityCode: String!
    $institutionId: Int!
    $isEnabled: Boolean
  ) {
    upsertRedoxIntegrationInstallation(
      facilityCode: $facilityCode
      institutionId: $institutionId
      isEnabled: $isEnabled
    ) {
      success
      message
    }
  }
`;

export const upsertModernizingMedicineIntegrationInstallation = gql`
  mutation upsertModernizingMedicineIntegrationInstallation(
    $username: String!
    $password: String!
    $firmUrlPrefix: String!
    $institutionId: Int!
    $isEnabled: Boolean
  ) {
    upsertModernizingMedicineIntegrationInstallation(
      username: $username
      password: $password
      firmUrlPrefix: $firmUrlPrefix
      institutionId: $institutionId
      isEnabled: $isEnabled
    ) {
      success
      message
    }
  }
`;

export const uploadDocumentsToNextGenMutation = gql`
  mutation uploadDocumentsToNextGen(
    $institutionPatientId: String!
    $files: [JSON]!
    $authorizationId: Int
    $isTestUpload: Boolean
  ) {
    uploadDocumentsToNextGen(
      institutionPatientId: $institutionPatientId
      files: $files
      authorizationId: $authorizationId
      isTestUpload: $isTestUpload
    ) {
      success
      message
      requiresDebug
    }
  }
`;
export const withUploadDocumentsToNextGen = graphql(
  uploadDocumentsToNextGenMutation,
  {
    name: "uploadDocumentsToNextGen",
  }
);

export const withUploadDocumentsToOncoEMR = graphql(
  uploadDocumentsToOncoEMRMutation,
  {
    name: "uploadDocumentsToOncoEMR",
  }
);

export const validateCredentialsOncoEMR = gql`
  mutation validateCredentialsOncoEMR($institutionId: Int!, $emrCreds: JSON!) {
    validateCredentialsOncoEMR(
      institutionId: $institutionId
      emrCreds: $emrCreds
    )
  }
`;

export const withValidateCredentialsOncoEMR = graphql(
  validateCredentialsOncoEMR,
  { name: "validateCredentialsOncoEMR" }
);

const UNNAMED_DOCUMENTS_ERROR =
  "Please verify that each document has a selected name";

export type UploadDocumentsToEmrResultType = {
  error: Error | void;
  requiresDebug?: boolean | null;
};

export const useUploadDocumentsToEmr = (institutionId: string) => {
  const [uploadDocumentsToOncoEMR] =
    useMutation<UploadDocumentsToOncoEmrMutation>(
      uploadDocumentsToOncoEMRMutation
    );
  const [uploadDocumentsToModernizingMedicine] =
    useMutation<UploadDocumentsToModernizingMedicineMutation>(
      uploadDocumentsToModernizingMedicineMutation
    );
  const [uploadDocumentsToNextGen] =
    useMutation<UploadDocumentsToNextGenMutation>(
      uploadDocumentsToNextGenMutation
    );
  const [uploadDocumentsToRedox] = useMutation<
    { uploadDocumentsToRedox: Mutation["uploadDocumentsToRedox"] },
    MutationUploadDocumentsToRedoxArgs
  >(uploadDocumentsToRedoxMutation);

  const { CONSTANTS } = useConfig();

  const {
    hasUploadToNextGen,
    hasUploadToOncoEmr,
    hasUploadToModernizingMedicine,
    hasUploadToRedox,
  } = useHasEmrIntegration(institutionId);

  return async (args: {
    patientMRN: string | undefined;
    documents: AwsDocument[];
    authorizationId: string | null;
    isTestUpload?: boolean;
  }): Promise<UploadDocumentsToEmrResultType> => {
    const { patientMRN, documents, authorizationId, isTestUpload } = args;

    if (!patientMRN) {
      return { error: new Error("Please verify that there is a Patient MRN") };
    }

    if (!isTestUpload && !authorizationId) {
      return {
        error: new Error("Please verify that there is an authorizationId"),
      };
    }

    const institutionPatientId = patientMRN.trim();
    const files = _.map(documents, (doc) =>
      doc.date ? doc : { ...doc, date: moment().format(CONSTANTS.DATE_FORMAT) }
    );

    try {
      if (hasUploadToOncoEmr) {
        if (containsUnnamedDocuments(documents)) {
          return { error: new Error(UNNAMED_DOCUMENTS_ERROR) };
        }

        await uploadDocumentsToOncoEMR({
          variables: { institutionPatientId, files, authorizationId },
        });

        return { error: undefined };
      } else if (hasUploadToModernizingMedicine) {
        if (containsUnnamedDocuments(documents)) {
          return { error: new Error(UNNAMED_DOCUMENTS_ERROR) };
        }

        const result = await uploadDocumentsToModernizingMedicine({
          variables: { institutionPatientId, files, authorizationId },
        });
        if (result?.data?.uploadDocumentsToModernizingMedicine.message) {
          return {
            error: new Error(
              result.data.uploadDocumentsToModernizingMedicine.message
            ),
          };
        }

        return { error: undefined };
      } else if (hasUploadToNextGen || isTestUpload) {
        // allow test uploads to nextGen
        const result = await uploadDocumentsToNextGen({
          variables: {
            institutionPatientId,
            files,
            authorizationId,
            isTestUpload,
          },
        });
        if (result?.data?.uploadDocumentsToNextGen.message) {
          return {
            error: new Error(result.data.uploadDocumentsToNextGen.message),
            requiresDebug: result.data.uploadDocumentsToNextGen.requiresDebug,
          };
        }
        if (result?.data?.uploadDocumentsToNextGen.success) {
          return { error: undefined };
        }
      } else if (hasUploadToRedox) {
        const result = await uploadDocumentsToRedox({
          variables: {
            authorizationId: parseInt(authorizationId!),
            files,
          },
        });
        if (result?.data?.uploadDocumentsToRedox.errorMessage) {
          return {
            error: new Error(result.data.uploadDocumentsToRedox.errorMessage),
          };
        }
        if (result?.data?.uploadDocumentsToRedox.success) {
          return { error: undefined };
        }
      }

      return {
        error: new Error(
          "Please verify an EMR is enabled for this institution"
        ),
      };
    } catch (e) {
      return { error: new Error("There was an error uploading your document") };
    }
  };
};
