import { Stack, Typography } from "@samacare/design/core";
import { QuestionnaireField } from "../../components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { DatePickerField, useWatch } from "@samacare/form";
import Link from "@samacare/design/core/Link";
import { useEnrollmentContext } from "../../routes/Enrollment/EnrollmentProvider";

export type PrescriptionInfoBlockProps = {
  isSubmitted?: boolean;
  isRequired?: boolean;
};

export const PrescriptionInfoBlock: React.FC<PrescriptionInfoBlockProps> = ({
  isSubmitted = false,
  isRequired = false,
}) => {
  const questionnaireData = useWatch({
    name: "questionnaire",
  });
  const { enrollmentProgram } = useEnrollmentContext();

  const rxType = {
    initial: "Initial Rx",
    maintenance: "Maintenance Rx",
  };
  return (
    <Stack direction="column">
      <Stack spacing={2} direction="column" maxWidth={600}>
        <Typography variant="body1" color="text.primary">
          To view administrative instructions –{" "}
          <Link
            href={enrollmentProgram?.pdfUrl ?? ""}
            target="_blank"
            rel="noreferrer"
          >
            View form here
          </Link>
        </Typography>
        <p>
          <strong>Prescription Information:</strong> UPLIZNA®
          (inebilizumab-cdon)
        </p>
        <p>
          {" "}
          <strong>ICD-10 code:</strong> G36.0
        </p>
        <p>
          {" "}
          <strong>NDC: 75987-150-03:</strong> One carton containing three 100
          mg/10 mL vials
        </p>

        <p>
          <strong>Dose:</strong> 300 mg per IV infusion
        </p>

        <DatePickerField
          label="Target Infusion Date"
          name="questionnaire.sama-prescriptionInfo-infusionDate"
          disabled={isSubmitted}
          textFieldProps={{ required: isRequired }}
        />
        <QuestionnaireField
          id="sama-rxType"
          text="Dose: 300 mg per IV infusion"
          type={QuestionTypeEnumType.Choice}
          disabled={isSubmitted}
          required
          radioGroupProps={{ row: false }}
          answerOption={[
            {
              label: (
                <>
                  <strong>Initial Rx</strong>: 300 mg IV infusion over 90
                  minutes at Day 1 and 2 weeks later
                </>
              ) as React.ReactNode,
              valueString: rxType.initial,
            },
            {
              label: (
                <>
                  <strong>Maintenance Rx</strong>: 300 mg IV infusion over 90
                  minutes every 6 months
                </>
              ) as React.ReactNode,
              valueString: rxType.maintenance,
            },
          ]}
        />
        <Stack ml={2} maxWidth={600}>
          <QuestionnaireField
            type={QuestionTypeEnumType.Number}
            id="sama-refillNum"
            text="Refill"
            disabled={
              isSubmitted || questionnaireData["sama-rxType"] === rxType.initial
            }
          />
        </Stack>
        <QuestionnaireField
          id="sama-prescriptionInfo-medicalUrgent"
          text={
            (
              <>
                <strong>Patient is Medically Urgent</strong>: Patient is
                Medically Urgent: Medically Urgent means a patient who (1) is at
                risk of permanent disability from either an NMOSD medical crisis
                or potential attack; (2) is either: (i) not on an NMOSD
                maintenance therapy OR (ii) on an alternate maintenance therapy;
                and (3) requires accelerated treatment with UPLIZNA because a
                viable insurance access pathway is not available prior to the
                proposed first infusion date, resulting in a delay in receiving
                treatment. I certify that the treatment of the Patient is
                Medically Urgent per the definition above, requiring accelerated
                access to UPLIZNA.
              </>
            ) as unknown as string
          }
          type={QuestionTypeEnumType.Boolean}
          disabled={isSubmitted}
        />
      </Stack>
    </Stack>
  );
};
