import { useEffect } from "react";
import * as React from "react";
import { useForm } from "@samacare/form";
import styled from "styled-components";
import { theme } from "../../theme";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { PrimaryButton } from "@@ui-kit";
import { FormControl, Input, PasswordField } from "@@ui-kit/forms";
import { useConfirmDialog } from "../../hooks/confirmDialogHook";
import Modal from "../../components/Modal";
import {
  ModalBodyLarge,
  ModalFooter as OriginalModalFooter,
} from "../../components/ModalStyledComponents";
import {
  withAccountsQuery,
  currentAccountQuery,
  upsertAccountForSamaUsersMutation,
  withUpsertPortalCredentialsMutation,
} from "../../graphql/Account";
import { Account, Portal, PortalCredential } from "@samacare/graphql";
import {
  LeftRightCenterV,
  LeftRightSpreadCenterV,
} from "@@components/LeftRight";
import { WhiteButton } from "@@ui-kit/WhiteButton";

const ModalContent = styled.div`
  font-size: 16px;
  margin: 20px 0;
  text-align: left;
`;

const ModalFooter = styled(OriginalModalFooter)`
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding-top: 24px;
`;

const updateStatusCheckLoginRequiresAttnMutation = gql`
  mutation updateStatusCheckLoginRequiresAttn($PortalId: Int!) {
    updateStatusCheckLoginRequiresAttn(PortalId: $PortalId) {
      success
    }
  }
`;

const PortalCredentialModal: React.VFC<{
  isOpen: boolean;
  credentialToEdit: PortalCredential | null;
  currentAccount: Account;
  currentInstitutionId: string | number | null | undefined;
  onModalClose: (shouldOpenMfaDialog: boolean) => void;
  portal: Portal | null;
}> = ({
  isOpen,
  credentialToEdit,
  currentAccount,
  currentInstitutionId,
  onModalClose,
  portal,
}) => {
  const alert = useAlert();

  const { requiresPhoneMfa } = portal || {};

  const [upsertPortalCredentials] = useMutation(
    withUpsertPortalCredentialsMutation,
    {
      refetchQueries: () => [withAccountsQuery],
    }
  );

  const [updateStatusCheckLoginRequiresAttn] = useMutation(
    updateStatusCheckLoginRequiresAttnMutation,
    {
      refetchQueries: ["allAuthorizations"],
    }
  );

  const [upsertAccountForSamaUsers] = useMutation(
    upsertAccountForSamaUsersMutation,
    {
      refetchQueries: () => [currentAccountQuery],
    }
  );

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm<{
    username: string;
    password: string;
    passwordConfirmation: string;
  }>({
    defaultValues: {
      username: "",
      password: "",
      passwordConfirmation: "",
    },
    mode: "onChange",
  });

  const clearModalFields = () => {
    setValue("username", "");
    setValue("password", "");
    setValue("passwordConfirmation", "");
  };

  const handleModalCloseAndClearForm = (shouldOpenMfaDialog = false) => {
    clearModalFields();
    onModalClose(shouldOpenMfaDialog);
  };

  useEffect(() => {
    if (credentialToEdit != null) {
      setValue("username", credentialToEdit.username.trim(), {
        shouldValidate: true,
      });
      setValue("password", credentialToEdit.password.trim(), {
        shouldValidate: true,
      });
      setValue("passwordConfirmation", credentialToEdit.password.trim(), {
        shouldValidate: true,
      });
    }
  }, [credentialToEdit, setValue]);

  const onSubmit = async (data: { username: string; password: string }) => {
    try {
      const newPortalCredential = {
        username: data.username,
        password: data.password,
        PortalId: portal?.id,
        InstitutionId: currentInstitutionId,
      };

      await upsertPortalCredentials({
        variables: {
          credentials: newPortalCredential,
          isDelete: false,
        },
      });

      if (!requiresPhoneMfa) {
        await updateStatusCheckLoginRequiresAttn({
          variables: {
            PortalId: portal?.id,
          },
        });
      }

      if (credentialToEdit) {
        alert.success("Portal credential successfully edited");
      } else {
        alert.success("Portal credential successfully added");
      }

      handleModalCloseAndClearForm(requiresPhoneMfa);
    } catch (err: unknown) {
      alert.error(err instanceof Error ? err.message : "Unknown error");
    }
  };

  const onDelete = async () => {
    try {
      if (credentialToEdit != null) {
        await upsertPortalCredentials({
          variables: {
            credentials: {
              ...credentialToEdit,
              InstitutionId: currentInstitutionId,
            },
            isDelete: true,
          },
        });
        if (portal != null) {
          await upsertAccountForSamaUsers({
            variables: {
              patch: {
                id: currentAccount.id,
                mfaConfiguredPortalIds:
                  currentAccount.mfaConfiguredPortalIds.filter(
                    (id) => id !== +portal.id
                  ),
              },
            },
          });
        }

        alert.success("Portal credential successfully deleted");

        handleModalCloseAndClearForm();
      }
    } catch (err: unknown) {
      alert.error(
        err instanceof Error ? err.message : "Error while deleting credential"
      );
    }
  };

  const [ConfirmDialog, confirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog({
      message: "Are you sure you want to delete this portal login?",
      confirm: onDelete,
      zIndexOffset: 1,
    });

  const isPasswordViewable = () => {
    if (!credentialToEdit) {
      return true;
    }
    return (
      currentAccount.portalCredentials?.includes(credentialToEdit) ?? false
    );
  };

  return (
    <Modal
      id={`pendo_portalCredentialModal_${credentialToEdit ? "edit" : "add"}`}
      header={
        credentialToEdit
          ? `Edit Portal Login - ${portal?.title ?? ""}`
          : `Add Portal Login - ${portal?.title ?? ""}`
      }
      headerStyleOverride={{ textAlign: "left" }}
      open={isOpen}
      onClick={() => handleModalCloseAndClearForm(false)}
    >
      <ModalBodyLarge>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <FormControl
              error={errors.username?.message}
              label={<span>Portal Username</span>}
              style={{
                color: theme.colors.darkGray,
                width: "48%",
              }}
            >
              <Input
                autoComplete={credentialToEdit ? "off" : "username"}
                id="portal-username"
                error={errors.username != null}
                {...register("username", {
                  required: "Username is required",
                  // Internally maintain a trimmed version of the value. This is
                  // the value that gets used for validation, not what gets
                  // displayed to the user; we'll wait to trim the value
                  // displayed to the the user until this field loses focus.
                  // This lets us avoid trimming the input as a user types,
                  // which is disorienting.
                  //
                  // Note that if we omitted this handler and only trimmed the
                  // string in an onBlur handler, the user could bypass the trim
                  // by pressing <Enter> to submit the form.
                  setValueAs<T>(value: T): T | string {
                    return typeof value === "string" ? value.trim() : value;
                  },
                })}
                onBlur={({ target }) =>
                  setValue("username", target.value.trim(), {
                    shouldValidate: true,
                  })
                }
              />
            </FormControl>

            <FormControl
              error={errors.password?.message}
              label={<span>Portal Password</span>}
              style={{
                color: theme.colors.darkGray,
                width: "48%",
              }}
            >
              <PasswordField
                inputId="portal-password"
                formRegister={register("password", {
                  required: "Password is required",
                })}
                canUserViewPassword={isPasswordViewable()}
              />
            </FormControl>
            <FormControl
              error={errors.passwordConfirmation?.message}
              label={<span>Re-Enter Password</span>}
              style={{
                color: theme.colors.darkGray,
                width: "48%",
              }}
            >
              <PasswordField
                inputId="portal-password-confirmation"
                formRegister={register("passwordConfirmation", {
                  required: "Password confirmation is required",
                  validate: (value) =>
                    getValues("password") === value ||
                    "Password does not match",
                })}
                canUserViewPassword={isPasswordViewable()}
              />
            </FormControl>
          </ModalContent>

          <ModalFooter>
            <LeftRightSpreadCenterV>
              <WhiteButton
                id="pendo_portalCredentialModal_cancel"
                onClick={() => handleModalCloseAndClearForm(false)}
              >
                Cancel
              </WhiteButton>
              <LeftRightCenterV>
                {credentialToEdit != null && (
                  <WhiteButton
                    style={{ marginRight: "12px" }}
                    onClick={() => setConfirmDialogOpen(true)}
                  >
                    Remove Login
                  </WhiteButton>
                )}
                <PrimaryButton
                  id={`pendo_portalCredentialModal_${
                    credentialToEdit ? "save" : "add"
                  }`}
                  type="submit"
                >
                  {requiresPhoneMfa ? "Save & Continue" : "Save"}
                </PrimaryButton>
              </LeftRightCenterV>
            </LeftRightSpreadCenterV>
          </ModalFooter>
        </form>
      </ModalBodyLarge>
      <ConfirmDialog {...confirmDialogProps} />
    </Modal>
  );
};

export default PortalCredentialModal;
