import { useEffect } from "react";
import { upperFirst } from "lodash";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Box,
  Stack,
  Button,
  Typography,
  LoadingButton,
  List,
  ListItem,
} from "@samacare/design/core";
import { Span } from "@samacare/design/core/Typography";

import { Authorization, NewEnrollmentStatusEnumType } from "@samacare/graphql";
import { EnrollmentSubmitMutation } from "@@generated/graphql";
import EnrollmentSubmitMutationGql from "./EnrollmentSubmitMutation.gql";
import ROUTES from "../../../routes/ROUTE_PATHS";
import { EnrollmentRoutes } from "../../../routes/Enrollment/Enrollment.types";

const STATUSES = window.CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;

type EnrollmentSubmitProps = {
  authorization: Authorization;
  disabled: boolean;
  loading: boolean;
  onBack: () => void;
  onSubmitAuth: (skip?: boolean) => Promise<boolean>;
};

export const EnrollmentSubmit: React.FC<EnrollmentSubmitProps> = ({
  onBack = () => {},
  onSubmitAuth = () => false,
  authorization,
  disabled = false,
  loading = false,
}) => {
  const history = useHistory();
  const [mutation, { data, loading: mutationLoading }] =
    useMutation<EnrollmentSubmitMutation>(EnrollmentSubmitMutationGql, {
      variables: { authorizationId: authorization.id },
      refetchQueries: ["GetAuthorization", "Enrollments"],
    });

  const isLoading = loading || mutationLoading;
  const { newEnrollment: enrollment } = authorization ?? {};

  const hasEnrollment = !!enrollment;

  const isShowSubmitButton =
    authorization.status === STATUSES.PRESUBMISSION ||
    authorization.status === STATUSES.EDIT_AND_RESUBMIT;

  useEffect(() => {
    if (data?.createEnrollmentFromAuthorization) {
      history.push(
        `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.details}/${data.createEnrollmentFromAuthorization.id}`
      );
    }
  });

  return (
    <Box p={4}>
      {isShowSubmitButton && (
        <>
          <Typography gutterBottom>
            To submit your authorization, complete the required enrollment
            module offered by {authorization.enrollmentProvider} (Avg completion
            time - 30 seconds)
          </Typography>
          <Typography gutterBottom>
            Your authorization will be submitted on completion of the
            enrollment.
          </Typography>
        </>
      )}
      {!isShowSubmitButton && (
        <>
          <Typography gutterBottom>
            Enrollment Status:
            {hasEnrollment && (
              <>
                <Span color="primary.main" sx={{ mx: 2 }}>
                  {upperFirst(
                    enrollment?.status.toLowerCase().replace(/_/g, " ")
                  )}
                </Span>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={async () => {
                    [
                      NewEnrollmentStatusEnumType.Completed,
                      NewEnrollmentStatusEnumType.Submitted,
                    ].includes(enrollment.status)
                      ? history.push(
                          `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.summary}/${enrollment?.id}`
                        )
                      : history.push(
                          `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.details}/${enrollment?.id}`
                        );
                  }}
                >
                  Enrollment #{enrollment?.id}
                </Button>
              </>
            )}
            {!hasEnrollment && (
              <>
                <Span color="primary.main" sx={{ mx: 2 }}>
                  Not started
                </Span>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={async () => {
                    await mutation();
                  }}
                >
                  Add Enrollment
                </LoadingButton>
              </>
            )}
          </Typography>
          <Typography gutterBottom>
            Authorization Status:
            <Span color="primary.main" sx={{ mx: 2 }}>
              {upperFirst(authorization.status.replace(/_/g, " "))}
            </Span>
          </Typography>
        </>
      )}
      <Box
        border="1px solid"
        borderColor="primary.main"
        borderRadius={1}
        p={2}
        mt={4}
      >
        <Typography color="primary.main" fontWeight="bold" maxWidth={800}>
          Simplified Enrollment to Pharma Services
        </Typography>
        <Typography color="primary.main" maxWidth={800}>
          SamaCare can now assist you enroll to services offered by Pharmas such
          as Copay Card Programs, Benefit Investigation, Prior Authorization,
          etc
        </Typography>
        <List sx={{ color: "primary.main", listStyleType: "disc", pl: 3 }}>
          <ListItem sx={{ display: "list-item" }}>
            Enroll in just 30 seconds
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Eliminate Redundant Data Entry
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            Simplified Consent Collection
          </ListItem>
        </List>
      </Box>
      <Box
        display="flex"
        mt={4}
        flexDirection="row"
        justifyContent="space-between"
      >
        {!isShowSubmitButton && (
          <Button
            type="submit"
            onClick={() => history.push("/")}
            color="neutral"
            variant="contained"
          >
            Back to Authorization List
          </Button>
        )}
        {isShowSubmitButton && (
          <>
            <Button
              type="submit"
              onClick={() => onBack()}
              color="neutral"
              variant="contained"
            >
              Back
            </Button>
            <Stack direction="row" gap={2}>
              <LoadingButton
                loading={isLoading}
                disabled={disabled}
                color="primary"
                variant="outlined"
                onClick={async () => onSubmitAuth()}
              >
                Submit without Enrollment
              </LoadingButton>
              <LoadingButton
                loading={isLoading}
                color="primary"
                variant="contained"
                disabled={disabled}
                onClick={async () => {
                  const isSuccess = await onSubmitAuth(true);
                  if (isSuccess) {
                    await mutation();
                  }
                }}
              >
                Continue with Enrollment
              </LoadingButton>
            </Stack>
          </>
        )}
      </Box>
    </Box>
  );
};
