import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";

import { withDefaultProps } from "./utils";
import signatureInfo from "./fragments/signatureInfo";

const GET_SIGNATURES = "getSignatures";
const GET_PRACTICE_SIGNATURES = "getPracticeSignatures";
export const withAddSignature = graphql(
  gql`
    mutation addSignature($data: String!, $addToInstitution: Boolean!) {
      createSignature(dataUrl: $data, addToInstitution: $addToInstitution) {
        ...signatureInfo
      }
    }
    ${signatureInfo}
  `,
  {
    name: "addSignature",
    options: () => ({
      refetchQueries: () => [GET_SIGNATURES, GET_PRACTICE_SIGNATURES],
    }),
  }
);

export const withSignatures = graphql(
  gql`
    query getSignatures {
      signaturesFindAll {
        ...signatureInfo
      }
    }
    ${signatureInfo}
  `,
  {
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      signatures: _.get(data, "signaturesFindAll"),
    })),
  }
);

export const withPracticeSignatures = graphql(
  gql`
    query getPracticeSignatures($findAllInstitutionalSignatures: Boolean!) {
      signaturesFindAll(
        findAllInstitutionalSignatures: $findAllInstitutionalSignatures
      ) {
        ...signatureInfo
      }
    }
    ${signatureInfo}
  `,
  {
    options: () => ({
      variables: {
        findAllInstitutionalSignatures: true,
      },
      fetchPolicy: "network-only",
    }),
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      practiceSignatures: _.get(data, "signaturesFindAll"),
    })),
  }
);

export const signatureByIdQuery = gql`
  query signatureById($id: Int!) {
    signatureById(id: $id) {
      ...signatureInfo
    }
  }
  ${signatureInfo}
`;

export const withDeleteSignature = graphql(
  gql`
    mutation deleteSignature($id: Int!) {
      deletePrescriberSignature(id: $id)
    }
  `,
  {
    name: "deleteSignature",
    options: () => ({
      refetchQueries: () => [GET_SIGNATURES, GET_PRACTICE_SIGNATURES],
    }),
  }
);
