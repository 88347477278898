import React from "react";
import { Typography } from "@samacare/design";
import styled from "styled-components";
import DescriptionOutlinedIcon from "@samacare/design/core/icons/DescriptionOutlinedIcon";

const Container = styled.div`
  display: flex;
  width: 424px;
  padding: 32px;
  border-radius: 8px;
  background: #6363f20a;
  margin: 32px auto auto 32px;
`;
const Wrapper = styled.div`
  display: flex;
  width: 360px;
  height: 232px;
  padding: 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #6363f21f;
  border-radius: 8px;
  gap: 8px;
`;
const IconWrapper = styled.span`
  color: #0000008a;
`;

const Message = styled(Typography)`
  font-size: 12px;
`;

const LinkButton = styled.a`
  font-size: 12px;
  color: rgb(99, 99, 242);

  &:hover {
    text-decoration: none;
  }
  &:active,
  &:focus {
    color: rgb(99, 99, 242);
  }
`;

const SimplifiedEmptyState = ({
  openReportMissingForm,
}: {
  openReportMissingForm: () => void;
}) => {
  const handleOpen = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    openReportMissingForm();
  };
  return (
    <Container>
      <Wrapper>
        <IconWrapper>
          <DescriptionOutlinedIcon />
        </IconWrapper>
        <Typography variant="subtitle1" textAlign="center">
          No Fax Forms Found
        </Typography>
        <Message variant="body2" textAlign="center">
          We could not find a form that matches the specific plan and insurance
          information in the Prior Authorization. You can try again with a new
          plan or insurance details or report{" "}
          <LinkButton href="#" onClick={handleOpen}>
            missing forms
          </LinkButton>
          .
        </Message>
      </Wrapper>
    </Container>
  );
};

export default SimplifiedEmptyState;
