import {
  Autocomplete,
  AutocompleteProps,
  TextField,
} from "@samacare/design/core";

export type AutocompleteSelectProps = Omit<
  AutocompleteProps<string, false, true, false>,
  "renderInput"
> & {
  cypressTag?: string;
  onChange: (value: string) => void;
};

export const AutocompleteSelect: React.FC<AutocompleteSelectProps> = ({
  onChange,
  cypressTag,
  ...remainingProps
}) => {
  return (
    <Autocomplete
      data-cy={cypressTag}
      disableClearable
      selectOnFocus
      handleHomeEndKeys
      autoHighlight
      onChange={(event, v) => onChange(v)}
      renderInput={(params) => (
        <TextField {...params} InputLabelProps={{ shrink: true }} />
      )}
      {...remainingProps}
    />
  );
};
