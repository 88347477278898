import * as React from "react";
import styled from "styled-components";
import { LeftRightCenterV } from "../app/components/LeftRight";

const SortButtonContainer = styled(LeftRightCenterV)`
  background-color: ${(props) => props.theme.lightestGray};
  height: 28px;
  border-radius: 4px;
`;

const SortButton = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 14px;
  border-radius: 4px;
  height: 28px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background-color: ${(props) =>
    props.selected === true ? props.theme.white : "transparent"};
  border: 1px solid;
  border-color: ${(props) =>
    props.selected === true ? props.theme.purple : "transparent"};
`;

export const TwoButtonToggle: React.FC<{
  rightLabel: string;
  onRightClick: () => void;
  leftLabel: string;
  onLeftClick: () => void;
  isRightSelected: boolean;
  cypressTag?: string;
}> = ({
  rightLabel,
  leftLabel,
  onRightClick,
  onLeftClick,
  isRightSelected,
  cypressTag,
}) => {
  return (
    <SortButtonContainer>
      <SortButton
        data-cy={`${cypressTag}_left`}
        onClick={onLeftClick}
        selected={!isRightSelected}
      >
        {leftLabel}
      </SortButton>
      <SortButton
        data-cy={`${cypressTag}_right`}
        onClick={onRightClick}
        selected={isRightSelected}
      >
        {rightLabel}
      </SortButton>
    </SortButtonContainer>
  );
};
