import { useCallback } from "react";
import { useDispatch } from "../configureStore";
import { setLocationId } from "../reducers/formReducer";

export const useSetLocationId = () => {
  const dispatch = useDispatch();

  return useCallback(
    (LocationId: string) => {
      dispatch(setLocationId(LocationId));
    },
    [dispatch]
  );
};
