import { formatDistance } from "date-fns";

import { LoadingButton, Typography, Stack } from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import OpenInNewIcon from "@samacare/design/core/icons/OpenInNew";
import {
  Patient,
  InsuranceCoverageCheckType,
  Account,
} from "@samacare/graphql";
import ROUTE_PATHS from "../../routes/ROUTE_PATHS";
import { segmentTypewriter } from "../../../analytics/analytics";
import { getSegmentEnumInsuranceTypeFromSamacareInsuranceType } from "../../../analytics/util";
import { ViewSource } from "../../../analytics/generated/segment";
import RefreshIcon from "@samacare/design/core/icons/RefreshIcon";
import WarningIcon from "@samacare/design/core/icons/Warning";
import CheckCircleIcon from "@samacare/design/core/icons/CheckCircle";
import CancelIcon from "@samacare/design/core/icons/CancelIcon";

const getInsuranceStatus = ({
  latestCoverageCheck,
  theme,
  eligibilityCheckError,
}: {
  latestCoverageCheck?: InsuranceCoverageCheckType | null;
  theme: any;
  eligibilityCheckError: boolean;
}): {
  message: string | null;
  icon: JSX.Element | null;
} => {
  if (eligibilityCheckError) {
    return {
      message: "Unable to check patient coverage",
      icon: (
        <WarningIcon
          sx={{ color: theme.palette.warning.light, fontSize: "20" }}
        />
      ),
    };
  }
  if (!latestCoverageCheck?.isActive) {
    return {
      message: "Inactive insurance",
      icon: (
        <CancelIcon sx={{ color: theme.palette.error.light, fontSize: "20" }} />
      ),
    };
  }
  return {
    message: "Active insurance",
    icon: (
      <CheckCircleIcon
        sx={{ color: theme.palette.success.light, fontSize: "20" }}
      />
    ),
  };
};

export const StatusDetailsForTreatmentView = ({
  eligibilityCheckError,
  latestCoverageCheck,
  currentAccount,
  patient,
  onRunClick,
  isLoading = false,
  disabled = false,
}: {
  eligibilityCheckError: boolean;
  latestCoverageCheck?: InsuranceCoverageCheckType | null;
  currentAccount: Account | null;
  patient: Patient | null;
  onRunClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  return (
    <>
      <Stack spacing={1} direction="row" alignItems="center">
        {
          getInsuranceStatus({
            latestCoverageCheck,
            theme,
            eligibilityCheckError,
          }).icon
        }
        <Typography variant="body1">
          {
            getInsuranceStatus({
              latestCoverageCheck,
              theme,
              eligibilityCheckError,
            }).message
          }
        </Typography>
      </Stack>

      {eligibilityCheckError && (
        <Typography variant="body2" paddingLeft="2rem">
          Update patient info to try again. If you continue to have this issue,
          please contact support.
        </Typography>
      )}

      {!eligibilityCheckError && (
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={2} direction="row">
            <LoadingButton
              size="small"
              onClick={() => {
                segmentTypewriter.viewBvLite({
                  accountId: +currentAccount!.id,
                  email: currentAccount!.email!,
                  isAdmin: currentAccount!.isAdmin,
                  institutionIsTop: currentAccount!.institution!.isTop,
                  institutionName: currentAccount!.institution!.name,
                  institutionSpecialty:
                    currentAccount!.institution!.practicingSpecialty ??
                    undefined,
                  insuranceName:
                    patient?.primaryInsurance?.insuranceCompany?.name ?? "",
                  insuranceState:
                    patient?.primaryInsurance?.insuranceState ?? "",
                  insuranceType:
                    getSegmentEnumInsuranceTypeFromSamacareInsuranceType(
                      patient?.primaryInsurance?.planType ?? undefined
                    ),
                  viewSource: ViewSource.Pa,
                });
                window.open(
                  `${
                    window.location.origin
                  }/#${`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${latestCoverageCheck?.id}`}`,
                  "_blank"
                );
              }}
              endIcon={<OpenInNewIcon sx={{ fontSize: 18 }} />}
              sx={{ maxWidth: "fit-content", padding: 0 }}
            >
              Review Benefits
            </LoadingButton>

            <LoadingButton
              data-cy="actionRunEligibilityCheck"
              type="button"
              onClick={onRunClick}
              loading={isLoading}
              disabled={disabled || eligibilityCheckError}
              variant="text"
              sx={{ width: "fit-content" }}
              endIcon={<RefreshIcon sx={{ fontSize: 18 }} />}
              size="small"
            >
              Check Coverage
            </LoadingButton>
          </Stack>
          {latestCoverageCheck?.checkedAt && (
            <Typography
              variant="caption"
              sx={{ display: "inline" }}
              color={theme.palette.text.secondary}
            >
              Updated{" "}
              {formatDistance(
                new Date(latestCoverageCheck?.checkedAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </Typography>
          )}
        </Stack>
      )}
    </>
  );
};
