import {
  AccountProviderQuery,
  AccountProviderQueryVariables,
} from "@@generated/graphql";

import { useQuery } from "@apollo/client";
import { Account } from "@samacare/graphql";
import { useEffect, useContext, createContext } from "react";

import ACCOUNT_PROVIDER_QUERY from "./AccountProviderQuery.gql";
import UserSessionRecorder from "../../services/UserSessionRecorder";
import { analytics } from "../../../analytics/analytics";

export const AccountContext = createContext<Account | undefined>(void 0);

export const useAccount = () => useContext(AccountContext);

export const AccountProvider: React.FC = ({ children }) => {
  const { data, loading } = useQuery<
    AccountProviderQuery,
    AccountProviderQueryVariables
  >(ACCOUNT_PROVIDER_QUERY);

  const user = data?.currentAccount as Account;

  useEffect(() => {
    if (user) {
      void analytics.identify(user.id, {
        email: user.email,
        institutionId: user.InstitutionId,
        isAdmin: user.isAdmin,
        isSamaUser: user.isSamaUser,
      });

      UserSessionRecorder.identify(
        user.id,
        `${user.firstName} ${user.lastName}`,
        user.email!
      );

      if (window.CONFIG.ENABLE_SATISMETER) {
        window.satismeter({
          writeKey: window.CONFIG.SATISMETER_KEY,
          userId: user.id,
          traits: {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            createdAt: user.createdAt,
          },
        });
      }

      if (window.CONFIG.ENABLE_FRESHDESK) {
        // Prefill these fields in the freshworks/freshchat widgets
        if (window.fcWidget) {
          window.fcWidget.user.setProperties({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          });
        }

        if (window.FreshworksWidget) {
          window.FreshworksWidget("identify", "ticketForm", {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          });

          // Hide these fields in the freshworks widget
          window.FreshworksWidget("hide", "ticketForm", ["name", "email"]);
        }
      }
    }
  }, [user]);

  return (
    <AccountContext.Provider value={user}>
      {data && !loading && children}
    </AccountContext.Provider>
  );
};
