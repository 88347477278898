import { Theme, Components } from "@mui/material";

export default (theme: Theme): Components["MuiFormControlLabel"] => ({
  styleOverrides: {
    asterisk: {
      color: theme.palette.error.main,
      "&$error": {
        color: theme.palette.error.main,
      },
    },
    label: {
      color: theme.palette.text.primary,
      ...theme.typography.body1,
    },
  },
});
