import { CreateNewEnrollmentMutation } from "@@generated/graphql";

import { gql, useMutation } from "@apollo/client";

const CREATE_ENROLLMENT_GQL = gql`
  mutation createNewEnrollment($patientId: Int, $EnrollmentProgramId: Int!) {
    createNewEnrollment(
      patientId: $patientId
      EnrollmentProgramId: $EnrollmentProgramId
    ) {
      id
    }
  }
`;

const useCreateNewEnrollment = () => {
  return useMutation<CreateNewEnrollmentMutation>(CREATE_ENROLLMENT_GQL, {
    refetchQueries: () => ["Enrollments"],
  });
};

export default useCreateNewEnrollment;
