import * as React from "react";
import styled from "styled-components";
import { Flex } from "../Flexbox";

export type ControlMenuContainerProps = React.ComponentProps<
  typeof ControlMenuContainer
>;

/**
 * @deprecated Use component from design/forms instead
 */
export const ControlMenuContainer = styled(Flex)`
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-color: ${({ theme }) => theme.colors.primaryDark};
  border-style: solid;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-width: 1px;
  box-shadow: 2px 4px 23px hsl(0, 0%, 0%, 23%);
  flex-direction: column;

  > :first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  > :last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  > *:not(:last-child) {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.colors.primaryDark};
    border-bottom-style: solid;
  }
`;
