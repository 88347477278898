import {
  GetGlobalTagsQuery,
  GetGlobalTagsQueryVariables,
} from "@@generated/graphql";
import { gql, useQuery } from "@apollo/client";

export const getGlobalTagsQuery = gql`
  query getGlobalTags {
    getGlobalTags
  }
`;

export const useGlobalTags = () => {
  const { data, error, loading } = useQuery<
    GetGlobalTagsQuery,
    GetGlobalTagsQueryVariables
  >(getGlobalTagsQuery);

  return {
    tags: data?.getGlobalTags ?? [],
    loading,
    error,
  };
};
