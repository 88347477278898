import * as React from "react";
import styled from "styled-components";
import { Flex, Box } from "../Flexbox";

export const ControlMenuItemContainer = styled(Flex)`
  &[aria-role="button"] {
    /* Double-clicking on an item selects its text without this */
    user-select: none;
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }

  &[aria-disabled="true"]:active {
    pointer-events: none;
  }

  &[aria-disabled="false"]:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  &[aria-disabled="false"]:active {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    color: White;
  }
`;

const BaseControlMenuItem: React.VoidFunctionComponent<{
  children: React.ReactNode | (() => React.ReactNode);
  disabled?: boolean;
  leftEnhancer?: React.ReactNode | (() => React.ReactNode);
  onClick?: () => void;
  rightEnhancer?: React.ReactNode | (() => React.ReactNode);
}> = ({
  children,
  disabled = false,
  leftEnhancer,
  onClick,
  rightEnhancer,
  ...rest
}) => (
  <ControlMenuItemContainer
    aria-disabled={onClick != null ? disabled : undefined}
    flexDirection="row"
    role={onClick != null ? "button" : undefined}
    tabIndex={onClick != null ? 0 : undefined}
    onClick={onClick}
    {...rest}
  >
    {leftEnhancer != null && (
      <Box className="menu-item-enhancer" flex="none" marginRight="10px">
        {typeof leftEnhancer === "function" ? leftEnhancer() : leftEnhancer}
      </Box>
    )}
    {/* Middle box  */}
    <Box flex="auto">
      {typeof children === "function" ? children() : children}
    </Box>
    {rightEnhancer != null && (
      <Box className="menu-item-enhancer" flex="none" marginLeft="10px">
        {typeof rightEnhancer === "function" ? rightEnhancer() : rightEnhancer}
      </Box>
    )}
  </ControlMenuItemContainer>
);

export type ControlMenuItemProps = React.ComponentProps<typeof ControlMenuItem>;

export const ControlMenuItem = styled(BaseControlMenuItem)<{
  backgroundColor?: string;
  color?: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  flex-grow: 1;
  padding-bottom: 14px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
`;

export type ControlMenuNestedMenuProps = React.ComponentProps<
  typeof ControlMenuNestedMenu
>;

/**
 * @deprecated Use component from design/forms instead
 */
export const ControlMenuNestedMenu = styled(Flex)<{
  direction?: "row" | "column";
}>`
  flex-direction: ${({ direction = "column" }) => direction};

  > ${ControlMenuItem}:not(:last-child) {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: ${({ theme }) => theme.colors.primaryDark};
    border-right-style: solid;
  }
`;
