const NON_APPROVALS = CONFIG.CONSTANTS.USER_NONAPPROVAL_TYPES;

export const formatNonApprovalTypeLabel = (type) => {
  if (type === NON_APPROVALS.OUT_OF_NETWORK) {
    return "Provider is out of network";
  }
  return type;
};

export const getNonApprovalTypesOptions = () => {
  return Object.values(NON_APPROVALS).map((t) => ({
    label: formatNonApprovalTypeLabel(t),
    value: t,
  }));
};
