import MuiTypography, { TypographyProps } from "@mui/material/Typography";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body: true;
    lead: true;
  }
}

const Typography: React.FC<TypographyProps> = (properties) => (
  <MuiTypography
    variantMapping={{
      body: "p",
      lead: "p",
    }}
    variant="body"
    {...properties}
  />
);

export default Typography;
export type { TypographyProps };
