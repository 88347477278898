import {
  Mutation,
  MutationCreateEdiAuthorizationArgs,
} from "@samacare/graphql";
import createEdiAuthorizationMutation from "../graphql/CreateEdiAuthorizationMutation.gql";

import { useMutation } from "@apollo/client";
import { GenericAuth } from "../types/GenericAuth";

const useCreateEdiAuthorization = () => {
  const [createEdiAuthorization] = useMutation<
    { createEdiAuthorization: Mutation["createEdiAuthorization"] },
    MutationCreateEdiAuthorizationArgs
  >(createEdiAuthorizationMutation);

  const create = async (genericAuth: GenericAuth) => {
    const result = await createEdiAuthorization({
      variables: genericAuth,
    });

    return result.data?.createEdiAuthorization;
  };

  return create;
};

export { useCreateEdiAuthorization };
