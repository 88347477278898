import { AuthorizationStep, AuthorizationStepKey } from "@samacare/graphql";

export const getStepByNumber = (
  steps: AuthorizationStep[],
  stepNumber: number
): AuthorizationStep => {
  const found = steps.find((step) => step.number === stepNumber);
  if (found === undefined) {
    throw new Error(`Step ${String(stepNumber)} not found`);
  }
  return found;
};

export const stepNumberForKey = (
  steps: AuthorizationStep[],
  stepKey: AuthorizationStepKey
): number => {
  const found = steps.find((step) => step.key === stepKey);
  if (found === undefined) {
    throw new Error(`Step ${String(stepKey)} not found`);
  }
  return found.number;
};

export const hasStep = (
  steps: AuthorizationStep[],
  stepKey: AuthorizationStepKey
): boolean => {
  const found = steps.find((step) => step.key === stepKey);
  return found !== undefined;
};

export const isStep = (
  steps: AuthorizationStep[],
  stepNumber: number,
  stepKey: AuthorizationStepKey
): boolean => {
  return getStepByNumber(steps, stepNumber).key === stepKey;
};
