import {
  Box,
  Logo,
  Link,
  Container,
  Typography,
  Background,
  CircularProgress,
} from "@samacare/design/core";

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { AuthLogin } from "./AuthLogin";
import { AuthConfirmLogin } from "./AuthConfirmLogin";
import { useAuth } from "../../providers/AuthProvider";
import { AuthVerification } from "./AuthVerification";
import { AuthResetPassword } from "./AuthResetPassword";
import { AuthConfirmResetPassword } from "./AuthConfirmResetPassword";
import { useEffect } from "react";
import { AUTH_TITLE_ID } from "./AuthTitle";

export const Auth: React.VFC = () => {
  const {
    isSignedIn,
    isChecked,
    isEmailVerified,
    signInStep,
    resetPasswordStep,
  } = useAuth();

  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (
      (signInStep?.signInStep === "RESET_PASSWORD" ||
        resetPasswordStep?.resetPasswordStep ===
          "CONFIRM_RESET_PASSWORD_WITH_CODE") &&
      location.pathname !== `${path}/reset-password`
    ) {
      history.push(`${path}/reset-password`);
    }
  }, [
    signInStep?.signInStep,
    resetPasswordStep?.resetPasswordStep,
    history,
    location,
    path,
  ]);

  if (!isChecked) {
    return (
      <Background variant="primary">
        <Box
          display="flex"
          alignItems="center"
          mt={20}
          flexDirection="column"
          height="100%"
        >
          <CircularProgress size={60} />
          <Typography variant="body1" color="primary" mt={2}>
            Loading...
          </Typography>
        </Box>
      </Background>
    );
  }

  if (isChecked && isSignedIn && isEmailVerified) {
    return <Redirect to="/" />;
  }

  return (
    <Background variant="primary">
      <Box display="flex" justifyContent="center" zIndex={1}>
        <Container maxWidth="xs">
          <Box display="flex" justifyContent="center" my={6} ml={-2}>
            <Box width={250}>
              <Logo />
            </Box>
          </Box>
          <Box boxShadow={1} bgcolor="white" width="100%" borderRadius={2}>
            <div id={AUTH_TITLE_ID} />
            <Box py={3} px={5}>
              <Switch>
                <Route path={`${path}/login`}>
                  {!signInStep?.signInStep && <AuthLogin />}
                  {signInStep?.signInStep ===
                    "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED" && (
                    <AuthConfirmLogin />
                  )}
                  {signInStep?.signInStep === "DONE" && !isEmailVerified && (
                    <AuthVerification />
                  )}
                </Route>
                <Route path={`${path}/reset-password`}>
                  {!resetPasswordStep?.resetPasswordStep &&
                    !signInStep?.signInStep && <AuthResetPassword />}
                  {(signInStep?.signInStep === "RESET_PASSWORD" ||
                    resetPasswordStep?.resetPasswordStep ===
                      "CONFIRM_RESET_PASSWORD_WITH_CODE") && (
                    <AuthConfirmResetPassword />
                  )}
                </Route>
                <Route path="*">
                  <Redirect to={`${path}/login`} />
                </Route>
              </Switch>
            </Box>
          </Box>
          <Switch>
            <Route path={`${path}/login`}>
              <Box textAlign="center" px={6} mt={2}>
                <Typography variant="caption" color="primary" display="block">
                  By logging in, you agree to SamaCare&apos;s{" "}
                  <Link href="https://app.samacare.com/sama_tos.pdf">
                    Terms of Use and Privacy Policy
                  </Link>
                  .{" "}
                  <Link href="https://docs.google.com/forms/d/1npABPJ7RXVNiiv9l_fIu1rWkNhCQ3tqZq479zMie9WA/edit?usp=drive_web">
                    Click here
                  </Link>{" "}
                  to anonymously report security issues or unethical behavior.
                </Typography>
              </Box>
            </Route>
          </Switch>
        </Container>
      </Box>
    </Background>
  );
};
