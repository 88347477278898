import React, { ReactNode } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
} from "@samacare/design/core";
import ExpandMoreIcon from "@samacare/design/core/icons/ExpandMore";

type AccordionSectionProps = AccordionProps & {
  title: string;
  children: ReactNode;
  renderTitle?: (title: string) => ReactNode;
};

const AccordionSection: React.FC<AccordionSectionProps> = ({
  children,
  renderTitle = (title) => <Typography fontWeight={700}>{title}</Typography>,
  ...props
}) => {
  const { title } = props;
  return (
    <Accordion disableGutters {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {renderTitle(title)}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;
