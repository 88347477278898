import { NextGenPatient } from "@samacare/graphql";

import {
  GetTestPatientsFromNextGenQuery,
  GetTestPatientsFromNextGenQueryVariables,
} from "@@generated/graphql";

import {
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
} from "@samacare/design";
import _ from "lodash";
import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@samacare/design/core";
import { useEffect, useState } from "react";
import {
  getTestPatientsFromNextGen as getTestPatientsFromNextGenQuery,
  useUploadDocumentsToEmr,
} from "../../../graphql/Emr";
import { useAlert } from "react-alert";
import { useLazyQuery } from "@apollo/client";
import { FormProvider, useForm, TextField } from "@samacare/form";
import ContentCopyIcon from "@samacare/design/core/icons/ContentCopy";
import Tooltip from "@samacare/design/core/Tooltip";

const TEST_DOCUMENT_AWS_KEY =
  "userUpload_userId_16248__2024_02_28T203447056_EMRUploadTestDocument.pdf";
const TEST_DOCUMENT_TITLE = "SamaCare Test Document Upload";
const FIRST_NAME = "firstName";
const LAST_NAME = "lastName";

type NextGenPatientSearchInputs = {
  firstName: string;
  lastName: string;
};

export const NextGenTestUploadModal: React.VoidFunctionComponent<{
  onClose: () => void;
  institutionId: number;
  open: boolean;
}> = ({ onClose, open, institutionId }) => {
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
  const [patients, setPatients] = useState<NextGenPatient[]>([]);
  const uploadDocumentsToEmr = useUploadDocumentsToEmr(
    institutionId.toString()
  );
  const alert = useAlert();
  const [errorContext, setErrorContext] = useState("");
  const [copied, setCopied] = useState(false);

  const [getTestPatientsFromNextGen, { data, loading }] = useLazyQuery<
    GetTestPatientsFromNextGenQuery,
    GetTestPatientsFromNextGenQueryVariables
  >(getTestPatientsFromNextGenQuery);

  const formMethods = useForm<NextGenPatientSearchInputs>({
    defaultValues: {
      [FIRST_NAME]: "",
      [LAST_NAME]: "",
    },
  });

  const handleClose = () => {
    onClose();
    formMethods.reset();
  };

  const handleCopyErrorContext = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(errorContext);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const handleSearch = async (searchData: NextGenPatientSearchInputs) => {
    const { firstName, lastName } = searchData;
    await formMethods.trigger();

    try {
      await getTestPatientsFromNextGen({
        variables: { institutionId, firstName, lastName },
      });
    } catch (e) {
      alert.error(
        `Unable to search test patients from Next Gen. Error: ${
          (e as Error)?.message
        }`
      );
    }
  };

  useEffect(() => {
    if (data) {
      const { testPatients, errorMessage } = data.getTestPatientsFromNextGen;

      if (testPatients?.length && testPatients !== null) {
        setPatients(testPatients);
      } else if (errorMessage) {
        alert.error(
          `Unable to get test patients for given institution: ${errorMessage}`
        );
      } else {
        alert.error("No test patients available for given institution");
      }
    }
  }, [data]);

  const handleUpload = async (testPatientsInd: number) => {
    const patientMRN = patients?.[testPatientsInd]?.id;
    if (!patientMRN) {
      alert.error("Unable to upload document: Invalid MRN");
    }
    alert.info("Uploading test document");
    const { error, requiresDebug } = await uploadDocumentsToEmr({
      patientMRN,
      documents: [
        {
          name: TEST_DOCUMENT_TITLE,
          awsKey: TEST_DOCUMENT_AWS_KEY,
          title: TEST_DOCUMENT_TITLE,
        },
      ],
      authorizationId: null,
      isTestUpload: true,
    });

    if (!error) {
      alert.info("Successfully uploaded test document");
    } else if (requiresDebug) {
      setErrorContext(error.message);
    } else {
      alert.error(error.message);
    }
  };

  const selectPatient = async (ind: number) => {
    setSelectedIndex(ind);
  };

  const formData = formMethods.watch();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select test patient</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          sx={{ paddingTop: ({ spacing }) => spacing(2) }}
        >
          <Stack direction="row" spacing={2}>
            <FormProvider {...formMethods}>
              <form>
                <Stack direction="row" spacing={2}>
                  {[FIRST_NAME, LAST_NAME].map((field) => (
                    <TextField
                      fullWidth
                      key={field}
                      label={_.startCase(field)}
                      name={field}
                    />
                  ))}
                </Stack>
              </form>
            </FormProvider>
            <Button
              onClick={async () => handleSearch(formData)}
              variant="outlined"
            >
              Search
            </Button>
          </Stack>
          <Typography>
            Choose one of these patients to test upload to NextGen
          </Typography>

          <List
            sx={{ height: ({ spacing }) => spacing(20), overflowY: "scroll" }}
          >
            {patients ? (
              _.map(patients, ({ id, name }, i) => (
                <ListItemButton
                  key={`filtered_file_${id}`}
                  selected={selectedIndex === i}
                  onClick={async () => selectPatient(parseInt(id))}
                >
                  <ListItemText primary={name} />
                </ListItemButton>
              ))
            ) : (
              <Typography>No test patients</Typography>
            )}
            {loading && <CircularProgress />}
          </List>
          {errorContext && (
            <Stack direction="column" spacing={1}>
              <Typography>
                External NextGen Error: forward the error context to practice
                user to start NextGen support ticket
              </Typography>
              <Tooltip title={copied ? "Copied!" : `Copy ${name}`}>
                <Button
                  variant="outlined"
                  endIcon={<ContentCopyIcon />}
                  sx={{ cursor: "pointer" }}
                  onClick={handleCopyErrorContext}
                >
                  Click to Copy
                </Button>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ marginLeft: ({ spacing }) => spacing(2) }}
          onClick={async () => {
            await handleUpload(selectedIndex as number);
          }}
          disabled={selectedIndex === null}
          variant="contained"
        >
          Test upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
