import _ from "lodash";

import { Tooltip } from "@samacare/design";
import Chip, { ChipProps } from "@samacare/design/core/Chip";
import { UnifiedBenefitsCheckType } from "@samacare/graphql";

const settings: Record<
  UnifiedBenefitsCheckType,
  { title: string; chipProps: ChipProps }
> = {
  [UnifiedBenefitsCheckType.Full]: {
    title: "Full Benefit Verification Check",
    chipProps: {
      color: "info",
    },
  },
  [UnifiedBenefitsCheckType.Lite]: {
    title: "Health Plan Benefit Check",
    chipProps: {
      style: { backgroundColor: "rgba(2, 136, 209, 0.5)" },
      color: "info",
    },
  },
};

export const TypeChip: React.FC<{
  type: UnifiedBenefitsCheckType;
}> = ({ type }) => {
  const { title, chipProps } = settings[type];
  return (
    <Tooltip title={title}>
      <Chip
        size="small"
        variant="filled"
        label={_.capitalize(type)}
        {...chipProps}
      />
    </Tooltip>
  );
};
