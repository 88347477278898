import _ from "lodash";
import * as React from "react";

import {
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  FormGroupProps,
  FormControlProps,
} from "@samacare/design/core";

import {
  useFormContext,
  RadioGroupField,
  useFormState,
  FieldError,
  type UseControllerProps,
  CheckboxField,
  CheckboxFieldProps,
  TextField,
} from "@samacare/form";

import {
  QuestionObjectType,
  QuestionEnableWhenObjectType,
  QuestionEnabledWhenBehaviourEnumType,
} from "./Questionnaire.types";

import { getQuestionVisibility } from "./lib/getQuestionVisibility";
import { getQuestionEnableWhenItems } from "./lib/getQuestionEnableWhenItems";

/**
 * @name QuestionnaireField
 * @type Component
 */
type QuestionnaireFieldProps = Pick<UseControllerProps, "rules"> &
  Omit<Partial<QuestionObjectType>, "answerOption"> & {
    row?: boolean;
    hideLabel?: boolean;
    disabled?: boolean;
    nameOverride?: string;
    inputRef?: React.Ref<any>;
    radioGroupProps?: Partial<FormGroupProps>;
    formControlProps?: Partial<FormControlProps>;
    checkboxFieldProps?: Partial<CheckboxFieldProps>;
    answerOption?: { valueString: string; label?: React.ReactNode }[];
    fullWidth?: boolean;
  };

const QuestionnaireField: React.FC<QuestionnaireFieldProps> = ({
  id,
  type,
  text,
  rules,
  answerOption,
  enableBehavior,
  hideLabel = false,
  inputRef = null,
  disabled = false,
  required: defaultRequired,
  enableWhen: defaultEnableWhen,
  nameOverride,
  radioGroupProps = {
    row: true,
  },
  formControlProps = {},
  checkboxFieldProps = {},
  fullWidth,
}) => {
  const { watch, control } = useFormContext();
  const { errors } = useFormState({ control });

  const required = defaultRequired ?? false;
  const enableWhen = defaultEnableWhen ?? [];

  const items = getQuestionEnableWhenItems(
    enableWhen as QuestionEnableWhenObjectType[]
  );

  const values = items.length > 0 ? watch(items) : false;

  const visible = getQuestionVisibility(
    values,
    enableWhen as QuestionEnableWhenObjectType[],
    enableBehavior as QuestionEnabledWhenBehaviourEnumType
  );

  if (!visible) return null;

  const name = nameOverride ?? `questionnaire.${id}`;
  const error = _.get(errors, name) as FieldError;

  switch (type) {
    case "choice":
      return (
        <FormControl
          error={!!error}
          required={required}
          disabled={disabled}
          {...formControlProps}
        >
          {!hideLabel && <FormLabel error={!!error}>{text}</FormLabel>}
          <RadioGroupField
            name={name}
            required={required}
            rules={rules}
            {...radioGroupProps}
          >
            {answerOption?.map(({ valueString, label }) => {
              return (
                <FormControlLabel
                  disabled={disabled}
                  key={`${id}-${valueString}`}
                  control={<Radio disabled={disabled} />}
                  label={label ?? valueString}
                  value={valueString}
                />
              );
            })}
          </RadioGroupField>
        </FormControl>
      );
    case "boolean":
      return (
        <FormControl
          required={required}
          disabled={disabled}
          {...formControlProps}
        >
          <CheckboxField
            name={name}
            inputRef={inputRef}
            label={text}
            disabled={disabled}
            required={required}
            rules={rules}
            {...checkboxFieldProps}
          />
        </FormControl>
      );

    default:
      return (
        <TextField
          type={type === "number" ? "number" : "text"}
          sx={{ mb: 2 }}
          name={name}
          label={text}
          required={required}
          disabled={disabled}
          rules={rules}
          fullWidth={fullWidth}
        />
      );
  }
};

export default QuestionnaireField;
