import * as React from "react";
import {
  Link as BaseRouterLink,
  LinkProps as BaseRouterLinkProps,
} from "react-router-dom";
import { Link } from "./Link";

/**
 * @deprecated Use a MUI component instead
 */
export const RouterLink: React.ComponentType<
  Omit<BaseRouterLinkProps, "component"> & {
    // Rename the `component` prop to `as` to match all our other components
    as?: BaseRouterLinkProps["component"];
  }
> = ({ as = Link, ...rest }) => <BaseRouterLink component={as} {...rest} />;
