import { AnalyticsBrowser } from "@segment/analytics-next";
import typewriter from "./generated/segment";

export const analytics = AnalyticsBrowser.load({
  writeKey: window.CONFIG.SEGMENT_WRITE_KEY,
});

typewriter.setTypewriterOptions({
  analytics,
});

export const segmentTypewriter = typewriter;
