import { useEffect } from "react";
import { withLDProvider, useLDClient } from "launchdarkly-react-client-sdk";

import { useCurrentAccount } from "../../graphql/Account";

const FeatureProviderComponent: React.FC = ({ children }) => {
  const client = useLDClient();
  const [account] = useCurrentAccount();

  // This is where we set up the Launch Darkly context for the app. We populate the context parameters so that subsequent calls to useFeatureFlag are based on the correct user/inst
  useEffect(() => {
    if (client && account) {
      client
        .identify({
          kind: "multi",
          institution: {
            key: `inst-${account?.institution?.id}`,
            id: account?.institution?.id,
            name: account?.institution?.name,
            specialty: account?.institution?.practicingSpecialty,
          },
          account: {
            id: account.id,
            key: account.email,
            isAdmin: account.isAdmin,
            isSamaUser: account.isSamaUser,
            lastName: account.lastName,
            firstName: account.firstName,
          },
        })
        .then(() => null)
        .catch((err) =>
          // eslint-disable-next-line no-console
          console.error("Failed to configure Launch Darkly", err)
        );
    }
  }, [account, client]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const FeatureProvider = withLDProvider({
  clientSideID: window.CONFIG.LAUNCH_DARKLY_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(FeatureProviderComponent);
