import _ from "lodash";
import { PureComponent } from "react";
import { Query } from "@apollo/client/react/components";
import styled from "styled-components";

import { formsFindActiveQuery } from "../../graphql/AuthorizationForm";
import BaseButton from "../../components/BaseButton";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: center;

  span {
    margin-left: auto;
    margin-right: 4px;
  }
`;

const ScrollContainer = styled.div`
  height: 75vh;
  max-height: 500px;
  overflow: scroll;
`;

const homogenizeString = (string) => string.replace(/\s/g, "").toLowerCase();
class FormFinder extends PureComponent {
  state = { searchText: "" };

  render() {
    const { onClick } = this.props;
    const { searchText } = this.state;

    return (
      <div>
        <Query query={formsFindActiveQuery}>
          {({ loading, data }) => {
            if (loading) return null;

            return (
              <div>
                <input
                  data-cy="importConfigFormSearch"
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  placeholder="Search forms"
                  style={{
                    width: "100%",
                    marginTop: 10,
                    borderRadius: 3,
                  }}
                />
                <ScrollContainer data-cy="importConfigFormSearchResults">
                  {_.map(
                    _.filter(
                      data.authorizationForms.edges.map(({ node }) => node),
                      (form) =>
                        homogenizeString(
                          `${form.title}${form.description}`
                        ).includes(homogenizeString(searchText))
                    ),
                    (form) => (
                      <Row key={`import_list_${form.id}`}>
                        <a href={form.pdfURL}>{form.title}</a>
                        <span>{form.description}</span>
                        <BaseButton
                          onClick={() => {
                            onClick(form);
                          }}
                        >
                          Select Form
                        </BaseButton>
                      </Row>
                    )
                  )}
                </ScrollContainer>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default FormFinder;
