import { PureComponent } from "react";
import styled from "styled-components";
import { withAlert } from "react-alert";

import FormFinder from "./FormFinder";
import Modal from "../../components/Modal";
import BaseButton from "../../components/BaseButton";

const RelativeButton = styled(BaseButton)`
  position: relative;
  margin-right: 10px;
`;

const ImportInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
`;

const ButtonContainer = styled.div`
  display: flex;

  ${BaseButton} {
    margin: 10px;
    position: relative;
  }
`;

class ImportConfigButton extends PureComponent {
  state = { open: false, fromProd: false };

  inputRef = null;

  importLocally = () => {
    const { importLocalConfig } = this.props;

    importLocalConfig(this.inputRef);
    this.setState({ open: false });
  };

  importRemoteConfig = (id, pdfURL, specification, imgKeys) => {
    const { importRemoteConfig } = this.props;

    importRemoteConfig(id, pdfURL, specification, imgKeys);
    this.setState({ open: false, fromProd: false });
  };

  render() {
    const { open, fromProd } = this.state;

    return (
      <>
        <RelativeButton
          data-cy="controlImportPdfFormConfig"
          onClick={() => {
            this.setState({ open: true, fromProd: false });
          }}
        >
          Import A Config
        </RelativeButton>
        <Modal
          open={open}
          header="Import a Config"
          onClick={() => {
            this.setState({ open: false });
          }}
        >
          {!fromProd ? (
            <ButtonContainer>
              <BaseButton>
                <ImportInput
                  type="file"
                  multiple
                  ref={(ref) => (this.inputRef = ref)}
                  onChange={this.importLocally}
                />
                Import from Computer
              </BaseButton>
              <BaseButton
                data-cy="controlImportPdfFormConfigFromDb"
                onClick={() => {
                  this.setState({ fromProd: true });
                }}
              >
                Import From DB
              </BaseButton>
            </ButtonContainer>
          ) : (
            <FormFinder
              onClick={(form) => {
                this.importRemoteConfig(
                  form.id,
                  form.pdfURL,
                  form.specification,
                  form.imgKeys
                );
              }}
            />
          )}
        </Modal>
      </>
    );
  }
}

/**
 * @deprecated Use a MUI component instead
 */
export default withAlert()(ImportConfigButton);
