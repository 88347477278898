import { graphql } from "@apollo/client/react/hoc";
import gql from "graphql-tag";

const refreshPrimaryInsuranceCoverageCheck = gql`
  mutation refreshPrimaryInsuranceCoverageCheck($PatientId: Int!) {
    refreshPrimaryInsuranceCoverageCheck(PatientId: $PatientId) {
      data {
        id
      }
      success
      errorMessage
    }
  }
`;

export const withRefreshPrimaryInsuranceCoverageCheck = graphql(
  refreshPrimaryInsuranceCoverageCheck,
  {
    name: "refreshPrimaryInsuranceCoverageCheck",
    options: {
      refetchQueries: ["GetAuthorization"],
    },
  }
);
