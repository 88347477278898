import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import { withAlert } from "react-alert";
import { BaseText } from "Segment/StyledComponents";
import colors from "Resources/colors";

import FormFinder from "./FormFinder";
import Modal from "../../components/Modal";
import BaseButton from "../../components/BaseButton";
import FormConfirmationView from "../NewAuthorization/SelectFormModal/FormConfirmationView";
import FormEdit from "../Support/SupportFormsNumbers/routes/FormEdit";

const RelativeButton = styled(BaseButton)`
  position: relative;
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;

  ${BaseButton} {
    margin: 10px;
    position: relative;
  }
`;

const TwoPane = styled.div`
  display: flex;
  flex-direction: row;
  width: 1100px;
  max-height: 600px;
`;

const ScrollContainer = styled.div`
  max-height: 500px;
  overflow: scroll;
  margin-left: 10px;
  width: 100%;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const StyledButton = styled(BaseButton)`
  width: 175px;
`;

class ImportConfigButton extends PureComponent {
  state = {
    open: false,
    uploadAsNew: false,
    uploadAsExisting: false,
    renderFormEdit: false,
  };

  exportLocally = () => {
    const { exportLocally } = this.props;
    exportLocally();
    this.setState({
      open: false,
      uploadAsNew: false,
      uploadAsExisting: false,
      renderFormEdit: false,
    });
  };

  upsert = async (
    id,
    { title = "", description = "" } = {},
    stateOverride = null
  ) => {
    const { upsertForm, alert } = this.props;
    if (id === null && (!title || !description)) {
      alert.error("Title and description are required");
    } else {
      await upsertForm(id, { title, description });
      if (stateOverride) {
        this.setState(stateOverride);
      } else {
        this.setState({
          open: false,
          uploadAsNew: false,
          uploadAsExisting: false,
          renderFormEdit: false,
        });
      }
    }
  };

  render() {
    const { importedRemoteFormId, institutionId } = this.props;
    const {
      open,
      title,
      description,
      uploadAsNew,
      uploadAsExisting,
      renderFormEdit,
    } = this.state;

    return (
      <>
        <RelativeButton
          data-cy="controlExportPdfConfig"
          onClick={() => {
            this.setState({
              open: true,
              uploadAsNew: false,
              uploadAsExisting: false,
              renderFormEdit: false,
            });
          }}
        >
          Export this Config
        </RelativeButton>
        <Modal
          open={open}
          header="Export"
          onClick={() => {
            this.setState({ open: false });
          }}
        >
          {!(uploadAsNew || uploadAsExisting || renderFormEdit) && (
            <ButtonContainer style={{ marginTop: "10px" }}>
              <StyledButton onClick={this.exportLocally}>
                Export Locally
              </StyledButton>
              {_.isNil(importedRemoteFormId) ? (
                <span>
                  <StyledButton
                    data-cy="controlUploadNewForm"
                    onClick={() => {
                      this.setState({ uploadAsNew: true });
                    }}
                  >
                    Upload New Form
                  </StyledButton>
                  <StyledButton
                    onClick={() => {
                      this.setState({ uploadAsExisting: true });
                    }}
                  >
                    Update Existing Form
                  </StyledButton>
                </span>
              ) : (
                <BaseButton
                  onClick={() => {
                    this.upsert(importedRemoteFormId);
                  }}
                >
                  Update Imported Form
                </BaseButton>
              )}
            </ButtonContainer>
          )}
          {uploadAsNew && (
            <div>
              Title
              <BaseText
                data-cy="controlPdfFormTitle"
                onChange={(e) => {
                  this.setState({ title: e.target.value });
                }}
                value={title || ""}
                style={{ margin: "5px 0" }}
              />
              Description
              <BaseText
                data-cy="controlPdfFormDescription"
                onChange={(e) => {
                  this.setState({ description: e.target.value });
                }}
                value={description || ""}
                style={{ marginTop: "5px" }}
              />
              <div style={{ display: "flex" }}>
                <BaseButton
                  style={{ width: "150px", marginTop: "25px" }}
                  onClick={() => {
                    this.setState({ uploadAsNew: false });
                  }}
                  color={colors.gray}
                >
                  Back
                </BaseButton>
                <BaseButton
                  data-cy="controlUploadNewForm"
                  onClick={async () => {
                    await this.upsert(
                      null,
                      { title, description },
                      { renderFormEdit: true, uploadAsNew: false }
                    );
                  }}
                  style={{
                    marginLeft: "auto",
                    marginTop: "25px",
                    width: "150px",
                  }}
                >
                  Upload
                </BaseButton>
              </div>
            </div>
          )}
          {uploadAsExisting && (
            <FormFinder
              onClick={(form) => {
                this.upsert(form.id);
              }}
            />
          )}
          {renderFormEdit && (
            <>
              <TwoPane>
                <FormConfirmationView formId={importedRemoteFormId} />
                <ScrollContainer>
                  <FormEdit
                    institutionId={institutionId}
                    formId={importedRemoteFormId}
                  />
                </ScrollContainer>
              </TwoPane>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BaseButton
                  data-cy="controlDoneEditingForm"
                  onClick={() => {
                    this.setState({
                      open: false,
                      uploadAsNew: false,
                      uploadAsExisting: false,
                      renderFormEdit: false,
                    });
                  }}
                  style={{ width: "500px" }}
                >
                  I am done setting up this form
                </BaseButton>
              </div>
            </>
          )}
        </Modal>
      </>
    );
  }
}

/**
 * @deprecated Use a MUI component instead
 */
export default withAlert()(ImportConfigButton);
