import { Components, Theme } from "@mui/material";

export default (theme: Theme): Components["MuiAlert"] => ({
  styleOverrides: {
    filledInfo: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
});
