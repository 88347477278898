import styled from "styled-components";
import { Query } from "@apollo/client/react/components";
import { withFileByAWSKey } from "../../graphql/File";

const ResponseObject = styled.object`
  width: 100%;
  height: 800px;
  width: 600px;
  margin: auto;
`;

const PDFContainer = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  height: 100%;
  width: 100%;
`;

function SecureFileViewer({ location }) {
  const params = new URLSearchParams(location.search);

  return (
    <Query
      query={withFileByAWSKey}
      variables={{ awsKey: params.get("awsKey") }}
    >
      {({ loading, data }) => {
        if (loading) return null;

        return (
          <PDFContainer>
            <ResponseObject
              data={`${data.fileByAWSKey}#view=FitH`}
              type="application/pdf"
              aria-label="Authorization attachment"
            >
              <div style={{ textAlign: "center", fontSize: "24px" }}>
                404 No File Found
              </div>
            </ResponseObject>
          </PDFContainer>
        );
      }}
    </Query>
  );
}

export default SecureFileViewer;
