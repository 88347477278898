import { EnrollmentProgramChannel } from "@samacare/graphql";
import useEnrollmentProgramInUrl from "../../../form/hooks/useEnrollmentProgramInUrl";
import { EnrollmentApellisSummaryPage } from "./EnrollmentApellisSummaryPage";

import { useEnrollmentContext } from "./EnrollmentProvider";
import GenentechOcrevusSummary from "../../enrollments/GenentechOcrevusSummary";

export const EnrollmentSummaryPage: React.FC = () => {
  const { enrollment } = useEnrollmentContext();
  const enrollmentProgramInUrl = useEnrollmentProgramInUrl();
  const channel =
    enrollment?.EnrollmentProgram?.channel || enrollmentProgramInUrl?.channel;

  switch (channel) {
    case EnrollmentProgramChannel.Genentech:
      return <GenentechOcrevusSummary />;
    case EnrollmentProgramChannel.Assistrx:
    case EnrollmentProgramChannel.Horizon:
      return <EnrollmentApellisSummaryPage />;
    default:
      return <>Unknown channel</>;
  }
};
