import _ from "lodash";
import moment from "moment";

import { Grid, Stack, Typography } from "@samacare/design/core";
import {
  InsuranceCoverageCheckBenefitAmountCoInsuranceValue,
  InsuranceCoverageCheckBenefitAmountDeductibleValue,
  InsuranceCoverageCheckBenefitAmountOutOfPocketValue,
} from "@samacare/graphql";

import AccordionSection from "../../../../layout/AccordionSection";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import { useTheme } from "@samacare/design/core/styles";

const formatValue = ({
  value,
  units,
  suffix,
}: {
  value: string;
  units?: string | null;
  suffix?: string | null;
}) => {
  if (units != null && units.trim().toLowerCase() === "percent") {
    const parsedValue = parseFloat(value) * 100;
    if (Number.isNaN(parsedValue)) {
      // return the raw value if it is not a valid percentage
      return _.compact([value, units, suffix]).join(" ");
    }
    const percentStr = `${parsedValue.toFixed(2)}%`;
    return _.compact([percentStr, suffix]).join(" ");
  }
  return _.compact([value, units, suffix]).join(" ");
};

const OptionalValue: React.FC<{
  value?: string | null;
  units?: string | null;
  suffix?: string | null;
  emptyString?: string;
}> = ({ value, units, suffix, emptyString = "-" }) => {
  if (value == null) {
    return <Typography variant="body2">{emptyString}</Typography>;
  }
  return (
    <Typography variant="body2">
      {formatValue({ value, units, suffix })}
    </Typography>
  );
};

const PayerNotes: React.FC<{ values?: (string | null)[] | null }> = ({
  values,
}) => {
  if (values == null || values.length === 0) {
    return null;
  }
  return (
    <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
      {_.compact(
        values.map((value, i) => (
          <li key={i}>
            <Typography variant="body2">{value}</Typography>
          </li>
        ))
      )}
    </ul>
  );
};

const RenderSubtitle: React.FC<{ title: string }> = ({ title }) => {
  const theme = useTheme();
  return (
    <Typography variant="subtitle2" color={theme.palette.text.secondary}>
      {title}
    </Typography>
  );
};

const DeductibleValue: React.FC<{
  value: InsuranceCoverageCheckBenefitAmountDeductibleValue | null;
}> = ({ value }) => {
  if (value == null) {
    return null;
  }
  return (
    <Grid container marginTop={2}>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Level" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue value={value.level} />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Total" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue
          value={value.total}
          units={value.units}
          suffix={value.totalTimePeriod}
        />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Amount" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue
          value={value.amount}
          units={value.units}
          suffix={value.amountTimePeriod}
        />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Remaining" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue
          value={value.remaining}
          units={value.units}
          suffix={value.remainingTimePeriod}
        />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Start Date" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue
          value={
            value.startDate
              ? moment(value.startDate).utc().format("MM/DD/YYYY")
              : null
          }
        />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "End Date" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue
          value={
            value.endDate
              ? moment(value.endDate).utc().format("MM/DD/YYYY")
              : null
          }
        />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Payer Notes" })}
      </Grid>
      <Grid item xs={8}>
        <PayerNotes values={value.payerNotes} />
      </Grid>
    </Grid>
  );
};

const DeductibleValues: React.FC<{
  values?: (InsuranceCoverageCheckBenefitAmountDeductibleValue | null)[] | null;
}> = ({ values }) => {
  if (values == null || values.length === 0) {
    return null;
  }
  return (
    <Stack>
      {values.map((value, i) => (
        <DeductibleValue key={i} value={value} />
      ))}
    </Stack>
  );
};

const OutOfPocketValue: React.FC<{
  value: InsuranceCoverageCheckBenefitAmountOutOfPocketValue | null;
}> = ({ value }) => {
  if (value == null) {
    return null;
  }
  return (
    <Grid container marginTop={2}>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Level" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue value={value.level} />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Total" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue
          value={value.total}
          units={value.units}
          suffix={value.totalTimePeriod}
        />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Amount" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue
          value={value.amount}
          units={value.units}
          suffix={value.amountTimePeriod}
        />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Remaining" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue
          value={value.remaining}
          units={value.units}
          suffix={value.remainingTimePeriod}
        />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Payer Notes" })}
      </Grid>
      <Grid item xs={8}>
        <PayerNotes values={value.payerNotes} />
      </Grid>
    </Grid>
  );
};

const OutOfPocketValues: React.FC<{
  values?:
    | (InsuranceCoverageCheckBenefitAmountOutOfPocketValue | null)[]
    | null;
}> = ({ values }) => {
  if (values == null || values.length === 0) {
    return null;
  }
  return (
    <Stack>
      {values.map((value, i) => (
        <OutOfPocketValue key={i} value={value} />
      ))}
    </Stack>
  );
};

const CoInsuranceValue: React.FC<{
  value: InsuranceCoverageCheckBenefitAmountCoInsuranceValue | null;
}> = ({ value }) => {
  if (value == null) {
    return null;
  }
  return (
    <Grid container marginTop={2}>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Level" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue value={value.level} />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Amount" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue value={value.amount} units={value.units} />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Place of Service" })}
      </Grid>
      <Grid item xs={8}>
        <OptionalValue value={value.placeOfService} />
      </Grid>
      <Grid item xs={4}>
        {RenderSubtitle({ title: "Payer Notes" })}
      </Grid>
      <Grid item xs={8}>
        <PayerNotes values={value.payerNotes} />
      </Grid>
    </Grid>
  );
};

const CoInsuranceValues: React.FC<{
  values?:
    | (InsuranceCoverageCheckBenefitAmountCoInsuranceValue | null)[]
    | null;
}> = ({ values }) => {
  if (values == null || values.length === 0) {
    return null;
  }
  return (
    <Stack>
      {values.map((value, i) => (
        <CoInsuranceValue key={i} value={value} />
      ))}
    </Stack>
  );
};

const AccordionSectionTitle: React.FC<{ title: string }> = ({ title }) => {
  const theme = useTheme();
  return (
    <Typography variant="body1" color={theme.palette.text.primary}>
      {title}
    </Typography>
  );
};

export const BenefitsCheckBenefitDetails: React.FC<{
  name: string;
  defaultExpanded?: boolean;
}> = ({ name, defaultExpanded }) => {
  const theme = useTheme();

  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();
  const benefit = unifiedBenefitsCheck?.liteData?.activePlan?.benefits?.find(
    (b) => b?.name === name
  );

  if (!benefit) {
    return null;
  }

  return (
    <AccordionSection
      title={name}
      renderTitle={(title) => <AccordionSectionTitle title={title} />}
      defaultExpanded={defaultExpanded}
    >
      <Grid container rowSpacing={1}>
        {/*deductibles*/}
        {benefit.amounts?.deductibles?.notApplicableNetwork && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" color={theme.palette.primary.main}>
              Deductibles
            </Typography>
            <Typography variant="body2">
              <DeductibleValues
                values={benefit.amounts.deductibles.notApplicableNetwork}
              />
            </Typography>
          </Grid>
        )}
        {benefit.amounts?.deductibles?.inNetwork && (
          <Grid item xs={6}>
            <Stack direction="column">
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
              >
                Deductibles (in-network)
              </Typography>
              <Typography variant="body2">
                <DeductibleValues
                  values={benefit.amounts.deductibles.inNetwork}
                />
              </Typography>
            </Stack>
          </Grid>
        )}
        {benefit.amounts?.deductibles?.outOfNetwork && (
          <Grid item xs={6}>
            <Stack direction="column">
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
              >
                Deductibles (out-of-network)
              </Typography>
              <Typography variant="body2">
                <DeductibleValues
                  values={benefit.amounts.deductibles.outOfNetwork}
                />
              </Typography>
            </Stack>
          </Grid>
        )}

        {/* Out-of-pocket */}
        {benefit.amounts?.outOfPocket?.notApplicableNetwork && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" color={theme.palette.primary.main}>
              Out-of-pocket
            </Typography>
            <Typography variant="body2">
              <OutOfPocketValues
                values={benefit.amounts.outOfPocket.notApplicableNetwork}
              />
            </Typography>
          </Grid>
        )}
        {benefit.amounts?.outOfPocket?.inNetwork && (
          <Grid item xs={6}>
            <Stack direction="column">
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
              >
                Out-of-pocket (in-network)
              </Typography>
              <OutOfPocketValues
                values={benefit.amounts.outOfPocket.inNetwork}
              />
            </Stack>
          </Grid>
        )}
        {benefit.amounts?.outOfPocket?.outOfNetwork && (
          <Grid item xs={6}>
            <Stack direction="column">
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
              >
                Out-of-pocket (out-of-network)
              </Typography>
              <OutOfPocketValues
                values={benefit.amounts.outOfPocket.outOfNetwork}
              />
            </Stack>
          </Grid>
        )}

        {/* Co-Insurance */}
        {benefit.amounts?.coInsurance?.notApplicableNetwork && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" color={theme.palette.primary.main}>
              Co-Insurance
            </Typography>
            <CoInsuranceValues
              values={benefit.amounts.coInsurance.notApplicableNetwork}
            />
          </Grid>
        )}
        {benefit.amounts?.coInsurance?.inNetwork && (
          <Grid item xs={6}>
            <Stack direction="column">
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
              >
                Co-Insurance (in-network)
              </Typography>
              <CoInsuranceValues
                values={benefit.amounts.coInsurance.inNetwork}
              />
            </Stack>
          </Grid>
        )}
        {benefit.amounts?.coInsurance?.outOfNetwork && (
          <Grid item xs={6}>
            <Stack direction="column">
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
              >
                Co-Insurance (out-of-network)
              </Typography>
              <CoInsuranceValues
                values={benefit.amounts.coInsurance.outOfNetwork}
              />
            </Stack>
          </Grid>
        )}
      </Grid>
    </AccordionSection>
  );
};
