import { PureComponent } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";
import styled from "styled-components";

import BaseButton from "./BaseButton";
import Modal from "./Modal";
import { ModalFooter, ModalContent } from "./ModalStyledComponents";
import ROUTE_PATHS from "../routes/ROUTE_PATHS";
import { withUpdateAuthorization } from "../graphql/Authorization";

const ModalBody = styled.div`
  width: 500px;
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;
  font-size: 16px;
  white-space: normal;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  ${BaseButton} {
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const GrayButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.lightGray};
  border-color: ${(props) => props.theme.lightGray};
  color: white;
`;

export class ResubmitPortalAuthButton extends PureComponent {
  state = { open: false };

  triggerEditAndResubmit = async () => {
    const { authorizationId, updateAuthorization, alert, toAuthorization } =
      this.props;

    try {
      await updateAuthorization({
        variables: {
          id: authorizationId,
          patch: {
            status: CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT,
            submittedAt: null,
          },
        },
      });

      toAuthorization(authorizationId);
    } catch {
      alert.error("There was an error in edit and resubmit");
    }
  };

  toggleModal = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { children } = this.props;
    const { open } = this.state;

    return (
      <div>
        <Modal
          header="Resubmit Authorization"
          onClick={this.toggleModal}
          open={open}
        >
          <ModalBody>
            <CustomModalContent>
              <div style={{ marginBottom: 10 }}>
                Please verify that all authorization details are still accurate.
                Proceed to the portal specific details step and allow our system
                to fully run to successfully resubmit this authorization.
              </div>
              <div style={{ color: "red" }}>
                Warning: If significant time has passed, your request may now be
                considered a retro request. To avoid this, you can update the
                start and end dates of this request.
              </div>
            </CustomModalContent>
            <CustomModalFooter>
              <GrayButton onClick={this.toggleModal}>Cancel</GrayButton>
              <BaseButton onClick={this.triggerEditAndResubmit}>
                Confirm Resubmit
              </BaseButton>
            </CustomModalFooter>
          </ModalBody>
        </Modal>
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.toggleModal();
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toAuthorization(id) {
    const route = `${ROUTE_PATHS.PORTAL_AUTHORIZATION.path}?step=1&id=${id}`;
    dispatch(push(route));
  },
});

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default connect(
  () => ({}),
  mapDispatchToProps
)(compose(withUpdateAuthorization)(withAlert()(ResubmitPortalAuthButton)));
