import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

type IApplicationContext = {
  setIsNavigationMenuOpen?: Dispatch<SetStateAction<boolean>>;
  isNavigationMenuOpen?: boolean;
};

const ApplicationContext = createContext<IApplicationContext>({});

export const useApplicationContext = (): IApplicationContext => {
  const applicationContext =
    useContext<IApplicationContext>(ApplicationContext);
  return applicationContext;
};

const ApplicationProvider: React.FC = ({ children }) => {
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useState(true);

  return (
    <ApplicationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isNavigationMenuOpen,
        setIsNavigationMenuOpen,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
