import { VFC, useState } from "react";
import { Box, Popper, Typography } from "@samacare/design";
import Info from "@samacare/design/core/icons/InfoOutlined";
import { IngestJob } from "@samacare/graphql";
import { TopBottomCenterH } from "@samacare/component";

interface AddedUpdatedPopperProps {
  ingestJob: IngestJob;
}
export const AddedUpdatedPopper: VFC<AddedUpdatedPopperProps> = ({
  ingestJob,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleInfoMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        onMouseEnter={handleInfoMouseEnter}
        onMouseLeave={handleInfoMouseLeave}
        sx={{ cursor: "pointer" }}
      >
        <Info />
      </Box>
      <Popper open={open} anchorEl={anchorEl} placement="right-start">
        <Box
          sx={{
            margin: 1,
            padding: 2,
            bgcolor: "background.paper",
            boxShadow: 2,
            borderRadius: "4px",
          }}
        >
          <TopBottomCenterH>
            <Typography variant="h5">Added</Typography>
            <Typography variant="h3">{ingestJob.addedCount ?? 0}</Typography>
            <Typography
              variant="h5"
              sx={{ marginTop: ({ spacing }) => spacing(2) }}
            >
              Updated
            </Typography>
            <Typography variant="h3">{ingestJob.updatedCount ?? 0}</Typography>
          </TopBottomCenterH>
        </Box>
      </Popper>
    </>
  );
};
