// TODO(ndhoule): Because we now mount this state provider at the root of the
// app, at this point, we should move this file elsewhere.
import _ from "lodash";
import { createReducerContext } from "react-use";
import {
  AuthorizationConnectionSortByAttribute,
  AuthorizationStatusEnum,
} from "@samacare/graphql";

export interface Status {
  label: string;
  value: AuthorizationStatusEnum | null;
}

const ALL_STATUSES = Object.values(
  // WARNING - changing this value will limit the authorizations our front end queries to a subset of all statuses
  window.CONFIG.CONSTANTS.AUTHORIZATION_STATUSES
);

const formatStatuses = (values: string[]): Status[] =>
  values.map((value) => ({
    label: _.upperFirst(_.replace(value, /_/g, " ")),
    value: value as Status["value"],
  }));

export const defaultStatuses = formatStatuses(ALL_STATUSES);

export const createDefaultState = (): State => ({
  filters: {
    expirationDate: null,
    filterByExpired: false,
    portalId: null,
    showDOSFilters: true,
    showExpirationFilters: false,
    showStatusFilters: true,
    filterByUpcomingDateOfServiceDays: null,
    firstName: "",
    insuranceCompanyId: null,
    isArchived: { in: [false] },
    lastName: "",
    MRN: "",
    AuthorizationId: null,
    locationId: null,
    prescriberId: null,
    accountId: null,
    status: null,
    statuses: defaultStatuses
      .map(({ value }) => value)
      .filter(
        (
          value: AuthorizationStatusEnum | null
        ): value is AuthorizationStatusEnum => value != null
      ),
    jCode: "",
    drugName: "",
  },
  limit: undefined,
  sortBy: AuthorizationConnectionSortByAttribute.LastUpdated,
});

export type State = {
  filters: {
    expirationDate: { offset: number; unit: "days" | "weeks" } | null;
    filterByExpired: boolean;
    portalId: string | null;
    showDOSFilters: boolean;
    showExpirationFilters: boolean;
    showStatusFilters: boolean;
    filterByUpcomingDateOfServiceDays: string | null;
    firstName: string;
    insuranceCompanyId: string | null;
    isArchived: { in?: boolean[] };
    lastName: string;
    MRN: string;
    locationId: string | null;
    prescriberId: string | null;
    accountId: string | null;
    status: AuthorizationStatusEnum | null;
    statuses: AuthorizationStatusEnum[];
    AuthorizationId: string | null;
    jCode: string;
    drugName: string;
  };
  limit?: number;
  sortBy: AuthorizationConnectionSortByAttribute;
};

export type Action =
  | {
      type: "expirationDate";
      value: { offset: number; unit: "days" | "weeks" } | null;
    }
  | { type: "filterByExpired"; value: boolean }
  | { type: "portalId"; value: string | null }
  | { type: "filterByUpcomingDateOfServiceDays"; value: string | null }
  | { type: "firstName"; value: string | null }
  | { type: "insuranceCompanyId"; value: string | null }
  | { type: "isArchived"; value: { in?: boolean[] } }
  | { type: "lastName"; value: string | null }
  | { type: "AuthorizationId"; value: string | null }
  | { type: "MRN"; value: string | null }
  | { type: "jCode"; value: string | null }
  | { type: "drugName"; value: string | null }
  | { type: "limit"; value: number }
  | { type: "locationId"; value: string | null }
  | { type: "prescriberId"; value: string | null }
  | { type: "accountId"; value: string | null }
  | { type: "sortBy"; value: AuthorizationConnectionSortByAttribute }
  | { type: "status"; value: AuthorizationStatusEnum | null }
  | {
      type: "statuses";
      value: AuthorizationStatusEnum[];
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "limit":
    case "sortBy":
      return {
        ...state,
        [action.type]: action.value,
      };
    case "filterByExpired":
      /*
       * By toggling the Expiring Only filter to true, we want to change
       * **showExpirationFilters** to true and set the default expiration date to already expired
       *
       * By toggling the Expiring Only filter to false, we want to change
       * **showDOSFilters & showStatusFilters** to true
       * and reset **expirationDate**
       */
      return {
        ...state,
        filters: {
          ...state.filters,
          filterByExpired: action.value,
          showDOSFilters: !action.value,
          showExpirationFilters: action.value,
          showStatusFilters: !action.value,
          expirationDate: !action.value
            ? null
            : window.CONFIG.CONSTANTS.EXPIRATION_DATE_FILTERS.NOW,
          isArchived: { in: action.value ? undefined : [false] },
        },
      };
    default:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.type]: action.value,
        },
      };
  }
};

// Create a provider that stores the filter state across things like navigation
// events.
const [useFilterState, PatientFiltersStateProvider] = createReducerContext(
  reducer,
  createDefaultState()
);

export { useFilterState, PatientFiltersStateProvider };
