import { push } from "connected-react-router";
import { Path } from "history";
import { useStore } from "react-redux";

export interface History {
  push(this: void, path: Path): void;
}

/**
 * This is a hook that emulates the `useHistory` hook exposed by react-router,
 * but connects to Redux via connected-react-router. We don't particularly want
 * to keep router state in Redux anymore, but until we migrate off of it, it's
 * best not to fragment how we do programmatic routing.
 */
export const useHistory = (): History => {
  const store = useStore();

  return {
    push(path) {
      store.dispatch(push(path));
    },
  };
};
