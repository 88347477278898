import {
  NewEnrollmentObjectType,
  EnrollmentProgramChannel,
} from "@samacare/graphql";
export const convertYesNoToBool = (value: string | null | undefined) => {
  if (value == null) {
    return null;
  }
  if (value.toLowerCase() === "yes") {
    return true;
  }
  return false;
};

export const convertBoolToYesNo = (value: boolean | null | undefined) => {
  if (value == null) {
    return null;
  }
  if (value) {
    return "yes";
  }
  return "no";
};

export const getPatientConsentStatus = (
  enrollment: Partial<NewEnrollmentObjectType> | undefined
) => {
  if (
    enrollment?.PatientConsent?.hasConsent === false &&
    enrollment.EnrollmentProgram?.channel === EnrollmentProgramChannel.Genentech
  ) {
    return "SMS Sent to Patient";
  }

  if (
    enrollment?.PatientConsent?.hasConsent === false &&
    enrollment.EnrollmentProgram?.channel === EnrollmentProgramChannel.Assistrx
  ) {
    return "AssistRx to contact";
  }

  if (enrollment?.PatientConsent?.hasConsent) {
    if (enrollment?.PatientConsent?.File?.id) {
      return "Faxed";
    }

    return "Not Uploaded";
  }

  return "Unknown";
};
