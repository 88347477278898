import { gql, useMutation } from "@apollo/client";
import { AUTHORIZATION_PAGINATION_QUERY_NAME } from "../graphql/Authorization";

export const EMULATE_FAKE_FAX_RESPONSE = gql`
  mutation emulateFakeFaxResponse($authorizationId: Int!) {
    emulateFakeFaxResponse(authorizationId: $authorizationId) {
      id
    }
  }
`;

export const useEmulateFakeFaxResponse = () => {
  const [emulateFakeFaxResponse, { loading, error }] = useMutation(
    EMULATE_FAKE_FAX_RESPONSE,
    {
      refetchQueries: [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
      ],
    }
  );
  return { emulateFakeFaxResponse, loading, error };
};
