import { BaseCleave } from "./StyledComponents";

export const PhoneCleave = ({
  onChange,
  number,
  name,
  disabled = false,
  highlight,
  placeholder = "555-515-5175",
  cypressTag,
  onBlur = () => {},
}) => (
  <BaseCleave
    data-cy={cypressTag}
    placeholder={placeholder}
    options={{ delimiter: "-", blocks: [3, 3, 4], numericOnly: true }}
    onChange={onChange}
    name={name}
    value={number}
    disabled={disabled}
    highlight={highlight}
    onBlur={onBlur}
  />
);

export const SocialCleave = ({
  onChange,
  number,
  disabled = false,
  highlight,
}) => (
  <BaseCleave
    placeholder="865-50-6891"
    options={{ delimiter: "-", blocks: [3, 2, 4], numericOnly: true }}
    onChange={onChange}
    value={number}
    disabled={disabled}
    highlight={highlight}
  />
);

export const ZipCleave = ({
  onInit = () => {},
  onBlur = () => {},
  onChange,
  number = "",
  name,
  disabled = false,
  placeholder = "94105-9552",
  highlight,
}) => (
  <BaseCleave
    data-cy="fieldZip"
    placeholder={placeholder}
    options={{
      delimiter: "-",
      blocks: [5, 4],
      numericOnly: true,
      delimiterLazyShow: true,
    }}
    onChange={onChange}
    name={name}
    value={number}
    disabled={disabled}
    highlight={highlight}
    onBlur={onBlur}
    onInit={onInit}
  />
);

export const ExtensionCleave = ({
  onChange,
  number,
  name,
  disabled = false,
  placeholder = "12345",
  highlight,
}) => (
  <BaseCleave
    placeholder={placeholder}
    options={{ blocks: [5], numericOnly: true }}
    onChange={onChange}
    name={name}
    value={number}
    disabled={disabled}
    highlight={highlight}
  />
);
