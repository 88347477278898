import {
  Button,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  Stack,
} from "@samacare/design/core";
import { useCurrentAccount } from "../../graphql/Account";
import GridQuickFilter from "./GridQuickFilter";
import { useTheme } from "styled-components";

interface GridToolbarProps {
  onNewClick: () => void;
  newButtonDataCy?: string;
  disableExport?: boolean;
  disableSearch?: boolean;
  searchWord?: string;
}
export const GridToolbar: React.VFC<GridToolbarProps> = (props) => {
  const theme = useTheme();
  const buttonSx = { color: theme.primary };
  const [currentAccount] = useCurrentAccount();
  return (
    <Stack p={1} direction="row" alignItems="center" justifyContent="flex-end">
      {!props.disableSearch && (
        <Stack
          sx={{
            position: "absolute",
            left: 0,
            marginLeft: 2,
          }}
        >
          <GridQuickFilter searchWord={props.searchWord} />
        </Stack>
      )}
      <Stack direction="row" alignItems="center" spacing={1}>
        <GridToolbarContainer>
          <GridToolbarColumnsButton sx={buttonSx} />
          <GridToolbarFilterButton sx={buttonSx} />
          <GridToolbarDensitySelector sx={buttonSx} />
          {currentAccount?.isAdmin && !props.disableExport && (
            <GridToolbarExport sx={buttonSx} />
          )}
        </GridToolbarContainer>
        {props.onNewClick && (
          <Button
            data-cy={props.newButtonDataCy ?? "actionNew"}
            size="small"
            variant="contained"
            onClick={props.onNewClick}
          >
            New
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
