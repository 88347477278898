import { useEffect } from "react";
import { Stack, FormLabel } from "@samacare/design/core";
import { QuestionnaireField } from "../../app/components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import {
  useWatch,
  OfficeBlock,
  useFormContext,
  PhoneField,
} from "@samacare/form";
import { LocationInfoFragment } from "@@generated/graphql";

type InfusionFacilityBlockBlockProps = {
  disabled?: boolean;
  showInfusionFacilityCheckBox?: boolean;
};

export const InfusionFacilityBlockBlock: React.FC<
  InfusionFacilityBlockBlockProps
> = ({ disabled, showInfusionFacilityCheckBox }) => {
  const questionnaireData = useWatch({
    name: "questionnaire",
  });

  const locationData = useWatch({
    name: "location",
  });

  const { setValue } = useFormContext();

  const isLocationSameAsFacility =
    locationData.id === questionnaireData["sama-infusionFacilityId"];

  useEffect(() => {
    if (!isLocationSameAsFacility) {
      setValue("questionnaire.sama-isSameAsPrescriber", false);
    } else {
      setValue("questionnaire.sama-isSameAsPrescriber", true);
      setValue("infusionFacility", locationData);
    }
  }, [isLocationSameAsFacility]);

  const valueMap: {
    name: string;
    value: string;
    resetValue: string | null;
    locationPropName?: keyof LocationInfoFragment;
  }[] = [
    {
      name: "questionnaire.sama-infusionFacilityId",
      value: locationData.id,
      resetValue: null,
      locationPropName: "id",
    },
    {
      name: "questionnaire.sama-infusionFacilityName",
      value: locationData.name,
      resetValue: null,
      locationPropName: "facilityName",
    },
    {
      name: "questionnaire.sama-infusionFacilityTin",
      value: locationData.taxId,
      resetValue: null,
      locationPropName: "taxId",
    },

    {
      name: "questionnaire.sama-infusionFacilityNPI",
      value: locationData.NPI,
      resetValue: null,
      locationPropName: "NPI",
    },
    {
      name: "questionnaire.sama-infusionFacilityAddress",
      value: locationData.address,
      resetValue: null,
      locationPropName: "address",
    },
    {
      name: "questionnaire.sama-infusionFacilityCity",
      value: locationData.city,
      resetValue: null,
      locationPropName: "city",
    },
    {
      name: "questionnaire.sama-infusionFacilityState",
      value: locationData.state,
      resetValue: locationData.state,
      locationPropName: "state",
    },
    {
      name: "questionnaire.sama-infusionFacilityZip",
      value: locationData.zip,
      resetValue: null,
      locationPropName: "zip",
    },
    {
      name: "questionnaire.sama-Infusion-phone",
      value: questionnaireData["sama-Office-phone"],
      resetValue: null,
    },
    {
      name: "questionnaire.sama-Infusion-fax",
      value: questionnaireData["sama-Office-fax"],
      resetValue: null,
    },
  ];

  return (
    <Stack spacing={2}>
      <FormLabel required>Do you have a preferred infusion facility?</FormLabel>
      <QuestionnaireField
        id="ifHasInfusionFacility"
        type={QuestionTypeEnumType.Choice}
        text="
				If yes, please provide the information below. If no, Horizon By Your Side will provide options for your patient."
        disabled={disabled}
        answerOption={[{ valueString: "Yes" }, { valueString: "No" }]}
        required
        fullWidth
      />
      {questionnaireData.ifHasInfusionFacility === "Yes" && (
        <Stack spacing={2} direction="column">
          {showInfusionFacilityCheckBox && (
            <QuestionnaireField
              id="sama-isSameAsPrescriber"
              type={QuestionTypeEnumType.Boolean}
              text="The infusion facility is the same as the prescribing office"
              disabled={disabled}
              checkboxFieldProps={{
                onChangeOverride: (
                  event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => {
                  if (checked) {
                    valueMap.forEach(({ name, value }) =>
                      setValue(name, value)
                    );
                  } else {
                    valueMap.forEach(({ name, resetValue }) =>
                      setValue(name, resetValue)
                    );
                  }
                  setValue(
                    "questionnaire.sama-isSameAsPrescriber",
                    event.target.checked
                  );
                },
              }}
            />
          )}
          <OfficeBlock
            required
            disabled={disabled}
            name="infusionFacility"
            IdInputProps={{
              name: "questionnaire.sama-infusionFacilityId",
            }}
            NameInputProps={{
              name: "questionnaire.sama-infusionFacilityName",
            }}
            TINInputProps={{
              name: "questionnaire.sama-infusionFacilityTin",
            }}
            NPIInputProps={{
              name: "questionnaire.sama-infusionFacilityNPI",
            }}
            AddressInputProps={{
              name: "questionnaire.sama-infusionFacilityAddress",
            }}
            CityInputProps={{
              name: "questionnaire.sama-infusionFacilityCity",
            }}
            StateInputProps={{
              name: "questionnaire.sama-infusionFacilityState",
            }}
            ZipInputProps={{
              name: "questionnaire.sama-infusionFacilityZip",
            }}
            PTANInputProps={{
              name: "questionnaire.sama-infusionFacilityPTAN",
            }}
            onChange={(x, change, reason) =>
              reason === "selectOption"
                ? valueMap.forEach(({ name, locationPropName }) => {
                    if (locationPropName != null) {
                      setValue(name, change?.[locationPropName]);
                    }
                  })
                : valueMap.forEach(({ name, resetValue }) => {
                    setValue(name, resetValue);
                  })
            }
          />
          <Stack spacing={2} direction="row" maxWidth={600}>
            <PhoneField
              disabled={disabled}
              required
              rules={{ required: "This field is required" }}
              fullWidth
              name="questionnaire.sama-Infusion-phone"
              label="Phone"
            />
            <PhoneField
              disabled={disabled}
              fullWidth
              name="questionnaire.sama-Infusion-fax"
              label="Fax"
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
