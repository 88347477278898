import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { withAlert } from "react-alert";

import { withUpdateInstitutionUploadCategory } from "../../graphql/Institution";
import BaseButton from "../../components/BaseButton";
import { BaseText } from "../../components/Segment/StyledComponents";

const CategoryWarningContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const CategoryWarning = styled.span`
  color: ${(props) => props.theme.red};
`;

class AdminSettings extends PureComponent {
  constructor(props) {
    super(props);
    const { account } = this.props;

    this.state = {
      emrUploadCategory: _.get(account, "institution.emrUploadCategory"),
    };
  }

  updateInstitutionUploadCategory = async () => {
    const { account, updateInstitutionUploadCategory, alert } = this.props;
    const { emrUploadCategory } = this.state;

    if (_.isEqual(account.institution.emrUploadCategory, emrUploadCategory)) {
      alert.info(
        "No changes have been made to the OncoEMR document upload category!"
      );
    } else {
      try {
        await updateInstitutionUploadCategory({
          variables: { emrUploadCategory },
        });
        alert.success("Successfully updated the document upload category!");
      } catch {
        alert.error(
          "There was an error updating the document upload category."
        );
      }
    }
  };

  render() {
    const { emrUploadCategory } = this.state;

    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>OncoEMR Document Upload Category:</div>
          <BaseText
            onChange={(e) => {
              this.setState({ emrUploadCategory: e.target.value });
            }}
            value={emrUploadCategory || ""}
            style={{ width: "250px", margin: "10px 10px 5px" }}
          />
          <BaseButton
            onClick={this.updateInstitutionUploadCategory}
            style={{ marginLeft: "auto" }}
          >
            Save Changes
          </BaseButton>
        </div>
        <CategoryWarningContainer>
          <CategoryWarning>
            Warning - invalid document category can lead to an upload error.
            Please ensure that the category is spelled exactly as it is on
            OncoEMR.
          </CategoryWarning>
        </CategoryWarningContainer>
      </>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withUpdateInstitutionUploadCategory)(
  withAlert()(AdminSettings)
);
