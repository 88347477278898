import * as React from "react";
import { Account } from "@samacare/graphql";
import { useApplicationContext } from "app/providers/ApplicationProvider";
import List from "@samacare/design/core/List";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import BusinessOutlinedIcon from "@samacare/design/core/icons/BusinessOutlined";
import InternalOnlyToggleUserTypeForTesting from "../InternalOnlyToggleUserTypeForTesting";
import SwitchCurrentInstitution from "../SwitchCurrentInstitution";
import Tooltip from "@samacare/design/core/Tooltip/Tooltip";

export const AdminFunctions: React.VoidFunctionComponent<{
  account: Account;
  isInternalUserToggleEnabled: boolean;
  isInstitutionDropdownEnabled: boolean;
}> = ({
  account,
  isInternalUserToggleEnabled,
  isInstitutionDropdownEnabled,
}) => {
  const { isNavigationMenuOpen, setIsNavigationMenuOpen } =
    useApplicationContext();
  const theme = useTheme();

  return (
    <List>
      {isInternalUserToggleEnabled && (
        <Tooltip
          title={isNavigationMenuOpen ? "" : "Enable SamaUser Switch"}
          placement="right"
        >
          <ListItem disablePadding>
            <ListItemButton
              disableGutters
              sx={{
                minHeight: 48,
                borderRadius: theme.spacing(1),
                px: 2,
                justifyContent: isNavigationMenuOpen ? "initial" : "center",
                ":hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <ListItemText
                primary="SamaUser"
                sx={{
                  opacity: isNavigationMenuOpen ? 1 : 0,

                  transition: "ease-in-out .3s",
                }}
              />
              <ListItemIcon
                sx={{
                  mx: isNavigationMenuOpen ? 1.5 : "",
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <InternalOnlyToggleUserTypeForTesting account={account} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Tooltip>
      )}
      {isInstitutionDropdownEnabled && (
        <Tooltip
          title={isNavigationMenuOpen ? "" : "Select Institution"}
          placement="right"
        >
          <ListItem
            sx={{
              borderRadius: theme.spacing(1),
              display: "block",
            }}
            disablePadding
            disableGutters
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                px: isNavigationMenuOpen ? "" : 2.5,
                borderRadius: theme.spacing(1),
                justifyContent: isNavigationMenuOpen ? "initial" : "center",
                ":hover": {
                  bgcolor: isNavigationMenuOpen ? "transparent" : "",
                },
              }}
              disableTouchRipple
              disableRipple
              disableGutters
            >
              <ListItemIcon
                data-cy="controlSelectInstitution"
                sx={{
                  mx: isNavigationMenuOpen ? "" : 1.5,
                  px: isNavigationMenuOpen ? 2 : "",
                  justifyContent: "center",
                }}
              >
                {isNavigationMenuOpen ? (
                  <SwitchCurrentInstitution account={account} />
                ) : (
                  <BusinessOutlinedIcon
                    onClick={() => {
                      setIsNavigationMenuOpen
                        ? setIsNavigationMenuOpen(true)
                        : null;
                    }}
                  />
                )}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Tooltip>
      )}
    </List>
  );
};
