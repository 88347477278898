export default () => ({
    styleOverrides: {
        root: {
            "+ &": {
                backgroundColor: "transparent",
            },
        },
        asterisk: {
            color: "#db3131",
            fontSize: "1.5em",
            "&$error": {
                color: "#db3131",
            },
        },
    },
});
