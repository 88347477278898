import * as React from "react";
import {
  Step,
  StepLabel,
  Stepper,
  Paper,
  ListItemButton,
} from "@samacare/design/core";

import {
  useParams,
  useHistory,
  matchPath,
  useLocation,
} from "react-router-dom";

import ROUTE_PATHS from "../ROUTE_PATHS";
import { useStepContext } from "./StepProvider";

import { EnrollmentRoutes, EnrollmentRouteNames } from "./Enrollment.types";

function getActiveStep(active: EnrollmentRoutes) {
  switch (active) {
    case EnrollmentRoutes.summary:
      return 3;
    case EnrollmentRoutes.submission:
      return 2;
    case EnrollmentRoutes.details:
      return 1;
    default:
      return 0;
  }
}

export const EnrollmentMenu: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { enrollmentId } = useParams<{ enrollmentId?: string }>();
  const { active } = useStepContext();

  const activeStep = getActiveStep(active);

  return (
    <Paper>
      <Stepper orientation="vertical" connector={null} activeStep={activeStep}>
        {Object.entries(EnrollmentRouteNames).map(([route, name], i) => {
          const selected =
            matchPath(pathname, {
              path: `${ROUTE_PATHS.ENROLLMENTS_CREATE.path}/${route}/:enrollmentId?`,
              exact: true,
              strict: false,
            }) != null;

          return (
            <Step key={encodeURI(`enrollment-menu-item-${i}`)}>
              <ListItemButton
                selected={selected}
                onClick={
                  !selected
                    ? () =>
                        history.push(
                          `${ROUTE_PATHS.ENROLLMENTS_CREATE.path}/${route}/${enrollmentId}`
                        )
                    : undefined
                }
                disabled={activeStep != null && activeStep < i}
              >
                <StepLabel>{name}</StepLabel>
              </ListItemButton>
            </Step>
          );
        })}
      </Stepper>
    </Paper>
  );
};
