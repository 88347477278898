import { connect } from "react-redux";
import * as React from "react";
import { useAlert } from "react-alert";

import { BaseCleave } from "./StyledComponents";

const SegmentTin: React.VoidFunctionComponent<{
  handleChange: (config: { [key: string]: string }) => void;
  disabled: boolean;
  result: string;
  highlight: boolean;
  item: { key: string };
}> = ({ handleChange, disabled, result, highlight, item }) => {
  const alert = useAlert();

  return (
    <BaseCleave
      options={{
        delimiter: "-",
        blocks: [2, 7],
        numericOnly: true,
        delimiterLazyShow: true,
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        handleChange({ [item.key]: val.replace("-", "") });
      }}
      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
        // If blurring with fewer than 9 characters plus "-", clear out input
        if (e.target.value.length !== 10) {
          handleChange({ [item.key]: "" });

          alert.error("Tax ID must be 9 digits");
        }
      }}
      value={result ?? undefined}
      disabled={disabled}
      highlight={highlight}
      placeholder="12-1234567"
    />
  );
};

function mapStateToProps(state: any, props: { item: { key: string } }) {
  const { key } = props.item;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  return key ? { result: state.form.results[key] } : { result: null };
}

export default connect(mapStateToProps)(SegmentTin);
