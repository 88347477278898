import React from "react";
import styled from "styled-components";

interface ResponseObjectProps {
  height?: string;
  width?: string;
}

const ResponseObject = styled.object<ResponseObjectProps>`
  height: ${(props) => props.height ?? "700px"};
  width: ${(props) => props.width ?? "500px"};
`;

interface PdfViewerProps {
  fileURL: string;
  height?: string;
  mimeTypeOverride?: string;
  width?: string;
  preventRerender?: boolean;
}

const PdfViewer: React.FC<PdfViewerProps> = React.memo(
  ({ fileURL, height, mimeTypeOverride, width }) => {
    return (
      <ResponseObject
        aria-label="Authorization attachment"
        data={`${fileURL}#view=FitH`}
        height={height}
        type={mimeTypeOverride || "application/pdf"}
        width={width}
      />
    );
  },
  (prevProps, nextProps) => {
    if (!nextProps.preventRerender) return false;

    const getFileName = (url: string): string => {
      try {
        const parsedUrl = new URL(url);
        return parsedUrl.pathname;
      } catch (e) {
        return url;
      }
    };

    const prevFile = getFileName(prevProps.fileURL);
    const nextFile = getFileName(nextProps.fileURL);

    return prevFile === nextFile;
  }
);

export default PdfViewer;
