import { useMutation } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import {
  Account,
  Authorization,
  MutationUpsertAccountForSamaUsersArgs,
} from "@samacare/graphql";
import { LeftRightCenterV } from "../LeftRight";
import SegmentPhone from "../Segment/SegmentPhone";
import SegmentText from "../Segment/SegmentText";
import { useSelector } from "../../configureStore";
import {
  ACCOUNTS_FIND_ALL_QUERY_NAME,
  upsertAccountForSamaUsersMutation,
} from "../../graphql/Account";
import { useFieldConfigs } from "../../hooks/useFieldConfigs";
import { useHighlightRequiredFields } from "../../hooks/useHighlightRequiredFields";
import { useSet } from "../../hooks/useSet";
import { requireAuthEnrollmentRequiredFields } from "../../util/enrollUtils";
import { FormField } from "../../routes/PortalAuthorization/PortalAuthorizationLegacy/FormField";
import styled from "styled-components";
import BaseButton from "../BaseButton";

const CreateButton = styled(BaseButton)`
  height: 30px;
  width: 250px;
  position: absolute;
  left: calc(100% + 16px);
`;

const df = window.CONFIG.DEFAULT_FIELDS;

interface RequesterFieldsProps {
  isDisabled?: boolean;
  auth: Authorization;
  account: Account;
  saveChanges: () => Promise<void>;
}
export const RequesterFields: React.FC<RequesterFieldsProps> = (props) => {
  const set = useSet();
  const highlightRequiredFields = useHighlightRequiredFields();

  const alert = useAlert();
  const results = useSelector((state) => state.form.results);

  const [phone, setPhone] = useState<string | undefined>(
    results[df.REQUESTED_BY_PHONE.key]
  );
  const [extension, setExtension] = useState<string | undefined>(
    results[df.REQUESTED_BY_EXTENSION.key]
  );

  const fields = useMemo(
    () => [
      df.REQUESTED_BY,
      {
        ...df.REQUESTED_BY_PHONE,
        required: requireAuthEnrollmentRequiredFields(props.auth),
      },
      df.REQUESTED_BY_EXTENSION,
    ],
    [props.auth]
  );
  const fieldConfigs = useFieldConfigs(fields, props.auth.formFieldConfig);

  const [upsertAccountForSamaUsers] = useMutation<
    void,
    MutationUpsertAccountForSamaUsersArgs
  >(upsertAccountForSamaUsersMutation, {
    refetchQueries: () => [ACCOUNTS_FIND_ALL_QUERY_NAME],
  });

  useEffect(() => {
    if (phone == null) {
      setPhone(results[df.REQUESTED_BY_PHONE.key]);
    }

    if (extension == null) {
      setExtension(results[df.REQUESTED_BY_EXTENSION.key]);
    }
  }, [extension, phone, setPhone, setExtension, results]);

  const handleUpdateRequestedByDetails = async () => {
    try {
      const hasUpdatedPhone = phone !== results[df.REQUESTED_BY_PHONE.key];
      const hasUpdatedExtension =
        extension !== results[df.REQUESTED_BY_EXTENSION.key];

      let formattedPhone;
      if (hasUpdatedPhone) {
        const number = results[df.REQUESTED_BY_PHONE.key] ?? "";
        formattedPhone = `${number.slice(0, 3)}-${number.slice(
          3,
          6
        )}-${number.slice(6)}`;
        set({ [df.REQUESTED_BY_PHONE.key]: formattedPhone });
        setPhone(formattedPhone);
      }

      if (hasUpdatedExtension) {
        set({
          [df.REQUESTED_BY_EXTENSION.key]:
            results[df.REQUESTED_BY_EXTENSION.key],
        });
        setExtension(results[df.REQUESTED_BY_EXTENSION.key]);
      }

      await props.saveChanges();

      await upsertAccountForSamaUsers({
        variables: {
          patch: {
            id: parseInt(props.account.id),
            InstitutionId: props.account.InstitutionId,
            firstName: props.account.firstName,
            lastName: props.account.lastName,
            email: props.account.email,
            ...(hasUpdatedPhone ? { phone: formattedPhone } : {}),
            ...(hasUpdatedExtension
              ? { extension: results[df.REQUESTED_BY_EXTENSION.key] }
              : {}),
          },
        },
      });
      alert.success("Successfully saved your phone number updates!");
    } catch (e) {
      alert.error("There was an error saving your phone number updates");
    }
  };

  return (
    <LeftRightCenterV style={{ columnGap: "8px" }}>
      <FormField
        title="Name"
        fieldConfig={fieldConfigs[df.REQUESTED_BY.key]}
        style={{ width: "100%" }}
      >
        <SegmentText
          disabled={props.isDisabled}
          handleChange={set}
          item={{
            key: df.REQUESTED_BY.key,
          }}
          highlightIfMissing={
            highlightRequiredFields &&
            fieldConfigs[df.REQUESTED_BY.key].isRequired
          }
        />
      </FormField>

      <FormField
        title="Phone"
        fieldConfig={fieldConfigs[df.REQUESTED_BY_PHONE.key]}
        style={{ width: "100%" }}
      >
        <SegmentPhone
          disabled={props.isDisabled}
          handleChange={set}
          item={{
            key: df.REQUESTED_BY_PHONE.key,
          }}
          highlightIfMissing={
            highlightRequiredFields &&
            fieldConfigs[df.REQUESTED_BY_PHONE.key].isRequired
          }
        />
      </FormField>

      <FormField
        title="Extension"
        fieldConfig={fieldConfigs[df.REQUESTED_BY_EXTENSION.key]}
        style={{ width: "100%" }}
      >
        <LeftRightCenterV style={{ position: "relative" }}>
          <SegmentText
            disabled={props.isDisabled}
            handleChange={set}
            item={{
              key: df.REQUESTED_BY_EXTENSION.key,
            }}
            highlightIfMissing={
              highlightRequiredFields &&
              fieldConfigs[df.REQUESTED_BY_EXTENSION.key].isRequired
            }
          />
          {(phone !== results[df.REQUESTED_BY_PHONE.key] ||
            extension !== results[df.REQUESTED_BY_EXTENSION.key]) && (
            <CreateButton onClick={handleUpdateRequestedByDetails}>
              Save Phone Number Updates
            </CreateButton>
          )}
        </LeftRightCenterV>
      </FormField>
    </LeftRightCenterV>
  );
};
