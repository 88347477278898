import * as React from "react";
import Modal from "@@components/Modal";
import { ModalBodyLarge } from "@@components/ModalStyledComponents";
import BaseButton from "@@components/BaseButton";

export const LocateNumberTipModal: React.VoidFunctionComponent<{
  onClick: () => void;
}> = ({ onClick }) => (
  <Modal header="Locating the right fax number" onClick={onClick} open>
    <ModalBodyLarge>
      <>
        <p>
          Locate the correct prior authorization fax number on the back of your
          patient&#39;s insurance card. If there is no fax number listed, please
          call the number listed on the back of the card to get help in
          determining the correct fax number.
        </p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <BaseButton
            style={{ marginRight: "auto", width: "100%" }}
            onClick={onClick}
          >
            Ok
          </BaseButton>
        </div>
      </>
    </ModalBodyLarge>
  </Modal>
);
