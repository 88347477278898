import { useApolloClient, useQuery } from "@apollo/client";

import { useMemo, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@samacare/design/core";
import IconButton from "@samacare/design/core/IconButton";
import { Prescriber } from "@samacare/graphql";
import { PrescribersQuery } from "@@generated/graphql";
import { allPrescribersQuery } from "../../../graphql/Prescriber";
import EditIcon from "@samacare/design/core/icons/Edit";
import { useCurrentAccount } from "../../../graphql/Account";
import { DeactivateIconButton } from "./DeactivateIconButton";
import { GridToolbar } from "../../../components/DataGrid/GridToolbar";
import { UpsertPrescriberDialog } from "@samacare/form";
import { formatTinForDisplay } from "@samacare/utils";
import { useAlert } from "react-alert";

function PrescriberTableNoResults() {
  return (
    <Box display="flex" justifyContent="center" mt={3}>
      <Typography variant="body">No prescribers have been added.</Typography>
    </Box>
  );
}

const PrescribersTable: React.FC = () => {
  const alert = useAlert();
  const [selectedPrescriber, setSelectedPrescriber] =
    useState<Prescriber | null>(null);

  const [currentAccount] = useCurrentAccount();
  const apolloClient = useApolloClient();

  const { data, loading } = useQuery<PrescribersQuery>(allPrescribersQuery);
  const prescriberList = useMemo(() => data?.prescribersFindAll ?? [], [data]);

  const gridColumns: GridColDef[] = useMemo(
    () => [
      { field: "lastName", headerName: "Last Name" },
      { field: "firstName", headerName: "First Name" },
      { field: "specialtyDescription", headerName: "Taxonomy" },
      { field: "NPI", headerName: "NPI" },
      {
        field: "TIN",
        headerName: "TIN",
        renderCell: (props) => formatTinForDisplay(props.value as string),
      },
      { field: "DEA", headerName: "DEA" },
      { field: "licenseNumber", headerName: "License" },
      { field: "label", headerName: "Nickname" },
      { field: "specialtyCode", headerName: "Taxonomy Code" },
      {
        field: "buttons",
        headerName: "",
        hideable: false,
        filterable: false,
        sortable: false,
        renderCell: (props: GridRenderCellParams) => (
          <Stack direction="row" width="100%" justifyContent="flex-end">
            <IconButton
              onClick={() => setSelectedPrescriber(props.row as Prescriber)}
            >
              <EditIcon />
            </IconButton>
            {currentAccount?.isAdmin && (
              <DeactivateIconButton prescriber={props.row as Prescriber} />
            )}
          </Stack>
        ),
      },
    ],
    [currentAccount]
  );

  const showList = !loading && currentAccount;

  return (
    <>
      {showList && (
        <DataGridPro
          rows={prescriberList}
          columns={gridColumns}
          autosizeOnMount
          autosizeOptions={{ expand: true, includeHeaders: true }}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: PrescriberTableNoResults,
          }}
          slotProps={{
            toolbar: {
              onNewClick: () => setSelectedPrescriber({} as Prescriber),
              newButtonDataCy: "actionNewPrescriber",
            },
          }}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
            sorting: {
              sortModel: [
                { field: "lastName", sort: "asc" },
                { field: "firstName", sort: "asc" },
              ],
            },
          }}
          sx={{ border: 0, mt: 2 }}
        />
      )}

      <UpsertPrescriberDialog
        apolloClient={apolloClient}
        open={!!selectedPrescriber}
        PrescriberId={selectedPrescriber?.id}
        onClose={() => setSelectedPrescriber(null)}
        onAlert={(message) => alert.error(message)}
      />
    </>
  );
};

export default PrescribersTable;
