export enum PatientColumn {
  FirstName = "first_name",
  LastName = "last_name",
  DOB = "dob",
  MRN = "mrn",
  Address = "address",
  City = "city",
  State = "state",
  Zip = "zip",
  Phone = "phone",
  Sex = "sex",
  InsuranceMemberId = "insurance_member_id",
  InsuranceGroupNumber = "insurance_group_number",
}

interface PatientColumnDetails {
  key: PatientColumn;
  label: string;
  searchTextList: string[];
  required: boolean;
}

export const patientColumnDetails: Record<PatientColumn, PatientColumnDetails> =
  {
    [PatientColumn.FirstName]: {
      key: PatientColumn.FirstName,
      label: "First Name",
      searchTextList: ["first"],
      required: true,
    },
    [PatientColumn.LastName]: {
      key: PatientColumn.LastName,
      label: "Last Name",
      searchTextList: ["last"],
      required: true,
    },
    [PatientColumn.DOB]: {
      key: PatientColumn.DOB,
      label: "Date of Birth",
      searchTextList: ["dob", "birth"],
      required: true,
    },
    [PatientColumn.MRN]: {
      key: PatientColumn.MRN,
      label: "MRN",
      searchTextList: ["mrn"],
      required: true,
    },
    [PatientColumn.Address]: {
      key: PatientColumn.Address,
      label: "Address",
      searchTextList: ["address"],
      required: false,
    },
    [PatientColumn.City]: {
      key: PatientColumn.City,
      label: "City",
      searchTextList: ["city"],
      required: false,
    },
    [PatientColumn.State]: {
      key: PatientColumn.State,
      label: "State",
      searchTextList: ["state"],
      required: false,
    },
    [PatientColumn.Zip]: {
      key: PatientColumn.Zip,
      label: "Zip",
      searchTextList: ["zip"],
      required: false,
    },
    [PatientColumn.Phone]: {
      key: PatientColumn.Phone,
      label: "Phone",
      searchTextList: ["phone"],
      required: false,
    },
    [PatientColumn.Sex]: {
      key: PatientColumn.Sex,
      label: "Sex",
      searchTextList: ["sex"],
      required: false,
    },
    [PatientColumn.InsuranceMemberId]: {
      key: PatientColumn.InsuranceMemberId,
      label: "Insurance Member ID",
      searchTextList: ["member"],
      required: false,
    },
    [PatientColumn.InsuranceGroupNumber]: {
      key: PatientColumn.InsuranceGroupNumber,
      label: "Insurance Group Number",
      searchTextList: ["group"],
      required: false,
    },
  };

export const requiredPatientColumns = [
  ...Object.values(patientColumnDetails)
    .filter((pcd) => pcd.required)
    .map((pcd) => pcd.key),
] as const;
