import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface BaseObject {
  id: number | string;
}

// This will refresh the selected object when the list changes.
// This helps when showing a view drawer and then editing the object.
export const useRefreshReadySelectedObject = <T extends BaseObject>(
  list: T[]
): [T | null, Dispatch<SetStateAction<T | null>>] => {
  const [selectedForView, setSelectedForView] = useState<T | null>(null);

  useEffect(() => {
    if (selectedForView && list) {
      const newObj = list.find((o) => o.id === selectedForView.id) ?? null;
      if (newObj) setSelectedForView(newObj);
    }
  }, [list]);

  return [selectedForView, setSelectedForView];
};
