import {
  Mutation,
  MutationCreateTrackerConfigArgs,
  TrackerConfig,
} from "@samacare/graphql";

import { useMutation } from "@apollo/client";
import CreateTrackerConfig from "../graphql/CreateTrackerConfigMutation.gql";

const useCreateTrackerConfig = () => {
  const [createTrackerConfig] = useMutation<
    { createTrackerConfig: Mutation["createTrackerConfig"] },
    MutationCreateTrackerConfigArgs
  >(CreateTrackerConfig, {
    refetchQueries: ["GetTrackerConfigs"],
  });

  const create = async (trackerConfig: TrackerConfig) => {
    const result = await createTrackerConfig({
      variables: { object: trackerConfig },
    });

    return result.data?.createTrackerConfig;
  };

  return create;
};

export { useCreateTrackerConfig };
