import _ from "lodash";
import { PureComponent } from "react";
import { compose } from "recompose";
import styled from "styled-components";
import { BaseText } from "Segment/StyledComponents";
import FaxEditContainer from "../components/FaxEditContainer";
import { Pill } from "../../SharedStyles";
import { withApollo } from "@apollo/client/react/hoc";
import { fetchFormNumbers } from "./formsNumbersUtils";
import { withAlert } from "react-alert";
import { withUpsertFaxFilter } from "../../../../graphql/FaxFilter";

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: ${(props) => props.selected && props.theme.lightPurple};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.lightPurple};
  }
`;

const DarkGray = styled.div`
  color: ${(props) => props.theme.darkGray};
`;

const InputLabel = styled.div`
  width: 100%;
  font-weight: 700;
  margin: 10px 0;
`;

export const Scrollable = styled.div`
  margin: 8px 0;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const MainContainer = styled.div`
  display: flex;
  margin: 10px 0px;
`;

export class FaxEdit extends PureComponent {
  state = {
    searchNumber: "",
    searchDescription: "",
    selectedNumberId: null,
    formNumbers: [],
  };

  getFilteredNumbers = () => {
    const { searchNumber, searchDescription, formNumbers } = this.state;
    return _.filter(
      formNumbers,
      ({ description, number }) =>
        number.replace(/-/g, "").includes(searchNumber.replace(/-/g, "")) &&
        description.toUpperCase().includes(searchDescription.toUpperCase())
    );
  };

  handleFetchFormNumbers = async () => {
    const { searchNumber, searchDescription } = this.state;
    const { client } = this.props;

    this.setState({ formNumbersLoading: true });
    const formNumbers = await fetchFormNumbers(
      client,
      searchNumber,
      searchDescription
    );
    this.setState({ formNumbers, formNumbersLoading: false });
  };

  debouncedFetchFormNumbers = _.debounce(this.handleFetchFormNumbers, 500);

  unsetFormNumbers = () => {
    this.setState({
      formNumbers: [],
      selectedNumberId: null,
    });
  };

  handleUpsertFaxFilter = async (vars) => {
    try {
      const { upsertFaxFilter } = this.props;
      const { selectedNumberId } = this.state;
      await upsertFaxFilter({
        variables: {
          formNumberId: +selectedNumberId,
          ...vars,
        },
      });

      await this.handleFetchFormNumbers();
    } catch (e) {
      alert.error(`Error, ${e.message}`);
    }
  };

  handleDeleteFaxFilter = async () => {
    try {
      const { selectedNumberId, formNumbers } = this.state;
      const updatedFormNumber = _.find(formNumbers, { id: selectedNumberId });
      const filterId = updatedFormNumber.faxFilter?.id;

      if (filterId) {
        const { deleteFormOrFaxFilter } = this.props;

        await deleteFormOrFaxFilter({
          variables: {
            id: filterId,
            isFormFilter: false,
          },
        });

        await this.handleFetchFormNumbers();
      }
    } catch (e) {
      alert.error(`Error, ${e.message}`);
    }
  };

  render() {
    const {
      searchNumber,
      searchDescription,
      selectedNumberId,
      formNumbers,
      formNumbersLoading,
    } = this.state;

    return (
      <MainContainer>
        <div style={{ width: "35%", marginRight: "16px" }}>
          <InputLabel>
            Type to Search Numbers - Click on a number to edit
          </InputLabel>
          <BaseText
            style={{ width: "250px" }}
            onChange={(e) => {
              this.unsetFormNumbers();
              this.setState({ searchDescription: e.target.value });
              this.debouncedFetchFormNumbers();
            }}
            value={searchDescription}
            placeholder={
              formNumbersLoading ? "Loading..." : "Search Description"
            }
          />
          <BaseText
            style={{ width: "200px", margin: "0 8px" }}
            onChange={(e) => {
              this.unsetFormNumbers();
              this.setState({ searchNumber: e.target.value });
              this.debouncedFetchFormNumbers();
            }}
            value={searchNumber}
            placeholder={formNumbersLoading ? "Loading..." : "Search Number"}
          />
          <Scrollable style={{ height: "450px" }}>
            {_.map(
              this.getFilteredNumbers().sort(
                (num1, num2) => num1.isHidden - num2.isHidden
              ),
              ({
                id,
                description,
                number,
                forbiddenReason,
                associatedForms,
                associatedCompanies,
                InstitutionId,
                isHidden,
              }) => (
                <ItemContainer
                  selected={selectedNumberId === id}
                  onClick={() => {
                    this.setState({ selectedNumberId: id });
                  }}
                  key={`fax-${id}`}
                >
                  <div
                    style={{
                      maxWidth: "80%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {description}
                    </div>
                    <DarkGray>{number}</DarkGray>
                  </div>
                  <div
                    style={{
                      maxWidth: "30%",
                      display: "flex",
                      flexWrap: "wrap",
                      marginLeft: "10px",
                    }}
                  >
                    <Pill>{`${_.size(associatedForms)} Form${
                      _.size(associatedForms) === 1 ? "" : "s"
                    }`}</Pill>
                    <Pill>{`${_.size(associatedCompanies)} Payer${
                      _.size(associatedCompanies) === 1 ? "" : "s"
                    }`}</Pill>
                    {forbiddenReason && <Pill disabled>Blacklisted</Pill>}
                    {isHidden && <Pill warning>Hidden</Pill>}
                    {InstitutionId && (
                      <Pill>{`Institution ${InstitutionId}`}</Pill>
                    )}
                  </div>
                </ItemContainer>
              )
            )}
          </Scrollable>
        </div>
        <div style={{ width: "65%" }}>
          {selectedNumberId && (
            <FaxEditContainer
              selectedNumber={_.find(formNumbers, { id: selectedNumberId })}
              clearSelectedNumber={() => {
                this.setState({ selectedNumberId: null });
              }}
              upsertFaxFilter={this.handleUpsertFaxFilter}
              deleteFaxFilter={this.handleDeleteFaxFilter}
            />
          )}
        </div>
      </MainContainer>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withUpsertFaxFilter)(withApollo(withAlert()(FaxEdit)));
