import { RoundedButton } from "../Button/RoundedButton";
import { UnderlineButton } from "../Button/UnderlineButton";
import { LeftRightCenterAll } from "../LeftRight/LeftRight";
import { DialogFooter } from "./DialogFooter";

interface DialogFooterWithButtonsProps {
  onCancel?: () => void | Promise<void>;
  onConfirm?: () => void | Promise<void>;
  cancelText?: string;
  confirmText?: string;
}
export const DialogFooterWithButtons: React.FC<DialogFooterWithButtonsProps> = (
  props,
) => (
    <DialogFooter>
      <LeftRightCenterAll sx={{ width: "100%" }}>
        {props.onCancel && (
          <UnderlineButton onClick={props.onCancel}>
            {props.cancelText || "Cancel"}
          </UnderlineButton>
        )}
        {props.onConfirm && (
          <RoundedButton onClick={props.onConfirm}>
            {props.confirmText || "Confirm"}
          </RoundedButton>
        )}
      </LeftRightCenterAll>
    </DialogFooter>
  );
