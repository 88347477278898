import { GridFilterModel, GridFilterItem } from "@samacare/design";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface CleanGridFilterModel extends GridFilterModel {
  quickFilterValues: string[];
}

const getDefaultFilterModel = (): CleanGridFilterModel => ({
  items: [],
  quickFilterValues: [],
});

const buildUrl = (path: string, query: string) => {
  const shouldAddQuestionMark = query && query[0] !== "?";
  return `${path}${shouldAddQuestionMark ? "?" : ""}${query}`;
};

const clean = (value: string | null) =>
  value ? value.replace(/[^a-zA-Z0-9.]/g, "") : null;

/////////////////////////
// Quick Filters Serde //
/////////////////////////

const serializeQuickFilters = (values: string[] | undefined) => {
  if (!values || values.length === 0) {
    return null;
  }
  return `quickFilters=${values.map(clean).join(",")}`;
};

const deserializeQuickFilters = (value: string | null) => {
  if (!value) {
    return [];
  }
  return value.split(",");
};

//////////////////////////
// Column filters Serde //
//////////////////////////

const serializeFilters = (items: GridFilterItem[]) => {
  if (!items || items.length === 0) {
    return null;
  }
  const filters = items.map(
    ({ field, value, operator }) =>
      `${clean(field)} ${clean(operator)} ${clean(value as string) ?? ""}`
  );
  return `filters=${filters.join(",")}`;
};

const deserializeFilters = (value: string | null) => {
  if (!value) {
    return [];
  }
  const filters: GridFilterItem[] = [];
  value.split(",").forEach((item) => {
    const [field, operator, fieldValue] = item.split(" ").map(clean);
    if (!field || !operator || !fieldValue) {
      return;
    }
    filters.push({
      field,
      operator,
      value: fieldValue,
    });
  });
  return filters;
};

/////////////////////////////
// Grid Filter Model Serde //
/////////////////////////////

const serializeFilterModel = (filterModel: GridFilterModel) => {
  const { items, quickFilterValues } = filterModel;
  const queryStrings = [] as string[];

  const serializedQuickFilters = serializeQuickFilters(quickFilterValues);
  if (serializedQuickFilters) {
    queryStrings.push(serializedQuickFilters);
  }

  const serializedFilters = serializeFilters(items);
  if (serializedFilters) {
    queryStrings.push(serializedFilters);
  }

  return queryStrings.join("&");
};

const deserializeQueryParam = (queryString: string, key: string) => {
  const query = new URLSearchParams(queryString);
  const value = query.get(key);
  if (!value) {
    return [];
  }
  return [
    {
      field: key,
      operator: "equals",
      value,
    },
  ];
};

const deserializeFilterModel = (queryString: string): GridFilterModel => {
  if (!queryString) {
    // no query string
    return getDefaultFilterModel();
  }

  if (queryString[0] !== "?") {
    // invalid query string? likely not possible but just in case
    return getDefaultFilterModel();
  }

  const query = new URLSearchParams(queryString);
  const quickFilterValues = deserializeQuickFilters(query.get("quickFilters"));
  let items = deserializeFilters(query.get("filters"));
  // backwards compatibility for `patientId=?` `status=?` and `payer=?` query params
  items = items.concat(deserializeQueryParam(queryString, "patientId"));
  items = items.concat(deserializeQueryParam(queryString, "status"));
  items = items.concat(deserializeQueryParam(queryString, "payer"));

  return {
    ...getDefaultFilterModel(),
    quickFilterValues,
    items,
  };
};

export const useGridFilterUrlSync = (skipReplace: boolean = false) => {
  const history = useHistory();
  const location = useLocation();
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    // Read url and hydrate filter model on first render
    deserializeFilterModel(location.search)
  );

  // Syncs the grid filters to the URL
  React.useEffect(() => {
    const newQuery = serializeFilterModel(filterModel);
    const newUrl = buildUrl(location.pathname, newQuery);
    const currentUrl = buildUrl(location.pathname, location.search);
    if (currentUrl !== newUrl && !skipReplace) {
      history.replace(newUrl);
    }
  }, [filterModel, history, skipReplace]);

  // Syncs the URL to the grid filters when we use history.push
  React.useEffect(() => {
    setFilterModel(deserializeFilterModel(location.search));
  }, [location]);

  return { filterModel, setFilterModel };
};
