import _ from "lodash";
import { PureComponent } from "react";
import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import styled from "styled-components";
import { Flex } from "@@ui-kit";
import PdfViewer from "../../../components/PdfViewer";
import moment from "moment";

const withFormById = graphql(
  gql`
    query formById($id: Int!) {
      formById(id: $id) {
        pdfURL
        title
      }
    }
  `,
  {
    options: (props) => ({ variables: { id: props.formId } }),
    props: ({ data }) => ({ form: _.get(data, "formById") }),
  }
);

const PDFContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: gray;
  margin: 8px 0;
  height: 100%;
  margin-left: 8px;
`;

const FormTitle = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 8px;
  font-size: 16px;
`;

const FormConfirmationContainer = styled(Flex)`
  flex-direction: column;
`;

const TitleSpan = styled.span`
  font-weight: 700;
  margin-right: 4px;
`;

export class FormConfirmation extends PureComponent {
  render() {
    const { form, showTitle } = this.props;
    if (form) {
      return (
        <FormConfirmationContainer
          style={showTitle && { height: "100%", width: "100%" }}
        >
          {showTitle && (
            <FormTitle>
              <TitleSpan>{form.title}</TitleSpan>
              {` `}– Updated{` `}
              {moment(form.updatedAt).format("MMM Do YYYY")}
            </FormTitle>
          )}
          <PDFContainer>
            <PdfViewer
              fileURL={form.pdfURL}
              height={showTitle ? "100%" : "400px"}
              width={showTitle ? "100%" : "650px"}
            />
          </PDFContainer>
        </FormConfirmationContainer>
      );
    }

    return <div />;
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withFormById)(FormConfirmation);
