import styled from "styled-components";

export type TextProps = React.ComponentProps<typeof Text>;

export const Text = styled.span<{
  color?: string;
  size?: number | string;
  weight?: number;
}>`
  color: ${({ color = "inherit" }) => color};
  font-size: ${({ size }) =>
    (typeof size === "number" ? `${size.toString()}px` : size) ?? undefined};
  font-weight: ${({ weight = 400 }) => weight};
`;
