import MuiListItemButton, {
  ListItemButtonProps as MuiListItemButtonProps,
} from "@mui/material/ListItemButton";

export type ListItemButtonVariant = "default" | "filled";

export interface ListItemButtonProps extends MuiListItemButtonProps {
  variant?: ListItemButtonVariant;
}

const ListItemButton: React.FC<ListItemButtonProps> = MuiListItemButton;

export default ListItemButton;
