import { useState } from "react";
import { Box } from "@@ui-kit";
import { useAlert } from "react-alert";
import { useForm } from "@samacare/form";
import { useHistory } from "react-router-dom";
import { EnrollmentProgramChannel } from "@samacare/graphql";
import { SubmitEnrollmentMuataionMutation } from "@@generated/graphql";
import { useMutation } from "@apollo/client";
import { EnrollmentRoutes } from "../../routes/Enrollment/Enrollment.types";
import ROUTE_PATHS from "../ROUTE_PATHS";
import { EnrollmentSection } from "./EnrollmentSection";
import { useEnrollmentContext } from "./EnrollmentProvider";
import { EnrollmentActions } from "./EnrollmentActions";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { EnrollmentPatientInfoHeader } from "./EnrollmentPatientInfoHeader";
import submitEnrollmentMuataion from "./EnrollmentSubmitMutation.gql";

const getSubmissionDetails = (channel?: EnrollmentProgramChannel) => {
  switch (channel) {
    case EnrollmentProgramChannel.Genentech:
      return (
        <>
          <Box>
            SamaCare will submit enrollment to Genentech’s portal directly.
          </Box>
          <Box>
            Upon submission we will update the statuses for all enrollment
            services.
          </Box>
        </>
      );
    case EnrollmentProgramChannel.Assistrx:
      return (
        <Box>
          SamaCare will submit the enrollment form on your behalf to AssistRx.
        </Box>
      );
    case EnrollmentProgramChannel.Horizon:
      return (
        <Box>
          SamaCare will submit the enrollment form on your behalf to Horizon.
        </Box>
      );
    default:
      return null;
  }
};

export const EnrollmentSubmissionPage: React.FC = () => {
  const history = useHistory();

  const [submitEnrollment, { loading }] =
    useMutation<SubmitEnrollmentMuataionMutation>(submitEnrollmentMuataion, {
      refetchQueries: () => ["Enrollments"],
    });
  const { enrollment, enrollmentProgram } = useEnrollmentContext();
  const alert = useAlert();
  const [open, setOpen] = useState(false);

  const methods = useForm({
    defaultValues: {},
  });

  const onSubmit = async () => {
    if (enrollment?.id) {
      try {
        await submitEnrollment({
          variables: {
            enrollmentId: Number(enrollment?.id),
          },
        });
        alert.info("Successfully submitted your enrollment!");
        history.push(
          `${ROUTE_PATHS.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.summary}/${enrollment.id}`
        );
      } catch (error) {
        alert.error("There was an error submitting your enrollment");
      }
    } else {
      alert.error(
        "There was an error submitting your enrollment: EnrollmentId could not be found"
      );
    }
  };

  return (
    <>
      <EnrollmentPatientInfoHeader />
      <Box sx={{ margin: "30px" }}>
        <form
          id="EnrollmentSubmission"
          name="EnrollmentSubmission"
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <EnrollmentSection title="Submission Details">
            {getSubmissionDetails(enrollmentProgram?.channel)}
          </EnrollmentSection>
          <EnrollmentActions
            loading={loading}
            onNextClick={() => {
              setOpen(true);
            }}
          />
          <ConfirmationDialog
            loading={loading}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            formName="EnrollmentSubmission"
          />
        </form>
      </Box>
    </>
  );
};
