import * as React from "react";
import styled from "styled-components";
import { MdClose as CloseIcon } from "@react-icons/all-files/md/MdClose";

import FrozenBackdropHoc from "./FrozenBackdropHoc";

const ModalHeader = styled.div`
  text-align: left;
  font-weight: 700;
  font-size: 20px;
  padding: 15px;
  color: white;
  background-color: ${({ theme }) => theme.purple};
`;

const ModalContainer = styled.div`
  max-height: calc(100vh - 10px);
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zTop};
  background-color: white;
  border-radius: 3px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${ModalHeader} > svg {
    color: white;
  }
`;

const ModalBody = styled.div`
  width: 100%;
`;

const ModalBodyContainer = styled.div`
  padding: 15px 15px 10px 15px;
`;

const CloseModal = styled(CloseIcon).attrs({
  "aria-label": "Close",
  role: "button",
  color: "white",
})`
  position: absolute;
  height: 25px;
  width: 20px;
  right: 15px;
  top: 15px;
  color: ${(props) => props.theme.purple};
  cursor: pointer;
`;

const BaseModal: React.ComponentType<{
  children: React.ReactNode;
  header?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  open?: boolean;
  styleOverride?: React.CSSProperties;
  isPreventCloseOnBackdropClick?: boolean;
  zindexOffset?: number;
  headerStyleOverride?: React.CSSProperties;
}> = ({ children, header, onClick, styleOverride, headerStyleOverride }) => (
  <ModalContainer
    aria-labelledby="modal-header"
    role="dialog"
    style={styleOverride}
  >
    {onClick && (
      <CloseModal
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      />
    )}
    <ModalBody>
      <ModalHeader id="modal-header" style={headerStyleOverride}>
        {header}
      </ModalHeader>
      <ModalBodyContainer>{children}</ModalBodyContainer>
    </ModalBody>
  </ModalContainer>
);
export const BoldHeaderModal = FrozenBackdropHoc(BaseModal);

/**
 * @deprecated Use a MUI component instead
 */
export default BoldHeaderModal;
