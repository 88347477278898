import { object, string, boolean, Maybe, AnySchema, ObjectShape } from "yup";
import _ from "lodash";

import {
  QuestionTypeEnumType,
  QuestionEnabledWhenBehaviourEnumType,
} from "../Questionnaire.types";

import type {
  QuestionObjectType,
  QuestionnaireSchemaType,
  QuestionEnableWhenObjectType,
  QuestionAnswerStringObjectType,
} from "../Questionnaire.types";

/**
 * @function getQuestionType
 * @description Gets yup schema type for a questions type
 */
type GetQuestionTypeOptions = {
  answerOption: QuestionAnswerStringObjectType[];
};

function getQuestionType(
  type: QuestionTypeEnumType,
  { answerOption = [] }: GetQuestionTypeOptions
) {
  switch (type) {
    case QuestionTypeEnumType.Choice:
      return string().oneOf(
        answerOption.map((answer) => answer.valueString ?? "")
      );
    case QuestionTypeEnumType.Boolean:
      return boolean().default(false);
    default:
      return string();
  }
}

/**
 * @function getQuestionWhen
 * @description Generates yup schema for a question's enableWhen clause
 */
type GetQuestionWhenOptions = {
  enableWhen: QuestionEnableWhenObjectType[];
  enableBehavior: QuestionEnabledWhenBehaviourEnumType;
};

function getQuestionWhen(
  item: AnySchema<Maybe<boolean | string>>,
  {
    enableWhen,
    enableBehavior = QuestionEnabledWhenBehaviourEnumType.Any,
  }: GetQuestionWhenOptions
) {
  const all = enableWhen.map((when) => when.question);

  return item.when(all, (values, target) => {
    const evaluated = enableWhen.map(
      ({ answerString, answerBoolean }, i) =>
        values[i] === (answerString ?? answerBoolean)
    );

    let fieldEnabled = false;
    if (enableBehavior === QuestionEnabledWhenBehaviourEnumType.All) {
      fieldEnabled = _.every(evaluated);
    } else if (enableBehavior === QuestionEnabledWhenBehaviourEnumType.Any) {
      fieldEnabled = _.some(evaluated);
    } else {
      throw new Error(
        `The enabledWhen behavior for "${enableBehavior}" is not implemented yet.`
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return fieldEnabled ? target.required() : target.notRequired();
  });
}

/**
 * @function getQuestionnaireSchema
 * @description Generates yup schema for a list of questions in fhir format (current genentech version)
 */
export function getQuestionnaireSchema(
  questions: QuestionObjectType[] = [],
  schemaOverrides: ObjectShape = {}
): QuestionnaireSchemaType {
  const schema: ObjectShape = {};

  for (const {
    id,
    text,
    type,
    answerOption = [],
    required,
    hidden,
    enableWhen,
    enableBehavior,
  } of questions) {
    if (hidden) continue;

    let item: AnySchema<Maybe<boolean | string>> = getQuestionType(type, {
      answerOption: answerOption as QuestionAnswerStringObjectType[],
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (required) item = item.required();

    if (enableWhen != null && enableWhen)
      item = getQuestionWhen(item, {
        enableWhen: enableWhen as QuestionEnableWhenObjectType[],
        enableBehavior: enableBehavior as QuestionEnabledWhenBehaviourEnumType,
      });

    if (text) item = item.label(text);
    if (item) schema[id] = item;
  }

  return object({ ...schema, ...schemaOverrides });
}
