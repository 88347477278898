import { PureComponent } from "react";
import styled from "styled-components";
import { withAlert } from "react-alert";
import { compose } from "recompose";
import _ from "lodash";

import AssociationViewRouter from "./AssociationViewRouter";
import { withCurrentAccount } from "../../graphql/Account";
import { ResponseList } from "./SupportResponses/List";
import { SearchFilters } from "./SupportResponses/SearchFilters";
import {
  withSetCorrespondenceOnAuthorization,
  withHideAuthorizationCorrespondence,
} from "../../graphql/AuthorizationCorrespondence";
import { withSupportToolAuthorizationSearch } from "../../graphql/Authorization";
import { withInstitutions } from "../../graphql/Institution";
import AuthorizationListManager from "./SupportAuthorizationList/AuthorizationListManager";
import CorrespondenceViewer from "../../components/CorrespondenceViewer";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 72px);
  margin-bottom: -10px;
`;

const ScrollContainer = styled.div`
  position: relative;
  flex-grow: 1;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  min-width: 100px;
  overflow-y: scroll;
`;

const { CONSTANTS } = CONFIG;
const statuses = CONSTANTS.AUTHORIZATION_STATUSES;
class SupportResponses extends PureComponent {
  state = {
    selectedResponse: {},
    selectedAuthorization: null,
    toHideQueue: [],
  };

  onSearch = async ({
    firstName,
    lastName,
    memberId,
    AuthorizationId,
    InstitutionId,
    InsuranceCompanyId,
    PrescriberId,
  } = {}) => {
    const { alert, supportToolAuthorizationsSearch } = this.props;

    try {
      await supportToolAuthorizationsSearch.refetch({
        // defaulting empty string to null, otherwise will error graphql
        AuthorizationId: AuthorizationId || null,
        patientFirstName: firstName,
        patientLastName: lastName,
        InstitutionId,
        authorizationStatuses: _.without(
          Object.values(statuses),
          statuses.PRESUBMISSION
        ),
        patientMemberId: memberId,
        PrescriberId,
        InsuranceCompanyId,
      });
    } catch (e) {
      alert.error(`There was an error searching authorizations, ${e.message}`);
    }
  };

  onSelectResponseSearch = async ({
    lastName,
    memberId,
    AuthorizationId,
    InstitutionId,
  }) => {
    const { supportToolAuthorizationsSearch, alert } = this.props;

    const defaultRefetchProps = {
      InstitutionId,
      authorizationStatuses: [
        statuses.EDIT_AND_RESUBMIT,
        statuses.SENT,
        statuses.PENDING,
        statuses.SENDING_FAILED,
        statuses.ACTION_REQUIRED,
      ],
      patientMemberId: memberId || "",
      patientLastName: lastName,
      patientFirstName: "",
      AuthorizationId,
    };

    try {
      const searchResponse = await supportToolAuthorizationsSearch.refetch({
        ...defaultRefetchProps,
      });

      if (
        AuthorizationId &&
        !_.isEmpty(searchResponse?.data?.supportToolAuthorizationsSearch)
      ) {
        this.setState({
          selectedAuthorization:
            searchResponse.data.supportToolAuthorizationsSearch[0],
        });
      }
    } catch (e) {
      alert.error(
        `There was an error finding related authorizations, ${e.message}`
      );
    }
  };

  setCorrespondenceOnAuthorization = async () => {
    const { setCorrespondenceOnAuthorization, alert } = this.props;
    const { selectedResponse, selectedAuthorization } = this.state;

    try {
      await setCorrespondenceOnAuthorization({
        variables: {
          authorizationId: selectedAuthorization.id,
          correspondenceId: selectedResponse.id,
        },
      });

      this.setState({ selectedAuthorization: null, selectedResponse: {} });
      alert.success("Success!");
    } catch (e) {
      alert.error("There was an error setting your response");
    } finally {
      // Very hacky 'fix'. This makes it so that after associating response, your auth doesn't disappear.
      // Only doing this because it's an internal tool
      setTimeout(() => {
        this.onSearch();
      }, 0);
    }
  };

  initiateHideResponse = (responseId) => {
    const { hideCorrespondence } = this.props;

    const clearMethod = setTimeout(() => {
      const { toHideQueue } = this.state;
      hideCorrespondence({ variables: { id: responseId } }).then(() =>
        this.setState({
          toHideQueue: _.reject(toHideQueue, { id: responseId }),
        })
      );
    }, 5000);

    const undoHideMethod = () => {
      const { toHideQueue } = this.state;
      clearTimeout(clearMethod);
      this.setState({ toHideQueue: _.reject(toHideQueue, { id: responseId }) });
    };

    const { toHideQueue } = this.state;
    this.setState({
      toHideQueue: toHideQueue.concat({ id: responseId, undoHideMethod }),
    });
  };

  render() {
    const {
      insuranceCompanies,
      supportToolAuthorizationsSearch,
      institutions,
      alert,
      account,
    } = this.props;

    const { selectedResponse, selectedAuthorization, toHideQueue } = this.state;

    return (
      <Container>
        <ResponseList
          institutions={institutions}
          onSelect={(response) => {
            this.setState({ selectedResponse: response }, () => {
              this.onSelectResponseSearch({
                lastName: response.patientLastName,
                memberId: response.patientMemberId,
                InstitutionId: response.InstitutionId,
                AuthorizationId: response.preAssociationAuthorizationId,
              });
            });
          }}
          onUndoHide={(fn) => {
            fn();
          }}
          onHide={this.initiateHideResponse}
          hiddenQueue={toHideQueue}
          accountId={_.get(account, "id")}
        />
        <CorrespondenceViewer expandOnHover correspondence={selectedResponse} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            flexGrow: 1,
          }}
        >
          <SearchFilters
            insuranceCompanies={insuranceCompanies}
            onSearch={this.onSearch}
            selectedResponse={selectedResponse}
            institutions={institutions}
          />
          <ScrollContainer>
            <AuthorizationListManager
              minWidth="600px"
              authorizations={_.get(
                supportToolAuthorizationsSearch,
                "supportToolAuthorizationsSearch"
              )}
              alwaysOpenInBlank
              emptyStateText="None Found"
              isSupport
              tableConfig={{
                headers: ["Patient", "Status", "Details"],
                columns: [
                  "stackedPatient",
                  "status",
                  "authorizationDetails",
                  {
                    type: "customButton",
                    text: "Associate",
                    customProps: {
                      "data-cy": "actionAssociateResponse",
                    },
                    onClick: (authorization) => {
                      if (!selectedResponse.id) {
                        alert.error("You must first select a response");
                      } else {
                        this.setState({
                          selectedAuthorization: authorization,
                        });
                      }
                    },
                  },
                ],
              }}
            />
          </ScrollContainer>
        </div>
        {selectedAuthorization && (
          <AssociationViewRouter
            onClose={() => {
              this.setState({ selectedAuthorization: null });
            }}
            correspondence={selectedResponse}
            currentAuthorization={selectedAuthorization}
            setCorrespondenceOnAuthorization={
              this.setCorrespondenceOnAuthorization
            }
          />
        )}
      </Container>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withSupportToolAuthorizationSearch,
  withSetCorrespondenceOnAuthorization,
  withHideAuthorizationCorrespondence,
  withInstitutions,
  withCurrentAccount
)(withAlert()(SupportResponses));
