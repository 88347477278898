import { useState } from "react";
import * as React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";

import Modal from "../../../components/Modal";
import { Box } from "@samacare/design";
import CorrespondenceViewer from "../../../components/CorrespondenceViewer";
import { PrimaryButton } from "@@ui-kit";
import { PhoneCleave } from "../../../components/Segment/BaseComponents";
import { BaseMultiline } from "../../../components/Segment/StyledComponents";
import { INSURANCE_RESPONSE_QUERY_NAME } from "../../../graphql/AuthorizationCorrespondence";

const TwoPaneContainer = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const AuthorizationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 80%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 80px 10px;
`;

const sendResponsesToHCPMutation = gql`
  mutation sendResponsesToHCP(
    $message: String!
    $faxNumber: String!
    $correspondenceId: Int!
    $authorizationId: Int!
  ) {
    sendResponsesToHCP(
      message: $message
      faxNumber: $faxNumber
      correspondenceId: $correspondenceId
      authorizationId: $authorizationId
    ) {
      success
    }
  }
`;

const forwardCorrespondenceToPayerMutation = gql`
  mutation forwardCorrespondenceToPayer(
    $faxNumber: String!
    $authorizationId: Int!
    $correspondenceId: Int!
  ) {
    forwardCorrespondenceToPayer(
      faxNumber: $faxNumber
      authorizationId: $authorizationId
      correspondenceId: $correspondenceId
    ) {
      success
    }
  }
`;

export type HCPResponseModalProps = React.ComponentProps<
  typeof HCPResponseModal
>;

export const HCPResponseModal: React.VoidFunctionComponent<{
  onClose: () => void;
  correspondence: { id: number; originatingFaxNumber: string };
  faxNumber: string;
  authorizationId: number;
  setCorrespondenceOnAuthorization: () => Promise<void>;
}> = ({
  faxNumber,
  onClose,
  correspondence,
  authorizationId,
  setCorrespondenceOnAuthorization,
}) => {
  const [view, setView] = useState("");
  const [message, setMessage] = useState("");
  const [updatedPayerFaxNumber, setUpdatedPayerFaxNumber] = useState(faxNumber);
  const [updatedHCPFaxNumber, setUpdatedHCPFaxNumber] = useState(
    correspondence.originatingFaxNumber
  );

  const [forwardCorrespondenceToPayer] = useMutation(
    forwardCorrespondenceToPayerMutation,
    {
      refetchQueries: () => [INSURANCE_RESPONSE_QUERY_NAME],
    }
  );
  const [sendResponsesToHCP] = useMutation(sendResponsesToHCPMutation);

  const alert = useAlert();

  // Most of the platform is not setup to handle international phone numbers.
  // We have found at times that we will receive an international phone number from Phaxio.
  // At some point, we will migrate to the E.164 format.  For now, we will not allow
  // the user to edit an international phone number since our UI is not setup to handle it.
  const isOriginatingFaxInternational =
    correspondence.originatingFaxNumber &&
    correspondence.originatingFaxNumber.length !== 12;

  const handleSend = async () => {
    if (!isOriginatingFaxInternational && updatedHCPFaxNumber.length !== 12) {
      throw new Error("Phone number is missing digits");
    }
    try {
      await sendResponsesToHCP({
        variables: {
          faxNumber: updatedHCPFaxNumber,
          message,
          correspondenceId: correspondence.id,
          authorizationId,
        },
      });
      await setCorrespondenceOnAuthorization();

      alert.info("Successfully sent response to HCP");
      onClose();
    } catch (e) {
      alert.error("Failed to send response to HCP with unknown error");
    }
  };

  const handleForwarding = async () => {
    if (updatedPayerFaxNumber.length !== 12) {
      throw new Error("Phone number is missing digits");
    }
    try {
      await forwardCorrespondenceToPayer({
        variables: {
          faxNumber: updatedPayerFaxNumber,
          authorizationId,
          correspondenceId: correspondence.id,
        },
      });
      onClose();

      alert.info("Successfully forward the correspondence to payer");
    } catch (e) {
      alert.error(
        "Failed to forward the correspondence to payer with unknown error"
      );
    }
  };

  return (
    <Modal onClick={onClose} open header="">
      <TwoPaneContainer>
        <CorrespondenceViewer
          correspondence={correspondence}
          width="700px"
          height="550px"
        />
        {view === "" && (
          <AuthorizationDetailsContainer>
            <ButtonWrapper>
              <PrimaryButton
                style={{ marginBottom: "10px" }}
                onClick={() => {
                  setView("Reject & Respond to HCP");
                }}
              >
                Reject & Respond to HCP
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  setView("Forward to payer");
                }}
              >
                Forward to Payer
              </PrimaryButton>
            </ButtonWrapper>
          </AuthorizationDetailsContainer>
        )}
        {view === "Reject & Respond to HCP" && (
          <AuthorizationDetailsContainer>
            <h1>Send response to HCP</h1>
            {isOriginatingFaxInternational ? (
              <Box>{updatedHCPFaxNumber}</Box>
            ) : (
              <PhoneCleave
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdatedHCPFaxNumber(e.target.value)
                }
                number={
                  updatedHCPFaxNumber ||
                  correspondence.originatingFaxNumber ||
                  ""
                }
              />
            )}

            <BaseMultiline
              placeholder="Message"
              style={{ margin: 10 }}
              value={message}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setMessage(e.target.value)
              }
            />
            <PrimaryButton onClick={handleSend}>Submit</PrimaryButton>
          </AuthorizationDetailsContainer>
        )}
        {view === "Forward to payer" && (
          <AuthorizationDetailsContainer>
            <h1>Forward response to payer</h1>
            <PhoneCleave
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setUpdatedPayerFaxNumber(e.target.value)
              }
              number={updatedPayerFaxNumber || faxNumber || ""}
            />
            <PrimaryButton
              onClick={handleForwarding}
              style={{ marginTop: "10px" }}
            >
              Forward
            </PrimaryButton>
          </AuthorizationDetailsContainer>
        )}
      </TwoPaneContainer>
    </Modal>
  );
};

export default HCPResponseModal;
